import BaseModel from "./base.model";
import { ObjectHash } from "../utils/helpers";
import TemplatePropertyModel from "./template-property.model";

export default class TemplateModel extends BaseModel {
  id: string;
  company: string;
  companyId: string;
  name: string;

  // original prop values received from the API
  userDefinedProperties: TemplatePropertyModel[];

  // props generated using userDefinedProperties and workspace settings via WorkspaceService::makeWorkspaceTemplates
  properties: TemplatePropertyModel[];
  deleted: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    super();

    this.id = data.id;
    this.company = data.company;
    this.companyId = data.companyId;
    this.name = data.name;
    this.properties = data.properties || [];
    this.userDefinedProperties = [];

    if (data.userDefinedProperties?.length) {
      this.userDefinedProperties = data.userDefinedProperties.map(
        (prop: ObjectHash) => new TemplatePropertyModel(prop)
      );
    }

    this.deleted = data.deleted;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
