import React from "react";

import AddBlockMenu from "../add-block-menu";
import Button from "../../../../components/button";
import { ObjectHash } from "../../../../utils/helpers";
import Tooltip from "../../../../components/tooltip";
import useModal from "../../../../hooks/use-modal.hook";

import "./multi-block-menu.scss";

interface Props {
  blockId: string;
  blocksCount: number;
  blockDeleteLabel: string;
  className: string;
  innerBlockId: string;
  innerIndex: number;
  onAddMultiBlock: CallableFunction;
  onDeleteMultiBlock: CallableFunction;
  onMoveMultiBlock: CallableFunction;
  options: ObjectHash;
}

export default function MultiBlockMenu(props: Props) {
  const { openModal } = useModal();

  return (
    <div className={props.className || "multi-block-menu"}>
      <div
        key={`add-${props.blockId}-${props.innerBlockId}`}
        className="multi-block-menu__option"
        onClick={() => {}}
      >
        <AddBlockMenu
          onAddBlock={() => {}}
          onAddMultiBlock={props.onAddMultiBlock}
          blockId={props.blockId}
          innerBlockId={props.innerBlockId}
          isDisabled={props.blocksCount > 3}
          key={`add-block-menu-${props.blockId}-${props.innerBlockId}`}
        />
      </div>
      <Tooltip text="Move block left">
        <Button
          key={`move-left-${props.blockId}-${props.innerBlockId}`}
          color="product-blue"
          icon="nav-arrow-left"
          isDisabled={props.innerIndex === 0}
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => props.onMoveMultiBlock("left")}
          size="medium"
        />
      </Tooltip>
      <Tooltip text="Move block right">
        <Button
          key={`move-right-${props.blockId}-${props.innerBlockId}`}
          color="product-blue"
          icon="nav-arrow-right"
          isDisabled={props.innerIndex + 1 === props.blocksCount}
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => props.onMoveMultiBlock("right")}
          size="medium"
        />
      </Tooltip>
      {props.blocksCount > 1 && (
        <div className="multi-block-menu__option multi-block-menu__option--error">
          <Tooltip text="Delete block">
            <Button
              key={`delete-${props.blockId}-${props.innerBlockId}`}
              color="light-red"
              icon="trash"
              isTransparent={true}
              isRippleDisabled={true}
              onClick={() => {
                openModal("confirm", {
                  dialogTitle: `Delete ${
                    props.blockDeleteLabel ? `${props.blockDeleteLabel} ` : ""
                  }Block`,
                  dialogBody: "Are you sure you want to delete this block?",
                  onConfirm: () => props.onDeleteMultiBlock()
                });
              }}
              size="medium"
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
}
