import React, { useCallback, useEffect, useState } from "react";

import AppIcon from "../../../components/app-icon";
import AppIconFrame from "../../../components/app-icon-frame";
import FilterMenu from "../../../components/stack/table-actions/filter-menu";
import {
  StackFilter,
  StackGroup,
  StackGroupDefault,
  StackGroupResult,
  StackSort
} from "../../../components/stack/helpers";
import KeywordSearchInput from "../../../components/stack/table-actions/keyword-search-input";
import AppContainer from "../../../container";
import BookingRequestModel from "../../../models/booking-request.model";
import CollectionModel from "../../../models/collection.model";
import CollectionRequestStack from "./collection-request-stack";
import {
  CollectionService,
  ICollectionService
} from "../../../services/collection.service";
import { LoadState } from "../../../utils/helpers";
import { Filters, GroupSections, Sorts } from "./stack";
import StackAddInput from "../../../components/stack/table-actions/stack-add-input";
import StackToolbar from "../../../components/stack/stack-toolbar";
import SortMenu from "../../../components/stack/table-actions/sort-menu";
import GroupMenu from "../../../components/stack/table-actions/group-menu";
import useApp from "../../../hooks/use-app.hook";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import useTravel from "../../../hooks/use-travel.hook";

import "./collection-requests.scss";

interface Props {
  collection: CollectionModel;
}

export default function CollectionRequests(props: Props) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );

  const { setSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { settings } = useApp();
  const { lastBookingRequest } = useTravel();

  const { collection } = props;
  const [keywordFilter, setKeywordFilter] = useState<StackFilter | null>(null);
  const [loadState, setLoadState] = useState<LoadState>("unloaded");

  const [requests, setRequests] = useState<BookingRequestModel[]>([]);
  const [stackFilters, setStackFilters] = useState<StackFilter[] | null>(null);
  const [stackGroup, setStackGroup] = useState<StackGroup>(StackGroupDefault);
  const [stackSort, setStackSort] = useState<StackSort>(Sorts[0]); // default sort is traveler asc
  const [stackGroupResults, setStackGroupResults] = useState<
    StackGroupResult[]
  >([]);

  const loadGroupResults = useCallback(async () => {
    const allFilters = [...(stackFilters || [])];
    if (keywordFilter?.value) {
      allFilters.push({ ...keywordFilter });
    }

    const filterData = collectionService.filterBookingRequests(
      requests,
      allFilters
    );

    const results = collectionService.groupBookingRequests(
      filterData,
      stackGroup
    );

    setStackGroupResults(results);

    setLoadState("loaded");
  }, [collectionService, keywordFilter, requests, stackGroup, stackFilters]);

  const handleReload = useCallback(() => {
    setLoadState("unloaded");
  }, [setLoadState]);

  const getStackTitle = (value: any): string => {
    const { onStackTitle } = stackGroup;
    return onStackTitle ? onStackTitle(value, settings) : value;
  };

  useEffect(() => {
    if (loadState !== "unloaded") {
      return;
    }

    setLoadState("loading");

    collectionService
      .getBookingRequests(collection.id)
      .then((requests: BookingRequestModel[]) => {
        setRequests(requests);
      });
  }, [collection, collectionService, loadState, setLoadState, setRequests]);

  // update results when requests change
  useEffect(() => {
    loadGroupResults();
  }, [loadGroupResults, requests]);

  // update results when keyword filter changes
  useEffect(() => {
    if (keywordFilter === null) {
      return;
    }
    loadGroupResults();
  }, [keywordFilter, loadGroupResults]);

  // update results when filter menu filters change
  useEffect(() => {
    if (stackFilters === null) {
      return;
    }
    loadGroupResults();
  }, [loadGroupResults, stackFilters]);

  // update results when grouping changes
  useEffect(() => {
    if (stackGroup === StackGroupDefault) {
      return;
    }
    loadGroupResults();
  }, [loadGroupResults, stackGroup]);

  // update results when new requests come in from the websocket
  useEffect(() => {
    const { bookingCollection } = lastBookingRequest;

    if (bookingCollection !== collection.id) {
      return;
    }

    handleReload();
  }, [collection, handleReload, lastBookingRequest]);

  const hasFilters = Boolean(stackFilters?.length);
  const emptyMessage = hasFilters
    ? "There were no requests found that mach your filter criteria"
    : "You haven't added any requests to the collection yet";

  return (
    <div id="collection-request-group">
      <StackToolbar>
        <div className="request">
          <StackAddInput
            menuPortalTarget={document.body}
            onAdd={async (userId: string) => {
              const request = await collectionService.createRequest(
                collection,
                userId
              );

              if (request) {
                handleReload();
                return true;
              }

              return false;
            }}
          />
          <span
            className="add-button"
            onClick={() =>
              openModal("add-profiles", {
                onChange: async (profileIdsToAdd: string[]) => {
                  if (collection) {
                    const response:
                      | BookingRequestModel[]
                      | null = await collectionService.batchCreateRequests(
                      collection,
                      profileIdsToAdd
                    );

                    if (response) {
                      setSnackbar({
                        message: "Travelers added!",
                        variant: "success"
                      });

                      handleReload();
                    } else {
                      setSnackbar({
                        message:
                          "There was a problem and the travelers were not added",
                        variant: "error"
                      });
                    }
                  }
                }
              })
            }
          >
            <AppIconFrame color="product-background-blue" shape="square">
              <AppIcon type="add-users" />
            </AppIconFrame>
          </span>
        </div>

        <div>
          <KeywordSearchInput
            shrink={true}
            shrinkDirection="right"
            onChange={(keyword: string, keywordFilter: StackFilter) =>
              setKeywordFilter(keywordFilter)
            }
          />
          <FilterMenu filters={Filters} onChange={setStackFilters} />
          <SortMenu
            defaultValue={stackSort}
            sorts={Sorts}
            onChange={setStackSort}
          />
          <GroupMenu sections={GroupSections} onChange={setStackGroup} />
        </div>
      </StackToolbar>
      <div className="stacks">
        {stackGroupResults?.map((result: StackGroupResult, index: number) => {
          const { data, value } = result;

          return (
            <CollectionRequestStack
              emptyMessage={emptyMessage}
              filters={stackFilters || []}
              onChange={() => handleReload()}
              requests={data}
              sort={stackSort}
              title={getStackTitle(value)}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
