import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { attach as RaxAttach } from "retry-axios";
import { inject } from "inversify";
import env from "../utils/env";
import BaseService from "./base.service";
import {
  AuthPair,
  IAuthStorageService,
  AuthStorageService
} from "./auth-storage.service";
import {
  IImpersonateService,
  ImpersonateService
} from "../services/impersonate.service";

export interface IApiService {
  get(uri: string, qsParams?: any): Promise<any>;

  post(uri: string, data?: any): Promise<any>;

  put(uri: string, data?: any): Promise<any>;

  delete(uri: string, data?: any): Promise<any>;

  request(method: "GET" | "POST", uri: string, data: any): Promise<any>;

  getAuthHeaders(): any;
}

export class ApiService extends BaseService implements IApiService {
  private baseUrl: string;

  @inject(AuthStorageService)
  authStorageService!: IAuthStorageService;

  @inject(ImpersonateService)
  impersonateService!: IImpersonateService;

  constructor() {
    super();

    this.baseUrl = env.api;
  }

  async get(uri: string, qsParams?: any): Promise<any> {
    return this.request("GET", uri, qsParams);
  }

  async post(uri: string, data?: any): Promise<any> {
    return this.request("POST", uri, data);
  }

  async put(uri: string, data?: any): Promise<any> {
    return this.request("PUT", uri, data);
  }

  delete(uri: string, data?: any): Promise<any> {
    return this.request("DELETE", uri, data);
  }

  async request(
    method: "GET" | "POST" | "PUT" | "DELETE",
    uri: string,
    data?: any
  ): Promise<any> {
    const url = `${this.baseUrl}${uri}`;

    const axiosInst = axios.create();

    // attach auth headers via interceptor to ensure that retried requests
    // have fresh credentials
    axiosInst.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers = this.getAuthHeaders();
      return config;
    });

    const requestOpts: AxiosRequestConfig = {
      method,
      url,
      timeout: 2 * 60 * 1000 // 2 mins
    };

    if (data) {
      const optsKey = method === "GET" ? "params" : "data";
      requestOpts[optsKey] = data;
    }

    // issue-83, authenticated requests can fail if the client is
    // in the middle of renewing their auth token
    requestOpts.raxConfig = {
      instance: axiosInst,
      retry: 4,
      noResponseRetries: 4,
      retryDelay: 1500,
      backoffType: "static",
      statusCodesToRetry: [[401, 401]],
      httpMethodsToRetry: ["GET", "HEAD", "DELETE", "PUT", "POST"],
      onRetryAttempt: (err: any) => {
        console.warn(`retrying request ${method} ${uri}`);
      }
    };

    RaxAttach(axiosInst);

    try {
      const response: AxiosResponse = await axiosInst(requestOpts);

      if (response) {
        return response.data;
      }
    } catch (err) {
      console.error(err);

      // stored auth credentials seem to be invalid, trigger a logout
      const requestHasAuth = err?.response?.config?.headers?.Authorization;

      if (requestHasAuth && err?.response?.status === 401) {
        window.location.href = "/logout";
      }
    }
  }

  getAuthHeaders(): any {
    const headers: any = {};

    const authPair: AuthPair = this.authStorageService.get();

    if (!authPair.isValid()) {
      return headers;
    }

    headers.Authorization = `Bearer ${authPair.token}`;

    const impersonationId = this.impersonateService.getId();
    if (impersonationId) {
      headers.impersonate = impersonationId;
    }

    return headers;
  }
}
