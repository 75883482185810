import React, { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from "@material-ui/core/FormControl";
import Popper from "@material-ui/core/Popper";

import Button from "../../../button";
import { ObjectHash } from "../../../../utils/helpers";
import UserModel from "../../../../models/user.model";
import Tooltip from "../../../tooltip";
import TravelerInput from "../../../form/inputs/TravelerInput";

import "./user-menu.scss";

interface Props {
  data: any[];
  onChangeTableBlockUsers: CallableFunction;
  onResetCustomDataOrder: CallableFunction;
  tagId: string;
}

export default function UserMenu(props: Props) {
  const { data, onChangeTableBlockUsers, tagId } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const popperClass = open ? "user-popper user-popper--open" : "user-popper";

  const handleClick = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleChangeUsers = (userIds: any[]) => {
    onChangeTableBlockUsers(userIds);
  };

  return (
    <div className="user-menu">
      <Tooltip text="Add Profiles">
        <div onClick={handleClick}>
          <Button
            color="dark-gray"
            icon="add-users"
            isRippleDisabled={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
      <Popper
        id={`user-popper-${tagId}`}
        className={popperClass}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="user-popper__content">
            <h1>Add Profiles</h1>
            <form>
              <FormControl>
                <TravelerInput
                  autoFocus={true}
                  defaultValue={data.map((userData: ObjectHash) => ({
                    label: userData.name,
                    value: userData.id,
                    meta: new UserModel(userData)
                  }))}
                  isCreatable={true}
                  onChange={(userIds: any[]) => handleChangeUsers(userIds)}
                  label="Profiles"
                />
              </FormControl>
            </form>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
