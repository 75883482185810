import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { resolve } from "inversify-react";
import { difference, startCase } from "lodash";

import { AppContext, AppSettings } from "../../context";
import { withModalContext } from "../../context/modal";
import { withSnackbarContext } from "../../context/snackbar";
import {
  batchDeleteObject,
  batchDuplicateObject
} from "./table-funcs/helper-funcs";
import { GridService, IGridService } from "../../services/grid.service";
import { TagService, ITagService } from "../../services/tag.service";
import GridModel from "../../models/grid.model";
import TagModel from "../../models/tag.model";

import Button from "../button";
import BatchTagsMenu from "./table-actions/batch-tags-menu";
import GridAddMenu from "./table-actions/grid-add-menu";
import Tooltip from "../tooltip";

interface Props extends RouteComponentProps {
  displayData: any[];
  doSearch: CallableFunction;
  grid?: GridModel;
  hasTravel?: boolean;
  idColIndex: number;
  onDelete?: CallableFunction;
  openModal: CallableFunction;
  resource: string;
  results: any[];
  selectedRows: any[];
  setSnackbar: CallableFunction;
  setState: CallableFunction;
  tagId: string;
  tableTitle: string;
  settings: AppSettings;
}

interface State {}

class CustomToolbarSelect extends React.Component<Props, State> {
  @resolve(TagService)
  tagService!: ITagService;

  @resolve(GridService)
  gridService!: IGridService;

  clearSelectedRows() {
    this.props.setState({
      selectedRows: []
    });
  }

  getDataIds = (rowsIndexes: any[]) =>
    rowsIndexes.map(
      (rowIndex) => this.props.displayData[rowIndex][this.props.idColIndex]
    );

  handleClickArchiveSelected = () => {
    const { resource, results, selectedRows, setSnackbar } = this.props;

    const dataIds = this.getDataIds(selectedRows);

    const updateActions: any[] = dataIds.map((dataId) => {
      const data = results.find((row) => row.id === dataId);

      if (resource === "grids") {
        data.archived = !data.archived;
        return this.gridService.update(data);
      }

      return null;
    });

    this.clearSelectedRows();
    Promise.all(updateActions).then(() => {
      setSnackbar({
        message: "Archive status updated!",
        variant: "success"
      });
    });
  };

  handleClickDeleteSelected = () => {
    const {
      doSearch,
      onDelete,
      openModal,
      resource,
      results,
      selectedRows,
      setSnackbar
    } = this.props;

    // Will the current page be empty after this delete is successful?
    const pageEmpty = results.length === selectedRows.length;

    const dataIds = this.getDataIds(selectedRows);
    const payload = { resource, ids: dataIds };
    batchDeleteObject({ ...payload, setSnackbar, openModal }).then(() => {
      if (doSearch instanceof Function) {
        doSearch();
      }
      this.clearSelectedRows();

      onDelete && onDelete(pageEmpty);
    });
  };

  handleClickDeleteGridTravelers = async () => {
    const { doSearch, grid, selectedRows } = this.props;
    if (!grid) {
      return;
    }
    const dataIds = this.getDataIds(selectedRows);
    grid.travelers = difference(grid.travelers, dataIds);

    await this.gridService.update(grid);

    this.clearSelectedRows();
    if (doSearch instanceof Function) {
      doSearch();
    }
  };

  handleClickDuplicateObject = () => {
    const { doSearch, resource, selectedRows, setSnackbar } = this.props;
    const dataIds = this.getDataIds(selectedRows);
    const payload = {
      resource,
      ids: dataIds
    };
    batchDuplicateObject({ ...payload, setSnackbar: setSnackbar }).then(() => {
      if (doSearch instanceof Function) {
        doSearch();
      }
      this.clearSelectedRows();
    });
  };

  handleClickApplyGridTag = async (grid: GridModel) => {
    const { resource, selectedRows, setSnackbar } = this.props;
    const dataIds = this.getDataIds(selectedRows);

    const response = await this.tagService.batchAddTags(
      [grid.tag.id],
      resource,
      dataIds
    );

    if (response) {
      setSnackbar({
        message: `The trips where added to the Grid`,
        variant: "success"
      });
    }
  };

  handleClickApplyTags = async (tagActions: any) => {
    const { resource, selectedRows, setSnackbar } = this.props;
    const dataIds = this.getDataIds(selectedRows);

    const addTagIds = tagActions.add.map((tag: TagModel) => tag.id);
    let tagsAdded = true;
    if (addTagIds.length) {
      tagsAdded = await this.tagService.batchAddTags(
        addTagIds,
        resource,
        dataIds
      );
    }

    const removeTagIds = tagActions.remove.map((tag: TagModel) => tag.id);
    let tagsRemoved = true;
    if (removeTagIds.length) {
      tagsRemoved = await this.tagService.batchRemoveTags(
        removeTagIds,
        resource,
        dataIds
      );
    }

    if (!addTagIds.length && !removeTagIds.length) {
      return;
    }

    if (tagsAdded && tagsRemoved) {
      setSnackbar({
        message: `Tag changes applied!`,
        variant: "success"
      });
    }

    this.clearSelectedRows();
  };

  render() {
    return (
      <AppContext.Consumer>
        {(appState) => {
          const {
            doSearch,
            grid,
            openModal,
            results,
            resource,
            selectedRows,
            setState,
            tagId,
            tableTitle,
            settings
          } = this.props;

          // @todo all this conditional logic should be removed to separate components, or props
          // the component shouldn't need to know about company settings, location, table title, etc.
          const isGridTravelersView = grid && resource === "travelers";
          let travelersHaveTravel = false;
          selectedRows.forEach((rowIndex: number) => {
            if (!travelersHaveTravel) {
              const row = results[rowIndex];
              if (
                row &&
                (row.hasAccommodation ||
                  row.hasInbound ||
                  row.hasOutbound ||
                  row.hasTransportation)
              ) {
                travelersHaveTravel = true;
              }
            }
          });

          const isGridTravelerDeletable =
            isGridTravelersView && !travelersHaveTravel;

          const isArchivable = ["grids"].includes(resource);

          const isArchivedView =
            tableTitle === `Archived ${startCase(resource)}`;

          const archiveLabel = isArchivedView ? "Unarchive" : "Archive";

          let isTravelerMergeable = false;

          if (
            ["travelers", "users"].includes(resource) &&
            selectedRows.length === 2
          ) {
            isTravelerMergeable = true;
          }

          const isNFL = appState.user.email?.endsWith("@nfl.com");
          const isDeletable =
            !isNFL &&
            (["trips", "documents"].includes(resource) ||
              (resource === "grids" &&
                selectedRows.every((rowIndex) => results[rowIndex]?.archived))); // don't allow non-archived grids to be deleted

          const isDuplicable =
            ["trips", "documents"].includes(resource) && !isNFL;

          const isTaggable = ["trips", "users"].includes(resource);
          const canAddToGrid = false; // @todo revisit this feature and probably remove resource === "trips" && company?.features.grids;

          return (
            <div className="custom-toolbar-select">
              {isArchivable && (
                <Tooltip text={`${archiveLabel} selected`}>
                  <Button
                    color="black"
                    icon={isArchivedView ? "unarchive" : "archive"}
                    isRippleDisabled={true}
                    isTransparent={true}
                    onClick={this.handleClickArchiveSelected}
                    size="medium"
                  />
                </Tooltip>
              )}
              {isDuplicable && (
                <Tooltip text="Duplicate selected">
                  <Button
                    color="black"
                    icon="file-copy"
                    isRippleDisabled={true}
                    isTransparent={true}
                    onClick={() =>
                      openModal("confirm", {
                        buttonText: "Duplicate",
                        dialogTitle: `Duplicate ${startCase(resource)}`,
                        dialogBody: `Are you sure you want to duplicate these ${resource}?`,
                        onConfirm: () => {
                          this.handleClickDuplicateObject();
                        }
                      })
                    }
                    size="medium"
                  />
                </Tooltip>
              )}
              {canAddToGrid && (
                <GridAddMenu onChange={this.handleClickApplyGridTag} />
              )}
              {isTaggable && (
                <BatchTagsMenu
                  tagGroups={settings.tagGroups}
                  onClickApplyTags={this.handleClickApplyTags}
                />
              )}
              {isDeletable && (
                <Tooltip text="Delete selected">
                  <Button
                    color="black"
                    icon="trash"
                    isRippleDisabled={true}
                    isTransparent={true}
                    onClick={this.handleClickDeleteSelected}
                    size="medium"
                  />
                </Tooltip>
              )}
              {isTravelerMergeable && (
                <Tooltip text="Merge Travelers">
                  <Button
                    color="black"
                    icon="merge"
                    isRippleDisabled={true}
                    isTransparent={true}
                    size="medium"
                    onClick={() => {
                      openModal("merge-profiles", {
                        onMerge: () => {
                          setState({
                            selectedRows: []
                          });
                          doSearch();
                        },
                        resource,
                        selectedUsers: this.getDataIds(selectedRows).map((id) =>
                          results.find((user) => user.id === id)
                        ),
                        tagId
                      });
                    }}
                  />
                </Tooltip>
              )}
              {isGridTravelerDeletable && (
                <Tooltip text="Remove traveler from grid">
                  <Button
                    color="black"
                    icon="trash"
                    isRippleDisabled={true}
                    isTransparent={true}
                    onClick={this.handleClickDeleteGridTravelers}
                    size="medium"
                  />
                </Tooltip>
              )}
            </div>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default withRouter(
  withModalContext(withSnackbarContext(CustomToolbarSelect))
);
