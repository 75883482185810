import React, { FC, ReactNode, useContext, useEffect, useReducer } from "react";
import { TravelContext, TravelState } from ".";
import { TravelReducer, TravelActionTypes } from "./reducer";

import AppContainer from "../../container";
import BookingRequestModel from "../../models/booking-request.model";
import {
  IntegrationService,
  IIntegrationService
} from "../../services/integration.service";
import {
  WebsocketService,
  IWebsocketService
} from "../../services/websocket.service";

interface TravelProviderProps {
  children: ReactNode;
}

export const TravelProvider: FC<TravelProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(TravelReducer, TravelState);

  const integrationService: IIntegrationService = AppContainer.get(
    IntegrationService
  );
  const websocketService: IWebsocketService = AppContainer.get(
    WebsocketService
  );

  useEffect(() => {
    if (state.subscribed) {
      return;
    }

    websocketService.getConnectPub().subscribe((connected: boolean) => {
      if (!connected) {
        return;
      }

      websocketService.subscribe(
        "IntegrationInstanceUpdates",
        async (data: any) => {
          if (!data) {
            return;
          }
          // websocket returns array of integration objects on initial subscription, or single object on updates
          const integrationUpdates = Array.isArray(data) ? data : [data];
          const newIntegrationInstances = state.integrationInstances;

          integrationUpdates.forEach((update) => {
            newIntegrationInstances[update.id] = update;
          });

          Object.entries(newIntegrationInstances).forEach(
            ([instanceId, instance]: any) => {
              if (
                instance.deleted ||
                instance.cleared ||
                !["MAILGUN", "TRAXO"].includes(instance.integration)
              ) {
                // removes integration instances that don't pertain to parsing, were deleted or dismissed
                delete newIntegrationInstances[instanceId];
              }
            }
          );

          const newParsedEmails = await integrationService.getParsedEmails(
            Object.values(newIntegrationInstances)
          );

          dispatch({
            type: TravelActionTypes.IntegrationInstances,
            payload: {
              parsedEmails: newParsedEmails,
              integrationInstances: newIntegrationInstances
            }
          });
        }
      );

      websocketService.subscribe(
        "BookingRequestUpdates",
        async (data: any, initial?: boolean) => {
          dispatch({
            type: TravelActionTypes.UpdateBookingRequest,
            payload: new BookingRequestModel(data)
          });
        }
      );
    });
  }, [dispatch, integrationService, state, websocketService]);

  return (
    <TravelContext.Provider
      value={{
        ...state,
        dispatch
      }}
    >
      {children}
    </TravelContext.Provider>
  );
};

// wrap class components with this to access the useContext hook functionality
export const withTravelContext = (Component: any) => {
  return (props: any) => {
    const { parsedEmails } = useContext(TravelContext);

    return <Component parsedEmails={parsedEmails} {...props} />;
  };
};
