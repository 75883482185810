import { ObjectHash } from "../utils/helpers";
import BaseModel from "./base.model";
import {
  TravelFilterFields,
  TravelFilterResources
} from "./travel-filter.model";

export class TravelQueryParam extends BaseModel {
  field: TravelFilterFields;
  resource: TravelFilterResources;
  value: any; // @todo stronger type here
  position: number;
  article: "is" | "is not"; // is not, contains, does not contain
  connect: "and" | "or";
  meta?: ObjectHash;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.field = data.field;
    this.resource = data.resource ?? TravelFilterResources.Trip;
    this.value = data.value;
    this.position = data.position;
    this.article = data.article ?? "is";
    this.connect = data.connect ?? "and";

    if (data.meta) {
      this.meta = data.meta;
    }
  }
}

export default class TravelQueryModel extends BaseModel {
  limit: number;
  page: number;
  order: string;

  // when order != null, false = ASC, true = DESC for IndexDbService sorting
  reverse: boolean;

  fields: string[];
  params: TravelQueryParam[];

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.limit = data.limit ?? 10;
    this.page = data.page ?? 1;
    this.order = data.order;
    this.reverse = Boolean(data.reverse);

    /*
     * @todo remove both this and the SearchService::parseInput defaults for order/reverse
     * The following defaults for order and reverse duplicate the defaults in SearchService::parseInput. These 2 default
     * sets MUST match, otherwise the TG REST API will apply a default order when querying the db, but that order will
     * not be reflected when client-side sorting happens in IndexDBService::find
     */
    if (!this.order) {
      this.order = "createdAt";
      this.reverse = true;
    }

    this.fields = data.fields ?? [];
    this.params = data.params
      ? data.params.map((param: ObjectHash) => new TravelQueryParam(param))
      : [];
  }
}
