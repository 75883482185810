// This component is used with react router dom to scroll to the top of the page when switching routes
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  // tries to scroll app-page or window to top whenever path changes from react router dom
  useEffect(() => {
    const scrollContainer = document.getElementById("app-page") || window;
    scrollContainer.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
