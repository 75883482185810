import { inject } from "inversify";
import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import TagModel from "../models/tag.model";
import TagGroupModel from "../models/tag-group.model";
import { sortByPosition } from "../utils/helpers";
import * as _ from "lodash";

export interface ITagService {
  getCurrentTagGroups(): Promise<TagGroupModel[]>;

  batchAddTags(
    tagIds: string[],
    resource: string,
    resourceIds: string[]
  ): Promise<boolean>;

  batchRemoveTags(
    tagIds: string[],
    resource: string,
    resourceIds: string[]
  ): Promise<boolean>;

  addGroup(tagGroup: TagGroupModel): Promise<TagGroupModel | null>;

  updateGroup(tagGroup: TagGroupModel): Promise<TagGroupModel | null>;

  deleteGroup(tagGroupId: string): Promise<TagGroupModel | null>;

  mapIdentifiersColumnIds(
    columnIds: string[],
    tagGroups: TagGroupModel[]
  ): string[];

  getPresetChanges(
    tagGroup: TagGroupModel,
    data: string,
    tagGroups: TagGroupModel[]
  ): { tagGroup: TagGroupModel; tagIds: string[] }[];

  getNewTagColor(tags: TagModel[]): string;
}

export class TagService extends BaseService implements ITagService {
  @inject(ApiService)
  private apiService!: IApiService;

  async getCurrentTagGroups(): Promise<TagGroupModel[]> {
    const response = await this.apiService.get("/tag-groups");

    if (!response) {
      return [];
    }

    return response
      .map((data: any) => new TagGroupModel(data))
      .sort(sortByPosition);
  }

  async addGroup(tagGroup: TagGroupModel): Promise<TagGroupModel | null> {
    const response = await this.apiService.post(
      "/tag-groups",
      tagGroup.toJSON()
    );

    if (!response) {
      return null;
    }

    return new TagGroupModel(response);
  }

  async updateGroup(tagGroup: TagGroupModel): Promise<TagGroupModel | null> {
    const response = await this.apiService.put(
      `/tag-groups/${tagGroup.id}`,
      tagGroup.toJSON()
    );

    if (!response) {
      return null;
    }

    return new TagGroupModel(response);
  }

  async deleteGroup(tagGroupId: string): Promise<TagGroupModel | null> {
    const response = await this.apiService.delete(`/tag-groups/${tagGroupId}`);

    if (!response) {
      return null;
    }

    return new TagGroupModel(response);
  }

  async batchAddTags(
    tagIds: string[],
    resource: string,
    resourceIds: string[]
  ): Promise<boolean> {
    const response = this.apiService.put(`/batch/${resource}/tag`, {
      toAdd: tagIds,
      ids: resourceIds
    });

    if (!response) {
      return false;
    }

    return true;
  }

  async batchRemoveTags(
    tagIds: string[],
    resource: string,
    resourceIds: string[]
  ): Promise<boolean> {
    const response = this.apiService.put(`/batch/${resource}/tag`, {
      toRemove: tagIds,
      ids: resourceIds
    });

    if (!response) {
      return false;
    }

    return true;
  }

  mapIdentifiersColumnIds(
    columnIds: string[],
    tagGroups: TagGroupModel[]
  ): string[] {
    const customKeyMap: Map<string, TagGroupModel> = new Map();
    tagGroups.forEach((tagGroup: TagGroupModel) => {
      if (tagGroup.customKey) {
        customKeyMap.set(tagGroup.customKey, tagGroup);
      }
    });

    return _.uniq(
      columnIds.map((columnId: string) => {
        const tagGroup = customKeyMap.get(columnId);

        if (tagGroup) {
          return tagGroup.getFieldId();
        }

        return columnId;
      })
    );
  }

  getPresetChanges(
    tagGroup: TagGroupModel,
    data: string,
    tagGroups: TagGroupModel[]
  ): { tagGroup: TagGroupModel; tagIds: string[] }[] {
    const tagUpdates: { tagGroup: TagGroupModel; tagIds: string[] }[] = [];

    if (!tagGroup.hasPreset()) {
      return tagUpdates;
    }

    const tagGroupPreset = tagGroup.getPreset();

    const fieldIndex = tagGroupPreset.format.indexOf(
      tagGroup.getIdentifierId()
    );

    if (fieldIndex === -1) {
      return tagUpdates;
    }

    // the updated tag name matches one of the presets
    const matchingPreset = tagGroupPreset.values.find(
      (preset: string[]) => preset[fieldIndex] === data
    );

    if (matchingPreset) {
      tagGroupPreset.format.forEach((valueFormat: string, index: number) => {
        if (index !== fieldIndex) {
          const tagGroup = tagGroups.find(
            (tagGroup: TagGroupModel) =>
              tagGroup.getIdentifierId() === valueFormat
          );

          if (tagGroup) {
            const presetTagName = matchingPreset[index];
            const tagIds = tagGroup.tags
              .filter((tag: TagModel) => tag.name === presetTagName)
              .map((tag: TagModel) => tag.id);

            if (tagIds.length) {
              tagUpdates.push({ tagGroup, tagIds });
            }
          }
        }
      });
    }

    return tagUpdates;
  }

  /*
   * Pick a color for a new tag at random, and prefer colors
   * which are not currently in use in a group of tags.
   */
  getNewTagColor(tags: TagModel[]): string {
    const allColors = TagModel.getColors();
    const currentColors = tags.map((tag: TagModel) => tag.color);
    const notInUseColors = _.difference(allColors, currentColors);
    const newColors = notInUseColors.length ? notInUseColors : allColors;
    const newColorIndex = _.random(0, newColors.length - 1);
    const newColor = newColors[newColorIndex];
    return newColor;
  }
}
