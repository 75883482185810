import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import { getSelectValueFromOptions } from "../../../utils/helpers";
import AutocompleteInputFilled from "./AutocompleteInputFilled";

interface Props {
  defaultValue?: any;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  value?: any;
}

export default function CountryInput(props: Props) {
  const countryOpts = useMemo(() => countryList().getData(), []);
  const valueProps = getSelectValueFromOptions(props, countryOpts);
  const acProps = { ...props, ...valueProps };

  return <AutocompleteInputFilled {...acProps} options={countryOpts} />;
}
