import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { resolve } from "inversify-react";

import Button from "@material-ui/core/Button";

import AppIcon from "../../../../components/app-icon";
import { AppPageProps } from "../../../../router";

import {
  DocumentService,
  IDocumentService
} from "../../../../services/document.service";

import DocumentModel from "../../../../models/document.model";

import "../../settings-page.scss";
import { withModalContext } from "../../../../context/modal";

interface Props extends AppPageProps, RouteComponentProps {
  openModal: CallableFunction;
}

interface State {
  templates: any[];
}

class TemplatesPage extends React.Component<Props, State> {
  @resolve(DocumentService)
  documentService!: IDocumentService;

  constructor(props: Props) {
    super(props);

    this.state = {
      templates: []
    };
  }

  async componentDidMount() {
    const response = await this.documentService.getTemplates();
    this.setState({ templates: response });
  }

  render() {
    const { history, openModal } = this.props;
    const { templates } = this.state;

    return (
      <div className="settings-subpage">
        <div className="settings-page__section-wrapper">
          <h2 className="settings-page__sub-header">Document Templates</h2>
          {templates.map((template) => (
            <Button
              key={template.id}
              className="settings-page__button-link settings-page__button-link--square"
              disableRipple={true}
              onClick={() => {
                history.push(`/documents/${template.id}/build`);
              }}
            >
              {template.name}
            </Button>
          ))}
          <Button
            className="settings-page__button-link settings-page__button-link--blue settings-page__button-link--square"
            disableRipple={true}
            onClick={() =>
              openModal("edit-document", {
                onAddDocument: async (data: any) => {
                  const newTemplate: DocumentModel | null = await this.documentService.create(
                    {
                      ...data,
                      isTemplate: true
                    }
                  );
                  if (newTemplate) {
                    history.push(`/documents/${newTemplate.id}/build`);
                  }
                },
                target: "template"
              })
            }
          >
            Create New Template
          </Button>
        </div>
        <div className="settings-page__section-wrapper">
          <h2 className="settings-page__sub-header">Travel Data Fields</h2>

          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/templates/profile");
            }}
          >
            <AppIcon type="contacts" color="yellow" />
            Configure Traveler Profiles
          </Button>

          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/templates/flight");
            }}
          >
            <AppIcon type="airplane-mode" color="product-blue" />
            Configure Flights
          </Button>

          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/templates/transportation");
            }}
          >
            <AppIcon type="car" color="purple" />
            Configure Transportation
          </Button>

          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/templates/accommodation");
            }}
          >
            <AppIcon type="hotel" color="green" />
            Configure Accommodations
          </Button>

          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/templates/rail");
            }}
          >
            <AppIcon type="train" color="orange" />
            Configure Rail
          </Button>

          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/templates/event");
            }}
          >
            <AppIcon type="event" color="red" />
            Configure Events
          </Button>
        </div>
        <div className="settings-page__section-wrapper">
          <h2 className="settings-page__sub-header">Tag Groups</h2>
          <Button
            className="settings-page__button-link"
            disableRipple={true}
            onClick={() => {
              history.push("/settings/tags");
            }}
          >
            <AppIcon type="tag" color="black" />
            Configure Tags
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(withModalContext(TemplatesPage));
