import BaseModel from "./base.model";
import { ObjectHash } from "../utils/helpers";

export default class AirportModel extends BaseModel {
  id: string;

  city: string;
  iata: string;
  name: string;
  timezoneRegionName: string;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;

    this.city = data.city;
    this.iata = data.iata;
    this.name = data.name;
    this.timezoneRegionName = data.timezoneRegionName;
  }

  getFullName(): string {
    if (this.name && this.iata) {
      return `(${this.iata}) ${this.city}`;
    }

    return this.iata || this.city || this.name;
  }
}
