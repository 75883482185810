import React from "react";

import ParsedEmail from "../parsed-email";
import ParsedEmailModel from "../../models/parsed-email.model";

import useTravel from "../../hooks/use-travel.hook";

import "./parsing-emails.scss";

interface Props {
  gridId?: string;
}

export default function ParsingEmails(props: Props) {
  const { gridId } = props;

  let emails = useTravel().parsedEmails.filter(
    (email) =>
      new Date().getTime() - new Date(email.updatedAt).getTime() <
      24 * 60 * 60 * 1000
  );
  if (gridId) {
    emails = emails.filter((email) => email.gridId === gridId);
  }

  if (!emails.length) {
    return null;
  }

  return (
    <div className="parsing-emails">
      <div className="parsing-emails-wrapper">
        <span className="parsing-emails__title">
          Parsed Confirmation Emails
          <span className="parsing-emails__results">( {emails.length} )</span>
        </span>
        {emails.map((email: ParsedEmailModel) => (
          <ParsedEmail
            key={`parsed-email-${email.id}`}
            email={email}
            gridId={gridId}
          />
        ))}
      </div>
    </div>
  );
}
