import React from "react";
import { debounce } from "lodash";
import AsyncCreatableSelect from "react-select/async-creatable";
import Loader from "../../loader";

import AppContainer from "../../../container";
import {
  FlightService,
  IFlightService
} from "../../../services/flight.service";
import {
  getSelectValueProps,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  closeMenuOnScroll?: boolean;
  disabledOpts: string[];
  isRequired?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  value?: string;
}

export default function AirportAsyncCreatableInput(props: Props) {
  const flightService: IFlightService = AppContainer.get(FlightService);

  const { menu, menuPortal, option } = customStyle;

  const {
    closeMenuOnScroll,
    disabledOpts = [],
    isRequired,
    label,
    menuPortalTarget,
    onChange
  } = props;

  const getOptions = (inputValue: string, callback: CallableFunction) => {
    if (!inputValue) {
      return Promise.resolve([] as SelectOption[]);
    }

    const filterAirports = debounce(
      (inputValue: string, callback: CallableFunction) => {
        flightService.airportSearch(inputValue).then((response: ObjectHash) => {
          const options = response
            .map((airport: ObjectHash) => ({
              label: `(${airport.iata}) ${airport.city}`,
              value: `(${airport.iata}) ${airport.city}`,
              meta: airport
            }))
            .filter(
              (option: ObjectHash) => !disabledOpts.includes(option.value.value)
            );
          return callback(options);
        });
      },
      300
    );

    return filterAirports(inputValue, callback);
  };

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu,
    menuPortal,
    option
  };

  return (
    <div className="autocomplete-input--filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncCreatableSelect
        {...getSelectValueProps({ ...props, isSearchable: true })}
        cacheOptions
        className="autocomplete-input autocomplete-input--async"
        closeMenuOnScroll={closeMenuOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        formatCreateLabel={(label) => `Use custom "${label}"`}
        isSearchable={true}
        loadOptions={getOptions}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No airports found" : null
        }
        onChange={(option: any) => onChange(option.value, option.meta)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
