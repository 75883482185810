import { inject } from "inversify";
import BaseService from "./base.service";
import {
  LocalStorageService,
  ILocalStorageService
} from "./local-storage.service";

export interface IImpersonateService {
  activate(userId: string): boolean;

  deactivate(): boolean;

  getId(): string;

  isActive(): boolean;
}

export class ImpersonateService extends BaseService
  implements IImpersonateService {
  @inject(LocalStorageService)
  private localStorageService!: ILocalStorageService;

  activate(userId: string): boolean {
    this.localStorageService.set("impersonation_id", userId);
    return true;
  }

  deactivate(): boolean {
    this.localStorageService.remove("impersonation_id");
    return true;
  }

  getId(): string {
    return this.localStorageService.get("impersonation_id") ?? "";
  }

  isActive(): boolean {
    return Boolean(this.getId());
  }
}
