import React, { useEffect, useState } from "react";
import classnames from "classnames";
import "react-dates/initialize";
import moment, { Moment } from "moment";
import { uniqueId } from "lodash";
import usePrevious from "../../hooks/use-previous.hook";

import AppIcon from "../app-icon";
import { DateRangePicker } from "react-dates";

import "react-dates/lib/css/_datepicker.css";
import "./date-range-select.scss";

export interface Props {
  anchorDirection?: "left" | "right";
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange: (dates: [string, string]) => void;
  onValidate?: CallableFunction;
  value?: string[];
}

interface DateArrowProps {
  back: boolean;
  onClick: CallableFunction;
  start: boolean;
}

const DateArrow = ({ back, start, onClick }: DateArrowProps) => (
  <div
    className={classnames("date-arrow", { start, back })}
    onClick={() => onClick(start, back)}
  >
    <AppIcon type={back ? "nav-arrow-left" : "nav-arrow-right"} size="small" />
  </div>
);

export default function DateRangeSelect(props: Props) {
  const {
    anchorDirection = "right",
    isDisabled,
    onChange,
    onValidate,
    value
  } = props;

  const [focusedInput, setFocusedInput] = useState<
    null | "startDate" | "endDate"
  >(null);

  const defaultStartDate = value?.length ? value[0] : "";
  const defaultEndDate = value && value.length > 1 ? value[1] : "";
  const [startDate, setStartDate] = useState<string>(defaultStartDate ?? "");
  const [endDate, setEndDate] = useState<string>(defaultEndDate ?? "");
  const [inputId] = useState<string>(`date-range-select-${uniqueId()}`);

  const getDateStr = (m: Moment) => (m ? m.format("YYYY-MM-DD") : "");

  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);

  useEffect(() => {
    if (previousStartDate === undefined || previousEndDate === undefined) {
      return;
    }

    if (previousStartDate === startDate && previousEndDate === endDate) {
      return;
    }

    const updatedDates = [startDate, endDate].map((date: string) => {
      const mDate = moment(date);
      if (!mDate.isValid()) {
        return "";
      }
      return getDateStr(mDate);
    });

    onChange([...updatedDates] as [string, string]);

    onValidate && onValidate([...updatedDates]);
  }, [
    endDate,
    onChange,
    onValidate,
    previousEndDate,
    previousStartDate,
    startDate,
    setEndDate,
    setStartDate
  ]);

  const handleChange = ({ startDate, endDate }: any) => {
    setStartDate(getDateStr(startDate));
    setEndDate(getDateStr(endDate));
  };

  const handleClick = (start: boolean, back: boolean) => {
    if (isDisabled) {
      return;
    }
    const mStartDate = startDate ? moment(startDate) : null;
    const mEndDate = endDate ? moment(endDate) : null;

    if (start && mStartDate?.isValid()) {
      const updatedDate = back
        ? mStartDate.subtract(1, "days")
        : mStartDate.add(1, "days");

      // start date cannot be >= end date
      if (mEndDate?.isValid() && updatedDate.isSameOrAfter(mEndDate)) {
        return;
      }

      setStartDate(getDateStr(updatedDate));
    }

    if (!start && mEndDate?.isValid()) {
      const updatedDate = back
        ? mEndDate.subtract(1, "days")
        : mEndDate.add(1, "days");

      // end date cannot be <= start date
      if (mStartDate?.isValid() && updatedDate.isSameOrBefore(mStartDate)) {
        return;
      }

      setEndDate(getDateStr(updatedDate));
    }
  };

  return (
    <div className={classnames("date-range-select", { disabled: isDisabled })}>
      <DateRangePicker
        anchorDirection={anchorDirection}
        appendToBody
        disabled={isDisabled}
        endDate={endDate ? moment(endDate) : null}
        endDateId={`${inputId}-end-date`}
        focusedInput={focusedInput}
        hideKeyboardShortcutsPanel
        isOutsideRange={() => false}
        navPrev={
          <div className="month-nav prev">
            <AppIcon type="arrow-left" color="dark-gray" />
          </div>
        }
        navNext={
          <div className="month-nav next">
            <AppIcon type="arrow-right" color="dark-gray" />
          </div>
        }
        noBorder
        onDatesChange={handleChange}
        onFocusChange={setFocusedInput}
        renderDayContents={(day: Moment) => (
          <div className="day-circle">
            <span>{day.format("D")}</span>
          </div>
        )}
        startDate={startDate ? moment(startDate) : null}
        startDateId={`${inputId}-start-date`}
      />

      <div className="date-arrows start">
        <DateArrow start={true} back={true} onClick={handleClick} />
        <DateArrow start={true} back={false} onClick={handleClick} />
      </div>

      <div className="date-arrows end">
        <DateArrow start={false} back={true} onClick={handleClick} />
        <DateArrow start={false} back={false} onClick={handleClick} />
      </div>
    </div>
  );
}
