import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AppContainer from "../../../container";
import { GridService, IGridService } from "../../../services/grid.service";
import DocumentModel from "../../../models/document.model";

import GridDocumentsBigTile from "./big-tile";
import Button from "../../button";
import CreateDocumentButton from "../../button/create-document";
import Tile, { TileProps } from "../tile";
import "./tile.scss";
import useModal from "../../../hooks/use-modal.hook";

export default function GridDocumentsTile(props: TileProps) {
  const gridService: IGridService = AppContainer.get(GridService);
  const history = useHistory();
  const { getModalLink, openModalElement } = useModal();

  const { grid } = props;

  const [documents, setDocuments] = useState<DocumentModel[]>([]);

  const loadDocuments = useCallback(() => {
    gridService.getDocuments(grid.id).then(setDocuments);
  }, [grid, gridService]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const bigTileEl = useCallback(
    () => <GridDocumentsBigTile grid={grid} onClose={loadDocuments} />,
    [grid, loadDocuments]
  );

  useEffect(() => {
    if (getModalLink("grid-documents-big-tile")) {
      openModalElement(bigTileEl());
    }
  }, [bigTileEl, getModalLink, openModalElement]);

  return (
    <Tile title="Document Builder" bigTileEl={bigTileEl()}>
      <div className="grid-documents-tile">
        <div className="header">
          <span>Documents</span>
          <CreateDocumentButton
            customButton={
              <Button
                color="product-blue"
                label="Create Document"
                size="small"
              />
            }
            gridId={grid.id}
          />
        </div>
        <div>
          {documents.map((document: DocumentModel) => (
            <div
              key={document.id}
              className="document-link"
              onClick={() => history.push(`/documents/${document.id}/build`)}
            >
              {document.name}
            </div>
          ))}
        </div>
      </div>
    </Tile>
  );
}
