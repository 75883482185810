import React, { useState } from "react";
import Draggable from "react-draggable";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import Button from "../../button";
import EmailInput from "../../form/inputs/EmailInput";
import TextInput from "../../form/inputs/TextInput";

import AppContainer from "../../../container";
import { UserService, IUserService } from "../../../services/user.service";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import useApp from "../../../hooks/use-app.hook";
import useModal from "../../../hooks/use-modal.hook";

import "./travel-planner-dialog.scss";

const PaperComponent = (props: object) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

interface Props {}

export default function TravelPlannerDialog(props: Props) {
  const userService: IUserService = AppContainer.get(UserService);
  const { setSnackbar } = useSnackbar();
  const { company } = useApp();
  const { closeModal } = useModal();

  const zeroState = {
    firstName: "",
    lastName: "",
    email: "",
    role: "admin",
    companyId: company.id
  };

  const [state, setState] = useState({
    ...zeroState
  });

  const handleFieldChange = (key: string, v: string) => {
    setState((oldValues) => ({ ...oldValues, [key]: v }));
  };

  const inviteTravelPlanner = (formState: any) => {
    const payload = { ...formState };
    userService.create(payload).then(() => {
      setSnackbar({
        message: "Invite sent!",
        variant: "success"
      });
    });
    closeModal();
  };

  return (
    <Dialog
      className="travel-planner-dialog__overlay"
      open={true}
      onClose={() => closeModal()}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }}>
        <div className="travel-planner-dialog__title">
          <h1>Invite a Travel Planner</h1>
        </div>
      </DialogTitle>
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <p className="travel-planner-dialog__text">
          Travel planners have full access to the Tripgrid workspace.
        </p>
        <TextInput
          autoFocus={true}
          defaultValue={zeroState.firstName}
          isRequired={true}
          label="First Name"
          onChange={(v: string) => handleFieldChange("firstName", v)}
          variant="filled"
        />
        <TextInput
          defaultValue={zeroState.lastName}
          isRequired={true}
          label="Last Name"
          onChange={(v: string) => handleFieldChange("lastName", v)}
          variant="filled"
        />
        <EmailInput
          defaultValue={zeroState.email}
          isRequired={true}
          label="Email"
          onChange={(v: string) => handleFieldChange("email", v)}
          variant="filled"
        />
      </DialogContent>
      <DialogActions classes={{ root: "dialog-actions" }}>
        <Button
          color="gray"
          isFullWidth={true}
          isRippleDisabled={true}
          isTransparent={true}
          label="Close"
          onClick={() => closeModal()}
          size="medium"
        />
        <Button
          color="product-blue"
          isDisabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email)}
          isFullWidth={true}
          label="Send Invite"
          onClick={() => {
            inviteTravelPlanner(state);
          }}
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
