import React from "react";
import AppContainer from "../../container";
import UserModel from "../../models/user.model";
import { IUserService, UserService } from "../../services/user.service";
import { ObjectHash } from "../../utils/helpers";
import SelectToggleMenu, { SelectOption } from "../select-toggle-menu";
import TravelerChip from "../traveler-chip";

export interface Props {
  isDisabled?: boolean;
  menuPortalTarget?: any;
  menuPosition?: "left" | "right";
  multiSelect?: boolean;
  onChange: (options: SelectOption[]) => void;
  searchPlaceholder?: string;
  selectedUsers?: ObjectHash[];
  users?: ObjectHash[];
  width?: number | string;
}

export default function TravelerSelect(props: Props) {
  const {
    users,
    isDisabled,
    onChange,
    menuPortalTarget,
    menuPosition,
    multiSelect,
    searchPlaceholder,
    selectedUsers,
    width
  } = props;

  const userService: IUserService = AppContainer.get(UserService);

  const search = (keyword: string) => {
    if (users) {
      return new Promise<any>((resolve) => {
        resolve(
          users.filter((option) => option.name.toLowerCase().includes(keyword))
        );
      });
    }
    return userService.search(keyword);
  };

  const selectedOptionIds = selectedUsers?.length
    ? selectedUsers.map((user: ObjectHash) => user.id)
    : [];

  const selectOptions = users || selectedUsers;

  return (
    <SelectToggleMenu
      isDisabled={isDisabled}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      multiSelect={multiSelect}
      onChange={onChange}
      onOption={(option: SelectOption) => (
        <TravelerChip traveler={option as UserModel} />
      )}
      onSearch={search}
      options={selectOptions ? (selectOptions as SelectOption[]) : []}
      placeholder="Select Travelers"
      searchPlaceholder={searchPlaceholder}
      selectedOptionIds={selectedOptionIds}
      width={width}
    />
  );
}
