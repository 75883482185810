import React, { SyntheticEvent } from "react";
import classNames from "classnames";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import { resolve } from "inversify-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { AppPageProps } from "../../router";
import { AppState } from "../../context";
import { ObjectHash } from "../../utils/helpers";
import { withModalContext } from "../../context/modal";
import { withSnackbarContext } from "../../context/snackbar";

import BuilderBlock from "./builder-block";
import DocumentMenuBar from "./document-menu-bar";
import AddBlockMenu from "./builder-actions/add-block-menu";
import FormatBlockMenu from "./builder-actions/format-block-menu";
import MultiBlockMenu from "./builder-actions/multi-block-menu";

import {
  blockTypesHash,
  getBlockTypes,
  Block,
  BlockSubTypes,
  BlockTypes,
  isMultiBlock,
  MultiBlock,
  MultiBlockChildBlock,
  MultiBlockChildTypes,
  MultiBlockSubTypes
} from "./blocks/block-types";
import AttachmentBlock from "./blocks/attachment";
import ImageBlock from "./blocks/image";
import MapBlock from "./blocks/map";
import TextBlock from "./blocks/text";
import Loader from "../../components/loader";

import { ApiService, IApiService } from "../../services/api.service";
import {
  IImpersonateService,
  ImpersonateService
} from "../../services/impersonate.service";
import {
  DocumentService,
  IDocumentService
} from "../../services/document.service";
import { GridService, IGridService } from "../../services/grid.service";
import {
  SearchDbService,
  ISearchDbService
} from "../../services/search-db.service";
import {
  TravelQueryService,
  ITravelQueryService
} from "../../services/travel-query.service";
import {
  WorkspaceService,
  IWorkspaceService
} from "../../services/workspace.service";

import DocumentModel from "../../models/document.model";
import SegmentModel from "../../models/segment.model";
import TagGroupModel from "../../models/tag-group.model";
import TemplateModel from "../../models/template.model";
import TemplatePropertyModel from "../../models/template-property.model";
import TravelerModel from "../../models/traveler.model";
import TravelQueryModel from "../../models/travel-query.model";
import TripModel from "../../models/trip.model";
import UserModel from "../../models/user.model";

import TripgridLetterMark from "../../images/tripgrid-letter-mark.svg";
import "./document-builder-page.scss";

// Block friendly names for delete confirmation
export const blockNameHash: ObjectHash = {};
Object.values(blockTypesHash)
  .flat()
  .forEach((block) => {
    blockNameHash[block.subType] = block.label;
  });

const templateHash: ObjectHash = {
  air: "flight",
  car: "transportation",
  hotel: "accommodation",
  profile: "profile",
  rail: "rail"
};

interface Props extends AppPageProps, RouteComponentProps {
  openModal: CallableFunction;
  setSnackbar: CallableFunction;
}

interface State {
  backLink: string | null;
  blocks: (Block | MultiBlock)[];
  data: ObjectHash;
  deletedBlocks: (Block | MultiBlock)[];
  doc: DocumentModel | null;
  loading: boolean;
  printMode: boolean;
  shareLink: string | null;
  viewMode: boolean;
  writeLock: boolean;
  downloadLock: boolean;
  templates: TemplateModel[];
  shareSettings: ObjectHash | null;
  userSub: ObjectHash | null;
  _dataUpdated: number;
}

class DocumentBuilderPage extends React.Component<Props, State> {
  @resolve(ApiService)
  apiService!: IApiService;

  @resolve(ImpersonateService)
  impersonateService!: IImpersonateService;

  @resolve(DocumentService)
  documentService!: IDocumentService;

  @resolve(GridService)
  gridService!: IGridService;

  @resolve(SearchDbService)
  searchDbService!: ISearchDbService;

  @resolve(TravelQueryService)
  travelQueryService!: ITravelQueryService;

  @resolve(WorkspaceService)
  workspaceService!: IWorkspaceService;

  private autoSaveIntervalId!: NodeJS.Timeout;

  constructor(props: Props) {
    super(props);

    this.state = {
      backLink: null,
      blocks: [],
      data: {},
      deletedBlocks: [],
      doc: null,
      loading: true,
      printMode: false,
      shareLink: null,
      viewMode: false,
      writeLock: true,
      downloadLock: true,
      templates: [],
      shareSettings: null,
      userSub: null,
      _dataUpdated: 0
    };
  }

  async componentDidMount() {
    const { match, setSnackbar } = this.props;
    const { shareHash } = match.params as ObjectHash;

    if (shareHash) {
      this.setState({
        printMode: /\?print=true/.test(window.location.href),
        viewMode: true
      });
    }

    const docStatus = await this.loadDocument();

    if (!docStatus.loaded) {
      setSnackbar({
        message: "The document could not be loaded",
        variant: "error"
      });
      return false;
    }

    this.setState({
      downloadLock: false,
      loading: !docStatus.ready
    });

    if (this.canSaveDocument()) {
      this.setState({ writeLock: false });

      const autoSaveIntervalMs = 1 * (60 * 1000); // 1 min

      clearInterval(this.autoSaveIntervalId);

      this.autoSaveIntervalId = setInterval(() => {
        this.autoSaveDocument();
      }, autoSaveIntervalMs);
    }

    // Merge traveler and confirmation number columns for shared doc
    if (this.state.viewMode) {
      this.setCellSpan();
    }
  }

  componentWillUnmount() {
    const { setSnackbar } = this.props;

    clearTimeout(this.autoSaveIntervalId);

    this.autoSaveDocument();

    setSnackbar({ message: "", variant: "success" });
  }

  setCellSpan = () => {
    const elsToHide = document.getElementsByClassName(
      "table-data--display-none"
    );
    [...elsToHide].forEach((el: Element) => {
      el.closest("td")!.style.display = "none";
    });
    const elsToSpan = document.querySelectorAll(
      "div[class*='table-data--rowspan-']"
    );
    [...elsToSpan].forEach((el: Element) => {
      const rowSpanCount = [...el.classList]
        .find((c) => c.includes("table-data--rowspan-"))!
        .split("rowspan-")[1];
      el.closest("td")!.rowSpan = parseInt(rowSpanCount);
    });
  };

  resetCellSpan = () => {
    const elsToHide = document.getElementsByClassName(
      "table-data--display-none"
    );
    [...elsToHide].forEach((el: Element) => {
      el.closest("td")!.style.display = "table-cell";
    });
    const elsToSpan = document.querySelectorAll(
      "div[class*='table-data--rowspan-']"
    );
    [...elsToSpan].forEach((el: Element) => {
      el.closest("td")!.rowSpan = 1;
    });
  };

  /*
   * Load and save document methods
   */

  // cannot save until the doc is loaded, and not in share mode
  canSaveDocument = () => {
    const { user } = this.props;
    const { doc } = this.state;
    return doc && user.id;
  };

  autoSaveDocument = () => {
    if (!this.canSaveDocument() || this.impersonateService.isActive()) {
      return;
    }

    this.saveDocument();
  };

  saveDocument = async (showAlert?: boolean) => {
    const { setSnackbar } = this.props;
    const { blocks, doc } = this.state;

    if (!this.canSaveDocument()) {
      console.error("attempt to save document when not allowed");
      return;
    }

    if (this.state.writeLock) {
      console.warn("did not save document, write lock enabled");
      return;
    }

    if (!blocks.length) {
      return;
    }

    this.setState({ writeLock: true });

    await this.documentService.update(new DocumentModel({ ...doc, blocks }));

    this.setState({ writeLock: false });

    if (showAlert) {
      setSnackbar({
        message: "Document saved!",
        variant: "success"
      });
    }
  };

  loadDocument = async (): Promise<{ loaded: boolean; ready: boolean }> => {
    const { match, templates } = this.props;
    const { shareHash } = match.params as ObjectHash;
    const docId = (match.params as ObjectHash).id;
    let { data, shareLink } = this.state;
    const response = { loaded: false, ready: false };

    if (this.state.viewMode || shareHash) {
      const shareData = await this.apiService.get(
        `/shared/${docId}/${shareHash}?blocks=true`
      );

      if (!shareData || !shareData.doc) {
        return response;
      }

      const { doc, tagGroups, travelers } = shareData;
      const { blocks } = doc;

      const sharedTemplates = this.workspaceService.makeWorkspaceTemplates(
        shareData.templates
      );

      if (travelers && Array.isArray(travelers)) {
        shareData.travelers = travelers.map(
          (traveler: ObjectHash) => new TravelerModel(traveler)
        );
      }

      this.setState({
        blocks,
        data: shareData,
        doc,
        templates: sharedTemplates,
        shareSettings: {
          ...AppState.settings,
          tagGroups: tagGroups.map(
            (tagGroup: ObjectHash) => new TagGroupModel(tagGroup)
          )
        }
      });

      response.loaded = true;
      response.ready = true;

      return response;
    }

    const doc: DocumentModel | null = await this.documentService.getById(docId);

    if (!doc) {
      return response;
    }

    response.loaded = true;
    response.ready = true;

    // add a randomly generated id to any block that's missing one
    const blocks = doc.blocks.map((block: Block | MultiBlock) => ({
      ...block,
      id: block.id || Math.random().toString().slice(2)
    }));

    let backLink = "/settings";

    if (doc.grid) {
      backLink = `/grids/${doc.grid}`;
      data.trips = await this.getGridTrips(doc.grid);
      data.travelers = await this.getGridTravelers(doc.grid);
    }

    if (!shareLink) {
      const shareLinkData = await this.apiService.get(`/share/${doc.id}`);
      shareLink = `${window.location.origin}/documents/${doc.id}/shared/${shareLinkData.shareHash}`;
    }

    // Fetch user objects for profile tables
    const userIds = [
      ...new Set(
        blocks
          .filter(
            (block: Block | MultiBlock) => block.blockSubType === "profile"
          )
          .map((block: Block | MultiBlock) => block.options?.users)
          .flat()
      )
    ];

    // Sub to user data for profile table
    const userSub = this.searchDbService.subscribe(
      {
        resource: "users",
        fields: [],
        filters: [["id", userIds]],
        limit: userIds.length
      },
      (results: UserModel[]) => {
        this.setState({
          data: { ...this.state.data, users: results },
          _dataUpdated: Date.now()
        });
      },
      () => {}
    );

    this.setState({
      doc,
      blocks,
      templates,
      backLink,
      data,
      _dataUpdated: Date.now(),
      shareLink,
      userSub
    });

    return response;
  };

  getGridTrips = async (gridId: string): Promise<ObjectHash[]> => {
    const grid = await this.gridService.getById(gridId);
    if (!grid) {
      return [];
    }

    const limitQuery = new TravelQueryModel({ ...grid.query, limit: 500 });
    return this.travelQueryService.search(limitQuery);
  };

  getGridTravelers = async (gridId: string): Promise<ObjectHash[]> => {
    const travelers: TravelerModel[] = await this.gridService.getTravelers(
      gridId
    );

    return travelers;
  };

  handleDownloadPDF = async () => {
    const { match, setSnackbar } = this.props;
    const docId = (match.params as ObjectHash).id;

    if (!docId || this.state.downloadLock) {
      return false;
    }

    this.setState({ downloadLock: true });

    setSnackbar({
      message: "Please wait, the PDF is being created.",
      variant: "waiting"
    });

    await this.saveDocument();

    await this.documentService.trackDownload(docId);

    const tmpLink = await this.documentService.getPDFUrl(docId);

    setSnackbar({ message: "", variant: "waiting" }); // hides snackbar
    this.setState({ downloadLock: false });

    if (!tmpLink) {
      setSnackbar({
        duration: 6000,
        message:
          "There was an error and the PDF was not generated. Please try again in a few minutes.",
        variant: "error"
      });
      return;
    }

    window.open(tmpLink, "_blank");

    return true;
  };

  handleChangeDocument = async (doc: DocumentModel) => {
    const { setSnackbar } = this.props;
    const updatedDocument: DocumentModel | null = await this.documentService.update(
      doc
    );
    if (updatedDocument) {
      this.setState({ doc });
      setSnackbar({
        message: "Document updated!",
        variant: "success"
      });
    }
  };

  handleChangeDocumentBuilderEditMode = (mode: "edit" | "print" | "view") => {
    const updatedState = { viewMode: false, printMode: false };
    const { viewMode, printMode } = this.state;

    if (mode === "view" && (printMode || !viewMode)) {
      updatedState.viewMode = true;
      updatedState.printMode = false;
      this.setCellSpan();
    } else if (mode === "print" && !printMode) {
      updatedState.viewMode = true;
      updatedState.printMode = true;
      this.setCellSpan();
    } else {
      this.resetCellSpan();
    }

    this.setState(updatedState);
  };

  handleRestoreLastDeletedBlock = () => {
    const deletedBlocks = [...this.state.deletedBlocks];
    const blocks = [...this.state.blocks];

    if (!deletedBlocks.length) {
      return;
    }

    const lastBlock = deletedBlocks.shift();

    if (!lastBlock) {
      return;
    }

    blocks.unshift(lastBlock);

    this.setState({
      blocks,
      deletedBlocks
    });
  };

  getBlockObjects = (blockState: ObjectHash, isSuperSet?: boolean) => {
    const { blockSubType, filter, options } = blockState;
    const template = this.state.templates.find(
      (template) => template.name === templateHash[blockSubType]
    );

    const currencyProperties = ((template || {}).properties || [])
      .filter(
        (property: TemplatePropertyModel) =>
          property.propertyType === "currency"
      )
      .map((property: TemplatePropertyModel) => property.name);

    if (
      ["arrival", "departure", "ground-transportation", "profile"].includes(
        blockSubType
      )
    ) {
      let objects = [];
      if (
        ["arrival", "departure", "ground-transportation"].includes(blockSubType)
      ) {
        objects = this.state.data.travelers || [];
      }
      if (blockSubType === "profile") {
        objects = (this.state.data.users || []).filter((user: UserModel) =>
          options.users.includes(user.id)
        );
      }

      if (options.customDataOrder.length) {
        objects.sort((a: ObjectHash, b: ObjectHash) => {
          const v1 = a.id;
          const v2 = b.id;

          let o1 = options.customDataOrder.indexOf(v1);
          let o2 = options.customDataOrder.indexOf(v2);

          if (o1 === -1) {
            o1 = 9999;
          }
          if (o2 === -1) {
            o2 = 9999;
          }

          if (o1 > o2) {
            return 1;
          }
          if (o2 > o1) {
            return -1;
          }

          return 0;
        });
      } else if (options.sorters.length) {
        objects = objects.slice().sort((a: ObjectHash, b: ObjectHash) => {
          for (let i = 0; i < options.sorters.length; i += 1) {
            const [key, dir] = options.sorters[i];
            let item1 = a[key] || "";
            let item2 = b[key] || "";

            if (key === "users") {
              item1 = a[key].map((user: UserModel) => user.name).join(",");
              item2 = b[key].map((user: UserModel) => user.name).join(",");
            }

            if (item1 < item2) {
              return 1 * (dir === "desc" ? 1 : -1);
            }
            if (item1 > item2) {
              return -1 * (dir === "desc" ? 1 : -1);
            }
          }
          return 0;
        });
      }
      return objects;
    }

    let trips = (this.state.data.trips || []).filter((t: ObjectHash) =>
      Object.entries(filter || {}).every(([k, v]) => t[k] === v)
    );
    if (["air", "rail"].includes(blockSubType)) {
      trips = (trips || [])
        .map((trip: TripModel) =>
          trip.segments.map((segment: SegmentModel) => ({
            ...trip,
            ...segment,
            confirmation: trip.confirmation,
            tripId: trip.id
          }))
        )
        .reduce((a: any, b: any) => a.concat(b), []);
    }
    if (isSuperSet) {
      return trips;
    }
    if (options.filters.length) {
      const filterFuncs = options.filters
        .map(([key, filters]: [keyof TripModel | "dates", any[]]) =>
          filters.map((filter: any[]) => {
            if (key === "dates") {
              return (trip: TripModel) => {
                const timestamp = DateTime.fromISO(trip.from).toMillis();
                return timestamp >= filter[0] && timestamp <= filter[1];
              };
            }
            if (key === "users") {
              return (trip: TripModel) =>
                (trip.users || []).find((user: any) =>
                  filters.includes(user.id)
                );
            }
            return (trip: TripModel) => filters.includes(trip[key]);
          })
        )
        .reduce((a: any[], b: any[]) => a.concat(b), []);
      trips = trips.filter((t: any) =>
        filterFuncs.every((func: CallableFunction) => func(t))
      );
    }

    if (options.customDataOrder.length) {
      trips.sort((a: ObjectHash, b: ObjectHash) => {
        const v1 = a.id;
        const v2 = b.id;

        let o1 = options.customDataOrder.indexOf(v1);
        let o2 = options.customDataOrder.indexOf(v2);

        if (o1 === -1) {
          o1 = 9999;
        }
        if (o2 === -1) {
          o2 = 9999;
        }

        if (o1 > o2) {
          return 1;
        }
        if (o2 > o1) {
          return -1;
        }

        return 0;
      });
    } else if (options.sorters.length) {
      trips = trips.slice().sort((a: any, b: any) => {
        for (let i = 0; i < options.sorters.length; i += 1) {
          const [key, dir] = options.sorters[i];
          let item1 = a[key] || "";
          let item2 = b[key] || "";

          if (currencyProperties.includes(key)) {
            // change sort for currency properties
            const convertToNum = (s: string) => {
              if (!s) {
                return 0;
              }
              return parseFloat(s.replace("$", "").replace(",", ""));
            };
            return (
              (convertToNum(item1) - convertToNum(item2)) *
              (dir === "desc" ? 1 : -1)
            );
          } else {
            if (key === "users") {
              item1 = a[key].map((user: ObjectHash) => user.name).join(",");
              item2 = b[key].map((user: ObjectHash) => user.name).join(",");
            }

            if (item1 < item2) {
              return 1 * (dir === "desc" ? 1 : -1);
            }
            if (item1 > item2) {
              return -1 * (dir === "desc" ? 1 : -1);
            }
          }
        }
        return 0;
      });

      if (options.sorters[0][0] === "users") {
        // special handling to group passenger chronological
        const arraysEqual = (arr1: any[], arr2: any[]) => {
          if (arr1.length !== arr2.length) {
            return false;
          }
          for (let i = 0; i < arr1.length; i += 1) {
            if (arr1[i] !== arr2[i]) {
              return false;
            }
          }

          return true;
        };

        const groupDataByUsers = (arr: any[]) => {
          const res: any[] = [];

          arr.forEach((item) => {
            // check if the item belongs in an already created group
            const added = res.some((group) => {
              // check if the item belongs in this group
              const shouldAdd = arraysEqual(
                group[0].users.map((user: any) => user.name),
                item.users.map((user: any) => user.name)
              );

              // add item to this group if it belongs
              if (shouldAdd) {
                group.push(item);
              }
              // exit the loop when an item is added, continue if not
              return shouldAdd;
            });

            // no matching group was found, so a new group needs to be created for this item
            if (!added) {
              res.push([item]);
            }
          });
          return res;
        };
        const groupedTrips = groupDataByUsers(trips.slice()).sort((a, b) => {
          if (a[0].from < b[0].from) {
            return -1;
          }
          if (a[0].from > b[0].from) {
            return 1;
          }
          return 0;
        });
        trips = groupedTrips.flat();
      }
    }
    return trips;
  };

  /*
   * Add/Edit/Remove blocks
   */

  // Actions for document block order
  handleAddBlock = (
    blockType: BlockTypes | "multi",
    blockSubType: BlockSubTypes | MultiBlockSubTypes,
    blockId?: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    let index = blockId
      ? newBlocks.findIndex((block) => block.id === blockId)
      : -1;
    if (index === -1) {
      index = this.state.blocks.length;
    }

    const blockToAdd = JSON.parse(
      JSON.stringify(
        getBlockTypes().find((block) => block.blockSubType === blockSubType)
      )
    );

    blockToAdd.id = Math.random().toString().slice(2);
    if (blockType === "multi") {
      blockToAdd.blocks.forEach((block: Block) => {
        block.id = Math.random().toString().slice(2);
      });
    }
    // set default columns on new tables
    if (
      blockType === "table" &&
      ["air", "car", "hotel", "profile", "rail"].includes(blockSubType)
    ) {
      const defaultColumnsHash: ObjectHash = {
        air: [
          "users",
          "confirmation",
          "name",
          "flightNum",
          "fromLocation",
          "from",
          "toLocation",
          "to"
        ],
        car: ["users", "name", "from", "to", "fromLocation", "confirmation"],
        hotel: ["users", "name", "fromLocation", "from", "to", "confirmation"],
        profile: ["firstName", "lastName", "jobTitle", "phone"],
        rail: [
          "users",
          "name",
          "trainNum",
          "fromLocation",
          "from",
          "toLocation",
          "to"
        ]
      };

      const template = this.state.templates.find(
        (t) => t.name === templateHash[blockSubType]
      );

      if (template) {
        blockToAdd.options.columns = [["id", "ID"]];

        defaultColumnsHash[blockSubType].forEach((colKey: string) => {
          const templateProperty = template.properties.find(
            (property: TemplatePropertyModel) => property.name === colKey
          );

          if (templateProperty) {
            const { friendlyName } = templateProperty;
            blockToAdd.options.columns.push([colKey, friendlyName]);
          }
        });
        blockToAdd.options.columns.push(["documentRowMenu", ""]);
      }
    }

    newBlocks.splice(index, 0, blockToAdd);

    this.setState({ blocks: newBlocks });
  };

  handleDeleteBlock = (blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const index = newBlocks.findIndex((block) => block.id === blockId);
    const [oldBlock] = newBlocks.splice(index, 1);

    const deletedBlocks = this.state.deletedBlocks.slice();
    deletedBlocks.unshift(oldBlock);

    this.setState({
      blocks: newBlocks,
      deletedBlocks: deletedBlocks.slice(0, 4)
    });
  };

  handleMoveBlock = (direction: "up" | "down", blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const index = newBlocks.findIndex((block) => block.id === blockId);

    if (!["up", "down"].includes(direction) || Number.isNaN(index)) {
      return;
    }

    let newIndex = index;

    if (direction === "up") {
      newIndex -= 1;
    }
    if (direction === "down") {
      newIndex += 1;
    }

    newBlocks.splice(newIndex, 0, newBlocks.splice(index, 1)[0]);

    this.setState({ blocks: newBlocks });
  };

  /*
   * Custom table block actions
   */

  handleChangeCustomTableBlockBodyCell = (
    colIndex: number,
    rowIndex: number,
    value: string,
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      const newData = blockToChange.options?.data.slice();

      newData[rowIndex][colIndex] = value;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeCustomTableBlockColName = (
    colIndex: number,
    value: string,
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      const newColumns = blockToChange.options.columns.slice();

      newColumns[colIndex] = value;

      blockToChange.options.columns = newColumns;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeCustomTableBlockCols = (
    colIndex: number,
    action: "add right" | "move left" | "move right" | "delete",
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      const newColumns = blockToChange.options.columns.slice();
      const newData = blockToChange.options.data.slice();
      if (action === "add right") {
        newColumns.splice(colIndex + 1, 0, "Column Name");
        newData.forEach((rowData: string[]) =>
          rowData.splice(colIndex + 1, 0, "")
        );
      }
      if (action === "move left") {
        newColumns.splice(colIndex - 1, 0, newColumns.splice(colIndex, 1)[0]);
        newData.forEach((rowData: string[]) => {
          rowData.splice(colIndex - 1, 0, rowData.splice(colIndex, 1)[0]);
        });
      }
      if (action === "move right") {
        newColumns.splice(colIndex + 1, 0, newColumns.splice(colIndex, 1)[0]);
        newData.forEach((rowData: string[]) => {
          rowData.splice(colIndex + 1, 0, rowData.splice(colIndex, 1)[0]);
        });
      }
      if (action === "delete") {
        newColumns.splice(colIndex, 1);
        newData.forEach((rowData: string[]) => {
          rowData.splice(colIndex, 1);
        });
      }

      blockToChange.options.columns = newColumns;
      blockToChange.options.data = newData;
      blockToChange.options.tableKey += 1;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeCustomTableBlockRows = (
    rowIndex: number,
    action: "add below" | "move up" | "move down" | "delete",
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      const newData = blockToChange.options.data.slice();
      if (action === "add below") {
        const addedRow: string[] = [];
        newData[0].forEach(() => addedRow.push(""));
        newData.splice(rowIndex + 1, 0, addedRow);
      }
      if (action === "move up") {
        newData.splice(rowIndex - 1, 0, newData.splice(rowIndex, 1)[0]);
      }
      if (action === "move down") {
        newData.splice(rowIndex + 1, 0, newData.splice(rowIndex, 1)[0]);
      }
      if (action === "delete") {
        newData.splice(rowIndex, 1);
      }

      blockToChange.options.data = newData;
      blockToChange.options.tableKey += 1;

      this.setState({ blocks: newBlocks });
    }
  };

  handleImportCustomTableData = (
    newColumns: string[],
    newData: string[],
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.columns = newColumns.slice();
      blockToChange.options.data = newData.slice();
      blockToChange.options.tableKey += 1;

      this.setState({ blocks: newBlocks });
    }
  };

  /*
   * Table block actions
   */

  handleChangeTableBlockColor = (color: string, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.color = color;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockCols = (columns: string[], blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);
    const newColumns = [["id", "ID"], ...columns.slice()];

    if (blockToChange) {
      blockToChange.options.columns = newColumns;
      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockColOrder = (
    cols: string[],
    visibleCols: string[],
    colIndex: number,
    direction: "left" | "right",
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      const newColumnOrder = cols.map(([colKey]) => colKey);
      let anchorIndex = direction === "left" ? colIndex - 1 : colIndex + 1;

      if (visibleCols.length) {
        const visibleColIndexes: number[] = [];
        cols
          .map(([colKey]) => colKey)
          .forEach((key, index) => {
            if (visibleCols.includes(key)) {
              visibleColIndexes.push(index);
            }
          });
        anchorIndex =
          direction === "left"
            ? visibleColIndexes[visibleColIndexes.indexOf(colIndex) - 1]
            : visibleColIndexes[visibleColIndexes.indexOf(colIndex) + 1];
      }

      if (direction === "left") {
        newColumnOrder.splice(
          anchorIndex,
          0,
          newColumnOrder.splice(colIndex, 1)[0]
        );
      }
      if (direction === "right") {
        newColumnOrder.splice(
          anchorIndex,
          0,
          newColumnOrder.splice(colIndex, 1)[0]
        );
      }

      blockToChange.options.columnOrder = newColumnOrder;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockDataOrder = (
    objectId: string,
    direction: "up" | "down",
    blockId: string
  ) => {
    const indexOffset = direction === "up" ? -1 : 1;
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      let newCustomDataOrder = blockToChange.options.customDataOrder.slice();
      if (
        newCustomDataOrder.length === 0 ||
        newCustomDataOrder.indexOf(objectId) === -1
      ) {
        newCustomDataOrder = this.getBlockObjects(blockToChange).map(
          (object: ObjectHash) => object.id
        );
      }
      newCustomDataOrder.splice(
        newCustomDataOrder.indexOf(objectId) + indexOffset,
        0,
        newCustomDataOrder.splice(newCustomDataOrder.indexOf(objectId), 1)[0]
      );

      blockToChange.options.customDataOrder = newCustomDataOrder;

      this.setState({ blocks: newBlocks });
    }
  };

  handleResetTableBlockDataOrder = (blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.customDataOrder = [];

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockFilter = (filters: any[], blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.filters = filters.slice();

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockFutureOnly = (blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      let bool = true;
      if (blockToChange.options.futureOnly) {
        bool = false;
      }

      blockToChange.options.futureOnly = bool;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockHiddenTrips = (
    tripId: string,
    action: "hide" | "unhide",
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      let newHiddenTrips = blockToChange.options.hiddenTrips.slice();

      if (action === "hide") {
        newHiddenTrips.push(tripId);
      }
      if (action === "unhide") {
        newHiddenTrips = newHiddenTrips
          .slice()
          .filter((tId: string) => tId !== tripId);
      }

      blockToChange.options.hiddenTrips = newHiddenTrips;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockSort = (sorters: any[], blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.sorters = sorters.slice();

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockTitle = (value: string, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.title = value;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeTableBlockUsers = (users: any[], blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange && this.state.userSub) {
      if ((blockToChange.options.customDataOrder || []).length) {
        // Update the customDataOrder if present
        blockToChange.options.customDataOrder = users.map((user) => user.value);
      }

      blockToChange.options.users = users.map((user) => user.value);

      // Fetch user objects for profile tables
      const userIds = [
        ...new Set(
          newBlocks
            .filter((block) => block.blockSubType === "profile")
            .map((block) => block && block.options && block.options.users)
            .flat()
        )
      ];

      this.state.userSub.update({
        resource: "users",
        fields: [],
        filters: [["id", userIds]],
        limit: userIds.length
      });

      this.setState({ blocks: newBlocks });
    }
  };

  /*
   * Schedule block actions
   */

  handleChangeScheduleBlockUser = (userId: string, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      blockToChange.options.userId = userId;

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeScheduleEventHide = (
    cardId: string,
    hide: boolean,
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (!blockToChange) {
      return;
    }

    let newPrintViewHides = blockToChange.options.printViewHides || [];

    if (hide && !newPrintViewHides.includes(cardId)) {
      newPrintViewHides.push(cardId);
    }

    if (!hide) {
      newPrintViewHides = newPrintViewHides.filter(
        (hiddenCardId: string) => hiddenCardId !== cardId
      );
    }

    blockToChange.options.printViewHides = newPrintViewHides;

    this.setState({ blocks: newBlocks });
  };

  handleChangeScheduleEventOrder = (
    eventIds: string[],
    eventCard: ObjectHash,
    direction: "up" | "down",
    blockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (!blockToChange) {
      return;
    }

    const indexOffset = direction === "up" ? -1 : 1;
    const newEventIds = eventIds.slice();
    const eventIndex = eventIds.indexOf(eventCard.id);

    const newCustomEventOrder = blockToChange.options.customEventOrder || {};

    newEventIds.splice(
      eventIndex + indexOffset,
      0,
      newEventIds.splice(eventIndex, 1)[0]
    );

    newCustomEventOrder[eventCard.dateGroup] = newEventIds;

    blockToChange.options.customEventOrder = newCustomEventOrder;

    this.setState({ blocks: newBlocks });
  };

  handleDeleteScheduleEventOrder = (dateGroup: string, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange = newBlocks.find((block) => block.id === blockId);

    if (blockToChange) {
      const newCustomEventOrder = blockToChange.options.customEventOrder;

      delete newCustomEventOrder[dateGroup];

      blockToChange.options.customEventOrder = newCustomEventOrder;

      this.setState({ blocks: newBlocks });
    }
  };

  handleAddScheduleEvent = (scheduleEvent: ObjectHash, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && !isMultiBlock(blockToChange)) {
      scheduleEvent.id = Math.random().toString().slice(2);
      blockToChange.events?.push(scheduleEvent);

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeScheduleEvent = (scheduleEvent: ObjectHash, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && !isMultiBlock(blockToChange)) {
      const eventToChange = blockToChange.events?.find(
        (event: any) => event.id === scheduleEvent.id
      );

      eventToChange &&
        Object.entries(scheduleEvent).forEach((kv) => {
          [, eventToChange[kv[0]]] = kv;
        });

      this.setState({ blocks: newBlocks });
    }
  };

  handleDeleteScheduleEvent = (scheduleEvent: ObjectHash, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && !isMultiBlock(blockToChange)) {
      blockToChange.events = blockToChange.events?.filter(
        (event: any) => event.id !== scheduleEvent.id
      );
    }
    this.setState({ blocks: newBlocks });
  };

  handleAddScheduleNote = (eventId: any, value: any, blockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && !isMultiBlock(blockToChange)) {
      if (!blockToChange.notes) {
        blockToChange.notes = {};
      }

      blockToChange.notes[eventId] = value;

      this.setState({ blocks: newBlocks });
    }
  };

  /*
   * Basic block actions
   */

  handleChangeBasicBlockFormat = (
    opt: string,
    value: string,
    blockId: string,
    innerBlockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange) {
      if (innerBlockId !== undefined && isMultiBlock(blockToChange)) {
        // Make change to multi block
        const childBlockToChange = blockToChange.blocks.find(
          (innerBlock: MultiBlockChildBlock) => innerBlock.id === innerBlockId
        );

        if (childBlockToChange && childBlockToChange.options[opt]) {
          childBlockToChange.options[opt] = value;
        }
      } else if (blockToChange.options) {
        blockToChange.options[opt] = value;
      }

      this.setState({ blocks: newBlocks });
    }
  };

  handleChangeBasicBlockValue = (
    value: string,
    blockId: string,
    innerBlockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange) {
      if (innerBlockId !== undefined && isMultiBlock(blockToChange)) {
        const childBlockToChange = blockToChange.blocks.find(
          (innerBlock: MultiBlockChildBlock) => innerBlock.id === innerBlockId
        );

        if (childBlockToChange) {
          childBlockToChange.value = value;
        }
      } else if (!isMultiBlock(blockToChange)) {
        blockToChange.value = value;
      }

      this.setState({ blocks: newBlocks });
    }
  };

  /*
   * Multi block actions
   */

  handleAddMultiBlock = (
    blockType: MultiBlockChildTypes,
    blockSubType: BlockSubTypes,
    blockId: string,
    innerBlockId: string
  ) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && isMultiBlock(blockToChange)) {
      const innerIndex = blockToChange.blocks.findIndex(
        (innerBlock: MultiBlockChildBlock) => innerBlock.id === innerBlockId
      );
      const blockToAdd = JSON.parse(
        JSON.stringify(
          getBlockTypes().find((block) => block.blockSubType === blockSubType)
        )
      );
      blockToAdd.id = Math.random().toString().slice(2);

      blockToChange.blocks.splice(innerIndex, 0, blockToAdd);
      this.setState({ blocks: newBlocks });
    }
  };

  handleDeleteMultiBlock = (blockId: string, innerBlockId: string) => {
    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && isMultiBlock(blockToChange)) {
      const innerIndex = blockToChange.blocks.findIndex(
        (innerBlock: MultiBlockChildBlock) => innerBlock.id === innerBlockId
      );

      blockToChange.blocks.splice(innerIndex, 1);
      this.setState({ blocks: newBlocks });
    }
  };

  handleMoveMultiBlock = (
    direction: "left" | "right",
    blockId: string,
    innerBlockId: string
  ) => {
    if (!["left", "right"].includes(direction)) {
      return;
    }

    const newBlocks = this.state.blocks.slice();
    const blockToChange: Block | MultiBlock | undefined = newBlocks.find(
      (block) => block.id === blockId
    );

    if (blockToChange && isMultiBlock(blockToChange)) {
      const innerIndex = blockToChange.blocks.findIndex(
        (innerBlock: MultiBlockChildBlock) => innerBlock.id === innerBlockId
      );

      let newIndex = blockToChange.blocks.findIndex(
        (innerBlock: MultiBlockChildBlock) => innerBlock.id === innerBlockId
      );

      if (direction === "left") {
        newIndex -= 1;
      }
      if (direction === "right") {
        newIndex += 1;
      }

      blockToChange.blocks.splice(
        newIndex,
        0,
        blockToChange.blocks.splice(innerIndex, 1)[0]
      );

      this.setState({ blocks: newBlocks });
    }
  };

  // Fetch inner for block in multiblock
  getMultiBlock = (
    innerBlock: any,
    parentBlockId: string,
    blocks: any[],
    isViewMode: boolean
  ) => {
    let inner = null;
    const innerIndex = blocks.findIndex((block) => block.id === innerBlock.id);
    const { blockType, blockSubType, options = {} } = innerBlock;
    const menus = [
      <FormatBlockMenu
        key={`format-block-menu-${innerBlock.id}`}
        blockId={parentBlockId}
        blockType={blockType}
        className={`format-block-menu ${
          innerIndex === 0 ? "format-block-menu--first" : ""
        } ${innerIndex + 1 === blocks.length ? "format-block-menu--last" : ""}`}
        innerBlockId={innerBlock.id}
        options={options}
        onChangeBlockFormat={(opt: string, value: string) =>
          this.handleChangeBasicBlockFormat(
            opt,
            value,
            parentBlockId,
            innerBlock.id
          )
        }
      />,
      <MultiBlockMenu
        key={`multi-block-menu-${innerBlock.id}`}
        blocksCount={blocks.length}
        blockId={parentBlockId}
        blockDeleteLabel={blockNameHash[blockSubType]}
        className={`multi-block-menu ${
          innerIndex === 0 ? "multi-block-menu--first" : ""
        } ${innerIndex + 1 === blocks.length ? "multi-block-menu--last" : ""}`}
        innerBlockId={innerBlock.id}
        innerIndex={innerIndex}
        onAddMultiBlock={(
          bType: MultiBlockChildTypes,
          bSubType: BlockSubTypes
        ) =>
          this.handleAddMultiBlock(
            bType,
            bSubType,
            parentBlockId,
            innerBlock.id
          )
        }
        onDeleteMultiBlock={() =>
          this.handleDeleteMultiBlock(parentBlockId, innerBlock.id)
        }
        onMoveMultiBlock={(direction: "left" | "right") =>
          this.handleMoveMultiBlock(direction, parentBlockId, innerBlock.id)
        }
        options={options}
      />
    ];

    // Hide add/move block menu of container block when hovering multi block
    const menuHideFuncs = {
      onMouseOver: () => {
        try {
          const wrapperEl: HTMLElement | null = document.getElementById(
            `multi-block-wrapper-${parentBlockId}`
          );

          if (wrapperEl && wrapperEl.parentNode) {
            const moveBlockMenu: HTMLElement | null = wrapperEl.parentNode.querySelector(
              "div.move-block-menu"
            );

            if (
              moveBlockMenu &&
              !moveBlockMenu.classList.contains("move-block-menu--hidden")
            ) {
              moveBlockMenu.classList.toggle("move-block-menu--hidden");
            }

            const addBlockMenu: HTMLElement | null = wrapperEl.parentNode.querySelector(
              "div.add-block-menu"
            );

            if (
              addBlockMenu &&
              !addBlockMenu.classList.contains("add-block-menu--hidden")
            ) {
              addBlockMenu.classList.toggle("add-block-menu--hidden");
            }
          }
        } catch (e) {
          // ignore error
        }
      },
      onMouseOut: () => {
        try {
          const wrapperEl: HTMLElement | null = document.getElementById(
            `multi-block-wrapper-${parentBlockId}`
          );

          if (wrapperEl && wrapperEl.parentNode) {
            const moveBlockMenu = wrapperEl.parentNode.querySelector(
              "div.move-block-menu"
            );

            if (
              moveBlockMenu &&
              moveBlockMenu.classList.contains("move-block-menu--hidden")
            ) {
              moveBlockMenu.classList.toggle("move-block-menu--hidden");
            }

            const addBlockMenu: HTMLElement | null = wrapperEl.parentNode.querySelector(
              "div.add-block-menu"
            );

            if (
              addBlockMenu &&
              addBlockMenu.classList.contains("add-block-menu--hidden")
            ) {
              addBlockMenu.classList.toggle("add-block-menu--hidden");
            }
          }
        } catch (e) {
          // ignore error
        }
      }
    };
    const verticalAlignStyle = {
      alignSelf: options.alignSelf ? `${options.alignSelf}` : "flex-start"
    };
    if (blockType === "text") {
      const debounceEventHandler = (onChange: any, wait: number) => {
        const debounced = debounce(onChange, wait);

        return (event: SyntheticEvent) => {
          event.persist();
          return debounced(
            (event.target as HTMLInputElement).value,
            parentBlockId,
            innerBlock.id
          );
        };
      };
      inner = (
        <div
          className="text-block-wrapper"
          style={{
            ...verticalAlignStyle
          }}
          {...menuHideFuncs}
          key={`text-block-wrapper-${blockSubType}-${innerBlock.id}`}
        >
          <TextBlock
            blockSubType={blockSubType}
            defaultValue={innerBlock.value}
            disabled={this.state.viewMode}
            isViewMode={isViewMode}
            options={options}
            onChange={debounceEventHandler(
              this.handleChangeBasicBlockValue,
              300
            )}
          />
          {!this.state.viewMode ? menus : null}
        </div>
      );
    }
    if (blockType === "miscellaneous" && blockSubType === "attachment") {
      inner = (
        <div
          className="attachment-block-wrapper"
          key={`attachment-block-wrapper-${blockSubType}-${innerBlock.id}`}
          style={{
            ...verticalAlignStyle
          }}
          {...menuHideFuncs}
        >
          <AttachmentBlock
            filename={(innerBlock.options || {}).filename || ""}
            isViewMode={this.state.viewMode}
            onUpload={(url: string, uploadedFile: ObjectHash) => {
              this.handleChangeBasicBlockValue(
                url,
                parentBlockId,
                innerBlock.id
              );
              this.handleChangeBasicBlockFormat(
                "filename",
                uploadedFile.filename,
                parentBlockId,
                innerBlock.id
              );
            }}
            src={innerBlock.value || ""}
          />
          {!this.state.viewMode ? menus : null}
        </div>
      );
    }
    if (blockType === "image" && blockSubType === "image") {
      inner = (
        <div
          className="image-block-wrapper"
          key={`image-block-wrapper-${blockSubType}-${innerBlock.id}`}
          style={{
            ...verticalAlignStyle
          }}
          {...menuHideFuncs}
        >
          <ImageBlock
            src={innerBlock.value || ""}
            onUpload={(url: string) =>
              this.handleChangeBasicBlockValue(
                url,
                parentBlockId,
                innerBlock.id
              )
            }
            textAlign={options.textAlign}
            width={options.width}
          />
          {!this.state.viewMode ? menus : null}
        </div>
      );
    }
    if (blockType === "image" && blockSubType === "map") {
      inner = (
        <div
          className="image-block-wrapper"
          key={`image-block-wrapper-${blockSubType}-${innerBlock.id}`}
          style={{
            ...verticalAlignStyle
          }}
          {...menuHideFuncs}
        >
          <MapBlock
            isViewMode={this.state.viewMode}
            location={innerBlock.value || {}}
            onChange={(location: any) =>
              this.handleChangeBasicBlockValue(
                location,
                parentBlockId,
                innerBlock.id
              )
            }
            textAlign={options.textAlign}
            width={options.width}
          />
          {!this.state.viewMode ? menus : null}
        </div>
      );
    }
    return inner;
  };

  render() {
    const { history, match, openModal, user } = this.props;
    const {
      _dataUpdated,
      backLink,
      blocks,
      data,
      deletedBlocks,
      doc,
      loading,
      printMode,
      shareLink,
      templates,
      viewMode,
      writeLock,
      downloadLock
    } = this.state;

    const isImpersonating = this.impersonateService.isActive();

    const saveDocument = this.saveDocument;
    const handleDownloadPDF = this.handleDownloadPDF;
    const handleRestoreLastDeletedBlock = this.handleRestoreLastDeletedBlock;
    const docId = (match.params as ObjectHash).id;

    const isSharedPath = match.path.includes("/shared");

    if (loading) {
      return (
        <div>
          <Loader type="dots" />
          <h5
            style={{
              textAlign: "center",
              margin: "0",
              fontSize: "12px",
              fontWeight: "normal"
            }}
          >
            Loading document...
          </h5>
        </div>
      );
    }

    return (
      <div
        className={classNames("document-builder", {
          "document-builder__view-mode": viewMode || printMode,
          "document-builder__print-mode": printMode,
          "document-builder__sharelink": isSharedPath
        })}
      >
        {!isSharedPath && doc && (
          <DocumentMenuBar
            backLink={backLink || ""}
            docData={doc}
            isImpersonating={isImpersonating}
            isPrimaryAdmin={(user || {}).primary || false}
            onChangeDocument={this.handleChangeDocument}
            onChangeDocumentBuilderEditMode={
              this.handleChangeDocumentBuilderEditMode
            }
            onDeleteDocument={() => {
              openModal("confirm", {
                dialogTitle: `Delete ${
                  doc.isTemplate ? "Template" : "Document"
                }`,
                dialogBody: `Are you sure you want to delete this ${
                  doc.isTemplate ? "template" : "document"
                }? This action cannot be undone.`,
                onConfirm: async () => {
                  const deletedDocument: DocumentModel | null = await this.documentService.delete(
                    docId
                  );
                  if (deletedDocument && backLink) {
                    history.push(backLink, { prevPage: "document" });
                  }
                }
              });
            }}
            onDownloadDocument={handleDownloadPDF}
            onRestoreLastDeletedBlock={handleRestoreLastDeletedBlock}
            writeLock={writeLock}
            downloadLock={downloadLock}
            onSaveDocument={saveDocument}
            printMode={printMode}
            shareLink={shareLink || ""}
            viewMode={viewMode}
            restoreLock={!Boolean(deletedBlocks.length)}
          />
        )}

        <div
          key="document-builder__blocks"
          className="document-builder__blocks"
        >
          {blocks.map((block, index) => (
            <BuilderBlock
              _dataUpdated={_dataUpdated}
              block={block}
              ctx={this}
              index={index}
              key={`document-builder__block-${block.id}`}
              locationsData={data.locations}
              printMode={printMode}
              updateStaticResults={async () => {
                if (doc?.grid) {
                  const trips = await this.getGridTrips(doc.grid);
                  this.setState({
                    data: { ...this.state.data, trips },
                    _dataUpdated: Date.now()
                  });
                }
              }}
              viewMode={viewMode}
              template={templates.find(
                (t) => t.name === templateHash[block.blockSubType]
              )}
              templates={templates}
            />
          ))}
        </div>

        {!viewMode && (
          <div key="add-block-menu--last" className="add-block-menu--last">
            <AddBlockMenu
              blockId=""
              onAddMultiBlock={() => {}}
              key="add-block-menu"
              onAddBlock={(
                blockType: BlockTypes | "multi",
                blockSubType: BlockSubTypes | MultiBlockSubTypes
              ) => this.handleAddBlock(blockType, blockSubType)}
            />
          </div>
        )}

        {viewMode && (
          <div className="document-builder__footer">
            <div
              className="document-builder__site-link"
              onClick={() => {
                window.open("https://www.tripgrid.com", "_blank");
              }}
            >
              <span className="document-builder__branding-text">
                Powered by
              </span>
              <div className="document-builder__branding">
                <div className="logo">
                  <img
                    alt="tripgrid-logo"
                    className="logo__icon"
                    src="https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg"
                  />
                  <img
                    alt="tripgrid-letter-mark.svg"
                    src={TripgridLetterMark}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(
  withModalContext(withSnackbarContext(DocumentBuilderPage))
);
