import React, { useRef, useState } from "react";
import AppIcon from "../../components/app-icon";
import Button from "../../components/button";
import InputController from "../../components/form/input-controller";
import PopperMenu from "../../components/popper-menu";
import AppContainer from "../../container";
import { AppActionTypes } from "../../context/reducer";
import useApp from "../../hooks/use-app.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";
import CollectionModel from "../../models/collection.model";
import {
  CollectionService,
  ICollectionService
} from "../../services/collection.service";
import { TemplateInput } from "../../services/workspace.service";
import { CollectionValidator, FormConfig } from "../../utils/form";
import {
  getDisplayDate,
  getDisplayTime,
  ObjectHash
} from "../../utils/helpers";

interface Props {
  collection: CollectionModel;
  onChange: CallableFunction;
}

const formConfig: FormConfig = {
  validator: CollectionValidator,
  fields: [
    {
      key: "bookingDates",
      label: "Trip Dates",
      inputType: "date-range"
    },
    {
      key: "startLocation",
      label: "Coming From?",
      inputType: "airport"
    },
    {
      key: "startDesiredTime",
      label: "",
      helperText: "Leave by a certain time?",
      inputType: "time"
    },
    {
      key: "endLocation",
      label: "Going to?",
      inputType: "airport"
    },
    {
      key: "endDesiredTime",
      label: "",
      helperText: "Arrive by a certain time?",
      inputType: "time"
    },
    {
      key: "notes",
      label: "Notes",
      inputType: "text",
      helperText: "Anything else you'd like to point out?"
    }
  ]
};

const linkedFields = [
  "First Name",
  "Middle Name",
  "Last Name",
  "Date of Birth"
];

export default function CollectionDefaultsEditor(props: Props) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );
  const { dispatch, settings } = useApp();
  const { setSnackbar } = useSnackbar();

  const { onChange } = props;

  const [collection, setCollection] = useState<CollectionModel>(
    props.collection
  );

  const { defaultBookingValues } = collection;
  const { fields, validator } = formConfig;

  // @todo gotta be a better way
  const menuRefs: {
    [index: string]: React.RefObject<HTMLDivElement | null>;
  } = {
    bookingDates: useRef(null),
    startLocation: useRef(null),
    startDesiredTime: useRef(null),
    endLocation: useRef(null),
    endDesiredTime: useRef(null),
    notes: useRef(null)
  };

  const zeroState: ObjectHash = {};
  fields.forEach((templateInput: TemplateInput) => {
    const { key } = templateInput;
    let value = (defaultBookingValues as ObjectHash)[key] || "";
    zeroState[key] = value;
  });

  const [formState, setFormState] = useState<ObjectHash>(zeroState);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const handleSave = async (key: string) => {
    const updatedDefaults = { ...defaultBookingValues, ...formState };
    const updatedCollection = await collectionService.update(collection.id, {
      defaultBookingValues: updatedDefaults
    });

    if (!updatedCollection) {
      setSnackbar({
        message: "There was an error and your changes were not saved",
        variant: "error"
      });
      return;
    }

    dispatch({
      type: AppActionTypes.UpdateCollection,
      payload: updatedCollection
    });

    (menuRefs[key]?.current as any).close();
    setCollection(updatedCollection);
    onChange(updatedCollection);
  };

  const editorRows = fields.map((templateInput: TemplateInput) => {
    const { helperText, key, label } = templateInput;

    const arrowElement = label.length ? null : (
      <div className="arrow">
        <AppIcon type="arrow-down-right" />
      </div>
    );

    const value = formState[key];
    const hasValue = Boolean(value);
    const linkLabel = hasValue ? "Edit default value" : "Set default value";
    let displayValue = (defaultBookingValues as ObjectHash)[key] || "";

    // date formatting for booking dates
    if (key === "bookingDates" && Array.isArray(value)) {
      displayValue = value
        .filter((value: string) => value)
        .map((value: string) => getDisplayDate(value, settings.dateFormat))
        .join(" - ");
    }

    if (["startDesiredTime", "endDesiredTime"].includes(key)) {
      displayValue = getDisplayTime(value);
    }

    return (
      <div className="editor-row" key={key}>
        {arrowElement}
        <div className="inner">
          <div className="label">
            {label && <span className="label-text">{label}</span>}
            <span className="helper-text">{helperText}</span>
          </div>
          <div className="menu">
            <div className="value">{displayValue}</div>
            <PopperMenu
              ref={menuRefs[key]}
              onClose={() => {
                setFormChanged(false);
              }}
              anchor={linkLabel}
            >
              <div className="collection-default-popper">
                <div className="title">Set Default Value</div>
                <div className="description">
                  Enter a default value to set for all booking requests in this
                  collection
                </div>
                <div className="form">
                  <div className="input-label">
                    <div>{label || helperText}</div>
                  </div>
                  <div className="article">
                    <div>{key === "bookingDates" ? "Are" : "Is"}</div>
                  </div>
                  <div className="input">
                    <InputController
                      model={formState}
                      templateInput={{
                        ...templateInput,
                        label: "",
                        helperText: ""
                      }}
                      customProps={{
                        menuPortalTarget: document.body,
                        onChange: (value: any) => {
                          setFormChanged(true);
                          setFormState({ ...formState, [key]: value });
                        }
                      }}
                      validator={validator[key]}
                    />
                  </div>
                </div>
                <div className="buttons">
                  <Button
                    color="gray"
                    isTransparent={true}
                    label="Cancel"
                    onClick={() => {
                      (menuRefs[key]?.current as any).close();
                    }}
                    size="medium"
                  />
                  <Button
                    isDisabled={!formChanged}
                    color="product-blue"
                    label="Save"
                    onClick={() => handleSave(key)}
                    size="medium"
                  />
                </div>
              </div>
            </PopperMenu>
          </div>
        </div>
      </div>
    );
  });

  const linkedRows = linkedFields.map((label: string, index: number) => {
    return (
      <div className="editor-row" key={index}>
        <div className="linked-tab">
          <div>Linked from Profile</div>
          <div>
            <AppIcon type="cord" />
          </div>
        </div>
        <div className="inner">{label}</div>
      </div>
    );
  });

  return (
    <div id="collection-defaults-editor">
      {editorRows}
      {linkedRows}
    </div>
  );
}
