import { ObjectHash, sortByStringCompare } from "../../utils/helpers";

// @todo better place to put these?
export const RowHeight = 56;
export const HeadRowHeight = 32;
export const ColumnDefaultWidth = 200;
export const ColumnMinWidth = 100;
export const ColumnMaxWidth = 600;

export type StyledColumnConfig = Map<string, StyledColumn>;

export type Column = {
  canDelete?: boolean;
  canEdit?: boolean;
  hidden?: boolean;
  hideLabel?: boolean;
  id: string;
  label: string;
  locked?: boolean;
  pinned?: boolean;
  position?: number;
  resize?: boolean;
  width?: number;
};

export type StyledColumn = Column & {
  width: number;
  left: number;
};

export type Filter = {
  article?: "is" | "are"; // display value only
  formId?: string; // unique id use by the filter menu to create a formState object
  fieldId: string; // what property of the related model does this filter apply to?
  inputType: string; // what type of input is used to select the value for this filter?
  label?: string; // used when selecting this filter from a select, in the filter menu
  position?: number;
  value?: any;
};

export type GridCell = {
  columnId: string;
  element: JSX.Element;
  style?: ObjectHash;
};

export type GridRow = {
  cells: GridCell[];
  groupId?: string | number;
  groupName?: string;
  pinned?: boolean;
};

export type Group = {
  fieldId: string;
  label: string;
  onName?: (value: any, meta?: ObjectHash) => string;
};

export type Sort = {
  column: Column;
  direction: "asc" | "desc";
  directionType?: string;
};

export const getColumnIdFromName = (name: string): string => {
  return String(name)
    .replace(/[^A-Za-z0-9_-]+/g, "")
    .trim()
    .toLowerCase();
};

export const getGridRows = (
  data: ObjectHash[],
  columns: StyledColumnConfig,
  group?: Group | null,
  meta?: ObjectHash
): GridRow[] => {
  let rows: GridRow[] = [];

  const columnIds = Array.from(columns.keys());

  data.forEach((dataRow: ObjectHash) => {
    const { sinkGroupId } = dataRow;
    const cells: GridCell[] = columnIds.map((columnId: string) => {
      const { left = 0, width = 0 } = columns.get(columnId) || {};
      const element = dataRow[columnId];
      return {
        columnId,
        element,
        style: {
          width,
          left
        }
      };
    });

    rows.push({ cells, groupId: sinkGroupId });
  });

  /*
   * Grouping
   */
  if (group) {
    const { onName } = group;
    // sort by group id value to create the groupings
    const sort = {
      column: { id: "groupId" },
      direction: "asc" as "asc" | "desc"
    };

    // @todo need to preserve sort within groups
    rows.sort((a, b) => sortByStringCompare(a, b, sort));

    // inject the pinned rows with the group titles
    const sourceRows = [...rows];
    rows = [];

    let lastGroupId: string | number | boolean = false;
    sourceRows.forEach((row: GridRow) => {
      const { groupId = "" } = row;

      if (lastGroupId === false || groupId !== lastGroupId) {
        rows.push({
          cells: [],
          pinned: true,
          groupId,
          groupName: onName ? onName(groupId, meta) : String(groupId)
        });
      }

      rows.push(row);
      lastGroupId = groupId;
    });
  }

  return rows;
};

export const getStyledColumns = (columns: Column[]): StyledColumnConfig => {
  let columnLeft = 0;
  const columnStyles: StyledColumnConfig = new Map();

  columns.sort(sortByPinnedPosition).forEach((column: Column) => {
    const {
      hidden = false,
      hideLabel = false,
      id,
      pinned = false,
      label = "",
      resize = true,
      width = ColumnDefaultWidth
    } = column;

    if (hidden) {
      return;
    }

    // validate width setting
    let safeWidth = width;

    if (safeWidth < ColumnMinWidth) {
      safeWidth = ColumnMinWidth;
    }

    if (safeWidth > ColumnMaxWidth) {
      safeWidth = ColumnMaxWidth;
    }

    const left = columnLeft;
    columnLeft += safeWidth;

    columnStyles.set(id, {
      hideLabel,
      id,
      label,
      pinned,
      resize,
      width: safeWidth,
      left
    });
  });

  return columnStyles;
};

const sortByPinnedPosition = (aCol: Column, bCol: Column) => {
  const aPinned = Boolean(aCol.pinned);
  const bPinned = Boolean(bCol.pinned);

  if (aPinned === bPinned) {
    const aPos = aCol.position || 0;
    const bPos = bCol.position || 0;
    if (aPos === bPos) {
      return 0;
    }
    return aPos < bPos ? -1 : 1;
  }

  return aPinned ? -1 : 1;
};
