import React from "react";
import Select from "react-select";

import AppContainer from "../../../container";
import AppIcon, { AppIconType } from "../../app-icon";
import AppIconFrame from "../../app-icon-frame";

import {
  ITravelQueryService,
  TravelQueryService
} from "../../../services/travel-query.service";

import TravelFilterModel, {
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import { ObjectHash, reactSelectCloseOnScroll } from "../../../utils/helpers";

import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

const filterGroupIcons: Map<TravelFilterGroups, AppIconType> = new Map([
  [TravelFilterGroups.People, "people"],
  [TravelFilterGroups.TravelDate, "date-range"],
  [TravelFilterGroups.Flight, "airplane"],
  [TravelFilterGroups.Identifier, "globe"],
  [TravelFilterGroups.Tag, "tag"]
]);

export interface Props {
  defaultValue?: string;
  disabledFields?: string[];
  isDisabled?: boolean;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  resource: TravelFilterResources;
}

export default function TravelFilterSelect(props: Props) {
  const travelQueryService: ITravelQueryService = AppContainer.get(
    TravelQueryService
  );

  const { menu, menuPortal, option, singleValue } = customStyle;

  const optionStyles = {
    control: (styles: ObjectHash) => ({
      ...styles,
      boxSizing: "border-box" as "border-box",
      borderWidth: "2px",
      cursor: "pointer",
      height: "100%",
      minHeight: "40px",
      paddingRight: "8px"
    }),
    group: (styles: ObjectHash) => ({
      ...styles,
      padding: "4px 0"
    }),
    groupHeading: (styles: any) => ({
      ...styles,
      ".travel-filter-select-group-label": {
        display: "flex",
        alignItems: "center",
        textTransform: "none",
        height: "40px",
        fontSize: 14
      },
      ".app-icon-frame": {
        marginRight: 8
      }
    }),
    menu,
    menuPortal,
    option: (styles: ObjectHash) => ({
      ...option(styles),
      padding: "8px 12px 8px 24px",
      display: "flex",
      alignItems: "center"
    }),
    singleValue
  };

  const {
    defaultValue,
    disabledFields = [],
    isDisabled,
    onChange,
    resource
  } = props;

  const filterGroups = travelQueryService.getActiveFiltersByGroup(resource);
  let defaultOption: ObjectHash | null = null;

  const groupOptions = filterGroups.map((filterGroup: ObjectHash) => {
    const options = filterGroup.filters.map((filter: TravelFilterModel) => ({
      label: filter.name,
      value: filter.field,
      isDisabled: disabledFields.includes(filter.field)
    }));

    const defaultOptionIndex = options.findIndex(
      (option: ObjectHash) => option.value === defaultValue
    );
    if (defaultOptionIndex > -1) {
      defaultOption = options[defaultOptionIndex];
    }

    return {
      label: filterGroup.name,
      options
    };
  });

  // Adds icon for groups
  const formatGroupLabel = (group: ObjectHash) => {
    const appIconType = filterGroupIcons.get(group.label);

    return (
      <div className="travel-filter-select-group-label">
        {appIconType && (
          <AppIconFrame color="light-green">
            <AppIcon type={appIconType} color="green" size="x-small" />
          </AppIconFrame>
        )}
        {group.label}
      </div>
    );
  };

  const handleChange = (option: ObjectHash) => {
    onChange(resource, option.value);
  };

  return (
    <Select
      components={{
        DropdownIndicator: () => <AppIcon type="menu-arrow" />,
        IndicatorSeparator: () => null
      }}
      className="travel-filter-select"
      classNamePrefix="tg-input"
      closeMenuOnScroll={reactSelectCloseOnScroll}
      defaultValue={defaultOption}
      isDisabled={isDisabled}
      formatGroupLabel={formatGroupLabel}
      menuPortalTarget={props.menuPortalTarget}
      onChange={(option: any) => handleChange(option)}
      options={groupOptions}
      placeholder="Select Filter"
      styles={optionStyles}
      tabSelectsValue={false}
      theme={customReactSelectTheme}
    />
  );
}
