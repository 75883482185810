import { flatten } from "lodash";
import { DateTime } from "luxon";
import TagGroupModel from "../../../../models/tag-group.model";
import TagModel from "../../../../models/tag.model";
import UserModel from "../../../../models/user.model";

import {
  airportToIata,
  getFlightNumber,
  ObjectHash
} from "../../../../utils/helpers";
import { Column } from "../../../sink/helpers";
import { TmlEntryModel, TmlEntrySegment, TmlEntrySegmentId } from "./models";

const getCsvDataFromSegment = (
  segment: TmlEntrySegment,
  segmentId: TmlEntrySegmentId,
  tripType: string
): string[] => {
  const { date = 0, value } = segment;

  const {
    name = "",
    flightNum = "",
    fromDate = 0,
    fromLocation = "",
    toDate = 0,
    toLocation = "",
    confirmation = ""
  } = (value as ObjectHash) || {};

  const FromDateISO = DateTime.fromSeconds(fromDate);
  const hasFromDate = fromDate && FromDateISO.isValid;
  const ToDateISO = DateTime.fromSeconds(toDate);
  const hasToDate = toDate && ToDateISO.isValid;

  const defaultTravelData = ["", "", "", "", "", ""];
  const defaultTransportData = ["", "", ""];
  const defaultLocationData = [""];

  const dateFormat = "M/d/yy";
  const timeFormat = "h:mma";

  switch (tripType) {
    case "air":
      if (!segment) {
        return defaultTravelData;
      }

      const departDate = hasFromDate ? FromDateISO.toFormat(dateFormat) : "";
      const airline = name ? String(name).split(" ").shift() : ""; // convert "American Airlines" to "American"

      const depart = airportToIata(fromLocation);
      const departTime = hasFromDate ? FromDateISO.toFormat(timeFormat) : "";

      const arrive = airportToIata(toLocation);
      const arriveTime = hasToDate ? ToDateISO.toFormat(timeFormat) : "";

      return [
        departDate,
        airline || "",
        getFlightNumber(flightNum),
        `${depart} ${departTime}`,
        `${arrive} ${arriveTime}`,
        confirmation
      ];

    case "car":
      if (!segment) {
        return defaultTransportData;
      }

      const rentalCompany = name ? String(name).split(" ").shift() : ""; // convert "Hertz Car Rental, JFK" to "Hertz"
      const pickupTime = hasFromDate ? FromDateISO.toFormat(timeFormat) : "";

      return [rentalCompany, pickupTime, confirmation];

    case "hotel":
      if (!segment) {
        return defaultLocationData;
      }
      return [name]; // @todo shorten this similar to rental company and airline?

    case "user":
      let userData: string[] = [];
      const override = (value as string) || "";

      switch (segmentId) {
        case "locationSegment":
          userData = defaultLocationData;
          userData[0] = override;
          break;
        case "transportSegment":
          userData = defaultTransportData;
          userData[0] = override;
          break;
        case "travelSegment":
          userData = defaultTravelData;
          const OverrideDateISO = DateTime.fromSeconds(date);
          const overrideDate =
            date && OverrideDateISO.isValid
              ? OverrideDateISO.toFormat(dateFormat)
              : "";

          userData[0] = overrideDate;
          userData[1] = override;
      }

      return userData;
  }

  return [""];
};

const getHeaderRow = (columns: Column[]): string[] => {
  const headerRow: string[] = [];

  columns.forEach((column: Column) => {
    const { id, label } = column;
    const isSegment = [
      "travelSegment",
      "transportSegment",
      "locationSegment"
    ].includes(id);

    // explode segment columns into to,from,date
    if (isSegment) {
      switch (id) {
        case "travelSegment":
          headerRow.push("Date"); // “XX/XX/XX” (only last 2 digits of year and drop “0" in first numbers for both
          headerRow.push("Airline"); // only first word of airiline name
          headerRow.push("Flight #");
          headerRow.push("Depart");
          headerRow.push("Arrive"); /// BDL 8:30am
          headerRow.push("Confirmation");
          break;

        case "transportSegment":
          headerRow.push("Transportation");
          headerRow.push("Pick-up Time");
          headerRow.push("Confirmation");
          break;

        case "locationSegment":
          headerRow.push("Accommodation");
          break;
      }

      return;
    }

    if (id === "entryType") {
      headerRow.push("Type");
      return;
    }

    if (id === "traveler") {
      headerRow.push("Name");
      headerRow.push("Title"); // job title
      return;
    }

    headerRow.push(label);
  });

  return headerRow.map(escapeCsvValue);
};

export function getCsvDataFromEntries(
  columns: Column[],
  entries: TmlEntryModel[],
  users: Map<string, UserModel>
): string {
  const csvColumns: Column[] = columns.filter(
    (column: Column) => !column.hidden
  );

  const headerRow = getHeaderRow(csvColumns);

  const dataRows = entries
    .map((entry: TmlEntryModel) => {
      const {
        locationSegment,
        notes,
        travelSegment,
        transportSegment,
        type,
        userId
      } = entry;

      const user = users.get(userId);
      const tags = user?.tags || [];

      const values: string[] = flatten(
        csvColumns.map((column: Column) => {
          const { id } = column;

          switch (id) {
            case "entryType":
              return type === "inbound" ? "Arrival" : "Departure";
            case "traveler":
              return [user?.getFullName() || userId, user?.jobTitle || ""];
            case "travelSegment":
              return getCsvDataFromSegment(
                travelSegment,
                id,
                travelSegment.tripType || "air"
              );
            case "transportSegment":
              return getCsvDataFromSegment(
                transportSegment,
                id,
                transportSegment.tripType || "car"
              );
            case "locationSegment":
              return getCsvDataFromSegment(
                locationSegment,
                id,
                locationSegment.tripType || "hotel"
              );
            case "notes":
              return notes || "";
          }

          if (TagGroupModel.isFieldId(id)) {
            const tagGroup = TagGroupModel.getIdFromField(id);
            return tags
              .filter((tag: TagModel) => tag.tagGroup === tagGroup)
              .map((tag: TagModel) => tag.name)
              .join(", ");
          }

          return "";
        })
      );

      return values.map(escapeCsvValue).join(",");
    })
    .join("\n");

  return `${headerRow}\n${dataRows}`;
}

const escapeCsvValue = (str: string) => `"${String(str).replace(/"/g, '""')}"`;
