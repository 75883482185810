import React from "react";
import BaseModel from "./base.model";
import { cloneDeep } from "lodash";
import { ObjectHash } from "../utils/helpers";

export type TravelTypeKeys = "air" | "activity" | "car" | "rail" | "hotel";

export enum TravelFilterFields {
  AirportArrive = "airport-arrive",
  AirportDepart = "airport-depart",
  ConfirmationNumber = "confirmation-number",
  GdsLocator = "gds-locator",
  Tag = "tag",
  TravelDateAfter = "travel-date-after",
  TravelDateBefore = "travel-date-before",
  TravelDateOn = "travel-date-on",
  Travelers = "travelers",
  Type = "type"
}

export enum TravelFilterGroups {
  People = "People",
  TravelDate = "Travel Date",
  Flight = "Flight",
  Identifier = "Identifier",
  Tag = "Tag"
}

export enum TravelFilterResources {
  Trip = "trips",
  User = "users"
}

interface componentPropsCallback {
  (filterProps: ObjectHash, meta: ObjectHash): ObjectHash;
}

export default class TravelFilterModel extends BaseModel {
  field: TravelFilterFields;
  name: string;
  resource: TravelFilterResources;
  group: TravelFilterGroups;
  id: string;

  value: any;
  component: any;
  meta: ObjectHash;
  onChange?: CallableFunction;
  onComponentProps?: componentPropsCallback;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }
    this.field = data.field;
    this.name = data.name;
    this.resource = data.resource;
    this.group = data.group;
    this.id = data.id ?? [this.resource, this.field].join("-");

    this.value = data.value;
    this.component = data.component;
    this.meta = data.meta ?? {};

    if (data.onChange) {
      this.onChange = data.onChange;
    }

    if (data.onComponentProps) {
      this.onComponentProps = data.onComponentProps;
    }
  }

  getComponentProps(filterProps: ObjectHash) {
    if (this.value) {
      filterProps.defaultValue = this.value;
    }

    if (this.onComponentProps) {
      filterProps = this.onComponentProps(filterProps, this.meta);
    }

    return cloneDeep(filterProps);
  }

  // @todo accurate return type
  getFilterElement(filterProps: ObjectHash, onChange: CallableFunction): any {
    if (!this.component) {
      return <div className="placeholder-input" />;
    }

    const FilterComponent = this.component;
    const props: ObjectHash = this.getComponentProps(filterProps);
    props.onChange = (value: any) => {
      if (this.onChange) {
        value = this.onChange(value, this.meta);
      }
      this.value = value;
      return onChange(value);
    };

    return <FilterComponent {...props} />;
  }
}
