import React from "react";
import AsyncSelect from "react-select/async";

import AppIcon from "../../app-icon";
import AppIconFrame from "../../app-icon-frame";
import Loader from "../../loader";

import AppContainer from "../../../container";
import { GridService, IGridService } from "../../../services/grid.service";
import GridModel from "../../../models/grid.model";
import { debounce } from "lodash";
import { ObjectHash, SelectOption } from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  onChange: CallableFunction;
}

export default function GridSearchInput(props: Props) {
  const gridService: IGridService = AppContainer.get(GridService);

  const { onChange } = props;

  const getOptions = debounce(
    (inputValue: string, callback: CallableFunction) => {
      if (!inputValue) {
        return Promise.resolve([] as SelectOption[]);
      }

      gridService.searchGrids(inputValue).then((grids: GridModel[]) => {
        const options = grids.map((grid: GridModel) => ({
          label: grid.name,
          value: grid.id
        }));

        return callback(options);
      });
    },
    300
  );

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    control: (styles: ObjectHash) => ({
      ...styles,
      boxShadow: "0", // disables blue focus outline
      cursor: "cursor",
      height: "100%",
      minHeight: "40px",
      borderRadius: 30,
      borderWidth: 2
    }),
    placeholder: (styles: ObjectHash) => ({
      ...styles,
      color: Colors.textGray
    }),
    menu,
    menuPortal,
    option
  };

  return (
    <AsyncSelect
      cacheOptions
      className="autocomplete-input autocomplete-input--async"
      components={{
        DropdownIndicator: () => (
          <AppIconFrame>
            <AppIcon type="search" color="white" size="x-small" />
          </AppIconFrame>
        ),
        IndicatorSeparator: () => null,
        LoadingIndicator: () => <Loader type="spinner" />
      }}
      loadOptions={getOptions}
      noOptionsMessage={({ inputValue }) =>
        inputValue.length ? "No Grids found" : null
      }
      onChange={(option: any) => onChange(option?.value)}
      placeholder="Search Grids"
      styles={optionStyles}
      tabSelectsValue={false}
      theme={customReactSelectTheme}
    />
  );
}
