import React from "react";
import { TemplateInput } from "../../../../services/workspace.service";
import { ObjectHash, SelectOption } from "../../../../utils/helpers";
import RemoveButton from "../../../button/remove";
import InputController from "../../../form/input-controller";
import AutocompleteInputFilled from "../../../form/inputs/AutocompleteInputFilled";
import ArticleSelect from "../../../travel-filter/article-select";
import { StackFilter } from "../../helpers";

interface Props {
  filter: StackFilter;
  filterOptions: SelectOption[];
  hasNextRow: boolean;
  menuPortalTarget?: HTMLElement;
  onFieldChange: CallableFunction;
  onValueChange: CallableFunction;
  onRemove: CallableFunction;
}

export default function FilterRow(props: Props) {
  const {
    filter,
    filterOptions,
    hasNextRow,
    menuPortalTarget = document.body,
    onFieldChange,
    onValueChange,
    onRemove
  } = props;

  const { article = "is", fieldId, inputType, value } = filter;
  let inputValue = value;

  const templateInput: TemplateInput = {
    key: fieldId,
    inputType,
    label: ""
  };

  const hasFilter = Boolean(inputType);

  const fieldOpts = filterOptions.filter(
    (opt: SelectOption) => opt.value === fieldId
  );

  const customProps: ObjectHash = {
    onChange: (value: any) => onValueChange(value)
  };

  if (inputType === "user-select") {
    customProps.menuPosition = "right";
    customProps.width = 400;
  }

  if (inputType === "booking-status") {
    templateInput.inputType = "select";
    templateInput.options = [
      { label: "In Progress", value: "initiated" },
      { label: "Ready to Book", value: "ready" },
      { label: "Details Needed", value: "requested" },
      { label: "Booked", value: "booked" }
    ];
    const selectedOpt = templateInput.options.find(
      (opt: ObjectHash) => opt.value === inputValue
    );

    inputValue = selectedOpt || "";
  }

  return (
    <div className="filter-row" key={`filter-row-${fieldId}`}>
      <AutocompleteInputFilled
        menuPortalTarget={menuPortalTarget}
        options={filterOptions}
        onChange={onFieldChange}
        value={fieldOpts}
      />
      <div className="article-select">
        <ArticleSelect defaultValue={article} isDisabled={true} />
      </div>
      <div className="input">
        {hasFilter && (
          <InputController
            customProps={customProps}
            model={{ [fieldId]: inputValue }}
            templateInput={templateInput}
          />
        )}
        {!hasFilter && <div className="input-placeholder"></div>}
      </div>
      {hasFilter && hasNextRow && (
        <div className="andor">
          <div>And</div>
        </div>
      )}
      <div className="remove-filter">
        <RemoveButton onClick={onRemove} size="small" />
      </div>
    </div>
  );
}
