import React from "react";
import env from "../../../../utils/env";
import AppIcon from "../../../../components/app-icon";
import LocationAsyncInputFilled from "../../../../components/form/inputs/LocationAsyncInputFilled";
import { ObjectHash } from "../../../../utils/helpers";
import "../image/image-block.scss";

interface Props {
  textAlign: string;
  width: string;
  location: ObjectHash;
  onChange: CallableFunction;
  isViewMode: boolean;
}

export default function MapBlock(props: Props) {
  const { textAlign, width, location, onChange, isViewMode } = props;
  const { placeId = "" } = location;

  return (
    <div
      className={`image-block image-block--align-${textAlign}`}
      style={{ width: `${width ? `${width}%` : "auto"}` }}
    >
      <LocationAsyncInputFilled
        isDisabled={isViewMode}
        label="Address"
        defaultValue={location ? [location] : []}
        onChange={(location: string, meta?: ObjectHash) => {
          onChange(meta);
        }}
      />
      {placeId ? (
        <iframe
          title={placeId}
          className="image-block__map"
          src={`https://www.google.com/maps/embed/v1/place?key=${env.google.apiKey}&q=place_id:${placeId}`}
        />
      ) : (
        <div className="image-block__map--empty">
          <AppIcon type="map" />
        </div>
      )}
    </div>
  );
}
