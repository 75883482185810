import React, { useRef, useState } from "react";

import Button from "../../../button";
import { Column } from "../../helpers";
import FormController from "../../../form/form-controller";
import { ModelValidator } from "./";
import { ObjectHash } from "../../../../utils/helpers";
import { TemplateInput } from "../../../../services/workspace.service";

interface Props {
  column: Column;
  onCancel: () => void;
  onChange: (column: Column) => void;
}

const TemplateInputs = [
  {
    inputType: "text",
    key: "label",
    label: ""
  }
];

export default function ColumnEditRow(props: Props) {
  const { column, onCancel, onChange } = props;
  const { id, label } = column;
  const [formValid, setFormValid] = useState(false);
  const [formState, setFormState] = useState({ label });
  const formRef = useRef(null);

  const handleSave = (formState: ObjectHash) => {
    const { label } = formState;
    const updatedColumn = { ...column, label };
    onChange(updatedColumn);
  };

  return (
    <div className="column-row edit-row" key={id}>
      <FormController
        fields={TemplateInputs}
        model={formState}
        onCustomProps={(
          templateInput: TemplateInput,
          customProps: ObjectHash
        ) => {
          customProps.autoFocus = true;
          return customProps;
        }}
        onSave={handleSave}
        onValidate={setFormValid}
        validator={ModelValidator}
        ref={formRef}
      />
      <Button
        color="product-blue"
        isDisabled={!formValid}
        label="Edit"
        onClick={() => {
          formRef.current && (formRef.current as any).save();
        }}
        size="small"
      />
      <Button
        color="gray"
        label="Cancel"
        onClick={() => {
          setFormState({ label: "" });
          onCancel();
        }}
        size="small"
      />
    </div>
  );
}
