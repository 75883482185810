import React from "react";
import { resolve } from "inversify-react";

import ObjectTable from "../object-table";

import { AppContext } from "../../context";
import { ObjectHash } from "../../utils/helpers";
import { GridService, IGridService } from "../../services/grid.service";

interface Props {
  gridId: string;
}

interface State {
  travelers: ObjectHash[];
  isLoaded: boolean;
}

export default class ArrivalDeparture extends React.Component<Props, State> {
  @resolve(GridService)
  private gridService!: IGridService;

  constructor(props: Props) {
    super(props);
    this.state = { travelers: [], isLoaded: false };
  }

  async componentDidMount() {
    const { gridId } = this.props;

    const travelers = await this.gridService.getTravelers(gridId);

    this.setState({
      travelers,
      isLoaded: true
    });
  }

  render() {
    const { travelers, isLoaded } = this.state;

    if (!isLoaded) {
      return null;
    }

    // @todo remove after the report is sufficiently debugged
    console.log(travelers);
    console.log(
      travelers.map(
        ({ email, travelerMeta }) => `${email} - ${travelerMeta.matchMethod}`
      )
    );

    return (
      <AppContext.Consumer>
        {(appState) => (
          <div>
            <ObjectTable
              title="Arrivals"
              columns={[
                ["name", "Name"],
                ["arrivalAirline", "Airline"],
                ["arrivalFlightNum", "Flight Number"],
                ["arrivalOrigin", "Arriving From"],
                ["arrivalTime", "Arrival Date/Time"],
                ["arrivalDestination", "Arriving At"]
              ]}
              renderOpts={{
                disableRowClick: true,
                disableDownload: true,
                disablePagination: true,
                disableRowSelect: true,
                disableViewChange: true,
                useReportStyle: true,
                reportAccentColor: "blue"
              }}
              searchOpts={{ resource: "users" }}
              staticResults={travelers.filter((t) => t && t.arrivalTime)}
              company={appState.company}
              templates={appState.templates}
              settings={appState.settings}
              user={appState.user}
            />
            <div style={{ height: "16px" }} />
            <ObjectTable
              title="Departures"
              columns={[
                ["name", "Name"],
                ["departureAirline", "Airline"],
                ["departureFlightNum", "Flight Number"],
                ["departureOrigin", "Departing From"],
                ["departureTime", "Departure Date/Time"],
                ["departureDestination", "Departing To"]
              ]}
              renderOpts={{
                disableRowClick: true,
                disableDownload: true,
                disablePagination: true,
                disableRowSelect: true,
                disableViewChange: true,
                useReportStyle: true,
                reportAccentColor: "blue"
              }}
              searchOpts={{ resource: "users" }}
              staticResults={travelers.filter((t) => t && t.departureTime)}
              company={appState.company}
              templates={appState.templates}
              settings={appState.settings}
              user={appState.user}
            />
            <div style={{ height: "16px" }} />
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}
