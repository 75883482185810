import { inject } from "inversify";
import { isObject } from "lodash";

import BaseService, { IBaseService } from "./base.service";
import { ApiService, IApiService } from "./api.service";
import { ISearchService, SearchService } from "./search.service";
import { ITagService, TagService } from "./tag.service";
import { WebsocketService, IWebsocketService } from "./websocket.service";

import GridModel, { GridDetailModel } from "../models/grid.model";
import DocumentModel from "../models/document.model";
import TravelerModel from "../models/traveler.model";
import UserModel from "../models/user.model";

import { AppActionTypes } from "../context/reducer";
import { ObjectHash, sortByPosition } from "../utils/helpers";

export interface IGridService extends IBaseService {
  create(grid: GridModel): Promise<GridModel | null>;

  update(grid: GridModel): Promise<GridModel | null>;

  delete(gridId: string): Promise<GridModel | null>;

  star(gridId: string): Promise<GridModel | null>;

  unstar(gridId: string): Promise<GridModel | null>;

  getById(gridId: string): Promise<GridModel | null>;

  getByUserId(userId: string): Promise<GridModel[]>;

  getDocuments(gridId: string): Promise<DocumentModel[]>;

  getDetails(gridId: string): Promise<GridDetailModel | null>;

  getTravelers(gridId: string): Promise<TravelerModel[]>;

  searchGrids(keyword: string): Promise<GridModel[]>;

  subscribeWs(dispatch: CallableFunction): Promise<void>;

  uploadRoomBlock(gridId: string, data: ObjectHash): Promise<ObjectHash | null>;
}

export class GridService extends BaseService implements IGridService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(SearchService)
  private searchService!: ISearchService;

  @inject(TagService)
  private tagService!: ITagService;

  @inject(WebsocketService)
  private websocketService!: IWebsocketService;

  async create(grid: GridModel): Promise<GridModel | null> {
    const response = await this.apiService.post("/grids", grid.toJSON());

    if (!response) {
      return null;
    }

    return new GridModel(response);
  }

  async update(grid: GridModel): Promise<GridModel | null> {
    const gridData = grid.toJSON();

    gridData.users = gridData.users.map((user: ObjectHash) => user.id);
    gridData.tag.name = grid.name;

    const response = await this.apiService.put(`/grids/${grid.id}`, gridData);

    if (!response) {
      return null;
    }

    return new GridModel(response);
  }

  async delete(gridId: string): Promise<GridModel | null> {
    const response = await this.apiService.delete(`/grids/${gridId}`);

    if (!response) {
      return null;
    }

    return new GridModel(response);
  }

  async star(gridId: string): Promise<GridModel | null> {
    const response = await this.apiService.post(`/grids/${gridId}/star`);

    if (!response) {
      return null;
    }

    return new GridModel(response);
  }

  async unstar(gridId: string): Promise<GridModel | null> {
    const response = await this.apiService.delete(`/grids/${gridId}/star`);

    if (!response) {
      return null;
    }

    return new GridModel(response);
  }

  async getById(gridId: string): Promise<GridModel | null> {
    const response = await this.apiService.get(`/grids/${gridId}`);

    if (!response) {
      return null;
    }

    return new GridModel(response);
  }

  async getByUserId(userId: string): Promise<GridModel[]> {
    const response = await this.apiService.get("/grids");

    if (!response) {
      return [];
    }

    return response
      .map((data: any) => new GridModel(data))
      .filter(
        (grid: GridModel) =>
          grid.creator === userId ||
          grid.users.map((user: UserModel) => user.id).includes(userId)
      )
      .sort(sortByPosition);
  }

  async getDocuments(gridId: string): Promise<DocumentModel[]> {
    const response = await this.apiService.get(`/grids/${gridId}/documents`);

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new DocumentModel(data));
  }

  async getDetails(gridId: string): Promise<GridDetailModel | null> {
    const response = await this.apiService.get(`/grids/${gridId}/details`);

    if (!response) {
      return null;
    }

    return new GridDetailModel(response);
  }

  async getTravelers(gridId: string): Promise<TravelerModel[]> {
    const response = await this.apiService.get(`/grids/${gridId}/travelers`);

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new TravelerModel(data));
  }

  async searchGrids(keyword: string): Promise<GridModel[]> {
    const searchParams = {
      any: false,
      fields: ["name"],
      filters: [["name", keyword]],
      order: "name"
    };

    const response = await this.searchService.search("grids", searchParams);

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new GridModel(data));
  }

  async subscribeWs(dispatch: CallableFunction): Promise<void> {
    this.websocketService.subscribe(
      "GridUpdates",
      async (data: ObjectHash, initial?: boolean) => {
        if (!initial && isObject(data)) {
          const payload = new GridModel(data);
          const type = payload.deleted
            ? AppActionTypes.DeleteGrid
            : AppActionTypes.UpdateGrid;

          dispatch({ type, payload });

          // reload tag groups to pick up the new/updated grid tag
          const tagGroups = await this.tagService.getCurrentTagGroups();
          dispatch({
            type: AppActionTypes.UpdateTagGroups,
            payload: tagGroups
          });
        }
      }
    );
  }

  async uploadRoomBlock(
    gridId: string,
    data: ObjectHash
  ): Promise<ObjectHash | null> {
    const response = await this.apiService.post(
      `/upload/room-block/${gridId}`,
      data
    );

    if (!response) {
      return null;
    }

    return response;
  }
}
