import React, { useState } from "react";
import { cloneDeep, debounce, uniqueId } from "lodash";

import AppContainer from "../../../../../container";
import AutocompleteInput from "../../../../../components/form/inputs/AutocompleteInput";
import Button from "../../../../../components/button";
import ColorPalettePopper from "../../../../../components/color-palette-popper";
import { ITagService, TagService } from "../../../../../services/tag.service";
import RemoveButton from "../../../../../components/button/remove";
import TagGroupModel from "../../../../../models/tag-group.model";
import TagModel from "../../../../../models/tag.model";
import TextInput from "../../../../../components/form/inputs/TextInput";

import "./tag-group-editor-menu.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import Tooltip from "../../../../../components/tooltip";
import { downloadCSV } from "../../../../../utils/csv";

interface Props {
  disabled?: boolean;
  tagGroup: TagGroupModel;
  onUpdate?: CallableFunction;
}

// removes temporary tag ids from new tags that are yet to be added
const stripTemporaryNewTagIds = (tagGroup: TagGroupModel) => {
  tagGroup.tags = tagGroup.tags.map((tag: TagModel) => {
    if (tag.id.match(/^new-tag-.+$/)) {
      tag.id = "";
    }
    return tag;
  });

  return tagGroup;
};

const multiSelectOpts = [
  { value: true, label: "Multiple Select" },
  { value: false, label: "Single Select" }
];

export default function TagGroupEditorMenu(props: Props) {
  const { disabled, onUpdate, tagGroup } = props;
  const tagService: ITagService = AppContainer.get(TagService);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [formChanged, setFormChanged] = useState(false);
  const [sortableKey, setSortableKey] = useState(0);
  const [filterBy, setFilterBy] = useState<string>("");

  const [tagGroupState, setTagGroupState] = useState(cloneDeep(tagGroup));

  const defaultOpt =
    multiSelectOpts.find(
      (option: any) => option.value === tagGroupState.multiSelect
    ) ?? multiSelectOpts[0];

  const handleClick = (e: any) => {
    setDialogOpen(true);
  };

  const handleUpdate = (updatedTagGroup: TagGroupModel) => {
    setFormChanged(true);
    setTagGroupState(updatedTagGroup);
  };

  const handleAddTag = () => {
    const updatedTagGroup = cloneDeep(tagGroupState);
    updatedTagGroup.tags.push(
      new TagModel({
        // sets a temp id for sorting added tags, must be stripped
        //  out before saving changes via stripTemporaryNewTagIds
        id: uniqueId("new-tag-"),
        position: tagGroupState.tags.length,
        color: tagService.getNewTagColor(tagGroupState.tags),
        tagGroup: tagGroupState.id
      })
    );

    handleUpdate(updatedTagGroup);
    setSortableKey(sortableKey + 1);
    setFilterBy("");
  };

  const handleBulkAddTags = (options: string[]) => {
    const updatedTagGroup = cloneDeep(tagGroupState);

    const knownTags = new Set<string>();
    for (const tag of updatedTagGroup.tags) {
      knownTags.add(tag.name);
    }

    for (const name of options) {
      if (knownTags.has(name)) {
        continue;
      }

      updatedTagGroup.tags.push(
        new TagModel({
          // sets a temp id for sorting added tags, must be stripped
          //  out before saving changes via stripTemporaryNewTagIds
          id: uniqueId("new-tag-"),
          position: tagGroupState.tags.length,
          color: tagService.getNewTagColor(tagGroupState.tags),
          tagGroup: tagGroupState.id,
          name,
          unconfirmed: false
        })
      );
    }

    handleUpdate(updatedTagGroup);
    setSortableKey(sortableKey + options.length);
    setFilterBy("");
  };

  const handleColorChange = (id: string, color: string) => {
    const updatedTagGroup = cloneDeep(tagGroupState);

    const tagIndex = updatedTagGroup.tags.findIndex(
      (tag: TagModel) => tag.id === id
    );
    updatedTagGroup.tags[tagIndex].color = color;
    handleUpdate(updatedTagGroup);
  };

  const handleReset = () => {
    setTagGroupState(tagGroup);
    setFormChanged(false);
    setDialogOpen(false);
  };

  const handleSave = (updatedTagGroup: TagGroupModel) => {
    updatedTagGroup = stripTemporaryNewTagIds(cloneDeep(updatedTagGroup));

    // exclude unnamed tag options that were added unintentionally
    updatedTagGroup.tags = updatedTagGroup.tags.filter(
      (tag: TagModel) => tag.name
    );

    onUpdate && onUpdate(updatedTagGroup);

    setTagGroupState(updatedTagGroup);
    setFormChanged(false);
  };

  const handleSortChange = () => {
    const updatedTagGroup = cloneDeep(tagGroupState);
    const updatedTags = cloneDeep(tagGroupState.tags);
    updatedTags.sort((a, b) => (a.name > b.name ? 1 : -1));

    updatedTagGroup.tags = updatedTags.map((tag: TagModel, index: number) => {
      tag.position = index;
      return tag;
    });

    handleUpdate(updatedTagGroup);
  };

  const handleTagNameChange = (id: string, value: string) => {
    const updatedTagGroup = cloneDeep(tagGroupState);
    const tagIndex = updatedTagGroup.tags.findIndex(
      (tag: TagModel) => tag.id === id
    );
    updatedTagGroup.tags[tagIndex].name = value;
    handleUpdate(updatedTagGroup);
  };

  const handleTagRemove = (id: string) => {
    const updatedTagGroup = cloneDeep(tagGroupState);
    const tagIndex = updatedTagGroup.tags.findIndex(
      (tag: TagModel) => tag.id === id
    );
    updatedTagGroup.tags[tagIndex].deleted = true;
    handleUpdate(updatedTagGroup);
    setSortableKey(sortableKey + 1);
  };

  return (
    <div className={"tag-group-editor-menu"}>
      <div onClick={handleClick}>
        {!tagGroupState.id ? (
          <Button
            color="product-blue"
            icon="plus-circle"
            isRippleDisabled={true}
            isTransparent={true}
            label="New Tag Group"
            size="medium"
          />
        ) : disabled ? (
          <AutocompleteInput
            defaultValue={defaultOpt}
            isDisabled={disabled}
            options={multiSelectOpts}
            isSearchable={false}
            menuIsOpen={false}
          />
        ) : (
          <Button
            color="product-blue"
            isRippleDisabled={true}
            isTransparent={true}
            label="Modify Tag Group"
            size="medium"
          />
        )}
      </div>
      <Dialog
        classes={{
          root: "add-profiles-dialog",
          paper: "paper",
          paperScrollPaper: "paper-scroll-paper",
          paperWidthSm: "paper-width-sm"
        }}
        disableEnforceFocus={true} // allows nested portaled tag select to take focus
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle classes={{ root: "dialog-title" }}>
          Modify Tag Group
        </DialogTitle>
        <DialogContent
          classes={{ root: "dialog-content" }}
          className="not-draggable"
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              margin: "1rem 0"
            }}
          >
            <TextInput
              autoFocus
              defaultValue={tagGroupState.name ? tagGroupState.name : ""}
              onChange={(value: string) => {
                tagGroupState.name = value;
                handleUpdate(tagGroupState);
              }}
              placeholder="Tag Group Name"
              label="Tag Group Name"
              style={{ width: "calc(50% - 1rem)", marginRight: "1rem" }}
            />

            <div style={{ flexGrow: 1 }}>
              <AutocompleteInput
                isSearchable={false}
                defaultValue={defaultOpt}
                options={multiSelectOpts}
                onChange={(option: any) => {
                  tagGroupState.multiSelect = option;
                  handleUpdate(tagGroupState);
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ flexGrow: 1 }}>Tag Group Options</h4>
            <TextInput
              placeholder="Filter tags"
              onChange={debounce((val: string) => setFilterBy(val), 300)}
              style={{ display: "flex", width: 200, marginLeft: "0.5rem" }}
              autoFocus={false}
            />
            <Tooltip text="Sort">
              <div onClick={handleSortChange} style={{ marginLeft: "0.5rem" }}>
                <Button
                  color="dark-gray"
                  isBordered
                  isRippleDisabled
                  isRounded
                  isTransparent
                  icon="sort"
                  size="small"
                />
              </div>
            </Tooltip>
            <Tooltip text="Add">
              <div
                onClick={() => handleAddTag()}
                style={{ marginLeft: "0.5rem" }}
              >
                <Button
                  color="dark-gray"
                  isBordered
                  isRippleDisabled
                  isRounded
                  isTransparent
                  icon="plus"
                  size="small"
                />
              </div>
            </Tooltip>
            <Tooltip text="Download Tags">
              <div
                onClick={() =>
                  downloadCSV(
                    tagGroup.tags
                      .filter((t) => !t.unconfirmed)
                      .map((t) => t.name)
                      .join("\n"),
                    `${tagGroup.name}-tags.csv`
                  )
                }
                style={{ marginLeft: "0.5rem" }}
              >
                <Button
                  color="dark-gray"
                  isBordered
                  isRippleDisabled
                  isRounded
                  isTransparent
                  icon="download"
                  size="small"
                />
              </div>
            </Tooltip>
            <Tooltip text="Bulk Upload Tags">
              <div
                onClick={() => {
                  ((document.querySelector(
                    `input[type=file][name=bulk-${tagGroup.id}]`
                  ) as unknown) as HTMLInputElement)?.click();
                }}
                style={{ marginLeft: "0.5rem" }}
              >
                <Button
                  color="dark-gray"
                  isBordered
                  isRippleDisabled
                  isRounded
                  isTransparent
                  icon="file"
                  size="small"
                />
                <input
                  type="file"
                  name={`bulk-${tagGroup.id}`}
                  style={{ opacity: 0, width: 0, height: 0 }}
                  onChange={async (e) => {
                    try {
                      const file = e.target.files?.item(0);
                      const txt = await file?.text();
                      const options = txt
                        ?.split("\n")
                        .filter((v) => v?.length > 1);
                      if (options?.length) {
                        handleBulkAddTags(options);
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                />
              </div>
            </Tooltip>
          </div>

          {tagGroupState.tags
            .filter(
              (tagOption: TagModel) =>
                !tagOption.deleted &&
                !tagOption.unconfirmed &&
                (!filterBy ||
                  tagOption.name
                    ?.toLowerCase()
                    .includes(filterBy.toLowerCase()))
            )
            .map((tag) => (
              <div
                className=""
                key={tag.id}
                style={{ display: "flex", marginBottom: "0.5rem" }}
              >
                <TextInput
                  defaultValue={tag.name}
                  onChange={debounce(
                    (value: string) => handleTagNameChange(tag.id, value),
                    300
                  )}
                  placeholder="Tag Name"
                  autoFocus={!tag.name}
                  style={{ marginRight: "1rem" }}
                />

                <ColorPalettePopper
                  selected={tag.color}
                  onClick={(color: string) => handleColorChange(tag.id, color)}
                />

                <RemoveButton
                  onClick={() => handleTagRemove(tag.id)}
                  size="small"
                />
              </div>
            ))}

          <br />
          <Button
            alignment="align-center"
            color="product-background-blue"
            icon="plus"
            isFullWidth={true}
            label="Add Option"
            onClick={() => handleAddTag()}
            size="medium"
          />
          <br />
        </DialogContent>
        <DialogActions
          classes={{ root: "dialog-actions add-profiles-dialog__actions" }}
        >
          <Button
            color="gray"
            isFullWidth={true}
            label="Cancel"
            onClick={() => handleReset()}
            size="medium"
          />
          <Button
            color="product-blue"
            isDisabled={!formChanged}
            isFullWidth={true}
            label="Save"
            onClick={() => {
              handleSave(tagGroupState);
              setDialogOpen(false);
            }}
            size="medium"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
