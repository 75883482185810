import React, { useCallback, useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";

import AppContainer from "../../../container";
import AppIcon from "../../app-icon";
import BookingRequestModel from "../../../models/booking-request.model";
import BookingRequestSegmentInput from "../../form/inputs/BookingRequestSegmentInput";
import BookingSegmentModel from "../../../models/booking-segment.model";
import {
  BookingRequestValidator,
  FormConfig,
  formIsValid
} from "../../../utils/form";
import Button from "../../button";
import CloseButton from "../../button/close";
import {
  CollectionService,
  ICollectionService
} from "../../../services/collection.service";
import {
  getDisplayDate,
  LoadState,
  ObjectHash,
  reactSelectCloseOnScroll
} from "../../../utils/helpers";
import InputController from "../../form/input-controller";
import { TemplateInput } from "../../../services/workspace.service";
import Tooltip from "../../tooltip";
import useApp from "../../../hooks/use-app.hook";
import useModal from "../../../hooks/use-modal.hook";
import UserModel from "../../../models/user.model";
import useSnackbar from "../../../hooks/use-snackbar.hook";

import "./booking-request-dialog.scss";

const PaperComponent = (props: ObjectHash) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

const linkedFields: ObjectHash[] = [
  { key: "firstName", label: "First Name" },
  { key: "middleName", label: "Middle Name" },
  { key: "lastName", label: "Last Name" },
  {
    key: "dateOfBirth",
    label: "Date of Birth"
  }
];

const formConfig: FormConfig = {
  validator: BookingRequestValidator,
  fields: [
    {
      key: "status",
      label: "",
      inputType: "booking-request-status"
    },
    {
      key: "bookingType",
      label: "One-way or round-trip?",
      inputType: "booking-type"
    },
    // date input for one-way
    {
      key: "bookingDate",
      label: "Trip Date",
      inputType: "date"
    },
    // date input for round-trip
    {
      key: "bookingDates",
      label: "Trip Dates",
      inputType: "date-range"
    },
    {
      key: "notes",
      label: "Notes",
      inputType: "text"
    }
  ]
};

interface Props {
  requestId: string;
  onChange?: CallableFunction;
}

export default function BookingRequestDialog(props: Props) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );

  const { setSnackbar } = useSnackbar();
  const { closeModal, linkModal, openModal } = useModal();
  const { settings } = useApp();

  const { onChange, requestId } = props;
  const domRef = useRef();

  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [menuPortalTarget, setMenuPortalTarget] = useState<any>(null);
  const [request, setRequest] = useState<BookingRequestModel>(
    new BookingRequestModel()
  );
  const [loadState, setLoadState] = useState<LoadState>("unloaded");

  const loaded = loadState === "loaded";
  const { validator, fields } = formConfig;
  const { users } = request;
  const user = users[0] || new UserModel(); // @todo multiple users per request

  const [formState, setFormState] = useState<ObjectHash>({
    id: requestId,
    status: "initiated"
  });

  const loadRequest = useCallback(
    async (refresh?: boolean) => {
      if (loadState !== "unloaded" && !refresh) {
        return;
      }

      setLoadState("loading");

      const formRequest = await collectionService.getRequestById(requestId);
      if (!formRequest) {
        return;
      }

      const zeroState: ObjectHash = {
        id: formRequest.id
      };

      formConfig.fields.forEach((templateInput: TemplateInput) => {
        const { key } = templateInput;
        let value: any = formRequest[key as keyof BookingRequestModel] || "";

        if (key === "bookingDates") {
          value = formRequest.getDates();
        }

        if (key === "bookingDate") {
          value = formRequest.getDates()[0];
        }

        zeroState[key] = value;
      });

      setRequest(formRequest);
      setFormState(zeroState);
      setLoadState("loaded");
    },
    [loadState, setLoadState, setRequest, requestId, collectionService]
  );

  // dialog deep-link
  useEffect(() => {
    if (request?.id) {
      linkModal("bookingRequest", { requestId: request?.id });
    }
  }, [linkModal, request]);

  // load the request by id when the dialog opens
  useEffect(() => {
    if (loadState === "unloaded") {
      loadRequest();
    }
  }, [loadRequest, loadState]);

  // update form validity for button enable/disable
  useEffect(() => {
    setFormValid(formIsValid(validator, formState));
  }, [formState, validator, setFormValid]);

  const handleClose = (skipConfirm?: boolean) => {
    if (formChanged && !skipConfirm) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "You have unsaved changes. Are you sure you want to close?",
        onConfirm: closeModal
      });
    } else {
      closeModal();
    }
  };

  const handleSave = async () => {
    if (!formIsValid(validator, formState)) {
      return;
    }

    const requestData = {
      ...formState
    };

    // reflect booking date changes from the date input(s) back to the related segments
    delete requestData.bookingDates;
    const { bookingType } = formState;
    const { bookingDate, bookingDates } = formState;

    requestData.bookingSegments = [...request.bookingSegments];

    if (bookingType === "one-way") {
      requestData.bookingSegments[0].startDate = bookingDate || "";
      requestData.bookingSegments[0].endDate = bookingDate || "";
    }

    if (bookingType === "round-trip") {
      requestData.bookingSegments[0].startDate = bookingDates[0] || "";
      requestData.bookingSegments[0].endDate = bookingDates[0] || "";

      requestData.bookingSegments[1].startDate = bookingDates[1] || "";
      requestData.bookingSegments[1].endDate = bookingDates[1] || "";
    }

    const updatedRequest = await collectionService.updateRequest(
      request.id,
      requestData
    );

    if (updatedRequest) {
      onChange && onChange();
      setRequest(request);
      setFormChanged(false);
      setSnackbar({
        message: "Traveler updated!",
        variant: "success"
      });
    } else {
      setSnackbar({
        message: "There was an error and your changes were not saved",
        variant: "error"
      });
    }
  };

  const handleInputChange = (key: string, value: any, meta?: ObjectHash) => {
    const updatedFormState = { ...formState, [key]: value };

    // Sync number of segments and trip dates with booking type changes
    if (key === "bookingType") {
      let updatedSegments = [...request.bookingSegments];
      const segmentLength = updatedSegments.length;
      const firstSegment = updatedSegments[0];
      const { bookingDates } = formState;

      if (value === "one-way") {
        if (segmentLength !== 1) {
          updatedSegments = updatedSegments.slice(0, 1);
        }

        // sync the one-way date input with the first date from the round-trip date range input
        updatedFormState.bookingDate = bookingDates[0] || "";
      }
      if (value === "round-trip") {
        if (segmentLength > 2) {
          updatedSegments = updatedSegments.slice(0, 2);
        }
        if (segmentLength === 1) {
          const newSegment: BookingSegmentModel = new BookingSegmentModel({
            startDate: bookingDates[1] || "",
            startLocation: firstSegment.endLocation,
            endDate: bookingDates[1] || "",
            endLocation: firstSegment.startLocation
          });
          updatedSegments.push(newSegment);
        }

        // sync the first date in the date range input with the one-way date input date
        updatedFormState.bookingDates[0] = updatedFormState.bookingDate;
      }

      setRequest(
        new BookingRequestModel({
          ...request,
          bookingSegments: updatedSegments
        })
      );
    }

    setFormChanged(true);
    setFormState(updatedFormState);
  };

  const handleDelete = () => {
    openModal("confirm", {
      buttonText: "Remove",
      dialogTitle: "Remove Traveler",
      dialogBody:
        "Are you sure you want to remove this traveler from the collection? This cannot be undone.",
      onConfirm: async () => {
        const deletedRequest = await collectionService.deleteRequest(
          request.id
        );
        if (deletedRequest) {
          setSnackbar({
            message: "Traveler removed!",
            variant: "success"
          });
          onChange && onChange();
          handleClose(true);
          return;
        }

        setSnackbar({
          message: "There was a problem and the traveler was not removed.",
          variant: "error"
        });
      }
    });
  };

  const inputElements: JSX.Element[] = [];
  const { bookingType } = formState;

  fields.forEach((templateInput: TemplateInput) => {
    const { key } = templateInput;

    // Remove the date range input for one-way
    if (key === "bookingDates" && bookingType === "one-way") {
      return;
    }

    // Remove the single date input for round-trip
    if (key === "bookingDate" && bookingType === "round-trip") {
      return;
    }

    const customProps: ObjectHash = {
      closeMenuOnScroll: reactSelectCloseOnScroll,
      onChange: (value: any, meta?: ObjectHash) =>
        handleInputChange(key, value, meta),
      menuPortalTarget
    };

    if (key === "bookingDates") {
      customProps.anchorDirection = "left";
    }

    inputElements.push(
      <InputController
        key={`booking-request-form-input-controller-${key}-${loadState}`}
        customProps={customProps}
        loadState={loadState}
        model={formState}
        readOnly={!loaded}
        templateInput={templateInput}
        validator={validator[key]}
      />
    );
  });

  const linkedElements = linkedFields.map((linkedField: ObjectHash) => {
    const { key, label } = linkedField;
    let displayValue = user[key as keyof UserModel] || "";

    if (key === "dateOfBirth" && displayValue) {
      displayValue = getDisplayDate(displayValue, settings.dateFormat);
    }

    return (
      <div className="linked-field" key={`linked-field-${key}`}>
        <div className="label">
          <AppIcon type="cord" color="product-blue" size="x-small" />
          {label}
        </div>
        <div className="value">{displayValue}</div>
      </div>
    );
  });

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      classes={{
        root: "booking-request-dialog",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper",
        paperWidthSm: "paper-width-sm"
      }}
      onClose={() => handleClose()}
      onEntered={() => setMenuPortalTarget(domRef.current)}
      open={true}
      PaperComponent={PaperComponent}
      ref={domRef}
    >
      <DialogTitle
        classes={{ root: "dialog-title" }}
        style={{ cursor: "move" }}
      >
        <div className="title">{loaded && user.getFullName()}</div>
        <div className="buttons">
          <Tooltip text="Delete Request">
            <Button
              color="gray"
              icon="trash"
              isRippleDisabled={true}
              isTransparent={true}
              onClick={() => handleDelete()}
              size="medium"
            />
          </Tooltip>

          <div className="close-button">
            <CloseButton onClick={() => handleClose()} size="medium" />
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <div className="booking-request-form">
          <div className="section status">
            <div className="section-header">Status</div>
            {inputElements.shift()}
          </div>

          <div className="section">
            <div className="section-header">Quicklinks</div>

            {loaded && (
              <Button
                color="product-background-blue"
                label={`View ${user.firstName}'s Traveler Profile`}
                onClick={() => {
                  openModal("user", {
                    userId: user.id,
                    onChange: () => {
                      setLoadState("unloaded");
                      loadRequest(true);
                      onChange && onChange(); // changes to the related user technically change the request by proxy, since they are linked
                    }
                  });
                }}
                size="medium"
              />
            )}
          </div>
          <div className="section">
            <div className="section-header">Pre-trip Details</div>
            {inputElements}
            <div className="booking-segments">
              {loaded && (
                <BookingRequestSegmentInput
                  key={request.bookingSegments.length}
                  request={request}
                  onChange={(segments: BookingSegmentModel[]) => {
                    setFormChanged(true);
                    setRequest(
                      new BookingRequestModel({
                        ...request,
                        bookingSegments: segments
                      })
                    );
                  }}
                />
              )}
            </div>
          </div>

          <div className="section linked">
            <div className="section-header">Additional Profile Information</div>
            <div className="section-subheader">
              This information is linked to the traveler's Tripgrid profile
            </div>
            <div className="linked-fields">{linkedElements}</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="gray"
          isTransparent={true}
          label="Cancel"
          onClick={handleClose}
          size="medium"
        />
        <Button
          color="product-blue"
          isDisabled={!formValid || !formChanged}
          label="Save Changes"
          onClick={() => handleSave()}
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
