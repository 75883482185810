import React from "react";

import Button from "../../../../components/button";
import Tooltip from "../../../../components/tooltip";
import useModal from "../../../../hooks/use-modal.hook";

import "./move-block-menu.scss";

interface Props {
  blockCount: number;
  blockId: string;
  blockDeleteLabel: string;
  onDeleteBlock: CallableFunction;
  onMoveBlock: CallableFunction;
}

export default function MoveBlockMenu(props: Props) {
  const { openModal } = useModal();

  const handleClickMoveBlock = (direction: string) => {
    props.onMoveBlock(direction, props.blockId);
  };

  const handleClickDeleteBlock = () => {
    openModal("confirm", {
      dialogTitle: `Delete ${
        props.blockDeleteLabel ? `${props.blockDeleteLabel} ` : ""
      }Block`,
      dialogBody: "Are you sure you want to delete this block?",
      onConfirm: () => props.onDeleteBlock(props.blockId)
    });
  };

  return (
    <div className="move-block-menu">
      <div
        className="move-block-menu__option move-block-menu__option--move-up"
        key={`move-up-${props.blockId}`}
      >
        <Tooltip text="Move block up">
          <Button
            color="product-blue"
            isRippleDisabled={true}
            isTransparent={true}
            icon="arrow-up"
            onClick={() => handleClickMoveBlock("up")}
            size="medium"
          />
        </Tooltip>
      </div>
      <div
        className="move-block-menu__option move-block-menu__option--move-down"
        key={`move-down-${props.blockId}`}
      >
        <Tooltip text="Move block down">
          <Button
            color="product-blue"
            isRippleDisabled={true}
            isTransparent={true}
            icon="arrow-down"
            onClick={() => handleClickMoveBlock("down")}
            size="medium"
          />
        </Tooltip>
      </div>
      <div
        className="move-block-menu__option move-block-menu__option--delete"
        key={`delete-${props.blockId}`}
      >
        <Tooltip text="Delete block">
          <Button
            color="red"
            isRippleDisabled={true}
            isTransparent={true}
            icon="trash"
            onClick={() => handleClickDeleteBlock()}
            size="medium"
          />
        </Tooltip>
      </div>
    </div>
  );
}
