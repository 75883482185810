import React from "react";
import Button from "@material-ui/core/Button";
import classnames from "classnames";

import AppIcon, { AppIconType } from "../app-icon";
import Tooltip from "../tooltip";

import AppContainer from "../../container";
import {
  IntegrationService,
  IIntegrationService
} from "../../services/integration.service";
import ParsedEmailModel from "../../models/parsed-email.model";

import { ObjectHash } from "../../utils/helpers";

import "./parsed-email.scss";

interface Props {
  email: ParsedEmailModel;
  gridId?: string;
}

export default function ParsedEmail(props: Props) {
  const integrationService: IIntegrationService = AppContainer.get(
    IntegrationService
  );

  const { email, gridId } = props;

  let statusLabel = null, // text display for parsed email
    statusInfo = null, // optional tooltip hover that provides additional info
    appIconType: AppIconType,
    appIconSpins = false;

  if (email.error) {
    statusLabel = "Failed";
    statusInfo = email.message;
    appIconType = "error";
    if (email.canceled) {
      statusInfo += " (Canceled)";
    }
  } else if (email.finished) {
    statusLabel = email.canceled ? "Canceled" : "Success";
    appIconType = email.canceled ? "no-circle" : "checkmark";
  } else if (email.inReview) {
    statusLabel = "Delayed";
    statusInfo = "Sent to manual team";
    appIconType = "clock";
  } else {
    statusLabel = "Parsing";
    appIconType = "refresh";
    appIconSpins = true;
  }

  const cancelEmail = (integrationInstanceId: string) => {
    return integrationService.updateEmailParse(integrationInstanceId, {
      status: "CANCELLED"
    });
  };

  const clearEmail = (integrationInstanceId: string) => {
    return integrationService.updateEmailParse(integrationInstanceId, {
      cleared: true
    });
  };

  const newIntercomMessage = (email: ObjectHash) => {
    if (!(window as any).Intercom) {
      console.error("Intercom not available");
      return;
    }

    let idLine = "";

    if (gridId) {
      idLine = `GridId: ${gridId}\n`;
    }

    const message = `${idLine}Subject: ${email.mailgunSubject}\nFailure Message: ${email.message}\n\nQuestion: What do I do now?`;

    (window as any).Intercom("showNewMessage", message);
  };

  return (
    <div
      className={classnames("parsed-email", {
        "in-review": !email.finished && email.inReview,
        canceled: email.finished && email.canceled,
        completed: email.finished && !email.error && !email.canceled,
        failed: email.finished && email.error,
        finished: email.finished
      })}
    >
      <div className="email">
        <AppIcon type={appIconType} spin={appIconSpins} size="small" />
        <div className="email__status-label">
          <h4>{statusLabel}</h4>
          {statusInfo && (
            <Tooltip text={statusInfo} placement="top">
              <span className="tooltip">
                <AppIcon type="info" size="small" />
              </span>
            </Tooltip>
          )}
        </div>
        <Tooltip text={`SL: ${email.mailgunSubject}`} placement="top">
          <h4 className="email__subject-label">{email.mailgunSubject}</h4>
        </Tooltip>
        <div className="buttons">
          {!email.finished &&
            (email.inReview || (!email.error && !email.inReview)) && (
              <Button
                className="email__action-button parsing-action-button"
                onClick={() => cancelEmail(email.id)}
              >
                Cancel Parsing
              </Button>
            )}
          {email.finished && email.error && (
            <Button
              className="email__action-button parsing-action-button"
              onClick={() => newIntercomMessage(email)}
            >
              Contact Support
            </Button>
          )}
        </div>
      </div>
      {email.finished && (
        <div className="clear-button" onClick={() => clearEmail(email.id)}>
          Dismiss
        </div>
      )}
    </div>
  );
}
