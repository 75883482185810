import React from "react";
import { ActionMeta, components } from "react-select";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

import AppContainer from "../../../../container";
import AppIcon from "../../../app-icon";
import {
  customReactSelectTheme,
  customStyle
} from "../../../form/inputs/custom-theme";
import { IUserService, UserService } from "../../../../services/user.service";
import Loader from "../../../loader";
import {
  reactSelectCloseOnScroll,
  SelectOption
} from "../../../../utils/helpers";
import UserModel from "../../../../models/user.model";

const Control = ({ children, ...props }: any) => (
  <components.Control className="value-container" {...props}>
    <AppIcon type="add-user" />
    {children}
  </components.Control>
);

interface Props {
  menuPortalTarget?: HTMLElement | null;
  onAdd: (userId: string) => Promise<boolean>;
}

export default function UserAddInput(props: Props) {
  const userService: IUserService = AppContainer.get(UserService);

  const { menuPortalTarget = document.body, onAdd } = props;

  const getOptions = debounce(
    (inputValue: string, callback: CallableFunction) => {
      if (!inputValue) {
        return;
      }

      userService.search(inputValue).then((travelers) => {
        const searchedOptions = travelers.map((user: UserModel) => ({
          label: user.getFullName(),
          value: user.id,
          meta: user
        }));

        return callback(searchedOptions);
      });
    },
    300
  );

  const handleChange = async (options: any, meta: ActionMeta<SelectOption>) => {
    const userId = ((options as SelectOption) || {}).value;
    if (!userId) {
      return;
    }

    return onAdd(userId as string);
  };

  return (
    <div className="user-add-input">
      <AsyncSelect
        cacheOptions
        classNamePrefix="tg-input"
        closeMenuOnScroll={reactSelectCloseOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />,
          Control
        }}
        defaultOptions={[]}
        isClearable={false}
        isSearchable={true}
        loadOptions={getOptions}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? "No travelers found" : null
        }
        onChange={handleChange}
        placeholder="Add a traveler"
        styles={customStyle}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
        value={[]}
      />
    </div>
  );
}
