import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

import AirportModel from "../../../models/airport.model";
import AppContainer from "../../../container";
import Button from "../../button";
import CloseButton from "../../button/close";
import {
  FlightService,
  IFlightService
} from "../../../services/flight.service";
import FormController from "../../form/form-controller";
import {
  getDisplayDate,
  getTimestamp,
  LoadState,
  ObjectHash
} from "../../../utils/helpers";
import { TemplateInput } from "../../../services/workspace.service";
import ProTip from "../../pro-tip";
import { TmlLogModel, TmlLogSettings } from "./lib/models";
import Tooltip from "../../tooltip";
import { updateLog } from "./lib/service";
import useModal from "../../../hooks/use-modal.hook";

interface Props {
  log: TmlLogModel;
  onSave: CallableFunction;
}

const formInputs: TemplateInput[] = [
  {
    inputType: "airport-select",
    isRequired: true,
    key: "locations",
    label: "Which Airports are your travelers arriving at?"
  },

  {
    inputType: "date-range",
    isRequired: true,
    key: "dates",
    label: "Select the dates of travel for this log."
  },

  {
    inputType: "text",
    key: "targetLocation",
    label: "Location"
  }
];

const ModelValidator: ObjectHash = {
  dates: {
    rule: { dateRange: {} }
  },
  locations: {
    rule: {
      arrayOfLength: {
        length: 1,
        message: "You must enter at least one location"
      }
    }
  }
};

export default function EditSettingsDialog(props: Props) {
  const flightService: IFlightService = AppContainer.get(FlightService);
  const { closeModal } = useModal();
  const { log, onSave } = props;
  const { settings } = log;
  const domRef = useRef();
  const formRef = useRef();

  const [airports, setAirports] = useState<AirportModel[]>([]);
  const [formValid, setFormValid] = useState(false);
  const [loadState, setLoadState] = useState<LoadState>("unloaded");
  const [portalTarget, setPortalTarget] = useState<RefObject<HTMLElement>>();

  const handleSave = async (formState: ObjectHash) => {
    const { dates, locations, targetLocation } = formState;

    const updatedLog = new TmlLogModel({ ...log });
    updatedLog.settings = new TmlLogSettings({
      targetLocation,
      dates: [
        getTimestamp(`${dates[0]}T00:00:00Z`),
        getTimestamp(`${dates[1]}T23:59:59Z`)
      ],
      locations: locations.map((airport: AirportModel) => airport.id),
      updatedAt: getTimestamp()
    });

    updateLog(updatedLog).then((response: TmlLogModel | null) => {
      if (!response) {
        return;
      }
      onSave(response);
      handleClose();
    });
  };

  const handleCustomProps = (
    templateInput: TemplateInput,
    customProps: ObjectHash
  ): ObjectHash => {
    const { key } = templateInput;

    if (key === "locations") {
      customProps.width = 400;
      customProps.menuPortalTarget = portalTarget; // for react-select menus, renders on top of modal to prevent clipping
    }
    return customProps;
  };

  const handleClose = () => {
    closeModal();
  };

  useEffect(() => {
    if (loadState !== "unloaded") {
      return;
    }
    const { locations } = settings;

    flightService
      .getAirportsByIds(locations)
      .then((response: AirportModel[]) => {
        setAirports(response);
        setLoadState("loaded");
      });
  }, [flightService, loadState, setAirports, settings]);

  const { dates, locations, targetLocation } = settings;
  const formState = {
    dates: dates.map((date: number) => getDisplayDate(date, "yyyy-MM-dd")),
    locations: locations
      .map((airportId: string) =>
        airports.find((airport: AirportModel) => airport.id === airportId)
      )
      .filter((v) => v),
    targetLocation
  };

  return (
    <Dialog
      classes={{
        root: "tml-settings-dialog",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper",
        paperWidthSm: "paper-width-sm"
      }}
      disableEnforceFocus={true}
      onEntered={() => setPortalTarget(domRef.current)}
      open={true}
      ref={domRef}
    >
      <DialogTitle classes={{ root: "dialog-title" }}>
        <div className="title">Edit Settings</div>

        <div className="buttons">
          <div className="close-button">
            <Tooltip text="Close">
              <CloseButton onClick={() => handleClose()} size="medium" />
            </Tooltip>
          </div>
        </div>
      </DialogTitle>

      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <FormController
          fields={formInputs}
          loadState={loadState}
          model={formState}
          onCustomProps={handleCustomProps}
          onSave={handleSave}
          validator={ModelValidator}
          onValidate={setFormValid}
          ref={formRef}
        />

        <ProTip text="Granting view access will give someone the ability to view all information within this collection, including traveler profiles." />
      </DialogContent>

      <DialogActions>
        <Button
          color="gray"
          isTransparent={true}
          label="Close"
          onClick={() => handleClose()}
          size="medium"
        />
        <Button
          color="product-blue"
          isDisabled={!formValid}
          label="Save Changes"
          onClick={() => {
            formRef.current && (formRef.current as any).save();
          }}
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
