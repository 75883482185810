import React from "react";
import "./loader.scss";

// http://tobiasahlin.com/spinkit/

export interface Props {
  type: string;
}

export default function Loader(props: Props) {
  if (props.type === "dots") {
    return (
      <div className="loader chasing-dots">
        <div className="dot1" />
        <div className="dot2" />
      </div>
    );
  }

  if (props.type === "spinner") {
    return (
      <div className="loader spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    );
  }

  return null;
}
