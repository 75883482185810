import React from "react";
import AutocompleteInputFilled from "./AutocompleteInputFilled";
import {
  getSelectValueFromOptions,
  SelectOption
} from "../../../utils/helpers";
import "./inputs.scss";

interface Props {
  defaultValue?: any[];
  isRequired?: boolean;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  value?: any;
}

export const BookingTypeOpts: SelectOption[] = [
  { value: "one-way", label: "One-way" },
  { value: "round-trip", label: "Round-trip" }
  // { value: "multi-city", label: "Multi-city" }
];

export default function BookingTypeInput(props: Props) {
  const valueProps = getSelectValueFromOptions(props, BookingTypeOpts);
  const acProps = { ...props, ...valueProps };
  return <AutocompleteInputFilled {...acProps} options={BookingTypeOpts} />;
}
