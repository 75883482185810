import React from "react";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import AppIcon from "../../app-icon";
import { ObjectHash } from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  onChange: CallableFunction;
  options?: any[];
  placeholder?: string;
}

export default function AutocompleteInputCheckbox(props: Props) {
  const { menu, menuPortal } = customStyle;

  const optionStyles = {
    control: (styles: ObjectHash) => ({
      ...styles,
      borderWidth: "2px",
      cursor: "pointer",
      height: "100%",
      minHeight: "40px",
      width: "184px",
      paddingLeft: "8px",
      paddingRight: "8px",
      "&:hover": {
        backgroundColor: Colors.backgroundGray
      }
    }),
    option: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "white !important",
      cursor: "pointer",
      ".checkbox-input-option-wrapper": {
        display: "flex",
        alignItems: "center"
      },
      ".checkbox-input-option__checkbox": {
        padding: "4px",
        marginRight: "4px",
        marginLeft: "-4px"
      },
      ".checkbox-input-option__text": {
        whiteSpace: "nowrap" as any
      }
    }),
    placeholder: (styles: ObjectHash) => ({
      ...styles,
      color: Colors.black
    }),
    menu,
    menuPortal
  };

  const options = props.options || [];

  const handleChange = (option: { [index: string]: string }) => {
    props.onChange(option.value);
  };

  const formatOptionLabel = (option: ObjectHash) => (
    <div className="checkbox-input-option-wrapper">
      <Checkbox
        checked={option.isChecked || false}
        className="checkbox-input-option__checkbox"
        color="primary"
      />
      <span className="checkbox-input-option__text">{option.label}</span>
    </div>
  );

  return (
    <Select
      className="autocomplete-input-checkbox"
      closeMenuOnSelect={false}
      components={{
        DropdownIndicator: () => <AppIcon type="menu-arrow" />,
        IndicatorSeparator: () => null
      }}
      formatOptionLabel={formatOptionLabel}
      hideSelectedOptions={false}
      isSearchable={false}
      onChange={(option: any) => handleChange(option)}
      options={options}
      placeholder={props.placeholder || "Select..."}
      styles={optionStyles}
      tabSelectsValue={false}
      theme={customReactSelectTheme}
      value={[]}
    />
  );
}
