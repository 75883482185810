import { DateTime } from "luxon";
import { flatten } from "lodash";
import { inject } from "inversify";

import AirportModel from "../models/airport.model";
import { AppContextService, IAppContextService } from "./app-context.service";
import BaseService, { IBaseService } from "./base.service";
import { ISearchService, SearchService } from "./search.service";
import { IUserService, UserService } from "./user.service";
import { FlightService, IFlightService } from "./flight.service";
import GridModel from "../models/grid.model";
import TagGroupModel from "../models/tag-group.model";
import TravelFilterModel, {
  TravelFilterGroups,
  TravelFilterFields,
  TravelFilterResources
} from "../models/travel-filter.model";
import TravelQueryModel, {
  TravelQueryParam
} from "../models/travel-query.model";
import UserModel from "../models/user.model";

import { ObjectHash, enumKeys } from "../utils/helpers";

import TravelerFilter from "../components/travel-filter/filters/travelers.filter";
import TravelDateAfter from "../components/travel-filter/filters/travel-date-after.filter";
import TravelDateBefore from "../components/travel-filter/filters/travel-date-before.filter";
import TravelDateOn from "../components/travel-filter/filters/travel-date-on.filter";
import AirportDepartFilter from "../components/travel-filter/filters/airport-depart.filter";
import AirportArriveFilter from "../components/travel-filter/filters/airport-arrive.filter";
import GdsLocatorFilter from "../components/travel-filter/filters/gds-locator.filter";
import ConfirmationNumberFilter from "../components/travel-filter/filters/confirmation-number.filter";
import TagFilter from "../components/travel-filter/filters/tag.filter";
import TemplateModel from "../models/template.model";

export type TravelQueryMeta = {
  airports: Map<string, AirportModel>;
  users: Map<string, UserModel>;
};

export interface ITravelQueryService extends IBaseService {
  getActiveFilters(): TravelFilterModel[];

  getActiveFiltersByGroup(resource: TravelFilterResources): ObjectHash;

  getFilterByField(
    resource: TravelFilterResources,
    field: TravelFilterFields
  ): TravelFilterModel | null;

  getQueryMeta(travelQuery: TravelQueryModel): Promise<TravelQueryMeta>;

  getStandardGridQuery(grid: GridModel): TravelQueryModel;

  gridHasStandardQuery(grid: GridModel): boolean;

  getSearchServiceParams(
    travelQuery: TravelQueryModel,
    useDataFreezeDate?: boolean
  ): ObjectHash;

  search(travelQuery: TravelQueryModel): Promise<any[]>;

  getSearchCount(travelQuery: TravelQueryModel): Promise<number>;

  upsertQueryParam(
    query: TravelQueryModel,
    param: TravelQueryParam
  ): TravelQueryModel;
}

export class TravelQueryService extends BaseService
  implements ITravelQueryService {
  @inject(AppContextService)
  private appContextService!: IAppContextService;

  @inject(SearchService)
  private searchService!: ISearchService;

  @inject(UserService)
  private userService!: IUserService;

  @inject(FlightService)
  private flightService!: IFlightService;

  getActiveFilters(): TravelFilterModel[] {
    const { company, settings } = this.appContextService.get();
    const { features } = company;
    const { tagGroups } = settings;

    const activeFilters = [
      TravelerFilter,
      TravelDateAfter,
      TravelDateBefore,
      TravelDateOn,
      AirportDepartFilter,
      AirportArriveFilter,
      GdsLocatorFilter,
      ConfirmationNumberFilter
    ].map(
      (filterModel: TravelFilterModel) =>
        new TravelFilterModel({ ...filterModel })
    );

    // dynamically generate tag group filters for both trip and user filter types
    tagGroups.forEach((tagGroup: TagGroupModel) => {
      if (tagGroup.hidden) {
        return;
      }
      const tagFilterTrip = this.getTagFilterModel(
        tagGroup,
        TravelFilterResources.Trip
      );
      activeFilters.push(tagFilterTrip);

      const tagFilterUser = this.getTagFilterModel(
        tagGroup,
        TravelFilterResources.User
      );
      activeFilters.push(tagFilterUser);
    });

    return activeFilters.filter((filterModel: TravelFilterModel) => {
      // only companies with integrations use GDS Locator
      if (
        !features.integrations &&
        filterModel.id === TravelFilterFields.GdsLocator
      ) {
        return false;
      }

      return true;
    });
  }

  getActiveFiltersByGroup(resource: TravelFilterResources): ObjectHash[] {
    const filterGroups: ObjectHash[] = [];
    const activeFilters = this.getActiveFilters();

    for (const groupKey of enumKeys(TravelFilterGroups)) {
      const groupName = TravelFilterGroups[groupKey];

      let groupFilters = activeFilters.filter(
        (activeFilter: TravelFilterModel) => activeFilter.group === groupName
      );

      groupFilters = groupFilters.filter(
        (filter: TravelFilterModel) => filter.resource === resource
      );

      if (!groupFilters.length) {
        continue;
      }

      filterGroups.push({
        group: groupKey,
        name: groupName,
        filters: groupFilters
      });
    }

    return filterGroups;
  }

  getFilterByField(
    resource: TravelFilterResources,
    field: TravelFilterFields
  ): TravelFilterModel | null {
    const filterModel = this.getActiveFilters().find(
      (filter: TravelFilterModel) =>
        filter.resource === resource && filter.field === field
    );

    if (!filterModel) {
      return null;
    }

    return filterModel;
  }

  async getQueryMeta(travelQuery: TravelQueryModel): Promise<TravelQueryMeta> {
    const queryMeta: TravelQueryMeta = {
      airports: new Map(),
      users: new Map()
    };

    let userIds: string[] = [];
    let airportIds: string[] = [];

    travelQuery.params.forEach((param: TravelQueryParam) => {
      const { field, value } = param;
      if (field === TravelFilterFields.Travelers) {
        userIds.push(value);
      } else if (
        [
          TravelFilterFields.AirportArrive,
          TravelFilterFields.AirportDepart
        ].includes(param.field)
      ) {
        airportIds.push(value);
      }
    });

    if (userIds.length) {
      const response = await this.userService.getByIds(flatten(userIds));
      if (response) {
        response.forEach((user: UserModel) => {
          queryMeta.users.set(user.id, user);
        });
      }
    }

    if (airportIds.length) {
      const response = await this.flightService.getAirportsWithCustom(
        flatten(airportIds)
      );

      if (response) {
        response.forEach((airport: AirportModel) => {
          queryMeta.airports.set(airport.getFullName(), airport);
        });
      }
    }

    return queryMeta;
  }

  getStandardGridQuery(grid: GridModel): TravelQueryModel {
    const { tagGroups } = this.appContextService.get().settings;
    const query = new TravelQueryModel({ id: "new-query" });

    const gridTagGroup = tagGroups.find((tagGroup: TagGroupModel) =>
      tagGroup.isGridTagGroup()
    );

    if (!gridTagGroup) {
      return query;
    }

    const tagFilterModel = this.getTagFilterModel(
      gridTagGroup,
      TravelFilterResources.Trip
    );

    const standardParam = new TravelQueryParam({
      resource: TravelFilterResources.Trip,
      field: tagFilterModel.field,
      value: ["new-grid-tag"],
      position: 1,
      article: "is",
      connect: "or",
      meta: { systemGridParam: true, readOnly: true }
    });

    query.params.push(standardParam);

    return query;
  }

  gridHasStandardQuery(grid: GridModel): boolean {
    const standardParam = grid.query.params.find(
      (param: TravelQueryParam) => param.meta?.systemGridParam
    );

    if (standardParam) {
      return true;
    }

    return false;
  }

  getSearchServiceParams(
    travelQuery: TravelQueryModel,
    useDataFreezeDate?: boolean
  ): ObjectHash {
    const { dataFreezeDate } = this.appContextService.get().settings;
    const templates = this.appContextService.get().templates;

    const searchParams: ObjectHash = {
      limit: travelQuery.limit,
      order: travelQuery.order,
      reverse: travelQuery.reverse,
      filters: [],
      fields: [
        "cancelled",
        "users",
        "deleted",
        "type",
        "name",
        "fromLocation",
        "toLocation",
        "from",
        "to",
        "confirmation",
        "price",
        "fromTimezone",
        "toTimezone",
        "company",
        "icon",
        "duration",
        "createdAt",
        "updatedAt"
      ],
      gridTagId: null
    };

    /*
     * Ensure that all template fields for all trip types are pulled by adding them to
     * the fields param. The API will automatically disregard those which are not
     * revelent to the query.
     */

    if (templates?.length) {
      templates.forEach((template: TemplateModel) => {
        if (template.properties) {
          template.properties
            .filter((prop: ObjectHash) => !prop.isHidden && !prop.isDeleted)
            .filter(
              (prop: ObjectHash) => !searchParams.fields.includes(prop.name)
            )
            .forEach((prop: ObjectHash) => searchParams.fields.push(prop.name));
        }
      });
    }

    if (useDataFreezeDate && dataFreezeDate) {
      const afterTime = DateTime.fromISO(dataFreezeDate).valueOf();
      const beforeTime = 9999999999999;
      searchParams.filters.push(["bookedDateTime", [afterTime, beforeTime]]);
    }

    const tripSearchFieldMap: Map<TravelFilterFields, string> = new Map([
      [TravelFilterFields.AirportDepart, "fromLocation"],
      [TravelFilterFields.AirportArrive, "toLocation"],
      [TravelFilterFields.GdsLocator, "gdsLocator"],
      [TravelFilterFields.ConfirmationNumber, "confirmation"],
      [TravelFilterFields.Tag, "tags"],
      [TravelFilterFields.TravelDateAfter, "dates"],
      [TravelFilterFields.TravelDateBefore, "dates"],
      [TravelFilterFields.TravelDateOn, "dates"],
      [TravelFilterFields.Travelers, "users"],
      [TravelFilterFields.Type, "type"]
    ]);

    const userSearchFieldMap: Map<TravelFilterFields, string> = new Map([
      [TravelFilterFields.Tag, "users.tags"]
    ]);

    let afterDate = "";
    let beforeDate = "";
    let exactDate = "";
    const tripTagIds: string[] = [];
    const userTagIds: string[] = [];

    travelQuery.params.forEach((param: TravelQueryParam) => {
      let { field, value, resource } = param;
      const searchFieldMap =
        resource === TravelFilterResources.Trip
          ? tripSearchFieldMap
          : userSearchFieldMap;

      // tag fields are suffixed with their parent tag group id
      if (field.match(/^tag-.+/)) {
        field = TravelFilterFields.Tag;
      }

      const searchField = searchFieldMap.get(field);

      if (!searchField) {
        return;
      }

      switch (field) {
        case TravelFilterFields.Type:
          const typeFilterIndex = searchParams.filters.findIndex(
            (filter: any[]) => filter[0] === field
          );
          const typeVal = String(value).toUpperCase();

          if (typeFilterIndex === -1) {
            searchParams.filters.push(["type", typeVal]);
            return;
          }

          searchParams.filters[typeFilterIndex][1] = typeVal;

          break;

        case TravelFilterFields.Travelers:
        case TravelFilterFields.AirportDepart:
        case TravelFilterFields.AirportArrive:
        case TravelFilterFields.GdsLocator:
        case TravelFilterFields.ConfirmationNumber:
          if (!Array.isArray(value)) {
            value = [value];
          }

          let filterIndex = searchParams.filters.findIndex(
            (filter: any[]) => filter[0] === field
          );

          if (filterIndex === -1) {
            searchParams.filters.push([searchField, value]);
            return;
          }

          let filterValues = searchParams.filters[filterIndex][1];
          filterValues = filterValues.concat(value);
          searchParams.filters[filterIndex][1] = filterValues;
          break;

        case TravelFilterFields.TravelDateAfter:
          if (
            !afterDate ||
            DateTime.fromISO(value) > DateTime.fromISO(afterDate)
          ) {
            afterDate = value;
          }
          break;

        case TravelFilterFields.TravelDateBefore:
          if (
            !beforeDate ||
            DateTime.fromISO(value) < DateTime.fromISO(beforeDate)
          ) {
            beforeDate = value;
          }
          break;

        case TravelFilterFields.TravelDateOn:
          exactDate = value;
          break;

        case TravelFilterFields.Tag:
          if (!Array.isArray(value)) {
            value = [value];
          }

          // handling for the special grid tag parameter for grid-related queries
          if (param.meta?.systemGridParam) {
            searchParams.gridTagId = value[0];
            return;
          }

          if (resource === TravelFilterResources.Trip) {
            tripTagIds.push(value);
          } else {
            userTagIds.push(value);
          }
      }
    });

    if (tripTagIds.length) {
      searchParams.filters.push([
        travelQuery.params.find(
          (p) => p.field.startsWith("tag") && p.article === "is not"
        )
          ? "!tags"
          : "tags",
        tripTagIds
      ]);
    }

    if (userTagIds.length) {
      searchParams.filters.push(["users.tags", userTagIds]);
    }

    if (exactDate) {
      // bookends are the beginning and end epoch values of the given date
      searchParams.filters.push([
        "dates",
        [
          [
            DateTime.fromISO(`${exactDate}T00:00:00Z`).valueOf(),
            DateTime.fromISO(`${exactDate}T23:59:59Z`).valueOf()
          ]
        ]
      ]);
    } else if (afterDate || beforeDate) {
      // sets the bookends to outer bounds (converts a date between filter into after/before if start or end range value is not provided)
      let afterTimestamp = 0;
      let beforeTimestamp = 9999999999999;

      if (afterDate) {
        afterTimestamp = DateTime.fromISO(`${afterDate}T23:59:59Z`).valueOf();
      }
      if (beforeDate) {
        beforeTimestamp = DateTime.fromISO(`${beforeDate}T00:00:00Z`).valueOf();
      }

      searchParams.filters.push(["dates", [[afterTimestamp, beforeTimestamp]]]);
    }

    return searchParams;
  }

  async search(travelQuery: TravelQueryModel): Promise<any[]> {
    const searchParams = this.getSearchServiceParams(travelQuery);

    return this.searchService.search("trips", searchParams);
  }

  async getSearchCount(travelQuery: TravelQueryModel): Promise<number> {
    const searchParams = this.getSearchServiceParams(travelQuery);
    return this.searchService.getSearchCount("trips", searchParams);
  }

  upsertQueryParam(
    query: TravelQueryModel,
    param: TravelQueryParam
  ): TravelQueryModel {
    const updatedQuery = new TravelQueryModel({ ...query });
    const { field } = param;

    const paramIndex = updatedQuery.params.findIndex(
      (updatedParam: TravelQueryParam) => updatedParam.field === field
    );

    if (paramIndex === -1) {
      const position = updatedQuery.params.length + 1;
      updatedQuery.params.push(
        new TravelQueryParam({
          ...param,
          position
        })
      );
    } else {
      const position = updatedQuery.params[paramIndex].position;
      updatedQuery.params[paramIndex] = new TravelQueryParam({
        ...param,
        position
      });
    }

    return updatedQuery;
  }

  private getTagFilterModel(
    tagGroup: TagGroupModel,
    resource: TravelFilterResources
  ): TravelFilterModel {
    const filterModel = new TravelFilterModel({
      ...TagFilter,
      resource: resource,
      field: ["tag", tagGroup.id].join("-"),
      id: [resource, TagFilter.field, tagGroup.id].join("-"),
      name: tagGroup.name,
      meta: { tagGroup }
    });

    return filterModel;
  }
}
