import React from "react";
import Draggable from "react-draggable";
import { cloneDeep } from "lodash";
import { resolve } from "inversify-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import AppIcon from "../../app-icon";
import { AppSettings, withAppContext } from "../../../context";
import { AppActionTypes } from "../../../context/reducer";
import Button from "../../button";
import ColorPalette from "../../color-palette";
import { FilterRowParam } from "../../travel-filter";

import { ITagService, TagService } from "../../../services/tag.service";
import {
  ITravelQueryService,
  TravelQueryMeta,
  TravelQueryService
} from "../../../services/travel-query.service";

import GridModel from "../../../models/grid.model";
import { GridService, IGridService } from "../../../services/grid.service";
import GridTipsCarousel from "./grid-tips-carousel";
import { LoadState, ObjectHash, SelectOption } from "../../../utils/helpers";
import TagChip from "../../tag-chip";
import TagGroupModel from "../../../models/tag-group.model";
import TagModel from "../../../models/tag.model";
import TextInput from "../../form/inputs/TextInput";
import ToggleText from "../../toggle-text";
import TravelerInput from "../../form/inputs/TravelerInput";
import TravelFilter from "../../travel-filter";
import TravelQueryModel, {
  TravelQueryParam
} from "../../../models/travel-query.model";
import UserModel from "../../../models/user.model";
import { withSnackbarContext } from "../../../context/snackbar";

import "./grid-dialog.scss";
import AirportSelect from "../../airport-select";
import DateRangeSelect from "../../date-range-select";
import AirportModel from "../../../models/airport.model";

const PaperComponent = (props: ObjectHash) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

type FilterModes = "standard" | "advanced";

interface Props {
  closeModal: CallableFunction;
  dispatch: CallableFunction;
  grid?: GridModel;
  onChangeGrid?: CallableFunction;
  openModal: CallableFunction;
  setSnackbar: CallableFunction;
  settings: AppSettings;
  travelQuery?: TravelQueryModel;
}

interface State {
  formChanged: boolean;
  formValid: boolean;
  grid: GridModel;
  gridChanged: boolean;
  isGridNameUnique: boolean;
  isNewGrid: boolean;
  loadState: LoadState;
  menuPortalTarget: HTMLElement | null;
  queryMeta: TravelQueryMeta;
  selectedUserOpts: SelectOption[];
  selectedFilter: FilterModes;
}

class GridDialog extends React.Component<Props, State> {
  @resolve(GridService)
  private gridService!: IGridService;

  @resolve(TagService)
  private tagService!: ITagService;

  @resolve(TravelQueryService)
  private travelQueryService!: ITravelQueryService;

  private modalRef: React.RefObject<HTMLElement>;
  private toggleTextRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    const grid: GridModel = props.grid
      ? cloneDeep(props.grid)
      : new GridModel();

    const isNewGrid = !Boolean(grid.id);

    this.state = {
      formChanged: false,
      formValid: this.validateGrid(grid),
      grid,
      gridChanged: false,
      isGridNameUnique: true,
      isNewGrid,
      loadState: isNewGrid ? "loaded" : "unloaded",
      menuPortalTarget: null,
      queryMeta: {
        airports: new Map(),
        users: new Map()
      },
      selectedUserOpts: grid.users.map((user: UserModel) => ({
        label: user.name,
        value: user.id,
        meta: user
      })),
      selectedFilter: grid.id ? "advanced" : "standard"
    };

    this.modalRef = React.createRef();
    this.toggleTextRef = React.createRef();
  }

  componentDidMount() {
    const { settings, travelQuery } = this.props;
    const { grid } = this.state;

    if (!grid.id) {
      let selectedFilter: FilterModes = "standard";
      grid.tag.id = "new-grid-tag";
      grid.tag.name = "Grid Tag";

      grid.tag.color = this.tagService.getNewTagColor(
        settings.grids.map((grid: GridModel) => grid.tag)
      );

      grid.query = this.travelQueryService.getStandardGridQuery(grid);

      if (travelQuery) {
        grid.query.params = grid.query.params.concat(travelQuery.params);
        selectedFilter = "advanced";
      }

      this.setState({ grid, selectedFilter, loadState: "loaded" });
      return;
    }

    this.travelQueryService
      .getQueryMeta(grid.query)
      .then((queryMeta: TravelQueryMeta) => {
        this.setState({ queryMeta, loadState: "loaded" });
      });
  }

  closeModal = () => {
    const { closeModal, onChangeGrid } = this.props;
    const { grid, gridChanged } = this.state;

    closeModal();

    gridChanged && onChangeGrid && onChangeGrid(cloneDeep(grid));
  };

  handleClose = (skipConfirm?: boolean) => {
    const { openModal } = this.props;

    if (this.state.formChanged && !skipConfirm) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "You have unsaved changes. Are you sure you want to close?",
        onConfirm: this.closeModal
      });

      return;
    }

    this.closeModal();
  };

  handleUpdateModel = (grid: GridModel) => {
    this.setState({
      grid,
      formChanged: true,
      formValid: this.validateGrid(grid)
    });
  };

  // @todo this belongs in GridModel?
  validateGrid(grid: GridModel) {
    let gridValid = false;

    if (grid.name?.length) {
      gridValid = true;
    }

    // @todo other requirements to create a grid?

    return gridValid;
  }

  validateGridNameUnique(name: string) {
    let gridNameUnique = true;

    const { grid = { id: "" } } = this.props;
    const { grids } = this.props.settings;

    const gridNames = grids
      .filter((g: GridModel) => !g.deleted && g.id !== grid.id) // allow deleted grid names to be reused, ignore current grid's name if editing
      .map((grid: GridModel) => grid.name.toLowerCase());

    if (gridNames.includes(name.toLowerCase())) {
      gridNameUnique = false;
    }

    this.setState({
      isGridNameUnique: gridNameUnique
    });
  }

  handleSubmit = async (): Promise<boolean> => {
    const { dispatch, setSnackbar } = this.props;
    const { grid, isNewGrid, formChanged } = this.state;
    if (!this.validateGrid(grid) || !formChanged) {
      return false;
    }

    let updatedGrid: GridModel | null;

    if (isNewGrid) {
      updatedGrid = await this.gridService.create(grid);
    } else {
      updatedGrid = await this.gridService.update(grid);
    }

    if (!updatedGrid) {
      setSnackbar({
        message: "There was an error and the Grid was not updated.",
        variant: "error"
      });

      return false;
    }

    dispatch({
      type: AppActionTypes.UpdateGrid,
      payload: updatedGrid
    });

    // reload tag groups to pick up the new/updated grid tag
    const tagGroups = await this.tagService.getCurrentTagGroups();
    dispatch({
      type: AppActionTypes.UpdateTagGroups,
      payload: tagGroups
    });

    this.setState({ grid: updatedGrid, gridChanged: true }, () => {
      this.closeModal();
    });

    return true;
  };

  handleSelectedFilterChange = (selectedIndex: number) => {
    const updatedGrid = new GridModel({ ...this.state.grid });
    const selectedFilter = selectedIndex === 0 ? "standard" : "advanced";

    if (selectedFilter === "standard") {
      updatedGrid.query = this.travelQueryService.getStandardGridQuery(
        updatedGrid
      );
    }

    this.setState({
      selectedFilter,
      grid: updatedGrid
    });
  };

  /*
   * When creating a new grid, the "default" filter set is [Tag=>Grid Tag === New Grid Tag]. This
   * is problematic because the grid tag doesn't exist yet, so we need to inject a mock tag group
   * that contains a placeholder for the new tag.
   */
  onFilterRow = (filterRow: FilterRowParam): FilterRowParam => {
    const { grid } = this.state;

    if (!filterRow.hasGridTagGroup()) {
      return filterRow;
    }

    const tagGroup: TagGroupModel = filterRow.model.meta.tagGroup;
    const gridTagIndex = tagGroup.tags.findIndex(
      (tag: TagModel) => tag.id === "new-grid-tag"
    );
    const newGridTag = new TagModel({ ...grid.tag });

    if (gridTagIndex === -1) {
      filterRow.model.meta.tagGroup.tags.push(newGridTag);
    } else {
      filterRow.model.meta.tagGroup.tags[gridTagIndex] = newGridTag;
    }

    return filterRow;
  };

  getQueryDateRange = (): [string, string] => {
    const { grid } = this.state;
    let startDate = "",
      endDate = "";

    const afterParam = grid.query.params.find(
      (param: TravelQueryParam) => param.field === "travel-date-after"
    );
    if (afterParam) {
      startDate = afterParam.value;
    }

    const beforeParam = grid.query.params.find(
      (param: TravelQueryParam) => param.field === "travel-date-before"
    );
    if (beforeParam) {
      endDate = beforeParam.value;
    }

    return [startDate, endDate];
  };

  handleDateChange = (updatedDates: [string, string]) => {
    const { grid } = this.state;
    const [startDate, endDate] = updatedDates;

    if (!startDate || !endDate) {
      return;
    }

    const updatedGrid = new GridModel({ ...grid });

    const afterParam = new TravelQueryParam({
      field: "travel-date-after",
      resource: "trips",
      value: startDate
    });

    const beforeParam = new TravelQueryParam({
      field: "travel-date-before",
      resource: "trips",
      value: endDate
    });

    updatedGrid.query = this.travelQueryService.upsertQueryParam(
      updatedGrid.query,
      afterParam
    );

    updatedGrid.query = this.travelQueryService.upsertQueryParam(
      updatedGrid.query,
      beforeParam
    );

    this.handleUpdateModel(updatedGrid);
  };

  getGridDestinationAirports = (): AirportModel[] => {
    const airportParam = this.state.grid.query.params.find(
      (param: TravelQueryParam) => param.field === "airport-arrive"
    );

    if (!airportParam) {
      return [];
    }

    return airportParam.value.map(
      (name: string) =>
        this.state.queryMeta.airports.get(name) || new AirportModel()
    );
  };

  handleDestinationChange = (selectedAirports: AirportModel[]) => {
    const { grid, queryMeta } = this.state;
    const updatedGrid = new GridModel({ ...grid });
    const { airports, users } = queryMeta;

    // update grid query meta with the newly selected airports
    selectedAirports.forEach((airport: AirportModel) =>
      airports.set(airport.getFullName(), airport)
    );

    this.setState({
      queryMeta: {
        users,
        airports
      }
    });

    // update the airport-arrive grid query param
    const destParam = new TravelQueryParam({
      field: "airport-arrive",
      resource: "trips",
      value: selectedAirports.map((airport: AirportModel) =>
        airport.getFullName()
      )
    });
    updatedGrid.query = this.travelQueryService.upsertQueryParam(
      updatedGrid.query,
      destParam
    );
    this.handleUpdateModel(updatedGrid);
  };

  render() {
    const {
      formChanged,
      formValid,
      isGridNameUnique,
      isNewGrid,
      loadState,
      grid,
      menuPortalTarget,
      selectedUserOpts,
      selectedFilter
    } = this.state;
    const { openModal } = this.props;
    const isNew = !Boolean(grid.id);
    const selectedFilterIndex = selectedFilter === "standard" ? 0 : 1;
    const loaded = loadState === "loaded";

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        classes={{ root: "grid-dialog", paperWidthSm: "paper-width-sm" }}
        disableBackdropClick={false}
        open={true}
        onClose={() => this.handleClose()}
        onEntered={() => {
          this.setState({ menuPortalTarget: this.modalRef.current });
        }}
        PaperComponent={PaperComponent}
        ref={this.modalRef}
        disableEnforceFocus={true}
      >
        {isNew && (
          <DialogTitle classes={{ root: "dialog-title" }}>
            <GridTipsCarousel />
          </DialogTitle>
        )}
        <DialogContent
          classes={{ root: "dialog-content" }}
          className="not-draggable"
        >
          <div className="grid-dialog-form">
            <div className="row">
              <label>Grid Name</label>
              <TextInput
                autoFocus={true}
                label=""
                defaultValue={grid.name ?? ""}
                onChange={(value: string) => {
                  grid.name = value;
                  grid.tag.name = value ? value : "Grid Tag";
                  this.validateGridNameUnique(value);
                  this.handleUpdateModel(grid);
                }}
              />
              <div className="input-help-text input-help-text--error">
                {!isGridNameUnique && (
                  <React.Fragment>
                    <AppIcon color="red" type="error" size="x-small" />
                    That name is already in use
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className="row destinations">
              <label>Grid Destinations</label>
              {loaded && (
                <AirportSelect
                  onChange={this.handleDestinationChange}
                  menuPortalTarget={menuPortalTarget}
                  selectedAirports={this.getGridDestinationAirports()}
                  width={300}
                />
              )}
              <div className="input-help-text">
                Choose optional destination airports. Only flights arriving at
                those airports will appear in the grid.
              </div>
            </div>
            <div className="row date-range">
              <label>Grid Dates</label>
              <DateRangeSelect
                anchorDirection="left"
                onChange={this.handleDateChange}
                value={this.getQueryDateRange()}
              />
              <div className="input-help-text">
                Choose an optional travel date range. Only trips falling within
                the date range will appear in the grid.
              </div>
            </div>

            <div className="row users">
              <label>Grid Collaborators</label>
              <TravelerInput
                defaultValue={selectedUserOpts}
                label=""
                onChange={(options: SelectOption[]) => {
                  grid.users = options.map(
                    (option: SelectOption) =>
                      new UserModel({ id: option.value })
                  );
                  this.handleUpdateModel(grid);
                }}
                menuPortalTarget={menuPortalTarget}
                onlyAdmins={true}
                placeholder="Search Collaborators"
              />
              <div className="input-help-text">
                Collaborators will have full access to the Grid
              </div>
            </div>
            <div className="row color">
              <label>Grid Tag Color</label>
              <ColorPalette
                fullText={true}
                selected={grid.tag.color}
                onClick={(color: string) => {
                  grid.tag.color = color;
                  this.handleUpdateModel(grid);
                }}
              />
            </div>
            <div className="row">
              <div className="filters-label">
                <label>Grid Filters</label>
                {isNewGrid && (
                  <div>
                    <ToggleText
                      ref={this.toggleTextRef}
                      options={["Standard", "Advanced"]}
                      selectedIndex={selectedFilterIndex}
                      onChange={this.handleSelectedFilterChange}
                    />
                  </div>
                )}
              </div>

              <div className="filter-container">
                {selectedFilter === "standard" && (
                  <div className="filter-container-standard">
                    <label>Standard Filter</label>
                    <div className="description">
                      This Grid will display any travel with this Grid Tag.
                    </div>
                    <TagChip tag={grid.tag} />
                    <div className="advanced-toggle">
                      Want more filters?{" "}
                      <span
                        onClick={() => {
                          this.toggleTextRef.current?.click();
                        }}
                      >
                        Switch to Advanced
                      </span>
                    </div>
                  </div>
                )}
                {selectedFilter === "advanced" && (
                  <div className="filter-container-advanced">
                    <label>Advanced Filter</label>
                    <TravelFilter
                      grid={grid}
                      menuPortalTarget={menuPortalTarget}
                      onFilterRow={this.onFilterRow}
                      onChange={(travelQuery: TravelQueryModel) => {
                        grid.query = travelQuery;
                        this.handleUpdateModel(grid);
                      }}
                      openModal={openModal}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="help-center-link">
            Learn more about Grids and Grid Tags in the{" "}
            <a
              rel="noopener noreferrer"
              href="http://help.tripgrid.com"
              target="_blank"
            >
              Tripgrid Help Center
            </a>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: "dialog-actions" }}>
          <Button
            color="gray"
            isFullWidth={true}
            isRippleDisabled={true}
            isTransparent={true}
            label="Close"
            onClick={() => this.handleClose(true)}
            size="medium"
          />
          <Button
            color="product-blue"
            label={grid.id ? "Save Changes" : "Create Grid"}
            isDisabled={!isGridNameUnique || !formValid || !formChanged}
            isFullWidth={true}
            isTransparent={!formValid || !formChanged}
            onClick={() => this.handleSubmit()}
            size="medium"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAppContext(withSnackbarContext(GridDialog));
