import React from "react";
import Select from "react-select";
import AppIcon from "../../app-icon";
import { ObjectHash } from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

export const colorOptions: any[] = [
  { label: "Green", value: Colors.green },
  { label: "Yellow", value: Colors.yellow },
  { label: "Orange", value: Colors.orange },
  { label: "Red", value: Colors.red },
  { label: "Blue", value: Colors.blue },
  { label: "Purple", value: Colors.purple },

  { label: "Light Red", value: Colors.lightRed },
  { label: "Light Orange", value: Colors.lightOrange },
  { label: "Light Yellow", value: Colors.lightYellow },
  { label: "Light Green", value: Colors.lightGreen },
  { label: "Light Blue", value: Colors.lightBlue },
  { label: "Light Purple", value: Colors.lightPurple },

  { label: "Black", value: Colors.black },
  { label: "Gray", value: Colors.gray }
];

export const secondaryColorHash: { [index: string]: string } = {
  [Colors.black]: Colors.lightGray,
  [Colors.gray]: Colors.lightGray,
  [Colors.blue]: Colors.lightBlue,
  [Colors.green]: Colors.lightGreen,
  [Colors.orange]: Colors.lightOrange,
  [Colors.purple]: Colors.lightPurple,
  [Colors.red]: Colors.lightRed,
  [Colors.yellow]: Colors.lightYellow
};

interface Props {
  defaultValue?: any[];
  menuPortalTarget?: any;
  onChange: CallableFunction;
  noLightColors?: boolean;
  placeholder?: string;
}

export default function ColorInput(props: Props) {
  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      borderWidth: "2px",
      boxShadow: "none",
      cursor: "pointer",
      minWidth: "200px"
    }),
    menu: (styles: ObjectHash) => ({
      ...menu(styles),
      marginBottom: "32px"
    }),
    menuPortal,
    option: (
      styles: ObjectHash,
      { isDisabled, isFocused }: { isDisabled: boolean; isFocused: boolean }
    ) => {
      let backgroundColor = "white";
      if (isDisabled) {
        backgroundColor = Colors.borderGray;
      }
      if (isFocused) {
        backgroundColor = Colors.backgroundGray;
      }
      return {
        ...option(styles),
        backgroundColor,
        color: "black",
        margin: "4px 0",
        transition: "all 0.3s ease-in-out",
        ".color-input-option-wrapper": {
          display: "flex",
          alignItems: "center",
          ".color-input-option__dot": {
            height: "16px",
            width: "16px",
            borderRadius: "4px",
            marginRight: "8px"
          }
        }
      };
    },
    placeholder: (styles: ObjectHash) => ({
      ...styles,
      fontSize: "14px"
    }),
    singleValue: (styles: any) => ({
      ...styles,
      ".color-input-option-wrapper": {
        display: "flex",
        alignItems: "center",
        ".color-input-option__dot": {
          height: "16px",
          width: "16px",
          borderRadius: "4px",
          marginRight: "8px"
        }
      }
    })
  };

  const formatOptionLabel = (option: { [index: string]: string }) => (
    <div className="color-input-option-wrapper">
      <div
        className="color-input-option__dot"
        style={{ background: option.value }}
      />
      {option.label}
    </div>
  );

  const handleChange = (option: { [index: string]: string }) => {
    props.onChange(option.label.toLowerCase());
  };

  return (
    <Select
      components={{
        DropdownIndicator: () => <AppIcon type="menu-arrow" />,
        IndicatorSeparator: () => null
      }}
      defaultValue={
        props.defaultValue
          ? [
              colorOptions.find(
                (c) => c.label.toLowerCase() === (props.defaultValue || [])[0]
              )
            ]
          : null
      }
      formatOptionLabel={formatOptionLabel}
      isSearchable={false}
      menuPortalTarget={props.menuPortalTarget}
      onChange={(option: any) => handleChange(option)}
      options={
        props.noLightColors
          ? colorOptions.filter(
              (color) => !color.label.toLowerCase().includes("light")
            )
          : colorOptions
      }
      placeholder={props.placeholder || "Select color"}
      styles={optionStyles}
      tabSelectsValue={false}
      theme={customReactSelectTheme}
    />
  );
}
