import { cloneDeep } from "lodash";
import AppContainer from "../container";
import TemplateModel from "../models/template.model";
import GridModel from "../models/grid.model";
import {
  IWorkspaceService,
  WorkspaceService
} from "../services/workspace.service";
import CollectionModel from "../models/collection.model";
import { IAppState } from ".";

export type AppAction = {
  type: AppActionTypes;
  payload?: any;
};

export enum AppActionTypes {
  LoadAppState = "app/load",
  UpdateCompany = "company/update",
  UpdateGrid = "grid/update",
  DeleteGrid = "grid/delete",
  UpdateTagGroups = "tag/update",
  UpdateTemplate = "template/update",
  UpdateUser = "user/update",
  UpdateCollection = "collection/update",
  DeleteCollection = "collection/delete"
}

export const AppReducer = (state: IAppState, action: AppAction) => {
  const { settings } = state;
  const { payload } = action;

  switch (action.type) {
    case AppActionTypes.LoadAppState:
      return { ...payload };

    case AppActionTypes.UpdateCompany:
      return {
        ...state,
        company: payload,
        settings: { ...settings, ...payload?.settings }
      };

    case AppActionTypes.UpdateGrid:
    case AppActionTypes.DeleteGrid:
      const updatedGrids = [...settings.grids];
      const updateGrid = cloneDeep(payload);
      const gridIndex = updatedGrids.findIndex(
        (grid: GridModel) => grid.id === updateGrid.id
      );

      if (action.type === AppActionTypes.DeleteGrid) {
        if (gridIndex > -1) {
          updatedGrids.splice(gridIndex, 1);
        }
      } else {
        if (gridIndex === -1) {
          updatedGrids.unshift(updateGrid);
        } else {
          updatedGrids[gridIndex] = updateGrid;
        }
      }

      return {
        ...state,
        settings: { ...settings, grids: updatedGrids }
      };

    case AppActionTypes.UpdateTagGroups:
      return {
        ...state,
        settings: { ...settings, tagGroups: cloneDeep(payload) }
      };

    case AppActionTypes.UpdateTemplate:
      const workspaceService: IWorkspaceService = AppContainer.get(
        WorkspaceService
      );
      const templates = [...state.templates];
      const updateTemplate = new TemplateModel(payload);
      const updatedTemplateIndex = templates.findIndex(
        (template: TemplateModel) => template.id === updateTemplate.id
      );

      if (updatedTemplateIndex === -1) {
        templates.push(updateTemplate);
      } else {
        templates[updatedTemplateIndex] = updateTemplate;
      }

      return {
        ...state,
        templates: workspaceService.makeWorkspaceTemplates(
          templates,
          state.company
        )
      };

    case AppActionTypes.UpdateCollection:
    case AppActionTypes.DeleteCollection:
      const updatedCollections = [...settings.collections];
      const updateCollection = cloneDeep(payload);
      const collectionIndex = updatedCollections.findIndex(
        (collection: CollectionModel) => collection.id === updateCollection.id
      );

      if (action.type === AppActionTypes.DeleteCollection) {
        if (collectionIndex > -1) {
          updatedCollections.splice(collectionIndex, 1);
        }
      } else {
        if (collectionIndex === -1) {
          updatedCollections.unshift(updateCollection);
        } else {
          updatedCollections[collectionIndex] = updateCollection;
        }
      }

      return {
        ...state,
        settings: { ...settings, collections: updatedCollections }
      };
  }
};
