import { DateTime } from "luxon";
import * as validate from "validate.js";

import AppContainer from "../container";
import {
  AppContextService,
  IAppContextService
} from "../services/app-context.service";
import CollectionModel from "../models/collection.model";
import { ObjectHash } from "./helpers";
import { TemplateInput } from "../services/workspace.service";

// https://github.com/ansman/validate.js/issues/182
validate.validators.emailOptional = (
  value: string,
  options: ObjectHash,
  key: string,
  attributes: ObjectHash
) => {
  if (!value || emailIsValid(value)) {
    return;
  }

  return "Please enter a valid e-mail address";
};

validate.validators.collectionNameUnique = (
  value: string,
  options: ObjectHash,
  key: string,
  attributes: ObjectHash,
  globalOptions: ObjectHash
) => {
  const { model } = globalOptions.meta || {};
  const existingId = model?.id;

  const appContextService: IAppContextService = AppContainer.get(
    AppContextService
  );
  const { collections = [] } = appContextService.get().settings;

  if (
    collections.some(
      (collection: CollectionModel) =>
        String(collection.name).toLowerCase() === String(value).toLowerCase() &&
        existingId !== collection.id
    )
  ) {
    return "That name is already in use";
  }
};

validate.validators.dateRange = (
  value: string,
  options: ObjectHash,
  key: string,
  attributes: ObjectHash
) => {
  if (!Array.isArray(value) || value.length !== 2) {
    return;
  }

  const startDate = DateTime.fromISO(`${value[0]}T00:00:00Z`);
  const endDate = DateTime.fromISO(`${value[1]}T23:59:59Z`);

  if (!startDate.isValid) {
    return "Invalid start date";
  }

  if (!endDate.isValid) {
    return "Invalid end date";
  }

  if (startDate > endDate) {
    return "The start date cannot come before the end date";
  }
};

validate.validators.arrayOfLength = (
  value: string,
  options: ObjectHash,
  key: string,
  attributes: ObjectHash
) => {
  const { length, message } = options;

  if (!Array.isArray(value)) {
    return "Invalid value";
  }

  if (value.filter((v) => v).length < length) {
    return message;
  }
};

export type FieldValidator = {
  debounceMs?: number;
  meta?: ObjectHash;
  rule: ObjectHash;
};

export type FormValidator = { [index: string]: FieldValidator };

export type FormConfig = {
  validator: FormValidator;
  fields: TemplateInput[];
};

export const emailIsValid = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const fieldIsRequired = (fieldValidator: FieldValidator): boolean => {
  const { rule } = fieldValidator || {};
  if (!rule) {
    return false;
  }

  return rule.presence?.allowEmpty === false;
};

export const fieldIsValid = (
  value: any,
  validator: FieldValidator
): string[] | null => {
  const { rule, meta } = validator;
  return validate.single(value, rule, { meta } as any);
};

export const formIsValid = (
  validator: FormValidator,
  formState: ObjectHash
): boolean => {
  return Object.keys(formState).every(
    (key: string) =>
      !validator[key]?.rule || !fieldIsValid(formState[key], validator[key])
  );
};

export const UserModelValidator: FormValidator = {
  firstName: {
    rule: { presence: { allowEmpty: false, message: "First name is required" } }
  },
  lastName: {
    rule: { presence: { allowEmpty: false, message: "Last name is required" } }
  },
  email: {
    rule: {
      presence: {
        allowEmpty: false,
        message: "Please enter a valid e-mail address"
      },
      email: { message: "Please enter a valid e-mail address" }
    }
  },
  role: {
    rule: { presence: { allowEmpty: false, message: "Role is required" } }
  }
};

export const UserDocumentPassportValidator: FormValidator = {
  passportNumber: {
    rule: {
      presence: { allowEmpty: false, message: "Passport number is required" }
    }
  },

  issuingCountry: {
    rule: {
      presence: { allowEmpty: false, message: "Issuing country is required" }
    }
  },

  expirationDate: {
    rule: {
      presence: { allowEmpty: false, message: "Expiration date is required" }
    }
  },

  sex: {
    rule: { presence: { allowEmpty: false, message: "Gender is required" } }
  },

  firstName: {
    rule: { presence: { allowEmpty: false, message: "First name is required" } }
  },

  lastName: {
    rule: { presence: { allowEmpty: false, message: "Last name is required" } }
  },

  dateOfBirth: {
    rule: {
      presence: { allowEmpty: false, message: "Date of birth is required" }
    }
  },

  nationality: {
    rule: {
      presence: { allowEmpty: false, message: "Nationality is required" }
    }
  },

  countryOfResidence: {
    rule: {
      presence: {
        allowEmpty: false,
        message: "Country of residence is required"
      }
    }
  }
};

export const UserDocumentIdCardValidator: FormValidator = {
  idCardNumber: {
    rule: {
      presence: { allowEmpty: false, message: "Card number is required" }
    }
  },

  issuingCountry: {
    rule: {
      presence: { allowEmpty: false, message: "Issuing country is required" }
    }
  },

  issueDate: {
    rule: {
      presence: { allowEmpty: false, message: "Expiration date is required" }
    }
  },

  expirationDate: {
    rule: {
      presence: { allowEmpty: false, message: "Expiration date is required" }
    }
  },

  sex: {
    rule: { presence: { allowEmpty: false, message: "Gender is required" } }
  },

  firstName: {
    rule: { presence: { allowEmpty: false, message: "First name is required" } }
  },

  lastName: {
    rule: { presence: { allowEmpty: false, message: "Last name is required" } }
  },

  dateOfBirth: {
    rule: {
      presence: { allowEmpty: false, message: "Date of birth is required" }
    }
  },

  nationality: {
    rule: {
      presence: { allowEmpty: false, message: "Nationality is required" }
    }
  },

  countryOfResidence: {
    rule: {
      presence: {
        allowEmpty: false,
        message: "Country of residence is required"
      }
    }
  }
};

export const UserDocumentTsaValidator: FormValidator = {
  tsaNumber: {
    rule: { presence: { allowEmpty: false, message: "TSA number is required" } }
  },
  expirationDate: {
    rule: {
      presence: { allowEmpty: false, message: "Expiration date is required" }
    }
  }
};

export const UserProgramValidator: FormValidator = {
  company: {
    rule: {
      presence: { allowEmpty: false, message: "Program name is required" }
    }
  },
  accountId: {
    rule: { presence: { allowEmpty: false, message: "Account Id is required" } }
  }
};

export const CollectionValidator: FormValidator = {
  name: {
    rule: {
      presence: { allowEmpty: false, message: "Collection name is required" },
      collectionNameUnique: {}
    },
    debounceMs: 500
  },
  bookingDates: {
    rule: { dateRange: {} }
  }
};

export const BookingRequestValidator: ObjectHash = {
  bookingDates: {
    rule: { dateRange: {} }
  }
};
