import { ObjectHash } from "../utils/helpers";
import BaseModel from "./base.model";

export default class CompanyModel extends BaseModel {
  id: string;
  active: boolean;
  deleted: boolean;
  hasCompletedSetup: boolean;
  billable: boolean;
  billingDate: string;
  billingPlan: string;
  features: ObjectHash;
  logoUploadUrl: string;
  mailbox: string;
  name: string;
  sandbox: boolean;
  settings: ObjectHash;
  status: string;
  createdAt: string;
  updatedAt: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.active = data.active;
    this.deleted = data.deleted;
    this.hasCompletedSetup = data.hasCompletedSetup;
    this.billable = data.billable;
    this.billingDate = data.billingDate;
    this.billingPlan = data.billingPlan;
    this.features = data.features ? data.features : {};
    this.logoUploadUrl = data.logoUploadUrl;
    this.mailbox = data.mailbox;
    this.name = data.name;
    this.sandbox = data.sandbox;
    this.settings = data.settings;
    this.status = data.status;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    // @todo validation for legacy upload url values?
    if (!String(this.logoUploadUrl).includes("http")) {
      this.logoUploadUrl = "";
    }
  }

  getEmailForAppDomain(domain: string): string {
    if (!this.mailbox) {
      return "";
    }

    return `${this.mailbox}@${domain}`;
  }
}
