import { useEffect } from "react";
import AppContainer from "../../container";
import { ITripService, TripService } from "../../services/trip.service";
import TripModel from "../../models/trip.model";
import useModal from "../../hooks/use-modal.hook";

export default function ModalDeepLinker() {
  const { getModalLink, openModal } = useModal();
  const tripService: ITripService = AppContainer.get(TripService);

  useEffect(() => {
    /*
     * Trip modals
     */
    const link = getModalLink("quick-add") || getModalLink("quick-add-large");
    if (link?.trip) {
      tripService.getById(link?.trip, true).then((data: TripModel | null) => {
        if (!data) {
          return;
        }
        openModal(link.modal, {
          data,
          dataType: data.type
        });
      });

      return;
    }

    /*
     * User modal
     */
    const { userId } = getModalLink("user") || {};

    if (userId) {
      openModal("user", { userId });
      return;
    }

    /*
     * Booking request modal
     */
    const { requestId } = getModalLink("bookingRequest") || {};
    if (requestId) {
      openModal("booking-request", { requestId });
      return;
    }
  }, [getModalLink, openModal, tripService]);

  return null;
}
