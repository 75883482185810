import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";

import TagGroupEditorMenu from "./tag-group-editor-menu";
import AppIcon from "../../../../components/app-icon";
import Button from "../../../../components/button";
import Tooltip from "../../../../components/tooltip";

import AppContainer from "../../../../container";
import { AppActionTypes } from "../../../../context/reducer";
import { AppPageProps } from "../../../../router";
import { ITagService, TagService } from "../../../../services/tag.service";
import { LoadState, sortByPosition } from "../../../../utils/helpers";
import TagGroupModel from "../../../../models/tag-group.model";
import Sortable from "../../../../components/sortable";
import useApp from "../../../../hooks/use-app.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";

import "../../settings-page.scss";
import ObjectTable from "../../../../components/object-table";
import { AppContext } from "../../../../context";
import { downloadCSV } from "../../../../utils/csv";

const TagEditorPage = (props: AppPageProps) => {
  const { tagGroupId } = useParams<{ tagGroupId: string }>();
  const history = useHistory();

  const tagService: ITagService = AppContainer.get(TagService);
  const { setSnackbar } = useSnackbar();

  const { dispatch } = useApp();

  const [loadState, setLoadState] = useState<LoadState>("unloaded");
  const [tagGroups, setTagGroups] = useState<TagGroupModel[]>([]);
  const [sortableState, setSortableState] = useState({
    disabled: false,
    key: 0
  });

  const handleUpdate = async (tagGroup: TagGroupModel): Promise<boolean> => {
    setSortableState({ ...sortableState, disabled: true });

    let newTagGroups = cloneDeep(tagGroups);

    if (!tagGroup.id) {
      const newTagGroup = await tagService.addGroup(tagGroup);

      if (!newTagGroup) {
        return false;
      }

      newTagGroups.push(newTagGroup);
    } else {
      const updatedTagGroup = await tagService.updateGroup(tagGroup);

      if (!updatedTagGroup) {
        return false;
      }

      newTagGroups = await tagService.getCurrentTagGroups();
    }

    dispatch({ type: AppActionTypes.UpdateTagGroups, payload: newTagGroups });

    setTagGroups(newTagGroups);

    let newSortableElKey = sortableState.key;
    setSortableState({ disabled: false, key: ++newSortableElKey });

    setSnackbar({
      message: "Tag Group updated!",
      variant: "success"
    });

    return true;
  };

  const handleSortChange = (itemId: string, newIndex: number) => {
    const updatedTagGroup = tagGroups.find(
      (tagGroup: TagGroupModel) => tagGroup.id === itemId
    );

    if (!updatedTagGroup) {
      return;
    }

    /*
     * Since some tag groups are pulled out of the array of sortable elements, any index value returned by the sortable component
     * may not be the correct updated position value for the group being updated.
     */

    const nonSortableGroups = tagGroups.filter(
      (tagGroup: TagGroupModel) =>
        tagGroup.readOnly && tagGroup.position < newIndex
    );

    updatedTagGroup.position = newIndex + nonSortableGroups.length;

    handleUpdate(new TagGroupModel(updatedTagGroup));
  };

  useEffect(() => {
    let subscribed = true;
    const unsubscribe = () => {
      subscribed = false;
    };
    if (loadState !== "unloaded") {
      return;
    }
    tagService.getCurrentTagGroups().then((tagGroups: TagGroupModel[]) => {
      if (!subscribed) {
        return;
      }
      setTagGroups(tagGroups);
      setLoadState("loaded");
    });

    return unsubscribe;
  }, [loadState, tagService]);

  const nonSortableElements = tagGroups
    .filter((tagGroup: TagGroupModel) => tagGroup.readOnly)
    .map((tagGroup: TagGroupModel, index: number) => {
      const { name } = tagGroup;
      return (
        <div className="settings-page__property-wrapper readonly" key={index}>
          <AppIcon type="lock" color="gray" />
          <span className="settings-page__property-name">{name}</span>
          <TagGroupEditorMenu disabled={true} tagGroup={tagGroup} />
          <div className="settings-page__property__button-group">
            <Button
              color="product-blue"
              icon="visible"
              isDisabled={true}
              isRounded={true}
              isTransparent={true}
              size="medium"
            />
          </div>
        </div>
      );
    });

  const sortableElements = tagGroups
    .filter((tagGroup: TagGroupModel) => !tagGroup.readOnly)
    .sort(sortByPosition)
    .map((tagGroup: TagGroupModel) => {
      const { hidden, id, name } = tagGroup;
      return {
        id,
        element: (
          <div className="settings-page__property-wrapper draggable">
            <AppIcon type="drag-handle" color="gray" />
            <span className="settings-page__property-name">{name}</span>
            <TagGroupEditorMenu tagGroup={tagGroup} onUpdate={handleUpdate} />

            <div className="settings-page__property__button-group">
              {!hidden ? (
                <Tooltip text="Hide Field">
                  <Button
                    color="product-blue"
                    icon="visible"
                    isDisabled={sortableState.disabled}
                    isRippleDisabled={true}
                    isRounded={true}
                    isTransparent={true}
                    onClick={() => {
                      tagGroup.hidden = true;
                      handleUpdate(tagGroup);
                    }}
                    size="medium"
                  />
                </Tooltip>
              ) : (
                <Tooltip text="Unhide Field">
                  <Button
                    color="red"
                    icon="visible-strike"
                    isDisabled={sortableState.disabled}
                    isRippleDisabled={true}
                    isRounded={true}
                    isTransparent={true}
                    onClick={() => {
                      tagGroup.hidden = false;
                      handleUpdate(tagGroup);
                    }}
                    size="medium"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )
      };
    });

  const loaded = loadState === "loaded";

  if (tagGroupId) {
    const tagGroup = tagGroups?.find((tg) => tg.id === tagGroupId);

    return (
      <AppContext.Consumer>
        {(appState) => (
          <div className="settings-subpage page-container">
            <div className="settings-subpage-breadcrumb">
              <p
                className="settings-subpage-breadcrumb__link"
                onClick={() => history.push("/settings")}
              >
                Settings
              </p>
              <AppIcon type="nav-arrow-right" />
              <p
                className="settings-subpage-breadcrumb__link"
                onClick={() => history.push("/settings/tags")}
              >
                Configure Tag Groups
              </p>
              <AppIcon type="nav-arrow-right" />
              <p>Modify Group Tags</p>
            </div>
            <h1 className="settings-page__header">Modify Group Tags</h1>
            <div>
              <div className="settings-page__section-wrapper --with-border">
                <ObjectTable
                  columns={[
                    ["name", "Name"],
                    ["color", "Color"]
                  ]}
                  renderOpts={{
                    showQuickAdd: true,
                    disableViewChange: true
                  }}
                  searchOpts={{
                    resource: "tags",
                    viewStateKey: `tags-${tagGroupId}`
                  }}
                  staticResults={tagGroup?.tags}
                  company={appState.company}
                  templates={appState.templates}
                  title={`${tagGroup?.name}`}
                  settings={appState.settings}
                  user={appState.user}
                />
              </div>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    );
  }

  return (
    <div className="settings-subpage page-container">
      <div className="settings-subpage-breadcrumb">
        <p
          className="settings-subpage-breadcrumb__link"
          onClick={() => history.push("/settings")}
        >
          Settings
        </p>
        <AppIcon type="nav-arrow-right" />
        <p>Configure Tag Groups</p>
      </div>
      <h1 className="settings-page__header">Configure Tag Groups</h1>
      <div>
        <div className="settings-page__section-wrapper --with-border">
          <div
            className="settings-page__header-flex"
            style={{ justifyContent: "flex-end" }}
          >
            <h2 className="settings-page__sub-header" style={{ flexGrow: 1 }}>
              Workspace Tag Groups
            </h2>
            <Tooltip text="Download All Workspace Tags">
              <div
                onClick={() =>
                  downloadCSV(
                    "Tag Group,Tag\n" +
                      tagGroups
                        .map((tg) =>
                          tg.tags.map((t) => [tg.name, t.name].join(","))
                        )
                        .flat()
                        .join("\n"),
                    "workspace-tags.csv"
                  )
                }
                style={{ marginRight: "0.5rem" }}
              >
                <Button
                  color="dark-gray"
                  isBordered
                  isRippleDisabled
                  isRounded
                  isTransparent
                  icon="download"
                  size="small"
                />
              </div>
            </Tooltip>
            <TagGroupEditorMenu
              key={`settings-page__header-menu-${sortableState.key}`}
              tagGroup={new TagGroupModel({ multiSelect: true })}
              onUpdate={handleUpdate}
            />
          </div>
          {nonSortableElements}

          {loaded && (
            <Sortable
              disabled={sortableState.disabled}
              items={sortableElements}
              onChange={handleSortChange}
              key={`sortable-${sortableState.key}`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TagEditorPage;
