import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import TextInput from "../../form/inputs/TextInput";

const GdsLocatorFilter = new TravelFilterModel({
  field: TravelFilterFields.GdsLocator,
  name: "GDS Locator",
  group: TravelFilterGroups.Identifier,
  resource: TravelFilterResources.Trip,
  component: TextInput
});

export default GdsLocatorFilter;
