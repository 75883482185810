import BaseModel from "./base.model";
import TagModel from "./tag.model";
import { sortByPosition } from "../utils/helpers";
import * as _ from "lodash";

export default class TagGroupModel extends BaseModel {
  id: string;
  companyId: string;
  name: string;
  readOnly: boolean;
  hidden: boolean;
  multiSelect: boolean;
  position: number;
  tags: TagModel[];
  presetValues: string;
  presetValueFormat: string;

  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;

  // id of the identifier that this tag group was mapped from, use to map viewState settings
  customKey: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.companyId = data.companyId;
    this.name = data.name;
    this.readOnly = data.readOnly;
    this.hidden = data.hidden;
    this.multiSelect = data.multiSelect;
    this.position = data.position;
    this.tags = [];
    this.presetValues = data.presetValues;
    this.presetValueFormat = data.presetValueFormat;

    if (data.tags && data.tags.length) {
      this.tags = data.tags
        .map((tag: any) => new TagModel(tag))
        .sort(sortByPosition);
    }

    this.deleted = data.deleted;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;

    this.customKey = data.customKey;
  }

  // @todo better way to flag this
  isGridTagGroup(): boolean {
    return this.readOnly && this.name === "Grid";
  }

  // WARNING: IF you change this, you must also update TagModel::getTagGroupFieldId
  getFieldId(): string {
    return `tag-group-${this.id}`;
  }

  static getIdFromField(fieldId: string): string {
    return fieldId.replace(/^tag-group-/, "");
  }

  static isFieldId(fieldId: string): boolean {
    return Boolean(fieldId.match(/^tag-group-[a-f\d]{24}$/));
  }

  getIdentifierId(): string {
    return _.camelCase(this.name);
  }

  hasPreset(): boolean {
    return Boolean(
      Array.isArray(this.presetValues) &&
        this.presetValues.length &&
        String(this.presetValueFormat).length
    );
  }

  getPreset(): { values: string[][]; format: string[] } {
    const values: string[][] = Array.isArray(this.presetValues)
      ? this.presetValues.map((presetValue: string) => presetValue.split("_"))
      : [];

    const format: string[] = this.presetValueFormat
      ? String(this.presetValueFormat).split("_")
      : [];

    return { values, format };
  }
}
