import UserModel from "./user.model";
import { ObjectHash } from "../utils/helpers";

export default class TravelerModel extends UserModel {
  /*
   * @todo The hasAccommodation and hasTransportation properties inidcate whether or not these trips
   * exist for the given traveler, whereas hasInbound and hasOutbound inidcate the type of travel.
   * Convert hasInbound and hasOutbound to booleans to match the other 2 "has" properties. Then add
   * 2 additional properties that indicate outbound/inbound travel type
   */
  public hasAccommodation: boolean;
  public hasInbound: string | null;
  public hasOutbound: string | null;
  public hasTransportation: boolean;
  public arrivalAirline: string | null;
  public arrivalFlightNum: string | null;
  public arrivalOrigin: string | null;
  public arrivalTime: string | null;
  public arrivalDestination: string | null;
  public departureAirline: string | null;
  public departureFlightNum: string | null;
  public departureOrigin: string | null;
  public departureTime: string | null;
  public departureDestination: string | null;
  public travelerMeta: ObjectHash;

  constructor(data?: ObjectHash) {
    super(data);

    if (!data) {
      data = {};
    }

    this.arrivalAirline = null;
    this.arrivalFlightNum = null;
    this.arrivalOrigin = null;
    this.arrivalTime = null;
    this.arrivalDestination = null;
    this.departureAirline = null;
    this.departureFlightNum = null;
    this.departureOrigin = null;
    this.departureTime = null;
    this.departureDestination = null;
    this.hasAccommodation = false;
    this.hasInbound = null;
    this.hasOutbound = null;
    this.hasTransportation = false;
    this.travelerMeta = data.travelerMeta ?? {};

    if (data.travelerMeta) {
      const { travelerMeta } = data;
      this.hasAccommodation = travelerMeta.booked?.includes("HOTEL");
      this.hasInbound = travelerMeta.arrival?.what;
      this.hasOutbound = travelerMeta.departure?.what;
      this.hasTransportation = travelerMeta.booked?.includes("CAR");

      if (travelerMeta.arrival) {
        this.arrivalAirline = travelerMeta.arrival.data?.name;
        this.arrivalFlightNum = travelerMeta.arrival.data?.flightNum;
        this.arrivalOrigin = travelerMeta.arrival.whereFrom;
        this.arrivalTime = travelerMeta.arrival.whenTo;
        this.arrivalDestination = travelerMeta.arrival.whereTo;
      }

      if (travelerMeta.departure) {
        this.departureAirline = travelerMeta.departure.data?.name;
        this.departureFlightNum = travelerMeta.departure.data?.flightNum;
        this.departureOrigin = travelerMeta.departure.whereFrom;
        this.departureTime = travelerMeta.departure.whenFrom;
        this.departureDestination = travelerMeta.departure.whereTo;
      }
    }
  }
}
