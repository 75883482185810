import React, { useEffect, useState } from "react";

import AppIcon from "../../../app-icon";
import AirportChip from "../../../airport-chip";
import Button from "../../../button";
import { getDisplayDate, LoadState } from "../../../../utils/helpers";
import GridModel from "../../../../models/grid.model";
import { refreshLog, updateLog } from "../lib/service";
import { TmlActionTypes } from "../context/reducer";
import { TmlLogUpdate, TmlLogModel } from "../lib/models";
import useModal from "../../../../hooks/use-modal.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";
import { useTmlLog } from "../context";

interface Props {
  grid: GridModel;
}

export default function BigTileTitle(props: Props) {
  const { grid } = props;
  const { airports, dispatch, dispatchLogUpdates, log } = useTmlLog();
  const { openModal } = useModal();
  const { setSnackbar } = useSnackbar();

  const [refreshState, setRefreshState] = useState<LoadState>("unloaded");

  const [refreshMeta, setRefreshMeta] = useState<{
    log: TmlLogModel;
    updates: TmlLogUpdate[];
    loaded: boolean;
  }>({ log: new TmlLogModel(), updates: [], loaded: false });

  const handleRefresh = () => {
    if (refreshState !== "unloaded") {
      return;
    }
    setRefreshState("loading");

    updateLog(refreshMeta.log).then((response: TmlLogModel | null) => {
      if (!response) {
        setSnackbar({
          variant: "error",
          message: "There was a problem and the log was not updated"
        });
        return;
      }

      setSnackbar({
        variant: "success",
        message: "Traveler Movement Updated!"
      });

      dispatch({ type: TmlActionTypes.LoadLog, payload: response });

      dispatchLogUpdates({
        type: TmlActionTypes.LoadUpdates,
        payload: refreshMeta.updates
      });

      setRefreshState("unloaded");
    });
  };

  useEffect(() => {
    if (!log.id) {
      return;
    }

    refreshLog(grid, log).then(
      (response: { log: TmlLogModel; updates: TmlLogUpdate[] }) => {
        setRefreshMeta({ ...response, loaded: true });
      }
    );
  }, [grid, log]);

  const { settings } = log;
  const { dates, locations, targetLocation } = settings;

  const hasLocations = settings.locationsValid();
  const hasDates = settings.datesValid();
  const hasTargetLocation = Boolean(targetLocation.length);

  const { loaded, updates } = refreshMeta;
  const updateCount = updates.length;
  const canRefresh =
    updateCount && settings.isValid() && loaded && refreshState === "unloaded";

  return (
    <div className="tml-header">
      <div className="tml-settings">
        <div className="grid-name">{grid.name}</div>
        {hasLocations && (
          <div className="locations setting">
            <AppIcon type="airplane" color="product-blue" />
            <div className="value">
              {locations.map((location: string, index: number) => {
                const airport = airports.get(location);
                if (!airport) {
                  return null;
                }

                return <AirportChip airport={airport} key={index} />;
              })}
            </div>
          </div>
        )}
        {hasTargetLocation && (
          <div className="target-location setting">
            <AppIcon type="map-marker" color="product-blue" />
            <div className="value">{targetLocation}</div>
          </div>
        )}
        {hasDates && (
          <div className="dates setting">
            <AppIcon type="date-range" color="product-blue" />
            <div className="value">
              {`${getDisplayDate(dates[0], "MMMM dd")} - ${getDisplayDate(
                dates[1],
                "MMMM dd"
              )}`}
            </div>
          </div>
        )}
      </div>

      <div className="buttons">
        <Button
          color="product-background-blue"
          isDisabled={!settings.isValid()}
          label="Edit Settings"
          onClick={() => {
            openModal("grid-app-tml-edit-settings", {
              log,
              onSave: (log: TmlLogModel | null) => {
                if (log) {
                  dispatch({
                    type: TmlActionTypes.UpdateSettings,
                    payload: log
                  });
                }
              }
            });
          }}
          size="medium"
        />

        <Button
          color="product-blue"
          label={`Refresh Travel ${updateCount ? `(${updateCount})` : ""}`}
          onClick={() => handleRefresh()}
          size="medium"
          isDisabled={!canRefresh}
        />
      </div>
    </div>
  );
}
