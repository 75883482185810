import React, { FC } from "react";
import classnames from "classnames";

import AppIcon from "../app-icon";
import "./app-banner.scss";

interface Props {
  message: string | JSX.Element;
  type?: "info" | "error";
}

const AppBanner: FC<Props> = ({ message, type = "info" }) => {
  return (
    <div className={classnames("app-banner", type)}>
      <div className="icon">
        <AppIcon type={type} />
      </div>
      <div className="message">{message}</div>
    </div>
  );
};

export default AppBanner;
