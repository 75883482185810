import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ClipboardJS from "clipboard";

import GridModel from "../../../../models/grid.model";
import ParsedEmailModel from "../../../../models/parsed-email.model";

import env from "../../../../utils/env";
import { SnackbarContext } from "../../../../context/snackbar";
import useTravel from "../../../../hooks/use-travel.hook";

import AppIcon from "../../../app-icon";
import BigTile from "../../big-tile";
import Tooltip from "../../../tooltip";
import ParsedEmail from "../../../parsed-email";

import "./big-tile.scss";
import { AppContext } from "../../../../context";

interface Props {
  grid: GridModel;
  onClose?: CallableFunction;
}

export default function EmailParsingBigTile(props: Props) {
  const { setSnackbar } = useContext(SnackbarContext);

  const { grid, onClose } = props;

  const emailForwardAddress = grid.getEmailForAppDomain(env.mailboxDomain);

  const emails = useTravel().parsedEmails.filter(
    (email) => email.gridId === grid.id
  );

  const parsingEmails = emails.filter(
    (email: ParsedEmailModel) =>
      (email.inReview || !email.finished) && !email.error && !email.canceled
  );

  const parsedEmails = emails.filter(
    (email: ParsedEmailModel) => email.finished || email.error || email.canceled
  );

  return (
    <AppContext.Consumer>
      {(appState) =>
        appState.user.email?.endsWith("@nfl.com") ? null : (
          <BigTile
            title="Email Parsing"
            onClose={onClose}
            onEntered={() => {
              new ClipboardJS(".email-parsing-big-tile__copy-button", {
                container: document.getElementById("email-parsing-big-tile")! // ClipboardJS needs this when targeting element inside modal due to focus shift, see advanced usage https://clipboardjs.com/
              });
            }}
          >
            <div id="email-parsing-big-tile">
              <h2 className="email-parsing-big-tile__sub-title">
                Grid Address
              </h2>
              <p className="email-parsing-big-tile__description">
                Add travel to your grid by forwarding in travel confirmation
                emails.
              </p>

              {emailForwardAddress && (
                <Tooltip text="Copy forward address" placement="top">
                  <Button
                    className="email-parsing-big-tile__copy-button"
                    data-clipboard-text={emailForwardAddress}
                    onClick={() =>
                      setSnackbar({
                        message: "Email address copied!",
                        variant: "success"
                      })
                    }
                  >
                    <AppIcon color="orange" type="file-copy" size="small" />
                    {emailForwardAddress}
                  </Button>
                </Tooltip>
              )}
              {[parsingEmails, parsedEmails].map((emailsToRender, index) => {
                if (!emailsToRender.length) {
                  return null; // skip render of section if no emails fall in category
                }

                const emailElements = emailsToRender.map((email) => {
                  return (
                    <ParsedEmail
                      key={`parsed-email-${email.id}`}
                      email={email}
                      gridId={grid.id}
                    />
                  );
                });

                return (
                  <div
                    key={index}
                    className="email-parsing-big-tile__email-group"
                  >
                    <h2 className="email-parsing-big-tile__sub-title">
                      {index === 0 ? "Parsing" : "Parsed"}
                    </h2>
                    {emailElements.map((email) => email)}
                  </div>
                );
              })}
            </div>
          </BigTile>
        )
      }
    </AppContext.Consumer>
  );
}
