import React from "react";
import { TemplateInput } from "../../../../services/workspace.service";
import { ObjectHash, SelectOption } from "../../../../utils/helpers";
import RemoveButton from "../../../button/remove";
import InputController from "../../../form/input-controller";
import AutocompleteInputFilled from "../../../form/inputs/AutocompleteInputFilled";
import ArticleSelect from "../../../travel-filter/article-select";
import { Filter } from "../../helpers";

interface Props {
  filter: Filter;
  filterOptions: SelectOption[];
  hasNextRow: boolean;
  portalTarget?: HTMLElement;
  onFieldChange: CallableFunction;
  onValueChange: CallableFunction;
  onRemove: CallableFunction;
}

export default function FilterRow(props: Props) {
  const {
    filter,
    filterOptions,
    hasNextRow,
    portalTarget = document.body,
    onFieldChange,
    onValueChange,
    onRemove
  } = props;

  const { article = "is", fieldId, inputType, value } = filter;
  let inputValue = value;

  const templateInput: TemplateInput = {
    key: fieldId,
    inputType,
    label: ""
  };

  const hasFilter = Boolean(inputType);

  const fieldOpts = filterOptions.filter(
    (opt: SelectOption) => opt.value === fieldId
  );

  const customProps: ObjectHash = {
    onChange: (value: any) => onValueChange(value),
    menuPortalTarget: portalTarget
  };

  if (inputType === "user-select") {
    customProps.menuPosition = "left";
    customProps.width = 400;
  }

  if (inputType === "airport-select") {
    customProps.menuPosition = "left";
    customProps.width = 400;
  }

  return (
    <div className="filter-row" key={`filter-row-${fieldId}`}>
      <AutocompleteInputFilled
        menuPortalTarget={portalTarget}
        options={filterOptions}
        onChange={onFieldChange}
        value={fieldOpts}
      />
      <div className="article-select">
        <ArticleSelect defaultValue={article} isDisabled={true} />
      </div>
      <div className="input">
        {hasFilter && (
          <InputController
            customProps={customProps}
            model={{ [fieldId]: inputValue }}
            templateInput={templateInput}
          />
        )}
        {!hasFilter && <div className="input-placeholder"></div>}
      </div>
      {hasFilter && hasNextRow && (
        <div className="andor">
          <div>And</div>
        </div>
      )}
      <div className="remove-filter">
        <RemoveButton onClick={onRemove} size="small" />
      </div>
    </div>
  );
}
