import React from "react";
import Button from "../../../../components/button";
import { AppPageProps } from "../../../../router";
import useModal from "../../../../hooks/use-modal.hook";
import ObjectTable from "../../../../components/object-table";

import "../../settings-page.scss";

export default function TravelPlannersPage(props: AppPageProps) {
  const { user, company, templates, settings } = props;
  const isPrimaryAdmin = Boolean(user.primary);

  const { openModal } = useModal();

  const tableCols = isPrimaryAdmin // planner actions only available to primary admin
    ? [
        ["name", "Name"],
        ["email", "Email"],
        ["primary", "Administrator"],
        ["blocked", "Login Disabled"],
        ["activated", "Invite Status"],
        ["travelPlannerActions", ""],
        ["id", "ID"]
      ]
    : [
        ["name", "Name"],
        ["email", "Email"],
        ["primary", "Administrator"],
        ["blocked", "Login Disabled"],
        ["id", "ID"]
      ];
  return (
    <div className="settings-subpage">
      <div className="settings-page__header-flex">
        <h2 className="settings-page__sub-header">Billable Users</h2>
        {isPrimaryAdmin ? (
          <Button
            color="product-blue"
            icon="add-user"
            label="Invite Travel Planner"
            onClick={() => openModal("invite-travel-planner")}
            size="medium"
          />
        ) : null}
      </div>
      <ObjectTable
        title="Users"
        columns={tableCols}
        renderOpts={{
          disableDownload: true,
          disableRowSelect: true,
          disableViewChange: true,
          useCardStyle: true
        }}
        searchOpts={{
          filters: [["role", "admin"]],
          fields: ["name", "email", "primary", "activated"],
          order: "name",
          resource: "users"
        }}
        templates={templates}
        company={company}
        settings={settings}
        user={user}
      />
    </div>
  );
}
