import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AppIcon from "../../../../components/app-icon";
import FileUploader from "../../../../components/file-uploader";
import Tooltip from "../../../../components/tooltip";
import "./image-block.scss";

const opts = {
  accept: ["image/*"],
  fromSources: ["local_file_system"],
  maxFiles: 1,
  imageMax: [500, 500]
};

interface Props {
  textAlign: string;
  width: string;
  src: string;
  onUpload: CallableFunction;
}

export default function ImageBlock(props: Props) {
  const { onUpload, src, textAlign, width } = props;
  const hasImage = Boolean(src.length);

  const trigger = (
    <div className="image-block__upload-trigger">
      <Tooltip
        text={hasImage ? "Change image" : "Upload image"}
        placement="left"
      >
        {hasImage ? (
          <img alt="upload-graphic" src={src} />
        ) : (
          <IconButton>
            <AppIcon type="add-image" size="x-large" />
          </IconButton>
        )}
      </Tooltip>
    </div>
  );

  return (
    <div
      className={`image-block image-block--align-${textAlign}`}
      style={{ width: `${width ? `${width}%` : "auto"}` }}
    >
      <FileUploader
        trigger={trigger}
        opts={opts}
        cb={([url]: any) => onUpload(url)}
      />
    </div>
  );
}
