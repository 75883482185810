import React from "react";
import classnames from "classnames";

import Button from "../../components/button";
import GridModel from "../../models/grid.model";
import Tooltip from "../../components/tooltip";
import useModal from "../../hooks/use-modal.hook";

import "./tile.scss";

interface Props {
  bigTileEl?: JSX.Element;
  children: JSX.Element;
  fullWidth?: boolean;
  title: string;
}

export interface TileProps {
  grid: GridModel;
  onGridChange: CallableFunction;
}

export default function Tile(props: Props) {
  const { openModalElement } = useModal();

  const { bigTileEl, children, fullWidth, title } = props;
  const hasSettings = false;
  const hasBigTile = Boolean(bigTileEl);

  return (
    <React.Fragment>
      <div className="tile-title">
        <span className="tile-title__label">{title}</span>
        <div className="tile-title__button-group">
          {hasSettings && (
            <Tooltip text="Settings" placement="top">
              <Button
                color="dark-gray"
                icon="settings"
                isRippleDisabled={true}
                isTransparent={true}
                size="small"
              />
            </Tooltip>
          )}
          {hasBigTile && (
            <Tooltip text="Expanded View" placement="top">
              <Button
                color="dark-gray"
                icon="expand"
                isRippleDisabled={true}
                isTransparent={true}
                size="small"
                onClick={() => {
                  openModalElement(bigTileEl);
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classnames("tile", { fullWidth })}>{children}</div>
    </React.Fragment>
  );
}
