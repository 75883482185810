import React from "react";
import Button from "../../button";
import useModal from "../../../hooks/use-modal.hook";

export interface Props {}

const CreateProfileButton = (props: Props) => {
  const { openModal } = useModal();

  return (
    <Button
      key="create-profile-button"
      color="product-background-blue"
      icon="add-user"
      label="Create Profile"
      onClick={() => openModal("user")}
      size="medium"
    ></Button>
  );
};

export default CreateProfileButton;
