import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import "./grid-tips-carousel.scss";

interface Props {}

export default function GridTipsCarousel(props: Props) {
  const tipText = [
    "Build a grid to keep all the travel for an event or project in one place.",
    "Send booked travel directly to a grid by forwarding in reservation emails.",
    "Add existing travel to a grid by associating trips with the grid tag.",
    "Grids can be used to filter travel by department to keep track of a budget."
  ];

  const prevArrow = (
    clickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => (
    <div className="control-arrow control-prev" onClick={clickHandler}>
      <AppIconFrame color="tag-light-blue">
        <AppIcon type="nav-arrow-left" />
      </AppIconFrame>
    </div>
  );

  const nextArrow = (
    clickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => (
    <div className="control-arrow control-next" onClick={clickHandler}>
      <AppIconFrame color="tag-light-blue">
        <AppIcon type="nav-arrow-right" />
      </AppIconFrame>
    </div>
  );

  return (
    <div className="grid-tips-carousel">
      <div className="title">grid tips</div>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        renderArrowPrev={prevArrow}
        renderArrowNext={nextArrow}
      >
        {tipText.map((tip: string, index: number) => (
          <div key={index} className="text">
            {tip}
          </div>
        ))}
      </Carousel>
    </div>
  );
}
