import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { AppPageProps } from "../../router";
import { isEqual } from "lodash";
import { DateTime } from "luxon";

import AppContainer from "../../container";
import { GridService, IGridService } from "../../services/grid.service";
import CollectionModel from "../../models/collection.model";
import GridModel from "../../models/grid.model";
import TravelQueryModel from "../../models/travel-query.model";
import useApp from "../../hooks/use-app.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";

import AccordionGroup from "../../components/accordion-group";
import BuildTravelButton from "../../components/button/build-travel";
import GridTravelers from "./grid-travelers";
import PageHeader from "../../components/page-header";
import PageLoader from "../../components/page-loader";
import TravelFilterMenu from "../../components/travel-filter-menu";
import TravelQueryTable from "../../components/travel-query-table";

import EmailParsingTile from "../../components/apps/email-parsing/tile";
import GridDetailsTile from "../../components/apps/grid-details/tile";
import GridDocumentsTile from "../../components/apps/grid-documents/tile";
import QuickReportsTile from "../../components/apps/quick-reports/tile";
import TravelMovementLogTile from "../../components/apps/travel-movement-log/tile";

import {
  getQueryStringParams,
  LoadState,
  ObjectHash
} from "../../utils/helpers";

import "./grid-view.scss";
import GridCollectionRow from "./grid-collection-row";

export default function GridView(props: AppPageProps) {
  const gridService: IGridService = AppContainer.get(GridService);
  const history = useHistory();
  const location = useLocation();
  const { id }: ObjectHash = useParams();
  const { setSnackbar } = useSnackbar();
  const { settings } = useApp();
  // @FEATURE_FLAG
  const hasTml = true;

  let prevTab: string | null = null;

  const [grid, setGrid] = useState<GridModel>(new GridModel());
  const [gridId, setGridId] = useState<string>(id);
  const [collections, setCollections] = useState<CollectionModel[]>([]);
  const [gridDataUpdatedAt, setGridDataUpdatedAt] = useState<string>(
    DateTime.local().toISO()
  );
  const [loadState, setLoadState] = useState<LoadState>("loading");

  const accordionGroups = ["Travelers", "Travel"];
  const isLoading = loadState !== "loaded";

  // update the URL to save the open tab
  const handleGroupChange = (groupName: string) => {
    const tabQs = groupName.toLowerCase();

    history.push({
      pathname: location.pathname,
      search: `?tab=${tabQs}`
    });

    prevTab = tabQs;
  };

  const onGridChange = (updatedGrid?: GridModel) => {
    if (updatedGrid) {
      setGrid(updatedGrid);
    }
    setGridDataUpdatedAt(DateTime.local().toISO());
  };

  const onFilterChange = async (updatedQuery: TravelQueryModel) => {
    if (isEqual(updatedQuery, grid.query)) {
      return;
    }

    grid.query = updatedQuery;

    const updatedGrid = await gridService.update(grid);

    if (!updatedGrid) {
      setSnackbar({
        message: "There was an error and the Grid was not updated.",
        variant: "error"
      });

      return false;
    }

    onGridChange(updatedGrid);
  };

  const getDefaultOpenIndex = () => {
    const tabs = accordionGroups.map((groupName: string) =>
      groupName.toLowerCase()
    );

    let defaultTab = prevTab;

    const qs = getQueryStringParams();

    if (qs.tab) {
      defaultTab = qs.tab;
    }

    let groupIndex = tabs.findIndex(
      (groupName: string) => groupName === defaultTab
    );

    if (groupIndex > -1) {
      prevTab = tabs[groupIndex];
      return groupIndex;
    }

    return [0, 1];
  };

  useEffect(() => {
    if (loadState !== "loading") {
      return;
    }

    gridService.getById(gridId).then((grid: GridModel | null) => {
      if (!grid) {
        history.push("/grids");
        return;
      }

      setGrid(grid);
      setLoadState("loaded");
    });
  }, [gridId, gridService, history, id, loadState, setGrid, setLoadState]);

  // load collections that use the grid
  useEffect(() => {
    if (!gridId) {
      return;
    }
    const { collections } = settings;
    const gridCollections = collections.filter((collection: CollectionModel) =>
      collection.grids.some((grid: GridModel) => grid.id === gridId)
    );
    setCollections(gridCollections);
  }, [gridId, setCollections, settings]);

  // reload grid when URL changes
  useEffect(() => {
    const urlGridId = location.pathname.split("/grids/").pop();
    if (!urlGridId || urlGridId === grid.id) {
      return;
    }
    setLoadState("loading");
    setGridId(urlGridId);
  }, [grid, location]);

  if (isLoading) {
    return (
      <div className="page-with-sidebar-container">
        <div id="grid-view-page">
          <PageLoader title="Loading Grid..." />
        </div>
      </div>
    );
  }

  const hasCollections = Boolean(collections.length);
  const defaultOpenIndex = getDefaultOpenIndex();

  return (
    <div className="page-with-sidebar-container">
      <div id="grid-view-page">
        <PageHeader title={grid.name}>
          <div className="header-tools">
            <BuildTravelButton gridId={grid.id} tagPreset={[grid.tag]} />
            <TravelFilterMenu
              key={`travel-filter-menu${grid ? `-${grid.id} }` : ""}`}
              onChange={onFilterChange}
              grid={grid}
            />
          </div>
        </PageHeader>

        {hasCollections && (
          <div className="grid-collections">
            {collections.map((collection: CollectionModel) => (
              <GridCollectionRow collection={collection} key={collection.id} />
            ))}
          </div>
        )}

        <AccordionGroup
          defaultOpen={defaultOpenIndex}
          onGroupChange={(groupName: string) => handleGroupChange(groupName)}
          titles={accordionGroups}
        >
          <div>
            <GridTravelers
              key={`grid-travelers-${gridDataUpdatedAt}`}
              grid={grid}
              onGridChange={onGridChange}
            />
          </div>
          <div>
            <TravelQueryTable
              key={`grid-air-${gridDataUpdatedAt}`}
              grid={grid}
              travelQuery={grid.query}
              travelType="air"
              onChange={onGridChange}
            />
            <TravelQueryTable
              key={`grid-car-${gridDataUpdatedAt}`}
              grid={grid}
              travelQuery={grid.query}
              travelType="car"
              onChange={onGridChange}
            />
            <TravelQueryTable
              key={`grid-hotel-${gridDataUpdatedAt}`}
              grid={grid}
              travelQuery={grid.query}
              travelType="hotel"
              onChange={onGridChange}
            />
            <TravelQueryTable
              key={`grid-rail-${gridDataUpdatedAt}`}
              grid={grid}
              travelQuery={grid.query}
              travelType="rail"
              onChange={onGridChange}
            />
            <TravelQueryTable
              key={`grid-activity-${gridDataUpdatedAt}`}
              grid={grid}
              travelQuery={grid.query}
              travelType="activity"
              onChange={onGridChange}
            />
          </div>
        </AccordionGroup>
      </div>
      <div className="grid-view-sidebar">
        <div className="inner">
          <GridDetailsTile
            grid={grid}
            onGridChange={onGridChange}
            key={`grid-details-${gridDataUpdatedAt}`}
          />
          <EmailParsingTile grid={grid} onGridChange={onGridChange} />
          <GridDocumentsTile grid={grid} onGridChange={onGridChange} />
          <QuickReportsTile grid={grid} onGridChange={onGridChange} />
          {hasTml && (
            <TravelMovementLogTile grid={grid} onGridChange={onGridChange} />
          )}
        </div>
      </div>
    </div>
  );
}
