import React from "react";
import classnames from "classnames";
import AutocompleteInputFilled from "./AutocompleteInputFilled";
import {
  getSelectValueFromOptions,
  SelectOption
} from "../../../utils/helpers";
import "./inputs.scss";

export interface Props {
  isRequired?: boolean;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  value?: any;
}

export const BookingStatusOpts: SelectOption[] = [
  { label: "In Progress", value: "initiated" },
  { label: "Ready to Book", value: "ready" },
  { label: "Details Needed", value: "requested" },
  { label: "Booked", value: "booked" }
];

export default function BookingRequestStatusInput(props: Props) {
  const valueProps = getSelectValueFromOptions(props, BookingStatusOpts);
  const { onChange } = props;

  let valueClass = "initiated";

  if (valueProps.value?.length) {
    valueClass = valueProps.value[0].value;
  }

  const acProps = {
    ...props,
    ...valueProps,
    onChange: (val: any) => {
      onChange(val);
    }
  };

  return (
    <div className={classnames("booking-request-input", valueClass)}>
      <AutocompleteInputFilled {...acProps} options={BookingStatusOpts} />
    </div>
  );
}
