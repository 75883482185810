import { ObjectHash } from "../utils/helpers";
import BaseModel from "./base.model";

export default class TemplatePropertyModel extends BaseModel {
  id: string;
  deleted: boolean;
  isHidden: boolean;
  migratedId?: number;

  company: string;
  companyId: string;
  userDefinedTemplate: string;

  friendlyName: string;
  name: string;
  nestedProperties: any[];
  position: number;
  propertyOptions: any[] | null;
  propertyRestrictions: string[];
  propertyType:
    | "airport"
    | "currency"
    | "date"
    | "duration"
    | "location"
    | "select"
    | "station" // rail equivalent for airport
    | "text" // single line text
    | "textarea" // long form text
    | "timezone"
    | "user_list"; // travelers

  // Can the user edit this prop value when working with trips, users, etc. in a form?
  readOnly: boolean;

  // Was this prop added by the system via WorkspaceService::makeWorkspaceTemplate?
  isCustomField: boolean;

  createdAt: string;
  updatedAt: string;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.deleted = data.deleted;
    this.isHidden = data.isHidden;
    if (data.migratedId) {
      this.migratedId = data.migratedId;
    }

    this.company = data.company;
    this.companyId = data.companyId;
    this.userDefinedTemplate = data.userDefinedTemplate;

    this.friendlyName = data.friendlyName;
    this.name = data.name;
    this.nestedProperties = data.nestedProperties || [];
    this.position = data.position;
    this.propertyOptions = data.propertyOptions;
    this.propertyRestrictions = data.propertyRestrictions || [];
    this.propertyType = data.propertyType || "text";

    this.readOnly = Boolean(data.readOnly);
    this.isCustomField = Boolean(data.isCustomField);

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  // Can the use edit this prop's name, type and position via the template editor?
  isProtected(): boolean {
    return this.propertyRestrictions.includes("protected");
  }
}
