import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function UserProfileIconSkeleton() {
  return (
    <Skeleton
      className="user-profile-icon--skeleton"
      animation="wave"
      height={28}
      width={28}
      variant="circle"
    />
  );
}
