import React from "react";
import Button, { ButtonSizes } from "..";

export interface Props {
  onClick?: CallableFunction;
  size: ButtonSizes;
}

const CloseButton = (props: Props) => {
  const { onClick, size } = props;

  return (
    <Button
      color="red"
      icon="close"
      isRippleDisabled={true}
      isTransparent={true}
      onClick={onClick}
      size={size || "medium"}
    />
  );
};

export default CloseButton;
