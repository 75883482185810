import React from "react";
import CompanyModel from "../../models/company.model";

export interface Props {
  company: CompanyModel;
}

const CompanyLogo = (props: Props) => {
  const { logoUploadUrl } = props.company;
  const logoUrl = logoUploadUrl
    ? logoUploadUrl
    : "https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg";

  return <img alt="company-logo" className="company-logo" src={logoUrl} />;
};

export default CompanyLogo;
