import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";

import Button from "../../../button";
import ColorInput from "../../../form/inputs/ColorInput";
import Tooltip from "../../../tooltip";

import "./color-menu.scss";

interface Props {
  onChangeTableBlockColor: CallableFunction;
  tagId: string;
}

export default function ColorMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const popperClass = open ? "color-popper color-popper--open" : "color-popper";

  const handleClick = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <div className={"color-menu"}>
      <Tooltip text="Change Color">
        <div onClick={handleClick}>
          <Button
            color="dark-gray"
            icon="brush"
            isRippleDisabled={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
      <Popper
        id={`color-popper-${props.tagId}`}
        className={popperClass}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="color-popper__content">
            <h1>Change Color</h1>
            <ColorInput
              noLightColors={true}
              onChange={(color: string) => props.onChangeTableBlockColor(color)}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
