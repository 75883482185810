import React, { useEffect, useState } from "react";
import classnames from "classnames";

import AppContainer from "../../../container";
import { getDisplayDate, LoadState } from "../../../utils/helpers";
import { getLogByGridId, getTmlLogEntryGroupId } from "./lib/service";
import { IUserService, UserService } from "../../../services/user.service";
import Tile, { TileProps } from "../tile";
import { TmlEntryModel, TmlLogModel } from "./lib/models";
import TravelMovementLogBigTile from "./big-tile";
import UserModel from "../../../models/user.model";

import "./tile.scss";

export default function TravelMovementLogTile(props: TileProps) {
  const { grid } = props;
  const userService: IUserService = AppContainer.get(UserService);

  const [users, setUsers] = useState<Map<string, UserModel>>(new Map());
  const [entries, setEntries] = useState<Map<number, TmlEntryModel[]>>(
    new Map()
  );
  const [loadState, setLoadState] = useState<LoadState>("unloaded");

  useEffect(() => {
    if (loadState !== "unloaded") {
      return;
    }
    setLoadState("loading");
    getLogByGridId(grid.id).then((log: TmlLogModel | null) => {
      if (!log?.entries.length) {
        setLoadState("loaded");
        return;
      }

      const entries: Map<number, TmlEntryModel[]> = new Map();
      const userIds: string[] = [];

      log.entries.forEach((entry: TmlEntryModel) => {
        const groupId = getTmlLogEntryGroupId(entry, {
          fieldId: "date",
          label: ""
        }) as number;
        const groupEntries = entries.get(groupId) || [];
        groupEntries.push(entry);
        entries.set(groupId, groupEntries);
        userIds.push(entry.userId);
        return entry;
      });

      setEntries(entries);

      userService.getByIds(userIds).then((response: UserModel[]) => {
        const users: Map<string, UserModel> = new Map();
        response.forEach((user: UserModel) => users.set(user.id, user));
        setUsers(users);
        setLoadState("loaded");
      });
    });
  }, [grid, loadState, setEntries, setLoadState, setUsers, userService]);

  const bigTileEl = <TravelMovementLogBigTile grid={grid} />;
  const loaded = loadState === "loaded";

  // nearest travel should be listed first
  const entryDates = Array.from(entries.keys()).sort();

  return (
    <Tile title="Travel Movement" bigTileEl={bigTileEl} fullWidth={true}>
      <div className="tml-tile">
        <div className="header">Traveler Movement</div>
        {loaded && (
          <div className="tml-entries">
            {entryDates.map((date: number) => {
              const groupEntries = entries.get(date) || [];
              return (
                <div className="date-group" key={date}>
                  <div className="header">
                    {date ? getDisplayDate(date, "EEE, MMM dd, y") : "TBD"}
                  </div>
                  <div className="travelers">
                    {groupEntries.map((entry: TmlEntryModel) => {
                      const { userId, type } = entry;
                      const user = users.get(userId);
                      const isInbound = type === "inbound";
                      if (!user) {
                        return null;
                      }
                      return (
                        <div className="traveler-row" key={`${userId}-${type}`}>
                          <div className="name truncate">
                            {user.getFullName()}
                          </div>
                          <div
                            className={classnames("entry-type", { isInbound })}
                          >
                            {isInbound ? "Arrival" : "Departure"}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Tile>
  );
}
