import { inject } from "inversify";
import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import SegmentModel from "../models/segment.model";

export interface ISegmentService {
  create(data: { [index: string]: any }): Promise<SegmentModel | null>;
  delete(segmentId: string): Promise<SegmentModel | null>;
  getById(segmentId: string): Promise<SegmentModel | null>;
  update(
    segmentId: string,
    data: { [index: string]: any }
  ): Promise<SegmentModel | null>;
}

export class SegmentService extends BaseService implements ISegmentService {
  @inject(ApiService)
  private apiService!: IApiService;

  async create(data: { [index: string]: any }): Promise<SegmentModel | null> {
    const response = await this.apiService.post(`/segments`, data);

    if (!response) {
      return null;
    }

    return new SegmentModel(response);
  }

  async delete(segmentId: string): Promise<SegmentModel | null> {
    const response = await this.apiService.delete(`/segments/${segmentId}`);

    if (!response) {
      return null;
    }

    return new SegmentModel(response);
  }

  async getById(segmentId: string): Promise<SegmentModel | null> {
    const response = await this.apiService.get(`/segments/${segmentId}`);

    if (!response) {
      return null;
    }

    return new SegmentModel(response);
  }

  async update(
    segmentId: string,
    data: { [index: string]: any }
  ): Promise<SegmentModel | null> {
    const response = await this.apiService.put(`/segments/${segmentId}`, data);

    if (!response) {
      return null;
    }

    return new SegmentModel(response);
  }
}
