import React, { RefObject, useRef, useState } from "react";

import Button from "../../../../button";
import PopperMenu from "../../../../popper-menu";
import TextInput from "../../../../form/inputs/TextInput";
import { TmlEntryModel } from "../../lib/models";
import UserModel from "../../../../../models/user.model";

import "./tml-traveler-cell.scss";

interface Props {
  entry: TmlEntryModel;
  onChange: CallableFunction;
  portalTarget: RefObject<HTMLElement>;
  user: UserModel;
}

export default function TmlTravelerCell(props: Props) {
  const { entry, onChange, portalTarget, user } = props;
  const { jobTitle } = entry;

  // if there is no override, entry.jobTitle should === false
  const entryJobTitle = jobTitle === false ? user.jobTitle : jobTitle;

  const menuRef = useRef();
  const [override, setOverride] = useState(jobTitle || "");

  return (
    <div className="tml-cell traveler">
      <div className="tml-cell-popper-wrapper">
        <PopperMenu
          anchor={
            <div className="label">
              <div className="name circular-bold truncate">
                {user && user.getFullName()}
              </div>
              <div className="job-title truncate">{entryJobTitle}</div>
            </div>
          }
          portalTarget={portalTarget}
          placement="bottom-start"
          ref={menuRef}
        >
          <div className="tml-traveler-menu">
            <TextInput
              autoFocus
              value={override}
              onChange={(value: string) => {
                setOverride(value);
              }}
              placeholder="Job Title"
            />
            <div className="buttons">
              <Button
                color="product-blue"
                isDisabled={!Boolean(override)}
                label="Override"
                onClick={() => {
                  onChange(override);
                  (menuRef?.current as any).close();
                }}
                size="medium"
              />
              <Button
                color="gray"
                isTransparent={true}
                label="Clear"
                onClick={() => {
                  setOverride("");
                  onChange(false);
                  (menuRef?.current as any).close();
                }}
                size="medium"
              />
            </div>
          </div>
        </PopperMenu>
      </div>
    </div>
  );
}
