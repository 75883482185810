import React, { SVGProps } from "react";

import SvgIcon, { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

import AccessTimeIcon from "@material-ui/icons/AccessTimeRounded";
import AddBoxIcon from "@material-ui/icons/AddBoxRounded";
import AddCircleIcon from "@material-ui/icons/AddCircleRounded";
import AddIcon from "@material-ui/icons/AddRounded";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternateRounded";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActiveRounded";
import ArchiveIcon from "@material-ui/icons/ArchiveRounded";
import ArrowBackIcon from "@material-ui/icons/ArrowBackRounded";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownRounded";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUpRounded";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardRounded";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpwardRounded";
import AttachFileIcon from "@material-ui/icons/AttachFileRounded";
import BlockIcon from "@material-ui/icons/BlockRounded";
import BrushIcon from "@material-ui/icons/BrushRounded";
import CancelIcon from "@material-ui/icons/CancelRounded";
import CheckCircleIcon from "@material-ui/icons/CheckCircleRounded";
import CloseIcon from "@material-ui/icons/CloseRounded";
import ConfirmationNumberRounded from "@material-ui/icons/ConfirmationNumberRounded";
import ContactsIcon from "@material-ui/icons/ContactsRounded";
import CropFreeIcon from "@material-ui/icons/CropFreeRounded";
import DateRangeIcon from "@material-ui/icons/DateRangeRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCarRounded";
import DoneIcon from "@material-ui/icons/DoneRounded";
import DragIndicatorIcon from "@material-ui/icons/DragIndicatorRounded";
import EditIcon from "@material-ui/icons/EditRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import EventIcon from "@material-ui/icons/EventRounded";
import ExitToAppIcon from "@material-ui/icons/ExitToAppRounded";
import FileCopyIcon from "@material-ui/icons/FileCopyRounded";
import FilterListIcon from "@material-ui/icons/FilterListRounded";
import FlightIcon from "@material-ui/icons/FlightRounded";
import FlightLandIcon from "@material-ui/icons/FlightLandRounded";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoffRounded";
import FolderIcon from "@material-ui/icons/FolderRounded";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenterRounded";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import FormatBoldIcon from "@material-ui/icons/FormatBoldRounded";
import FormatItalicIcon from "@material-ui/icons/FormatItalicRounded";
import FormatSizeIcon from "@material-ui/icons/FormatSizeRounded";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlinedRounded";
import GetAppIcon from "@material-ui/icons/GetAppRounded";
import GroupAddIcon from "@material-ui/icons/GroupAddRounded";
import GroupIcon from "@material-ui/icons/GroupRounded";
import GroupWorkIcon from "@material-ui/icons/GroupWorkRounded";
import HomeIcon from "@material-ui/icons/HomeRounded";
import ImageIcon from "@material-ui/icons/ImageRounded";
import InboxIcon from "@material-ui/icons/InboxRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFileRounded";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRightRounded";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import LabelIcon from "@material-ui/icons/LabelRounded";
import ListAltIcon from "@material-ui/icons/ListAltRounded";
import LocalHotelIcon from "@material-ui/icons/LocalHotelRounded";
import LockIcon from "@material-ui/icons/LockRounded";
import MapIcon from "@material-ui/icons/MapRounded";
import MergeTypeIcon from "@material-ui/icons/MergeTypeRounded";
import MoreHorizIcon from "@material-ui/icons/MoreHorizRounded";
import MoreVertIcon from "@material-ui/icons/MoreVertRounded";
import NotesIcon from "@material-ui/icons/NotesRounded";
import PersonAddIcon from "@material-ui/icons/PersonAddRounded";
import PersonIcon from "@material-ui/icons/PersonRounded";
import PhonelinkIcon from "@material-ui/icons/PhonelinkRounded";
import PhonelinkOffIcon from "@material-ui/icons/PhonelinkOffRounded";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdfRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import PublicIcon from "@material-ui/icons/PublicRounded";
import RefreshIcon from "@material-ui/icons/RefreshRounded";
import RemoveIcon from "@material-ui/icons/RemoveRounded";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import RoomRoundedIcon from "@material-ui/icons/RoomRounded";
import SaveIcon from "@material-ui/icons/SaveRounded";
import SearchIcon from "@material-ui/icons/SearchRounded";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import SortByAlphaIcon from "@material-ui/icons/SortByAlphaRounded";
import StarIcon from "@material-ui/icons/StarRounded";
import StarBorderIcon from "@material-ui/icons/StarBorderRounded";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRightRounded";
import SyncAltIcon from "@material-ui/icons/SyncAltRounded";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabledRounded";
import TableChartIcon from "@material-ui/icons/TableChartRounded";
import TimerIcon from "@material-ui/icons/TimerRounded";
import TitleIcon from "@material-ui/icons/TitleRounded";
import TrainIcon from "@material-ui/icons/TrainRounded";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlatRounded";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStationRounded";
import UnarchiveIcon from "@material-ui/icons/UnarchiveRounded";
import UpdateIcon from "@material-ui/icons/UpdateRounded";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottomRounded";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenterRounded";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTopRounded";
import VideocamIcon from "@material-ui/icons/VideocamRounded";
import ViewAgendaIcon from "@material-ui/icons/ViewAgendaRounded";
import ViewColumnIcon from "@material-ui/icons/ViewColumnRounded";
import ViewComfyIcon from "@material-ui/icons/ViewComfyRounded";
import ViewDayIcon from "@material-ui/icons/ViewDayRounded";
import VisibilityIcon from "@material-ui/icons/VisibilityRounded";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffRounded";
import WarningIcon from "@material-ui/icons/WarningRounded";
import WifiOffIcon from "@material-ui/icons/WifiOffRounded";
import WorkIcon from "@material-ui/icons/WorkRounded";
import WorkOffIcon from "@material-ui/icons/WorkOffRounded";

import classnames from "classnames";

import { ReactComponent as ElectricalCordIcon } from "../../images/app-icons/electrical-cord-icon.svg";
import { ReactComponent as HelpCenterIcon } from "../../images/app-icons/material-ui-help-center-icon.svg";
import { ReactComponent as LightblubIcon } from "../../images/app-icons/lightbulb-icon.svg";
import { ReactComponent as SuperAdminIcon } from "../../images/app-icons/material-ui-admin-icon.svg";
import { ReactComponent as TgGridIcon } from "../../images/app-icons/tg-grid-icon.svg";
import { ReactComponent as TgCollectionIcon } from "../../images/app-icons/airplane-ticket.svg";
import { ReactComponent as TextSnippetIcon } from "../../images/app-icons/text-snippet-icon.svg";

import "./app-icon.scss";

export type AppIconType =
  | "add-user"
  | "add-users"
  | "add-image"
  | "admin"
  | "agenda"
  | "airplane-mode"
  | "airplane"
  | "archive"
  | "arrow-down-right"
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "attachment"
  | "briefcase-strike"
  | "briefcase"
  | "brush"
  | "bulb"
  | "car"
  | "checkmark-circle"
  | "checkmark"
  | "clock"
  | "close"
  | "contacts"
  | "cord"
  | "dashboard"
  | "date-range"
  | "download"
  | "drag-handle"
  | "edit"
  | "email"
  | "error"
  | "event"
  | "expand"
  | "file"
  | "file-copy"
  | "filter"
  | "flight-land"
  | "flight-takeoff"
  | "format-bold"
  | "format-hor-center"
  | "format-hor-left"
  | "format-hor-right"
  | "format-italic"
  | "format-size"
  | "format-underline"
  | "format-vert-bottom"
  | "format-vert-center"
  | "format-vert-top"
  | "globe"
  | "grid"
  | "group"
  | "help-center"
  | "home"
  | "horiz-dots"
  | "hotel"
  | "image"
  | "info"
  | "laptop-strike"
  | "laptop"
  | "list"
  | "lock"
  | "logout"
  | "map"
  | "map-marker"
  | "menu-arrow"
  | "menu-arrow-open"
  | "merge"
  | "nav-arrow-down"
  | "nav-arrow-left"
  | "nav-arrow-right"
  | "nav-arrow-up"
  | "no-circle"
  | "notes"
  | "one-way"
  | "pdf"
  | "people"
  | "person"
  | "plus-box"
  | "plus-circle"
  | "plus"
  | "print"
  | "refresh"
  | "remove-circle"
  | "remove"
  | "restore"
  | "reports"
  | "round-trip"
  | "save"
  | "search"
  | "settings"
  | "sort"
  | "star"
  | "star-border"
  | "stopwatch"
  | "success"
  | "sync-strike"
  | "table"
  | "tag"
  | "text-snippet"
  | "tg-collection"
  | "tg-grid"
  | "ticket"
  | "title"
  | "train"
  | "transfer-station"
  | "trash"
  | "unarchive"
  | "update"
  | "vert-dots"
  | "video-cam"
  | "view-column"
  | "view-day"
  | "visible-strike"
  | "visible"
  | "warning"
  | "wifi-off";

type MuiIconType = OverridableComponent<SvgIconTypeMap>;
type RawSvgImportType = React.FunctionComponent<SVGProps<SVGSVGElement>>;
type AppIconComponent = MuiIconType | RawSvgImportType;

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
function isRawSvgImport(
  component: AppIconComponent
): component is RawSvgImportType {
  return (component as RawSvgImportType).prototype !== undefined;
}

export const AppIconMap: Map<AppIconType, AppIconComponent> = new Map([
  ["add-user", PersonAddIcon],
  ["add-users", GroupAddIcon],
  ["add-image", AddPhotoAlternateIcon],
  ["admin", SuperAdminIcon as AppIconComponent],
  ["agenda", ViewAgendaIcon],
  ["airplane-mode", AirplanemodeActiveIcon],
  ["airplane", FlightIcon],
  ["archive", ArchiveIcon],
  ["arrow-down-right", SubdirectoryArrowRightIcon],
  ["arrow-down", ArrowDownwardIcon],
  ["arrow-left", ArrowBackIcon],
  ["arrow-right", ArrowForwardIcon],
  ["arrow-up", ArrowUpwardIcon],
  ["attachment", AttachFileIcon],
  ["briefcase-strike", WorkOffIcon],
  ["briefcase", WorkIcon],
  ["brush", BrushIcon],
  ["bulb", LightblubIcon],
  ["car", DirectionsCarIcon],
  ["checkmark-circle", CheckCircleIcon],
  ["checkmark", DoneIcon],
  ["clock", AccessTimeIcon],
  ["close", CloseIcon],
  ["contacts", ContactsIcon],
  ["cord", ElectricalCordIcon],
  ["dashboard", InboxIcon],
  ["date-range", DateRangeIcon],
  ["download", GetAppIcon],
  ["drag-handle", DragIndicatorIcon],
  ["edit", EditIcon],
  ["email", EmailIcon],
  ["error", ErrorIcon],
  ["event", EventIcon],
  ["expand", CropFreeIcon],
  ["file", InsertDriveFileIcon],
  ["file-copy", FileCopyIcon],
  ["filter", FilterListIcon],
  ["flight-land", FlightLandIcon],
  ["flight-takeoff", FlightTakeoffIcon],
  ["format-bold", FormatBoldIcon],
  ["format-hor-center", FormatAlignCenterIcon],
  ["format-hor-left", FormatAlignLeftIcon],
  ["format-hor-right", FormatAlignRightIcon],
  ["format-italic", FormatItalicIcon],
  ["format-size", FormatSizeIcon],
  ["format-underline", FormatUnderlinedIcon],
  ["format-vert-bottom", VerticalAlignBottomIcon],
  ["format-vert-center", VerticalAlignCenterIcon],
  ["format-vert-top", VerticalAlignTopIcon],
  ["globe", PublicIcon],
  ["grid", ViewComfyIcon],
  ["group", GroupWorkIcon],
  ["help-center", HelpCenterIcon],
  ["home", HomeIcon],
  ["horiz-dots", MoreHorizIcon],
  ["hotel", LocalHotelIcon],
  ["image", ImageIcon],
  ["info", InfoIcon],
  ["laptop-strike", PhonelinkOffIcon],
  ["laptop", PhonelinkIcon],
  ["list", ListAltIcon],
  ["lock", LockIcon],
  ["logout", ExitToAppIcon],
  ["map", MapIcon],
  ["map-marker", RoomRoundedIcon],
  ["menu-arrow", ArrowDropDownIcon],
  ["menu-arrow-open", ArrowDropUpIcon],
  ["merge", MergeTypeIcon],
  ["nav-arrow-down", KeyboardArrowDownIcon],
  ["nav-arrow-left", KeyboardArrowLeftIcon],
  ["nav-arrow-right", KeyboardArrowRightIcon],
  ["nav-arrow-up", KeyboardArrowUpIcon],
  ["no-circle", BlockIcon],
  ["notes", NotesIcon],
  ["one-way", TrendingFlatIcon],
  ["pdf", PictureAsPdfIcon],
  ["people", GroupIcon],
  ["person", PersonIcon],
  ["plus-box", AddBoxIcon],
  ["plus-circle", AddCircleIcon],
  ["plus", AddIcon],
  ["print", PrintIcon],
  ["refresh", RefreshIcon],
  ["remove-circle", CancelIcon],
  ["remove", RemoveIcon],
  ["restore", RestoreFromTrashIcon],
  ["reports", FolderIcon],
  ["round-trip", SyncAltIcon],
  ["save", SaveIcon],
  ["search", SearchIcon],
  ["settings", SettingsIcon],
  ["sort", SortByAlphaIcon],
  ["star", StarIcon],
  ["star-border", StarBorderIcon],
  ["stopwatch", TimerIcon],
  ["success", CheckCircleIcon],
  ["sync-strike", SyncDisabledIcon],
  ["table", TableChartIcon],
  ["tag", LabelIcon],
  ["text-snippet", TextSnippetIcon],
  ["tg-collection", TgCollectionIcon],
  ["tg-grid", TgGridIcon as AppIconComponent],
  ["ticket", ConfirmationNumberRounded],
  ["title", TitleIcon],
  ["train", TrainIcon],
  ["transfer-station", TransferWithinAStationIcon],
  ["trash", DeleteIcon],
  ["unarchive", UnarchiveIcon],
  ["update", UpdateIcon],
  ["vert-dots", MoreVertIcon],
  ["video-cam", VideocamIcon],
  ["view-column", ViewColumnIcon],
  ["view-day", ViewDayIcon],
  ["visible-strike", VisibilityOffIcon],
  ["visible", VisibilityIcon],
  ["warning", WarningIcon],
  ["wifi-off", WifiOffIcon]
]);

export const getIconComponent = (
  type: AppIconType
): AppIconComponent | null => {
  return AppIconMap.get(type) ?? null;
};

export type AppIconColor =
  | "black"
  | "product-blue"
  | "dark-gray"
  | "gray"
  | "green"
  | "invert-black"
  | "orange"
  | "purple"
  | "red"
  | "white"
  | "yellow";

interface activeCallback {
  (): boolean;
}

export interface Props {
  type: AppIconType;
  active?: activeCallback;
  color?: AppIconColor;
  size?: "x-small" | "small" | "medium" | "large" | "x-large";
  spin?: Boolean;
}

/**
 * SVG-based icons, based on the MaterilUI icon set: https://material-ui.com/components/material-icons"
 */
export default function AppIcon(props: Props) {
  let { type, size, color, active, spin } = props;

  const IconComponent = AppIconMap.get(type);

  if (!IconComponent) {
    console.warn("unknown icon", type);
    return null;
  }

  if (active && active()) {
    color = "product-blue";
  }

  const classNames = classnames("icon", type, size, color, {
    "animated-spinning": spin
  });

  // MUI uses a wrapper component named SvgIcon, which must be manually added for non-MUI icons
  if (isRawSvgImport(IconComponent)) {
    return <SvgIcon className={classNames} component={IconComponent} />;
  }

  return <IconComponent className={classNames} />;
}
