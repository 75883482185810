import React, { useState } from "react";

import AppContainer from "../../../../container";
import { AppPageProps } from "../../../../router";
import { AppActionTypes } from "../../../../context/reducer";
import useApp from "../../../../hooks/use-app.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";
import {
  CompanyService,
  ICompanyService
} from "../../../../services/company.service";
import CompanyModel from "../../../../models/company.model";

import Button from "../../../../components/button";
import DateInput from "../../../../components/form/inputs/DateInput";

import "../../settings-page.scss";

const DataFreezePage = (props: AppPageProps) => {
  const { company, settings } = props;

  const companyService: ICompanyService = AppContainer.get(CompanyService);
  const { dispatch } = useApp();
  const { setSnackbar } = useSnackbar();

  const [freezeDate, setFreezeDate] = useState<string>(
    settings.dataFreezeDate ?? ""
  );

  const updateDataFreeze = async (dataFreezeDate: string) => {
    const updatedCompany = new CompanyModel({
      ...company,
      settings: {
        ...company.settings,
        dataFreezeDate
      }
    });

    const response: CompanyModel | null = await companyService.update(
      updatedCompany
    );

    if (response) {
      dispatch({ type: AppActionTypes.UpdateCompany, payload: response });

      setSnackbar({
        message: "Data freeze applied!",
        variant: "success"
      });
    }
  };

  const dateInputProps: any = {
    onChange: (value: string) => setFreezeDate(value)
  };

  if (freezeDate) {
    dateInputProps.defaultValue = freezeDate;
  }

  return (
    <div className="settings-subpage">
      <h2 className="settings-page__sub-header">Data Freeze</h2>
      <p className="settings-page__hint-text">
        Lock editing of all travel with an invoice date on or before selected
        date.
      </p>
      <DateInput {...dateInputProps} />
      <p className="settings-page__hint-text">
        * Applies midnight UTC of selected date
      </p>
      <Button
        color="product-blue"
        onClick={() => {
          updateDataFreeze(freezeDate);
        }}
        label="Apply Freeze"
        size="medium"
      />
    </div>
  );
};

export default DataFreezePage;
