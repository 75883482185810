import React from "react";
import MuiTooltip from "@material-ui/core/Tooltip";
import "./tooltip.scss";

interface Props {
  children: JSX.Element;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  text: string | JSX.Element | JSX.Element[];
}

export default function Tooltip(props: Props) {
  const { children, placement, text } = props;

  return (
    <MuiTooltip
      classes={{ tooltip: "app-tooltip" }}
      disableFocusListener={true} // prevents tooltip from remaining open on page when a new tab is opened
      placement={placement || "bottom"}
      title={text} // text shown in tooltip
    >
      <span className="app-tooltip-wrapper">{children}</span>
    </MuiTooltip>
  );
}
