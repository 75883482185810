import { inject } from "inversify";
import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import { SearchService, ISearchService } from "./search.service";
import { ObjectHash } from "../utils/helpers";
import ParsedEmailModel from "../models/parsed-email.model";

export interface IIntegrationService {
  createSandbox(data: ObjectHash): Promise<ObjectHash | null>;
  getParsedEmails(
    instances: ObjectHash[],
    gridId?: string
  ): Promise<ParsedEmailModel[]>;
  getRecentInstances(): Promise<any[]>;
  updateEmailParse(
    integrationInstanceId: string,
    data: ObjectHash
  ): Promise<any>;
  verifyConcurConnect(verificationCode: string, data: ObjectHash): Promise<any>;
}

export class IntegrationService extends BaseService
  implements IIntegrationService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(SearchService)
  private searchService!: ISearchService;

  async createSandbox(data: ObjectHash): Promise<ObjectHash | null> {
    const response = await this.apiService.post("/sandbox", data);

    if (!response) {
      return null;
    }

    return response;
  }

  async getParsedEmails(
    instances: ObjectHash[],
    gridId: string
  ): Promise<any[]> {
    const integrationInstances = instances.map((instance: ObjectHash) => ({
      ...instance,
      error: ["ERROR"].includes(instance.status),
      canceled: ["CANCELLED"].includes(instance.status),
      manual: instance.message === "manually completed",
      inReview: ["For Review", "FOR-REVIEW", "IN-REVIEW"].includes(
        instance.meta?.traxoStatus
      )
    }));

    const mailgunInstances: ObjectHash[] = integrationInstances.filter(
      (instance: ObjectHash) => instance.integration === "MAILGUN"
    );

    let traxoInstances = integrationInstances.filter(
      (instance: ObjectHash) => instance.integration === "TRAXO"
    );

    if (gridId) {
      traxoInstances = traxoInstances.filter(
        (instance: ObjectHash) => instance.meta?.gridId === gridId
      );
    }

    // sort by newest first
    traxoInstances.sort((a: ObjectHash, b: ObjectHash) => {
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });

    // filter out resends
    traxoInstances = traxoInstances.filter(
      (instance: ObjectHash, index: number) =>
        !traxoInstances
          .slice(0, index)
          .find(
            (subIi: ObjectHash) =>
              subIi.meta.mailgunInstanceId === instance.meta?.mailgunInstanceId
          )
    );

    const response = traxoInstances
      .map((ii: ObjectHash) => ({
        ...ii,
        gridId: ii.meta.gridId,
        mailgunSubject:
          ii.meta &&
          ii.meta.mailgunInstanceId &&
          (
            (
              mailgunInstances.find(
                (mi: ObjectHash) => mi.id === ii.meta.mailgunInstanceId
              ) || {}
            ).meta || {}
          ).subject
      }))
      .map((email: ObjectHash) => new ParsedEmailModel(email));

    return response;
  }

  async getRecentInstances(): Promise<any[]> {
    return this.searchService.search("integrationInstances", {
      fields: ["createdAt", "message", "finished", "id", "status", "meta"],
      filters: [
        ["cleared", [false]],
        ["integration", ["MAILGUN", "TRAXO"]],
        ["updatedAt", Date.now() - 24 * 60 * 60000 * 7]
      ],
      order: "updatedAt",
      reverse: true,
      limit: 100
    });
  }

  async updateEmailParse(
    integrationInstanceId: string,
    data: ObjectHash
  ): Promise<any> {
    const response = await this.apiService.put(
      `/integration-instances/${integrationInstanceId}`,
      data
    );

    if (!response) {
      return null;
    }

    return response;
  }

  async verifyConcurConnect(
    verificationCode: string,
    data: ObjectHash
  ): Promise<any> {
    const uri = "/concur/connect";
    const response = await this.apiService.post(uri, data);

    if (!response) {
      return null;
    }

    return response;
  }
}
