import { inject } from "inversify";

import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import { ISearchService, SearchService } from "./search.service";
import { IWorkspaceService, WorkspaceService } from "./workspace.service";
import UserModel from "../models/user.model";

import { ObjectHash } from "../utils/helpers";

export interface IUserService {
  create(data: ObjectHash): Promise<UserModel | null>;
  delete(userId: string): Promise<UserModel | null>;
  getById(
    userId: string,
    loadLinkedUsers?: boolean,
    loadTemplateFields?: boolean
  ): Promise<UserModel | null>;
  getByIds(userIds: string[]): Promise<UserModel[]>;
  getByTags(tagIds: string[]): Promise<UserModel[]>;
  getCurrent(): Promise<UserModel | null>;
  getLinkedUsers(userId: string): Promise<UserModel[]>;
  linkUsers(ghostUserId: string, userId: string): Promise<any>;
  register(data: any): Promise<string | null>;
  update(
    userId: string,
    data: ObjectHash,
    fullResponse?: boolean
  ): Promise<UserModel | null>;
  search(keyword: string, onlyAdmins?: boolean): Promise<UserModel[]>;
}

export class UserService extends BaseService implements IUserService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(SearchService)
  private searchService!: ISearchService;

  @inject(WorkspaceService)
  private workspaceService!: IWorkspaceService;

  async create(data: ObjectHash): Promise<UserModel | null> {
    const response = await this.apiService.post(`/users`, data);

    if (!response) {
      return null;
    }

    return new UserModel(response);
  }

  async delete(userId: string): Promise<UserModel | null> {
    const response = await this.apiService.delete(`/users/${userId}`);

    if (!response) {
      return null;
    }

    return new UserModel(response);
  }

  async getById(
    userId: string,
    loadLinkedUsers?: boolean,
    loadTemplateFields?: boolean
  ): Promise<UserModel | null> {
    const response = await this.apiService.get(`/users/${userId}`);

    if (!response) {
      return null;
    }

    let user = new UserModel(response);

    if (loadLinkedUsers) {
      user.linkedUsers = await this.getLinkedUsers(user.id);
    }

    if (loadTemplateFields) {
      user = this.workspaceService.addModelTemplateProps(
        user,
        response,
        "profile"
      ) as UserModel;
    }

    return user;
  }

  async getByIds(userIds: string[]): Promise<UserModel[]> {
    const response = await this.searchService.search("users", {
      fields: [
        "id",
        "jobTitle",
        "firstName",
        "nickname",
        "middleName",
        "lastName",
        "name",
        "email",
        "company"
      ],
      filters: [["id", userIds]],
      limit: userIds.length
    });

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new UserModel(data));
  }

  async getByTags(tagIds: string[]): Promise<UserModel[]> {
    const response = await this.searchService.search("users", {
      fields: [
        "id",
        "jobTitle",
        "firstName",
        "nickname",
        "middleName",
        "lastName",
        "name",
        "email",
        "company"
      ],
      filters: [["tags", [tagIds]]]
    });

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new UserModel(data));
  }

  async getCurrent(): Promise<UserModel | null> {
    const response = await this.apiService.get("/users/me");

    if (!response) {
      return null;
    }

    return new UserModel(response);
  }

  async getLinkedUsers(userId: string): Promise<UserModel[]> {
    const response = await this.searchService.search("users", {
      fields: [
        "jobTitle",
        "firstName",
        "nickname",
        "middleName",
        "lastName",
        "name"
      ],
      filters: [
        ["isGhost", true],
        ["linkedUserId", [userId]]
      ],
      limit: 100,
      resource: "users"
    });

    if (!response) {
      return [];
    }

    return response.map((userData: ObjectHash) => new UserModel(userData));
  }

  async linkUsers(ghostUserId: string, userId: string): Promise<any> {
    return this.apiService.put("/batch/link", { ghostUserId, userId });
  }

  // @todo create type for the data parameter
  async register(data: ObjectHash): Promise<string | null> {
    const response = await this.apiService.post("/auth/register", data);

    if (response) {
      return response.link;
    }

    return "";
  }

  async update(
    userId: string,
    data: ObjectHash,
    fullResponse?: boolean
  ): Promise<UserModel | null> {
    const response = await this.apiService.put(`/users/${userId}`, data);

    if (!response) {
      return null;
    }

    if (fullResponse) {
      return this.getById(userId, true, true);
    }

    return new UserModel(response);
  }

  async search(keyword: string, onlyAdmins?: boolean): Promise<UserModel[]> {
    if (!keyword) {
      return [];
    }

    let filters = [["name", keyword]];

    if (onlyAdmins) {
      filters.push(["role", "admin"]);
    }

    const response = await this.searchService.search("users", {
      fields: [
        "jobTitle",
        "firstName",
        "name",
        "nickname",
        "middleName",
        "lastName",
        "gender",
        "email",
        "company",
        "role"
      ],
      filters,
      order: "name"
    });

    return response.map((data: ObjectHash) => new UserModel(data));
  }
}
