import React, { useState } from "react";
import classnames from "classnames";
import "./tab-header.scss";

export type Tab = {
  id: string;
  isActive?: boolean;
  title: string;
};

export interface Props {
  tabs: Tab[];
  onChange: CallableFunction;
}

export default function TabHeader(props: Props) {
  const { tabs, onChange } = props;
  const [activeTab, setActiveTab] = useState<Tab>(
    tabs.find((tab: Tab) => tab.isActive) || tabs[0]
  );

  const handleChange = (tabId: string) => {
    const tab = tabs.find((tab: Tab) => tab.id === tabId);
    if (!tab) {
      return;
    }
    setActiveTab(tab);
    onChange(tab);
  };

  return (
    <div className="tab-header">
      {tabs.map(({ id, title }) => (
        <div
          key={id}
          onClick={() => handleChange(id)}
          className={classnames("tab-header--tab", {
            active: activeTab.id === id
          })}
        >
          {title}
        </div>
      ))}
    </div>
  );
}
