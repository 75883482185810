import { inject } from "inversify";
import BaseService from "./base.service";
import {
  LocalStorageService,
  ILocalStorageService
} from "./local-storage.service";
import {
  AuthPair,
  IAuthStorageService,
  AuthStorageService
} from "./auth-storage.service";
import { ApiService, IApiService } from "./api.service";
import { IImpersonateService, ImpersonateService } from "./impersonate.service";
import { IndexDbService, IIndexDbService } from "./index-db.service";
import {
  ObjectHash,
  getUrlHashParams,
  makeQueryString,
  getLocalDateISO
} from "../utils/helpers";

export interface IAuthService {
  authenticateFromUrlHash(): Promise<boolean>;

  autoRenewToken(): Promise<boolean>;

  doLogin(email: string, persist: boolean): Promise<any>;

  getAuth(): AuthPair;

  getInviteData(userId: string, token: string): Promise<ObjectHash>;

  isAuthenticated(): boolean;

  logout(sessionExpired?: boolean): Promise<void>;

  renewToken(token?: string): Promise<any>;

  sendInvite(userId: string): Promise<boolean>;

  smartLoginRedirect(): void;
}

export class AuthService extends BaseService implements IAuthService {
  @inject(LocalStorageService)
  private localStorageService!: ILocalStorageService;

  @inject(AuthStorageService)
  private authStorageService!: IAuthStorageService;

  @inject(ApiService)
  private apiService!: IApiService;

  @inject(ImpersonateService)
  private impersonateService!: IImpersonateService;

  @inject(IndexDbService)
  private indexDbService!: IIndexDbService;

  private autoRenewIntervalId!: any;

  getAuth(): AuthPair {
    return this.authStorageService.get();
  }

  isAuthenticated(): boolean {
    return this.getAuth().isValid();
  }

  async authenticateFromUrlHash(): Promise<boolean> {
    const hashParams: any = getUrlHashParams();

    if (!hashParams.hasOwnProperty("authToken")) {
      return false;
    }

    const authToken = hashParams.authToken;

    // update the page hash to remove the auth token value, for clarity and to prevent some sort of auth loop
    delete hashParams.authToken;
    document.location.hash = makeQueryString(hashParams);

    if (authToken) {
      const refreshPair = await this.renewToken(authToken);
      if (!refreshPair) {
        return false;
      }
    }

    return true;
  }

  async doLogin(email: string, persist: boolean): Promise<any> {
    let afterRoute = this.localStorageService.get("auth_redirect_to") || "";
    this.localStorageService.remove("auth_redirect_to");
    afterRoute = `${window.location.origin}${afterRoute}`;

    const data = {
      email,
      persist,
      redirectTo: afterRoute,
      date: getLocalDateISO()
    };

    return this.apiService.post(`/auth/login`, data);
  }

  smartLoginRedirect(): void {
    let afterRoute = window.location.pathname || "";

    this.authStorageService.clear();
    this.localStorageService.set("auth_redirect_to", afterRoute);

    window.location.href = "/login";
  }

  async logout(sessionExpired?: boolean): Promise<void> {
    // @todo move this out
    try {
      await this.indexDbService.clear();
    } catch (err) {
      console.error(err);
    }

    if (this.impersonateService.isActive()) {
      this.impersonateService.deactivate();
      window.location.href = "/admin";
      return;
    }

    // @todo move this out
    const viewState = this.localStorageService.get("viewState");
    this.localStorageService.clear();
    if (viewState) {
      this.localStorageService.set("viewState", viewState);
    }

    let redirectPath = "/login";

    if (sessionExpired) {
      redirectPath = `${redirectPath}?expired`;
    }

    window.location.href = redirectPath;
  }

  async renewToken(token?: string): Promise<AuthPair | null> {
    if (!token && !this.getAuth().token) {
      return null;
    }

    const response = await this.apiService.post(
      token ? `/auth/renew/${token}` : "/auth/renew"
    );

    if (!response) {
      await this.logout(true);
      return null;
    }

    const refreshToken = new AuthPair(response);

    this.authStorageService.set(refreshToken);

    return refreshToken;
  }

  async autoRenewToken(): Promise<boolean> {
    clearInterval(this.autoRenewIntervalId);

    const renewPeriodMs = 15 * (60 * 1000); // 15 mins
    //const renewPeriodMs = 10 * 1000; // testing

    this.autoRenewIntervalId = setInterval(async () => {
      if (!this.getAuth().token) {
        clearInterval(this.autoRenewIntervalId);
        return;
      }

      const refreshPair = await this.renewToken();

      if (!refreshPair) {
        clearInterval(this.autoRenewIntervalId);
      }
    }, renewPeriodMs);

    return true;
  }

  async sendInvite(userId: string): Promise<boolean> {
    const response = await this.apiService.put(`/auth/invites/${userId}`);

    if (!response) {
      return false;
    }

    return true;
  }

  async getInviteData(userId: string, token: string): Promise<ObjectHash> {
    const response = await this.apiService.get(
      `/auth/invites/${userId}/${token}`
    );

    if (!response) {
      return {};
    }

    return response;
  }
}
