import React from "react";
import * as filestack from "filestack-js";
import env from "../../utils/env";
import { ObjectHash } from "../../utils/helpers";

export const afterPick = (cb: any, resp: any) => {
  const urls = resp.filesUploaded.map((f: ObjectHash) => f.url);
  if (typeof cb === "function") {
    cb(urls, resp.filesUploaded);
  }
  return urls;
};

let fsClient: any;

export const openPicker = (opts: ObjectHash, cb: any) => {
  if (!fsClient) {
    fsClient = filestack.init(env.filestack.apiKey);
  }

  return fsClient
    .picker({
      fromSources: [
        "local_file_system",
        "imagesearch",
        "googledrive",
        "facebook",
        "instagram",
        "dropbox"
      ],
      onUploadDone: (resp: any) => {
        afterPick(cb, resp);
      },
      uploadInBackground: false, // must use false with cropper
      ...opts
    })
    .open();
};

function FileUploader(props: ObjectHash) {
  const { trigger, opts, cb } = props;
  return (
    <div className="file-uploader" onClick={() => openPicker(opts, cb)}>
      {trigger}
    </div>
  );
}

export default FileUploader;
