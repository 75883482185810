import React, { MouseEvent } from "react";
import classnames from "classnames";
import MuiButton from "@material-ui/core/Button";

import AppIcon, { AppIconType } from "../app-icon";

import "./button.scss";

export type ButtonColor =
  | "black"
  | "product-background-blue"
  | "light-green"
  | "light-red"
  | "product-blue"
  | "background-gray"
  | "border-gray"
  | "gray"
  | "dark-gray"
  | "green"
  | "red"
  | "yellow";

export type ButtonSizes = "small" | "medium" | "large" | "x-large";

export interface Props {
  alignment?: // only affects standard button with isFullWidth
  "align-left" | "align-center" | "align-right" | "align-space-between";
  color: ButtonColor;
  icon?: AppIconType;
  isBordered?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean; // only affects standard button
  isRippleDisabled?: boolean;
  isRounded?: boolean;
  isTransparent?: boolean;
  label?: string;
  onClick?: CallableFunction;
  size: ButtonSizes;
}

export default function Button(props: Props) {
  let {
    alignment,
    color,
    icon,
    isBordered,
    isDisabled,
    isFullWidth,
    isRippleDisabled,
    isRounded,
    isTransparent,
    label,
    onClick,
    size
  } = props;

  return (
    <MuiButton
      className={classnames(alignment, "button", color, size, {
        disabled: isDisabled,
        fullWidth: isFullWidth,
        rounded: isRounded,
        transparent: isTransparent,
        withBorder: isBordered,
        noLabel: !Boolean(label)
      })}
      disableRipple={isRippleDisabled || false}
      onClick={(event: MouseEvent) => onClick && onClick(event)}
    >
      {icon ? <AppIcon type={icon} /> : null}
      {label ? `${label}` : ""}
    </MuiButton>
  );
}
