import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import TagSelect from "../../tag-select";
import { ObjectHash } from "../../../utils/helpers";
import TagGroupModel from "../../../models/tag-group.model";
import TagModel from "../../../models/tag.model";

const TagFilter = new TravelFilterModel({
  field: TravelFilterFields.Tag,
  name: "Tag",
  group: TravelFilterGroups.Tag,
  resource: TravelFilterResources.Trip,
  component: TagSelect,
  onComponentProps: (filterProps: ObjectHash, meta: ObjectHash) => {
    filterProps.tagGroup = meta.tagGroup ?? new TagGroupModel();
    filterProps.multiSelect = true;
    filterProps.width = 300;
    filterProps.selectedTagIds = filterProps.defaultValue;
    return filterProps;
  },
  onChange: (tags: TagModel[], meta: ObjectHash) => {
    return tags.map((tag: TagModel) => tag.id);
  }
});

export default TagFilter;
