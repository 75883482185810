import React from "react";
import { Link } from "react-router-dom";
import env from "../../../utils/env";

import Tile, { TileProps } from "../tile";
import "./tile.scss";

export default function QuickReportsTile(props: TileProps) {
  const { grid } = props;
  const email = grid.getEmailForAppDomain(env.mailboxDomain);

  if (!email) {
    return null;
  }

  return (
    <Tile title="Reporting">
      <div className="quick-reports-tile">
        <div className="header">Quick Reports</div>
        <div className="description">Downloadable reports in a click.</div>

        <Link to={`/grids/${grid.id}/reports/arrivaldeparture`} target="_blank">
          <div className="report-link-container arrival-departure">
            <div className="link-header">Arrival &amp; Departure</div>
            <div className="link-description">
              View arrival and departures to help coordinate hotels and
              transportation.
            </div>
          </div>
        </Link>

        <Link to={`/grids/${grid.id}/reports/roominglist`} target="_blank">
          <div className="report-link-container">
            <div className="link-header">Rooming List</div>
            <div className="link-description">
              Quickly generate a rooming list to help book room blocks.
            </div>
          </div>
        </Link>
      </div>
    </Tile>
  );
}
