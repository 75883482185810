import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useConditionalTimeout } from "beautiful-react-hooks";

import "./highlight-fade.scss";

interface Props {
  children: JSX.Element | JSX.Element[];
  delayMs: number;
  onStop?: CallableFunction;
  start: boolean;
}

export default function HighlightFade(props: Props) {
  const { delayMs, children, onStop, start } = props;

  const [highlight, setHighlight] = useState<boolean>(false);

  useEffect(() => {
    let subscribed = true;
    const unsubscribe = () => {
      subscribed = false;
    };

    if (!subscribed) {
      return;
    }

    setHighlight(start);

    return unsubscribe;
  }, [start]);

  useConditionalTimeout(
    () => {
      setHighlight(false);
      onStop && onStop();
    },
    delayMs,
    start
  );

  return (
    <div className={classnames("highlight-fade", { highlight })}>
      {children}
    </div>
  );
}
