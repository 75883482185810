import React, { useRef, useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "../../../button";
import CompanySettingsForm from "../forms/company-settings-form";

import UserDialogTitle from "../user-dialog-title";
import { UserDialogTabProps } from "../";
import TagModel from "../../../../models/tag.model";

interface Props extends UserDialogTabProps {
  tagPreset?: TagModel[];
}

export default function CompanySettingsEditTab(props: Props) {
  const { menuPortalTarget, onFormChange, onSave, onViewChange, user } = props;
  const loadState = user.id ? "loaded" : "unloaded";
  const formRef = useRef();

  const [formValid, setFormValid] = useState(false);

  const titleElement = <div>Edit Company Settings</div>;

  return (
    <React.Fragment>
      <UserDialogTitle {...props} hideClose={true} title={titleElement} />
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <div className="user-dialog__content">
          <CompanySettingsForm
            ref={formRef}
            editing={true}
            loadState={loadState}
            menuPortalTarget={menuPortalTarget}
            onFormChange={onFormChange}
            onSave={onSave}
            onValidate={setFormValid}
            user={user}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="gray"
          isTransparent={true}
          label="Cancel"
          onClick={() => onViewChange("profile-view")}
          size="medium"
        />

        <Button
          color="product-blue"
          isDisabled={!formValid}
          label="Save Changes"
          onClick={() => {
            formRef.current && (formRef.current as any).save();
          }}
          size="medium"
        />
      </DialogActions>
    </React.Fragment>
  );
}
