import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import UserModel from "../../../../models/user.model";
import UserProgramRow from "./UserProgramRow";

interface Props {
  loading: boolean;
  onViewChange: CallableFunction;
  user: UserModel;
}

export default function UserProgramSection(props: Props) {
  const { loading, onViewChange, user } = props;
  const { loyaltyPrograms } = user;

  return (
    <div className="user-dialog-section">
      {Object.keys(user.getValidPrograms()).map((programId: string) => (
        <UserProgramRow
          program={loyaltyPrograms[programId]}
          loading={loading}
          key={programId}
          onViewChange={onViewChange}
        />
      ))}
      {Object.keys(user.getValidPrograms()).length === 0 &&
        (loading ? (
          <Skeleton animation="wave" height={80} variant="rect" />
        ) : (
          <div className="user-dialog-section--empty-state">
            You have not added any loyalty programs.
          </div>
        ))}
    </div>
  );
}
