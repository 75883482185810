import React from "react";
import AutocompleteInputFilled from "./AutocompleteInputFilled";
import {
  getSelectValueFromOptions,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";
import { LoyaltyPrograms } from "../../../models/loyalty-program.model";
import "./inputs.scss";

interface Props {
  defaultValue?: any[];
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  value?: any;
}

const opts: SelectOption[] = LoyaltyPrograms.map((program: ObjectHash) => ({
  label: program.name,
  value: program.name
}));

export default function LoyaltyProgramInput(props: Props) {
  const valueProps = getSelectValueFromOptions(props, opts);
  const acProps = { ...props, ...valueProps };
  return (
    <AutocompleteInputFilled {...acProps} isSearchable={true} options={opts} />
  );
}
