import { cloneDeep } from "lodash";

import AppContainer from "../../../container";
import { SearchDbService } from "../../../services/search-db.service";
import TagGroupModel from "../../../models/tag-group.model";
import { WorkspaceService } from "../../../services/workspace.service";

const getInitialState = (
  props: { [index: string]: any },
  setState: CallableFunction,
  ref: any
) => {
  const searchDbService = AppContainer.get(SearchDbService);
  const workspaceService = AppContainer.get(WorkspaceService);

  const {
    blockState,
    columns,
    renderOpts,
    staticResults,
    searchOpts,
    templateName,
    templates,
    settings
  } = props;

  const { resource } = searchOpts;

  const transitionTemplateHash: { [index: string]: string } = {
    activity: "activity",
    air: "flight",
    car: "transportation",
    hotel: "accommodation",
    rail: "rail"
  };

  const state: { [index: string]: any } = {};

  state.id = Math.random().toString().slice(2);

  state.selectedRows = [];

  state.sortBy = { colIndex: null, direction: null };

  state.results = Array.isArray(staticResults) ? staticResults : [];

  // @todo what is this filtering out exactly?
  state.columns = (columns || []).filter(
    (column: any[]) => Array.isArray(column) && column.length === 2
  );

  if (templateName) {
    // Fetch template cols and title
    const templateCols = [["id", "ID"]];

    const template = templates.find(
      (t: { [index: string]: any }) =>
        t.name ===
        (resource === "trips"
          ? transitionTemplateHash[templateName]
          : templateName)
    );

    if (template) {
      const modTemplate = cloneDeep(template);

      // Inject routing column using other col positions in template
      if (["air"].includes(templateName)) {
        let anchorPropIndex = modTemplate.properties
          .map((p: { [index: string]: any }) => p.name)
          .indexOf("name");
        const propToAdd = {
          friendlyName: "Routing",
          name: "routing"
        };
        // put before from column when airline is disabled
        if (anchorPropIndex === modTemplate.properties.length - 1) {
          anchorPropIndex = modTemplate.properties
            .map((p: { [index: string]: any }) => p.name)
            .indexOf("from");
          modTemplate.properties.splice(anchorPropIndex, 0, propToAdd);
        } else {
          modTemplate.properties.splice(anchorPropIndex + 1, 0, propToAdd);
        }
      }

      const enabledProps = modTemplate.properties.filter(
        (prop: { [index: string]: any }) =>
          !prop.isHidden &&
          (!renderOpts.isDocument ? prop.name !== "flightNum" : true)
      );

      // profile table sorts by protected fields, then custom fields ordered by position
      if (templateName === "profile") {
        workspaceService.sortProfileProps(enabledProps);
      }

      enabledProps.forEach((prop: { [index: string]: any }) =>
        templateCols.push([prop.name, prop.friendlyName])
      );

      state.template = modTemplate;
    }

    state.columns = templateCols;
  }

  if (renderOpts.showTagCol && settings.tagGroups) {
    settings.tagGroups.forEach((tagGroup: TagGroupModel) => {
      if (!tagGroup.hidden) {
        state.columns.unshift([tagGroup.getFieldId(), tagGroup.name]);
      }
    });
  }

  if (renderOpts.isDocument) {
    // Add row menu column for document tables
    if (templateName) {
      state.columns.push(["documentRowMenu", ""]);
    }

    // State for setting custom table color
    state.onChangeTableBlockColor = blockState.onChangeTableBlockColor;

    // State for setting custom table title
    state.titleInputWidth = 0;
    state.onChangeTableBlockTitle = blockState.onChangeTableBlockTitle;

    // State for setting columns and column order
    state.onChangeTableBlockCols = blockState.onChangeTableBlockCols;
    state.onChangeTableBlockColOrder = blockState.onChangeTableBlockColOrder;
    if (props.columnsDefault) {
      state.columns = props.columnsDefault; // Provides full column list for non-template tables in Documents
    }
    state.documentVisibleCols = columns.length
      ? columns.map((c: any[]) => c[0])
      : state.columns.map((c: any[]) => c[0]);

    // State for tracking in table filter
    state.inTableFilters = blockState.block.options.filters;
    state.onChangeTableBlockFilter = blockState.onChangeTableBlockFilter;

    // State for tracking future only in document travel table
    state.onChangeTableBlockFutureOnly =
      blockState.onChangeTableBlockFutureOnly;

    // State for tracking in table sorter
    state.inTableSorters = blockState.block.options.sorters;
    state.onChangeTableBlockSort = blockState.onChangeTableBlockSort;

    // State for tracking hidden trips in document table
    state.onChangeTableBlockHiddenTrips =
      blockState.onChangeTableBlockHiddenTrips;

    // State for tracking users in document profile table
    state.onChangeTableBlockUsers = blockState.onChangeTableBlockUsers;

    // State for setting custom data order in document table
    state.onResetCustomDataOrder = blockState.onResetCustomDataOrder;
    state.onChangeTableBlockDataOrder = blockState.onChangeTableBlockDataOrder;
  }

  state.searchOpts = searchOpts || {};

  if (renderOpts && renderOpts.disablePagination === true && !staticResults) {
    state.searchOpts.limit = 200; // limit is hardcoded when pagination disabled
  }

  state.searchOpts.fields = (state.searchOpts.fields || []).concat(
    state.columns.map((c: any[]) => c[0])
  );

  if (staticResults) {
    return state;
  }

  state.isLoading = true;
  state.isSoftLoading = true;

  state.searchSub = searchDbService.subscribe(
    state.searchOpts,
    (results: any[]) => {
      const stateUpdate: { [index: string]: any } = { isLoading: false };
      const prevIds =
        ref && ref.state && Array.isArray(ref.state.results)
          ? ref.state.results.map(
              (r: { [index: string]: any }) => r._id || r.id
            )
          : [];

      const hasNew =
        Array.isArray(results) &&
        results.find((r) => !prevIds.includes(r._id || r.id));

      if (
        !hasNew ||
        !(
          ref &&
          ref.state &&
          ref.state.selectedRows &&
          ref.state.selectedRows.length
        )
      ) {
        stateUpdate.results = results;
      }

      if (
        hasNew &&
        (!(
          ref &&
          ref.state &&
          ref.state.selectedRows &&
          ref.state.selectedRows.length
        ) ||
          document.querySelector("[class*=global-filter-popper--open]"))
      ) {
        stateUpdate.selectedRows = [];
      }

      if (ref._isMounted) {
        setState({ ...stateUpdate });
      }
    },
    (searchMeta: { [index: string]: any }) => {
      if (searchMeta || !(ref && ref.state && ref.state.isSoftLoading)) {
        if (ref._isMounted) {
          setState({ searchMeta, isSoftLoading: !searchMeta });
        }
      }
    }
  );

  return state;
};

export default getInitialState;
