import BaseModel from "./base.model";
import TagModel from "./tag.model";
import { ObjectHash } from "../utils/helpers";
import LoyaltyProgramModel from "./loyalty-program.model";
import {
  UserDocument,
  UserDocumentIdCardModel,
  UserDocumentPassportModel,
  UserDocumentTsaModel
} from "./user-document.model";

export class RoleModel {
  id: string;
  name: string;

  constructor(data?: ObjectHash) {
    if (!data) {
      data = {};
    }
    this.id = data.id;
    this.name = data.name;
  }
}

export const UserRoles: RoleModel[] = [
  new RoleModel({ name: "Traveler", id: "contact" }),
  // new RoleModel({ name: "Guest Traveler", id: "guest" }),
  new RoleModel({ name: "Admin", id: "admin" })
];

export default class UserModel extends BaseModel {
  id: string;
  migratedId?: number; // users created before mongoDB migration, needed for Intercom integrations
  activated: boolean;
  blocked: boolean;
  companyMigratedId?: string;
  deleted: boolean;
  primary: boolean;
  virtual: boolean;
  employee: boolean;
  superuser: boolean;
  isSuperuser: boolean;
  isGhost: boolean;

  dateOfBirth: string;
  email: string;
  jobTitle: string;
  firstName: string;
  nickname: string;
  middleName: string;
  lastName: string;
  name: string;
  fullName: string;
  gender: string;

  intercomToken: string;

  company: string;
  companyId: string;
  companyName: string;

  tags: TagModel[];
  data?: ObjectHash;

  role: string;
  roles: any;

  documents: { [index: string]: UserDocument };
  loyaltyPrograms: { [index: string]: LoyaltyProgramModel };

  linkedUsers?: UserModel[];

  searchPreset?: string;
  travelTablePreset?: string;

  createdAt: string;
  updatedAt: string;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    if (data.migratedId) {
      this.migratedId = data.migratedId;
    }

    this.activated = data.activated;
    this.blocked = Boolean(data.blocked);
    this.dateOfBirth = data.dateOfBirth;
    this.deleted = data.deleted;
    this.primary = data.primary;
    this.virtual = data.virtual;
    this.employee = data.employee;
    this.superuser = data.superuser;
    this.isSuperuser = data.isSuperuser;
    this.isGhost = data.isGhost;

    this.email = data.email;
    this.jobTitle = data.jobTitle;
    this.firstName = data.firstName;
    this.nickname = data.nickname;
    this.middleName = data.middleName;
    this.lastName = data.lastName;
    this.name = data.name;
    this.fullName = data.fullName;
    this.gender = data.gender;

    this.intercomToken = data.intercomToken;

    this.company = data.company;
    this.companyId = data.companyId;
    this.companyName = data.companyName;

    if (data.searchPreset) {
      this.searchPreset = data.searchPreset;
    }

    if (data.travelTablePreset) {
      this.travelTablePreset = data.travelTablePreset;
    }

    if (data.companyMigratedId) {
      this.companyMigratedId = data.companyMigratedId;
    }

    this.loyaltyPrograms = {};
    if (data.loyaltyPrograms) {
      Object.keys(data.loyaltyPrograms).forEach((key: string) => {
        const { accountId } = data?.loyaltyPrograms[key];

        this.loyaltyPrograms[key] = new LoyaltyProgramModel({
          company: key,
          accountId
        });
      });
    }

    this.tags = [];
    if (data.tags) {
      this.tags = data.tags.map((tag: any) => new TagModel(tag));
    }

    if (data.data) {
      this.data = data.data;
    }

    this.documents = data.documents || {};
    if (this.documents.passport) {
      this.documents.passport = new UserDocumentPassportModel(
        this.documents.passport
      );
    }
    if (this.documents.tsa) {
      this.documents.tsa = new UserDocumentTsaModel(this.documents.tsa);
    }
    if (this.documents.idCard) {
      this.documents.idCard = new UserDocumentIdCardModel(
        this.documents.idCard
      );
    }

    this.role = data.role;
    this.roles = data.roles;

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getValidDocuments(): { [index: string]: UserDocument } {
    const validDocs: { [index: string]: UserDocument } = {};

    Object.keys(this.documents).forEach((key: string) => {
      const doc = this.documents[key];
      if (doc.getId()) {
        validDocs[key] = doc;
      }
    });

    return validDocs;
  }

  getValidPrograms(): { [index: string]: LoyaltyProgramModel } {
    const validPrograms: { [index: string]: LoyaltyProgramModel } = {};

    Object.keys(this.loyaltyPrograms).forEach((key: string) => {
      const program = this.loyaltyPrograms[key];
      if (program.getId()) {
        validPrograms[key] = program;
      }
    });

    return validPrograms;
  }

  getFullName(): string {
    const nameFields = [
      this.firstName,
      this.nickname ? `"${this.nickname}"` : "",
      this.middleName,
      this.lastName
    ].filter((v) => v);

    if (!nameFields.length) {
      return this.fullName || this.name;
    }

    return nameFields.join(" ");
  }

  getInitials(): string {
    return [this.firstName, this.middleName, this.lastName]
      .filter((v) => v)
      .map((v) => v[0])
      .join("")
      .toUpperCase();
  }

  isAdmin(): boolean {
    return this.role === "admin";
  }

  // isEmployee checks if a given user has been explicitly marked as an employee of Tripgrid
  isEmployee(): boolean {
    return this.employee === true;
  }

  // isSuperAdmin checks if a given user has been explicitly marked as a superuser within their tenant
  isSuperAdmin(): boolean {
    return typeof this.superuser !== "undefined"
      ? this.superuser
      : this.isSuperuser;
  }
}
