import React from "react";
import { resolve } from "inversify-react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Button from "../../components/button";
import ArrivalDeparture from "../../components/quick-reports/ArrivalDeparture";
import RoomingList from "../../components/quick-reports/RoomingList";

import { AppPageProps } from "../../router";
import { exportTablesToCSV } from "../../utils/csv";
import { GridService, IGridService } from "../../services/grid.service";
import GridModel from "../../models/grid.model";

import TripgridLetterMark from "../../images/tripgrid-letter-mark.svg";
import "./grid-reports-page.scss";

const reportsHash: any = {
  arrivaldeparture: {
    component: ArrivalDeparture,
    name: "Arrival & Departure",
    fileName: "ArrivalDeparture.csv"
  },
  roominglist: {
    component: RoomingList,
    name: "Rooming List",
    fileName: "RoomingList.csv"
  }
};

interface Props extends AppPageProps, RouteComponentProps {}

interface State {
  grid: GridModel | null;
}

class GridReportsPage extends React.Component<Props, State> {
  @resolve(GridService)
  private gridService!: IGridService;

  constructor(props: Props) {
    super(props);

    this.state = {
      grid: null
    };
  }

  async componentDidMount() {
    const gridId = (this.props.match.params as any).id;
    const grid = await this.gridService.getById(gridId);

    if (grid) {
      this.setState({ grid });
    }
  }

  render() {
    const { id, reportType } = this.props.match.params as any;
    const { grid } = this.state;
    const report: any = reportsHash[reportType];
    const ReportComponent = report.component;

    return (
      <div id="grid-reports-page">
        <div className="reports-page-wrapper">
          <div className="reports-page-header">
            <div className="reports-page-header__branding">
              <img
                alt="tripgrid-logo"
                src="https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg"
              />
              <img alt="tripgrid-letter-mark.svg" src={TripgridLetterMark} />
            </div>
            <Button
              color="product-blue"
              label="Export to CSV"
              onClick={() => {
                exportTablesToCSV(`${report.fileName}`);
              }}
              size="medium"
            />
          </div>
          {grid && (
            <div className="reports-page-content">
              <h1>{report.name}</h1>
              <h2>{grid.name}</h2>
              <ReportComponent gridId={id} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(GridReportsPage);
