import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import DateInput from "../../form/inputs/DateInput";

const TravelDateOnFilter = new TravelFilterModel({
  field: TravelFilterFields.TravelDateOn,
  name: "On",
  group: TravelFilterGroups.TravelDate,
  resource: TravelFilterResources.Trip,
  component: DateInput
});

export default TravelDateOnFilter;
