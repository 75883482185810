class ApiConfig {
  apiKey: string;

  constructor(o: any) {
    this.apiKey = o.apiKey;
  }
}

class AppEnvironment {
  assetBase: string;
  api: string;
  assetUrl: string;
  datadog: {
    applicationId: string;
    clientToken: string;
    env: string;
    site: string;
    service: string;
    version: string;
  };
  filestack: ApiConfig;
  google: ApiConfig;
  mailboxDomain: string;
  pusher: ApiConfig;
  segment: ApiConfig;
  disableParseEmailPull: boolean;

  constructor(o: any) {
    this.assetBase = o.assetBase;
    this.api = o.api;
    this.assetUrl = o.assetUrl;
    this.datadog = o.datadog;
    this.filestack = o.filestack;
    this.google = o.google;
    this.mailboxDomain = o.mailboxDomain;
    this.pusher = o.pusher;
    this.segment = o.segment;
    this.disableParseEmailPull = Boolean(o.disableParseEmailPull);
  }
}

const productionEnvironment = new AppEnvironment({
  assetBase: "https://s3-us-west-2.amazonaws.com/tripgrid-assets",
  api: "https://node-api.tripgrid.com/api",
  assetUrl: "https://s3-us-west-2.amazonaws.com/tripgrid-assets",
  datadog: {
    applicationId: "8a99d2a7-1179-49de-9c07-430c719ed53e",
    clientToken: "pub5db96c03137ff4e27c4bf8d98c057dda",
    env: "production",
    site: "datadoghq.com",
    service: "Tripgrid Production",
    version: "3.1.5"
  },
  filestack: new ApiConfig({
    apiKey: "Aj3ftg4VSE6X1ZSXJmemgz"
  }),
  google: new ApiConfig({
    apiKey: "AIzaSyDdi6phDlzIm1pcqGrJBQ3FwVFleIEsOoY"
  }),
  mailboxDomain: "inbox.tripgrid.com",
  pusher: new ApiConfig({
    apiKey: "01add0e68c10bf69716b"
  }),
  segment: new ApiConfig({
    apiKey: "zsrHJSZFJERQrG5nBbO2CLE984Y7RWC9"
  })
});

const stagingEnvironment = new AppEnvironment({
  assetBase: "https://s3-us-west-2.amazonaws.com/tripgrid-assets",
  api: "https://node-api-frontier.tripgrid.com/api",
  assetUrl: "https://s3-us-west-2.amazonaws.com/tripgrid-assets",
  datadog: {
    applicationId: "8a99d2a7-1179-49de-9c07-430c719ed53e",
    clientToken: "pub5db96c03137ff4e27c4bf8d98c057dda",
    env: "staging",
    site: "datadoghq.com",
    service: "Tripgrid Staging",
    version: "3.1.5"
  },
  filestack: new ApiConfig({
    apiKey: "Aj3ftg4VSE6X1ZSXJmemgz"
  }),
  google: new ApiConfig({
    apiKey: "AIzaSyCkWN8pFjOIf9v-Ry9Zw0wHuMedAa4DMts"
  }),
  mailboxDomain: "inbox.frontier.tripgrid.com",
  pusher: new ApiConfig({
    apiKey: "ec32e5248877d1da639f"
  })
});

const localEnvironment = new AppEnvironment({
  assetBase: "https://s3-us-west-2.amazonaws.com/tripgrid-assets",
  api: process.env.REACT_APP_TG_API_URL
    ? process.env.REACT_APP_TG_API_URL
    : "http://localhost:3001/api",
  assetUrl: "https://s3-us-west-2.amazonaws.com/tripgrid-assets",
  filestack: new ApiConfig({
    apiKey: "Aj3ftg4VSE6X1ZSXJmemgz"
  }),
  google: new ApiConfig({
    apiKey: "AIzaSyCkWN8pFjOIf9v-Ry9Zw0wHuMedAa4DMts"
  }),
  mailboxDomain: "local",
  pusher: new ApiConfig({
    apiKey: "ec32e5248877d1da639f"
  }),
  disableParseEmailPull: true
});

const appEnvironments: Map<string, AppEnvironment> = new Map();
appEnvironments.set("production", productionEnvironment);
appEnvironments.set("staging", stagingEnvironment);
appEnvironments.set("local", localEnvironment);

const getAppEnvironment = (): AppEnvironment => {
  let environmentKey: string = process.env.REACT_APP_BUILD_ENV || "staging";

  // force production config when the hostname suggests we are on the live site
  if (window && /app/.test(window.location.host)) {
    environmentKey = "production";
  }

  if (!appEnvironments.has(environmentKey)) {
    // @todo massive problem, need to abort bootstrap rather than defaulting to local
    environmentKey = "local";
  }

  console.log(`app environment: ${environmentKey}`);

  const appEnvironment = appEnvironments.get(environmentKey);

  /*
   * It's actually not possible for the below conditional to evaluate to false because of the
   * above Map.has check, but it's required for ts null return value checking
   */
  if (!appEnvironment) {
    return productionEnvironment;
  }

  return appEnvironment;
};

// @todo move this to application bootstrap
const appEnvironment = getAppEnvironment();

export default appEnvironment;
