import React from "react";
import { useHistory } from "react-router-dom";
import { AppPageProps } from "../../router";
import Checkbox from "@material-ui/core/Checkbox";

import Loader from "../../components/loader";

import AppContainer from "../../container";
import {
  IntegrationService,
  IIntegrationService
} from "../../services/integration.service";

import TripgridLetterMark from "../../images/tripgrid-letter-mark.svg";
import "./concur-page.scss";

export default function ConcurPage(props: AppPageProps) {
  const integrationService: IIntegrationService = AppContainer.get(
    IntegrationService
  );
  const history = useHistory();

  const [code, setCode] = React.useState("");
  const [policyConsent, setPolicyConsent] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const verify = async (verificationCode: string) => {
    const payload = {
      ...window.location.search
        .slice(1)
        .split("&")
        .map((p) => p.split("="))
        .reduce((a, b) => ({ ...a, [b[0]]: b[1] }), {}),
      verificationCode
    };

    try {
      await integrationService.verifyConcurConnect(verificationCode, payload);

      setValid(true);
    } catch (e) {
      // ignore this
    }

    setSubmitted(true);

    try {
      window.close();
    } catch (e) {
      // ignore this
    }

    setLoading(false);
  };

  return (
    <div className="concur-page">
      <div className="concur-page-header">
        <div className="concur-page-header__branding">
          <img
            alt="tripgrid-logo"
            src="https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg"
          />
          <img alt="tripgrid-letter-mark.svg" src={TripgridLetterMark} />
        </div>
      </div>
      <div className="concur-page__form-wrapper">
        <h2>Connect to SAP Concur</h2>
        <form
          className="verification-form"
          onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);
            verify(code);
          }}
        >
          <div className="form-input">
            <input
              disabled={loading}
              onChange={(e) => {
                setCode(e.target.value);
              }}
              placeholder="Enter verification code"
              required
            />
          </div>
          <div className="checkbox-wrapper">
            <Checkbox
              checked={policyConsent}
              color="primary"
              disabled={valid ? true : false}
              onChange={() => setPolicyConsent(!policyConsent)}
            />
            <p>
              {`I agree to the Tripgrid `}
              <a
                href="https://www.tripgrid.com/legal/terms"
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms of Service
              </a>
              {` and `}
              <a
                href="https://www.tripgrid.com/legal/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          {loading ? (
            <div className="status">
              <span>Establishing connection</span>
              <Loader type="spinner" />
            </div>
          ) : null}
          {submitted && !valid && (
            <div className="status status--error">
              <span>Connection failed</span>
            </div>
          )}
          {submitted && valid && (
            <div className="status status--success">
              <span>Connection successful</span>
            </div>
          )}
          {!valid && !loading && (
            <button
              className={policyConsent ? "" : "disabled"}
              disabled={!policyConsent}
              type="submit"
            >
              Connect
            </button>
          )}
          {submitted && valid && (
            <button
              onClick={() => {
                history.push("/");
              }}
            >
              Open Tripgrid
            </button>
          )}
        </form>
      </div>
      <div style={{ height: "64px" }} />
    </div>
  );
}
