import React from "react";
import Button from "../../../button";

interface Props {
  loading: boolean;
  docType: string;
  onViewChange: CallableFunction;
}

export default function UserDocumentRow(props: Props) {
  const { docType, onViewChange } = props;

  let title = "";

  switch (docType) {
    default:
    case "passport":
      title = "Passport";
      break;
    case "idCard":
      title = "ID Card";
      break;
    case "tsa":
      title = "TSA";
      break;
  }

  return (
    <div className="user-dialog-section--row">
      <div className="title">{title}</div>
      <div className="button">
        <Button
          color="product-background-blue"
          label="View"
          onClick={() => {
            onViewChange("profile-document", { docType });
          }}
          size="small"
        />
      </div>
    </div>
  );
}
