import BaseModel from "./base.model";
import { getDisplayDate, ObjectHash } from "../utils/helpers";

export default class SegmentModel extends BaseModel {
  id: string;
  cancelled: boolean;
  deleted: boolean;
  company: string;
  companyId: string;
  data: ObjectHash;
  duration: string;
  flightNum: string; // rail uses this for the train number
  from: string;
  fromLocation: string;
  fromTimezone: string;
  name: string;
  tickets: ObjectHash;
  to: string;
  toLocation: string;
  toTimezone: string;
  travelerData: ObjectHash;
  trip: string;
  tripId: string;
  updatesByKey: ObjectHash;

  createdAt: string;
  updatedAt: string;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.cancelled = data.cancelled;
    this.deleted = data.deleted;

    this.company = data.company;
    this.companyId = data.companyId;
    this.data = data.data || {};
    this.duration = data.duration;
    this.flightNum = data.flightNum;
    this.from = data.from;
    this.fromLocation = data.fromLocation;
    this.fromTimezone = data.fromTimezone;
    this.name = data.name;
    this.tickets = data.tickets;
    this.to = data.to;
    this.toLocation = data.toLocation;
    this.toTimezone = data.toTimezone;
    this.travelerData = data.travelerData;
    this.trip = data.trip;
    this.tripId = data.tripId;
    this.updatesByKey = data.updatesByKey;

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getLegDate(): string {
    return getDisplayDate(this.from, "yyyy-MM-dd");
  }
}
