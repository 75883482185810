import React, { useRef, useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import AppContainer from "../../../../container";
import Button from "../../../button";
import { IUserService, UserService } from "../../../../services/user.service";
import ProfileDocumentForm from "../forms/profile-document-form";
import { Tab } from "../../../tab-header";
import UserDialogTitle from "../user-dialog-title";
import { UserDialogTabProps } from "../";
import { UserDocumentType } from "../../../../models/user-document.model";
import useModal from "../../../../hooks/use-modal.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";

interface Props extends UserDialogTabProps {
  docType?: UserDocumentType;
}

export default function ProfileDocumentTab(props: Props) {
  const { docType, onSave, onViewChange, user } = props;
  const userService: IUserService = AppContainer.get(UserService);
  const { openModal } = useModal();
  const { setSnackbar } = useSnackbar();
  const [isNew, setIsNew] = useState<boolean>(!Boolean(docType));

  const [formValid, setFormValid] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);

  const formRef = useRef();

  let titleText = "Edit";

  if (isNew) {
    titleText = "Add";
  } else if (readOnly) {
    titleText = "View";
  }

  const titleElement = <div>{titleText} Document</div>;

  const handleDelete = () => {
    if (!user.documents[docType as string]) {
      return;
    }

    openModal("confirm", {
      dialogTitle: `Delete Document`,
      dialogBody: `Are you sure you want to delete this document? This cannot be undone.`,
      onConfirm: async () => {
        const userDocs = { ...user.documents, [docType as string]: {} };

        const updatedUser = await userService.update(user.id, {
          documents: userDocs
        });

        if (updatedUser) {
          onSave && onSave(updatedUser);
          onViewChange("profile-view");
          setSnackbar({
            message: `Document deleted!`,
            variant: "success"
          });
        }
      }
    });
  };

  return (
    <React.Fragment>
      <UserDialogTitle
        {...props}
        deleteTooltip="Delete Document"
        hideClose={true}
        onDelete={isNew ? undefined : handleDelete} // hide delete if creating new doc
        title={titleElement}
      />
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <div className="user-dialog__content">
          <div>
            {
              <ProfileDocumentForm
                {...props}
                onReadOnly={() => setReadOnly(false)}
                onSave={onSave}
                onTabChange={(tab: Tab) => {
                  setIsNew(!Boolean(user.getValidDocuments()[tab.id as any]));
                }}
                onValidate={setFormValid}
                readOnly={!isNew ? readOnly : false}
                ref={formRef}
              />
            }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="gray"
          isTransparent={true}
          label={readOnly ? "Back" : "Cancel"}
          onClick={() => onViewChange("profile-view")}
          size="medium"
        />

        {(!readOnly || isNew) && (
          <Button
            color="product-blue"
            isDisabled={!formValid}
            label="Save Document"
            onClick={() => {
              formRef.current && (formRef.current as any).save();
            }}
            size="medium"
          />
        )}
      </DialogActions>
    </React.Fragment>
  );
}
