import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AppIcon from "../../../../components/app-icon";
import FileUploader from "../../../../components/file-uploader";
import Tooltip from "../../../../components/tooltip";
import "./attachment-block.scss";

const opts = {
  accept: ["audio/*", "application/*", "image/*", "text/*"],
  fromSources: ["local_file_system"],
  maxFiles: 1,
  imageMax: [500, 500]
};

interface Props {
  src: string;
  isViewMode: boolean;
  filename: string;
  onUpload: CallableFunction;
}

export default function AttachmentBlock(props: Props) {
  const trigger = (
    <div className="attachment-block__upload-trigger">
      <Tooltip
        text={
          props.src.length && !props.isViewMode
            ? "Change attachment"
            : "Upload attachment"
        }
        placement="left"
      >
        {props.src.length ? (
          <IconButton className="attachment-block__download-link">
            <AppIcon type="file" />
            <span className="attachment-block__download-name">
              {props.filename}
            </span>
          </IconButton>
        ) : (
          <IconButton>
            <AppIcon type="attachment" size="large" />
          </IconButton>
        )}
      </Tooltip>
    </div>
  );

  return (
    <div className="attachment-block">
      {props.src.length && props.isViewMode ? (
        <Tooltip text="Download attachment" placement="left">
          <a href={props.src} rel="noopener noreferrer" target="_blank">
            <IconButton className="attachment-block__download-link">
              <AppIcon type="file" />
              <span className="attachment-block__download-name">
                {props.filename}
              </span>
            </IconButton>
          </a>
        </Tooltip>
      ) : (
        <FileUploader
          trigger={trigger}
          opts={opts}
          cb={([url]: any, [uploadedFile]: any) =>
            props.onUpload(url, uploadedFile)
          }
        />
      )}
    </div>
  );
}
