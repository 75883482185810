import React, { MouseEvent, useCallback } from "react";

import AppContainer from "../../../container";
import AppIcon from "../../../components/app-icon";
import Tooltip from "../../../components/tooltip";
import BookingRequestModel, {
  BookingStatus
} from "../../../models/booking-request.model";
import BookingRequestStatusInput from "../../../components/form/inputs/BookingRequestStatusInput";
import {
  ICollectionService,
  CollectionService
} from "../../../services/collection.service";
import { Columns } from "./stack";
import { getDisplayDate, ObjectHash } from "../../../utils/helpers";
import SegmentChipList from "../../../components/segment-chip-list";
import {
  getStaticData,
  StackFilter,
  StackSort
} from "../../../components/stack/helpers";

import Stack from "../../../components/stack";
import useApp from "../../../hooks/use-app.hook";
import UserModel from "../../../models/user.model";
import UserProfileIcon from "../../../components/user-profile-icon";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";

import "./collection-requests.scss";

interface Props {
  emptyMessage?: string;
  filters: StackFilter[];
  onChange: CallableFunction;
  requests: BookingRequestModel[];
  sort: StackSort;
  title: string;
}

export default function CollectionRequestStack(props: Props) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );
  const { openModal } = useModal();
  const { settings } = useApp();
  const { setSnackbar } = useSnackbar();
  const { emptyMessage, filters, onChange, requests, sort, title } = props;

  const handleStatusUpdate = useCallback(
    async (index: number, value: BookingStatus) => {
      const updatedRequests = [...requests];
      updatedRequests[index].status = value;

      const updatedRequest = await collectionService.updateRequest(
        updatedRequests[index].id,
        { status: value }
      );

      if (!updatedRequest) {
        setSnackbar({
          message: "There was a problem and the traveler was not updated",
          variant: "error"
        });
        return;
      }

      setSnackbar({
        message: "Traveler updated!",
        variant: "success"
      });

      onChange();
    },
    [collectionService, onChange, requests, setSnackbar]
  );

  const getStackRows = useCallback(
    (requests: BookingRequestModel[]): ObjectHash[] => {
      return requests.map((request: BookingRequestModel, index: number) => {
        const { id, notes, users, status } = request;

        const travelerElements: JSX.Element[] = users.map((user: UserModel) => {
          return (
            <div className="request-traveler" key={`traveler-cell-${user.id}`}>
              <div className="icon">
                <UserProfileIcon user={user} />
              </div>
              <div className="name circular-bold">{user.getFullName()}</div>
            </div>
          );
        });

        const bookingDates = request
          .getDates()
          .map((date: string) => getDisplayDate(date, settings.dateFormat))
          .join(" to ");

        return {
          id,
          traveler: travelerElements,
          bookingDates: <div className="booking-dates">{bookingDates}</div>,
          segmentList: <SegmentChipList request={request} />,
          notes: notes ? (
            <Tooltip text={notes}>
              <AppIcon type="text-snippet" color="gray" />
            </Tooltip>
          ) : null,
          status: (
            <div
              className="booking-status"
              onClick={(e: MouseEvent) => e.stopPropagation()}
            >
              <BookingRequestStatusInput
                key={`booking-request-status-input-${id}-${status}`}
                menuPortalTarget={document.body}
                onChange={(value: BookingStatus) =>
                  handleStatusUpdate(index, value)
                }
                value={status}
              />
            </div>
          )
        };
      });
    },
    [handleStatusUpdate, settings]
  );

  const handleData = useCallback(
    async (page: number, limit: number, sort: StackSort) => {
      const pagedResponse = await getStaticData(requests, page, limit, sort);

      pagedResponse.data = getStackRows(
        pagedResponse.data as BookingRequestModel[]
      );

      return pagedResponse;
    },
    [getStackRows, requests]
  );

  return (
    <div id="collection-requests">
      <Stack
        onData={handleData}
        emptyMessage={emptyMessage}
        filters={filters}
        columns={Columns}
        onRowClick={(rowData: ObjectHash) => {
          openModal("booking-request", {
            requestId: rowData.id,
            onChange
          });
        }}
        sort={sort}
        title={title}
      />
    </div>
  );
}
