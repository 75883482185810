import AirportSelect from "../../airport-select";
import AirportModel from "../../../models/airport.model";
import { ObjectHash } from "../../../utils/helpers";
import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

const AirportDepartFilter = new TravelFilterModel({
  field: TravelFilterFields.AirportDepart,
  name: "Departure Airport",
  group: TravelFilterGroups.Flight,
  resource: TravelFilterResources.Trip,
  component: AirportSelect,
  onComponentProps: function (filterProps: ObjectHash) {
    let { value, meta } = this;

    filterProps.selectedAirports = [];

    if (value?.length && meta?.airports) {
      // support legacy values from AirportAsyncInput
      if (!Array.isArray(value)) {
        value = [value];
      }

      value.forEach((airportName: string) => {
        const airport = meta.airports.get(airportName);
        if (!airport) {
          return;
        }
        filterProps.selectedAirports.push(airport);
      });
    }

    filterProps.multiSelect = true;
    filterProps.width = 300;

    return filterProps;
  },
  onChange: function (airports: AirportModel[]) {
    if (!this.meta.airports) {
      this.meta.airports = new Map();
    }

    return airports.map((airport: AirportModel) => {
      this.meta.airports.set(airport.getFullName(), airport);
      return airport.getFullName();
    });
  }
});

export default AirportDepartFilter;
