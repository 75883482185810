import React, { cloneElement } from "react";
import { isModalConfig, Modal, ModalContext } from "./";

/*
 * The addition of the openModal and closeModal props below is only being used for
 * the few modals which are defined as class components and not easily converted to
 * function components. All other modal components use the useModal hook to access
 * the ModalContext.Provider value in the expected way.
 */
export default function ModalRoot() {
  return (
    <ModalContext.Consumer>
      {({ modalStack, openModal, closeModal }) =>
        modalStack.map((modal: Modal) => {
          if (isModalConfig(modal)) {
            const { Component, key, props } = modal;
            return (
              <Component
                key={key}
                openModal={openModal}
                closeModal={closeModal}
                {...props}
              />
            );
          }

          const { element, key } = modal;

          return cloneElement(element, { key });
        })
      }
    </ModalContext.Consumer>
  );
}
