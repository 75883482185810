import React from "react";
import { DateTime } from "luxon";

import AutocompleteInputFilled from "../../form/inputs/AutocompleteInputFilled";
import AddressAsyncCreatableInputFilled from "../../form/inputs/AddressAsyncCreatableInputFilled";
import AirlineAsyncCreatableInputFilled from "../../form/inputs/AirlineAsyncCreatableInputFilled";
import AirportAsyncCreatableInputFilled from "../../form/inputs/AirportAsyncCreatableInputFilled";
import CurrencyInput from "../../form/inputs/CurrencyInput";
import DateInput from "../../form/inputs/DateInput";
import FlightNumberInput from "../../form/inputs/FlightNumberInput";
import LocationAsyncCreatableInputFilled from "../../form/inputs/LocationAsyncCreatableInputFilled";
import TextInput from "../../form/inputs/TextInput";
import TravelerInput from "../../form/inputs/TravelerInput";
import TimezoneAsyncInputFilled from "../../form/inputs/TimezoneAsyncInputFilled";
import TagInput from "../../form/inputs/TagInput";

import { ObjectHash } from "../../../utils/helpers";

export const getDefaultDate = (dataType: string, key: string) => {
  let [defaultDate] = new Date().toISOString().split("T");
  if (dataType === "HOTEL") {
    if (key === "from") {
      defaultDate = `${defaultDate}T15:00:00.000Z`;
    }
    if (key === "to") {
      defaultDate = `${DateTime.fromISO(defaultDate)
        .plus({ day: 1 })
        .toFormat("yyyy-MM-dd")}T11:00:00.000Z`;
    }
  } else {
    if (["fromForSearch", "from"].includes(key)) {
      defaultDate = `${defaultDate}T08:00:00.000Z`;
    }
    if (key === "to") {
      defaultDate = `${defaultDate}T09:00:00.000Z`;
    }
  }
  return defaultDate;
};

export const getInputComponent = (
  key: string,
  opts: ObjectHash,
  dataType: string,
  handleChange: CallableFunction,
  state: ObjectHash,
  locationsData: any[],
  menuPortalTarget: any,
  isRequired: boolean
) => {
  const { inputType } = opts;
  const value = state[key];

  const componentProps: ObjectHash = {
    closeMenuOnScroll: (e: any) => {
      // https://app.bugsnag.com/tripgrid/vanilla-ui/errors/6227ae04b2ea420008c51fc6?event_id=6227ae04009270c3282e0000&i=sk&m=nw
      if (!e?.target?.classList?.length) {
        return;
      }

      if (
        e.target.classList.contains("not-draggable") ||
        e.target.classList.contains("quick-add-dialog__form")
      ) {
        return e;
      }
      return;
    }, // close react-select menus when scrolling dialog, but not when scrolling menu
    key: `${dataType}-${key}`,
    label: opts.label,
    onChange: (value: any, meta?: ObjectHash) => handleChange(key, value, meta),
    menuPortalTarget, // for react-select menus, renders on top of modal to prevent clipping
    value, // makes the inputs controlled
    variant: "filled" // for non react-select input filled style
  };

  const inputsHash: ObjectHash = {
    airport: AirportAsyncCreatableInputFilled,
    currency: CurrencyInput,
    date: DateInput,
    location: LocationAsyncCreatableInputFilled,
    select: AutocompleteInputFilled,
    station: TextInput,
    text: TextInput,
    textarea: TextInput,
    timezone: TimezoneAsyncInputFilled,
    user_list: TravelerInput,
    tag: TagInput
  };

  let Component: any = TextInput; // use uppercase for dynamic component generation

  if (Object.keys(inputsHash).includes(inputType)) {
    Component = inputsHash[inputType];
  }

  if (isRequired) {
    componentProps.isRequired = true;
  }

  if (inputType === "textarea") {
    componentProps.isMultiline = true;
  }

  if (["ACTIVITY", "CAR", "HOTEL"].includes(dataType)) {
    if (["CAR", "HOTEL"].includes(dataType)) {
      if (key === "name") {
        Component = LocationAsyncCreatableInputFilled;
      }
    }
    if (["fromLocation", "toLocation"].includes(key)) {
      Component = AddressAsyncCreatableInputFilled;
      componentProps.defaultOptions = locationsData.map((location) => ({
        label: location,
        value: location
      }));
    }
  }

  if (["from", "to"].includes(key) || opts.withTime) {
    componentProps.withTime = true;
  }

  if (dataType === "AIR") {
    if (key === "name") {
      Component = AirlineAsyncCreatableInputFilled;
    }
    if (key === "flightNum") {
      Component = FlightNumberInput;
      componentProps.airline = state.name || null;
      componentProps.date = state.fromForSearch || null;
    }
    if (key === "from" && Object.hasOwnProperty.call(state, "name")) {
      componentProps.isDisabled = true;
    }
    if (["fromLocation", "toLocation"].includes(key)) {
      componentProps.disabledOpts = [
        state.fromLocation || "",
        state.toLocation || ""
      ].filter((option) => option.length);
    }
  }

  if (["ACTIVITY", "AIR", "CAR", "HOTEL", "RAIL"].includes(dataType)) {
    if (key === "to") {
      [componentProps.minValue] = state.from
        ? String(state.from).split("T")
        : "";
    }
  }

  // convert value into array for React-Select for controlled input
  const keysUsingReactSelect = [];

  if (["AIR", "CAR", "HOTEL"].includes(dataType)) {
    keysUsingReactSelect.push("fromLocation", "toLocation");
    if (["AIR"].includes(dataType)) {
      keysUsingReactSelect.push("name", "flightNum");
    }
    if (["CAR", "HOTEL"].includes(dataType)) {
      keysUsingReactSelect.push("name");
    }
  }

  if (Object.keys(opts).includes("options")) {
    componentProps.options = opts.options;
    if (!opts.isMultiple) {
      componentProps.value = [{ label: value, value }];
    }

    if (opts.isMultiple) {
      componentProps.value = [];
      if ((value || []).length) {
        if (typeof value === "string") {
          componentProps.value = [{ label: value, value }];
        } else {
          componentProps.value = value.map((v: string) => ({
            label: v,
            value: v
          }));
        }
      }
    }
  }

  if (inputType === "user_list") {
    componentProps.isCreatable = true;
    componentProps.defaultValue = value;
    delete componentProps.value;
  }

  if (inputType === "tag") {
    componentProps.label = opts.label;
    componentProps.placeholder = "";
    componentProps.tagGroup = opts.tagGroup;
    componentProps.selectedTagIds = opts.selectedTagIds;
    componentProps.onChange = opts.onChange;
    componentProps.menuPlacement = "top";
    delete componentProps.value;
  }

  return <Component {...componentProps} />;
};

export const handleDateChange = (
  key: string,
  date: string,
  stateSetter: CallableFunction,
  formState: ObjectHash
) => {
  const isoDate = DateTime.fromISO(date, {
    zone: "utc"
  });
  const isoDateStr = isoDate.toISO();

  // pull "to" date forward to match "fromForSearch" date, and use
  // the time value from the "from" field
  if (key === "fromForSearch") {
    const isoDateFrom = DateTime.fromISO(formState.from, { zone: "utc" });
    const compDate = DateTime.fromISO(
      `${isoDate.toFormat("yyyy-MM-dd")}T${isoDateFrom.toFormat("HH:mm")}`,
      { zone: "utc" }
    );
    const compDateStr = compDate.toISO();

    if (DateTime.fromISO(formState.to) < compDate) {
      stateSetter((oldValues: ObjectHash) => ({
        ...oldValues,
        fromForSearch: compDateStr,
        from: compDateStr,
        to: compDateStr
      }));
    } else {
      stateSetter((oldValues: ObjectHash) => ({
        ...oldValues,
        fromForSearch: isoDateStr,
        from: isoDateStr
      }));
    }

    return;
  }

  // pull "to" date forward to match "from" date
  if (key === "from" && DateTime.fromISO(formState.to) < isoDate) {
    stateSetter((oldValues: ObjectHash) => ({
      ...oldValues,
      from: isoDateStr,
      to: isoDateStr
    }));
  } else {
    stateSetter((oldValues: ObjectHash) => ({
      ...oldValues,
      [key]: isoDateStr
    }));
  }
};
