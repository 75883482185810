import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { debounce } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import AppIcon from "../../app-icon";
import CloseButton from "../../button/close";
import Button from "../../button";
import TextInput from "../../form/inputs/TextInput";
import Tooltip from "../../tooltip";
import useModal from "../../../hooks/use-modal.hook";

import "./convert-csv-dialog.scss";

interface Props {
  onImport: any;
}

const parseData = (
  rawData: string,
  hasHeaders: boolean
): {
  columns: string[];
  hasError: boolean;
  rows: string[][];
} => {
  const response = { columns: [], rows: [], hasError: false };

  if (!rawData.length) {
    return response;
  }

  const rows = rawData.split(/\r?\n/).map((rowString) => rowString.split("	"));

  const columns: string[] = [];

  if (!hasHeaders) {
    for (let i = 0; i < rows[0].length; i += 1) {
      columns.push(`Column ${i + 1}`);
    }
  } else {
    rows.splice(0, 1)[0].forEach((col) => columns.push(col));

    if (rows.length === 0) {
      const blankRow = [];
      for (let i = 0; i < columns.length; i += 1) {
        blankRow.push("");
      }
      rows.push(blankRow);
    }
  }

  columns.push("documentRowMenu");

  const hasError = rows.every(
    (row: string[]) => row.length !== columns.length - 1
  );

  return { columns, hasError, rows };
};

export default function ConvertCsvDialog(props: Props) {
  const { closeModal } = useModal();
  const { onImport } = props;

  const [hasHeaders, setHasHeaders] = useState(false);
  const [importError, setImportError] = useState("");
  const [rawData, setRawData] = useState("");

  const validateImport = debounce((rawData: string, hasHeaders: boolean) => {
    const { hasError } = parseData(rawData, hasHeaders);
    setImportError(hasError ? "Invalid format" : "");
  }, 500);

  const handleClose = () => {
    closeModal();
  };

  const handleImport = () => {
    const { columns, rows, hasError } = parseData(rawData, hasHeaders);

    if (hasError) {
      return;
    }

    onImport(columns, rows);

    handleClose();
  };

  const hasError = Boolean(importError.length);
  const canImport = !hasError && rawData.length;

  return (
    <Dialog
      classes={{ root: "convert-csv-dialog", paper: "paper" }}
      onClose={() => handleClose()}
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <div className="title">
          <h1>Paste Values</h1>
        </div>
        <div className="buttons">
          <div className="close-button">
            <Tooltip text="Close">
              <CloseButton onClick={() => handleClose()} size="medium" />
            </Tooltip>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="description">
          Copy values from a spreadsheet or TSV and paste them below. The values
          will be parsed and used to populate the custom table.
        </div>
        <TextInput
          isMultiline={true}
          onChange={(value: string) => {
            setRawData(value);
            validateImport(value, hasHeaders);
          }}
          value={rawData}
        />
        <div className="footer">
          <FormControlLabel
            control={
              <Checkbox
                checked={hasHeaders}
                color="primary"
                onChange={() => {
                  setHasHeaders(!hasHeaders);
                  validateImport(rawData, !hasHeaders);
                }}
                value="hasHeaders"
              />
            }
            label="Pasted values include header row"
          />
          {hasError && (
            <div className="import-error">
              <AppIcon type="error" color="red" />
              <span>Invalid Format</span>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="gray"
          isTransparent={true}
          label="Cancel"
          onClick={() => closeModal()}
          size="medium"
        />
        <Button
          color="product-blue"
          isDisabled={!canImport}
          label="Import"
          onClick={() => handleImport()}
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
