import { ObjectHash, sortByStringCompare } from "../../utils/helpers";

export type StackColumn = {
  hidden?: boolean;
  id: string;
  index: number;
  label: string;
  sort?: boolean;
  filter?: boolean;
};

export type StackPage = {
  count: number;
  data: ObjectHash[];
};

export type StackSort = {
  column: StackColumn;
  direction: "asc" | "desc";
  directionType?: string;
  onSort?: (a: ObjectHash, b: ObjectHash, sort: StackSort) => number;
};

export type StackFilter = {
  article?: "is" | "are"; // display value only
  formId?: string; // unique id use by the stack filter menu to create a formState object
  fieldId: string; // what property of the related model does this filter apply to?
  inputType: string; // what type of input is used to select the value for this filter?
  label?: string; // used when selecting this filter from a select, in the filter menu
  position?: number;
  value?: any;
};

export type StackGroup = {
  fieldId: string;
  label: string;
  onStackTitle?: (value: any, meta?: ObjectHash) => string;
};

/*
 * When grouping results into multiple stacks, all results must belong to a group. Since the default view is
 * to NOT have a group selected, all results must belong to a "default" group which is easily identifiable.
 */

export const StackGroupDefault = {
  fieldId: "default",
  label: "",
  onStackTitle: () => ""
};

export type StackGroupResult = {
  value: any;
  data: any[];
};

export type StackColumnProp = string | StackColumn;

export const filterByKeyword = (
  data: ObjectHash,
  keys: string[],
  keyword: string
) => {
  if (!keyword) {
    return true;
  }
  return keys.some((key: string) =>
    String(data[key] || "")
      .toLowerCase()
      .includes(keyword.toLowerCase())
  );
};

export const getStaticData = async (
  data: ObjectHash[],
  page: number,
  limit: number,
  sort?: StackSort
): Promise<{ count: number; data: ObjectHash[] }> => {
  const offset = page * limit;
  const sortData = sort ? sortStaticData(data, sort) : data;

  return {
    count: data.length,
    data: sortData.slice(offset, offset + limit)
  };
};

export const sortStaticData = (
  data: ObjectHash[],
  sort: StackSort
): ObjectHash[] => {
  const sortFunc = sort.onSort || sortByStringCompare;
  data.sort((a: ObjectHash, b: ObjectHash) => sortFunc(a, b, sort));
  return data;
};
