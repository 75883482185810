import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import {
  getTextValueProps,
  MuiVariant,
  ObjectHash
} from "../../../utils/helpers";
import "./inputs.scss";

interface Props {
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  enableUnderline?: boolean;
  label?: string;
  margin?: "none" | "dense" | "normal" | undefined;
  InputProps?: ObjectHash;
  inputProps?: ObjectHash;
  isMultiline?: boolean;
  isRequired?: boolean;
  onClick?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  placeholder?: string;
  style?: ObjectHash;
  type?: string;
  value?: string;
  variant?: MuiVariant;
}

const TextInput = forwardRef((props: Props, ref: any) => {
  let disableUnderline = {};
  if (props.variant && ["filled", "standard"].includes(props.variant)) {
    disableUnderline = { disableUnderline: true };
  }
  if (props.enableUnderline) {
    disableUnderline = { disableUnderline: false };
  }

  const {
    autoFocus,
    isMultiline,
    disabled,
    InputProps,
    inputProps,
    label,
    margin,
    onChange,
    onValidate,
    onClick,
    placeholder,
    isRequired,
    style,
    type,
    variant
  } = props;

  return (
    <TextField
      {...getTextValueProps(props)}
      fullWidth
      autoFocus={autoFocus}
      autoComplete="off"
      className={`text-input ${isMultiline ? "text-input--textarea" : ""}`}
      disabled={disabled || false}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ...disableUnderline,
        ...InputProps
      }}
      inputProps={{
        autoComplete: "new-password", // disables Chrome autocomplete
        ...inputProps
      }}
      inputRef={ref}
      label={label}
      margin={margin}
      multiline={isMultiline}
      onClick={onClick}
      onChange={(e) => {
        onChange(e.target.value);
        onValidate && onValidate(e.target.value);
      }}
      placeholder={placeholder}
      required={isRequired || false}
      style={style}
      type={type || "text"}
      variant={variant || "outlined"}
    />
  );
});

export default TextInput;
