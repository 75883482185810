import React, { useCallback } from "react";

import { AppPageProps } from "../../router";
import CreateProfileButton from "../../components/button/create-profile";
import ObjectTable from "../../components/object-table";
import PageHeader from "../../components/page-header";
import SearchInput from "../../components/search-input";

import "./people-page.scss";

export default function PeoplePage(props: AppPageProps) {
  const [filterState, setFilterState] = React.useState<any[]>([]);
  const [prevFilterHashState, setPrevFilterHashState] = React.useState<any[]>(
    []
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { company, templates, settings, user } = props;

  const handleApplyFilter = useCallback((searchParams: any[]) => {
    setPrevFilterHashState(searchParams);
    setFilterState(searchParams);
  }, []);

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      handleApplyFilter(prevFilterHashState);
    }

    return () => {
      isMounted = false;
    };
  }, [handleApplyFilter, prevFilterHashState]);

  const filterParams: any[] = [...filterState];
  if (searchTerm) {
    filterParams.push(["name", searchTerm]);
  }

  return (
    <div id="people-page" className="page-container">
      <PageHeader title="All Profiles" />
      <div className="people-page-utils">
        <div className="people-page-button-group">
          <SearchInput
            id={"people-page-search-input"}
            key={"people-page-search-input"}
            onInput={(value: string) => {
              setSearchTerm(value);
            }}
            resource={"users"}
            keepOpen={false}
          />
          <CreateProfileButton />
        </div>
      </div>
      <ObjectTable
        key={`people-page__profile-table-${filterState}-${searchTerm}`}
        renderOpts={{
          disableDownload: true,
          showQuickAdd: true,
          useCardStyle: true,
          showTagCol: true
        }}
        searchOpts={{
          filters: filterParams,
          limit: rowsPerPage,
          order: "name",
          resource: "users",
          viewStateKey: "users-profile"
        }}
        setRowsPerPage={setRowsPerPage}
        templateName="profile"
        title="Profiles"
        company={company}
        templates={templates}
        settings={settings}
        user={user}
      />
    </div>
  );
}
