import BookingRequestModel from "../../models/booking-request.model";
import { createContext } from "react";
import { ObjectHash } from "../../utils/helpers";
import ParsedEmailModel from "../../models/parsed-email.model";

export interface ITravelState {
  dispatch: CallableFunction;
  integrationInstances: { [index: string]: ObjectHash };
  lastBookingRequest: BookingRequestModel;
  parsedEmails: ParsedEmailModel[];
  subscribed: boolean;
}

export const TravelState: ITravelState = {
  dispatch: () => {},
  integrationInstances: {},
  lastBookingRequest: new BookingRequestModel(),
  parsedEmails: [],
  subscribed: false
};

export const TravelContext = createContext<ITravelState>(TravelState);
