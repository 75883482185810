import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis
} from "@dnd-kit/modifiers";

import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";

import SortableItem from "./SortableItem";

import "./sortable.scss";

export type Item = { id: string; disabled?: boolean; element: JSX.Element };

export interface Props {
  disabled?: boolean;
  items: Item[];
  onChange?: CallableFunction;
}

export default function Sortable(props: Props) {
  const { disabled = false, onChange } = props;

  const [items, setItems] = useState<Item[]>(props.items);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2
      }
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item: Item) => item.id === active.id);
      const newIndex = items.findIndex((item: Item) => item.id === over.id);

      setItems(arrayMove(items, oldIndex, newIndex));

      onChange && onChange(active.id, newIndex, oldIndex);
    }
  };

  return (
    <div className="sortable">
      {disabled && <div className="disable-overlay"></div>}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToParentElement, restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((item: Item) => (
            <SortableItem item={item} key={item.id} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
