import React, { useEffect, useState } from "react";

import AppContainer from "../../../container";
import { dateComesBefore, getDisplayDate } from "../../../utils/helpers";
import Button from "../../button";
import { GridDetailModel } from "../../../models/grid.model";
import { GridService, IGridService } from "../../../services/grid.service";
import Tile, { TileProps } from "../tile";
import Tooltip from "../../tooltip";
import useModal from "../../../hooks/use-modal.hook";
import UserModel from "../../../models/user.model";
import UserProfileIcon from "../../user-profile-icon";

import "./tile.scss";

export default function GridDetailsTile(props: TileProps) {
  const { grid, onGridChange } = props;

  const gridService: IGridService = AppContainer.get(GridService);
  const { openModal } = useModal();

  const [gridDetails, setGridDetails] = useState<GridDetailModel | null>(null);

  const first3Users = grid.users.slice(0, 3);
  const moreUsers = grid.users.slice(3);
  const hasMoreUsers = Boolean(moreUsers.length);

  useEffect(() => {
    let subscribed = true;
    const unsubscribe = () => {
      subscribed = false;
    };

    gridService.getDetails(grid.id).then((response) => {
      if (!response || !subscribed) {
        return;
      }

      setGridDetails(response);
    });

    return unsubscribe;
  }, [grid.id, grid.query.params, gridService]);

  // Start with generated grid details and override with values from the grid filter

  const gridDates = grid.getFilterStartEndDates();

  let startDate = gridDetails?.startDateTime ? gridDetails.startDateTime : "";

  if (gridDates.start) {
    startDate = gridDates.start;
  }

  let endDate = gridDetails?.endDateTime ? gridDetails.endDateTime : "";

  if (gridDates.end) {
    endDate = gridDates.end;
  }

  // failsafe
  if (dateComesBefore(endDate, startDate)) {
    endDate = "";
    startDate = "";
  }

  let destinations = gridDetails?.destinationAirports || [];

  const gridDestinations = grid.getFilterDestinationAirports();
  if (gridDestinations.length) {
    destinations = gridDestinations;
  }

  return (
    <Tile title="Grid Details">
      <div className="grid-details-tile">
        <div className="header">
          <div className="user-profile-icons">
            {first3Users.map((user: UserModel, index: number) => {
              return <UserProfileIcon user={user} key={user.id} />;
            })}
            {hasMoreUsers && (
              <Tooltip
                text={
                  <div>
                    {moreUsers.map((user: UserModel) => (
                      <span key={user.id}>
                        {user.name}
                        <br />
                      </span>
                    ))}
                  </div>
                }
              >
                <div className="more-users-count">
                  <span>{`+${moreUsers.length}`}</span>
                </div>
              </Tooltip>
            )}
          </div>
          <div>
            <Button
              color="product-blue"
              isRippleDisabled={true}
              label="Edit Grid"
              size="small"
              onClick={() => {
                openModal("edit-grid", {
                  grid,
                  onChangeGrid: onGridChange
                });
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="header">Start Date:</div>
          <div className="description">
            {startDate ? getDisplayDate(startDate, "MMM d, yyyy") : "n/a"}
          </div>
        </div>
        <div className="row">
          <div className="header">End Date:</div>
          <div className="description">
            {endDate ? getDisplayDate(endDate, "MMM d, yyyy") : "n/a"}
          </div>
        </div>
        <div className="row">
          <div className="header">Destinations:</div>
        </div>
        {destinations.map((destination: string, index: number) => (
          <div className="destination-chip" key={index}>
            {destination}
          </div>
        ))}
      </div>
    </Tile>
  );
}
