import React, { useState } from "react";
import { camelCase } from "lodash";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";

import useModal from "../../../../../hooks/use-modal.hook";
import TemplatePropertyModel from "../../../../../models/template-property.model";

import AutocompleteInput from "../../../../../components/form/inputs/AutocompleteInput";
import Button from "../../../../../components/button";
import RemoveButton from "../../../../../components/button/remove";
import TextInput from "../../../../../components/form/inputs/TextInput";

import { stripAndTrim, ObjectHash } from "../../../../../utils/helpers";
import "./template-editor-menu.scss";

const propertyTypeHash: ObjectHash = {
  airport: { friendlyName: "Airport" },
  currency: { friendlyName: "Currency" },
  date: { friendlyName: "Date" },
  duration: { friendlyName: "Duration" },
  location: { friendlyName: "Location" },
  select: { friendlyName: "Single Select" },
  station: { friendlyName: "Station" },
  text: { friendlyName: "Single Line Text" },
  textarea: { friendlyName: "Long Form Text" },
  timezone: { friendlyName: "Timezone" },
  user_list: { friendlyName: "User List" }
};

interface Props {
  disabled?: boolean;
  userDefinedTemplate: string;
  onUpdateTemplateProperty: CallableFunction;
  templateProp?: TemplatePropertyModel;
}

export default function TemplateEditorMenu(props: Props) {
  const { modalIsOpen, openModal } = useModal();

  const {
    disabled,
    onUpdateTemplateProperty,
    templateProp = new TemplatePropertyModel(),
    userDefinedTemplate
  } = props;
  const { friendlyName, propertyType } = templateProp;
  const isNew = !Boolean(templateProp.id);

  const [anchorEl, setAnchorEl] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [selectOptionsKey, setSelectOptionsKey] = useState(0);
  const [popperKey, setPopperKey] = useState(0);

  let zeroState: ObjectHash = {
    name: "",
    friendlyName: "",
    propertyOptions: [],
    propertyType: "",
    userDefinedTemplate // every new and updated field needs this to ties it to the right template
  };

  if (templateProp?.id) {
    zeroState = { ...templateProp };
  }

  const [state, setState] = React.useState({
    ...zeroState
  });

  const resetState = () => {
    setState({ ...zeroState });
    setPopperKey(popperKey + 1);
    setFormChanged(false);
  };

  const onClose = () => {
    resetState();
  };

  const open = disabled ? false : Boolean(anchorEl);
  const popperClass = open
    ? "template-editor-popper template-editor-popper--open"
    : "template-editor-popper";

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = (event: any) => {
    // prevents click away from triggering on portaled propertyType menu
    if (event && event.target.id.includes("option")) {
      return;
    }
    if (formChanged) {
      if (modalIsOpen("confirm")) {
        return;
      }
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody:
          "Are you sure you would like to cancel editing this field? Your changes will not be saved.",
        onConfirm: () => {
          onClose();
          setAnchorEl(null);
        }
      });
    } else {
      onClose();
      setAnchorEl(null);
    }
  };

  const handleChangeTemplateProperty = () => {
    const payload: ObjectHash = { ...state };

    if (!payload.id) {
      let name = payload.friendlyName;

      if (!name || !name.length) {
        return;
      }

      name = name.replace(/ /g, "_").toLowerCase().match(/\w/g);

      if (!name || !name.length) {
        return;
      }

      payload.name = camelCase(String(stripAndTrim(name.join(""), "_")));
    }
    onUpdateTemplateProperty(payload);
    onClose();
    setAnchorEl(null);
  };

  const handleCheckboxToggle = (key: string, value: any) => {
    setFormChanged(true);
    let newValue: any[] = [];
    if (state[key].includes(value)) {
      newValue = state[key].filter((item: any) => item !== value);
    } else {
      newValue.push(value);
    }

    setState((oldValues) => ({
      ...oldValues,
      [key]: newValue
    }));
  };

  const handleInputChange = (key: string, value: any) => {
    setFormChanged(true);
    setState((oldValues) => ({
      ...oldValues,
      [key]: value
    }));
  };

  const handleAddTemplatePropertyOption = () => {
    setFormChanged(true);
    const newPropertyOptions = state.propertyOptions.slice();
    newPropertyOptions.push("");
    setState((oldValues) => ({
      ...oldValues,
      propertyOptions: newPropertyOptions
    }));
    setSelectOptionsKey(selectOptionsKey + 1);
    // Try to focus new input
    try {
      setTimeout(() => {
        const optElement = document
          .getElementById(
            `select-options__option--${newPropertyOptions.length - 1}`
          )
          ?.querySelector("input");

        if (optElement) {
          optElement.focus();
        }
      }, 100);
    } catch (e) {
      // ignore error
    }
  };

  const handleDeleteTemplatePropertyOption = (index: number) => {
    setFormChanged(true);
    const newPropertyOptions = state.propertyOptions.slice();
    newPropertyOptions.splice(index, 1);
    setState((oldValues) => ({
      ...oldValues,
      propertyOptions: newPropertyOptions
    }));
    setSelectOptionsKey(selectOptionsKey + 1);
  };

  const handleTemplatePropertyOptionChange = (value: any, index: number) => {
    setFormChanged(true);
    const newPropertyOptions = state.propertyOptions.slice();
    newPropertyOptions[index] = value;
    setState((oldValues) => ({
      ...oldValues,
      propertyOptions: newPropertyOptions
    }));
  };

  let propertyTypeOpts = Object.keys(propertyTypeHash)
    .filter((key) => !["duration", "user_list"].includes(key))
    .map((k) => ({
      label: (propertyTypeHash[k] || {}).friendlyName || "",
      value: k
    }));

  if (templateProp && ["duration", "user_list"].includes(propertyType)) {
    propertyTypeOpts = Object.keys(propertyTypeHash)
      .filter((key) => key === propertyType) // only show existing propertyType for duration/user_list fields
      .map((k) => ({
        label: propertyTypeHash[k].friendlyName,
        value: k
      }));
  }

  return (
    <div className="template-editor-menu">
      <div onClick={handleClick}>
        {isNew ? (
          <Button
            color="product-blue"
            icon="plus-circle"
            isRippleDisabled={true}
            isTransparent={true}
            label="New Field"
            size="medium"
          />
        ) : (
          <AutocompleteInput
            isDisabled={Boolean(disabled)}
            defaultValue={{
              label: (propertyTypeHash[propertyType] || {}).friendlyName || "",
              value: propertyType
            }}
            isSearchable={false}
            menuIsOpen={false}
          />
        )}
      </div>
      <Popper
        id="template-editor-popper"
        key={`template-editor-popper-${popperKey}`}
        className={popperClass}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={(e) => {
            handleClickAway(e);
          }}
        >
          <div className="template-editor-popper__content">
            <TextInput
              defaultValue={friendlyName ?? null}
              onChange={(value: string) =>
                handleInputChange("friendlyName", value)
              }
              placeholder="Field Name"
            />
            <AutocompleteInput
              closeMenuOnScroll={(event: any) => {
                if (event.target.id === "app-page") {
                  return event;
                }
                return false;
              }}
              defaultValue={
                templateProp
                  ? {
                      label:
                        (propertyTypeHash[propertyType] || {}).friendlyName ||
                        "",
                      value: propertyType
                    }
                  : ""
              }
              menuPortalTarget={document.body}
              placeholder="Field Type"
              options={propertyTypeOpts}
              onChange={(value: any) =>
                handleInputChange("propertyType", value)
              }
            />
            {state.propertyType === "select" ? (
              <div
                key={`select-options-${selectOptionsKey}`}
                className="template-editor-popper__select-options"
              >
                {(state.propertyOptions || []).length
                  ? state.propertyOptions.map((opt: string, index: number) => (
                      <div
                        key={`template-editor-popper__select-options__option--${index}`}
                        id={`select-options__option--${index}`}
                        className="template-editor-popper__select-options__option"
                      >
                        <TextInput
                          defaultValue={opt}
                          onChange={(value: string) =>
                            handleTemplatePropertyOptionChange(value, index)
                          }
                        />
                        <RemoveButton
                          onClick={() =>
                            handleDeleteTemplatePropertyOption(index)
                          }
                          size="small"
                        />
                      </div>
                    ))
                  : null}
                <div className={"template-editor-popper__buttons"}>
                  <Button
                    alignment="align-center"
                    color="product-background-blue"
                    icon="plus"
                    isFullWidth={true}
                    label="Add Option"
                    onClick={() => handleAddTemplatePropertyOption()}
                    size="medium"
                  />
                </div>
              </div>
            ) : null}
            {state.propertyType === "date" ? (
              <div className="template-editor-popper__checkbox-flex">
                <Checkbox
                  checked={(state.propertyOptions || []).includes("with-time")}
                  color="primary"
                  onChange={() => {
                    handleCheckboxToggle("propertyOptions", "with-time");
                  }}
                  value={"withTime"}
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                Include Time Field
              </div>
            ) : null}
            <div className={"template-editor-popper__buttons"}>
              <Button
                color="product-blue"
                isDisabled={!formChanged}
                isFullWidth={true}
                label="Save"
                onClick={() => handleChangeTemplateProperty()}
                size="medium"
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
