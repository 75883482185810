import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { resolve } from "inversify-react";

import { ObjectHash } from "../../../utils/helpers";
import Loader from "../../../components/loader";
import RegistrationPage from "../";
import UserModel from "../../../models/user.model";
import CompanyModel from "../../../models/company.model";
import { AppPageProps } from "../../../router";
import { AuthService, IAuthService } from "../../../services/auth.service";

interface Props extends AppPageProps, RouteComponentProps {}

interface State {
  company: CompanyModel;
  isLoading: boolean;
  user: UserModel;
}

class InvitePage extends React.Component<Props, State> {
  @resolve(AuthService)
  authService!: IAuthService;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      company: new CompanyModel(),
      user: new UserModel()
    };
  }

  async componentDidMount() {
    const { match }: { match: ObjectHash } = this.props;
    const { id, token } = match.params;
    if (!id || !token) return;

    const response: ObjectHash = await this.authService.getInviteData(
      id,
      token
    );

    if (!response.user || !response.user.id) {
      this.setState({ isLoading: false });
      return;
    } else {
      this.setState({
        isLoading: false,
        user: new UserModel(response.user),
        company: new CompanyModel(response.company)
      });
    }
  }

  render() {
    const { settings, templates } = this.props;
    const { company, isLoading, user } = this.state;

    if (isLoading) {
      return <Loader type="dots" />;
    }

    return (
      <RegistrationPage
        settings={settings}
        templates={templates}
        company={company}
        user={user}
      />
    );
  }
}

export default withRouter(InvitePage);
