import React from "react";
import UserModel from "../../models/user.model";
import Tooltip from "../tooltip";
import UserProfileIcon from "../user-profile-icon";
import "./user-profile-icon-list.scss";

interface Props {
  count?: number;
  users: UserModel[];
}

export default function UserProfileIconList(props: Props) {
  const { count = 3, users } = props;
  const firstUsers = users.slice(0, count);
  const moreUsers = users.slice(count);

  return (
    <div className="user-profile-icons">
      {firstUsers.map((user: UserModel, index: number) => {
        return <UserProfileIcon user={user} key={user.id} />;
      })}
      {Boolean(moreUsers.length) && (
        <Tooltip
          text={
            <div>
              {moreUsers.map((user: UserModel) => (
                <span key={user.id}>
                  {user.name}
                  <br />
                </span>
              ))}
            </div>
          }
        >
          <div className="more-users-count">
            <span>{`+${moreUsers.length}`}</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
}
