import React, { useRef, useState } from "react";

import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import AutocompleteInputFilled from "../../../form/inputs/AutocompleteInputFilled";
import PopperMenu from "../../../popper-menu";
import { SelectOption } from "../../../../utils/helpers";
import { StackSort } from "../../helpers";

import "./sort-menu.scss";

interface Props {
  defaultValue?: StackSort;
  disablePortal?: boolean;
  onChange: CallableFunction;
  sorts: StackSort[];
}

const directionOpts: { [index: string]: SelectOption[] } = {
  string: [
    { label: "A to Z", value: "asc" },
    { label: "Z to A", value: "desc" }
  ],
  date: [
    { label: "Newest to Oldest", value: "desc" },
    { label: "Oldest to Newest", value: "asc" }
  ]
};

export default function SortMenu(props: Props) {
  const { defaultValue, disablePortal, onChange, sorts } = props;

  const menuRef = useRef();

  const [sort, setSort] = useState<StackSort>(defaultValue || sorts[0]);
  const [directionType, setDirectionType] = useState<string>(
    defaultValue?.directionType || "string"
  );

  const dirTypeOpts = directionOpts[directionType];

  const sortOpts = sorts.map((sort: StackSort) => ({
    label: sort.column.label,
    value: sort.column.id
  }));

  const getDirectionOpt = (): SelectOption[] => {
    const selectedOpt = dirTypeOpts.find(
      (opt: SelectOption) => opt.value === sort.direction
    );
    if (selectedOpt) {
      return [selectedOpt];
    }

    return [];
  };

  const getSortOpt = (): SelectOption[] => {
    const selectedOpt = sortOpts.find(
      (opt: SelectOption) => opt.value === sort?.column.id
    );

    if (selectedOpt) {
      return [selectedOpt];
    }

    return [];
  };

  const handleChange = (updatedSort: StackSort) => {
    setSort(updatedSort);
    onChange && onChange(updatedSort);
  };

  const handleSortChange = (value: string) => {
    const selectedSort = sorts.find(
      (sort: StackSort) => sort.column.id === value
    );
    if (selectedSort) {
      handleChange({ ...selectedSort });
      setDirectionType(selectedSort.directionType || "string");
    }
  };

  const handleDirectionChange = (value: "asc" | "desc") => {
    handleChange({ ...sort, direction: value });
  };

  return (
    <PopperMenu
      anchor={
        <AppIconFrame color="product-background-blue" shape="square">
          <AppIcon type="sort" color="black" />
        </AppIconFrame>
      }
      disablePortal={disablePortal}
      ref={menuRef}
    >
      <div className="stack-sort-menu">
        <div className="title">Sort By</div>

        <div className="form">
          <AutocompleteInputFilled
            value={getSortOpt()}
            onChange={handleSortChange}
            options={sortOpts}
          />
          <div className="from">
            <div>From</div>
          </div>
          <AutocompleteInputFilled
            key={directionType}
            value={getDirectionOpt()}
            onChange={handleDirectionChange}
            options={dirTypeOpts}
          />
        </div>
      </div>
    </PopperMenu>
  );
}
