import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import {
  ColumnMaxWidth,
  ColumnMinWidth,
  GridCell,
  GridRow,
  StyledColumnConfig
} from "./helpers";

interface Props {
  columns: StyledColumnConfig;
  hideGridHead?: boolean;
  onMeasure: (columns: StyledColumnConfig) => void;
  parent?: HTMLElement;
  rows: GridRow[];
}

// https://medium.com/trabe/measuring-non-rendered-elements-in-react-with-portals-c5b7c51aec25
export default function MeasurePortal(props: Props) {
  const {
    columns,
    rows,
    hideGridHead = false,
    onMeasure,
    parent = document.body
  } = props;

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    // measure
    const sizedColumns: StyledColumnConfig = new Map();
    const headerRow = Array.from(tableRef.current.rows).shift();
    Array.from(headerRow?.cells || []).forEach((cell: HTMLTableCellElement) => {
      const columnId = cell.getAttribute("data-column-id") || "";
      const column = columns.get(columnId);
      if (column) {
        // apply column min/max width bounds
        let width = cell.offsetWidth;
        if (width > ColumnMaxWidth) {
          width = ColumnMaxWidth;
        }
        if (width < ColumnMinWidth) {
          width = ColumnMinWidth;
        }

        sizedColumns.set(columnId, { ...column, width });
      }
    });

    onMeasure(sizedColumns);
  }, [columns, onMeasure]);

  // ensure that the table will not be visible
  const maxTableWidth = columns.size * ColumnMaxWidth;
  const left = maxTableWidth * -1 - 500;

  const maxWidthWrapperStyle = {
    minWidth: ColumnMinWidth,
    maxWidth: ColumnMaxWidth,
    overflow: "hidden"
  };

  const TableElement = (
    <div style={{ position: "absolute", left }} className="measure-portal">
      <table ref={tableRef}>
        {!hideGridHead && (
          <thead>
            <tr>
              {Array.from(columns.keys()).map((columnId: string) => {
                const { label, width } = columns.get(columnId) || {};
                return (
                  <td key={columnId} data-column-id={columnId}>
                    <div style={{ ...maxWidthWrapperStyle, minWidth: width }}>
                      {label || columnId}
                    </div>
                  </td>
                );
              })}
            </tr>
          </thead>
        )}
        {hideGridHead && (
          <thead>
            <tr>
              <th colSpan={columns.size}></th>
            </tr>
          </thead>
        )}
        <tbody>
          {rows.map((row: GridRow, index: number) => {
            const { cells } = row;

            return (
              <tr key={index}>
                {Array.from(columns.keys()).map((columnId: string) => {
                  const { element } =
                    cells.find(
                      (cell: GridCell) => cell.columnId === columnId
                    ) || {};
                  const { width } = columns.get(columnId) || {};

                  return (
                    <td key={columnId}>
                      <div style={{ ...maxWidthWrapperStyle, minWidth: width }}>
                        {element}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return ReactDOM.createPortal(TableElement, parent);
}
