import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "../../button";

import AppContainer from "../../../container";
import { IUserService, UserService } from "../../../services/user.service";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";

import "./merge-profiles-dialog.scss";

interface Props {
  onMerge?: CallableFunction;
  resource: string;
  selectedUsers: any[];
  tagId: string;
}

export default function MergeProfilesDialog(props: Props) {
  const userService: IUserService = AppContainer.get(UserService);
  const { setSnackbar } = useSnackbar();
  const { closeModal } = useModal();
  const [userId, setUserId] = useState<string>("");

  const { onMerge, selectedUsers, tagId } = props;

  const handleMerge = () => {
    const ghostUser = selectedUsers.find((user) => user.id !== userId);

    userService.linkUsers(ghostUser.id, userId).then(() => {
      setSnackbar({
        message: "Travelers merged!",
        variant: "success"
      });

      onMerge && onMerge();

      closeModal();
    });
  };

  const ghostProfileNames = selectedUsers
    .filter((u) => u.id !== userId)
    .map((u) => u.name);

  const user = userId ? selectedUsers.find((u) => u.id === userId) : null;

  return (
    <Dialog
      classes={{ root: "merge-profiles-dialog" }}
      open={true}
      onClose={() => closeModal()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id={`form-dialog-title-${tagId}`}
        classes={{ root: "dialog-title" }}
      >
        Merge Profiles
      </DialogTitle>
      <DialogContent classes={{ root: "dialog-content" }}>
        <div className="merge-profiles-dialog__subtitle">
          Select which profile you would like to preserve.
        </div>
        <div className="profile-buttons">
          {selectedUsers.map((user) => (
            <Button
              key={user.id}
              alignment="align-center"
              color={userId === user.id ? "product-blue" : "gray"}
              isBordered={true}
              isFullWidth={true}
              isTransparent={true}
              label={`${user.name} - Created: ${new Date(
                user.createdAt
              ).toLocaleDateString()}`}
              onClick={() => setUserId(user.id)}
              size="medium"
            />
          ))}
        </div>

        {userId ? (
          <div className="merge-warning">
            <br />
            <small></small>
            All existing and future travel found for{" "}
            {ghostProfileNames.join(", ")} will be merged to the Tripgrid
            profile {user.name}. {ghostProfileNames.join(", ")} will be removed
            from your workspace.
          </div>
        ) : null}

        <DialogActions classes={{ root: "dialog-actions" }}>
          <Button
            color="gray"
            isFullWidth={true}
            isTransparent={true}
            label="Close"
            onClick={() => closeModal()}
            size="medium"
          />

          <Button
            color="product-blue"
            isFullWidth={true}
            label="Merge"
            isDisabled={!userId}
            isTransparent={!userId}
            onClick={handleMerge}
            size="medium"
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
