import React, { useRef, useState } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "../../../button";
import ProfileAddForm from "../forms/profile-add-form";

import UserDialogTitle from "../user-dialog-title";
import { UserDialogTabProps } from "../";

export default function ProfileAddTab(props: UserDialogTabProps) {
  const { menuPortalTarget, onFormChange, onSave } = props;
  const formRef = useRef();

  const [formValid, setFormValid] = useState(false);

  const titleElement = <div>Create new profile</div>;

  return (
    <React.Fragment>
      <UserDialogTitle {...props} title={titleElement} />
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <div className="user-dialog__content">
          <ProfileAddForm
            ref={formRef}
            editing={true}
            menuPortalTarget={menuPortalTarget}
            onFormChange={onFormChange}
            onSave={onSave}
            onValidate={setFormValid}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="product-blue"
          isDisabled={!formValid}
          label="Create Profile"
          onClick={() => {
            formRef.current && (formRef.current as any).save();
          }}
          size="medium"
        />
      </DialogActions>
    </React.Fragment>
  );
}
