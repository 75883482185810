import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { withRouter, RouteComponentProps, useParams } from "react-router-dom";

import { AppActionTypes } from "../../../../context/reducer";
import AppContainer from "../../../../container";
import AppIcon from "../../../../components/app-icon";
import { AppPageProps } from "../../../../router";
import Button from "../../../../components/button";
import {
  LoadState,
  ObjectHash,
  sortByPosition
} from "../../../../utils/helpers";
import Sortable from "../../../../components/sortable";
import TemplateEditorMenu from "./template-editor-menu";
import TemplateModel from "../../../../models/template.model";
import TemplatePropertyModel from "../../../../models/template-property.model";
import Tooltip from "../../../../components/tooltip";
import useApp from "../../../../hooks/use-app.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";
import {
  WorkspaceService,
  IWorkspaceService
} from "../../../../services/workspace.service";

import "../../settings-page.scss";

interface Props extends AppPageProps, RouteComponentProps {}

const getHeader = (templateType: string): string => {
  switch (templateType) {
    case "accommodation":
      return "Accommodations";
    case "event":
      return "Events";
    case "flight":
      return "Flights";
    case "profile":
      return "Traveler Profiles";
    case "rail":
      return "Rail";
    case "transportation":
      return "Car Rental / Transfers";
  }

  return "";
};

const TemplateEditor = (props: Props) => {
  const history = useHistory();
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );
  const { setSnackbar } = useSnackbar();

  const { company, templates } = props;
  const { templateType } = useParams<{ templateType: string }>();
  const { dispatch } = useApp();

  const [loadState, setLoadState] = useState<LoadState>("unloaded");
  const [template, setTemplate] = useState<TemplateModel | null>(null);

  const [sortableState, setSortableState] = useState({
    disabled: false,
    key: 0
  });

  const handleUpdateTemplateProperty = async (
    propId: string | null,
    propPayload: ObjectHash
  ) => {
    setSortableState({ ...sortableState, disabled: true });

    const updatedProp: TemplatePropertyModel | null = propId
      ? await workspaceService.updateTemplateProperty(propId, propPayload)
      : await workspaceService.createTemplateProperty(propPayload);

    if (!updatedProp || !template) {
      return;
    }

    const updatedTemplate: TemplateModel | null = await workspaceService.getUserDefinedTemplateById(
      template.id
    );

    if (updatedTemplate) {
      // regenerate the template with the update props
      const wsTemplate = workspaceService.makeWorkspaceTemplate(
        updatedTemplate,
        company
      );

      dispatch({
        type: AppActionTypes.UpdateTemplate,
        payload: wsTemplate
      });

      setTemplate(wsTemplate);

      let newSortableElKey = sortableState.key;
      setSortableState({ disabled: false, key: ++newSortableElKey });

      setSnackbar({
        message: propId ? "Template updated!" : "Template created!",
        variant: "success"
      });
    }
  };

  useEffect(() => {
    if (loadState !== "unloaded") {
      return;
    }

    const templateName = templateType === "event" ? "activity" : templateType;
    const template = templates.find(
      (template: TemplateModel) => template.name === templateName
    );

    setTemplate(template || null);
    setLoadState("loaded");
  }, [loadState, templates, templateType]);

  if (!template) {
    return null;
  }

  const { properties } = template;
  const editableTemplateProps = properties.filter(
    (prop: TemplatePropertyModel) => !prop.isProtected()
  );

  /*
   * Update the row that was just moved with the position of the row it was dropped on
   * and let the API sort out updating positions of other rows.
   */
  const handleSortChange = (
    itemId: string,
    newIndex: number,
    oldIndex: number
  ) => {
    if (!template) {
      return;
    }

    const targetProp = editableTemplateProps[newIndex];

    handleUpdateTemplateProperty(itemId, {
      position: targetProp.position
    });
  };

  const nonSortableElements = properties
    .filter((prop: TemplatePropertyModel) => prop.isProtected())
    .map((prop: TemplatePropertyModel, index: number) => {
      const { friendlyName, name } = prop;
      return (
        <div className="settings-page__property-wrapper readonly" key={index}>
          <AppIcon type="lock" color="gray" />
          <span className="settings-page__property-name">
            {friendlyName || name}
          </span>
          <TemplateEditorMenu
            disabled={true}
            templateProp={prop}
            onUpdateTemplateProperty={() => {}}
            userDefinedTemplate={template.id}
          />
          <div className="settings-page__property__button-group">
            <Button
              color="product-blue"
              icon="visible"
              isDisabled={true}
              isRippleDisabled={true}
              isRounded={true}
              isTransparent={true}
              size="medium"
            />
          </div>
        </div>
      );
    });

  const sortableElements = editableTemplateProps
    .sort(sortByPosition)
    .map((templateField: TemplatePropertyModel, index: number) => {
      const { friendlyName, id, isHidden, name } = templateField;

      return {
        id,
        element: (
          <div className="settings-page__property-wrapper">
            <AppIcon type="drag-handle" color="gray" />
            <span className="settings-page__property-name">
              {friendlyName || name}
            </span>
            <TemplateEditorMenu
              templateProp={templateField}
              onUpdateTemplateProperty={(payload: ObjectHash) =>
                handleUpdateTemplateProperty(id, payload)
              }
              userDefinedTemplate={template.id}
            />
            <div className="settings-page__property__button-group">
              {isHidden ? (
                <Tooltip text="Unhide Field">
                  <Button
                    color="red"
                    icon="visible-strike"
                    isDisabled={sortableState.disabled}
                    isRippleDisabled={true}
                    isRounded={true}
                    isTransparent={true}
                    onClick={() =>
                      handleUpdateTemplateProperty(id, {
                        isHidden: false
                      })
                    }
                    size="medium"
                  />
                </Tooltip>
              ) : (
                <Tooltip text="Hide Field">
                  <Button
                    color="product-blue"
                    icon="visible"
                    isDisabled={sortableState.disabled}
                    isRippleDisabled={true}
                    isRounded={true}
                    isTransparent={true}
                    onClick={() =>
                      handleUpdateTemplateProperty(templateField.id, {
                        isHidden: true
                      })
                    }
                    size="medium"
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )
      };
    });

  const header = getHeader(templateType);

  return (
    <div className="settings-subpage page-container">
      <div className="settings-subpage-breadcrumb">
        <p
          className="settings-subpage-breadcrumb__link"
          onClick={() => history.push("/settings")}
        >
          Settings
        </p>
        <AppIcon type="nav-arrow-right" />
        <p>{`Configure ${header}`}</p>
      </div>
      <h1 className="settings-page__header">{`Configure ${header}`}</h1>
      <div className="settings-page__section-wrapper --with-border">
        <div className="settings-page__header-flex">
          <h2 className="settings-page__sub-header">{`${header} Template`}</h2>
          <TemplateEditorMenu
            onUpdateTemplateProperty={(payload: ObjectHash) =>
              handleUpdateTemplateProperty(null, payload)
            }
            userDefinedTemplate={template.id}
          />
        </div>
        <div>{nonSortableElements}</div>
        <div key={sortableState.key}>
          <Sortable
            disabled={sortableState.disabled}
            items={sortableElements}
            onChange={handleSortChange}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(TemplateEditor);
