import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { cloneDeep } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import AppIcon from "../../app-icon";
import Button from "../../button";
import CloseButton from "../../button/close";
import RemoveButton from "../../button/remove";
import Tooltip from "../../tooltip";

import AppContainer from "../../../container";
import useApp from "../../../hooks/use-app.hook";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import SegmentModel from "../../../models/segment.model";
import TagGroupModel from "../../../models/tag-group.model";
import TagModel from "../../../models/tag.model";
import UserModel from "../../../models/user.model";
import {
  SegmentService,
  ISegmentService
} from "../../../services/segment.service";
import { TripService, ITripService } from "../../../services/trip.service";
import { TagService, ITagService } from "../../../services/tag.service";
import {
  IWorkspaceService,
  TemplateInput,
  WorkspaceService
} from "../../../services/workspace.service";

import {
  getDisplayDate,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";
import {
  getDefaultDate,
  getInputComponent,
  handleDateChange
} from "./helper-funcs";

import "./quick-add-dialog.scss";
import { AppContext } from "../../../context";

const dataTypeLabelHash: ObjectHash = {
  ACTIVITY: "Event",
  AIR: "Flight",
  CAR: "Transportation",
  HOTEL: "Accommodation",
  RAIL: "Rail",
  PROFILE: "Profile"
};

const segmentInputKeys: ObjectHash = {
  AIR: [
    "fromForSearch",
    "name",
    "flightNum",
    "from",
    "fromLocation",
    "to",
    "toLocation"
  ],
  RAIL: ["name", "trainNum", "from", "fromLocation", "to", "toLocation"]
};

const PaperComponent = (props: ObjectHash) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

interface Props {
  data: ObjectHash;
  dataType: string;
  locationsData: any[];
  onChange?: CallableFunction; // callback to update other components when changes are committed inside modal
  tagPreset?: TagModel[];
}

export default function QuickAddDialog(props: Props) {
  const segmentService: ISegmentService = AppContainer.get(SegmentService);
  const tripService: ITripService = AppContainer.get(TripService);
  const tagService: ITagService = AppContainer.get(TagService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const { setSnackbar } = useSnackbar();
  const { settings } = useApp();
  const { closeModal, linkModal, openModal } = useModal();

  const { data, dataType, locationsData, onChange, tagPreset } = props;

  const isNew = !Boolean(data?.id);
  const domRef = useRef();

  const [menuPortalTarget, setMenuPortalTarget] = useState<any>(null);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  useEffect(() => {
    if (!isNew) {
      linkModal("quick-add", { trip: data.id });
    }
  }, [data, isNew, linkModal]);

  const templateInputs = workspaceService.getTemplateInputs(dataType);

  const dataIsFrozen = workspaceService.dataIsFrozen(data);
  const { tagGroups } = settings;

  const zeroState: ObjectHash = {
    id: data?.id || null,
    type: dataType
  };

  templateInputs.forEach((templateInput: TemplateInput) => {
    const { key, inputType, isMultiple } = templateInput;
    let value: null | string | SelectOption[] | string[] = "";

    if (isMultiple) {
      value = null;
    }

    if (inputType === "tag") {
      value = [];
    }

    if (["fromForSearch", "from", "to"].includes(key)) {
      value = getDefaultDate(dataType, key);
    }

    if (!isNew) {
      value = data[key];

      // convert user objects into array of options using name and id
      if (key === "users") {
        value = (data?.users || []).map((user: ObjectHash) => ({
          label: user.name,
          value: user.id,
          meta: new UserModel(user) // @todo remove this casting per #347
        }));
      }

      // separate trip tags out into their tag groups for input pre-fills
      if (inputType === "tag") {
        const { tagGroup } = templateInput;
        if (tagGroup) {
          if (data.tags?.length) {
            value = data.tags
              .filter((tag: TagModel) => tag.tagGroup === tagGroup.id)
              .map((tag: TagModel) => tag.id);
          }
        }
      }
    }

    zeroState[key] = value;
  });

  // load tag preset
  if (tagPreset) {
    tagPreset.forEach((tag: TagModel) => {
      const tagGroup = tagGroups.find(
        (tagGroup: TagGroupModel) => tagGroup.id === tag.tagGroup
      );
      if (!tagGroup) {
        return;
      }
      const fieldId = tagGroup.getFieldId();
      zeroState[fieldId].push(tag.id);
    });
  }

  const [formState, setFormState] = useState({ ...zeroState });

  const updateTags = (options: any, tagGroup: TagGroupModel) => {
    const updatedFormState = cloneDeep(formState);
    updatedFormState[tagGroup.getFieldId()] = options.map(
      (option: ObjectHash) => option.tag.id
    );

    // apply tag presets
    const tagNames = options.map((option: ObjectHash) => option.tag.name);
    const tagUpdates = tagService.getPresetChanges(
      tagGroup,
      tagNames.shift(),
      tagGroups
    );

    tagUpdates.forEach(
      (tagUpdate: { tagGroup: TagGroupModel; tagIds: string[] }) => {
        const { tagGroup } = tagUpdate;
        updatedFormState[tagGroup.getFieldId()] = tagUpdate.tagIds;
      }
    );

    setFormChanged(true);
    setFormState(updatedFormState);
  };

  // switches the inputs shown for adding legs to segmented trips
  const [inputStates, setinputStates] = useState(cloneDeep(templateInputs));

  const requiredInputs = ["name"];
  const formValid = (): boolean => {
    return requiredInputs.every((key: string) => Boolean(formState[key]));
  };

  const resetFormState = () => {
    setFormState(() => ({
      ...zeroState
    }));
  };

  // AIR and RAIL segmented trips, adding leg in dialog mode
  const [segmentedTripState, setSegmentedTripState] = useState<{
    [index: string]: any;
  }>({
    tripId: null,
    segments: []
  });

  const handleClose = (skipConfirm: boolean) => {
    if (formChanged && !skipConfirm && !dataIsFrozen) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "You have unsaved changes. Are you sure you want to close?",
        onConfirm: closeModal
      });
    } else {
      closeModal();
    }
  };

  const handleInputChange = (key: string, value: any, meta?: ObjectHash) => {
    // related dates validation
    if (["from", "fromForSearch", "to"].includes(key)) {
      handleDateChange(key, value, setFormState, formState);
    }

    // When changing fromLocation for transportation, mirror that change to the toLocation
    else if (dataType === "CAR" && key === "fromLocation") {
      setFormState((oldValues) => ({
        ...oldValues,
        fromLocation: value,
        toLocation: value
      }));
    }

    // Set timezones when updating airport departure/destination
    else if (
      dataType === "AIR" &&
      ["fromLocation", "toLocation"].includes(key)
    ) {
      if (key === "fromLocation") {
        setFormState((oldValues: ObjectHash) => ({
          ...oldValues,
          fromLocation: value,
          fromTimezone: meta?.timezoneRegionName || ""
        }));
      } else if (key === "toLocation") {
        setFormState((oldValues: ObjectHash) => ({
          ...oldValues,
          toLocation: value,
          toTimezone: meta?.timezoneRegionName || ""
        }));
      }
    } else if (key === "flightNum") {
      /*
       * When selecting a flight number, populate all other relevant
       * arrival and departure fields based on the flight info
       */
      setFormState((oldValues: ObjectHash) => {
        const updates: ObjectHash = { flightNum: value };

        if (meta) {
          updates.from = meta.from || "";
          updates.fromLocation = meta.fromLocation || "";
          updates.fromTimezone = meta.fromTimezone || "";
          updates.to = meta.to || "";
          updates.toLocation = meta.toLocation || "";
          updates.toTimezone = meta.fromTimezone || "";
        }

        return {
          ...oldValues,
          ...updates
        };
      });
    } else if (["CAR", "HOTEL"].includes(dataType) && key === "name") {
      /*
       * When auto-complete-searching for a car rental or hotel, fill in location
       * fields with the address of the place, and update the name field to be
       * just the name of the place.
       */
      const formUpdates: ObjectHash = {
        name: meta?.name || value,
        supportPhoneNumber: meta?.phone || "",
        fromLocation: meta?.address || ""
      };

      if (dataType === "CAR") {
        formUpdates.toLocation = meta?.address || "";
      }
      setFormState((oldValues) => ({
        ...oldValues,
        ...formUpdates
      }));
    } else {
      setFormState((oldValues) => ({ ...oldValues, [key]: value }));
    }

    setFormChanged(true);
  };

  const getPayload = (): ObjectHash => {
    const payload: ObjectHash = { ...formState, tags: [] };

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      // turn group-separated tag ids back into a single array
      if (TagGroupModel.isFieldId(key)) {
        if (value?.length) {
          payload.tags = payload.tags.concat(value);
        }
        delete payload[key];
      }

      if (key === "users") {
        payload[key] = (value || []).map(
          (option: SelectOption) => option.value
        );
      }
    });

    return payload;
  };

  const handleAddTrip = async () => {
    setFormChanged(false);
    const payload: ObjectHash = getPayload();
    if (segmentedTripState.tripId) {
      // selecting save & close after adding leg for AIR or RAIL trip
      payload.tripId = segmentedTripState.tripId;
      const newSegment: SegmentModel | null = await segmentService.create(
        payload
      );
      if (newSegment) {
        handleClose(true);
      }
    } else {
      tripService.create(payload).then(() => {
        onChange && onChange();
        handleClose(true);
        setSnackbar({
          message: "Trip created!",
          variant: "success"
        });
      });
    }
  };

  const handleUpdateTrip = () => {
    const payload: ObjectHash = getPayload();

    tripService.update(payload.id, payload).then(() => {
      onChange && onChange();
      handleClose(true);
      setSnackbar({
        message: "Trip updated!",
        variant: "success"
      });
    });
  };

  const handleDeleteObjectClick = () => {
    openModal("confirm", {
      dialogTitle: `Delete Trip`,
      dialogBody: `Are you sure you want to delete this trip? This cannot be undone.`,
      onConfirm: () => {
        tripService.delete(data.id).then(() => {
          onChange && onChange();
          handleClose(true);
          setSnackbar({
            message: "Trip deleted!",
            variant: "success"
          });
        });
      }
    });
  };

  const handleAddSegmentClick = async () => {
    const payload: ObjectHash = getPayload();
    if (!segmentedTripState.tripId) {
      // first leg created for AIR or RAIL trip generates the parent trip
      // @todo add type hint after trip model created
      const newTrip = await tripService.create(payload);
      if (newTrip) {
        setSegmentedTripState((oldValues: ObjectHash) => ({
          ...oldValues,
          tripId: newTrip.id,
          segments: [newTrip.segments[0]]
        }));
        const keysToKeep = segmentInputKeys[dataType];

        const newinputStates = inputStates.filter(
          (templateInput: TemplateInput) =>
            keysToKeep.includes(templateInput.key)
        );

        setinputStates(() => newinputStates);
        resetFormState();
        setFormChanged(false);
        onChange && onChange();
        setSnackbar({
          message: "Trip created!",
          variant: "success"
        });
      }
    } else {
      // subsequent legs being added
      payload.tripId = segmentedTripState.tripId;
      const newSegment: SegmentModel | null = await segmentService.create(
        payload
      );
      if (newSegment) {
        const newSegments = segmentedTripState.segments.slice();
        newSegments.push(newSegment);
        setSegmentedTripState((oldValues) => ({
          ...oldValues,
          segments: newSegments
        }));
        resetFormState();
        setFormChanged(false);
        onChange && onChange();
        setSnackbar({
          message: "Leg created!",
          variant: "success"
        });
      }
    }
  };

  const handleDeleteSegmentClick = async (segmentId: string) => {
    const deletedSegment: SegmentModel | null = await segmentService.delete(
      segmentId
    );
    if (deletedSegment) {
      onChange && onChange();
      const newSegments = segmentedTripState.segments.filter(
        (segment: ObjectHash) => segment.id !== segmentId
      );
      setSegmentedTripState((oldValues) => ({
        ...oldValues,
        segments: newSegments
      }));
    }
  };

  const inputElements = inputStates.map((templateInput: TemplateInput) => {
    const { inputType, key, presetExclude } = templateInput;

    if (presetExclude) {
      return <></>;
    }

    const inputOpts = templateInput as ObjectHash;

    if (inputType === "tag") {
      const { tagGroup = new TagGroupModel() } = templateInput;
      inputOpts.tagGroup = tagGroup;

      if (tagGroup.id) {
        inputOpts.selectedTagIds = formState[key]?.length ? formState[key] : [];
      }
      inputOpts.onChange = updateTags;
    }

    return getInputComponent(
      key,
      inputOpts,
      dataType,
      handleInputChange,
      formState,
      locationsData || [], // provides dropdown options of existing vals for static data sets
      menuPortalTarget,
      requiredInputs.includes(key)
    );
  });

  return (
    <AppContext.Consumer>
      {(appState) => (
        <Dialog
          aria-labelledby="form-dialog-title"
          classes={{
            root: "quick-add-dialog quick-add-dialog-standard",
            paper: "paper",
            paperScrollPaper: "paper-scroll-paper",
            paperWidthSm: "paper-width-sm"
          }}
          onClose={() => handleClose(false)}
          onEntered={() => setMenuPortalTarget(domRef.current)}
          open={true}
          PaperComponent={PaperComponent}
          ref={domRef}
        >
          <DialogTitle
            classes={{ root: "dialog-title" }}
            style={{ cursor: "move" }}
          >
            <div className="quick-add-dialog__title">
              <div className="quick-add-dialog__title">
                <h1>
                  {!data
                    ? `Add ${dataTypeLabelHash[dataType]}`
                    : `${dataIsFrozen ? "View" : "Edit"} ${
                        dataTypeLabelHash[dataType]
                      }`}
                </h1>
                {dataIsFrozen && (
                  <div className="quick-add-dialog__lock-indicator">
                    <AppIcon type="lock" size="x-small" />
                    <span>LOCKED</span>
                  </div>
                )}
              </div>
              <div className="quick-add-dialog__button-group">
                {data &&
                  !dataIsFrozen &&
                  (dataType === "PROFILE" ||
                    !appState.user.email?.endsWith("@nfl.com")) && (
                    <Tooltip
                      text={`Delete ${
                        dataType === "PROFILE" ? "User" : "Trip"
                      } ${appState.user.email}`}
                    >
                      <Button
                        color="gray"
                        icon="trash"
                        isRippleDisabled={true}
                        isTransparent={true}
                        onClick={() => handleDeleteObjectClick()}
                        size="medium"
                      />
                    </Tooltip>
                  )}
                <Tooltip text="Close">
                  <CloseButton
                    onClick={() => handleClose(false)}
                    size="medium"
                  />
                </Tooltip>
              </div>
            </div>
          </DialogTitle>
          <DialogContent
            classes={{ root: "dialog-content" }}
            className="not-draggable"
          >
            <div className="quick-add-dialog__form">{inputElements}</div>
            {segmentedTripState.segments.length ? (
              <div className="quick-add-dialog__segment-tracker">
                {segmentedTripState.segments.map((segment: ObjectHash) => (
                  <div
                    key={segment.id}
                    className="quick-add-dialog__segment-wrapper"
                  >
                    <div className="quick-add-dialog__segment-text">
                      <span>{segment.name ? `${segment.name}` : ""}</span>
                      <span>
                        {getDisplayDate(segment.from, "EEEE, MMMM d @ hh:mm a")}
                      </span>
                      <span>{`${segment.fromLocation} to ${segment.toLocation}`}</span>
                    </div>
                    <RemoveButton
                      key={`delete-segment-${segment.id}`}
                      isDisabled={segmentedTripState.segments.length < 2}
                      onClick={() => {
                        handleDeleteSegmentClick(segment.id);
                      }}
                      size="small"
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </DialogContent>
          {!dataIsFrozen && (
            <DialogActions
              classes={{ root: "dialog-actions" }}
              className="quick-add-dialog__actions"
            >
              <Button
                color="product-blue"
                isDisabled={!formChanged || !formValid()}
                isFullWidth={true}
                label={isNew ? "Save & Close" : "Save Changes"}
                onClick={() => (isNew ? handleAddTrip() : handleUpdateTrip())}
                size="medium"
              />

              {isNew && ["AIR", "RAIL"].includes(dataType) && (
                <Button
                  color="product-blue"
                  isDisabled={!formChanged || !formValid()}
                  isFullWidth={true}
                  label="Add Another Leg"
                  onClick={() => handleAddSegmentClick()}
                  size="medium"
                />
              )}
            </DialogActions>
          )}
        </Dialog>
      )}
    </AppContext.Consumer>
  );
}
