import React, { useState } from "react";
import classnames from "classnames";
import AppIcon from "../app-icon";
import "./accordion-group.scss";

interface Props {
  children: JSX.Element | JSX.Element[];
  defaultOpen?: number | number[];
  onGroupChange?: CallableFunction;
  titles: string[];
}

export default function AccordionGroup(props: Props) {
  const { children, defaultOpen = 0, onGroupChange, titles } = props;

  const groupChildren: JSX.Element[] = Array.isArray(children)
    ? children
    : [children];

  const openSection = (openIndex: number) => {
    // closing the open accordion?
    if (sections[openIndex]) {
      openIndex = -1;
    }

    const sectionState = groupChildren.map(
      (child: JSX.Element, index) => index === openIndex
    );
    setSections(sectionState);
  };

  const openTabIndexes = Array.isArray(defaultOpen)
    ? defaultOpen
    : [defaultOpen];

  const [sections, setSections] = useState<Boolean[]>(
    groupChildren.map((child: JSX.Element, index: number) =>
      openTabIndexes.includes(index)
    )
  );

  return (
    <div className="accordion-group">
      {groupChildren.map((child: JSX.Element, index: number) => {
        const open = sections[index];
        return (
          <div
            className={classnames("accordion-section", {
              open
            })}
            key={index}
          >
            <div
              className="header"
              onClick={() => {
                openSection(index);
                if (onGroupChange) {
                  onGroupChange(titles[index]);
                }
              }}
            >
              <div className="title">{titles[index]}</div>
              <div className="expand-button">
                {open && <AppIcon type="menu-arrow-open" />}
                {!open && <AppIcon type="menu-arrow" />}
              </div>
            </div>
            <div className="content">{child}</div>
          </div>
        );
      })}
    </div>
  );
}
