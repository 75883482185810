import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import DateInput from "../../form/inputs/DateInput";

const TravelDateAfterFilter = new TravelFilterModel({
  field: TravelFilterFields.TravelDateAfter,
  name: "After",
  group: TravelFilterGroups.TravelDate,
  resource: TravelFilterResources.Trip,
  component: DateInput
});

export default TravelDateAfterFilter;
