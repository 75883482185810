import React from "react";
import SkeletonLoader from "../skeleton-loader";
import Tooltip from "../tooltip";
import UserProfileIconSkeleton from "./UserProfileIconSkeleton";
import UserModel from "../../models/user.model";
import Colors from "../../styles/colors.module.scss";
import "./user-profile-icon.scss";

const bgColors = Object.entries(Colors)
  .filter(
    ([key]) =>
      [
        "blue",
        "green",
        "orange",
        "primary",
        "purple",
        "red",
        "pink",
        "slateBlue",
        "yellow"
      ].includes(key) && !key.includes("template")
  )
  .map(([, value]) => value)
  .sort();

export interface Props {
  loading?: boolean;
  user: UserModel;
}

const getColorsFromInitials = (initials: string): string => {
  const idx = initials.length
    ? Math.floor(
        ((initials.toUpperCase().charCodeAt(0) - 65) / 26) * bgColors.length
      )
    : 0;

  if (!bgColors[idx]) {
    return "";
  }

  return String(bgColors[idx]);
};

export default function UserProfileIcon(props: Props) {
  const { loading, user } = props;
  const initials = user.getInitials();

  return (
    <SkeletonLoader
      loading={loading}
      skeletonComponent={<UserProfileIconSkeleton />}
    >
      <Tooltip text={user.getFullName()}>
        <div
          className="user-profile-icon"
          style={{ background: getColorsFromInitials(initials) }}
        >
          {initials}
        </div>
      </Tooltip>
    </SkeletonLoader>
  );
}
