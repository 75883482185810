import BaseModel from "./base.model";
import CompanyModel from "./company.model";

export default class AdminSearchUserModel extends BaseModel {
  id: string;
  name: string;
  company: CompanyModel;
  companyId: string;
  email: string;
  hasRecent: boolean;
  hasSockets: number;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.name = data.name;
    this.company = new CompanyModel(data.company);
    this.companyId = data.companyId;
    this.email = data.email;
    this.hasRecent = data.hasRecent;
    this.hasSockets = data.hasSockets;
  }
}
