import React from "react";
import { resolve } from "inversify-react";
import Checkbox from "@material-ui/core/Checkbox";

import AppIcon from "../../components/app-icon";
import Button from "../../components/button";

import { AppPageProps } from "../../router";
import { AuthService, IAuthService } from "../../services/auth.service";
import { withSnackbarContext } from "../../context/snackbar";

import TripgridLetterMark from "../../images/tripgrid-letter-mark.svg";
import "./login-page.scss";
import {
  ILocalStorageService,
  LocalStorageService
} from "../../services/local-storage.service";
import AppBanner from "../../components/app-banner";

interface Props extends AppPageProps {
  setSnackbar: CallableFunction;
}

interface State {
  email: string;
  finished: boolean;
  emailExists: boolean;
  persistLogin: boolean;
  lsAvailable: boolean;
}

class LoginPage extends React.Component<Props, State> {
  @resolve(AuthService)
  private authService!: IAuthService;

  @resolve(LocalStorageService)
  private localStorageService!: ILocalStorageService;

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      finished: false,
      emailExists: false,
      persistLogin: true,
      lsAvailable: true
    };
  }

  componentDidMount() {
    this.setState({ lsAvailable: this.localStorageService.isAvailable() });
  }

  onSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    // prevent pressing enter key from triggering page reload
    if (event) {
      event.preventDefault();
    }

    const response = await this.authService.doLogin(
      this.state.email.toLowerCase(),
      this.state.persistLogin
    );

    this.setState({
      finished: true,
      emailExists: Boolean(response)
    });
  };

  onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };

  onChangePersist = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ persistLogin: e.target.checked });
  };

  onResetForm = () => {
    this.setState({
      email: "",
      finished: false,
      emailExists: false,
      persistLogin: true
    });
  };

  render() {
    const { setSnackbar } = this.props;
    const {
      finished,
      email,
      emailExists,
      persistLogin,
      lsAvailable
    } = this.state;

    return (
      <div className="login-page">
        <div className="login-page-header">
          <div className="login-page-header__branding">
            <img
              alt="tripgrid-logo"
              src="https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg"
            />
            <img alt="tripgrid-letter-mark.svg" src={TripgridLetterMark} />
          </div>
        </div>
        {!finished ? (
          <div className="login-page__form-wrapper">
            <h2>Login to Tripgrid</h2>
            <form className="verification-form" onSubmit={this.onSubmit}>
              <div className="form-input">
                <input
                  autoComplete="off"
                  onChange={this.onChangeEmail}
                  value={email}
                  placeholder="Enter your email"
                  type="email"
                  required
                  disabled={!lsAvailable}
                />
              </div>
              <div className="checkbox-wrapper">
                <Checkbox
                  checked={persistLogin}
                  color="primary"
                  onChange={this.onChangePersist}
                  style={{
                    transform: "scale(1.5)"
                  }}
                />
                <div
                  onClick={() => {
                    this.setState({ persistLogin: !persistLogin });
                  }}
                >
                  Keep me logged in
                </div>
              </div>
              <Button
                color="product-blue"
                label="Login"
                onClick={() => this.onSubmit()}
                size="medium"
                isDisabled={!lsAvailable}
              />
            </form>
            {!lsAvailable && (
              <AppBanner
                type="error"
                message={
                  <span>
                    Local storage is not available. You will not be able to sign
                    in. Please read{" "}
                    <a
                      href="https://help.tripgrid.com/en/articles/6442997-troubleshooting-login-problems"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#fff", textDecoration: "underline" }}
                    >
                      this help article
                    </a>{" "}
                    for troubleshooting information.
                  </span>
                }
              />
            )}
          </div>
        ) : (
          <div className="login-page__form-wrapper">
            {emailExists ? (
              <React.Fragment>
                <AppIcon type="email" size="x-large" color="product-blue" />
                <h2 style={{ fontWeight: "normal", marginBottom: "48px" }}>
                  Check your email
                </h2>
                <p style={{ fontSize: "16px", margin: "auto" }}>
                  An email was sent to <b>{email}</b> to complete login.
                </p>
                <p style={{ marginTop: "32px" }}>
                  Didn't receive it?
                  <span
                    className="link"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      this.onSubmit();
                      setSnackbar({
                        message: "Login email resent!",
                        variant: "success"
                      });
                    }}
                  >
                    Send Again
                  </span>
                  <Button
                    color="product-background-blue"
                    label="Go Back"
                    onClick={() => this.onResetForm()}
                    size="medium"
                  />
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <AppIcon type="error" size="x-large" color="yellow" />
                <h2 style={{ fontWeight: "normal", marginBottom: "48px" }}>
                  Email not recognized
                </h2>
                <p>
                  <b>{email}</b> is not associated with any Tripgrid
                  organization.
                </p>
                <p>
                  Please contact a primary admin from your company to request
                  access.
                </p>
                <p style={{ marginTop: "32px" }}>
                  New to Tripgrid?
                  <a
                    style={{ marginLeft: "8px" }}
                    href="/signup"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Register Now
                  </a>
                </p>
                <Button
                  color="product-background-blue"
                  label="Go Back"
                  onClick={() => this.onResetForm()}
                  size="medium"
                />
              </React.Fragment>
            )}
          </div>
        )}
        <div />
        <div style={{ height: "64px" }} />
      </div>
    );
  }
}

export default withSnackbarContext(LoginPage);
