import { isEqual } from "lodash";
import { DateTime } from "luxon";
import TagModel from "../models/tag.model";
import UserModel from "../models/user.model";

export type DateMeta = {
  date: string;
  hasTime: boolean;
  iso: DateTime | null;
  time: string;
  source: string | number;
};

export type ObjectHash = { [index: string]: any };

export type SelectOption = {
  label: string;
  value: string | boolean;
  meta?: ObjectHash;
};

export type LoadState = "unloaded" | "loading" | "loaded";

export type MuiVariant = "filled" | "standard" | "outlined" | undefined;

export const addUrlQuery = (key: string, value: string) => {
  const qs = getQueryStringParams();
  qs[key] = value;

  const url = window.location.href.split("?").shift();
  window.history.pushState(null, "", `${url}?${makeQueryString(qs)}`);
};

export const airportToIata = (label: string) => {
  if (!label || !label.length) {
    return null;
  }

  let iata = null;
  const t = label || "";

  if (t.length === 3) {
    iata = t;
  } else if (t.length >= 5) {
    const match = t.match(/[^A-z]([A-z]{3})[^A-z]/);
    if (match && Array.isArray(match)) {
      [, iata] = match;
    }
  }

  if (!iata) {
    return null;
  }

  return iata.toUpperCase();
};

export const currencyToFloat = (currencyStr: string) => {
  if (!currencyStr) {
    return 0;
  }

  return parseFloat(currencyStr.replace(/[^0-9.]+/gi, ""));
};

/*
 * Flight numbers are sometimes stored simply as "375" or "1223", but can
 * also be stored as something like "375 - (JAX) 7:50 AM to (DFW) 9:40 AM"
 */
export const getFlightNumber = (val: string): string => {
  if (!val) {
    return "";
  }
  const matches = String(val).match(/^([^-]+) - .+$/);

  if (!matches?.length) {
    return val;
  }

  return String(matches[1]).trim();
};

const parseDate = (
  dateVal: string | number
): { DateISO: DateTime | null; hasTime: boolean; isTimestamp: boolean } => {
  let DateISO: DateTime | null = null;
  let hasTime = false;
  const isTimestamp = typeof dateVal === "number";

  if (isTimestamp) {
    hasTime = true;
    DateISO = DateTime.fromSeconds(dateVal as number);
  } else {
    hasTime = (dateVal as string).includes(":"); // @todo better way to do this?
    DateISO = DateTime.fromISO(dateVal as string);
  }

  return { DateISO, hasTime, isTimestamp };
};

export const dateComesBefore = (
  d1: string | number,
  d2: string | number
): boolean => {
  const date1 = parseDate(d1);
  const date2 = parseDate(d2);

  if (!date1.DateISO?.isValid) {
    return false;
  }

  if (!date2.DateISO?.isValid) {
    return true;
  }

  return date1.DateISO < date2.DateISO;
};

export const getDateMeta = (dateVal: string | number): DateMeta => {
  const { DateISO, hasTime } = parseDate(dateVal);

  if (!DateISO?.isValid) {
    return {
      date: "",
      time: "",
      hasTime: false,
      iso: DateISO,
      source: dateVal
    };
  }

  return {
    date: DateISO ? DateISO.toISODate() : "",
    time: hasTime && DateISO ? DateISO.toFormat("HH:mm") : "",
    hasTime,
    iso: DateISO,
    source: dateVal
  };
};

let localZone = "";
export const setLocalTimezone = (zone: string): void => {
  localZone = zone;
};

export const getLocalTimezone = (): string => localZone;

export const getLocalDateISO = () => {
  const DateObj = DateTime.fromObject({
    zone: getLocalTimezone()
  });

  return DateObj.toISO();
};

export const getDisplayDate = (
  date: string | number,
  format: string
): string => {
  if (!date) {
    return "";
  }

  let DateISO: DateTime;

  if (typeof date === "number") {
    DateISO = DateTime.fromSeconds(date);
  } else if (date === "now") {
    DateISO = DateTime.local();
  } else {
    DateISO = DateTime.fromISO(date);
  }

  if (!DateISO?.isValid) {
    console.warn("invalid date", date);
    return "";
  }

  return DateISO.toFormat(format);
};

export const getDisplayTime = (time: string, format?: string): string => {
  if (!time) {
    return "";
  }
  const date = DateTime.local().toFormat("yyyy-MM-dd");
  return getDisplayDate(`${date}T${time}`, format || "h:mma");
};

export const getObjectDiff = (obj1: ObjectHash, obj2: ObjectHash) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const getStringCompareSortValue = (
  aVal: string,
  bVal: string,
  direction: string
): number => {
  if (aVal === bVal) {
    return 0;
  }

  const compVal = aVal.localeCompare(bVal);

  if (direction === "desc") {
    return compVal * -1;
  }
  return compVal;
};

export const getTimestamp = (date?: string): number => {
  const isoDate = date ? DateTime.fromISO(date) : DateTime.local();
  return isoDate.isValid ? Number(isoDate.toFormat("X")) : 0;
};

export const sortByStringCompare = (
  a: ObjectHash,
  b: ObjectHash,
  sort: { column: ObjectHash; direction: "asc" | "desc" }
): number => {
  const { column, direction } = sort;
  const { id } = column;

  const aVal = String(a[id]);
  const bVal = String(b[id]);

  return getStringCompareSortValue(aVal, bVal, direction);
};

// https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export const getQueryStringParams = (qs?: string): ObjectHash => {
  if (!qs) {
    qs = window.location.search.substring(1);
  }

  let match,
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = (s: string) => decodeURIComponent(s.replace(pl, " "));
  const urlParams: ObjectHash = {};
  while ((match = search.exec(qs))) {
    urlParams[decode(String(match[1]))] = decode(String(match[2]));
  }

  return urlParams;
};

export const getUrlHashParams = (): ObjectHash => {
  const url = window.location.href;

  if (url.indexOf("#") === -1) {
    return {};
  }

  const hashVal = url.split("#").pop();

  if (!hashVal) {
    return {};
  }

  return getQueryStringParams(hashVal);
};

export const makeQueryString = (params: {
  [index: string]: string;
}): string => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const isTagModel = (model: any): model is TagModel => {
  return model instanceof TagModel;
};

export const isUserModel = (model: any): model is UserModel => {
  return model instanceof UserModel;
};

export const removeUrlQuery = (key: string) => {
  const qsObj = getQueryStringParams();
  delete qsObj[key];
  const hasQs = Boolean(Object.keys(qsObj).length);

  const url = window.location.href.split("?").shift();
  window.history.pushState(
    null,
    "",
    `${url}${hasQs ? "?" : ""}${makeQueryString(qsObj)}`
  );
};

export const sortByPosition = (a: any, b: any) => {
  if (a.postion === b.position) {
    return 0;
  }

  return a.position < b.position ? -1 : 1;
};

export const sortByCreatedAt = (a: any, b: any) => {
  if (a.createdAt === b.createdAt) {
    return 0;
  }

  return a.createdAt < b.createdAt ? 1 : -1;
};

export const stripAndTrim = (str: string = "", chr: string) => {
  if (!str || !chr) {
    return null;
  }
  return str
    .split(chr)
    .filter((s) => s.trim().length > 0)
    .join(chr)
    .trim();
};

export const toPrice = (str: string = "") => {
  if (!str) {
    return null;
  }
  return `$${Number(str.replace(/[^0-9.]/g, "")).toFixed(2)}`;
};

// https://www.petermorlion.com/iterating-a-typescript-enum/
export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

/*
 * React-select components which are rendered inside MUI Dialogs/Menus are sometimes portaled
 * to document.body rather than the Dialog/Menu (i.e. menuPortalTarget={document.body}), to
 * allow them to overflow their parent. This is handy when a select may have many options and/or
 * appear in a small Menu/Dialog. However, this means the select will not scroll with its parent
 * when a Dialog/Menu has an scrollable child. This function allows the select to scroll itself,
 * and closes the select to prevent visual bugs when anything else scrolls.
 *
 * WARNING: You MUST also add the prop classNamePrefix="tg-input" to any react-select which uses
 * this function.
 */
export const reactSelectCloseOnScroll = (event: any) => {
  const classList = event.target?.classList
    ? Array.from(event.target.classList) // converts event.target DOMTokenList[] into standard array
    : [];

  // Cannot leverage includes on DOMTokenList[] https://developer.mozilla.org/en-US/docs/Web/API/Element/classList

  return (
    !classList.includes("tg-input__menu-list") &&
    !classList.includes("autocomplete-input__menu-list")
  );
};

/*
 * The AutocompleteInputFilled component (which is used as a lower-level input component
 * for various select inputs) requires value/defaultValue: SelectOption[]. These values are
 * frequently stored as plain strings on the related models. This function converts the string
 * values given by the models to SelectOption[] as an interface convenience.
 */
export const getSelectValueFromOptions = (
  props: ObjectHash,
  options: SelectOption[]
): ObjectHash => {
  const valueProps: ObjectHash = {};
  const { defaultValue, value } = props;

  if (typeof value === "string") {
    valueProps.value = options.filter(
      (opt: SelectOption) => opt.value === value
    );
  } else if (typeof defaultValue === "string") {
    valueProps.defaultValue = options.filter(
      (opt: SelectOption) => opt.value === defaultValue
    );
  }

  return valueProps;
};

export const getSelectValueProps = (props: ObjectHash): ObjectHash => {
  const valueProps: ObjectHash = {};
  const { defaultValue, isSearchable, value } = props;

  const isControlled = props.hasOwnProperty("value");
  const hasDefault = !isControlled && props.hasOwnProperty("defaultValue");

  if (isControlled) {
    let controlOpt = value;

    // convert string to SelectOption automatically as a convenience feature
    if (typeof controlOpt === "string") {
      controlOpt = { label: controlOpt, value: controlOpt };
    }

    valueProps.value = controlOpt;

    if (isSearchable) {
      valueProps.inputValue = controlOpt.value;
    }
  }

  if (hasDefault) {
    let defaultOpt = defaultValue;

    // convert string to SelectOption automatically as a convenience feature
    if (typeof defaultOpt === "string") {
      defaultOpt = { label: defaultOpt, value: defaultOpt };
    }

    valueProps.defaultValue = defaultOpt;
  }

  return valueProps;
};

export const getTextValueProps = (props: ObjectHash): ObjectHash => {
  const valueProps: ObjectHash = {};
  const { defaultValue, value } = props;

  const isControlled = props.hasOwnProperty("value");
  const hasDefault = !isControlled && props.hasOwnProperty("defaultValue");

  if (isControlled) {
    valueProps.value = value;

    // edge case for non-standard template types being set as text fields (i.e. Encana's airport fields)
    if (value && value[0] && value[0].value) {
      valueProps.value = value[0].value;
    }
  }

  if (hasDefault) {
    valueProps.defaultValue = defaultValue;
  }

  return valueProps;
};
