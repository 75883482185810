import React, { RefObject, useEffect, useState } from "react";

import Button from "../../../button";
import { createLog, getLogByGridId } from "../lib/service";
import GridModel from "../../../../models/grid.model";
import { LoadState } from "../../../../utils/helpers";
import { TmlActionTypes } from "../context/reducer";
import TmlEntries from "../tml-entries";
import { TmlLogModel } from "../lib/models";
import TripModel from "../../../../models/trip.model";
import useModal from "../../../../hooks/use-modal.hook";
import { useTmlLog } from "../context";

import "./big-tile.scss";

interface Props {
  grid: GridModel;
  refs: {
    dialog: RefObject<HTMLElement>;
    tile: RefObject<HTMLElement>;
  };
}

export default function TmlApp(props: Props) {
  const { grid, refs } = props;
  const { openModal } = useModal();
  const { dispatch, log } = useTmlLog();

  const [loadState, setLoadState] = useState<LoadState>("unloaded");

  useEffect(() => {
    if (loadState !== "unloaded") {
      return;
    }

    setLoadState("loading");

    getLogByGridId(grid.id).then((log: TmlLogModel | null) => {
      if (!log) {
        return;
      }

      const { entries, settings } = log;

      // log settings are valid
      if (settings.isValid()) {
        // entries exist, go ahead and load
        if (entries.length) {
          dispatch({ type: TmlActionTypes.LoadLog, payload: log });
          setLoadState("loaded");
          return;
        }

        // no entries, try to create them
        createLog(grid, settings).then(
          (response: {
            log: TmlLogModel | null;
            tripsByUser: Map<string, TripModel[]>;
          }) => {
            const { log } = response;

            if (log) {
              dispatch({ type: TmlActionTypes.LoadLog, payload: log });
              setLoadState("loaded");
            }
          }
        );

        return;
      }

      setLoadState("loaded");
    });
  }, [dispatch, grid, loadState, setLoadState]);

  const loaded = loadState === "loaded";
  const validSettings = Boolean(log?.settings.isValid());
  const showEntries = loaded && validSettings;
  const showInvalid = loaded && !validSettings;

  return (
    <div id="tml-big-tile">
      <div className="title">Traveler Movement</div>
      {showEntries && <TmlEntries grid={grid} refs={refs} />}
      {showInvalid && (
        <div className="invalid-settings">
          <div className="description">
            You haven't configured your movement log yet.
          </div>
          <div className="button">
            <Button
              label="Configure"
              size="large"
              color="product-blue"
              onClick={() =>
                openModal("grid-app-tml-edit-settings", {
                  log,
                  onSave: () => setLoadState("unloaded")
                })
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
