// https://slides.com/djanoskova/react-context-api-create-a-reusable-snackbar#/6/3

import React from "react";
import MuiSnackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import useSnackbar from "../../hooks/use-snackbar.hook";
import AppIcon, { AppIconType } from "../app-icon";
import Colors from "../../styles/colors.module.scss";

const variantIcon: { [index: string]: AppIconType } = {
  success: "success",
  warning: "warning",
  error: "error",
  info: "info",
  waiting: "stopwatch"
};

const backgroundColor = {
  success: Colors.green,
  warning: Colors.yellow,
  error: Colors.red,
  info: Colors.productBlue,
  waiting: Colors.productBlue
};

export type SnackbarVariant =
  | "error"
  | "info"
  | "success"
  | "warning"
  | "waiting";

export interface Props {
  duration?: number | null;
  message: string;
  variant: SnackbarVariant;
}

export default function Snackbar(props: Props) {
  const { setSnackbar } = useSnackbar();

  const { message, variant } = props;
  const appIconType = variantIcon[variant] || "success";

  let duration: number | null;

  if (variant === "waiting") {
    duration = 45 * 1000; // set a high max as a failsafe, rather than being left open indefinitely
  } else {
    duration = props.duration !== undefined ? props.duration : 3000; // allows custom duration or null setting for persisted snackbars
  }

  const handleClose = (event: any, context?: any) => {
    if (variant === "waiting" && context === "clickaway") {
      return;
    }

    setSnackbar({ message: "" }); // snackbar reset to empty string message
  };

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={duration}
      open={Boolean(message.length)} // snackbar is hidden when the message is set to empty string
      onClose={handleClose}
    >
      <SnackbarContent
        message={
          <React.Fragment>
            <AppIcon type={appIconType} />
            <span>{message}</span>
          </React.Fragment>
        }
        style={{ backgroundColor: `${backgroundColor[variant]}` }}
      />
    </MuiSnackbar>
  );
}
