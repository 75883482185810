import React from "react";
import { resolve } from "inversify-react";

import {
  ITravelQueryService,
  TravelQueryService
} from "../../services/travel-query.service";

import { TravelTypeKeys } from "../../models/travel-filter.model";
import GridModel from "../../models/grid.model";
import TravelQueryModel from "../../models/travel-query.model";

import ObjectTable from "../object-table";
import { AppContext } from "../../context";

import { ObjectHash } from "../../utils/helpers";
import { withModalContext } from "../../context/modal";

interface Props {
  grid?: GridModel;
  onChange?: CallableFunction;
  openModal: CallableFunction;
  travelQuery: TravelQueryModel;
  travelType: TravelTypeKeys; // @todo merge into travelQuery prop once search supports and/or and grouping
  useDataFreezeDate?: boolean;
}

interface State {}

const tableTitleMap: ObjectHash = {
  activity: "Events",
  air: "Flights",
  car: "Transportation",
  hotel: "Accommodations",
  rail: "Rail"
};

class TravelQueryTable extends React.Component<Props, State> {
  @resolve(TravelQueryService)
  travelQueryService!: ITravelQueryService;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  editTravel = (trip: ObjectHash) => {
    const { onChange, openModal } = this.props;

    const quickAddType = ["AIR", "RAIL"].includes(trip.type)
      ? "quick-add-large"
      : "quick-add";

    openModal(quickAddType, {
      data: trip,
      dataType: trip.type,
      onChange: () => {
        onChange && onChange();
      }
    });
  };

  render() {
    const { grid, travelQuery, travelType, useDataFreezeDate } = this.props;
    const searchParams = this.travelQueryService.getSearchServiceParams(
      travelQuery,
      useDataFreezeDate
    );

    searchParams.resource = "trips";
    searchParams.filters = [
      ...searchParams.filters,
      ["type", travelType.toUpperCase()]
    ];
    searchParams.viewStateKey = `trips-${travelType}`;

    if (grid) {
      searchParams.viewStateKey = `${searchParams.viewStateKey}-grid-${grid.id}`;
    }

    const tableTitle = tableTitleMap[travelType.toLowerCase()];

    // we do not currently support using toLocation/fromLocation filters when the type is not "air"
    if (travelType !== "air") {
      searchParams.filters = searchParams.filters.filter(
        ([key]: [string]) => !["toLocation", "fromLocation"].includes(key)
      );
    }

    return (
      <AppContext.Consumer>
        {(appState) => (
          <ObjectTable
            grid={grid}
            onQuickAddTravel={() => {
              const { onChange } = this.props;
              onChange && onChange();
            }}
            renderOpts={{
              showTagCol: true,
              showQuickAdd: true,
              useCardStyle: true,
              customRowClick: (trip: ObjectHash) => {
                this.editTravel(trip);
              }
            }}
            searchOpts={searchParams}
            templateName={travelType.toLowerCase()}
            title={tableTitle}
            templates={appState.templates}
            company={appState.company}
            settings={appState.settings}
            user={appState.user}
          />
        )}
      </AppContext.Consumer>
    );
  }
}

export default withModalContext(TravelQueryTable);
