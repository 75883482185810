import React from "react";
import classnames from "classnames";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

interface Props {
  children: JSX.Element;
  left: number;
  pinned?: boolean;
  width: number;
}

export default function Pane(props: Props) {
  const { children, left, pinned = false, width } = props;

  return (
    <div className={classnames("pane", { pinned })} style={{ left, width }}>
      {pinned && children}
      {!pinned && <PerfectScrollbar>{children}</PerfectScrollbar>}
    </div>
  );
}
