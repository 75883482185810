import React from "react";
import Button from "@material-ui/core/Button";
import ClipboardJS from "clipboard";

import ParsedEmailModel from "../../../models/parsed-email.model";
import env from "../../../utils/env";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import useTravel from "../../../hooks/use-travel.hook";

import EmailParsingBigTile from "./big-tile";
import ParsedEmail from "../../parsed-email";
import Tooltip from "../../tooltip";
import Tile, { TileProps } from "../tile";

import "./tile.scss";
import { AppContext } from "../../../context";

export default function EmailParsingTile(props: TileProps) {
  const { grid } = props;
  const { openModalElement } = useModal();
  const { setSnackbar } = useSnackbar();

  const emailForwardAddress = grid.getEmailForAppDomain(env.mailboxDomain);
  new ClipboardJS(".email-copy-button");

  const emails = useTravel().parsedEmails.filter(
    (email) =>
      email.gridId === grid.id &&
      new Date().getTime() - new Date(email.updatedAt).getTime() <
        24 * 60 * 60 * 1000
  );

  new ClipboardJS(".email-copy-button");

  const bigTileEl = <EmailParsingBigTile grid={grid} />;

  const parsingEmails = emails.filter(
    (email: ParsedEmailModel) =>
      (email.inReview || !email.finished) && !email.error && !email.canceled
  );

  const parsedEmails = emails.filter(
    (email: ParsedEmailModel) => email.finished || email.error || email.canceled
  );

  if (!emailForwardAddress) {
    return null;
  }

  return (
    <AppContext.Consumer>
      {(appState) =>
        appState.user.email?.endsWith("@nfl.com") ? null : (
          <Tile title="Email Parsing" bigTileEl={bigTileEl}>
            <div className="email-parsing-tile">
              <div className="header">Copy Forward Address</div>
              <div className="description">
                Add travel to your grid by forwarding in confirmation emails.
              </div>
              <div className="header">Grid Address</div>
              <Tooltip text="Copy forward address">
                <Button
                  className="email-copy-button"
                  data-clipboard-text={emailForwardAddress}
                  onClick={() =>
                    setSnackbar({
                      message: "Email address copied!",
                      variant: "success"
                    })
                  }
                >
                  {emailForwardAddress}
                </Button>
              </Tooltip>
              {[parsingEmails, parsedEmails].map((emailsToRender, index) => {
                if (!emailsToRender.length) {
                  return null; // skip render of section if no emails fall in category
                }

                const emailElements = emailsToRender.map((email) => {
                  return (
                    <div
                      onClick={() => {
                        openModalElement(bigTileEl);
                      }}
                    >
                      <ParsedEmail
                        key={`parsed-email-${email.id}`}
                        email={email}
                        gridId={grid.id}
                      />
                    </div>
                  );
                });

                return (
                  <div key={index} className="email-parsing-tile__email-group">
                    <div className="header">
                      {index === 0 ? "Parsing" : "Parsed"}
                    </div>
                    {emailElements.map((email) => email)}
                  </div>
                );
              })}
            </div>
          </Tile>
        )
      }
    </AppContext.Consumer>
  );
}
