import { ObjectHash } from "../../../utils/helpers";

export type BlockTypes =
  | "image"
  | "miscellaneous"
  | "print"
  | "table"
  | "text"
  | "traveler";

type ImageBlockSubTypes = "image" | "map";

type MiscellaneousBlockSubTypes = "attachment";

type PrintBlockSubTypes = "break";

type TextBlockSubTypes = "h1" | "p";

type TableBlockSubTypes =
  | "air"
  | "arrival"
  | "car"
  | "custom"
  | "departure"
  | "event"
  | "ground-transportation"
  | "hotel"
  | "profile"
  | "rail";

type TravelerBlockSubTypes = "schedule";

export type BlockSubTypes =
  | ImageBlockSubTypes
  | MiscellaneousBlockSubTypes
  | PrintBlockSubTypes
  | TableBlockSubTypes
  | TextBlockSubTypes
  | TravelerBlockSubTypes;

export type Block = {
  blockType: BlockTypes;
  blockSubType: BlockSubTypes;
  events?: ObjectHash[]; // specific to schedule block
  filters?: ObjectHash;
  id: string;
  options: ObjectHash;
  notes?: ObjectHash; // specific to schedule block
  value?: string | ObjectHash;
  templateName?: "activity" | "air" | "car" | "hotel" | "profile" | "rail";
};

// MultiBlock types are limited to simpler blocks
export type MultiBlockChildTypes = "image" | "miscellaneous" | "text";

export type MultiBlockSubTypes = "header";

export type MultiBlockChildBlock = {
  blockType: MultiBlockChildTypes;
  blockSubType: MultiBlockSubTypes;
  id: string;
  options: ObjectHash;
  value?: string | ObjectHash;
};

export type MultiBlock = {
  blockType: "multi";
  blockSubType: MultiBlockSubTypes;
  blocks: MultiBlockChildBlock[];
  id: string;
  options: ObjectHash;
};

export const isMultiBlock = (
  block: Block | MultiBlock
): block is MultiBlock => {
  return !!(block as MultiBlock).blocks;
};

export const getBlockTypes = () =>
  [
    {
      blockType: "multi",
      blockSubType: "header",
      blocks: [
        {
          blockType: "text",
          blockSubType: "p",
          options: {
            alignSelf: "flex-start",
            isBold: false,
            isItalic: false,
            isUnderlined: false,
            isLargerFontSize: false,
            textAlign: "left"
          },
          value: ""
        },
        {
          blockType: "text",
          blockSubType: "h1",
          options: {
            alignSelf: "flex-start",
            isBold: false,
            isItalic: false,
            isUnderlined: false,
            isLargerFontSize: false,
            textAlign: "left"
          },
          value: ""
        },
        {
          blockType: "image",
          blockSubType: "image",
          options: {
            alignSelf: "flex-start",
            textAlign: "left",
            width: "100"
          },
          value: ""
        }
      ]
    },
    {
      blockType: "text",
      blockSubType: "h1",
      options: {
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        isLargerFontSize: false,
        textAlign: "left"
      },
      value: ""
    },
    {
      blockType: "text",
      blockSubType: "p",
      options: {
        isBold: false,
        isItalic: false,
        isUnderlined: false,
        isLargerFontSize: false,
        textAlign: "left"
      },
      value: ""
    },
    {
      blockType: "miscellaneous",
      blockSubType: "attachment",
      options: {},
      value: ""
    },
    {
      blockType: "image",
      blockSubType: "image",
      options: {
        textAlign: "left",
        width: "100"
      },
      value: ""
    },
    {
      blockType: "image",
      blockSubType: "map",
      options: {
        textAlign: "left",
        width: "100"
      },
      value: {}
    },
    {
      blockType: "print",
      blockSubType: "break",
      options: {}
    },
    {
      blockType: "traveler",
      blockSubType: "schedule",
      events: [],
      notes: {},
      options: { customEventOrder: {}, futureOnly: false, userId: null }
    },
    {
      blockType: "table",
      blockSubType: "arrival",
      options: {
        columns: [
          ["id", "ID"],
          ["name", "Name"],
          ["arrivalAirline", "Airline"],
          ["arrivalFlightNum", "Flight Number"],
          ["arrivalOrigin", "Arriving From"],
          ["arrivalTime", "Arrival Date/Time"],
          ["arrivalDestination", "Arriving At"],
          ["documentRowMenu", ""]
        ],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        hiddenTrips: [],
        sorters: [["arrivalOrigin", "asc"]],
        title: "Arrivals",
        viewStateKey: "users-traveler"
      }
    },
    {
      blockType: "table",
      blockSubType: "departure",
      options: {
        columns: [
          ["id", "ID"],
          ["name", "Name"],
          ["departureAirline", "Airline"],
          ["departureFlightNum", "Flight Number"],
          ["departureOrigin", "Departing From"],
          ["departureTime", "Departure Date/Time"],
          ["departureDestination", "Departing To"],
          ["documentRowMenu", ""]
        ],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        hiddenTrips: [],
        sorters: [["departureOrigin", "asc"]],
        title: "Departures",
        viewStateKey: "users-traveler"
      }
    },
    {
      blockType: "table",
      blockSubType: "ground-transportation",
      options: {
        columns: [
          ["id", "ID"],
          ["name", "Name"],
          ["arrivalDestination", "Arrival Airport"],
          ["arrivalTime", "Arrival Date/Time"],
          ["arrivalAirline", "Arrival Airline"],
          ["departureOrigin", "Departure Airport"],
          ["departureTime", "Departure Date/Time"],
          ["departureAirline", "Departure Airline"],
          ["hotelAddress", "Hotel Address"],
          ["documentRowMenu", ""]
        ],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        hiddenTrips: [],
        sorters: [],
        title: "Ground Transportation",
        viewStateKey: "users-traveler"
      }
    },
    {
      blockType: "table",
      blockSubType: "custom",
      options: {
        columns: ["Column 1", "Column 2", "Column 3", "documentRowMenu"],
        data: [
          ["", "", ""],
          ["", "", ""],
          ["", "", ""]
        ],
        tableKey: 0,
        title: "Custom Table"
      }
    },
    {
      blockType: "table",
      blockSubType: "event",
      filter: { type: "ACTIVITY" },
      options: {
        columns: [],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        futureOnly: false,
        hiddenTrips: [],
        sorters: [],
        title: "Events",
        viewStateKey: "trips-activity"
      },
      templateName: "activity"
    },
    {
      blockType: "table",
      blockSubType: "air",
      filter: { type: "AIR" },
      options: {
        columns: [],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        futureOnly: false,
        hiddenTrips: [],
        sorters: [
          ["users", "asc"],
          ["from", "asc"]
        ],
        title: "Flights",
        viewStateKey: "trips-air"
      },
      templateName: "air"
    },
    {
      blockType: "table",
      blockSubType: "hotel",
      filter: { type: "HOTEL" },
      options: {
        columns: [],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        futureOnly: false,
        hiddenTrips: [],
        sorters: [],
        title: "Accommodations",
        viewStateKey: "trips-hotel"
      },
      templateName: "hotel"
    },
    {
      blockType: "table",
      blockSubType: "car",
      filter: { type: "CAR" },
      options: {
        columns: [],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        futureOnly: false,
        hiddenTrips: [],
        sorters: [],
        title: "Transportation",
        viewStateKey: "trips-car"
      },
      templateName: "car"
    },
    {
      blockType: "table",
      blockSubType: "rail",
      filter: { type: "RAIL" },
      options: {
        columns: [],
        columnOrder: [],
        customDataOrder: [],
        filters: [],
        futureOnly: false,
        hiddenTrips: [],
        sorters: [
          ["users", "asc"],
          ["from", "asc"]
        ],
        title: "Rail",
        viewStateKey: "trips-rail"
      },
      templateName: "rail"
    },
    {
      blockType: "table",
      blockSubType: "profile",
      options: {
        columns: [],
        columnOrder: [],
        customDataOrder: [],
        sorters: [],
        title: "Profiles",
        users: [],
        viewStateKey: "users-profile"
      },
      templateName: "profile"
    }
  ].slice();

export const blockTypesHash: ObjectHash = {
  "Basic Blocks": [
    {
      label: "Document Header",
      description: "Add a document header.",
      iconType: "view-column",
      type: "multi",
      subType: "header"
    },
    {
      label: "Text Header",
      description: "Add a text header.",
      iconType: "title",
      type: "text",
      subType: "h1"
    },
    {
      label: "Text",
      description: "Add a text block.",
      iconType: "notes",
      type: "text",
      subType: "p"
    },
    {
      label: "Attachment",
      description: "Add an attachment.",
      iconType: "attachment",
      type: "miscellaneous",
      subType: "attachment"
    },
    {
      label: "Image",
      description: "Add an image.",
      iconType: "image",
      type: "image",
      subType: "image"
    },
    {
      label: "Map",
      description: "Add an interactive map.",
      iconType: "map",
      type: "image",
      subType: "map"
    },
    {
      label: "Custom Table",
      description: "Add a customizable table for miscellaneous data.",
      iconType: "table",
      type: "table",
      subType: "custom"
    },
    {
      label: "Page Break",
      description: "Add a page break for print mode.",
      iconType: "agenda",
      type: "print",
      subType: "break"
    },
    {
      label: "Profile Table",
      description: "Add a customizable table of user profiles.",
      iconType: "contacts",
      type: "table",
      subType: "profile"
    }
  ],
  "Traveler Blocks": [
    {
      label: "Schedule",
      description: "Add a customizable schedule or itinerary.",
      iconType: "list",
      type: "traveler",
      subType: "schedule"
    },
    {
      label: "Arrival Table",
      description: "Add a customizable table of traveler arrivals.",
      iconType: "flight-land",
      type: "table",
      subType: "arrival"
    },
    {
      label: "Departure Table",
      description: "Add a customizable table of traveler departures.",
      iconType: "flight-takeoff",
      type: "table",
      subType: "departure"
    },
    {
      label: "Ground Transportation Table",
      description:
        "Add a customizable table of traveler ground transportation.",
      iconType: "transfer-station",
      type: "table",
      subType: "ground-transportation"
    }
  ],
  "Travel Blocks": [
    {
      label: "Flight Table",
      description: "Add a customizable table of all your grid's flights.",
      iconType: "airplane",
      type: "table",
      subType: "air"
    },
    {
      label: "Transportation Table",
      description:
        "Add a customizable table of all your grid's transportation.",
      iconType: "car",
      type: "table",
      subType: "car"
    },
    {
      label: "Hotel Table",
      description: "Add a customizable table of all your grid's hotels.",
      iconType: "hotel",
      type: "table",
      subType: "hotel"
    },
    {
      label: "Rail Table",
      description: "Add a customizable table of all your grid's rail.",
      iconType: "train",
      type: "table",
      subType: "rail"
    },
    {
      label: "Event Table",
      description: "Add a customizable table of all your grid's events.",
      iconType: "event",
      type: "table",
      subType: "event"
    }
  ]
};
