import React from "react";
import { resolve } from "inversify-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

import AppIcon from "../../components/app-icon";
import Loader from "../../components/loader";

import { AppPageProps } from "../../router";
import { AuthService, IAuthService } from "../../services/auth.service";
import { UserService, IUserService } from "../../services/user.service";

import TripgridLetterMark from "../../images/tripgrid-letter-mark.svg";
import "./registration-page.scss";

interface Props extends AppPageProps, RouteComponentProps {}

interface State {
  company?: any;
  finished: boolean;
  isInvite: boolean;
  loading: boolean;
  policyConsent: boolean;
  user?: any;
}

class RegistrationPage extends React.Component<Props, State> {
  @resolve(AuthService)
  private authService!: IAuthService;

  @resolve(UserService)
  private userService!: IUserService;

  constructor(props: Props) {
    super(props);

    this.resendLoginEmail = this.resendLoginEmail.bind(this);

    this.state = {
      company: props.company || {},
      finished: false,
      isInvite: false,
      loading: false,
      policyConsent: false,
      user: props.user || {}
    };
  }

  componentDidMount() {
    const { params }: any = this.props.match;
    this.setState({ isInvite: !!params.token });
  }

  async register(e: any) {
    if (e && e.preventDefault instanceof Function) {
      e.preventDefault();
    }

    this.setState({ loading: true });

    const payload: any = {
      companyName: this.state.company.name,
      userEmail: this.state.user.email,
      userName: this.state.user.name
    };

    if (this.state.isInvite) {
      const { params }: any = this.props.match;
      payload.inviteId = params.id;
      payload.inviteToken = params.token;
    }

    payload.redirectTo = window.location.origin;

    // @todo how do we know when this fails?
    const registerRedirectLink = await this.userService.register(payload);

    if (registerRedirectLink) {
      window.location.href = registerRedirectLink;
      return;
    }

    this.setState({ finished: true, loading: false });

    return false;
  }

  resendLoginEmail() {
    const email = this.state.user.email;
    if (!email) {
      return;
    }

    this.authService.doLogin(email, false);
  }

  render() {
    const {
      company,
      finished,
      isInvite,
      loading,
      policyConsent,
      user = {}
    } = this.state;

    return (
      <div className="registration-page">
        <div className="registration-page-header">
          <div className="registration-page-header__branding">
            <img
              alt="tripgrid-logo"
              src="https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg"
            />
            <img alt="tripgrid-letter-mark.svg" src={TripgridLetterMark} />
          </div>
        </div>
        {finished ? (
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <AppIcon type="email" size="x-large" color="product-blue" />
            <h2 style={{ fontWeight: "normal", marginBottom: "48px" }}>
              Check your email
            </h2>
            <p style={{ fontSize: "16px", margin: "auto" }}>
              An email was sent to <b>{user.email}</b>. Please confirm your
              email address to complete login.
            </p>
            <p style={{ marginTop: "32px" }}>
              Didn't receive it?
              <span
                className="link"
                style={{ marginLeft: "8px" }}
                onClick={this.resendLoginEmail}
              >
                Send Again
              </span>
            </p>
          </div>
        ) : (
          <div>
            <h2 style={{ fontWeight: "normal", marginBottom: "48px" }}>
              Create your account
            </h2>
            <form
              className="registration-form"
              onSubmit={this.register.bind(this)}
            >
              <div className="form-input">
                <input
                  autoComplete="off"
                  onChange={(e) => {
                    user.name = e.target.value;
                    this.setState({ user });
                  }}
                  placeholder="Full Name"
                  defaultValue={user.name}
                  required
                />
              </div>
              <div className="form-input">
                <input
                  autoComplete="off"
                  defaultValue={company.name}
                  disabled={isInvite}
                  onChange={(e) => {
                    company.name = e.target.value;
                    this.setState({ company });
                  }}
                  placeholder="Company Name"
                  required
                />
              </div>
              <div className="form-input">
                <input
                  autoComplete="off"
                  disabled={isInvite}
                  onChange={(e) => {
                    user.email = e.target.value;
                    this.setState({ user });
                  }}
                  placeholder="Work Email"
                  defaultValue={user.email}
                  type="email"
                  required
                />
              </div>
              <div className="checkbox-wrapper">
                <Checkbox
                  checked={policyConsent}
                  color="primary"
                  disabled={finished}
                  onChange={() =>
                    this.setState({ policyConsent: !policyConsent })
                  }
                />
                <p>
                  {`I agree to the Tripgrid `}
                  <a
                    href="https://www.tripgrid.com/legal/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  {` and `}
                  <a
                    href="https://www.tripgrid.com/legal/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              {loading ? (
                <Loader type="dots" />
              ) : (
                <button
                  className={policyConsent ? "" : "disabled"}
                  disabled={!policyConsent}
                  type="submit"
                >
                  Register
                </button>
              )}
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(RegistrationPage);
