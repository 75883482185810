import { inject } from "inversify";
import { ApiService, IApiService } from "./api.service";
import BaseService from "./base.service";
import env from "../utils/env";
import { ObjectHash } from "../utils/helpers";

export class GeoLocation {
  address: string;
  description: string;
  name: string;
  phone: string;
  placeId: string;

  constructor(o: ObjectHash) {
    this.address = o.address ?? "";
    this.description = o.description ?? "";
    this.name = o.name ?? "";
    this.phone = o.phone ?? "";
    this.placeId = o.placeId ?? "";
  }
}

export interface IGeoService {
  getLocations(queryString: string): Promise<GeoLocation[]>;

  getLocationDetails(placeId: string): Promise<GeoLocation | null>;
}

export class GeoService extends BaseService implements IGeoService {
  @inject(ApiService)
  apiService!: IApiService;

  async getLocations(queryString: string): Promise<GeoLocation[]> {
    const uri = window.encodeURIComponent(
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?key=${env.google.apiKey}&input=${queryString}`
    );

    const response: ObjectHash = await this.apiService.get(
      `/forward?uri=${uri}`
    );

    if (response?.predictions) {
      return response.predictions
        .filter((prediction: ObjectHash) => prediction?.description)
        .map((prediction: ObjectHash) => {
          const {
            description = "",
            place_id = "",
            structured_formatting = {}
          } = prediction || {};
          const { main_text = "", secondary_text = "" } = structured_formatting;

          return new GeoLocation({
            address: secondary_text,
            description,
            name: main_text,
            placeId: place_id
          });
        });
    } else {
      return [];
    }
  }

  async getLocationDetails(placeId: string): Promise<GeoLocation | null> {
    const uri = window.encodeURIComponent(
      `https://maps.googleapis.com/maps/api/place/details/json?placeid=${placeId}&key=${env.google.apiKey}`
    );

    const response: ObjectHash = await this.apiService.get(
      `/forward?uri=${uri}`
    );

    if (response?.result) {
      const {
        formattedAddress = "",
        formattedPhoneNumber = "",
        name = ""
      } = response.result;
      return new GeoLocation({
        address: formattedAddress,
        description: formattedAddress,
        name,
        phone: formattedPhoneNumber,
        placeId
      });
    }

    return null;
  }
}
