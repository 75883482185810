import { inject } from "inversify";
import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import { IWorkspaceService, WorkspaceService } from "./workspace.service";
import TripModel from "../models/trip.model";
import { ObjectHash } from "../utils/helpers";

export interface ITripService {
  create(data: ObjectHash): Promise<any>;
  delete(tripId: string): Promise<any>;
  getById(
    tripId: string,
    loadTemplateFields?: boolean
  ): Promise<TripModel | null>;
  update(tripId: string, data: ObjectHash): Promise<any>;
}

export class TripService extends BaseService implements ITripService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(WorkspaceService)
  private workspaceService!: IWorkspaceService;

  async create(data: ObjectHash): Promise<any> {
    const response = await this.apiService.post(`/trips`, data);

    if (!response) {
      return null;
    }

    return response;
  }

  async delete(tripId: string): Promise<any> {
    const response = await this.apiService.delete(`/trips/${tripId}`);
    if (!response) {
      return null;
    }

    return response;
  }

  async getById(
    tripId: string,
    loadTemplateFields?: boolean
  ): Promise<TripModel | null> {
    const response = await this.apiService.get(`/trips/${tripId}`);

    if (!response) {
      return null;
    }

    let trip = new TripModel(response);

    if (loadTemplateFields) {
      trip = this.workspaceService.addModelTemplateProps(
        trip,
        response,
        trip.getTemplateName()
      ) as TripModel;
    }

    return trip;
  }

  async update(tripId: string, data: ObjectHash): Promise<any> {
    const response = await this.apiService.put(`/trips/${tripId}`, data);

    if (!response) {
      return null;
    }

    return response;
  }
}
