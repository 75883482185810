import React, { useRef, useState } from "react";
import classnames from "classnames";
import { debounce } from "lodash";

import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import { Filter } from "../../helpers";

import "./keyword-search-input.scss";

interface Props {
  shrinkDirection?: "left" | "right";
  shrink?: boolean;
  onChange: CallableFunction;
  placeholder?: string;
}

export default function KeywordSearchInput(props: Props) {
  const { shrink, shrinkDirection = "left", onChange, placeholder } = props;
  const [open, setOpen] = useState<boolean>(!shrink);
  const [keyword, setKeyword] = useState<string>("");
  const searchInput = useRef<HTMLInputElement>(null);

  const handleChange = debounce((value: string) => {
    const filter: Filter = {
      fieldId: "keyword",
      inputType: "text",
      value
    };

    onChange(value, filter);
  }, 500);

  const inputElement = (
    <input
      onChange={(event: any) => {
        const value = event.target.value;
        setKeyword(value); // can't go in handleChange because debouncing it would degrade input UI performance
        handleChange(value);
      }}
      placeholder={placeholder || ""}
      ref={searchInput}
      value={keyword}
    />
  );

  return (
    <div
      className={classnames("keyword-search-input", shrinkDirection, {
        open
      })}
    >
      {shrinkDirection === "left" && inputElement}
      <div
        className="icon"
        onClick={() => {
          if (!shrink) {
            return;
          }
          const openState = !open;
          setOpen(openState);

          if (openState && searchInput) {
            searchInput.current?.focus(); // autofocus input on click to expand
          }

          if (!openState) {
            setKeyword("");
            handleChange("");
          }
        }}
      >
        <AppIconFrame>
          <AppIcon color="white" size="x-small" type="search" />
        </AppIconFrame>
      </div>
      {shrinkDirection === "right" && inputElement}
    </div>
  );
}
