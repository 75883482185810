import React from "react";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import timezonesJSON from "timezones.json";

import Loader from "../../loader";

import {
  getSelectValueProps,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

const namedTimezones: ObjectHash = {};
timezonesJSON
  .filter((z) => z.utc && z.utc.length)
  .forEach((z) => {
    z.utc.forEach((u) => {
      namedTimezones[u.toLowerCase().replace(/[^a-z]/g, "")] = u;
    });
  });

interface Props {
  closeMenuOnScroll?: any; // can be bool or func
  isRequired?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: any;
  value?: any[];
}

export default function TimezoneAsyncInputFilled(props: Props) {
  const {
    closeMenuOnScroll,
    isRequired,
    label,
    menuPortalTarget,
    onChange
  } = props;

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu,
    menuPortal,
    option
  };

  const getTimezones = debounce((inputValue: string, callback: any) => {
    if (!inputValue || inputValue.length < 3) return;
    const term = inputValue.toLowerCase().replace(/[^a-z]/g, "");

    const options = Object.entries(namedTimezones)
      .filter(([name]) => name.includes(term))
      .map(([, value]) => value)
      .map((timezone) => ({
        label: timezone,
        value: timezone
      }));

    callback(options);
  }, 300);

  const getOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      return Promise.resolve([] as SelectOption[]);
    }
    return getTimezones(inputValue, callback);
  };

  return (
    <div className="autocomplete-input--filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncSelect
        {...getSelectValueProps(props)}
        cacheOptions
        className="autocomplete-input autocomplete-input--async"
        closeMenuOnScroll={closeMenuOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        loadOptions={getOptions}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No timezones found" : null
        }
        onChange={(option: any) => onChange(option?.value)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
