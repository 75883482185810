import React from "react";
import Select, { MenuPlacement } from "react-select";
import classnames from "classnames";

import AppIcon from "../../app-icon";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import { ObjectHash, reactSelectCloseOnScroll } from "../../../utils/helpers";
import Tooltip from "../../tooltip";
import TagModel from "../../../models/tag.model";
import TagGroupModel from "../../../models/tag-group.model";

import Colors from "../../../styles/colors.module.scss";
import "./inputs.scss";

interface Props {
  tagGroup: TagGroupModel;
  onChange: CallableFunction;
  selectedTagIds?: string[];
  label?: string;
  menuPlacement?: MenuPlacement;
  menuPortalTarget?: HTMLElement | null;
  isDisabled?: boolean;
  multiSelect?: boolean; // manual override (default is to match tag group)
  allowUnconfirmed?: boolean; // whether or not to show unconfirmed tags as options (default is no)
}

export default function TagInput(props: Props) {
  const {
    allowUnconfirmed,
    isDisabled,
    label,
    menuPlacement,
    menuPortalTarget,
    onChange,
    selectedTagIds,
    tagGroup
  } = props;
  const { tags } = tagGroup;
  const multiSelect =
    typeof props.multiSelect === "boolean"
      ? props.multiSelect
      : tagGroup.multiSelect;

  const { menu, option, menuPortal } = customStyle;

  const optionStyles: any = {
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "white",
      borderWidth: "2px",
      cursor: "pointer",
      height: "100%",
      minHeight: "40px",
      paddingRight: "8px"
    }),
    menu,
    multiValue: (
      styles: any,
      { data }: { data: { label: string; value: string; tag: TagModel } }
    ) => {
      const { tag } = data;
      const chipColors = tag.getChipColors();

      return {
        ...styles,
        backgroundColor: chipColors.backgroundColor,
        borderRadius: "4px",
        border: `2px solid ${chipColors.borderColor}`,
        boxSizing: "border-box",
        color: chipColors.color,
        height: "24px",
        ".tag-input-option-wrapper": {
          color: chipColors.color,
          height: "100%"
        }
      };
    },
    multiValueLabel: (styles: ObjectHash) => ({
      ...styles,
      color: Colors.productDarkBlue,
      fontSize: "14px",
      padding: "0 6px 0 8px !important"
    }),
    multiValueRemove: (styles: ObjectHash, { data }: { data: ObjectHash }) => {
      const { tag } = data;
      const chipColors = tag.getChipColors();

      return {
        ...styles,
        borderRadius: "0 3px 3px 0",
        ":hover": {
          backgroundColor: chipColors.color,
          color: chipColors.borderColor
        }
      };
    },
    option: (
      styles: ObjectHash,
      {
        data,
        isDisabled,
        isFocused
      }: {
        data: { label: string; value: string; tag: TagModel };
        isDisabled: boolean;
        isFocused: boolean;
      }
    ) => {
      let backgroundColor = "white";
      const color = Colors.tgBlack;
      if (isDisabled) {
        backgroundColor = Colors.borderGray;
      }
      if (isFocused) {
        backgroundColor = Colors.backgroundGray;
      }

      const { tag } = data;
      const chipColors = tag.getChipColors();

      return {
        ...option(styles),
        backgroundColor,
        color,
        margin: "4px 0",
        transition: "all 0.3s ease-in-out",
        ".tag-input-option-wrapper": {
          border: `2px solid ${chipColors.borderColor}`,
          borderRadius: "4px",
          backgroundColor: chipColors.backgroundColor,
          boxSizing: "border-box",
          color: chipColors.color,
          display: "inline-block",
          fontSize: "14px",
          padding: "0 8px"
        }
      };
    },
    placeholder: (styles: ObjectHash) => ({
      ...styles,
      fontSize: "14px"
    }),
    input: (styles: ObjectHash) => ({
      ...styles,
      display: isDisabled ? "none" : "inline-block"
    }),
    menuPortal
  };

  const options = tags
    .filter((tag: TagModel) => allowUnconfirmed || !tag.unconfirmed)
    .map((tag: TagModel) => {
      return {
        label: tag.name,
        value: tag.id,
        tag
      };
    });

  const values: any[] = [];

  if (selectedTagIds) {
    selectedTagIds.forEach((tagId: string) => {
      const tag: TagModel | undefined = tags.find(
        (tag: TagModel) => tag.id === tagId
      );
      if (!tag || !tag.id) {
        return;
      }
      values.push({
        label: tag.name,
        value: tag.id,
        tag
      });
    });
  }

  return (
    <div
      className={classnames({
        "tag-input-wrapper": true,
        "has-label": Boolean(label)
      })}
    >
      {label && <label className="tag-input__label">{label}</label>}

      <Select
        components={{
          DropdownIndicator: () => <AppIcon type="menu-arrow" />,
          IndicatorSeparator: () => null
        }}
        menuPortalTarget={menuPortalTarget}
        className="tag-input"
        classNamePrefix="tg-input"
        closeMenuOnScroll={reactSelectCloseOnScroll}
        formatOptionLabel={(option: any) => {
          const { tag }: { tag: TagModel } = option;

          /*
           * Although this JSX is duplicated in TagChip, react-select options
           * and values are different enough that it doesn't make sense to use
           * TagChip above with a custom component for Option or MultiValue
           */

          return (
            <div
              className={classnames({
                "tag-input-option-wrapper": true,
                unconfirmed: tag.unconfirmed
              })}
            >
              {tag.unconfirmed && (
                <Tooltip
                  text="This tag does not match any pre-defined tags in your tag group"
                  placement="top"
                >
                  <span>
                    <AppIcon type="error" size="x-small" color="invert-black" />
                  </span>
                </Tooltip>
              )}
              <span>{option.label}</span>
            </div>
          );
        }}
        isClearable={false}
        isDisabled={isDisabled || false}
        isMulti
        onChange={(options: any) => {
          let response = Array.isArray(options) ? [...options] : [];
          if (!multiSelect && options.length > 1) {
            response = [response.pop()];
          }

          onChange(response, tagGroup);
        }}
        options={options}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
        value={values}
      />
    </div>
  );
}
