import React from "react";
import useModal from "../../../../hooks/use-modal.hook";
import Button from "../../../../components/button";
import FutureOnlyMenu from "../../../../components/object-table/table-actions/future-only-menu";
import ScheduleContent from "./schedule-content";
import AutocompleteInput from "../../../../components/form/inputs/AutocompleteInput";
import "./schedule-block.scss";

interface Props {
  id: string;
  uniqueUsers: any[];
  onChangeScheduleBlockUser: CallableFunction;
  onAddScheduleEvent: CallableFunction;
  onApplyFilter: CallableFunction;
  onChangeTableBlockFutureOnly: CallableFunction;
  block: any;
  locationsData: any;
  onAddScheduleNote: CallableFunction;
  onChangeScheduleEventOrder: CallableFunction;
  onChangeScheduleEventHide: CallableFunction;
  onChangeScheduleEvent: CallableFunction;
  onDeleteScheduleEvent: CallableFunction;
  onDeleteScheduleEventOrder: CallableFunction;
  printMode: boolean;
  scheduleTrips: any[];
  updateStaticResults: CallableFunction;
  viewMode: boolean;
}

export default function ScheduleBlock(props: Props) {
  const {
    block,
    id,
    onAddScheduleEvent,
    onAddScheduleNote,
    onApplyFilter,
    onChangeScheduleBlockUser,
    onChangeScheduleEvent,
    onChangeScheduleEventOrder,
    onChangeScheduleEventHide,
    onChangeTableBlockFutureOnly,
    onDeleteScheduleEvent,
    onDeleteScheduleEventOrder,
    printMode,
    scheduleTrips,
    updateStaticResults,
    uniqueUsers,
    viewMode
  } = props;

  const { options } = block;

  const defaultUser =
    uniqueUsers.find((user) => user?.id === options.userId) ?? {};

  const { openModal } = useModal();

  return (
    <React.Fragment>
      <div className="schedule-block-actions">
        <span className="schedule-block-actions__title">Schedule</span>
        <div className="schedule-block-actions__button-group">
          <AutocompleteInput
            defaultValue={
              defaultUser
                ? { label: defaultUser.name, value: defaultUser.id }
                : ""
            }
            isDisabled={viewMode}
            key={`autocomplete-input-${id}-${defaultUser.name ?? "name"}`}
            onChange={onChangeScheduleBlockUser}
            options={[
              { value: null, label: "Schedule Events" },
              ...uniqueUsers
                .map((user) => ({
                  value: user.id,
                  label: user.name
                }))
                .sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
            ]}
          />
          <Button
            color="product-background-blue"
            icon="event"
            label="Add Schedule Event"
            onClick={() => {
              openModal("edit-schedule-event", {
                onAddScheduleEvent: onAddScheduleEvent
              });
            }}
            size="medium"
          />
          <FutureOnlyMenu
            futureOnly={options.futureOnly || false}
            onApplyFilter={onApplyFilter}
            onChangeTableBlockFutureOnly={onChangeTableBlockFutureOnly}
            customDataOrder={[]}
            onResetCustomDataOrder={() => {}}
          />
        </div>
      </div>
      <ScheduleContent
        customEventOrder={props.block.options.customEventOrder || {}}
        printViewHides={props.block.options.printViewHides || []}
        events={block.events}
        futureOnly={options.futureOnly || false}
        locationsData={props.locationsData}
        notes={block.notes}
        onAddScheduleNote={onAddScheduleNote}
        onChangeScheduleEventOrder={onChangeScheduleEventOrder}
        onChangeScheduleEvent={onChangeScheduleEvent}
        onChangeScheduleEventHide={onChangeScheduleEventHide}
        onDeleteScheduleEvent={onDeleteScheduleEvent}
        onDeleteScheduleEventOrder={onDeleteScheduleEventOrder}
        printMode={printMode}
        trips={scheduleTrips}
        updateStaticResults={updateStaticResults}
        viewMode={viewMode}
      />
    </React.Fragment>
  );
}
