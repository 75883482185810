import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MuiButton from "@material-ui/core/Button";

import AppIcon, { AppIconType } from "../app-icon";
import Button from "../button";

import {
  DocumentService,
  IDocumentService
} from "../../services/document.service";
import DocumentModel from "../../models/document.model";

import "./suggested-documents.scss";
import AppContainer from "../../container";

const documents: {
  title: string;
  name: string;
  description: string;
  icon: AppIconType;
}[] = [
  {
    title: "Arrival & Departure Manifest",
    name: "[DEFAULT] Arrival & Departure Manifest",
    description: "Arrival and Departure flights for every traveler.",
    icon: "flight-land"
  },
  {
    title: "Team Itinerary",
    name: "[DEFAULT] Team Itinerary",
    description: "A complete travel itinerary for your entire team.",
    icon: "people"
  },
  {
    title: "Individual Itinerary",
    name: "[DEFAULT] Individual Itinerary",
    description: "A personalized travel schedule for any traveler.",
    icon: "person"
  },
  {
    title: "Production Book",
    name: "[DEFAULT] Production Book",
    description: "A plug and play production book with travel.",
    icon: "video-cam"
  }
];

interface Props {
  gridId?: string;
  onCreate?: CallableFunction;
}

const SuggestedDocuments: FC<Props> = (props: Props) => {
  const documentService: IDocumentService = AppContainer.get(DocumentService);

  const [defaultTemplates, setDefaultTemplates] = useState<DocumentModel[]>([]);
  const [open, setOpen] = useState(true);

  const history = useHistory();

  useEffect(() => {
    documentService.getDefaultTemplates().then((response) => {
      setDefaultTemplates(response);
    });
  }, [documentService]);

  const { onCreate, gridId } = props;

  return defaultTemplates.length ? (
    <div
      className={`suggested-documents__card ${
        open ? "" : " suggested-documents__card--closed"
      }`}
    >
      <div className="suggested-documents__header">
        <h2>Suggested Document Templates</h2>
        {open ? (
          <Button
            color="black"
            icon="nav-arrow-up"
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => setOpen(false)}
            size="small"
          />
        ) : (
          <Button
            color="black"
            icon="nav-arrow-down"
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => setOpen(true)}
            size="small"
          />
        )}
      </div>
      <div className="suggested-documents__options">
        {documents
          .filter((document) =>
            defaultTemplates
              .map((defaultDoc: { [index: string]: any }) => defaultDoc.name)
              .includes(document.name)
          )
          .map((document) => {
            return (
              <MuiButton
                key={document.title}
                className="suggested-document-option"
                disableRipple={true}
                onClick={async () => {
                  const templateId = (
                    defaultTemplates.find(
                      (template: DocumentModel) =>
                        template.name === document.name
                    ) || {}
                  ).id;
                  if (templateId) {
                    const newDocument: DocumentModel | null = await documentService.createFromDefaultTemplate(
                      templateId,
                      {
                        name: document.title,
                        grid: gridId
                      }
                    );
                    if (newDocument) {
                      onCreate && onCreate();
                      history.push(`/documents/${newDocument.id}/build`);
                    }
                  }
                }}
              >
                <div className="document-icon-container">
                  <AppIcon type={document.icon} />
                </div>
                <div className="suggested-documents__option-text">
                  <h3>{document.title}</h3>
                  <p>{document.description}</p>
                </div>
              </MuiButton>
            );
          })}
      </div>
    </div>
  ) : null;
};

export default SuggestedDocuments;
