import merge from "lodash/merge";
import { createTheme } from "@material-ui/core/styles";
import { ObjectHash } from "../utils/helpers";

import Colors from "./colors.module.scss";

const defaultTheme = createTheme();

// theme overrides for Material UI components
const muiOverrides: ObjectHash = {
  MuiCheckbox: {
    colorPrimary: {
      "&$checked": {
        color: Colors.productBlue
      }
    },
    root: {
      "&$checked": {
        color: Colors.productBlue
      }
    }
  },
  MuiDialog: {
    root: {
      borderRadius: "8px"
    },
    paper: {
      position: "absolute",
      margin: "0"
    },
    paperScrollPaper: {
      maxHeight: "calc(100% - 48px)"
    }
  },
  MuiDialogActions: {
    root: {
      margin: "8px 24px 16px"
    }
  },
  MuiDialogContent: {
    root: {
      overflowY: "visible",
      padding: "0 48px"
    }
  },
  MuiFilledInput: {
    root: {
      backgroundColor: "transparent",
      borderRadius: "4px",
      border: `2px solid ${Colors.borderGray}`,
      "&:hover": {
        backgroundColor: Colors.backgroundGray
      }
    },
    input: {
      padding: "32px 12px 12px"
    },
    inputMultiline: {
      minHeight: "20px"
    },
    multiline: {
      padding: "32px 12px 10px"
    }
  },
  MuiFormControl: {
    root: {
      marginBottom: "0"
    }
  },
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: Colors.productBlue
      }
    }
  },
  MuiIconButton: {
    root: {
      padding: "8px"
    }
  },
  MuiInput: {
    root: {
      "&$disabled": {
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    underline: {
      "&::after": {
        borderColor: Colors.productBlue
      }
    }
  },
  MuiInputBase: {
    fullWidth: {
      height: "100%"
    }
  },
  MuiOutlinedInput: {
    adornedEnd: {
      paddingRight: "8px" // offset to match react select icons
    },
    root: {
      height: "40px",
      minHeight: "40px",
      "& $notchedOutline": {
        borderWidth: "2px"
      },
      "&:hover $notchedOutline": {
        borderColor: Colors.borderGray
      },
      "&$disabled": {
        "&:hover": {
          backgroundColor: "transparent"
        }
      },
      "&$focused $notchedOutline": {
        borderColor: Colors.borderGay
      }
    },
    input: {
      padding: "16px"
    },
    multiline: {
      height: "auto",
      "& $notchedOutline": {
        borderWidth: "2px"
      }
    }
  },
  MuiPaper: {
    elevation2: {
      border: `2px solid ${Colors.borderGray}`,
      borderRadius: "8px",
      boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.08)"
    }
  },
  MuiSkeleton: {
    rect: {
      borderRadius: "4px"
    }
  },
  MuiSnackbar: {
    root: {},
    anchorOriginBottomCenter: {
      marginBottom: "32px"
    }
  },
  MuiSnackbarContent: {
    root: {
      borderRadius: "500px !important",
      overflow: "hidden",
      flexGrow: 0,
      padding: "0 16px"
    },
    message: {
      display: "flex",
      alignItems: "center",
      "& .icon": {
        paddingRight: "16px"
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: "14px",
      backgroundColor: Colors.black
    }
  }
};

// theme overrides for mui-datatables
const muiDataTableOverrides: ObjectHash = {
  MUIDataTable: {
    paper: {
      backgroundColor: "inherit",
      borderRadius: "8px",
      boxShadow: "none !important",
      marginLeft: "16px",
      overflow: "hidden",
      paddingRight: "0 !important"
    },
    responsiveBase: {
      overflowY: "hidden"
    },
    responsiveScrollFullHeight: {
      overflow: "auto"
    },
    tableRoot: {
      borderCollapse: "separate",
      outline: "none"
    }
  },
  MUIDataTableBodyCell: {
    root: {
      padding: "4px 16px",
      position: "relative",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "& >div": {
        padding: "0",
        display: "flex",
        flexGrow: "1",
        alignItems: "center"
      },
      "&:hover": {
        cursor: "pointer"
      }
    }
  },
  MUIDataTableBodyRow: {
    root: {
      height: "49px", // fixes 1 pixel offset in doc table for row menu
      backgroundColor: "white",
      "&.mui-row-selected": {
        backgroundColor: `${Colors.backgroundGray}`,
        "&:hover": {
          backgroundColor: `${Colors.backgroundGray}`,
          filter: "brightness(0.95)"
        }
      },
      "&:hover": {
        backgroundColor: `${Colors.backgroundGray} !important`,
        "& .object-table__document-row-menu": {
          opacity: "1 !important",
          visibility: "visible !important"
        }
      }
    }
  },
  MUIDataTableFilter: {
    root: {
      padding: "16px 0 16px 24px"
    },
    textFieldRoot: {
      flexDirection: "column"
    }
  },
  MUIDataTableFilterList: {
    root: {
      marginBottom: "0"
    },
    chip: {
      margin: "8px 8px 0 0"
    }
  },
  MUIDataTableFooter: {
    root: {
      "& td": {
        borderBottom: "none",
        height: "48px",
        padding: "4px 16px"
      }
    }
  },
  MUIDataTableHeadCell: {
    root: {
      fontSize: "14px",
      padding: "4px 16px",
      "&:hover": {
        "& .object-table__document-column-menu": {
          opacity: "1 !important",
          visibility: "visible !important"
        }
      }
    },
    data: {
      whiteSpace: "nowrap"
    },
    fixedHeader: {
      backgroundColor: "white",
      left: "auto",
      position: "inherit",
      whiteSpace: "nowrap",
      zIndex: "auto"
    },
    sortLabelRoot: {
      height: "auto"
    }
  },
  MUIDataTableHeadRow: {
    root: {
      backgroundColor: "transparent",
      height: "48px"
    }
  },
  MUIDataTablePagination: {
    navContainer: {
      height: "48px"
    },
    tableCellContainer: {
      borderBottom: "none"
    },
    toolbar: {
      height: "48px",
      minHeight: "48px"
    }
  },
  MUIDataTableSelectCell: {
    root: {
      "@media (max-width: 959.95px)": {
        display: "table-cell"
      }
    },
    checkboxRoot: {
      padding: "6px",
      width: "24px",
      "&$checked": {
        color: Colors.productBlue
      }
    },
    fixedHeader: {
      backgroundColor: "white",
      zIndex: "1"
    },
    fixedLeft: {
      backgroundColor: "inherit",
      padding: "0 6px",
      width: "auto"
    },
    headerCell: {
      width: "24px",
      minWidth: "24px",
      zIndex: "1"
    }
  },
  MUIDataTableToolbar: {
    root: {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      boxSizing: "border-box",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      paddingLeft: "0px",
      paddingRight: "0px",
      position: "inherit",
      "@media (max-width: 600px)": {
        minHeight: "56px"
      },
      "@media (min-width: 600px)": {
        minHeight: "56px"
      },
      "@media (max-width: 959.95px)": {
        display: "flex !important"
      }
    },
    [defaultTheme.breakpoints.down("xs")]: {
      root: {
        "@media print": {
          display: "flex !important"
        }
      }
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      "@media (max-width: 959.95px)": {
        textAlign: "right !important"
      }
    },
    icon: {
      padding: "8px",
      "&:hover": {
        backgroundColor: "transparent",
        color: Colors.black
      }
    },
    iconActive: {
      padding: "8px",
      color: Colors.darkGray
    },
    left: {
      display: "block",
      "@media (max-width: 595.95px)": {
        paddingTop: "0 !important"
      }
    },
    titleText: {
      fontSize: "20px !important",
      whiteSpace: "nowrap",
      "@media (max-width: 959.95px)": {
        fontSize: "20px !important",
        textAlign: "left !important"
      }
    }
  },
  MUIDataTableToolbarSelect: {
    root: {
      marginLeft: "0",
      minHeight: "40px",
      zIndex: "auto",
      borderRadius: "8px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      paddingBottom: "0",
      paddingTop: "0"
    },
    title: {
      paddingLeft: "0"
    }
  },
  MUIDataTableViewCol: {
    checkboxRoot: {
      "&$checked": {
        color: Colors.productBlue
      }
    },
    label: {
      fontSize: "14px"
    },
    title: {
      color: Colors.textGray,
      fontSize: "16px",
      lineHeight: "1",
      marginLeft: "-4px"
    }
  }
};

export const overrides = merge({}, muiOverrides, muiDataTableOverrides);

export const customTheme = createTheme({
  overrides
});
