import React, { useEffect, useState } from "react";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";
import pluralize from "pluralize";

import Button from "../button";

import AppContainer from "../../container";
import useModal from "../../hooks/use-modal.hook";
import {
  ITravelQueryService,
  TravelQueryService
} from "../../services/travel-query.service";
import GridModel from "../../models/grid.model";
import TravelQueryModel, {
  TravelQueryParam
} from "../../models/travel-query.model";
import {
  TravelFilterFields,
  TravelTypeKeys
} from "../../models/travel-filter.model";

import TravelFilter from "../travel-filter";

import "./travel-filter-menu.scss";

interface Props {
  onChange: CallableFunction;
  grid?: GridModel;
  travelQuery?: TravelQueryModel;
  travelType?: TravelTypeKeys; // @todo merge into travelQuery prop once search supports and/or and grouping
  placement?: PopperPlacementType;
}

export default function TravelFilterMenu(props: Props) {
  const [viewPortWidth, setViewportWidth] = useState(window.outerWidth);
  const menuPortalTarget = document.body; // portals react-select menus to flow outside popper

  const travelQueryService: ITravelQueryService = AppContainer.get(
    TravelQueryService
  );

  const { openModal } = useModal();

  const { grid, onChange, placement = "bottom-end", travelQuery } = props;
  const sourceQuery = grid ? grid.query : travelQuery;

  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedQuery, setUpdatedQuery] = useState<TravelQueryModel | null>(
    null
  );
  const [searchCount, setSearchCount] = useState<number>(0);

  const toggleOpen = (event: any) => {
    if (anchorEl) {
      return handleClose();
    }
    setAnchorEl(event.currentTarget);

    if (sourceQuery) {
      updateSearchCount(sourceQuery);
    }
  };

  const handleChange = async (updatedQuery: TravelQueryModel) => {
    setUpdatedQuery(updatedQuery);
    updateSearchCount(updatedQuery);
  };

  const handleSave = () => {
    onChange(updatedQuery);
  };

  const handleClose = () => {
    setUpdatedQuery(null);
    setAnchorEl(null);
  };

  const updateSearchCount = async (travelQuery: TravelQueryModel) => {
    const { travelType } = props;
    const countQuery = new TravelQueryModel({ ...travelQuery });

    if (travelType) {
      const typeParam = new TravelQueryParam({
        field: TravelFilterFields.Type,
        value: travelType
      });
      countQuery.params.push(typeParam);
    }

    const searchCount = await travelQueryService.getSearchCount(countQuery);
    setSearchCount(searchCount);
  };

  const filterCount = sourceQuery ? sourceQuery.params.length : 0;
  let filterLabel: string = "Filter";
  if (filterCount === 1) {
    filterLabel = "1 Filter applied";
  } else if (filterCount > 1) {
    filterLabel = `${filterCount} Filters applied`;
  }

  const open = Boolean(anchorEl);
  const hasQuery = Boolean(sourceQuery);
  const countStr =
    searchCount === 1
      ? "trip matches the filter"
      : `${pluralize("trip", searchCount)} match the filter`;

  useEffect(() => {
    const checkBrowserWidth = () => setViewportWidth(window.outerWidth);

    window.addEventListener("resize", checkBrowserWidth);

    return () => {
      window.removeEventListener("resize", checkBrowserWidth);
    };
  }, []);

  // dismisses the filter menu when the user resizes browser to prevent nav overlap bug
  useEffect(() => {
    handleClose();
  }, [viewPortWidth]);

  return (
    <div className="travel-filter-menu">
      <div className="travel-filter-trigger" onClick={toggleOpen}>
        <Button
          color={filterCount > 0 ? "product-blue" : "black"}
          isBordered={true}
          isTransparent={filterCount < 1}
          isRippleDisabled={true}
          label={`${filterLabel}`}
          size="medium"
        />
      </div>
      <Popper
        id="travel-filter-popper"
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        <div>
          <TravelFilter
            grid={grid}
            travelQuery={travelQuery}
            menuPortalTarget={menuPortalTarget}
            onChange={handleChange}
            openModal={openModal}
          />
          <div className="buttons">
            <div className="search-count">
              {hasQuery &&
                `${Number(searchCount).toLocaleString()} ${countStr}`}
            </div>
            <Button
              color="gray"
              isTransparent={true}
              label="Cancel"
              onClick={() => handleClose()}
              size="medium"
            />
            <Button
              color="product-blue"
              isDisabled={!updatedQuery}
              label="Apply"
              onClick={() => {
                handleSave();
                handleClose();
              }}
              size="medium"
            />
          </div>
        </div>
      </Popper>
    </div>
  );
}
