import React, { forwardRef } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CloseButton from "../button/close";
import Tooltip from "../tooltip";
import useModal from "../../hooks/use-modal.hook";

import "./big-tile.scss";

interface Props {
  children: JSX.Element;
  onClose?: CallableFunction;
  onEntered?: CallableFunction;
  title: string | JSX.Element;
}

const BigTile = forwardRef((props: Props, ref: any) => {
  const { children, onClose, onEntered, title } = props;
  const { closeModal } = useModal();
  const { dialog, tile } = ref || {
    dialog: null,
    tile: null
  };

  return (
    <Dialog
      classes={{
        root: "grid-big-tile-dialog",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper",
        paperWidthSm: "paper-width-sm"
      }}
      disableEnforceFocus={true}
      open={true}
      onClose={() => closeModal()}
      onEntered={() => {
        onEntered && onEntered();
      }}
      ref={dialog}
    >
      <div className="grid-big-tile__inner" ref={tile}>
        <DialogTitle>
          <div className="grid-big-tile__title">
            {typeof title === "string" ? <h1>{title}</h1> : title}
            <Tooltip text="Close">
              <CloseButton
                onClick={() => {
                  closeModal();
                  onClose && onClose();
                }}
                size="medium"
              />
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </div>
    </Dialog>
  );
});

export default BigTile;
