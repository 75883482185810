import React from "react";
import { debounce } from "lodash";
import AsyncCreatableSelect from "react-select/async-creatable";

import {
  GeoLocation,
  GeoService,
  IGeoService
} from "../../../services/geo.service";

import AppContainer from "../../../container";
import Loader from "../../loader";

import {
  ObjectHash,
  SelectOption,
  getSelectValueProps
} from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  closeMenuOnScroll?: any; // can be bool or func
  isRequired?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  value?: any[];
}

export default function LocationAsyncCreatableInput(props: Props) {
  const geoService: IGeoService = AppContainer.get(GeoService);

  const {
    closeMenuOnScroll,
    isRequired,
    label,
    menuPortalTarget,
    onChange
  } = props;

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu,
    menuPortal,
    option
  };

  const filterLocations = debounce(
    async (inputValue: string, callback: CallableFunction) => {
      if (inputValue.length < 2) {
        return callback([]);
      }
      const locations: GeoLocation[] = await geoService.getLocations(
        inputValue
      );

      const options = locations.map((location: ObjectHash) => ({
        label: location.description,
        value: location.description,
        meta: location
      }));

      return callback(options);
    },
    300
  );

  const getOptions = (inputValue: string, callback: CallableFunction) => {
    if (!inputValue) {
      return Promise.resolve({ options: [] });
    }
    return filterLocations(inputValue, callback);
  };

  const handleChange = async (option: SelectOption) => {
    let meta = option?.meta ?? {};
    let value = option?.value ?? "";
    const { placeId } = meta;

    if (placeId) {
      // Fetch location's details from Google for non-custom value
      const location = await geoService.getLocationDetails(placeId);
      if (location) {
        value = location.description;
        meta = location;
      }
    }

    onChange(value, meta);
  };

  return (
    <div className="autocomplete-input--filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncCreatableSelect
        {...getSelectValueProps({ ...props, isSearchable: true })}
        cacheOptions
        className="autocomplete-input autocomplete-input--async location-async-creatable-input-filled"
        closeMenuOnScroll={closeMenuOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        formatCreateLabel={(label) => `Use custom "${label}"`}
        isSearchable={true}
        loadOptions={getOptions}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No locations found" : null
        }
        onChange={(option: any) => handleChange(option)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
