import React, { useCallback, useEffect, useRef, useState } from "react";
import classnames from "classnames";

import Grid from "./grid";
import {
  ColumnDefaultWidth,
  getStyledColumns,
  RowHeight,
  StyledColumnConfig,
  StyledColumn,
  Column,
  HeadRowHeight,
  Group,
  getGridRows
} from "./helpers";
import { LoadState, ObjectHash } from "../../utils/helpers";
import Pane from "./pane";
import MeasurePortal from "./measure-portal";
import useApp from "../../hooks/use-app.hook";
import useWindowSize from "../../hooks/use-windowsize";

import "./sink.scss";

interface Props {
  columns: Column[];
  data: ObjectHash[];
  group?: Group | null;
  hideGridHead?: boolean;
  onResizeColumn?: (columnId: string, width: number) => void;
  title?: string;
}

export default function Sink(props: Props) {
  const { data, group, hideGridHead, onResizeColumn, title } = props;
  const sinkRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();
  const { settings } = useApp();

  const [columns, setColumns] = useState<StyledColumnConfig>(new Map());
  const [loadState, setLoadState] = useState<LoadState>("unloaded");
  const [pinnedWidth, setPinnedWidth] = useState(0);
  const [mainWidth, setMainWidth] = useState(0);

  const updateColumns = (columns: StyledColumnConfig) => {
    const updatedColumns: Column[] = [];

    Array.from(columns.entries()).forEach(([, column]) => {
      updatedColumns.push(column);
    });

    setColumns(getStyledColumns(updatedColumns));
  };

  const handleMeasure = (columns: StyledColumnConfig) => {
    updateColumns(columns);
    setLoadState("loaded");
  };

  const handleResize = (columnId: string, width: number) => {
    const updatedColumns: StyledColumnConfig = new Map(columns);
    const column = updatedColumns.get(columnId);

    if (column) {
      updatedColumns.set(columnId, { ...column, width });
      updateColumns(updatedColumns);
    }
  };

  const handleResizeEnd = (columnId: string, width: number) => {
    onResizeColumn && onResizeColumn(columnId, width);
  };

  const sizePanes = useCallback(() => {
    if (!sinkRef.current) {
      return;
    }

    let pinnedWidth = 0;

    Array.from(columns.keys()).forEach((key: string) => {
      const column = columns.get(key) || { width: 0, pinned: false };
      const columnWidth = column.width || ColumnDefaultWidth;
      if (column.pinned) {
        pinnedWidth += columnWidth;
      }
    });

    const mainWidth = sinkRef.current.offsetWidth - pinnedWidth;

    setPinnedWidth(pinnedWidth);
    setMainWidth(mainWidth);
  }, [columns, setMainWidth, setPinnedWidth]);

  useEffect(() => {
    setColumns(getStyledColumns(props.columns));
    setLoadState("loading");
  }, [props.columns]);

  // update pane sizes when column sizes change, or when window size changes
  useEffect(() => {
    sizePanes();
  }, [sizePanes, windowSize]);

  const allColumns: StyledColumnConfig = new Map();
  const pinnedColumns: StyledColumnConfig = new Map();
  const mainColumns: StyledColumnConfig = new Map();

  columns.forEach((column: StyledColumn, columnId: string) => {
    const sortedColumn = { ...column };
    if (column.pinned) {
      pinnedColumns.set(columnId, sortedColumn);
    } else {
      sortedColumn.left = sortedColumn.left - pinnedWidth;
      mainColumns.set(columnId, sortedColumn);
    }
    allColumns.set(columnId, sortedColumn);
  });

  const hasTitle = Boolean(title);
  const loaded = loadState === "loaded";
  const rows = getGridRows(data, allColumns, group, { settings });

  return (
    <div className={classnames("sink-wrapper", { hasGroup: Boolean(group) })}>
      {loaded && hasTitle && <div className="sink-title">{title}</div>}
      <div
        className="sink"
        ref={sinkRef}
        style={{
          height: HeadRowHeight + RowHeight * rows.length + 15 // grid height plus room for the scrollbar
        }}
      >
        {loadState === "loading" && (
          <React.Fragment>
            <MeasurePortal
              columns={columns}
              hideGridHead={hideGridHead}
              onMeasure={handleMeasure}
              rows={rows}
            />
          </React.Fragment>
        )}

        {loaded && (
          <React.Fragment>
            <Pane pinned={true} width={pinnedWidth} left={0}>
              <Grid
                rows={rows}
                columns={pinnedColumns}
                hideGridHead={hideGridHead}
                onResize={handleResize}
                pinned={true}
              />
            </Pane>

            <Pane width={mainWidth} left={pinnedWidth}>
              <Grid
                rows={rows}
                columns={mainColumns}
                hideGridHead={hideGridHead}
                onResize={handleResize}
                onResizeEnd={handleResizeEnd}
              />
            </Pane>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
