import React from "react";
import TextField from "@material-ui/core/TextField";
import { getTextValueProps, MuiVariant } from "../../../utils/helpers";
import "./inputs.scss";

interface Props {
  defaultValue?: string;
  isRequired?: boolean;
  label?: string;
  onChange: CallableFunction;
  value?: string;
  variant?: MuiVariant;
}

export default function TimeInput(props: Props) {
  const { isRequired, label, onChange, variant = "" } = props;

  let disableUnderline = {};
  if (["filled", "standard"].includes(variant)) {
    disableUnderline = { disableUnderline: true };
  }

  return (
    <form noValidate style={{ marginLeft: "8px" }}>
      <TextField
        {...getTextValueProps({
          ...props
        })}
        fullWidth
        className="time-input"
        label={label}
        type="time"
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          ...disableUnderline
        }}
        inputProps={{
          step: 300 // 5 min
        }}
        onChange={(e) => onChange(e.target.value)}
        required={isRequired || false}
        variant={variant || "outlined"}
      />
    </form>
  );
}
