import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import Dialog from "@material-ui/core/Dialog";
import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";

import AppContainer from "../../../container";
import Button from "../../button";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import {
  CollectionService,
  ICollectionService
} from "../../../services/collection.service";
import { ObjectHash } from "../../../utils/helpers";
import useApp from "../../../hooks/use-app.hook";
import CloseButton from "../../button/close";
import Tooltip from "../../tooltip";

import "./grid-link.scss";
import ProTip from "../../pro-tip";
import InputController from "../../form/input-controller";
import { TemplateInput } from "../../../services/workspace.service";
import GridModel from "../../../models/grid.model";
import { AppActionTypes } from "../../../context/reducer";

const PaperComponent = (props: ObjectHash) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

interface Props {
  collectionId: string;
}

export default function GridLinkDialog(props: Props) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );

  const history = useHistory();
  const { setSnackbar } = useSnackbar();
  const { closeModal, openModal } = useModal();
  const { dispatch, getGridTagGroup, settings } = useApp();
  const domRef = useRef();

  const [menuPortalTarget, setMenuPortalTarget] = useState<any>(null);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [formState, setFormState] = useState<ObjectHash>({ gridTags: [] });
  const { collectionId } = props;

  const handleClose = () => {
    closeModal();
    history.push(`/collections/${collectionId}`);
  };

  const handleSave = async () => {
    const gridIds: string[] = [];
    const { grids } = settings;

    formState.gridTags.forEach((tagId: string) => {
      const grid = grids.find((grid: GridModel) => grid.tag.id === tagId);
      if (grid) {
        gridIds.push(grid.id);
      }
    });

    const updatedCollection = await collectionService.update(collectionId, {
      grids: gridIds
    });

    if (!updatedCollection) {
      setSnackbar({
        message: "There was a problem and the collection was not updated.",
        variant: "error"
      });
      return;
    }

    dispatch({
      type: AppActionTypes.UpdateCollection,
      payload: updatedCollection
    });

    handleClose();
  };

  const handleChange = (value: ObjectHash[]) => {
    const gridTags = value.map((option: ObjectHash) => option.tag.id);
    setFormState({
      gridTags
    });
    setFormValid(Boolean(gridTags.length));
  };

  const handleCreateGrid = (grid?: GridModel) => {
    if (!grid) {
      return;
    }
    setFormState({
      gridTags: [...formState.gridTags, grid.tag.id]
    });
    setFormValid(true);

    setSnackbar({
      message: "The Grid was created!",
      variant: "success"
    });
  };

  const templateInput: TemplateInput = {
    key: "gridTags",
    label: "Select Grid(s)",
    inputType: "tag",
    tagGroup: getGridTagGroup()
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      classes={{
        root: "grid-link-dialog",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper",
        paperWidthSm: "paper-width-sm"
      }}
      onClose={handleClose}
      onEntered={() => setMenuPortalTarget(domRef.current)}
      open={true}
      PaperComponent={PaperComponent}
      ref={domRef}
    >
      <DialogTitle style={{ cursor: "move" }}>
        <div className="title">Link to Grid</div>

        <div className="tools">
          <div className="close-button">
            <Tooltip text="Close">
              <CloseButton onClick={() => handleClose()} size="medium" />
            </Tooltip>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className="not-draggable">
        <div className="description">
          Let's get this collection linked to a grid
        </div>
        <div className="form">
          <InputController
            model={formState}
            customProps={{
              menuPortalTarget,
              onChange: handleChange,
              selectedTagIds: formState.gridTags // @todo move this input InputController
            }}
            templateInput={templateInput}
          />

          <Button
            color="product-blue"
            label="Create new Grid"
            onClick={() => {
              openModal("edit-grid", {
                onChangeGrid: handleCreateGrid
              });
            }}
            size="small"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <ProTip text="Be sure to link your Booking Collections to a Grid so you can easily complete post-booking workflows later" />
        <div className="buttons">
          <Button
            color="gray"
            isTransparent={true}
            label="Skip"
            onClick={() => handleClose()}
            size="medium"
          />

          <Button
            color="product-blue"
            isDisabled={!formValid}
            label="Next"
            onClick={() => handleSave()}
            size="medium"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
