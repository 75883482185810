import { Theme } from "react-select";
import { ObjectHash } from "../../../utils/helpers";
import Colors from "../../../styles/colors.module.scss";

export const customStyle: ObjectHash = {
  loadingMessage: (styles: ObjectHash) => ({
    ...styles,
    fontSize: "14px"
  }),
  menu: (styles: ObjectHash) => ({
    ...styles,
    border: `2px solid ${Colors.borderGray}`,
    boxShadow: Colors.shadow,
    fontSize: "14px",
    zIndex: 180 // matches colors.module.scss z-index map for "menu" key
  }),
  menuPortal: (styles: ObjectHash) => ({
    ...styles,
    zIndex: 180 // matches colors.module.scss z-index map for "menu" key
  }),
  option: (styles: ObjectHash) => ({
    ...styles,
    cursor: "pointer",
    fontSize: "14px"
  }),
  singleValue: (styles: ObjectHash) => ({
    ...styles,
    marginLeft: "0"
  })
};

export const customReactSelectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    danger: Colors.red,
    dangerLight: Colors.lightRed,
    neutral80: Colors.black,
    primary: Colors.productBlue,
    primary25: Colors.backgroundGray,
    primary50: Colors.backgroundGray
  }
});
