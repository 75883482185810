import React from "react";
import Select from "react-select";
import AppIcon from "../../app-icon";
import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";
import {
  getSelectValueProps,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";

interface Props {
  closeMenuOnScroll?: any; // can be bool or func
  defaultValue?: string | SelectOption;
  isDisabled?: boolean;
  isSearchable?: boolean;
  menuIsOpen?: boolean;
  menuPortalTarget?: any;
  onChange?: CallableFunction;
  options?: any[];
  placeholder?: string;
  value?: string | SelectOption;
}

export default function AutocompleteInput(props: Props) {
  const { menu, menuPortal, option, singleValue } = customStyle;
  const {
    closeMenuOnScroll,
    isDisabled,
    isSearchable,
    menuIsOpen,
    menuPortalTarget,
    onChange,
    options,
    placeholder
  } = props;

  const optionStyles = {
    control: (styles: ObjectHash) => ({
      ...styles,
      borderWidth: "2px",
      boxShadow: "0", // disables blue focus outline
      cursor: "pointer",
      height: "100%",
      minHeight: "40px",
      maxHeight: "40px",
      paddingLeft: "6px",
      paddingRight: "8px",
      "&:hover": {
        backgroundColor: Colors.backgroundGray
      }
    }),
    menu,
    menuPortal,
    option,
    singleValue
  };

  const handleChange = (option: ObjectHash) => {
    onChange && onChange(option.value);
  };

  return (
    <Select
      {...getSelectValueProps(props)}
      components={{
        DropdownIndicator: () => <AppIcon type="menu-arrow" />,
        IndicatorSeparator: () => null
      }}
      className="autocomplete-input"
      closeMenuOnScroll={closeMenuOnScroll}
      isDisabled={isDisabled || false}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      menuPlacement={"auto"}
      menuPortalTarget={menuPortalTarget}
      onChange={(option: any) => handleChange(option)}
      options={options ?? []}
      placeholder={placeholder || "Select..."}
      styles={optionStyles}
      tabSelectsValue={false}
      theme={customReactSelectTheme}
    />
  );
}
