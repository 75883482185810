import React from "react";
import "./page-header.scss";

export interface Props {
  title: string;
  children?: JSX.Element | JSX.Element[];
}

export default function AppPageHeader(props: Props) {
  const { title, children } = props;
  return (
    <div className="page-header">
      <h1 className="circular-bold">{title}</h1>
      {children}
    </div>
  );
}
