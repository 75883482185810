import React from "react";
import CustomToolbar from "../custom-toolbar";
import CustomToolbarSelect from "../custom-toolbar-select";
import Loader from "../../loader";

import AppContainer from "../../../container";
import {
  currencyToFloat,
  getDisplayDate,
  ObjectHash
} from "../../../utils/helpers";
import {
  LocalStorageService,
  ILocalStorageService
} from "../../../services/local-storage.service";
import { ITagService, TagService } from "../../../services/tag.service";
import {
  IWorkspaceService,
  WorkspaceService
} from "../../../services/workspace.service";

import { handleRowClick } from "../table-funcs/helper-funcs";
import {
  customRenderCols,
  disableDownloadCols,
  getCheckmark,
  getIcon,
  getTagChip,
  getPrice,
  getPriceHeader,
  getTravelIcon,
  getUserFullName,
  getUsers,
  parseResults,
  unsortableCols
} from "../table-funcs/render-funcs";
import TagGroupModel from "../../../models/tag-group.model";

export const StaticResultsTableWrapper = (
  vState: ObjectHash,
  setState: CallableFunction,
  params: ObjectHash
) => {
  const localStorageService: ILocalStorageService = AppContainer.get(
    LocalStorageService
  );
  const tagService: ITagService = AppContainer.get(TagService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const {
    doSearch,
    id,
    onChangeTableBlockFilter,
    onChangeTableBlockSort,
    inTableFilters,
    inTableSorters,
    searchOpts,
    template
  } = vState;

  const { resource, viewStateKey } = searchOpts;
  const {
    grid,
    history,
    isLoading,
    location, // React Router withRouter prop
    locationsData, // Provides select dropdown options for quick add when using static data
    onCreate,
    openModal,
    renderOpts = {},
    staticResults,
    title,
    settings
  } = params || {};

  let { results } = vState;

  const columns = vState.columns || params.columns;

  if (staticResults) {
    results = staticResults;
  }

  let tableTitle = title;

  const colKeys = columns.map((c: any[]) => c[0]);

  // set user defined display cols
  let colsToDisplay =
    localStorageService.getViewState(`${viewStateKey}-columns`) || colKeys;

  // convert legacy identifier column settings over to tag column ids
  colsToDisplay = tagService.mapIdentifiersColumnIds(
    colsToDisplay,
    settings.tagGroups
  );

  const tableCols = columns.map((column: any[]) => {
    const fieldName = column[0];

    const tableCol: ObjectHash = {
      label: column[1].length ? column[1] : " ",
      name: fieldName,
      options: { sort: true }
    };
    // disable sorting of cols
    if (
      unsortableCols.includes(fieldName) ||
      TagGroupModel.isFieldId(fieldName)
    ) {
      tableCol.options.sort = false;
    }

    // enable display of cols
    if (
      renderOpts.disableViewChange ||
      colsToDisplay.includes(fieldName) ||
      customRenderCols.includes(fieldName)
    ) {
      tableCol.options.display = true;
    } else {
      tableCol.options.display = false;
    }
    // disable csv export of cols
    if (
      disableDownloadCols.includes(fieldName) ||
      !colsToDisplay.includes(fieldName)
    ) {
      tableCol.options.download = false;
    }
    // hide cols from viewColumns menu
    if (customRenderCols.includes(fieldName)) {
      tableCol.options.viewColumns = false;
    }
    // hide id col from display and viewColumns menu on non-admin pages
    if (
      (fieldName === "id" && location.pathname !== "/admin") ||
      fieldName === "userId"
    ) {
      tableCol.options.display = "excluded";
    }
    // use customBodyRender to display React component or special display value in td cell
    if (fieldName && fieldName.endsWith("Date")) {
      tableCol.options.customBodyRender = (value: string) =>
        getDisplayDate(value, settings.dateFormat);
    }
    if (
      ["arrivalTime", "departureTime", "from", "to"].includes(fieldName) ||
      (fieldName && fieldName.endsWith("DateTime"))
    ) {
      tableCol.options.customBodyRender = (value: string) =>
        getDisplayDate(value, settings.dateTimeFormat);
    }
    if (
      [
        "hasAccommodation",
        "hasInbound",
        "hasOutbound",
        "hasTransportation"
      ].includes(fieldName)
    ) {
      tableCol.options.customBodyRender = (value: string) =>
        getTravelIcon(value, fieldName);
    }
    if (fieldName === "icon") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getIcon(tableMeta, colKeys);
    }
    if (["blocked", "primary"].includes(fieldName)) {
      tableCol.options.customBodyRender = (
        value: boolean,
        tableMeta: ObjectHash
      ) => getCheckmark(value);
    }
    if (
      (
        ((template || {}).properties || []).find(
          (p: ObjectHash) => p.name === fieldName
        ) || {}
      ).propertyType === "currency"
    ) {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getPrice(tableMeta, colKeys);
      // customHeadRender for currency column to include total, getDocumentTableHeader() mirrors this in document tables
      if (staticResults) {
        tableCol.options.customHeadRender = (
          columnMeta: ObjectHash,
          handleToggleColumn: CallableFunction
        ) => getPriceHeader(columnMeta, handleToggleColumn, results);
      }
    }
    if (fieldName === "users") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getUsers(tableMeta, results, colKeys);
    }

    if (["travelers", "users"].includes(resource) && fieldName === "name") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getUserFullName(value, tableMeta, colKeys, results);
    }

    // Tags as columns
    if (TagGroupModel.isFieldId(fieldName)) {
      tableCol.options.customBodyRender = (
        values: ObjectHash[],
        tableMeta: ObjectHash
      ) => {
        if (!values || !Array.isArray(values)) {
          return "";
        }
        return getTagChip(values);
      };
    }

    return tableCol;
  });

  let tableData: any[] = [];

  if (results) {
    tableData = parseResults(results, colKeys, template);
  }

  let selectableRows = renderOpts.disableRowSelect ? "none" : "multiple";

  let isRowSelectable: CallableFunction = () => true;
  let setRowProps = null;
  if (selectableRows !== "none") {
    setRowProps = (row: string[]) => {
      const rowProps: ObjectHash = {};
      const dataId = row[colKeys.indexOf("id")];
      const data: ObjectHash = results.find(
        (row: ObjectHash) => row.id === dataId
      );

      if (data && workspaceService.dataIsFrozen(data)) {
        rowProps.className = "mui-data-table__row--disabled";
      }

      if (data?.cancelled) {
        rowProps.className = "mui-data-table__row--cancelled";
      }

      return rowProps;
    };
    isRowSelectable = (dataIndex: number) => {
      const dataId = tableData[dataIndex][colKeys.indexOf("id")];
      const data = results.find((row: ObjectHash) => row.id === dataId);
      if (data && workspaceService.dataIsFrozen(data)) {
        return false;
      }
      return true;
    };
  }

  const tableOpts: ObjectHash = {
    customSort: (data: any[], colIndex: number, order: string) => {
      // handle sorting different for currency type properties
      if (
        (
          ((template || {}).properties || []).find(
            (p: ObjectHash) => p.name === colKeys[colIndex]
          ) || {}
        ).propertyType === "currency"
      ) {
        tableData.sort((a, b) => {
          return (
            (currencyToFloat(a[colIndex]) - currencyToFloat(b[colIndex])) *
            (order === "desc" ? 1 : -1)
          );
        });

        data.sort((a, b) => {
          return (
            (currencyToFloat(a.data[colIndex]) -
              currencyToFloat(b.data[colIndex])) *
            (order === "desc" ? 1 : -1)
          );
        });
      } else {
        tableData.sort((a, b) => {
          if (a[colIndex] < b[colIndex]) {
            return 1 * (order === "desc" ? 1 : -1);
          }
          if (a[colIndex] > b[colIndex]) {
            return -1 * (order === "desc" ? 1 : -1);
          }
          return 0;
        });
        data.sort((a, b) => {
          if (a.data[colIndex] < b.data[colIndex]) {
            return 1 * (order === "desc" ? 1 : -1);
          }
          if (a.data[colIndex] > b.data[colIndex]) {
            return -1 * (order === "desc" ? 1 : -1);
          }
          return 0;
        });
      }

      data.forEach((d, i) => {
        d.index = i;
      });

      return data;
    },
    customToolbarSelect: () => (
      <CustomToolbarSelect
        displayData={tableData}
        doSearch={renderOpts.updateStaticResults || doSearch}
        grid={grid}
        idColIndex={colKeys.indexOf("id")}
        resource={resource}
        results={results}
        selectedRows={vState.selectedRows}
        setState={setState}
        tagId={id}
        tableTitle={title}
        settings={settings}
      />
    ),
    download: !renderOpts.disableDownload,
    downloadOptions: {
      filename: `${title}.csv`
    },
    filter: false,
    filterType: "textField",
    isRowSelectable,
    onDownload: (
      buildHead: CallableFunction,
      buildBody: CallableFunction,
      cols: any[],
      dataRows: any[]
    ) => {
      try {
        const head = buildHead(
          cols.map((c) => ({ ...c, name: c.label || c.name }))
        );

        const tagColumnIndexes: number[] = [];
        cols.forEach((col: ObjectHash, index: number) => {
          if (TagGroupModel.isFieldId(col.name)) {
            tagColumnIndexes.push(index);
          }
        });

        const body = buildBody(
          dataRows.map((row) => ({
            ...row,
            data: row.data.slice().map((d: ObjectHash, index: number) => {
              if (tagColumnIndexes.indexOf(index) !== -1) {
                return d.map((tag: any) => tag.name);
              }
              if (d && d.props && d.props.children) {
                return d.props.children;
              }
              return d;
            })
          }))
        );
        return `${head}${body}`;
      } catch (e) {
        return false;
      }
    },
    onRowSelectionChange: (
      currentRowsSelected: any[],
      allRowsSelected: any[]
    ) => {
      setState({
        selectedRows: allRowsSelected.map((row) => row.dataIndex).sort()
      });
    },
    pagination: !renderOpts.disablePagination,
    print: false,
    responsive: "standard",
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsSelected: vState.selectedRows,
    search: false,
    selectableRows,
    serverSide: false,
    setRowProps,
    sort: !renderOpts.disableSort,
    sortOrder: {},
    textLabels: {
      body: {
        noMatch: ""
      },
      selectedRows: {
        text: "selected"
      },
      toolbar: {
        viewColumns: "Properties"
      }
    },
    viewColumns: !renderOpts.disableViewChange
  };

  tableOpts.customToolbarSelect.displayName = "CustomToolbarSelect";

  if (
    renderOpts.showColorMenu ||
    renderOpts.showQuickAdd ||
    renderOpts.showFilterMenu ||
    renderOpts.showSortMenu ||
    renderOpts.showUserMenu
  ) {
    const displayedCols = tableCols
      .filter((col: ObjectHash) => col.options.display === true)
      .map((col: ObjectHash) => col.name);
    const visibleCols = columns.filter(
      (col: ObjectHash) =>
        col[0] !== "documentRowMenu" && displayedCols.includes(col[0])
    );

    let handleApplyFilter: CallableFunction = function () {};
    let handleApplySort: CallableFunction = function () {};

    if (staticResults) {
      handleApplyFilter = (filtersHash: ObjectHash) => {
        const filters = Object.entries(filtersHash).filter(
          (kv) => !["from", "to"].includes(kv[0])
        );

        onChangeTableBlockFilter(filters);
      };

      handleApplySort = (sortersToApply: ObjectHash) => {
        onChangeTableBlockSort(sortersToApply);
      };
    }

    tableOpts.customToolbar = () => (
      <CustomToolbar
        data={staticResults || results}
        dataType={viewStateKey ? viewStateKey.split("-")[1].toUpperCase() : ""}
        doSearch={renderOpts.updateStaticResults || doSearch}
        grid={grid}
        locationsData={locationsData}
        tableFiltersState={inTableFilters}
        tableSortersState={inTableSorters}
        visibleCols={visibleCols}
        onApplyFilter={handleApplyFilter}
        onApplySort={handleApplySort}
        onCreate={onCreate}
        renderOpts={renderOpts}
        resource={resource}
        tagId={id}
      />
    );
    tableOpts.customToolbar.displayName = "CustomToolbar";
  }

  const changeColumnView = (cols: any[]) => {
    const showColsIndexes: any[] = [];

    cols
      .map((o) => o.display)
      .forEach((displayOpt, index) => {
        if (displayOpt === "true") {
          showColsIndexes.push(index);
        }
      });

    const newFields: any[] = showColsIndexes.map((i) => columns[i][0]);

    localStorageService.setViewState(`${viewStateKey}-columns`, newFields);

    vState.searchOpts.fields = newFields;
  };

  const changeDownloadOpt = (cols: any[]) => {
    cols.forEach((col) => {
      if (col.display === "false") {
        col.download = false;
      }
      if (col.display === "true" && !disableDownloadCols.includes(col.name)) {
        col.download = true;
      }
    });
  };

  tableOpts.count = results.length;
  tableOpts.onTableChange = (action: string, tableState: ObjectHash) => {
    switch (action) {
      case "viewColumnsChange":
        changeColumnView(tableState.columns);
        changeDownloadOpt(tableState.columns);
        break;

      case "changeRowsPerPage":
        vState.rowsPerPage = tableState.rowsPerPage;
        break;

      default:
      // do nothing
    }
  };
  if (vState.rowsPerPage) {
    tableOpts.rowsPerPage = vState.rowsPerPage;
  } else {
    tableOpts.rowsPerPage = resource === "trips" ? 10 : 100;
  }
  tableTitle = (
    <div className="table-title">
      <span className="table-title__name">{title}</span>
      <span className="table-title__results">( {results.length} )</span>
      {isLoading ? <Loader type="spinner" /> : null}
    </div>
  );

  if (!renderOpts.disableRowClick) {
    tableOpts.onRowClick = (rowData: any[], rowMeta: ObjectHash) => {
      try {
        const dataId = tableData[rowMeta.dataIndex][colKeys.indexOf("id")];
        if (dataId) {
          const data = results.find((obj: ObjectHash) => obj.id === dataId);
          if (data) {
            if (renderOpts.customRowClick) {
              renderOpts.customRowClick(data);
            } else {
              handleRowClick(
                data,
                resource,
                history,
                renderOpts.updateStaticResults || doSearch,
                locationsData || [],
                openModal
              );
            }
          }
        }
      } catch (e) {
        // ignore error
      }
    };
  }

  const muiDataTableProps = {
    data: tableData,
    title: tableTitle,
    columns: tableCols,
    options: tableOpts
  };

  return {
    muiDataTableProps,
    renderOpts,
    title
  };
};
