import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AppPageProps } from "../../router";

import AccordionGroup from "../../components/accordion-group";
import PageHeader from "../../components/page-header";
import PageLoader from "../../components/page-loader";
import { LoadState, ObjectHash } from "../../utils/helpers";

import "./collection-view.scss";
import {
  CollectionService,
  ICollectionService
} from "../../services/collection.service";
import AppContainer from "../../container";
import CollectionModel from "../../models/collection.model";
import CollectionRequests from "./collection-requests";
import CollectionSettings from "./collection-settings";

export default function CollectionViewPage(props: AppPageProps) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );

  const [collection, setCollection] = useState<CollectionModel>(
    new CollectionModel()
  );
  const [loadState, setLoadState] = useState<LoadState>("loading");

  const { id }: ObjectHash = useParams();
  const history = useHistory();

  useEffect(() => {
    if (loadState !== "loading") {
      return;
    }
    collectionService.getById(id).then((collection) => {
      if (!collection) {
        history.push("/collections");
        return;
      }
      setCollection(collection);
      setLoadState("loaded");
    });
  }, [collectionService, history, id, loadState, setCollection, setLoadState]);

  if (loadState !== "loaded") {
    return (
      <div id="collection-view-page" className="page-container">
        <PageLoader title="Loading collection..." />
      </div>
    );
  }

  return (
    <div id="collection-view-page" className="page-container">
      <PageHeader title={collection.name}></PageHeader>
      <AccordionGroup titles={["Booking Collection Details"]} defaultOpen={-1}>
        <CollectionSettings collection={collection} onChange={setCollection} />
      </AccordionGroup>

      <CollectionRequests collection={collection} />
    </div>
  );
}
