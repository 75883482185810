import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { DateTime } from "luxon";

import TimeInput from "./TimeInput";
import { getDateMeta, MuiVariant } from "../../../utils/helpers";
import "./inputs.scss";

interface Props {
  defaultValue?: string | number;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  minValue?: string;
  onChange: CallableFunction;
  value?: string | number;
  variant?: MuiVariant;
  withTime?: boolean;
}

export default function DateInput(props: Props) {
  const {
    defaultValue,
    isDisabled,
    isRequired,
    label,
    minValue,
    onChange,
    value,
    variant,
    withTime
  } = props;

  const [error, setError] = useState(false);
  const [labelWithStatus, setLabelWithStatus] = useState(label);

  let disableUnderline = {};
  if (variant && ["filled", "standard"].includes(variant)) {
    disableUnderline = { disableUnderline: true };
  }

  const getFullDate = (newDate?: string, newTime?: string) => {
    const date = newDate || storedDate || DateTime.local().toISODate();
    const time = newTime || storedTime || "12:00";
    return `${date}T${time}`;
  };

  // if withTime, returned value needs to include both date and time
  const handleChangeDate = (value: string) => {
    setStoredDate(value);
    onChange(withTime ? getFullDate(value) : value);
  };

  // always return both date and time
  const handleChangeTime = (value: string) => {
    setStoredTime(value);
    onChange(getFullDate("", value));
  };

  // Cannot use controlled value and defaultValue at same time
  const isControlled = props.hasOwnProperty("value");
  const valuePropName = isControlled ? "value" : "defaultValue";
  const dateMeta = getDateMeta((isControlled ? value : defaultValue) || "");

  const dateValueProps = {
    [valuePropName]: dateMeta.date
  };

  const timeValueProps = {
    [valuePropName]: dateMeta.time
  };

  /*
   * Keep a copy of the date and time input values synced with a separate component state,
   * regardless of wether or not the inputs are controlled. This copy can be used in
   * getFullDate above to return an updated, full date time.
   */
  const [storedDate, setStoredDate] = useState(dateValueProps[valuePropName]);
  const [storedTime, setStoredTime] = useState(
    timeValueProps[valuePropName] || ""
  );

  useEffect(() => {
    if (isControlled) {
      const dateMeta = getDateMeta(value || "");
      setStoredDate(dateMeta.date);

      if (withTime && dateMeta.hasTime) {
        setStoredTime(dateMeta.time);
      }
    }
  }, [isControlled, setStoredDate, value, withTime]);

  return (
    <div className="date-input-flex" style={{ display: "flex" }}>
      <TextField
        {...dateValueProps}
        className="date-input"
        disabled={isDisabled || false}
        error={error}
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          ...disableUnderline
        }}
        inputProps={{
          min: minValue
        }}
        label={labelWithStatus}
        onChange={(e) => {
          const updatedDate = e.target.value;
          // Adds invalid text to label and turns red if date is invalid
          if (label) {
            if (!updatedDate) {
              setError(true);
              setLabelWithStatus(`${label} - Invalid`);
            } else {
              setError(false);
              setLabelWithStatus(label);
            }
          }
          handleChangeDate(updatedDate);
        }}
        required={isRequired || false}
        type="date"
        variant={variant || "outlined"}
      />
      {withTime && (
        <TimeInput
          {...timeValueProps}
          isRequired={isRequired}
          label="Time"
          onChange={handleChangeTime}
          variant={variant}
        />
      )}
    </div>
  );
}
