import React, { useState } from "react";

import AppContainer from "../../../../container";
import { AppPageProps } from "../../../../router";
import { AppActionTypes } from "../../../../context/reducer";
import useApp from "../../../../hooks/use-app.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";
import useModal from "../../../../hooks/use-modal.hook";
import {
  CompanyService,
  ICompanyService
} from "../../../../services/company.service";
import CompanyModel from "../../../../models/company.model";

import AppIcon from "../../../../components/app-icon";
import Button from "../../../../components/button";
import CompanyLogo from "../../../../components/company-logo";
import FileUploader from "../../../../components/file-uploader";
import TextInput from "../../../../components/form/inputs/TextInput";
import Tooltip from "../../../../components/tooltip";

import env from "../../../../utils/env";

import "../../settings-page.scss";

const WorkspacePage = (props: AppPageProps) => {
  const companyService: ICompanyService = AppContainer.get(CompanyService);
  const { setSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const { company, user } = props;
  const { dispatch } = useApp();

  const [formState, setFormState] = useState<{ company: CompanyModel }>({
    company
  });

  const updateCompany = async (newData: any) => {
    const updatedCompany = new CompanyModel({ ...company, ...newData });
    const response: CompanyModel | null = await companyService.update(
      updatedCompany
    );

    if (response) {
      dispatch({ type: AppActionTypes.UpdateCompany, payload: response });

      setSnackbar({
        message: "Workspace settings updated!",
        variant: "success"
      });
    }
  };

  const companyEmail = company.getEmailForAppDomain(env.mailboxDomain);
  const isPrimaryAdmin = Boolean(user.primary);

  return (
    <div className="settings-subpage">
      <div className="settings-page__section-wrapper">
        <h2 className="settings-page__sub-header">Workspace Name</h2>
        <TextInput
          defaultValue={formState.company.name}
          disabled={!isPrimaryAdmin}
          onChange={(value: string) => {
            formState.company.name = value;
            setFormState({ company });
          }}
          variant="outlined"
        />
        <h2 className="settings-page__sub-header">
          Workspace Forwarding Address
          <Tooltip
            placement="top"
            text="Forward reservation emails to this address to parse them directly into your dashboard."
          >
            <AppIcon color="black" type="info" size="x-small" />
          </Tooltip>
        </h2>
        <div className="settings-input-flex">
          <TextInput
            defaultValue={formState.company.mailbox}
            disabled={!isPrimaryAdmin}
            onChange={(value: string) => {
              company.mailbox = (value || "")
                .toLowerCase()
                .replace(/ /g, "_")
                .replace(/[^a-z0-9_.]/g, "");

              setFormState({ company });
            }}
            variant="outlined"
          />
          <span>{`@${(companyEmail || "").split("@").pop()}`}</span>
        </div>

        <Button
          color="product-blue"
          label="Update"
          onClick={() => {
            updateCompany({
              name: company.name,
              mailbox: company.mailbox
            });
          }}
          size="medium"
        />
      </div>

      <div className="settings-page__section-wrapper">
        <h2 className="settings-page__sub-header">Workspace Icon</h2>
        <div className="settings-flex">
          <div className="settings-flex-column">
            <div className="workspace-icon-wrapper">
              <div className="workspace-icon-helper">
                <Tooltip
                  placement="top"
                  text={
                    <span>
                      Use a solid background or transparent png.
                      <br />
                      Upload a square image that is 132px or larger.
                    </span>
                  }
                >
                  <AppIcon color="black" type="info" size="x-small" />
                </Tooltip>
              </div>
              <CompanyLogo company={company} />
            </div>
            {isPrimaryAdmin ? (
              <div className="settings-page__button-group">
                <FileUploader
                  trigger={
                    <Button color="product-blue" label="Upload" size="medium" />
                  }
                  opts={{
                    accept: ["image/*"],
                    fromSources: ["local_file_system"],
                    imageMax: [500, 500],
                    maxFiles: 1,
                    transformations: {
                      crop: {
                        force: true,
                        aspectRatio: 1
                      }
                    }
                  }}
                  cb={([url]: any) => updateCompany({ logoUploadUrl: url })}
                />
                <Button
                  color="red"
                  isDisabled={!company.logoUploadUrl}
                  onClick={() => {
                    openModal("confirm", {
                      buttonText: "Remove",
                      dialogTitle: "Remove Workspace Icon",
                      dialogBody:
                        "Are you sure you’d like to remove the existing icon?",
                      onConfirm: () => {
                        updateCompany({ logoUploadUrl: "" });
                      }
                    });
                  }}
                  label="Remove"
                  size="medium"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspacePage;
