import React, { RefObject, useEffect, useRef, useState } from "react";
import { flatten } from "lodash";

import Button from "../../../../button";
import DateInput from "../../../../form/inputs/DateInput";
import { getEntrySegmentFromTrip } from "../../lib/service";
import { getTimestamp } from "../../../../../utils/helpers";
import HighlightFade from "../../../../highlight-fade";
import Label from "./label";
import PopperMenu from "../../../../popper-menu";
import SegmentModel from "../../../../../models/segment.model";
import TextInput from "../../../../form/inputs/TextInput";
import { TmlActionTypes } from "../../context/reducer";
import {
  TmlEntryModel,
  TmlEntrySegmentId,
  TmlEntrySegmentTravel,
  TmlEntrySegmentTrip,
  TmlLogUpdate
} from "../../lib/models";
import TmlSegmentTripRow from "./tml-segment-trip-row";
import TripModel from "../../../../../models/trip.model";
import { useTmlLog } from "../../context";

import "./tml-segment-cell.scss";

interface Props {
  entry: TmlEntryModel;
  onChange: (entry: TmlEntryModel) => void;
  portalTarget: RefObject<HTMLElement>;
  segmentId: TmlEntrySegmentId;
  trips: TripModel[];
}

export default function TmlSegmentCell(props: Props) {
  const { entry, onChange, portalTarget, segmentId, trips } = props;
  const { dispatchLogUpdates, logUpdates } = useTmlLog();
  const menuRef = useRef();
  const [highlight, setHighlight] = useState<boolean>(false);
  const [override, setOverride] = useState<{ value: string; date: number }>({
    value: "",
    date: 0
  });

  let tripTypes: string[] = [];

  switch (segmentId) {
    case "travelSegment":
      tripTypes = ["air", "rail"];
      break;
    case "transportSegment":
      tripTypes = ["car"];
      break;
    case "locationSegment":
      tripTypes = ["hotel"];
      break;
  }

  const handleChange = (entry: TmlEntryModel) => {
    onChange(entry);
    (menuRef?.current as any).close();
  };

  const handleOverride = () => {
    const { date, value } = override;
    const updatedEntry = new TmlEntryModel({ ...entry });
    updatedEntry[segmentId] = {
      addedByUser: true,
      tripType: "user",
      date,
      value
    };
    handleChange(updatedEntry);
  };

  const handleSelectTrip = (trip: TripModel, segment?: SegmentModel) => {
    const updatedSegment = getEntrySegmentFromTrip(
      trip,
      segment
    ) as TmlEntrySegmentTrip;
    updatedSegment.addedByUser = true;

    const updatedEntry = new TmlEntryModel({ ...entry });
    updatedEntry[segmentId] = updatedSegment as TmlEntrySegmentTravel;

    handleChange(updatedEntry);
  };

  useEffect(() => {
    const { userId, type } = entry;

    const matchUpdates = logUpdates.filter(
      (update: TmlLogUpdate) =>
        update.updateType === "segment/update" &&
        update.userId === userId &&
        update.entryType === type &&
        update.segmentId === segmentId
    );

    const updated = Boolean(matchUpdates.length);

    if (updated) {
      setHighlight(updated);

      dispatchLogUpdates({
        type: TmlActionTypes.DeleteUpdate,
        payload: matchUpdates
      });
    }
  }, [dispatchLogUpdates, entry, logUpdates, segmentId]);

  const tripElements = flatten(
    trips
      ?.filter((trip: TripModel) =>
        tripTypes.includes(String(trip.type).toLowerCase())
      )
      .map((trip: TripModel) => {
        const { type } = trip;

        if (["air", "rail"].includes(String(type).toLowerCase())) {
          const segments = trip.getSegments();

          return segments.map((segment: SegmentModel) => (
            <TmlSegmentTripRow
              entry={entry}
              trip={trip}
              segment={segment}
              key={`${trip.id}-${segment.id}`}
              onSelect={handleSelectTrip}
            />
          ));
        }

        return (
          <TmlSegmentTripRow
            entry={entry}
            trip={trip}
            key={trip.id}
            onSelect={handleSelectTrip}
          />
        );
      })
  );

  return (
    <HighlightFade
      start={highlight}
      delayMs={15000}
      onStop={() => setHighlight(false)}
    >
      <div className="tml-cell segment">
        <div className="tml-cell-popper-wrapper">
          <PopperMenu
            anchor={<Label entry={entry} segmentId={segmentId} />}
            portalTarget={portalTarget}
            placement="bottom-start"
            ref={menuRef}
          >
            <div className="tml-segment-menu">
              <div className="header">Booked Travel</div>
              <div className="trip-list">{tripElements}</div>
              <div className="header">Override</div>
              <div className="override-inputs">
                <TextInput
                  value={override.value}
                  onChange={(value: string) =>
                    setOverride({ ...override, value })
                  }
                />
                <DateInput
                  label="Date"
                  onChange={(value: string) => {
                    setOverride({ ...override, date: getTimestamp(value) });
                  }}
                  value={override.date}
                  withTime={true}
                />
              </div>
              <Button
                color="product-blue"
                label="Override"
                onClick={handleOverride}
                size="medium"
              />
            </div>
          </PopperMenu>
        </div>
      </div>
    </HighlightFade>
  );
}
