import React, { useRef, useState } from "react";
import Button from "../../../components/button";
import FormController from "../../../components/form/form-controller";
import AppContainer from "../../../container";
import { AppActionTypes } from "../../../context/reducer";
import useApp from "../../../hooks/use-app.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import CollectionModel from "../../../models/collection.model";
import GridModel from "../../../models/grid.model";
import TagGroupModel from "../../../models/tag-group.model";
import {
  CollectionService,
  ICollectionService
} from "../../../services/collection.service";
import { TemplateInput } from "../../../services/workspace.service";
import { CollectionValidator, FormConfig } from "../../../utils/form";
import { ObjectHash } from "../../../utils/helpers";

interface Props {
  collection: CollectionModel;
  onChange: CallableFunction;
}

const formConfig: FormConfig = {
  validator: CollectionValidator,
  fields: [
    {
      key: "name",
      label: "Collection Name",
      inputType: "text"
    },
    {
      key: "gridTags",
      label: "Grid(s)",
      inputType: "tag"
    },
    {
      key: "expectedTravelers",
      label: "Expected Travelers",
      inputType: "integer"
    },
    {
      key: "budget",
      label: "Budget",
      inputType: "currency"
    },
    {
      key: "primaryContact",
      label: "Primary Contact",
      inputType: "text"
    },
    {
      key: "notes",
      label: "Notes",
      inputType: "text"
    }
  ]
};

export default function CollectionGeneralSettingsForm(props: Props) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );
  const { collection, onChange } = props;
  const { getGridTagGroup, settings } = useApp();
  const { grids } = settings;
  const { setSnackbar } = useSnackbar();
  const { dispatch } = useApp();

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [formValid, setFormValid] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const formRef = useRef();

  const { validator, fields } = formConfig;

  const zeroState: ObjectHash = { id: collection.id };
  const formFields = fields.map((templateInput: TemplateInput) => {
    const { key } = templateInput;
    const updatedTemplate = { ...templateInput };
    let value: any = collection[key as keyof CollectionModel] || "";

    // load the grid tag group into the grids input
    if (key === "gridTags") {
      templateInput.tagGroup = getGridTagGroup();
      const fieldId = templateInput.tagGroup!.getFieldId();
      const { grids } = collection;
      zeroState[fieldId] = grids.map((grid: GridModel) => grid.tag.id);
      updatedTemplate.key = fieldId;
      return updatedTemplate;
    }

    zeroState[key] = value;

    return updatedTemplate;
  });

  const handleSave = async (formState: ObjectHash) => {
    const payload: ObjectHash = {
      ...formState,
      grids: []
    };

    // convert grid tags back into grid ids
    const gridTagKey = Object.keys(formState).find((key: string) =>
      TagGroupModel.isFieldId(key)
    );

    if (gridTagKey && formState[gridTagKey]) {
      formState[gridTagKey].forEach((tagId: string) => {
        const grid = grids.find((grid: GridModel) => grid.tag.id === tagId);
        if (grid) {
          payload.grids.push(grid.id);
        }
      });
    }

    const updatedCollection = await collectionService.update(
      collection.id,
      payload
    );

    if (updatedCollection) {
      setReadOnly(true);

      setSnackbar({
        message: "Collection updated!",
        variant: "success"
      });

      dispatch({
        type: AppActionTypes.UpdateCollection,
        payload: updatedCollection
      });

      onChange(updatedCollection);

      return;
    }

    setSnackbar({
      message: "There was an error and your changes were not saved",
      variant: "error"
    });
  };

  return (
    <div id="collection-general-settings-form">
      <FormController
        fields={formFields}
        ref={formRef}
        model={zeroState}
        onFormChange={(changed: boolean) => setFormChanged(changed)}
        onSave={handleSave}
        onValidate={setFormValid}
        readOnly={readOnly}
        validator={validator}
      />

      <div className="form-footer">
        <Button
          isDisabled={!readOnly && (!formChanged || !formValid)}
          color="product-blue"
          label={readOnly ? "Edit" : "Save Changes"}
          onClick={() => {
            if (readOnly) {
              setReadOnly(false);
              return;
            }
            (formRef.current as any)?.save();
          }}
          size="medium"
        />
        {!readOnly && (
          <Button
            color="gray"
            isTransparent={true}
            label="Cancel"
            onClick={() => {
              setReadOnly(true);
              (formRef.current as any)?.reset();
            }}
            size="medium"
          />
        )}
      </div>
    </div>
  );
}
