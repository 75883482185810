import { getDisplayDate, ObjectHash } from "../../../../utils/helpers";
import { Column, Filter, Sort } from "../../../sink/helpers";
import { GroupMenuSection } from "../../../sink/grid-actions/group-menu";

export const Columns: Column[] = [
  {
    hideLabel: true,
    id: "entryType",
    label: "Entry Type",
    locked: true,
    pinned: true,
    resize: false,
    width: 140
  },
  {
    id: "traveler",
    label: "Traveler",
    pinned: true,
    locked: true
  },
  {
    id: "travelSegment",
    label: "Departure/Arrival"
  },
  {
    id: "transportSegment",
    label: "Transportation"
  },
  {
    id: "locationSegment",
    label: "Location"
  },
  {
    id: "notes",
    label: "Notes",
    width: 300
  }
];

export const Filters: Filter[] = [
  {
    fieldId: "traveler",
    label: "Travelers",
    inputType: "user-select",
    article: "are"
  },
  {
    fieldId: "travelDates",
    label: "Travel Date",
    inputType: "date-range"
  },
  {
    fieldId: "travelSegment",
    label: "Arrival/Departure Airport",
    inputType: "airport-select"
  },
  {
    fieldId: "locationSegment",
    label: "Drop-off/Pick-up Location",
    inputType: "text"
  }
];

export const GroupSections: GroupMenuSection[] = [
  {
    label: "Traveler Movement",
    groups: [
      {
        label: "Traveler",
        fieldId: "user"
      },
      {
        label: "Arrival/Departure",
        fieldId: "entryType",
        onName: (value: any, meta?: ObjectHash): string =>
          value === "inbound" ? "Arrivals" : "Departures"
      },
      {
        label: "Arrival/Departure Date",
        fieldId: "date",
        onName: (value: any, meta?: ObjectHash): string =>
          value ? getDisplayDate(value, meta?.dateFormat || "MMM dd") : "--"
      }
    ]
  }
];

export const Sorts: Sort[] = [
  {
    column: { label: "Traveler", id: "traveler" },
    direction: "asc"
  },
  {
    column: { label: "Travel Dates", id: "dates" },
    direction: "asc",
    directionType: "date"
  }
];
