import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";

import AppIcon from "../app-icon";

import ColorPalette from "../color-palette";
import "./color-palette-popper.scss";

export interface Props {
  placement?: PopperPlacementType;
  onClick?: CallableFunction;
  selected?: string;
}

export default function ColorPalettePopper(props: Props) {
  const [anchorEl, setAnchorEl]: [
    HTMLElement | null,
    CallableFunction
  ] = React.useState(null);
  const [selected, setSelected]: [string, CallableFunction] = React.useState(
    String(props.selected).replace("-", " ")
  );

  const { placement } = props;
  const open = Boolean(anchorEl);

  const handleClick = (colorOption: String) => {
    setSelected(colorOption);
    setAnchorEl(null);
    if (props.onClick) {
      props.onClick(colorOption);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        className="color-palette-popper__anchor"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setAnchorEl(event.currentTarget)
        }
      >
        <div className={selected ? `palette ${selected}` : "noselect"}>
          <AppIcon type="menu-arrow" />
        </div>
      </div>

      <Popper
        id="color-palette-popper"
        open={open}
        className="color-palette-popper"
        anchorEl={anchorEl}
        placement={placement || "bottom-start"}
        transition
        style={{ zIndex: 1500 }}
      >
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => {
            handleClickAway();
          }}
        >
          <div
            id="color-palette-popper__content"
            className="color-palette-popper__content"
          >
            Select Tag Color
            <div className="color-palette-popper__colors">
              <ColorPalette selected={selected} onClick={handleClick} />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
