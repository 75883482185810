import React from "react";
import { Link } from "react-router-dom";
import NotFoundImage from "../../images/404-traveler.svg";
import "./not-found.scss";

export default function NotFoundPage() {
  return (
    <div className="not-found-page">
      <div>
        <h1 className="circular-bold">404</h1>
        <img alt="page-not-found-graphic" src={NotFoundImage} />
        <div className="header circular-bold">Where ya' off to?</div>
        <div className="description">
          You can return to Tripgrid by clicking <Link to="/">here</Link>.
        </div>
      </div>
    </div>
  );
}
