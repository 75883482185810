import React, {
  createContext,
  useContext,
  ReactNode,
  ReactElement,
  useReducer,
  useCallback
} from "react";
import { lowerFirst, uniqueId, upperFirst } from "lodash";
import {
  addUrlQuery,
  getQueryStringParams,
  ObjectHash,
  removeUrlQuery
} from "../../utils/helpers";

import AddProfilesDialog from "../../components/dialogs/add-profiles-dialog";
import BookingRequestDialog from "../../components/dialogs/booking-request-dialog";
import ConfirmationWarning from "../../components/dialogs/confirmation-warning-dialog";
import ConvertCsvDialog from "../../components/dialogs/convert-csv-dialog";
import CustomizeReportDialog from "../../components/dialogs/customize-report-dialog";
import DocumentDialog from "../../components/dialogs/document-dialog";
import GripAppTmlEditSettingsDialog from "../../components/apps/travel-movement-log/edit-settings-dialog";
import GridDialog from "../../components/dialogs/grid-dialog";
import GridLinkDialog from "../../components/dialogs/grid-link-dialog";
import MergeProfilesDialog from "../../components/dialogs/merge-profiles-dialog";
import QuickAddDialog from "../../components/dialogs/quick-add-dialog/quick-add-dialog";
import QuickAddDialogLarge from "../../components/dialogs/quick-add-dialog/quick-add-dialog-large";
import ScheduleEventDialog from "../../components/dialogs/schedule-event-dialog";
import TravelPlannerDialog from "../../components/dialogs/travel-planner-dialog";
import UserDialog from "../../components/dialogs/user-dialog";
import RoomBlockDialog from "../../components/dialogs/room-block-dialog";

export type ModalConfig = {
  key: string;
  Component: any;
  modalType: ModalType;
  props?: ObjectHash;
};

export type ModalElement = {
  key: string;
  element: ReactElement;
};

export type Modal = ModalConfig | ModalElement;

type ModalType =
  | "add-profiles"
  | "booking-request"
  | "confirm"
  | "convert-csv"
  | "customize-report"
  | "edit-grid"
  | "edit-document"
  | "edit-schedule-event"
  | "grid-link"
  | "grid-app-tml-edit-settings"
  | "invite-travel-planner"
  | "merge-profiles"
  | "quick-add"
  | "quick-add-large"
  | "room-block"
  | "user";

const modalTypeMap: Map<ModalType, any> = new Map();

modalTypeMap.set("add-profiles", AddProfilesDialog);
modalTypeMap.set("booking-request", BookingRequestDialog);
modalTypeMap.set("confirm", ConfirmationWarning);
modalTypeMap.set("convert-csv", ConvertCsvDialog);
modalTypeMap.set("customize-report", CustomizeReportDialog);
modalTypeMap.set("edit-grid", GridDialog);
modalTypeMap.set("edit-document", DocumentDialog);
modalTypeMap.set("edit-schedule-event", ScheduleEventDialog);
modalTypeMap.set("grid-link", GridLinkDialog);
modalTypeMap.set("grid-app-tml-edit-settings", GripAppTmlEditSettingsDialog);
modalTypeMap.set("invite-travel-planner", TravelPlannerDialog);
modalTypeMap.set("merge-profiles", MergeProfilesDialog);
modalTypeMap.set("quick-add", QuickAddDialog);
modalTypeMap.set("quick-add-large", QuickAddDialogLarge);
modalTypeMap.set("room-block", RoomBlockDialog);
modalTypeMap.set("user", UserDialog);

export function isModalConfig(modal: Modal): modal is ModalConfig {
  return (modal as ModalConfig).modalType !== undefined;
}

export interface IModalState {
  getModalLink: CallableFunction;
  linkModal: CallableFunction;
  modalIsOpen: CallableFunction;
  openModal: CallableFunction;
  openModalElement: CallableFunction;
  closeModal: CallableFunction;
  modalStack: Modal[];
}

export const ModalContext = createContext<IModalState>({
  getModalLink: () => {},
  linkModal: () => {},
  modalIsOpen: () => {},
  openModal: () => {},
  openModalElement: () => {},
  closeModal: () => {},
  modalStack: []
});

const updateModalStack = (
  state: Modal[],
  action: { type: "open" | "close"; modal?: Modal }
) => {
  const updatedStack: Modal[] = [...state];
  const { type, modal } = action;

  switch (type) {
    case "close":
      updatedStack.pop();
      break;
    case "open":
      if (!modal) {
        return updatedStack;
      }

      // modals based on configs must be unique by type, in the stack
      const openConfigs = updatedStack.filter(isModalConfig);
      if (
        isModalConfig(modal) &&
        openConfigs.find(
          (stackModal) => stackModal.modalType === modal.modalType
        )
      ) {
        return updatedStack;
      }

      updatedStack.push(modal);
      break;
  }

  return updatedStack;
};

export function ModalProvider({
  children
}: {
  children: ReactNode | ReactNode[];
}) {
  const [modalStack, setModalStack] = useReducer(updateModalStack, []);

  const removeModalQsParams = useCallback(() => {
    const qsParams = getQueryStringParams();
    Object.keys(qsParams).forEach(
      (key: string) => key.match(/^modal.*/) && removeUrlQuery(key)
    );
  }, []);

  const closeModal = useCallback(
    (persistQsParams?: boolean) => {
      setModalStack({ type: "close" });
      if (!persistQsParams) {
        removeModalQsParams();
      }
    },
    [removeModalQsParams]
  );

  const openModal = useCallback((modalType: ModalType, props?: ObjectHash) => {
    if (!modalTypeMap.has(modalType)) {
      console.warn("unknown modal type", modalType);
      return;
    }

    const Component = modalTypeMap.get(modalType);

    const newModalConfig = {
      Component,
      key: uniqueId("modal-"),
      props,
      modalType
    };

    setModalStack({ type: "open", modal: newModalConfig });
  }, []);

  const modalIsOpen = useCallback(
    (modalType: ModalType): boolean => {
      return modalStack.some(
        (modal: Modal) => isModalConfig(modal) && modal.modalType === modalType
      );
    },
    [modalStack]
  );

  /*
   * Rather than opening a modal known to this context, open an arbitrary component
   * as if it were a modal. Currently only in use for grid app big tiles.
   */
  const openModalElement = useCallback((modalEl: ReactElement) => {
    const newModalConfig = {
      element: modalEl,
      key: uniqueId("modal-")
    };

    setModalStack({ type: "open", modal: newModalConfig });
  }, []);

  const linkModal = useCallback(
    (linkId: string, params?: ObjectHash) => {
      removeModalQsParams();
      addUrlQuery("modal", linkId);

      // namespace extra parameters' keys with the 'modal' prefix so they can be identified/removed/retrieved in other contexts
      if (params) {
        Object.keys(params).forEach((key: string) =>
          addUrlQuery(`modal${upperFirst(key)}`, params[key])
        );
      }
    },
    [removeModalQsParams]
  );

  const getModalLink = useCallback((linkId: string): ObjectHash | null => {
    const qs = getQueryStringParams();
    if (qs.modal !== linkId) {
      return null;
    }

    // remove the namespacing from extra parameters' keys that may have been added above in linkModal
    const plainQs: ObjectHash = {};
    Object.keys(qs).forEach(
      (key: string) =>
        (plainQs[lowerFirst(key.replace(/^modal(.+)/, "$1"))] = qs[key])
    );
    return plainQs;
  }, []);

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        getModalLink,
        linkModal,
        modalIsOpen,
        openModal,
        openModalElement,
        modalStack
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export const withModalContext = (Component: any) => {
  return (props: any) => {
    const { closeModal, getModalLink, openModal } = useContext(ModalContext);
    return (
      <Component
        openModal={openModal}
        getModalLink={getModalLink}
        closeModal={closeModal}
        {...props}
      />
    );
  };
};
