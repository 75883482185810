import React, { useState } from "react";
import { AppActionTypes } from "../../context/reducer";
import AppContainer from "../../container";
import { AppPageProps } from "../../router";
import Button from "../../components/button";
import InputController from "../../components/form/input-controller";
import CollectionModel from "../../models/collection.model";
import {
  CollectionService,
  ICollectionService
} from "../../services/collection.service";
import CollectionsStack from "./collections-stack";
import { CollectionValidator } from "../../utils/form";
import { ObjectHash } from "../../utils/helpers";
import PageHeader from "../../components/page-header";
import useApp from "../../hooks/use-app.hook";
import useModal from "../../hooks/use-modal.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";
import WomanWriting from "../../images/woman-writing.png";

import "./collections-page.scss";

export default function CollectionsPage(props: AppPageProps) {
  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );
  const { dispatch } = useApp();
  const { setSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const [formState, setFormState] = useState<ObjectHash>({ name: "" });
  const [formValid, setFormValid] = useState<boolean>(false);

  const handleInputChange = (value: string, meta?: ObjectHash) => {
    setFormState({ ...formState, name: value });
  };

  const handleSubmit = async () => {
    const newCollection = await collectionService.create(
      new CollectionModel(formState)
    );

    if (!newCollection) {
      setSnackbar({
        message: "There was a problem and your collection was not created",
        variant: "error"
      });
      return;
    }

    dispatch({
      type: AppActionTypes.UpdateCollection,
      payload: newCollection
    });

    openModal("grid-link", { collectionId: newCollection.id });
  };

  return (
    <div id="collections-page" className="page-container">
      <PageHeader title="Booking" />
      <div className="new-container">
        <div className="title">New Flight Booking Collection</div>
        <div className="description">
          Book flights for multiple travelers at one time by creating a new
          flight booking collection.
        </div>
        <div className="form">
          <InputController
            model={formState}
            templateInput={{
              key: "name",
              inputType: "text",
              label: "",
              helperText: "Ex: Flights for Sales team"
            }}
            customProps={{
              onChange: handleInputChange,
              placeholder: "Name Collection"
            }}
            onValidate={setFormValid}
            validator={CollectionValidator.name}
          />
          <Button
            color="product-blue"
            isDisabled={!formValid}
            label="Create New Collection"
            onClick={() => handleSubmit()}
            size="medium"
          />
        </div>

        <img src={WomanWriting} className="woman-writing" alt="Booking" />
      </div>
      <div className="list-view">
        <div className="title">Booking Collections</div>
        <CollectionsStack />
      </div>
    </div>
  );
}
