import React from "react";
import AutocompleteInputFilled from "./AutocompleteInputFilled";
import {
  getSelectValueFromOptions,
  SelectOption
} from "../../../utils/helpers";
import { RoleModel, UserRoles } from "../../../models/user.model";
import "./inputs.scss";

interface Props {
  defaultValue?: any[];
  isRequired?: boolean;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  value?: any;
}

const opts: SelectOption[] = UserRoles.map((role: RoleModel) => ({
  label: role.name,
  value: role.id
}));

export default function RoleInput(props: Props) {
  const valueProps = getSelectValueFromOptions(props, opts);
  const acProps = { ...props, ...valueProps };
  return <AutocompleteInputFilled {...acProps} options={opts} />;
}
