import BaseModel from "./base.model";

export default class LoyaltyProgramModel extends BaseModel {
  company: string;
  accountId: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.company = data.company;
    this.accountId = data.accountId;
  }

  getId(): string | null | undefined {
    return this.accountId;
  }
}

// @todo pull this from the API/templtes once its available
export const LoyaltyPrograms = [
  { name: "Air Canada Aeroplan", propertyMeta: { type: "AIR" } },
  {
    name: "Air France/KLM Flying Blue",
    propertyMeta: { type: "AIR" }
  },
  {
    name: "Alaska Airlines Mileage Plan",
    propertyMeta: { type: "AIR" }
  },
  { name: "Alitalia Millemiglia", propertyMeta: { type: "AIR" } },
  {
    name: "American Airlines AAdvantage",
    propertyMeta: { type: "AIR" }
  },
  { name: "ANA Mileage Club", propertyMeta: { type: "AIR" } },
  { name: "Asiana Club", propertyMeta: { type: "AIR" } },
  {
    name: "British Airways Executive Club",
    propertyMeta: { type: "AIR" }
  },
  {
    name: "Cathay Pacific Asia Miles",
    propertyMeta: { type: "AIR" }
  },
  { name: "Delta SkyMiles", propertyMeta: { type: "AIR" } },
  { name: "Emirates Skywards", propertyMeta: { type: "AIR" } },
  { name: "Etihad Guest", propertyMeta: { type: "AIR" } },
  { name: "myFRONTIER", propertyMeta: { type: "AIR" } },
  { name: "Iberia Plus", propertyMeta: { type: "AIR" } },
  { name: "JetBlue TrueBlue", propertyMeta: { type: "AIR" } },
  { name: "LATAM LATAMPASS", propertyMeta: { type: "AIR" } },
  { name: "Lufthansa Miles & More", propertyMeta: { type: "AIR" } },
  { name: "Qantas Frequent Flyer", propertyMeta: { type: "AIR" } },
  { name: "Qatar Privilege Club", propertyMeta: { type: "AIR" } },
  { name: "Singapore Kris Flyer", propertyMeta: { type: "AIR" } },
  { name: "Southwest Rapid Rewards", propertyMeta: { type: "AIR" } },
  {
    name: "Thai Airways Royal Orchid Club",
    propertyMeta: { type: "AIR" }
  },
  { name: "United Mileage Plus", propertyMeta: { type: "AIR" } },
  { name: "Virgin Atlantic", propertyMeta: { type: "AIR" } },
  {
    name: "Virgin Australia Velocity",
    propertyMeta: { type: "AIR" }
  },

  { name: "Alamo Insiders", propertyMeta: { type: "CAR" } },
  { name: "Avis Preferred", propertyMeta: { type: "CAR" } },
  { name: "Budget Business Program", propertyMeta: { type: "CAR" } },
  {
    name: "Dollar Express Renter Rewards",
    propertyMeta: { type: "CAR" }
  },
  { name: "Enterprise Plus", propertyMeta: { type: "CAR" } },
  { name: "Fox Rewards", propertyMeta: { type: "CAR" } },
  { name: "Hertz Gold Plus", propertyMeta: { type: "CAR" } },
  { name: "National Emerald Club", propertyMeta: { type: "CAR" } },
  {
    name: "Thrifty Blue Chip Rewards",
    propertyMeta: { type: "CAR" }
  },

  { name: "Accor", propertyMeta: { type: "HOTEL" } },
  { name: "Best Western", propertyMeta: { type: "HOTEL" } },
  {
    name: "Choice Hotels - Choice Privileges®",
    propertyMeta: { type: "HOTEL" }
  },
  {
    name: "Fairmont - Fairmont President's Club",
    propertyMeta: { type: "HOTEL" }
  },
  { name: "Hilton - Hilton Honors", propertyMeta: { type: "HOTEL" } },
  { name: "Hyatt - World of Hyatt", propertyMeta: { type: "HOTEL" } },
  {
    name: "IHG - Intercontinental - Ambassador",
    propertyMeta: { type: "HOTEL" }
  },
  { name: "IHG - Kimpton", propertyMeta: { type: "HOTEL" } },
  { name: "IHG - Rewards Club", propertyMeta: { type: "HOTEL" } },
  {
    name: "Marriott Bonvoy - Marriott",
    propertyMeta: { type: "HOTEL" }
  },
  {
    name: "Marriott Bonvoy - Ritz Carlton",
    propertyMeta: { type: "HOTEL" }
  },
  { name: "Omni", propertyMeta: { type: "HOTEL" } },
  {
    name: "Radisson - Radisson Rewards",
    propertyMeta: { type: "HOTEL" }
  },
  { name: "Wyndham", propertyMeta: { type: "HOTEL" } }
];
