import React from "react";
import { debounce } from "lodash";

import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "../../../../components/button";
import IntegerInput from "../../../../components/form/inputs/IntegerInput";

import "./format-block-menu.scss";

interface Props {
  blockId: string;
  blockType: string;
  className: string;
  innerBlockId?: string;
  onChangeBlockFormat: CallableFunction;
  options: any;
}

export default function FormatBlockMenuReact(props: Props) {
  const keyString = props.innerBlockId
    ? `${props.blockId}-${props.innerBlockId}`
    : `${props.blockId}`;

  const handleChangeTextAlign = (alignment: any) => {
    props.onChangeBlockFormat("textAlign", alignment);
  };

  const handleChangeVerticalAlign = (alignment: any) => {
    props.onChangeBlockFormat("alignSelf", alignment);
  };

  const handleChangeTextStyle = (type: any) => {
    const newVal = !props.options[type];
    props.onChangeBlockFormat(type, newVal);
  };

  const handleChangeImageWidth = (value: any) => {
    props.onChangeBlockFormat("width", value);
  };

  return (
    <div className={props.className || "format-block-menu"}>
      {props.blockType === "text" && (
        <Button
          key={`format-bold-${keyString}`}
          onClick={() => handleChangeTextStyle("isBold")}
          color={!props.options.isBold ? "gray" : "black"}
          icon="format-bold"
          isRippleDisabled={true}
          isTransparent={true}
          size="medium"
        />
      )}
      {props.blockType === "text" && (
        <Button
          key={`format-italic-${keyString}`}
          color={!props.options.isItalic ? "gray" : "black"}
          icon="format-italic"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeTextStyle("isItalic")}
          size="medium"
        />
      )}
      {props.blockType === "text" && (
        <Button
          key={`format-underlined-${keyString}`}
          color={!props.options.isUnderlined ? "gray" : "black"}
          icon="format-underline"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeTextStyle("isUnderlined")}
          size="medium"
        />
      )}
      {props.blockType === "text" && (
        <Button
          key={`format-size-${keyString}`}
          color={!props.options.isLargerFontSize ? "gray" : "black"}
          icon="format-size"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeTextStyle("isLargerFontSize")}
          size="medium"
        />
      )}
      {["text", "image"].includes(props.blockType) && (
        <Button
          key={`format-align-left-${keyString}`}
          color={!(props.options.textAlign === "left") ? "gray" : "black"}
          icon="format-hor-left"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeTextAlign("left")}
          size="medium"
        />
      )}
      {["text", "image"].includes(props.blockType) && (
        <Button
          key={`format-align-center-${keyString}`}
          color={!(props.options.textAlign === "center") ? "gray" : "black"}
          icon="format-hor-center"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeTextAlign("center")}
          size="medium"
        />
      )}
      {["text", "image"].includes(props.blockType) && (
        <Button
          key={`format-align-right-${keyString}`}
          color={!(props.options.textAlign === "right") ? "gray" : "black"}
          icon="format-hor-right"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeTextAlign("right")}
          size="medium"
        />
      )}
      {props.innerBlockId !== undefined && [
        <Button
          key={`format-align-self-top-${keyString}`}
          color={!(props.options.alignSelf === "flex-start") ? "gray" : "black"}
          isRippleDisabled={true}
          icon="format-vert-top"
          isTransparent={true}
          onClick={() => handleChangeVerticalAlign("flex-start")}
          size="medium"
        />,
        <Button
          key={`format-align-self-center-${keyString}`}
          color={!(props.options.alignSelf === "center") ? "gray" : "black"}
          icon="format-vert-center"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeVerticalAlign("center")}
          size="medium"
        />,
        <Button
          key={`format-align-self-bottom-${keyString}`}
          color={!(props.options.alignSelf === "flex-end") ? "gray" : "black"}
          icon="format-vert-bottom"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => handleChangeVerticalAlign("flex-end")}
          size="medium"
        />
      ]}
      {props.blockType === "image" && (
        <div
          className="format-block-menu__option"
          key={`format-width-${keyString}`}
        >
          <IntegerInput
            defaultValue={props.options.width}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            inputProps={{
              min: "1",
              max: "100",
              onKeyDown: (e: any) => {
                // ignore .+-eE
                if ([69, 187, 189, 190].includes(e.keyCode)) {
                  e.preventDefault();
                }
              },
              step: "1"
            }}
            onChange={debounce(
              (value: any) => handleChangeImageWidth(value),
              300
            )}
            variant="outlined"
          />
        </div>
      )}
    </div>
  );
}
