import { DateTime } from "luxon";
import { inject } from "inversify";
import { isObject } from "lodash";

import { ApiService, IApiService } from "./api.service";
import { AppActionTypes } from "../context/reducer";
import BaseService, { IBaseService } from "./base.service";
import BookingRequestModel from "../models/booking-request.model";
import CollectionModel from "../models/collection.model";
import { ObjectHash } from "../utils/helpers";
import {
  StackFilter,
  StackGroup,
  StackGroupResult
} from "../components/stack/helpers";
import UserModel from "../models/user.model";
import { IWebsocketService, WebsocketService } from "./websocket.service";

export interface ICollectionService extends IBaseService {
  batchCreateRequests(
    collection: CollectionModel,
    userIds: string[]
  ): Promise<BookingRequestModel[] | null>;

  create(collection: CollectionModel): Promise<CollectionModel | null>;

  createRequest(
    collection: CollectionModel,
    userId: string
  ): Promise<BookingRequestModel | null>;

  delete(collectionId: string): Promise<CollectionModel | null>;

  deleteRequest(requestId: string): Promise<BookingRequestModel | null>;

  filterBookingRequests(
    data: BookingRequestModel[],
    filters: StackFilter[]
  ): BookingRequestModel[];

  getBookingRequests(collectionId: string): Promise<BookingRequestModel[]>;

  getById(collectionId: string): Promise<CollectionModel | null>;

  getByUserId(userId: string): Promise<CollectionModel[]>;

  getRequestById(requestId: string): Promise<BookingRequestModel | null>;

  groupBookingRequests(
    requests: BookingRequestModel[],
    group: StackGroup
  ): StackGroupResult[];

  subscribeWs(dispatch: CallableFunction): Promise<void>;

  update(
    collectionId: string,
    data: ObjectHash
  ): Promise<CollectionModel | null>;

  updateRequest(
    requestId: string,
    data: ObjectHash
  ): Promise<BookingRequestModel | null>;
}

export class CollectionService extends BaseService
  implements ICollectionService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(WebsocketService)
  private websocketService!: IWebsocketService;

  async batchCreateRequests(
    collection: CollectionModel,
    userIds: string[]
  ): Promise<BookingRequestModel[] | null> {
    const { id } = collection;

    const requests = userIds.map((userId) =>
      this.getNewRequestPayload(collection, userId)
    );

    const response: ObjectHash[] = await this.apiService.post(
      `/booking-collections/${id}/booking-requests`,
      requests
    );

    if (!response) {
      return null;
    }

    return response.map(
      (request: ObjectHash) => new BookingRequestModel(request)
    );
  }

  async create(collection: CollectionModel): Promise<CollectionModel | null> {
    const response = await this.apiService.post(
      "/booking-collections",
      collection.toJSON()
    );

    if (!response) {
      return null;
    }

    return new CollectionModel(response);
  }

  async createRequest(
    collection: CollectionModel,
    userId: string
  ): Promise<BookingRequestModel | null> {
    const requestData = this.getNewRequestPayload(collection, userId);

    const response = await this.apiService.post(
      "/booking-requests",
      requestData
    );

    if (!response) {
      return null;
    }

    return new BookingRequestModel(response);
  }

  async delete(collectionId: string): Promise<CollectionModel | null> {
    const response = await this.apiService.delete(
      `/booking-collections/${collectionId}`
    );

    if (!response) {
      return null;
    }

    return new CollectionModel(response);
  }

  async deleteRequest(requestId: string): Promise<BookingRequestModel | null> {
    const response = await this.apiService.delete(
      `/booking-requests/${requestId}`
    );

    if (!response) {
      return null;
    }

    return new BookingRequestModel(response);
  }

  filterBookingRequests(
    data: BookingRequestModel[],
    filters: StackFilter[]
  ): BookingRequestModel[] {
    return data.filter((model: BookingRequestModel) => {
      return filters.every((filter: StackFilter) => {
        const { fieldId, value } = filter;
        const { bookingType, notes, status, users } = model;

        switch (fieldId) {
          case "traveler":
            if (!value?.length) {
              return true;
            }
            const userIds = value.map((user: UserModel) => user.id);
            return users.some((user: UserModel) => userIds.includes(user.id));

          case "bookingDates":
            if (!value?.length) {
              return true;
            }

            const dates = model.getDates();
            const requestStartDate = dates[0]
              ? DateTime.fromISO(dates[0])
              : null;
            const requestEndDate = dates[1] ? DateTime.fromISO(dates[1]) : null;

            const startDate = value[0] ? DateTime.fromISO(value[0]) : null;
            const endDate = value[1] ? DateTime.fromISO(value[1]) : null;

            // does the first segment start date come after the filter start date?
            if (startDate && requestStartDate && startDate > requestStartDate) {
              return false;
            }

            // does the last segment end date come before the filter end date?
            if (endDate && requestEndDate && endDate < requestEndDate) {
              return false;
            }

            return true;

          case "inboundDepartureAirport":
          case "inboundArrivalAirport":
            if (!value) {
              return true;
            }
            if (bookingType === "one-way") {
              return false;
            }

            const inboundSegments = model.getSegments();
            const lastSegment = inboundSegments[inboundSegments.length - 1];

            if (!lastSegment) {
              return false;
            }

            const inboundLocation =
              fieldId === "inboundArrivalAirport"
                ? lastSegment.endLocation
                : lastSegment.startLocation;

            return inboundLocation === value;

          case "keyword":
            const user = users[0];
            return [
              user.firstName,
              user.lastName,
              user.middleName,
              user.email,
              notes
            ]
              .filter((v) => v)
              .some((keywordValue: string) =>
                keywordValue.toLowerCase().includes(String(value).toLowerCase())
              );

          case "outboundDepartureAirport":
          case "outboundArrivalAirport":
            if (!value) {
              return true;
            }

            const outboundSegments = model.getSegments();
            const firstSegment = outboundSegments[0];

            if (!firstSegment) {
              return false;
            }

            const outboundLocation =
              fieldId === "outboundArrivalAirport"
                ? firstSegment.endLocation
                : firstSegment.startLocation;

            return outboundLocation === value;

          case "status":
            if (!value) {
              return true;
            }

            return value === status;

          default:
            return true;
        }
      });
    });
  }

  async getBookingRequests(
    collectionId: string
  ): Promise<BookingRequestModel[]> {
    const response = await this.apiService.get(
      `/booking-collections/${collectionId}/booking-requests`
    );

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new BookingRequestModel(data));
  }

  async getById(collectionId: string): Promise<CollectionModel | null> {
    const response = await this.apiService.get(
      `/booking-collections/${collectionId}`
    );

    if (!response) {
      return null;
    }

    return new CollectionModel(response);
  }

  async getByUserId(userId: string): Promise<CollectionModel[]> {
    const response = await this.apiService.get("/booking-collections");

    if (!response) {
      return [];
    }

    // return only collection the user created, or is a collaborator on
    return response
      .map((data: any) => new CollectionModel(data))
      .filter(
        (collection: CollectionModel) =>
          collection.creator.id === userId ||
          collection.collaborators
            .map((user: UserModel) => user.id)
            .includes(userId)
      );
  }

  private getNewRequestPayload(
    collection: CollectionModel,
    userId: string
  ): ObjectHash {
    const { defaultBookingValues, id } = collection;
    const {
      bookingDates = [],
      endDesiredTime = "",
      endLocation = "",
      notes = "",
      startDesiredTime = "",
      startLocation = ""
    } = defaultBookingValues;

    // default booking type for new requests is round-trip, which is always 2 segments with reflected locations
    const bookingSegmentData: ObjectHash[] = [
      // outbound
      {
        startDate: bookingDates[0] || "",
        endDate: bookingDates[0] || "",
        endDesiredTime,
        endLocation,
        startDesiredTime,
        startLocation
      },
      // inbound
      {
        startDate: bookingDates[1] || "",
        endDate: bookingDates[1] || "",
        endDesiredTime: "",
        endLocation: startLocation,
        startDesiredTime: "",
        startLocation: endLocation
      }
    ];

    const requestData: ObjectHash = {
      bookingCollection: id,
      bookingSegments: bookingSegmentData,
      bookingType: "round-trip",
      notes,
      status: "initiated",
      users: [userId]
    };

    return requestData;
  }

  async subscribeWs(dispatch: CallableFunction): Promise<void> {
    this.websocketService.subscribe(
      "BookingCollectionUpdates",
      async (data: ObjectHash, initial?: boolean) => {
        if (!initial && isObject(data)) {
          const payload = new CollectionModel(data);
          const type = payload.deleted
            ? AppActionTypes.DeleteCollection
            : AppActionTypes.UpdateCollection;

          dispatch({ type, payload });
        }
      }
    );
  }

  async getRequestById(requestId: string): Promise<BookingRequestModel | null> {
    const response = await this.apiService.get(
      `/booking-requests/${requestId}`
    );

    if (!response) {
      return null;
    }

    return new BookingRequestModel(response);
  }

  groupBookingRequests(
    requests: BookingRequestModel[],
    group: StackGroup
  ): StackGroupResult[] {
    const results: StackGroupResult[] = [];
    const { fieldId } = group;

    requests.forEach((request: BookingRequestModel) => {
      let value = "default";
      const { bookingType, status } = request;
      const segments = request.getSegments();
      const firstSegment = segments[0];
      const lastSegment = segments[segments.length - 1];
      const dates = request.getDates();

      switch (fieldId) {
        case "bookingType":
          value = bookingType;
          break;
        case "departDate":
          value = dates[0];
          break;
        case "inboundArrivalAirport":
          value = lastSegment.endLocation;
          break;
        case "inboundDepartureAirport":
          value = lastSegment.startLocation;
          break;
        case "outboundArrivalAirport":
          value = firstSegment.endLocation;
          break;
        case "outboundDepartureAirport":
          value = firstSegment.startLocation;
          break;
        case "returnDate":
          value = dates[1];
          break;

        case "status":
          value = status;
          break;
      }

      let valueIndex = results.findIndex((group) => group.value === value);
      if (valueIndex === -1) {
        results.push({ value, data: [] });
        valueIndex = results.length - 1;
      }

      results[valueIndex].data.push(request);
    });

    return results;
  }

  async update(
    collectionId: string,
    data: ObjectHash
  ): Promise<CollectionModel | null> {
    const response = await this.apiService.put(
      `/booking-collections/${collectionId}`,
      data
    );

    if (!response) {
      return null;
    }

    return new CollectionModel(response);
  }

  async updateRequest(
    requestId: string,
    data: ObjectHash
  ): Promise<BookingRequestModel | null> {
    const response = await this.apiService.put(
      `/booking-requests/${requestId}`,
      data
    );

    if (!response) {
      return null;
    }

    return new BookingRequestModel(response);
  }
}
