import React, { useState } from "react";
import BookingRequestModel from "../../../models/booking-request.model";
import BookingSegmentModel from "../../../models/booking-segment.model";
import { TemplateInput } from "../../../services/workspace.service";
import InputController from "../input-controller";
import RadioToggleButton from "./RadioToggleButton";

interface Props {
  onChange: CallableFunction;
  request: BookingRequestModel;
}

export default function BookingRequestSegmentInput(props: Props) {
  const { onChange, request } = props;
  const { bookingType, bookingSegments = [] } = request;

  const [formState, setFormState] = useState<BookingSegmentModel[]>(
    bookingSegments
  );

  /*
   * Note that segmentIndex is not in use here. See the note below. We are not currently
   * supporting changing anything other than the first segment, and for round-trip the
   * 2nd segment is a reflection of the 1st.
   */
  const handleChange = (segmentIndex: number, key: string, value: any) => {
    const updatedFormState = [...formState];
    updatedFormState[0][key as keyof BookingSegmentModel] = value;

    if (bookingType === "round-trip") {
      updatedFormState[1].startLocation = updatedFormState[0].endLocation;
      updatedFormState[1].endLocation = updatedFormState[0].startLocation;
    }

    setFormState(updatedFormState);
    onChange(updatedFormState);
  };

  const locationElements = formState.map(
    (segment: BookingSegmentModel, index: number) => {
      const { startDesiredTime, endDesiredTime } = segment;
      const hasStartTime = Boolean(startDesiredTime);
      const hasEndTime = Boolean(endDesiredTime);

      const startInput: TemplateInput = {
        key: `startLocation`,
        label: "Coming From?",
        labelHelperText: "Which airport are you coming from?",
        inputType: "airport"
      };

      const startTimeInput: TemplateInput = {
        key: `startDesiredTime`,
        label: "",
        inputType: "time"
      };

      const endInput: TemplateInput = {
        key: `endLocation`,
        label: "Going to?",
        labelHelperText: "Which airport are you headed to?",
        inputType: "airport"
      };

      const endTimeInput: TemplateInput = {
        key: `endDesiredTime`,
        label: "",
        inputType: "time"
      };

      return (
        <div className="segment-row" key={`segment-row-${index}`}>
          <div className="start">
            <InputController
              model={segment}
              templateInput={startInput}
              customProps={{
                onChange: (value: any) =>
                  handleChange(index, "startLocation", value)
              }}
            />
            <div className="time-container">
              <div className="label">
                <RadioToggleButton
                  on={hasStartTime}
                  onChange={(on: boolean) => {
                    handleChange(index, "startDesiredTime", on ? "12:00" : "");
                  }}
                />
                <div>Leave by a certain time?</div>
              </div>
              <div className="input">
                <InputController
                  model={segment}
                  templateInput={startTimeInput}
                  customProps={{
                    onChange: (value: any) =>
                      handleChange(index, "startDesiredTime", value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="end">
            <InputController
              model={segment}
              templateInput={endInput}
              customProps={{
                onChange: (value: any) =>
                  handleChange(index, "endLocation", value)
              }}
            />
            <div className="time-container">
              <div className="label">
                <RadioToggleButton
                  on={hasEndTime}
                  onChange={(on: boolean) => {
                    handleChange(index, "endDesiredTime", on ? "12:00" : "");
                  }}
                />
                <div>Arrive by a certain time?</div>
              </div>
              <div className="input">
                <InputController
                  model={segment}
                  templateInput={endTimeInput}
                  customProps={{
                    onChange: (value: any) =>
                      handleChange(index, "endDesiredTime", value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  );

  /*
   * Note below that we only ever show the first value from locationElements. For now, we aren't
   * allowing users to modify anything other than the first segment, since there is no multi-city
   * support, and round-trip 2nd segments are strictly reflections of the 1st segment.
   */

  return (
    <div className="booking-request-segment-input">{locationElements[0]}</div>
  );
}
