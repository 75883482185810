import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import DocumentModel from "../../../models/document.model";
import { ObjectHash } from "../../../utils/helpers";
import useModal from "../../../hooks/use-modal.hook";

import Button from "../../button";
import CloseButton from "../../button/close";
import Tooltip from "../../tooltip";
import TextInput from "../../form/inputs/TextInput";

import "./document-dialog.scss";

interface Props {
  document?: DocumentModel;
  target: "document" | "template";
  fromBlank?: boolean;
  onAddDocument: CallableFunction;
  onChangeDocument: CallableFunction;
  template?: ObjectHash;
}

export default function DocumentDialog(props: Props) {
  const { closeModal } = useModal();

  const {
    target,
    document,
    fromBlank,
    onAddDocument,
    onChangeDocument,
    template
  } = props;

  let zeroState: ObjectHash = {
    name: ""
  };

  if (document) {
    zeroState = document;
  } else if (template) {
    zeroState = template;
  }

  const [state, setState] = useState({
    ...zeroState
  });

  const handleTextFieldChange = (key: string, v: string) => {
    setState((oldValues) => ({ ...oldValues, [key]: v }));
  };

  const textFieldProps = {
    className: "input--text",
    fullWidth: true,
    InputProps: {
      disableUnderline: true
    },
    InputLabelProps: {
      shrink: true
    },
    variant: "filled" as "filled" // Workaround to prevent TS error
  };

  const handleAddDocumentClick = () => {
    onAddDocument(state);
    closeModal();
  };

  const handleChangeDocumentClick = () => {
    onChangeDocument(state);
    closeModal();
  };

  const targetIsDocument = target === "document";
  const docLabel = targetIsDocument ? "Document" : "Template";

  let headerText = `New ${docLabel}`;
  let buttonText = `Create ${docLabel}`;

  if (document) {
    headerText = `Rename ${docLabel}`;
    buttonText = "Save Changes";
  }

  if (targetIsDocument && template) {
    headerText = fromBlank ? "New Blank Document" : "New Template Document";
  }

  return (
    <Dialog
      className="document-dialog__overlay"
      onClose={() => closeModal()}
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <div className="document-dialog__title">
          <h1>{headerText}</h1>
          <Tooltip text="Close">
            <CloseButton onClick={() => closeModal()} size="medium" />
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <TextInput
          autoFocus={true}
          defaultValue={zeroState.name}
          label="Name"
          onChange={(v: any) => handleTextFieldChange("name", v)}
          {...textFieldProps}
        />
      </DialogContent>
      <DialogActions classes={{ root: "dialog-actions" }}>
        <Button
          color="product-blue"
          isDisabled={!state.name.length}
          isFullWidth={true}
          label={buttonText}
          onClick={() => {
            if (!template && !document) {
              handleAddDocumentClick();
            } else {
              handleChangeDocumentClick();
            }
          }}
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
