import { inject } from "inversify";
import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import { AuthService, IAuthService } from "./auth.service";
import { IImpersonateService, ImpersonateService } from "./impersonate.service";
import { SearchService, ISearchService } from "./search.service";
import DocumentModel from "../models/document.model";
import env from "../utils/env";
import { ObjectHash } from "../utils/helpers";

export interface IDocumentService {
  create(data: ObjectHash): Promise<DocumentModel | null>;
  createFromDefaultTemplate(
    templateId: string,
    data: ObjectHash
  ): Promise<DocumentModel | null>;
  delete(documentId: string): Promise<DocumentModel | null>;
  getById(documentId: string): Promise<DocumentModel | null>;
  getDefaultTemplates(): Promise<DocumentModel[]>;
  getPDFUrl(documentId: string): Promise<string | null>;
  getTemplates(): Promise<DocumentModel[]>;
  trackDownload(documentId: string): Promise<boolean>;
  update(document: DocumentModel): Promise<DocumentModel | null>;
}

export class DocumentService extends BaseService implements IDocumentService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(AuthService)
  private authService!: IAuthService;

  @inject(ImpersonateService)
  private impersonateService!: IImpersonateService;

  @inject(SearchService)
  private searchService!: ISearchService;

  async create(data: ObjectHash): Promise<DocumentModel | null> {
    const response = await this.apiService.post("/documents?basic=true", data);

    if (!response) {
      return null;
    }

    return new DocumentModel(response);
  }

  async createFromDefaultTemplate(
    templateId: string,
    data: ObjectHash
  ): Promise<DocumentModel | null> {
    const response = await this.apiService.post(
      `/defaults/documents/duplicate/${templateId}`,
      data
    );

    if (!response) {
      return null;
    }

    return new DocumentModel(response);
  }

  async delete(documentId: string): Promise<DocumentModel | null> {
    const response = await this.apiService.delete(`/documents/${documentId}`);

    if (!response) {
      return null;
    }

    return new DocumentModel(response);
  }

  async getById(documentId: string): Promise<DocumentModel | null> {
    const response = await this.apiService.get(
      `/documents/${documentId}?basic=true`
    );

    if (!response) {
      return null;
    }

    return new DocumentModel(response);
  }

  async getDefaultTemplates(): Promise<DocumentModel[]> {
    const response = await this.apiService.get("/defaults/documents");

    if (!response) {
      return [];
    }

    return response.map((document: ObjectHash) => new DocumentModel(document));
  }

  async getPDFUrl(documentId: string): Promise<string | null> {
    const shareLink = await this.apiService.get(`/share/${documentId}`);

    if (!shareLink?.shareHash) {
      return null;
    }

    const uri = `/documents/${documentId}/${shareLink.shareHash}/pdf`;

    const response = await this.apiService.get(uri, {
      blocks: true,
      tmp: true
    });

    if (!response) {
      return null;
    }

    const url = `${env.api}${response}`.replace("api/api", "api");

    return url;
  }

  async getTemplates(): Promise<DocumentModel[]> {
    const response = await this.searchService.search("documents", {
      filters: [["isTemplate", [true]]],
      fields: ["blocks", "html", "name"],
      limit: 100
    });

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new DocumentModel(data));
  }

  // @todo are these stats still in use?
  async trackDownload(documentId: string): Promise<boolean> {
    if (!this.authService.isAuthenticated()) {
      return false;
    }

    if (this.impersonateService.isActive()) {
      return false;
    }

    await this.apiService.post("/trackers", {
      type: "DocumentPDF",
      data: { documentId }
    });

    return true;
  }

  async update(document: DocumentModel): Promise<DocumentModel | null> {
    const response = await this.apiService.put(
      `/documents/${document.id}`,
      document
    );

    if (!response) {
      return null;
    }

    return new DocumentModel(response);
  }
}
