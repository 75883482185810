import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import classnames from "classnames";
import { debounce } from "lodash";

import { Column, Filter, Group, Sort } from "../../../sink/helpers";
import { Columns } from "./sink";
import {
  getTmlLogEntryGroupId,
  updateEntry,
  updateEntryJobTitle
} from "../lib/service";
import { LoadState, ObjectHash } from "../../../../utils/helpers";
import Sink from "../../../sink";
import TagChip from "../../../tag-chip";
import TagGroupModel from "../../../../models/tag-group.model";
import TagModel from "../../../../models/tag.model";
import TextInput from "../../../form/inputs/TextInput";
import { TmlActionTypes } from "../context/reducer";
import { TmlEntryModel, TmlLogModel } from "../lib/models";
import TmlSegmentCell from "./tml-segment-cell";
import TmlEditCell from "./tml-edit-cell";
import TmlTravelerCell from "./tml-traveler-cell";
import UserModel from "../../../../models/user.model";
import { useTmlLog } from "../context";
import useSnackbar from "../../../../hooks/use-snackbar.hook";

import "./tml-entries.scss";

interface Props {
  columns: Column[];
  emptyMessage?: string;
  filters: Filter[];
  group: Group | null;
  hideGridHead?: boolean;
  portalTarget: RefObject<HTMLElement>;
  entries: TmlEntryModel[];
  sort: Sort;
}

export default function TmlEntrySink(props: Props) {
  const { columns, entries, group, hideGridHead = false, portalTarget } = props;

  const { dispatch, log, trips, users } = useTmlLog();
  const { setSnackbar } = useSnackbar();
  const [loadState, setLoadState] = useState<LoadState>("loading");

  const customColumns = useMemo(() => {
    const defaultColumnIds = Columns.map((column: Column) => column.id);
    return columns.filter(
      (column: Column) =>
        !defaultColumnIds.includes(column.id) &&
        !TagGroupModel.isFieldId(column.id)
    );
  }, [columns]);

  const handleChangeEntry = useCallback(
    async (entry: TmlEntryModel): Promise<TmlLogModel | null> => {
      const updatedLog = await updateEntry(log, entry);

      if (!updatedLog) {
        setSnackbar({
          message: "There was a problem and the entry was not updated",
          variant: "error"
        });
        return null;
      }

      dispatch({
        type: TmlActionTypes.UpdateLog,
        payload: updatedLog
      });

      return updatedLog;
    },
    [log, dispatch, setSnackbar]
  );

  const handleChangeEntryJobTitle = useCallback(
    async (
      entry: TmlEntryModel,
      jobTitle: string
    ): Promise<TmlLogModel | null> => {
      const updatedLog = await updateEntryJobTitle(log, entry, jobTitle);

      if (!updatedLog) {
        setSnackbar({
          message: "There was a problem and the entries were not updated",
          variant: "error"
        });
        return null;
      }

      dispatch({
        type: TmlActionTypes.UpdateLog,
        payload: updatedLog
      });

      return updatedLog;
    },
    [log, dispatch, setSnackbar]
  );

  const getRows = useCallback(
    (entries: TmlEntryModel[]): ObjectHash[] => {
      return entries.map((entry: TmlEntryModel, index: number) => {
        const { userId, notes, type, updatedAt } = entry;
        const user = users.get(userId) || new UserModel();
        const userTrips = trips.get(userId) || [];

        const rowData: ObjectHash = {
          entryType: (
            <div className="tml-cell entry-type">
              <div className={classnames("entry-type-tag", type)}>
                {type === "inbound" ? "Arrival" : "Departure"}
              </div>
            </div>
          ),
          traveler: (
            <TmlTravelerCell
              entry={entry}
              key={updatedAt}
              onChange={(jobTitle: string) =>
                handleChangeEntryJobTitle(entry, jobTitle)
              }
              portalTarget={portalTarget}
              user={user}
            />
          ),
          travelSegment: (
            <TmlSegmentCell
              entry={entry}
              onChange={handleChangeEntry}
              portalTarget={portalTarget}
              segmentId="travelSegment"
              trips={userTrips}
            />
          ),
          transportSegment: (
            <TmlSegmentCell
              entry={entry}
              onChange={handleChangeEntry}
              portalTarget={portalTarget}
              segmentId="transportSegment"
              trips={userTrips}
            />
          ),
          locationSegment: (
            <TmlSegmentCell
              entry={entry}
              onChange={handleChangeEntry}
              portalTarget={portalTarget}
              segmentId="locationSegment"
              trips={userTrips}
            />
          ),
          notes: (
            <div className="tml-cell notes">
              <TextInput
                key={`tml-cell-notes-${userId}-${type}`}
                placeholder="Notes"
                defaultValue={notes}
                onChange={debounce((value: string) => {
                  const updatedEntry = new TmlEntryModel({ ...entry });
                  updatedEntry.notes = value;
                  handleChangeEntry(updatedEntry);
                }, 1000)}
              />
            </div>
          )
        };

        // add user's available tags to their row data
        if (user?.tags) {
          const tagsByGroup: ObjectHash = {};
          user.tags.forEach((tag: TagModel) => {
            const columnId = tag.getTagGroupFieldId();
            if (!tagsByGroup[columnId]) {
              tagsByGroup[columnId] = [];
            }
            tagsByGroup[columnId].push(<TagChip tag={tag} key={tag.id} />);
          });

          Object.keys(tagsByGroup).forEach((columnId: string) => {
            rowData[columnId] = (
              <div className="tml-cell tags">{tagsByGroup[columnId]}</div>
            );
          });
        }

        if (customColumns.length) {
          customColumns.forEach((column: Column) => {
            const { id } = column;

            rowData[id] = (
              <TmlEditCell
                onChange={(value: string) => {
                  const updatedEntry = new TmlEntryModel({ ...entry });
                  updatedEntry.customData[id] = value;
                  handleChangeEntry(updatedEntry);
                }}
                portalTarget={portalTarget}
                defaultValue={entry.customData[id] || ""}
              />
            );
          });
        }

        if (group) {
          rowData.sinkGroupId = getTmlLogEntryGroupId(entry, group, user);
        }

        return rowData;
      });
    },
    [
      customColumns,
      group,
      handleChangeEntry,
      handleChangeEntryJobTitle,
      portalTarget,
      trips,
      users
    ]
  );

  useEffect(() => {
    if (!users.size) {
      return;
    }

    if (!entries.length) {
      return;
    }

    setLoadState("loaded");
  }, [entries, users, setLoadState]);

  return (
    <div className="tml-entry-sink">
      {loadState === "loaded" && (
        <Sink
          data={getRows(entries)}
          columns={columns}
          group={group}
          hideGridHead={hideGridHead}
        />
      )}
    </div>
  );
}
