import { startCase } from "lodash";
import AppContainer from "../../../container";
import { ApiService, IApiService } from "../../../services/api.service";
import { UserService, IUserService } from "../../../services/user.service";
import UserModel from "../../../models/user.model";

export const batchDeleteObject = ({
  resource,
  ids = [],
  setSnackbar,
  openModal
}: {
  resource: string;
  ids: any[];
  setSnackbar: CallableFunction;
  openModal: CallableFunction;
}) =>
  new Promise((res) => {
    if (!resource || !ids || !ids.length) return res(false);
    const apiService: IApiService = AppContainer.get(ApiService);
    let label = "these";
    let resourceLabel = startCase(resource);
    if (ids.length === 1) {
      label = "this";
      if (resourceLabel.endsWith("s"))
        resourceLabel = resourceLabel.slice(0, -1);
    }
    return openModal("confirm", {
      dialogTitle: `Delete ${resourceLabel}`,
      dialogBody: `Are you sure you want to delete ${label} ${resourceLabel.toLowerCase()}? This cannot be undone.`,
      onConfirm: () =>
        res(
          apiService.delete(`/batch/${resource}`, { ids }).then(() =>
            setSnackbar({
              message: `${resourceLabel} deleted!`,
              variant: "success"
            })
          )
        )
    });
  });

export const batchDuplicateObject = ({
  resource,
  ids = [],
  setSnackbar
}: {
  resource: string;
  ids: any[];
  setSnackbar: CallableFunction;
}) =>
  new Promise((res) => {
    if (!resource || !ids || !ids.length) return res(false);
    const apiService: IApiService = AppContainer.get(ApiService);
    return res(
      apiService.post(`/${resource}/batch-duplicate`, { ids }).then(() => {
        setSnackbar({
          message: "Duplication completed!",
          variant: "success"
        });
      })
    );
  });

export const handleRowClick = async (
  data: { [index: string]: any },
  resource: string,
  history: any,
  doSearch: CallableFunction,
  locationsData: any[],
  openModal: CallableFunction
) => {
  const userService: IUserService = AppContainer.get(UserService);
  const overlayPayloadData = JSON.parse(JSON.stringify(data));

  if (["AIR", "RAIL"].includes(overlayPayloadData.type)) {
    if (overlayPayloadData.tripId) {
      overlayPayloadData.id = overlayPayloadData.tripId; // use trip level id for calling quick add to edit segmented trip in document table
    }
  }

  if (resource === "documents") {
    history.push(`/documents/${overlayPayloadData.id}/build`);
  }

  if (resource === "grids") {
    history.push(`/grids/${overlayPayloadData.id}`);
  }

  if (["trips", "users"].includes(resource)) {
    const onClose = () => {
      if (doSearch instanceof Function) {
        doSearch();
      }
    };
    const overlayPayload: { [index: string]: any } = {
      resource,
      data: overlayPayloadData,
      onClose
    };

    if (
      overlayPayload.resource === "trips" &&
      ["AIR", "RAIL"].includes(overlayPayloadData.type)
    ) {
      overlayPayload.resource = "segments";
      overlayPayload.overlayPayloadData = [
        {
          tripId: overlayPayloadData.id,
          id: "null",
          type: overlayPayloadData.type
        }
      ];
    }

    if (resource === "trips") {
      const dataType = String(overlayPayloadData.type).toUpperCase();
      const quickAddType = ["AIR", "RAIL"].includes(dataType)
        ? "quick-add-large"
        : "quick-add";

      openModal(quickAddType, {
        data: overlayPayloadData,
        dataType,
        locationsData,
        onChange: () => doSearch && doSearch()
      });
    } else if (resource === "users") {
      openModal("user", {
        userId: overlayPayloadData.id,
        onChange: () => doSearch && doSearch()
      });
    }
  }

  if (resource === "travelers") {
    const userId = overlayPayloadData.userId
      ? overlayPayloadData.userId
      : overlayPayloadData.id;
    const userData: UserModel | null = await userService.getById(userId, true);
    if (!userData) {
      return;
    }

    openModal("quick-add", {
      data: userData,
      dataType: "PROFILE",
      onChange: () => doSearch && doSearch(),
      resource: "users"
    });
  }
};
