import React from "react";
import ClipboardJS from "clipboard";
import Button from "..";
import Tooltip from "../../tooltip";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import { AppContext } from "../../../context";

export interface Props {
  email: string;
}

export default function CopyEmailForwardButton(props: Props) {
  const { email } = props;

  new ClipboardJS(".copy-email-forward-icon-button");

  const { setSnackbar } = useSnackbar();

  return (
    <AppContext.Consumer>
      {(appState) =>
        appState.user.email?.endsWith("@nfl.com") ? null : (
          <Tooltip text="Copy forward address">
            <span
              className="copy-email-forward-icon-button"
              data-clipboard-text={email}
            >
              <Button
                color="background-gray"
                icon="file-copy"
                onClick={() =>
                  setSnackbar({
                    message: "Email address copied!",
                    variant: "success"
                  })
                }
                size="medium"
              />
            </span>
          </Tooltip>
        )
      }
    </AppContext.Consumer>
  );
}
