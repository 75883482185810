import React from "react";
import { resolve } from "inversify-react";

import ObjectTable from "../object-table";

import { AppContext } from "../../context";
import { ObjectHash } from "../../utils/helpers";
import { GridService, IGridService } from "../../services/grid.service";
import TravelerModel from "../../models/traveler.model";

interface Props {
  gridId: string;
}

interface State {
  travelers: ObjectHash[];
  isLoaded: boolean;
}

export default class RoomingList extends React.Component<Props, State> {
  @resolve(GridService)
  private gridService!: IGridService;

  constructor(props: Props) {
    super(props);
    this.state = { travelers: [], isLoaded: false };
  }

  async componentDidMount() {
    const { gridId } = this.props;
    let travelers: ObjectHash[] = [];

    const response = await this.gridService.getTravelers(gridId);

    travelers = response.map((traveler: TravelerModel) => {
      const { travelerMeta } = traveler;
      const travelerExtra: ObjectHash = { ...traveler };

      travelerExtra.nights = travelerMeta.nights;
      travelerExtra.arrivalTime = travelerMeta.arrival?.whenTo;
      travelerExtra.departureTime = travelerMeta.departure?.whenFrom;
      travelerExtra.phone = traveler.data?.phone;

      return travelerExtra;
    });

    this.setState({
      travelers,
      isLoaded: true
    });
  }

  render() {
    const { travelers, isLoaded } = this.state;

    if (!isLoaded) {
      return null;
    }

    return (
      <AppContext.Consumer>
        {(appState) => (
          <ObjectTable
            title="Travelers"
            columns={[
              ["name", "Name"],
              ["arrivalTime", "Arrival"],
              ["departureTime", "Departure"],
              ["nights", "Nights Required"],
              ["email", "Email"],
              ["phone", "Phone"]
            ]}
            renderOpts={{
              disableRowClick: true,
              disableDownload: true,
              disablePagination: true,
              disableRowSelect: true,
              useReportStyle: true,
              reportAccentColor: "green"
            }}
            searchOpts={{ resource: "users" }}
            staticResults={travelers.filter((t) => t && t.arrivalTime)}
            company={appState.company}
            templates={appState.templates}
            settings={appState.settings}
            user={appState.user}
          />
        )}
      </AppContext.Consumer>
    );
  }
}
