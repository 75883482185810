import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { IUserService, UserService } from "../../../../services/user.service";

import AppContainer from "../../../../container";
import { ObjectHash } from "../../../../utils/helpers";

import { FormConfig, UserProgramValidator } from "../../../../utils/form";
import LoyaltyProgramModel from "../../../../models/loyalty-program.model";
import UserModel from "../../../../models/user.model";
import FormController from "../../../form/form-controller";
import { TemplateInput } from "../../../../services/workspace.service";

interface Props {
  program?: LoyaltyProgramModel;
  menuPortalTarget: any;
  onFormChange?: CallableFunction;
  onSave?: CallableFunction;
  onReadOnly?: CallableFunction;
  onValidate?: CallableFunction;
  readOnly?: boolean;
  user: UserModel;
}

const formConfig: FormConfig = {
  validator: UserProgramValidator,
  fields: [
    {
      key: "company",
      label: "Program Name",
      inputType: "loyalty-program"
    },
    {
      key: "accountId",
      label: "Program Number",
      inputType: "text"
    }
  ]
};

const ProfileProgramForm = forwardRef((props: Props, ref: any) => {
  const {
    menuPortalTarget,
    onFormChange,
    onSave,
    onValidate,
    onReadOnly,
    program = new LoyaltyProgramModel(),
    readOnly,
    user
  } = props;

  const userService: IUserService = AppContainer.get(UserService);

  const formRef = useRef();

  const { validator, fields } = formConfig;

  const handleCustomProps = (
    templateInput: TemplateInput,
    customProps: ObjectHash
  ): ObjectHash => {
    customProps.menuPortalTarget = menuPortalTarget;
    return customProps;
  };

  const handleSave = async (formState: ObjectHash) => {
    const { company } = formState;
    const existingPrograms = { ...user.loyaltyPrograms };

    /*
     * Since programs are stored in an object keyed by program name, changing the program name (while a somewhat infrequent action)
     * would change the object key, creating a new program entry, rather than updating the existing. To resolve this issue, clear out
     * an existing program before setting a new one.
     */
    if (program) {
      existingPrograms[program.company] = new LoyaltyProgramModel();
    }

    const userPrograms = { ...existingPrograms, [company]: formState };

    const updatedUser = await userService.update(
      user.id,
      {
        loyaltyPrograms: userPrograms
      },
      true
    );

    onSave && onSave(updatedUser);
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      formRef.current && (formRef.current as any).save();
    }
  }));

  return (
    <div className="profile-program-form">
      {readOnly && (
        <div className="readonly-header">
          <div className="readonly-header--title">Program Details</div>
          <div className="readonly-header--edit">
            <span
              className="edit-button"
              onClick={() => onReadOnly && onReadOnly()}
            >
              Edit
            </span>
          </div>
        </div>
      )}
      <div className="form-container">
        <FormController
          fields={fields}
          model={program}
          onCustomProps={handleCustomProps}
          onFormChange={(changed: boolean) =>
            onFormChange && onFormChange(changed)
          }
          onSave={handleSave}
          onValidate={(formValid: boolean) =>
            onValidate && onValidate(formValid)
          }
          readOnly={readOnly}
          ref={formRef}
          validator={validator}
        />
      </div>
    </div>
  );
});

export default ProfileProgramForm;
