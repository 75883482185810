import React from "react";
import Router from "./router";
import { Settings } from "luxon";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Provider } from "inversify-react";

import Analytics from "./components/analytics";
import AppContainer from "./container";
import { AppProvider } from "./context/provider";
import { ModalProvider } from "./context/modal";
import { SnackbarProvider } from "./context/snackbar";
import { TravelProvider } from "./context/travel/provider";
import { setLocalTimezone } from "./utils/helpers";

import { customTheme } from "./styles/app-custom-theme";
import "./styles/app.scss";

setLocalTimezone(Settings.defaultZoneName);
Settings.defaultZoneName = "utc"; // defaults Luxon DateTime methods to use utc zone rather than local

export default function App() {
  return (
    <MuiThemeProvider theme={customTheme}>
      <Provider container={AppContainer} key={AppContainer.id}>
        <AppProvider>
          <ModalProvider>
            <SnackbarProvider>
              <TravelProvider>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </TravelProvider>
            </SnackbarProvider>
          </ModalProvider>
          <Analytics />
        </AppProvider>
      </Provider>
    </MuiThemeProvider>
  );
}
