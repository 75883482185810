import React from "react";
import { airportToIata } from "../../utils/helpers";
import AppIcon, { AppIconType } from "../app-icon";
import BookingRequestModel from "../../models/booking-request.model";
import BookingSegmentModel from "../../models/booking-segment.model";

import "./segment-chip-list.scss";

interface Props {
  request: BookingRequestModel;
}

export default function SegmentChipList(props: Props) {
  const { request } = props;
  const { bookingSegments, bookingType } = request;

  if (!bookingSegments.length) {
    return null;
  }

  const chipLabels: string[] = [];
  const iconType: AppIconType =
    bookingType === "round-trip" ? "round-trip" : "one-way";

  if (bookingType === "multi-city") {
    bookingSegments.forEach((segment: BookingSegmentModel, index: number) => {
      const { startLocation, endLocation } = segment;
      chipLabels.push(index === 0 ? startLocation : endLocation);
    });
  } else {
    const { startLocation, endLocation } = bookingSegments[0];
    chipLabels.push(startLocation);
    chipLabels.push(endLocation);
  }

  const validChipLabels = chipLabels.filter((label: string) => label);

  if (!validChipLabels.length) {
    return null;
  }

  return (
    <div className="segment-chip-list">
      {validChipLabels.map((label: string, index: number) => {
        return (
          <div className="segment-chip" key={index}>
            {Boolean(index) && (
              <AppIcon type={iconType} color="gray" size="x-small" />
            )}
            <div className="chip">{airportToIata(label)}</div>
          </div>
        );
      })}
    </div>
  );
}
