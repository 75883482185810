import { isEqual } from "lodash";

import TripModel from "../../../../models/trip.model";
import UserModel from "../../../../models/user.model";
import { TmlLogModel, TmlLogUpdate } from "../lib/models";

export type TmlAction = {
  type: TmlActionTypes;
  payload?: any;
};

export enum TmlActionTypes {
  AddEntry = "entry/add",
  DeleteEntry = "entry/delete",
  DeleteUpdate = "updates/delete",
  LoadLog = "log/load",
  LoadUsers = "users/load",
  LoadTrips = "trips/load",
  LoadUpdates = "updates/load",
  UpdateLog = "log/update",
  UpdateUsers = "users/update",
  UpdateSettings = "settings/update",
  UpdateTrips = "trips/update"
}

export const TmlReducer = (log: TmlLogModel, action: TmlAction) => {
  const { payload, type } = action;

  switch (type) {
    case TmlActionTypes.LoadLog:
    case TmlActionTypes.UpdateLog:
      return payload;
    case TmlActionTypes.AddEntry:
      return payload;
    case TmlActionTypes.UpdateSettings:
      return payload;
  }
};

export const TmlUpdateReducer = (
  updates: TmlLogUpdate[],
  action: TmlAction
) => {
  const { payload, type } = action;

  switch (type) {
    case TmlActionTypes.LoadUpdates:
      return payload;
    case TmlActionTypes.DeleteUpdate:
      return updates.filter((update: TmlLogUpdate) => {
        return !payload.find((payloadUpdate: TmlLogUpdate) =>
          isEqual(payloadUpdate, update)
        );
      });
  }
};

export const TmlUserReducer = (
  users: Map<string, UserModel>,
  action: TmlAction
) => {
  const { payload, type } = action;

  switch (type) {
    default:
    case TmlActionTypes.LoadUsers:
      return payload;

    case TmlActionTypes.UpdateUsers:
      payload.forEach((user: UserModel, userId: string) =>
        users.set(userId, user)
      );
      return users;
  }
};

export const TmlTripReducer = (
  trips: Map<string, TripModel[]>,
  action: TmlAction
): Map<string, TripModel[]> => {
  const { payload, type } = action;

  switch (type) {
    default:
    case TmlActionTypes.LoadTrips:
      return payload;

    case TmlActionTypes.UpdateTrips:
      payload.forEach((userTrips: TripModel[], userId: string) =>
        trips.set(userId, userTrips)
      );
      return trips;
  }
};
