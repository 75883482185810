import React, { useEffect, useState } from "react";
import CompanyFeatures from "./company-features";
import ObjectTable from "../../components/object-table";
import PageHeader from "../../components/page-header";
import SearchInput from "../../components/search-input";

import { AppPageProps } from "../../router";
import AppContainer from "../../container";
import { IAdminService, AdminService } from "../../services/admin.service";
import {
  IImpersonateService,
  ImpersonateService
} from "../../services/impersonate.service";
import CompanyModel from "../../models/company.model";
import CustomerModel from "../../models/customer.model";
import AdminSearchUserModel from "../../models/admin-search-user.model";

import "./admin-page.scss";

export default function AdminPage(props: AppPageProps) {
  const [companies, setCompanies]: [
    CompanyModel[],
    CallableFunction
  ] = useState([]);

  const [customers, setCustomers]: [
    CustomerModel[],
    CallableFunction
  ] = useState([]);

  const [onlineUsers, setOnlineUsers]: [
    AdminSearchUserModel[],
    CallableFunction
  ] = useState([]);

  const [matchedUsers, setMatchedUsers]: [
    AdminSearchUserModel[],
    CallableFunction
  ] = useState([]);

  const { company, templates, settings, user } = props;

  const adminService: IAdminService = AppContainer.get(AdminService);
  const impersonateService: IImpersonateService = AppContainer.get(
    ImpersonateService
  );

  const mapUserResults = (adminUsers: AdminSearchUserModel[]) => {
    return adminUsers.map((adminUser: AdminSearchUserModel) => ({
      ...adminUser,
      companyName: adminUser.company.name,
      hasRecent: adminUser.hasRecent ? "true" : "false"
    }));
  };

  const searchUsers = async (searchTerm: string) => {
    if (searchTerm && searchTerm.length > 3) {
      const response: AdminSearchUserModel[] = await adminService.searchUsers(
        searchTerm
      );
      if (response) {
        setMatchedUsers(mapUserResults(response));
      }
    }
  };

  // Load customer list and online user on component mount
  useEffect(() => {
    let isMounted = true;

    const fetchCompanies = async () => {
      const companyList: CompanyModel[] = await adminService.getCompanies();
      if (companyList && isMounted) {
        setCompanies(companyList);
      }
    };

    const fetchCustomers = async () => {
      const customerList: CustomerModel[] = await adminService.getCustomers();
      if (customerList && isMounted) {
        setCustomers(
          customerList
            .filter(
              (customer: CustomerModel) =>
                customer.status === "CUSTOMER" && customer.billable
            )
            .map((customer: CustomerModel) => ({
              ...customer,
              active: `${customer.active}`,
              invoiceMMR: `$${
                ((customer.nextInvoice || {}).total || 0) /
                100 /
                (+(customer.billingPlan || "").split("_").pop()! * 1)
              }`,
              invoiceTotal: `$${
                ((customer.nextInvoice || {}).total || 0) / 100
              }`,
              invoiceUnits: (customer.nextInvoice || {}).units
            }))
        );
      }
    };

    const fetchOnlineUsers = async () => {
      const userList: AdminSearchUserModel[] = await adminService.getOnlineUsers();
      if (userList && isMounted) {
        setOnlineUsers(mapUserResults(userList));
      }
    };

    fetchCompanies();

    if (user.isEmployee()) {
      // only fetch customers and online users if we're an employee
      fetchCustomers();
      fetchOnlineUsers();
    }

    return () => {
      isMounted = false;
    };
  }, [adminService, user]);

  // if the user is NOT an employee
  if (!user.isEmployee()) {
    // then render only a tenant admin dashboard
    return (
      <div id="admin-page" className="page-container">
        <PageHeader title="Admin Page"></PageHeader>
        <ObjectTable
          title="Tenant Companies"
          columns={[
            ["name", "Company Name"],
            ["id", "ID"]
          ]}
          renderOpts={{
            customRowClick: ({ id }: any) => {
              impersonateService.activate(`company-${id}`);
              window.location.href = "/";
            },
            disableDownload: true,
            disablePagination: true,
            disableRowSelect: true,
            disableViewChange: true,
            useCardStyle: true
          }}
          searchOpts={{
            order: "name",
            resource: "companies"
          }}
          staticResults={companies}
          company={company}
          templates={templates}
          settings={settings}
          user={user}
        />
      </div>
    );
  }

  // otherwise, if the user is a superuser and an employee,
  // then render everything
  return (
    <div id="admin-page" className="page-container">
      <PageHeader title="Admin Page">
        <SearchInput
          id={"admin-page-search-input"}
          key={"admin-page-search-input"}
          onInput={(value: string) => {
            searchUsers(value);
          }}
          resource={"users"}
          keepOpen={false}
        />
      </PageHeader>
      <ObjectTable
        title="Travel Planners"
        columns={[
          ["companyName", "Company Name"],
          ["companyId", "Company ID"],
          ["name", "Name"],
          ["email", "Email"],
          ["id", "ID"]
        ]}
        renderOpts={{
          customRowClick: ({ id }: any) => {
            impersonateService.activate(id);
            window.location.href = "/";
          },
          disableDownload: true,
          disablePagination: true,
          disableRowSelect: true,
          disableViewChange: true,
          useCardStyle: true
        }}
        searchOpts={{
          order: "name",
          resource: "users"
        }}
        staticResults={matchedUsers}
        company={company}
        templates={templates}
        settings={settings}
        user={user}
      />
      {onlineUsers.length ? (
        <ObjectTable
          columns={[
            ["name", "Name"],
            ["companyName", "Company Name"],
            ["email", "Email"],
            ["hasRecent", "Recently Active"],
            ["hasSockets", "Open Tabs"],
            ["id", "ID"]
          ]}
          renderOpts={{
            customRowClick: ({ id }: any) => {
              impersonateService.activate(id);
              window.location.href = "/";
            },
            disableDownload: true,
            disablePagination: true,
            disableRowSelect: true,
            disableViewChange: true,
            useCardStyle: true
          }}
          searchOpts={{
            order: "name",
            resource: "users"
          }}
          staticResults={onlineUsers}
          title="Online Users"
          company={company}
          templates={templates}
          settings={settings}
          user={user}
        />
      ) : null}
      <ObjectTable
        columns={[
          ["id", "ID"],
          ["name", "Name"],
          ["invoiceUnits", "MAU"],
          ["invoiceMMR", "MMR"],
          ["billingPlan", "Billing Plan"],
          ["billingDate", "Next Billing Date"],
          ["invoiceTotal", "Next Invoice Amount"]
        ]}
        renderOpts={{
          disableDownload: true,
          disablePagination: true,
          disableRowSelect: true,
          disableViewChange: true,
          useCardStyle: true
        }}
        searchOpts={{
          order: "invoiceUnits",
          resource: "companies",
          reverse: true
        }}
        staticResults={customers}
        title="Billable Customers"
        company={company}
        templates={templates}
        settings={settings}
        user={user}
      />

      <CompanyFeatures company={company} />
    </div>
  );
}
