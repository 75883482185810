import BaseModel from "./base.model";
import { ObjectHash } from "../utils/helpers";

export type UserDocumentType = "passport" | "idCard" | "tsa";

export type UserDocument =
  | UserDocumentPassportModel
  | UserDocumentIdCardModel
  | UserDocumentTsaModel;

export class UserDocumentPassportModel extends BaseModel {
  passportNumber: string;
  issuingCountry: string;
  expirationDate: string;
  issueDate: string;
  sex: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  nationality: string;
  countryOfResidence: string;

  constructor(data?: ObjectHash) {
    super();
    if (!data) {
      data = {};
    }

    this.passportNumber = data.passportNumber;
    this.issuingCountry = data.issuingCountry;
    this.expirationDate = data.expirationDate;
    this.issueDate = data.issueDate;
    this.sex = data.sex;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.dateOfBirth = data.dateOfBirth;
    this.nationality = data.nationality;
    this.countryOfResidence = data.countryOfResidence;
  }

  getId(): string | null | undefined {
    return this.passportNumber;
  }
}

export class UserDocumentIdCardModel extends BaseModel {
  idCardNumber: string;
  issuingCountry: string;
  expirationDate: string;
  issueDate: string;
  sex: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  nationality: string;
  countryOfResidence: string;

  constructor(data?: ObjectHash) {
    super();
    if (!data) {
      data = {};
    }

    this.idCardNumber = data.idCardNumber;
    this.issuingCountry = data.issuingCountry;
    this.expirationDate = data.expirationDate;
    this.issueDate = data.issueDate;
    this.sex = data.sex;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.dateOfBirth = data.dateOfBirth;
    this.nationality = data.nationality;
    this.countryOfResidence = data.countryOfResidence;
  }

  getId(): string | null | undefined {
    return this.idCardNumber;
  }
}

export class UserDocumentTsaModel extends BaseModel {
  tsaNumber: string;
  expirationDate: string;

  constructor(data?: ObjectHash) {
    super();
    if (!data) {
      data = {};
    }

    this.tsaNumber = data.tsaNumber;
    this.expirationDate = data.expirationDate;
  }

  getId(): string | null | undefined {
    return this.tsaNumber;
  }
}
