import React, {
  forwardRef,
  RefObject,
  useImperativeHandle,
  useState
} from "react";
import classnames from "classnames";
import { ClickAwayListener } from "@material-ui/core";
import Popper, { PopperPlacementType } from "@material-ui/core/Popper";

import useModal from "../../hooks/use-modal.hook";

import "./popper-menu.scss";

interface Props {
  anchor: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  className?: string;
  confirmText?: string;
  portalTarget?: RefObject<HTMLElement>;
  disablePortal?: boolean;
  paddingSize?: "small" | "medium";
  placement?: PopperPlacementType;
  onClose?: CallableFunction;
}

const PopperMenu = forwardRef((props: Props, ref: any) => {
  const {
    anchor,
    children,
    className = "",
    confirmText,
    portalTarget,
    disablePortal = false,
    paddingSize = "medium",
    placement = "bottom-end",
    onClose
  } = props;
  const { modalIsOpen, openModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  const handleClickAway = (event: any) => {
    // stay open for clicks on react-select that are portaled out of the popper
    if (
      String(event?.target?.id).includes("option") ||
      String(event?.target?.className).includes("MenuList")
    ) {
      return;
    }

    if (confirmText) {
      if (modalIsOpen("confirm")) {
        return;
      }
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: confirmText,
        onConfirm: handleClose
      });
    } else {
      handleClose();
    }
  };

  useImperativeHandle(ref, () => ({
    close: handleClose
  }));

  return (
    <div className={classnames("popper-menu", className)}>
      <span
        className="popper-anchor"
        onClick={(event) => {
          event.stopPropagation(); // prevent bubbling if the anchor is rendered inside clickable container
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }}
      >
        {anchor}
      </span>
      <Popper
        className={classnames("tg-popper", `tg-popper--${paddingSize}`, {
          open
        })}
        container={portalTarget?.current || null}
        disablePortal={disablePortal}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={handleClickAway}
        >
          <div className="tg-popper-inner">{children}</div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
});

export default PopperMenu;
