import BaseService from "./base.service";
import { inject } from "inversify";
import * as _ from "lodash";
import { WebsocketService, IWebsocketService } from "./websocket.service";
import { SearchService, ISearchService } from "./search.service";
import { IndexDbService, IIndexDbService } from "./index-db.service";

export interface ISearchDbService {
  connect(): Promise<boolean>;

  parseQueryParams(params: any): any;

  queryIndexDb(params: any, state?: any): Promise<any>;

  subscribe(
    queryParams: any,
    callback: CallableFunction,
    readyCallback: CallableFunction
  ): any;
}

export class SearchDbService extends BaseService implements ISearchDbService {
  @inject(WebsocketService)
  private websocketService!: IWebsocketService;

  @inject(SearchService)
  private searchService!: ISearchService;

  @inject(IndexDbService)
  private indexDbService!: IIndexDbService;

  private searchState: {
    connected: boolean;
    initializedStores: any;
    previousHardSearches: any[];
    subscriptions: any;
  } = {
    connected: false,
    initializedStores: {},
    previousHardSearches: [],
    subscriptions: {}
  };

  async connect(): Promise<boolean> {
    if (this.searchState.connected) {
      console.warn("cannot connect searchdb, already connected");
      return false;
    }

    // @todo find a way to express these connection dependencies in a different way
    if (!this.websocketService.isConnected()) {
      console.error("cannot connect searchdb, websocket not connected");
      return false;
    }

    if (!this.indexDbService.isConnected()) {
      console.error("cannot connect searchdb, indexdb not connected");
      return false;
    }

    // @todo move this out to the router
    if (
      window.location &&
      window.location.href &&
      window.location.href.includes("/shared/")
    ) {
      return false;
    }

    this.searchState.connected = true;

    await this.indexDbService.clearAllStores();

    const onMessage = async (
      store: string,
      data: any,
      initial?: boolean
    ): Promise<boolean> => {
      const addFunc = Array.isArray(data)
        ? this.indexDbService.addMany
        : this.indexDbService.add;

      const response = await addFunc.apply(this.indexDbService, [store, data]);

      if (!response) {
        // @todo major error
        return false;
      }

      const isFirstLoad = initial && !this.searchState.initializedStores[store];

      if (isFirstLoad) {
        this.searchState.initializedStores[store] = true;
        return true;
      }

      let subsToUpdate = Object.keys(this.searchState.subscriptions);

      subsToUpdate = subsToUpdate.filter((subId) => {
        const sub = this.searchState.subscriptions[subId];
        return sub.queryParams && sub.queryParams.resource === store;
      });

      const copiedData = Array.isArray(data) ? data : [data];

      const hasNew =
        isFirstLoad ||
        copiedData.find(
          (o) =>
            o &&
            (Number(new Date(o.createdAt)) > Date.now() - 60 * 1000 ||
              Number(new Date(o.deletedAt)) > Date.now() - 60 * 1000)
        );

      subsToUpdate.forEach((subId) => {
        const sub = this.searchState.subscriptions[subId];
        clearTimeout(sub.socketUpdate);
        sub.socketUpdate = setTimeout(() => sub.runQuery(hasNew, true), 300);
      });

      return true;
    };

    const wsSubscribes = Object.keys(this.indexDbService.getStores()).map(
      (store: any) => {
        const channelName = `${_.capitalize(store.slice(0, -1))}Updates`;

        return this.websocketService.subscribe(
          channelName,
          async (data: any, initial?: boolean) =>
            onMessage(store, data, initial)
        );
      }
    );

    await Promise.all(wsSubscribes);

    return true;
  }

  parseQueryParams(params: any): any {
    const { filters = [], resource } = params || {};

    if (!resource) {
      return {};
    }

    const validFilters = (Array.isArray(filters) ? filters : []).filter(
      (f) =>
        Array.isArray(f) &&
        f.length === 2 &&
        !["forcedUpdate", "searchUpdated"].includes(f[0])
    );

    return {
      ...params,
      filters: validFilters
    };
  }

  async queryIndexDb(params: any = {}, state?: any): Promise<any> {
    const parsedParams = this.parseQueryParams(params);
    const { resource } = parsedParams;

    if (!resource) {
      console.error(
        "SearchDbService::queryIndexDb | missing resource",
        parsedParams
      );
      return [];
    }

    const storeInited = Boolean(this.searchState.initializedStores[resource]);
    const storeExists = this.indexDbService.hasStore(resource);

    if (!storeExists || !storeInited) {
      console.warn(
        "SearchDbService::queryIndexDb | store not ready or invalid",
        parsedParams
      );

      return this.searchService.search(resource, parsedParams, state || {});
    }

    return this.indexDbService.find(resource, parsedParams, state || {});
  }

  subscribe(
    queryParams: any,
    callback: CallableFunction,
    readyCallback: CallableFunction
  ): any {
    const id = `sub-${Math.random() * 1000}-${Date.now()}`;

    const sub = {
      callback: (results: any) => {
        const parsed = this.searchService.parseOutput(
          {},
          { results },
          sub.queryParams
        );
        return callback(parsed);
      },
      queryParams: this.parseQueryParams(queryParams),
      remove: () => {
        delete this.searchState.subscriptions[id];
      },
      runQuery: (hasNew: any, isSocketUpdate?: boolean, round: number = 0) => {
        const meta: any = {};

        if (!this.indexDbService.hasStore(sub.queryParams.resource)) {
          readyCallback(null);
          this.queryIndexDb(sub.queryParams, meta).then((results: any) => {
            sub.callback(results);
            readyCallback(meta.searchMeta);
          });
          return;
        }

        if (
          !this.searchState.initializedStores[sub.queryParams.resource] &&
          round < 10
        ) {
          setTimeout(() => {
            sub.runQuery(hasNew, isSocketUpdate, round + 1);
          }, 300);
          return;
        }

        const searchSignature = Object.entries(sub.queryParams)
          .filter(([k]) =>
            ["filters", "order", "resource", "reverse"].includes(k)
          )
          .map(([k, v]) => `${k}:${JSON.stringify(v)}`)
          .sort()
          .join("&");

        if (
          this.searchState.previousHardSearches.includes(searchSignature) ||
          ["users"].includes(sub.queryParams.resource)
        ) {
          readyCallback(null);
          this.queryIndexDb(sub.queryParams, meta)
            .then(sub.callback)
            .then(() => {
              readyCallback(meta.searchMeta);
            });
          return;
        }

        if (hasNew) {
          readyCallback(null);
        }

        this.queryIndexDb(sub.queryParams)
          .then(sub.callback)
          .then(() => {
            if (!hasNew) {
              return;
            }

            const { resource } = sub.queryParams;

            this.searchService
              .search(
                resource,
                {
                  ...sub.queryParams,
                  ...(isSocketUpdate && hasNew ? { limit: 0 } : {}),
                  fields: ["*", ...sub.queryParams.fields]
                },
                meta
              )
              .then((results: any) => {
                if (meta.searchMeta && !meta.searchMeta.limit) {
                  meta.searchMeta.limit = sub.queryParams.limit || 10;
                  meta.searchMeta.count = meta.searchMeta.limit;
                }

                if (
                  results &&
                  results.length < (sub.queryParams.limit || 10) &&
                  (sub.queryParams.page || 0) <= 1 &&
                  !hasNew
                ) {
                  this.searchState.previousHardSearches.push(searchSignature);
                } else if (isSocketUpdate) {
                  readyCallback(meta.searchMeta);
                  return;
                }

                this.indexDbService
                  .addMany(sub.queryParams.resource, results)
                  .then(() =>
                    this.queryIndexDb(sub.queryParams)
                      .then(sub.callback)
                      .then(() => readyCallback(meta.searchMeta))
                  );
              });
          });
      },
      update: (newQP: any) => {
        sub.queryParams = this.parseQueryParams(newQP);
        sub.runQuery(true);
      }
    };

    sub.runQuery(true);

    this.searchState.subscriptions[id] = sub;

    return this.searchState.subscriptions[id];
  }
}
