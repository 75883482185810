import React, { useState } from "react";
import { ActionMeta } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { debounce } from "lodash";

import { IUserService, UserService } from "../../../services/user.service";

import UserModel from "../../../models/user.model";

import {
  ObjectHash,
  reactSelectCloseOnScroll,
  SelectOption
} from "../../../utils/helpers";

import AppContainer from "../../../container";
import Loader from "../../loader";
import TravelerChip from "../../traveler-chip";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  autoFocus?: boolean;
  defaultValue?: SelectOption[];
  disabled?: boolean;
  disableSearch?: boolean;
  isCreatable?: boolean;
  label?: string;
  menuPortalTarget?: HTMLElement | null;
  onChange: CallableFunction;
  onlyAdmins?: boolean;
  options?: SelectOption[];
  placeholder?: string;
}

export default function TravelerInput(props: Props) {
  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      height: "100%",
      ":hover": {
        color: Colors.red
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      height: "100%",
      "> div": {
        padding: "0"
      }
    }),
    input: (styles: ObjectHash) => ({
      ...styles,
      marginTop: "0",
      marginBottom: "0",
      minHeight: "24px"
    }),
    menu,
    menuPortal,
    option
  };

  const {
    autoFocus = false,
    defaultValue,
    disabled = false,
    disableSearch = false,
    isCreatable = false,
    label,
    menuPortalTarget,
    onChange,
    onlyAdmins,
    options,
    placeholder
  } = props;

  const userService: IUserService = AppContainer.get(UserService);

  const [selectedOpts, setSelectedOpts] = useState<SelectOption[]>(
    defaultValue ?? []
  );

  const getOptions = debounce(
    (inputValue: string, callback: CallableFunction) => {
      if (!inputValue) {
        return { options: [] };
      }

      // search options list if provided via props
      if (options) {
        const searchTerm = inputValue.toLowerCase();
        const travelers = options.filter((option: SelectOption) =>
          String(option.label).toLowerCase().includes(searchTerm)
        );

        return callback(travelers);
      }

      userService.search(inputValue, onlyAdmins).then((travelers) => {
        const searchedOptions = travelers.map((user: UserModel) => ({
          label: user.getFullName(),
          value: user.id,
          meta: user
        }));

        return callback(searchedOptions);
      });
    },
    300
  );

  const removeSelectedOption = (selectedValue: string) => {
    if (!selectedOpts?.length) {
      return;
    }
    const updatedOptions = [...selectedOpts];
    const optIndex = updatedOptions.findIndex(
      (opt: SelectOption) => opt.value === selectedValue
    );
    if (optIndex === -1) {
      return;
    }
    updatedOptions.splice(optIndex, 1);
    setSelectedOpts(updatedOptions);
    onChange(updatedOptions);
  };

  const handleChange = (options: any, meta: ActionMeta<SelectOption>) => {
    const updatedOptions = Array.isArray(options)
      ? (options as SelectOption[])
      : [options as SelectOption];

    setSelectedOpts(updatedOptions);
    onChange(updatedOptions);
  };

  // Allows component to provide create profile option if prop is present
  const ReactSelectComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect;

  const handleCreate = (inputValue: string) => {
    userService.create({ name: inputValue }).then((res: any) => {
      const newUserOption: SelectOption = {
        label: res.name,
        value: res.id
      };
      const updatedOptions: SelectOption[] = selectedOpts.slice();
      updatedOptions.push(newUserOption);

      setSelectedOpts(updatedOptions);
      onChange(updatedOptions);
    });
  };

  const creatableProps = isCreatable
    ? {
        formatCreateLabel: (label: string) => `Create profile for "${label}"`,
        onCreateOption: (inputValue: string) => handleCreate(inputValue)
      }
    : {};

  return (
    <div className="traveler-input">
      {label && <label className="autocomplete-input__label">{label}</label>}
      <ReactSelectComponent
        {...creatableProps}
        autoFocus={autoFocus}
        cacheOptions
        className="autocomplete-input autocomplete-input--async"
        classNamePrefix="tg-input"
        closeMenuOnScroll={reactSelectCloseOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />,
          MultiValue: ({ data, ...props }) => {
            const traveler = data.meta
              ? (data.meta as UserModel)
              : new UserModel({ name: data.label });
            return (
              <TravelerChip
                traveler={traveler}
                onRemove={(traveler: UserModel) => {
                  removeSelectedOption(traveler.id);
                }}
              />
            );
          }
        }}
        defaultOptions={options}
        isDisabled={disabled}
        isClearable={false}
        isSearchable={!disableSearch}
        isMulti={true}
        loadOptions={getOptions}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length
            ? `No ${onlyAdmins ? "travel planners" : "travelers"} found`
            : null
        }
        onChange={handleChange}
        placeholder={placeholder ?? ""}
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
        value={selectedOpts}
      />
    </div>
  );
}
