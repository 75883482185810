import React from "react";
import AppIcon from "../app-icon";

import "./pro-tip.scss";

export interface Props {
  text: string;
}

export default function ProTip(props: Props) {
  const { text } = props;
  return (
    <div className="pro-tip">
      <AppIcon type="bulb" />
      <div>Pro tip: {text}</div>
    </div>
  );
}
