import TagGroupModel from "../../../models/tag-group.model";
import { getDisplayDate } from "../../../utils/helpers";

export type ReportFormInput = {
  inputType: string;
  key: string;
  label: string;
  options?: string[];
  value: boolean | string;
  tag?: boolean;
};

export const getReportFormInputs = (
  features: any,
  tagGroups: TagGroupModel[],
  dataFreezeDate: any
): ReportFormInput[] =>
  [
    {
      inputType: "checkbox",
      key: "gdsLocator",
      label: "GDS Locator",
      value: true
    },
    {
      inputType: "checkbox",
      key: "confirmation",
      label: "Confirmation Number",
      value: true
    },
    ...tagGroups.map((tagGroup: TagGroupModel) => ({
      inputType: "checkbox",
      key: tagGroup.getFieldId(),
      label: tagGroup.name,
      value: true,
      tag: true
    })),
    ...(features?.extraReportFields || []).map((erf: [string, string]) => ({
      inputType: "checkbox",
      key: erf[0],
      label: erf[1],
      value: true
    })),
    features &&
      features.integrations && {
        inputType: "checkbox",
        key: "bookedDateTime",
        label: "Booked Date",
        value: true
      },
    features &&
      features.integrations && {
        inputType: "checkbox",
        key: "invoiceDate",
        label: "Invoice Date",
        value: true
      },
    { inputType: "checkbox", key: "price", label: "Cost", value: true },
    { inputType: "checkbox", key: "type", label: "Travel Type", value: true },
    { inputType: "checkbox", key: "ticket", label: "Tickets", value: true },
    {
      inputType: "checkbox",
      key: "users",
      label: "Travelers",
      value: true
    },
    {
      inputType: "checkbox",
      key: "description",
      label: "Description",
      value: true
    },
    { inputType: "checkbox", key: "from", label: "Travel Start", value: true },
    { inputType: "checkbox", key: "to", label: "Travel End", value: true },
    {
      inputType: "checkbox",
      key: "fromLocation",
      label: "Travel Origin",
      value: true
    },
    {
      inputType: "checkbox",
      key: "toLocation",
      label: "Travel Destination",
      value: true
    },
    {
      className: "half-field",
      inputType: "date",
      key: "dateFrom",
      label: "Start Date",
      value: dataFreezeDate || getDisplayDate("now", "yyyy-MM-dd") // default report start date uses settings freeze data date or the current date if it isn't set
    },
    {
      className: "half-field",
      inputType: "date",
      key: "dateTo",
      label: "End Date",
      value: getDisplayDate("now", "yyyy-MM-dd") // default report end date is set to current date
    },
    {
      inputType: "select",
      key: "filter.type",
      label: "Travel Type",
      options: ["All", "AIR", "CAR", "HOTEL", "RAIL"],
      value: "All"
    },
    {
      inputType: "checkbox--special",
      key: "totals",
      label: "Include totals and averages section?",
      value: false
    }
  ].filter((v) => v && v.key);
