import React from "react";
import classnames from "classnames";
import { debounce, startCase } from "lodash";

import AppIcon from "../app-icon";
import AppIconFrame from "../app-icon-frame";
import "./search-input.scss";

export interface Props {
  id: string;
  keepOpen: boolean;
  onInput: CallableFunction;
  resource: string;
}

export default function SearchInput(props: Props) {
  const { id, keepOpen, onInput, resource } = props;
  const [isOpen, setOpen] = React.useState(keepOpen);
  const searchInput = React.useRef<HTMLInputElement>(null);

  const handleChange = debounce((value: string) => {
    onInput(value);
  }, 300);

  return (
    <div
      id={id}
      className={classnames("search-input", {
        "search-input--open": isOpen
      })}
    >
      <input
        className="search-input__field"
        onChange={(event) => handleChange(event.target.value)}
        placeholder={`Search ${startCase(resource)}`}
        ref={searchInput}
      />
      <div
        className="search-input__icon"
        onClick={() => {
          if (keepOpen) {
            return;
          }
          const newOpenState = !isOpen;
          setOpen(newOpenState);

          if (newOpenState && searchInput) {
            searchInput.current?.focus(); // autofocus input on click to expand
          }
        }}
      >
        <AppIconFrame>
          <AppIcon color="white" size="x-small" type="search" />
        </AppIconFrame>
      </div>
    </div>
  );
}
