import React, { useState } from "react";
import { Link } from "react-router-dom";
import GridModel from "../../models/grid.model";
import classnames from "classnames";
import AppIcon from "../app-icon";
import GridCardMenu from "../../pages/grids/grid-card-menu";

interface Props {
  active?: boolean;
  grid: GridModel;
  starred?: boolean;
}

export default function GridLink(props: Props) {
  const { active, grid, starred } = props;
  const [persistHover, setPersistHover] = useState<Boolean>(false);

  return (
    <Link
      className={classnames("grid-link", {
        "grid-link--active": active,
        hover: persistHover
      })}
      to={`/grids/${grid.id}`}
    >
      <div className="grid-link-label">
        {starred && <AppIcon color="dark-gray" size="x-small" type="star" />}
        <span>{grid.name}</span>
      </div>
      <GridCardMenu
        color="border-gray"
        grid={grid}
        onOpen={() => setPersistHover(true)}
        onClose={() => setPersistHover(false)}
      />
    </Link>
  );
}
