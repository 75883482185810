import React, { RefObject, useRef, useState } from "react";

import Button from "../../../../button";
import PopperMenu from "../../../../popper-menu";
import TextInput from "../../../../form/inputs/TextInput";

import "./tml-edit-cell.scss";

interface Props {
  defaultValue: string;
  onChange: (value: string) => void;
  portalTarget: RefObject<HTMLElement>;
}

export default function TmlEditCell(props: Props) {
  const { defaultValue, onChange, portalTarget } = props;
  const [value, setValue] = useState(defaultValue);
  const menuRef = useRef();

  const handleChange = (value: string) => {
    onChange(value);
    (menuRef?.current as any).close();
  };

  return (
    <div className="tml-cell edit">
      <div className="tml-cell-popper-wrapper">
        <PopperMenu
          anchor={defaultValue}
          portalTarget={portalTarget}
          placement="bottom-start"
          ref={menuRef}
        >
          <div className="tml-edit-menu">
            <TextInput autoFocus value={value} onChange={setValue} />

            <div className="buttons">
              <Button
                color="product-blue"
                label="Save"
                onClick={() => {
                  handleChange(value);
                }}
                size="medium"
              />
              <Button
                color="gray"
                isTransparent={true}
                label="Clear"
                onClick={() => {
                  setValue("");
                  handleChange("");
                }}
                size="medium"
              />
            </div>
          </div>
        </PopperMenu>
      </div>
    </div>
  );
}
