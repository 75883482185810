import React, { useEffect } from "react";

interface Props {
  alllowedClassName: string;
  children: JSX.Element | JSX.Element[];
  onClose: CallableFunction;
}

export default function CloseOnScroll(props: Props) {
  const { alllowedClassName, children, onClose } = props;

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.target?.classList?.contains(alllowedClassName)) {
        return true;
      }

      onClose();
    };

    document.addEventListener("scroll", handleScroll, true);

    return () => {
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, [alllowedClassName, onClose]);

  return <React.Fragment>{children}</React.Fragment>;
}
