import React, { useRef, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import classnames from "classnames";

import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import { Column, getColumnIdFromName } from "../../helpers";
import FormController from "../../../form/form-controller";
import { FormValidator } from "../../../../utils/form";
import PopperMenu from "../../../popper-menu";
import Sortable from "../../../sortable";
import { ObjectHash, sortByPosition } from "../../../../utils/helpers";

import "./column-menu.scss";
import Button from "../../../button";
import ColumnEditRow from "./column-edit-row";

interface Props {
  columns: Column[];
  disablePortal?: boolean;
  onAddColumn?: (column: Column) => void;
  onChangeHide?: (id: string, hidden: boolean) => void;
  onChangeSort?: (columns: Column[]) => void;
  onDeleteColumn?: (columnId: string) => void;
  onEditColumn?: (column: Column) => void;
}

const TemplateInputs = [{ inputType: "text", key: "label", label: "" }];

export const ModelValidator: FormValidator = {
  label: {
    rule: {
      presence: { allowEmpty: false, message: "Column name is required" },
      format: {
        pattern: "[ A-Za-z0-9_-]+",
        message: "Column name must be alpha-numeric"
      }
    }
  }
};

export default function ColumnMenu(props: Props) {
  const {
    disablePortal,
    onAddColumn,
    onChangeHide,
    onChangeSort,
    onDeleteColumn,
    onEditColumn
  } = props;
  const [addFormState, setAddFormState] = useState({ label: "" });
  const [columns, setColumns] = useState(props.columns);
  const [editColumnId, setEditColumnId] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [sortableKey, setSortableKey] = useState(0);
  const addFormRef = useRef(null);

  const handleDeleteColumn = (columnId: string) => {
    const updatedColumns = columns.filter(
      (column: Column) => column.id !== columnId
    );
    setColumns(updatedColumns);
    setSortableKey(sortableKey + 1);

    onDeleteColumn && onDeleteColumn(columnId);
  };

  const handleAddColumn = (formState: ObjectHash) => {
    const updatedColumns = [...columns].sort(sortByPosition);
    const nextPosition =
      (updatedColumns[updatedColumns.length - 1].position || 0) + 1;

    const { label } = formState;
    const newColumn = {
      id: getColumnIdFromName(label),
      label,
      position: nextPosition
    };

    updatedColumns.push(newColumn);
    setColumns(updatedColumns);
    setSortableKey(sortableKey + 1);
    setAddFormState({ label: "" });
    addFormRef.current && (addFormRef.current as any).reset();

    onAddColumn && onAddColumn(newColumn);
  };

  const handleEditColumn = (column: Column) => {
    const { id } = column;
    const updatedColumns = [...columns];
    const columnIndex = updatedColumns.findIndex(
      (updateColumn: Column) => updateColumn.id === id
    );
    if (columnIndex === -1) {
      return;
    }

    updatedColumns[columnIndex] = column;
    setColumns(updatedColumns);
    setEditColumnId("");
    setSortableKey(sortableKey + 1);
    onEditColumn && onEditColumn(column);
  };

  const handleSortChange = (
    itemId: string,
    newIndex: number,
    oldIndex: number
  ) => {
    const { pinned = false } =
      columns.find((column: Column) => column.id === itemId) || {};

    let updatedColumns = columns.filter(
      (column: Column) => Boolean(column.pinned) === pinned
    );
    const [updatedColumn] = updatedColumns.splice(oldIndex, 1);

    updatedColumns.splice(newIndex, 0, updatedColumn);

    updatedColumns = updatedColumns.map((column: Column, index: number) => {
      column.position = index + 1;
      return column;
    });

    const allColumns = [
      ...columns.filter((column: Column) => Boolean(column.pinned) !== pinned),
      ...updatedColumns
    ];

    setColumns(allColumns);
    onChangeSort && onChangeSort(allColumns);
  };

  const nonSortableElements = columns
    .filter((column: Column) => column.pinned)
    .sort(sortByPosition)
    .map((column: Column) => {
      const { hidden, id, label, locked } = column;
      return (
        <div
          className={classnames("column-row", { disabled: locked })}
          key={id}
        >
          <Checkbox
            disabled={locked}
            defaultChecked={!hidden}
            color="primary"
            onChange={(e) => {
              onChangeHide && onChangeHide(id, !e.target.checked);
            }}
            value={id}
          />
          <div className="label truncate">{label}</div>
          <AppIcon type="lock" size="x-small" />
          <AppIcon type="drag-handle" />
        </div>
      );
    });

  const sortableElements = columns
    .filter((column: Column) => !column.pinned)
    .sort(sortByPosition)
    .map((column: Column) => {
      const {
        canEdit = false,
        canDelete = false,
        hidden,
        id,
        label,
        locked
      } = column;
      const editing = editColumnId === id;

      if (editing) {
        return {
          id,
          element: (
            <ColumnEditRow
              column={column}
              onCancel={() => {
                setEditColumnId("");
                setSortableKey(sortableKey + 1);
              }}
              onChange={handleEditColumn}
            />
          )
        };
      }

      return {
        id,
        disabled: locked,
        element: (
          <div
            className={classnames("column-row", { disabled: locked })}
            key={id}
          >
            <Checkbox
              disabled={locked}
              defaultChecked={!hidden}
              color="primary"
              onChange={(e) => {
                onChangeHide && onChangeHide(id, !e.target.checked);
              }}
              value={id}
            />
            <div className="label truncate">{label}</div>
            {canEdit && (
              <div
                className="edit-button"
                onClick={() => {
                  setEditColumnId(id);
                  setSortableKey(sortableKey + 1);
                }}
              >
                <AppIcon type="edit" size="x-small" />
              </div>
            )}
            {canDelete && (
              <div
                className="delete-button"
                onClick={() => handleDeleteColumn(id)}
              >
                <AppIcon type="remove-circle" size="x-small" />
              </div>
            )}
            <AppIcon type="drag-handle" />
          </div>
        )
      };
    });

  return (
    <PopperMenu
      anchor={
        <AppIconFrame color="product-background-blue" shape="square">
          <AppIcon type="view-column" color="black" />
        </AppIconFrame>
      }
      className="column-menu"
      disablePortal={disablePortal}
    >
      <div className="sink-column-menu">
        <div className="title">Show Columns</div>

        <div className="content">
          <div className="sortables">
            {nonSortableElements}
            <Sortable
              items={sortableElements}
              onChange={handleSortChange}
              key={sortableKey}
            />
          </div>
          <div className="column-row add-row">
            <FormController
              fields={TemplateInputs}
              model={addFormState}
              onSave={handleAddColumn}
              onValidate={setFormValid}
              validator={ModelValidator}
              ref={addFormRef}
            />

            <Button
              color="product-blue"
              isDisabled={!formValid}
              label="Add"
              onClick={() => {
                addFormRef.current && (addFormRef.current as any).save();
              }}
              size="medium"
            />
          </div>
        </div>
      </div>
    </PopperMenu>
  );
}
