import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect
} from "react";
import ClickAwayListener from "react-click-away-listener";
import classnames from "classnames";

import Portal from "@material-ui/core/Portal";

import AppIcon from "../app-icon";
import CloseOnScroll from "../close-on-scroll";
import TextInput from "../form/inputs/TextInput";
import ToggleSwitch from "../toggle-switch";
import usePrevious from "../../hooks/use-previous.hook";

import "./select-toggle-menu.scss";

export interface SelectOption {
  id: string;
  name: string;
}

type MenuPosition = {
  top?: number;
  left?: number;
  right?: number;
};

interface SearchFunction {
  (keyword: string): Promise<SelectOption[]>;
}

const sortByName = (aOpt: SelectOption, bOpt: SelectOption) => {
  return aOpt.name > bOpt.name ? 1 : -1;
};

export interface Props {
  createOption?: boolean;
  isDisabled?: boolean;
  menuPortalTarget?: any;
  menuPosition?: "left" | "right";
  multiSelect?: boolean;
  onChange: (options: SelectOption[]) => void;
  onFilterOptions?: CallableFunction;
  onOption?: (option: SelectOption) => JSX.Element | string;
  onSearch?: SearchFunction;
  onSelectedOption?: (option: SelectOption) => JSX.Element | string;
  options?: SelectOption[];
  placeholder?: string;
  searchPlaceholder?: string;
  selectedOptionIds?: string[];
  width?: number | string;
}

interface MenuState {
  open: boolean;
  position: MenuPosition;
  searchKeyword: string;
}

export default function SelectToggleMenu(props: Props) {
  const {
    createOption = false,
    isDisabled = false,
    menuPortalTarget,
    menuPosition = "left",
    onFilterOptions,
    onOption,
    onSearch,
    onSelectedOption,
    placeholder,
    searchPlaceholder,
    width
  } = props;

  const multiSelect =
    typeof props.multiSelect === "boolean" ? props.multiSelect : true;

  const defaultOptions = useMemo(
    () => (props.options ? [...props.options] : []),
    [props.options]
  );

  const searchInputRef = useRef(null);

  const [options, setOptions] = useState<SelectOption[]>(defaultOptions);

  const [menuState, setMenuState] = useState<MenuState>({
    open: false,
    position: { top: 0, left: 0 },
    searchKeyword: ""
  });

  const [searchTerm, setSearchTerm] = useState<string>("");
  const previousSearchTerm = usePrevious(searchTerm);

  const initSelectedOpts: SelectOption[] = [];
  let selectedOptionIds = props.selectedOptionIds || [];
  if (selectedOptionIds) {
    // ensure that non-multi-select inputs only have one selected tag
    if (!multiSelect) {
      selectedOptionIds = selectedOptionIds.slice(0, 1);
    }
    selectedOptionIds.forEach((optionId: string) => {
      const selectedOpt = options.find(
        (opt: SelectOption) => opt.id === optionId
      );
      if (selectedOpt) {
        initSelectedOpts.push(selectedOpt);
      }
    });
  }

  const [selectedOpts, setSelectedOpts] = useState<SelectOption[]>(
    initSelectedOpts
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const optionRefs: Map<string, any> = new Map();

  const hasOptions = Boolean(options.length);
  const hasSearch = Boolean(onSearch);
  const menuDisabled = (!hasOptions && !hasSearch) || isDisabled;

  const isOptionSelected = useCallback(
    (optionId: string) => {
      return selectedOpts.map((opt: SelectOption) => opt.id).includes(optionId);
    },
    [selectedOpts]
  );

  const sortBySelectedName = useCallback(
    (aOpt: SelectOption, bOpt: SelectOption) => {
      const aSelected = isOptionSelected(aOpt.id);
      const bSelected = isOptionSelected(bOpt.id);

      if (aSelected === bSelected) {
        return aOpt.name > bOpt.name ? 1 : -1;
      }

      return aSelected ? -1 : 1;
    },
    [isOptionSelected]
  );

  const closeMenu = useCallback(() => {
    setOptions([...defaultOptions]);
    setMenuState({ ...menuState, open: false, searchKeyword: "" });
  }, [defaultOptions, menuState, setMenuState]);

  const toggleMenu = useCallback(() => {
    const headerEl = headerRef?.current;
    const position: MenuPosition = { top: 0 };

    if (headerEl) {
      const headerPos = headerEl.getBoundingClientRect();
      position.top = headerPos.top + headerPos.height + 4;
      position.left = headerPos.left;

      // @todo auto-detect this condition rather than allowing fix via props config
      if (menuPosition === "right" && width) {
        const widthDiff = Number(width) - headerPos.width;
        position.left -= widthDiff;
      }
    }

    const open = !menuState.open && !menuDisabled;

    if (open) {
      setOptions([...options].sort(sortBySelectedName));
    }

    setMenuState({
      open,
      position,
      searchKeyword: open ? menuState.searchKeyword : ""
    });
  }, [
    headerRef,
    menuDisabled,
    menuPosition,
    menuState,
    options,
    setMenuState,
    sortBySelectedName,
    width
  ]);

  const toggleOption = (toggleOpt: SelectOption, selected: boolean) => {
    const { onChange } = props;
    let updatedOpts = [...selectedOpts];

    if (!multiSelect) {
      if (selected) {
        updatedOpts = [toggleOpt];
      } else {
        updatedOpts = [];
      }
    } else {
      if (selected) {
        updatedOpts.push(toggleOpt);
      } else {
        const optIndex = updatedOpts.findIndex(
          (opt: SelectOption) => opt.id === toggleOpt.id
        );
        if (optIndex > -1) {
          updatedOpts.splice(optIndex, 1);
        }
      }
    }

    setSelectedOpts(updatedOpts);
    onChange(updatedOpts);
  };

  useEffect(() => {
    if (searchTerm === previousSearchTerm) {
      return;
    }
    const searchKeyword = searchTerm.toLowerCase();

    let subscribed = true;
    const unsubscribe = () => {
      subscribed = false;
    };

    const makeUniqueOpts = (
      selectedOpts: SelectOption[],
      otherOpts: SelectOption[]
    ) => {
      const uniqueOpts = [...selectedOpts].sort(sortByName);

      otherOpts.forEach((opt: SelectOption) => {
        const optExists = uniqueOpts.find(
          (uOpt: SelectOption) => uOpt.id === opt.id
        );
        if (!optExists) {
          uniqueOpts.push(opt);
        }
      });

      if (createOption && searchTerm) {
        const matchingOption = uniqueOpts.find(
          (opt: SelectOption) =>
            opt.id === searchTerm || opt.name === searchTerm
        );

        if (!matchingOption) {
          uniqueOpts.push({
            id: searchTerm,
            name: searchTerm
          });
        }
      }

      return uniqueOpts;
    };

    if (onSearch) {
      onSearch(searchKeyword).then((searchOpts: SelectOption[]) => {
        if (subscribed) {
          setOptions(makeUniqueOpts(selectedOpts, searchOpts));
          setMenuState({
            ...menuState,
            searchKeyword
          });
        }
      });

      return unsubscribe;
    }

    let allOptions = [...(props.options || [])];

    if (searchKeyword) {
      allOptions = allOptions.filter((opt: SelectOption) =>
        String(opt.name).toLowerCase().includes(searchKeyword)
      );
    }

    setOptions(makeUniqueOpts(selectedOpts, allOptions));

    setMenuState({
      ...menuState,
      searchKeyword
    });

    return unsubscribe;
  }, [
    createOption,
    menuState,
    onSearch,
    previousSearchTerm,
    props.options,
    searchTerm,
    selectedOpts,
    setMenuState
  ]);

  const optionsFiltered = onFilterOptions ? onFilterOptions(options) : options;

  let displayedOpt = null;
  const hasSelectedOpts = Boolean(selectedOpts.length);

  if (hasSelectedOpts) {
    const firstOpt = selectedOpts.sort(sortByName)[0];

    if (onSelectedOption) {
      displayedOpt = onSelectedOption(firstOpt);
    } else if (onOption) {
      displayedOpt = onOption(firstOpt);
    } else {
      displayedOpt = firstOpt.name;
    }
  }

  const { open, position } = menuState;

  return (
    <CloseOnScroll alllowedClassName="options-container" onClose={closeMenu}>
      <div
        className={classnames("select-toggle-menu", {
          disabled: menuDisabled
        })}
      >
        <div
          className="select-toggle-menu-header"
          ref={headerRef}
          onClick={toggleMenu}
        >
          <div className="selected-wrapper">
            {!hasSelectedOpts && (
              <div className="placeholder">
                {placeholder ?? "Select Options"}
              </div>
            )}
            {displayedOpt}
            {selectedOpts.length > 1 && <span className="ellipsis">...</span>}
          </div>
          <div className="menu-arrow">
            <AppIcon type="menu-arrow" size="small" />
          </div>
        </div>
        {open && (
          <Portal container={menuPortalTarget ?? document.body}>
            <ClickAwayListener onClickAway={closeMenu}>
              <div
                className="select-toggle-menu-popper"
                ref={menuRef}
                style={{ ...position, width: width || "100%" }}
              >
                <div className="search-header">
                  <TextInput
                    autoFocus={true}
                    type="text"
                    placeholder={searchPlaceholder ?? "Search for an option"}
                    onChange={setSearchTerm}
                    value={searchTerm}
                    ref={searchInputRef}
                  />
                </div>
                <div className="options-container">
                  {optionsFiltered.map((opt: SelectOption) => (
                    <div
                      className="option-row"
                      key={opt.id}
                      onClick={() => {
                        const optionRef = optionRefs.get(opt.id);
                        optionRef.click();
                      }}
                    >
                      <ToggleSwitch
                        ref={(button) => optionRefs.set(opt.id, button)}
                        size="small"
                        on={isOptionSelected(opt.id)}
                        onChange={(selected: boolean) => {
                          if (selected && !multiSelect) {
                            selectedOpts.forEach((opt: SelectOption) => {
                              const optionRef = optionRefs.get(opt.id);
                              optionRef.click();
                            });
                          }

                          (searchInputRef.current as any)?.focus();

                          toggleOption(opt, selected);
                        }}
                      />
                      <div className="option-container">
                        {onOption ? onOption(opt) : opt.name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </ClickAwayListener>
          </Portal>
        )}
      </div>
    </CloseOnScroll>
  );
}
