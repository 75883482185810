import BaseService, { IBaseService } from "./base.service";
import { AppState, IAppState } from "../context";

export interface IAppContextService extends IBaseService {
  get(): IAppState;

  set(appState: IAppState): void;
}

export class AppContextService extends BaseService
  implements IAppContextService {
  private appState: IAppState = AppState;

  get(): IAppState {
    return this.appState;
  }

  set(appState: IAppState): void {
    this.appState = appState;
  }
}
