import { inject } from "inversify";
import * as _ from "lodash";

import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import { AppContextService, IAppContextService } from "./app-context.service";
import BaseModel from "../models/base.model";
import CompanyModel from "../models/company.model";
import TemplateModel from "../models/template.model";
import TemplatePropertyModel from "../models/template-property.model";

import { ObjectHash, sortByPosition } from "../utils/helpers";
import TagGroupModel from "../models/tag-group.model";

export type TemplateInput = {
  colors?: ObjectHash;
  helperText?: string;
  isIdentifier?: boolean;
  isMultiple?: boolean;
  isRequired?: boolean;
  inputType: string;
  key: string;
  label: string;
  labelHelperText?: string;
  options?: ObjectHash[];
  tagGroup?: TagGroupModel;
  withTime?: boolean;
  presetExclude?: boolean;
};

export interface IWorkspaceService {
  addModelTemplateProps(
    model: BaseModel,
    data: ObjectHash,
    templateName: string
  ): BaseModel;

  createTemplateProperty(
    data: ObjectHash
  ): Promise<TemplatePropertyModel | null>;

  dataIsFrozen(data: ObjectHash): boolean;

  getTemplateInputs(templateType: string): TemplateInput[];

  getTemplateInputProps(templateInput: TemplateInput, value: any): ObjectHash;

  getUserDefinedTemplates(): Promise<TemplateModel[]>;

  getUserDefinedTemplateById(templateId: string): Promise<TemplateModel | null>;

  makeWorkspaceTemplate(
    template: TemplateModel,
    company?: CompanyModel
  ): TemplateModel;

  makeWorkspaceTemplates(
    templates: TemplateModel[],
    company?: CompanyModel
  ): TemplateModel[];

  sortProfileProps(props: TemplatePropertyModel[]): TemplatePropertyModel[];

  updateTemplateProperty(
    propertyId: string,
    data: ObjectHash
  ): Promise<TemplatePropertyModel | null>;

  getUserTravelTablePreset(): {
    columns?: string[];
    fields?: ObjectHash;
  } | null;
}

export class WorkspaceService extends BaseService implements IWorkspaceService {
  @inject(ApiService)
  private apiService!: IApiService;

  @inject(AppContextService)
  private appContextService!: IAppContextService;

  addModelTemplateProps(
    model: BaseModel,
    data: ObjectHash,
    templateName: string
  ): BaseModel {
    const { templates } = this.appContextService.get();
    const template = templates.find(
      (template: TemplateModel) => template.name === templateName
    );

    if (!template?.properties?.length) {
      return model;
    }

    template.properties.forEach((templateProp: TemplatePropertyModel) => {
      const { name } = templateProp;
      (model as ObjectHash)[name] = data[name];
    });

    return model;
  }

  async createTemplateProperty(
    data: ObjectHash
  ): Promise<TemplatePropertyModel | null> {
    const response: TemplatePropertyModel | null = await this.apiService.post(
      "/user-defined-properties",
      data
    );

    if (!response) {
      return null;
    }

    return new TemplatePropertyModel(response);
  }

  dataIsFrozen(data: ObjectHash): boolean {
    const { settings } = this.appContextService.get();
    const { dataFreezeDate } = settings;
    const { bookedDateTime } = data ?? {};

    if (!bookedDateTime || !dataFreezeDate) {
      return false;
    }

    return bookedDateTime <= `${dataFreezeDate}T23:59:59Z`;
  }

  async getUserDefinedTemplates(): Promise<TemplateModel[]> {
    const response = await this.apiService.get("/user-defined-templates");

    if (!response || !response.length) {
      return [];
    }

    return response.map((row: any) => new TemplateModel(row));
  }

  public getTemplateInputs(templateType: string): TemplateInput[] {
    const { settings, templates } = this.appContextService.get();
    const { tagGroups } = settings;

    let templateInputs: TemplateInput[] = [];

    const templateNameHash: ObjectHash = {
      ACTIVITY: "activity",
      AIR: "flight",
      HOTEL: "accommodation",
      CAR: "transportation",
      RAIL: "rail",
      PROFILE: "profile"
    };

    templateType = String(templateType).toUpperCase();
    const isProfile = templateType === "PROFILE";
    const template = templates.find(
      (template: ObjectHash) => template.name === templateNameHash[templateType]
    );

    const templateProps = template?.properties ?? [];
    const propertiesToRemove: any[] = [];

    if (isProfile) {
      propertiesToRemove.push("name");
    } else {
      propertiesToRemove.push("duration", "fromTimezone", "toTimezone");
    }

    const validProps = templateProps.filter(
      (property: ObjectHash) =>
        !property.deleted &&
        !property.isCustomField &&
        !property.isHidden &&
        !propertiesToRemove.includes(property.name)
    );

    if (
      templateType === "AIR" &&
      validProps.map((prop: ObjectHash) => prop.name).includes("name")
    ) {
      templateInputs.push({
        key: "fromForSearch",
        label: "Departure Date",
        inputType: "date"
      });
    }

    if (isProfile) {
      this.sortProfileProps(validProps);
    }

    validProps.forEach((prop: ObjectHash) => {
      const input: TemplateInput = {
        key: prop.name,
        inputType: prop.propertyType,
        label: prop.friendlyName,
        withTime: false
      };
      const { propertyOptions, propertyType } = prop;

      if (isProfile && ["firstName", "lastName"].includes(prop.name)) {
        input.helperText = "Exactly as it appears on travel documents";
      }
      if (propertyType === "select") {
        input.options = (propertyOptions || []).map((option: ObjectHash) => ({
          label: option,
          value: option
        }));
      }
      if (propertyType === "date") {
        input.withTime = (propertyOptions || []).includes("with-time");
      }

      templateInputs.push(input);
    });

    const customProps = templateProps.filter(
      (property: ObjectHash) =>
        property.isCustomField &&
        !property.readOnly &&
        !property.deleted &&
        !property.isHidden &&
        !propertiesToRemove.includes(property.name)
    );

    customProps.forEach((prop: ObjectHash) => {
      const input: TemplateInput = {
        key: prop.name,
        inputType: prop.propertyType,
        isIdentifier: prop.isCustomField,
        isMultiple: (prop.isCustomField && prop.multiple) || false,
        label: prop.friendlyName
      };
      if (prop.propertyType === "select") {
        input.options = prop.propertyOptions.map(
          (option: { [index: string]: string }) => ({
            label: option,
            value: option
          })
        );
      }
      if (Object.keys(prop.colors || {}).length) {
        input.colors = prop.colors;
      }

      templateInputs.push(input);
    });

    if (isProfile) {
      templateInputs.push({
        key: "role",
        label: "Role",
        inputType: "role"
      });
      templateInputs.push({
        key: "linkedUserIds",
        label: "Aliases",
        inputType: "user_list",
        helperText: "Other traveler names linked to this profile"
      });
      templateInputs.push({
        key: "searchPreset",
        label: "Search Preset",
        inputType: "text"
      });
      templateInputs.push({
        key: "travelTablePreset",
        label: "Travel Table Preset",
        inputType: "text"
      });
    }

    tagGroups.forEach((tagGroup: TagGroupModel) => {
      const { hidden, tags } = tagGroup;

      if (hidden || !tags.length) {
        return;
      }

      templateInputs.push({
        inputType: "tag",
        key: tagGroup.getFieldId(),
        label: tagGroup.name,
        tagGroup
      });
    });

    // exclude inputs from forms by user travel table preset config
    if (!isProfile) {
      const userPreset = this.getUserTravelTablePreset();
      if (userPreset?.fields?.exclude) {
        const excludeInputs = [...userPreset?.fields?.exclude];
        templateInputs = templateInputs.map((input) => {
          const { key = "" } = input || {};
          input.presetExclude = excludeInputs.includes(key);
          return input;
        });
      }
    }

    return templateInputs;
  }

  public getTemplateInputProps(
    templateInput: TemplateInput,
    model: ObjectHash
  ): ObjectHash {
    const { key } = templateInput;
    const { isAdmin } = model;

    const componentProps: ObjectHash = {};

    if (key === "email" && isAdmin) {
      componentProps.disabled = true;
    }

    if (key === "linkedUserIds") {
      componentProps.disableSearch = true;
    }

    return componentProps;
  }

  async getUserDefinedTemplateById(
    templateId: string
  ): Promise<TemplateModel | null> {
    const response = await this.apiService.get(
      `/user-defined-templates/${templateId}`
    );

    if (!response) {
      return null;
    }

    return this.makeWorkspaceTemplate(new TemplateModel(response));
  }

  makeWorkspaceTemplate(
    template: TemplateModel,
    company?: CompanyModel
  ): TemplateModel {
    const hasIntegrations = company
      ? Boolean(company.features.integrations)
      : false;

    const addonTemplates = [
      "activity",
      "flight",
      "transportation",
      "accommodation",
      "rail"
    ];

    const templateAddonProps: TemplatePropertyModel[] = [];

    // company has integrations? add extra template properties
    if (hasIntegrations) {
      const defaultCustomProps = {
        isCustomField: true,
        propertyRestrictions: ["protected"]
      };

      templateAddonProps.push(
        new TemplatePropertyModel({
          ...defaultCustomProps,
          id: "bookedDateTime",
          friendlyName: "Booked Date",
          name: "bookedDateTime",
          readOnly: false,
          propertyType: "date"
        }),
        new TemplatePropertyModel({
          ...defaultCustomProps,
          id: "invoiceDate",
          friendlyName: "Invoice Date",
          name: "invoiceDate",
          readOnly: false,
          propertyType: "date"
        }),
        new TemplatePropertyModel({
          ...defaultCustomProps,
          id: "gdsLocator",
          friendlyName: "GDS Locator",
          name: "gdsLocator",
          readOnly: true,
          propertyType: "text"
        })
      );
    }

    const wsTemplate = new TemplateModel(template);

    wsTemplate.properties = wsTemplate.userDefinedProperties
      // remove fields like Passport, ID Card, Loyalty Programs
      .filter((prop: TemplatePropertyModel) => !prop.nestedProperties?.length)
      .map((prop: TemplatePropertyModel) => {
        const { name } = prop;

        prop.friendlyName = _.startCase(
          prop.friendlyName || name.replace(/_/g, " ")
        );
        prop.name = _.camelCase(name);

        if (name === "duration" && wsTemplate.name !== "profile") {
          prop.propertyType = "duration";
        }

        return prop;
      })
      // remove potential duplicates from add-on templates
      .filter(
        (prop: TemplatePropertyModel) =>
          !templateAddonProps.find(
            (addonField: any) => addonField.name === prop.name
          )
      );

    wsTemplate.properties.sort(sortByPosition);

    if (addonTemplates.includes(wsTemplate.name)) {
      wsTemplate.properties.unshift(...templateAddonProps);
    }

    return wsTemplate;
  }

  makeWorkspaceTemplates(
    templates: TemplateModel[],
    company?: CompanyModel
  ): TemplateModel[] {
    return templates
      .filter(
        (template) => template.name && !template.name.includes("Spreadsheet")
      )
      .map((template) => this.makeWorkspaceTemplate(template, company));
  }

  public sortProfileProps(
    props: TemplatePropertyModel[]
  ): TemplatePropertyModel[] {
    return props.sort(
      (aProp: TemplatePropertyModel, bProp: TemplatePropertyModel) => {
        const aProtected = aProp.isProtected();
        const aPos = aProp.position;
        const bProtected = bProp.isProtected();
        const bPos = bProp.position;

        if (aProtected && !bProtected) {
          return -1;
        }

        if (aPos === bPos) {
          return 0;
        }

        return aPos < bPos ? -1 : 1;
      }
    );
  }

  async updateTemplateProperty(
    propertyId: string,
    data: ObjectHash
  ): Promise<TemplatePropertyModel | null> {
    const response = await this.apiService.put(
      `/user-defined-properties/${propertyId}`,
      data
    );

    if (!response) {
      return null;
    }

    return new TemplatePropertyModel(response);
  }

  getUserTravelTablePreset(): {
    columns?: string[];
    fields?: ObjectHash;
  } | null {
    const { settings, user } = this.appContextService.get();

    if (!settings?.travelTablePresets) {
      return null;
    }

    const presets = settings.travelTablePresets;
    let preset: any = null;

    if (user?.travelTablePreset) {
      preset = presets[user.travelTablePreset];
    } else if (presets.default) {
      preset = presets.default;
    }

    return preset || null;
  }
}
