import BaseModel from "./base.model";
import { colorOptions } from "../components/form/inputs/ColorInput";
import Colors from "../styles/colors.module.scss";

export default class TagModel extends BaseModel {
  id: string;
  tagGroup: string;
  color: string;
  name: string;
  position: number;
  unconfirmed: boolean;

  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.tagGroup = data.tagGroup;
    this.color = data.color;
    this.name = data.name;
    this.position = data.position;
    this.unconfirmed = Boolean(data.unconfirmed);

    this.deleted = data.deleted;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  static getColors(): string[] {
    return (
      colorOptions
        // black and gray are in use for document builder tables, but should be excluded as tag colors
        .filter(
          (colorOpt: any) =>
            [Colors.black, Colors.gray].indexOf(colorOpt.value) === -1
        )
        .map((colorOpt: any) => colorOpt.label.toLowerCase())
    );
  }

  static getColorMap(): Map<string, string> {
    const colorMap = new Map();

    this.getColors().forEach((color: string) => {
      colorMap.set(
        color,
        colorOptions.find(
          (colorOpt: any) => colorOpt.label.toLowerCase() === color
        ).value
      );
    });

    return colorMap;
  }

  getChipColors(): {
    backgroundColor: string;
    borderColor: string;
    color: string;
  } {
    if (this.unconfirmed) {
      return {
        backgroundColor: Colors.tagLightBlue,
        borderColor: Colors.tagLightBlue,
        color: Colors.black
      };
    }

    const tagColor = this.color;
    const colorMap = TagModel.getColorMap();
    const isLightColor = Boolean(tagColor?.match(/^light .+/));
    const backgroundColor = colorMap.get(tagColor) || Colors.black;
    const fontColor = isLightColor ? Colors.black : Colors.white;

    let borderColor = backgroundColor;
    if (isLightColor) {
      const offsetBorderColor = tagColor.split(" ").pop();
      if (offsetBorderColor && colorMap.has(offsetBorderColor)) {
        borderColor = colorMap.get(offsetBorderColor) || "";
      }
    }

    return {
      backgroundColor,
      borderColor,
      color: fontColor
    };
  }

  getFieldId(): string {
    return `tag-${this.id}`;
  }

  static getIdFromField(fieldId: string): string {
    return fieldId.replace(/^tag-/, "");
  }

  // WARNING: If you change this method, you MUST also update TagGroupModel::getFieldId
  getTagGroupFieldId(): string {
    return `tag-group-${this.tagGroup}`;
  }

  static isFieldId(fieldId: string): boolean {
    return Boolean(fieldId.match(/^tag-[a-f\d]{24}$/));
  }
}
