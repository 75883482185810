import React, { RefObject, useCallback, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";

import {
  addLogUsers,
  getTmlColumns,
  filterTmlLogEntries,
  sortTmlLogEntries,
  updateLog,
  getTmlLogEntryGroupId
} from "../lib/service";
import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import { Columns, Filters, GroupSections, Sorts } from "./sink";
import ColumnMenu from "../../../sink/grid-actions/column-menu";
import { downloadCSV } from "../../../../utils/csv";
import FilterMenu from "../../../sink/grid-actions/filter-menu";
import { getCsvDataFromEntries } from "../lib/csv";
import GridModel from "../../../../models/grid.model";
import GroupMenu from "../../../sink/grid-actions/group-menu";
import KeywordSearchInput from "../../../sink/grid-actions/keyword-search-input";
import {
  LoadState,
  ObjectHash,
  sortByStringCompare
} from "../../../../utils/helpers";
import SortMenu from "../../../sink/grid-actions/sort-menu";
import UserAddInput from "../../../sink/grid-actions/user-add-input";

import { Column, Filter, Group, Sort } from "../../../sink/helpers";

import { TmlActionTypes } from "../context/reducer";
import { TmlEntryModel, TmlLogModel } from "../lib/models";
import TmlEntrySink from "./tml-entry-sink";
import useApp from "../../../../hooks/use-app.hook";
import useModal from "../../../../hooks/use-modal.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";
import { useTmlLog } from "../context";
import UserModel from "../../../../models/user.model";
// import useTravel from "../../../../hooks/use-travel.hook";

import "./tml-entries.scss";

interface Props {
  grid: GridModel;
  refs: {
    dialog: RefObject<HTMLElement>;
    tile: RefObject<HTMLElement>;
  };
}

export default function TmlEntries(props: Props) {
  const { setSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { settings } = useApp();
  const { dispatch, dispatchUsers, dispatchTrips, log, users } = useTmlLog();
  // const { lastBookingRequest } = useTravel();

  const fullColumns = getTmlColumns(
    Columns,
    log.settings.columns,
    settings.tagGroups
  );

  const defaultGroup = GroupSections[0].groups[2]; // group by arrival/departure date

  const { grid, refs } = props;
  const [keywordFilter, setKeywordFilter] = useState<Filter | null>(null);
  const [, setLoadState] = useState<LoadState>("unloaded");

  const [columns, setColumns] = useState<Column[]>(fullColumns);
  const [filters, setFilters] = useState<Filter[] | null>(null);
  const [group, setGroup] = useState<Group | null>(defaultGroup);
  const [sort, setSort] = useState<Sort>(Sorts[0]); // default sort is traveler asc
  const [entries, setEntries] = useState<TmlEntryModel[]>([]);

  const loadEntries = useCallback(async () => {
    const { entries } = log;

    const allFilters = [...(filters || [])];
    if (keywordFilter?.value) {
      allFilters.push({ ...keywordFilter });
    }

    const filterData = filterTmlLogEntries(entries, allFilters, users);
    const sortData = sortTmlLogEntries(filterData, sort, users);

    setEntries(sortData);

    setLoadState("loaded");
  }, [keywordFilter, log, filters, sort, users]);

  const handleAddUsers = async (userIds: string[]) => {
    const response = await addLogUsers(grid, log, userIds);

    if (!response) {
      setSnackbar({
        message: "There was a problem and the traveler was not added.",
        variant: "error"
      });
      return false;
    }

    const { trips, users } = response;

    /*
     * Dispatch updates to trip and user metadata (for the new entries) before dispatching the
     * change to the log itself. This prevents blank, un-sorted entries from briefly appearing in
     * the log before the required metadata to display and sort them is available.
     */
    dispatchUsers({
      type: TmlActionTypes.UpdateUsers,
      payload: users
    });

    dispatchTrips({
      type: TmlActionTypes.UpdateTrips,
      payload: trips
    });

    dispatch({ type: TmlActionTypes.AddEntry, payload: response.log });

    setSnackbar({
      message: "The traveler was added!",
      variant: "success"
    });

    return true;
  };

  const handleEditColumn = (column: Column) => {
    const { id } = column;
    const updatedColumns = [...columns];
    const columnIndex = updatedColumns.findIndex(
      (updateColumn: Column) => updateColumn.id === id
    );
    if (columnIndex === -1) {
      return;
    }

    updatedColumns[columnIndex] = column;
    handleSaveColumns(updatedColumns);
  };

  const handleAddColumn = (column: Column) => {
    column.canEdit = true;
    column.canDelete = true;
    const updatedColumns = [...columns];
    updatedColumns.push(column);
    handleSaveColumns(updatedColumns);
  };

  const handleDeleteColumn = (columnId: string) => {
    handleSaveColumns(
      columns.filter((column: Column) => column.id !== columnId)
    );
  };

  const handleSaveColumns = (columns: Column[]) => {
    setColumns(columns);

    const updatedLog = new TmlLogModel({ ...log });
    updatedLog.settings.columns = columns;

    updateLog(updatedLog).then((response) => {
      if (!response) {
        setSnackbar({
          message: "There was a problem and your changes were not saved",
          variant: "error"
        });
      }

      dispatch({ type: TmlActionTypes.UpdateLog, payload: response });
    });
  };

  const handleChangeColumnHide = (id: string, hidden: boolean) => {
    const columnIndex = columns.findIndex((column: Column) => column.id === id);

    if (columnIndex === -1) {
      return;
    }

    const updatedColumns = [...columns];
    updatedColumns[columnIndex].hidden = hidden;

    handleSaveColumns(updatedColumns);
  };

  const handleDownload = useCallback(() => {
    let rowData = cloneDeep(entries);
    if (group) {
      rowData = rowData.map((entry: TmlEntryModel) => {
        const user = users.get(entry.userId) || new UserModel();
        (entry as ObjectHash).groupId = getTmlLogEntryGroupId(
          entry,
          group,
          user
        );
        return entry;
      });

      const sort = {
        column: { id: "groupId" },
        direction: "asc" as "asc" | "desc"
      };

      rowData.sort((a, b) => sortByStringCompare(a, b, sort));
    }
    const csvData = getCsvDataFromEntries(columns, rowData, users);
    downloadCSV(csvData, `${grid.name} - TML.csv`);
  }, [columns, grid.name, entries, users, group]);

  // update results when entries change
  useEffect(() => {
    loadEntries();
  }, [loadEntries, log.entries]);

  // update results when keyword filter changes
  useEffect(() => {
    if (keywordFilter === null) {
      return;
    }
    loadEntries();
  }, [keywordFilter, loadEntries]);

  // update results when filter menu filters change
  useEffect(() => {
    if (filters === null) {
      return;
    }
    loadEntries();
  }, [loadEntries, filters]);

  // update results when new requests come in from the websocket
  // useEffect(() => {
  //   const { bookingCollection, id } = lastBookingRequest;
  //   if (bookingCollection !== collection.id) {
  //     return;
  //   }
  // }, [handleReload, lastBookingRequest]);

  const hasFilters = Boolean(filters?.length);
  const emptyMessage = hasFilters
    ? "There was no travel found that mach your filter criteria"
    : "You haven't added any travel to the log yet";

  const { dialog } = refs;

  return (
    <div id="tml-entries">
      <div className="sink-toolbar">
        <div>
          <UserAddInput
            onAdd={(userId: string) => handleAddUsers([userId])}
            menuPortalTarget={dialog?.current}
          />

          <span
            className="add-button"
            onClick={() =>
              openModal("add-profiles", {
                onChange: handleAddUsers
              })
            }
          >
            <AppIconFrame color="product-background-blue" shape="square">
              <AppIcon type="add-users" />
            </AppIconFrame>
          </span>
        </div>
        <div>
          <KeywordSearchInput
            shrink={true}
            shrinkDirection="right"
            onChange={(keyword: string, keywordFilter: Filter) =>
              setKeywordFilter(keywordFilter)
            }
          />
          <div className="download-button" onClick={() => handleDownload()}>
            <AppIconFrame color="product-background-blue" shape="square">
              <AppIcon type="download" />
            </AppIconFrame>
          </div>

          <FilterMenu
            disablePortal={true}
            filters={Filters}
            portalTarget={dialog?.current || undefined}
            onChange={setFilters}
          />
          <SortMenu
            defaultValue={sort}
            disablePortal={true}
            sorts={Sorts}
            onChange={setSort}
          />
          <GroupMenu
            defaultValue={defaultGroup}
            disablePortal={true}
            onChange={setGroup}
            sections={GroupSections}
          />
          <ColumnMenu
            columns={columns}
            disablePortal={true}
            onAddColumn={handleAddColumn}
            onChangeHide={handleChangeColumnHide}
            onChangeSort={handleSaveColumns}
            onDeleteColumn={handleDeleteColumn}
            onEditColumn={handleEditColumn}
          />
        </div>
      </div>
      <TmlEntrySink
        columns={columns}
        emptyMessage={emptyMessage}
        filters={filters || []}
        group={group}
        portalTarget={dialog}
        entries={entries}
        sort={sort}
      />
    </div>
  );
}
