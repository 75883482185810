import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import { debounce } from "lodash";

import { AppSettings } from "../../context";
import useModal from "../../hooks/use-modal.hook";

import CompanyModel from "../../models/company.model";
import UserModel from "../../models/user.model";
import GridModel from "../../models/grid.model";

import { sortByCreatedAt } from "../../utils/helpers";

import AppSidebarLink from "./app-sidebar-link";
import Button from "../button";
import CompanyLogo from "../company-logo";
import GridLink from "./grid-link";
import GridSearchInput from "../form/inputs/GridSearchInput";
import Tooltip from "../tooltip";

import TripgridLetterMark from "../../images/tripgrid-letter-mark.svg";
import "./app-sidebar.scss";

interface Props {
  user: UserModel;
  company: CompanyModel;
  settings: AppSettings;
}

const AppSidebar = (props: Props) => {
  const history = useHistory();
  const { location } = history;

  const { openModal } = useModal();

  const { company, user, settings } = props;
  const { features } = company;
  const shouldAutoCollapse = useCallback(
    (): boolean => window.innerWidth <= 1200,
    []
  );

  const [expanded, setExpanded] = useState<boolean>(!shouldAutoCollapse());

  const showGrids = Boolean(features.grids);
  const showReports = user.primary && Boolean(features.reports);
  // @FEATURE_FLAG
  const showCollections = false; // @todo also needs to be a turned-on feature for the company?

  const starredGrids = settings.grids
    .filter(
      (grid: GridModel) => !grid.archived && grid.starredBy.includes(user.id) // ignore archived
    )
    .sort(sortByCreatedAt); // no limit on the starred grids shown, use slice if we want to limit later

  const recentGrids = settings.grids
    .filter(
      (grid: GridModel) => !grid.archived && !grid.starredBy.includes(user.id) // ignore archived and starred to prevent duplicate listing
    )
    .sort(sortByCreatedAt)
    .slice(0, 3);

  useEffect(() => {
    const autoCollapse = debounce(
      () => {
        if (expanded && shouldAutoCollapse()) {
          setExpanded(false);
        }
      },
      300,
      { leading: false, trailing: true }
    );

    window.addEventListener("resize", autoCollapse);

    return () => window.removeEventListener("resize", autoCollapse);
  }, [expanded, setExpanded, shouldAutoCollapse]);

  const gridElements = recentGrids.map((grid: GridModel) => {
    const active = location.pathname.includes(grid.id);
    return (
      <GridLink
        active={active}
        grid={grid}
        key={`app-sidebar-grid-link-${grid.id}`}
      />
    );
  });

  const starredGridElements = starredGrids.map((grid: GridModel) => {
    const active = location.pathname.includes(grid.id);
    return (
      <GridLink
        active={active}
        grid={grid}
        starred={true}
        key={`app-sidebar-grid-link-starred-${grid.id}`}
      />
    );
  });

  return (
    <div className={classnames("app-sidebar", { collapsed: !expanded })}>
      <div className="gray-background">
        <div className="inner">
          <div className="main">
            <div className="app-sidebar-header">
              <CompanyLogo company={company} />
              <span className="tg-letter-mark">
                <img alt="Tripgrid" src={TripgridLetterMark} />
              </span>
            </div>
            {showGrids && (
              <div className="app-sidebar-search">
                <GridSearchInput
                  onChange={(gridId: string) =>
                    history.push(`/grids/${gridId}`)
                  }
                />
              </div>
            )}
            <div className="app-sidebar-links">
              <AppSidebarLink
                alias="/"
                href="/dashboard"
                icon="home"
                label="All Travel"
              />
              {showCollections && (
                <AppSidebarLink
                  href="/collections"
                  icon="tg-collection"
                  label="Collections"
                />
              )}
              {showGrids && (
                <AppSidebarLink href="/grids" icon="tg-grid" label="Grids" />
              )}
            </div>

            {showGrids && (
              <React.Fragment>
                <div className="app-sidebar-grids-nav">
                  <div className="header">starred grids</div>
                  {starredGridElements}
                </div>

                <div className="app-sidebar-grids-nav">
                  <div className="header">recent grids</div>
                  {gridElements}
                </div>
              </React.Fragment>
            )}

            <div className="divider"></div>

            <AppSidebarLink href="/people" icon="people" label="People" />

            {showReports && (
              <AppSidebarLink
                href="#"
                icon="reports"
                label="Reports"
                onClick={() => {
                  openModal("customize-report", {
                    iconType: "dollar",
                    reportType: "Cost",
                    reportDesc:
                      "This report pulls travel with key identifiers for finance reconcilliation."
                  });
                }}
              />
            )}
          </div>
          <div className="footer">
            <Tooltip text="Help Center">
              <span>
                <Button
                  color="green"
                  icon="help-center"
                  isRippleDisabled={true}
                  isRounded={true}
                  isTransparent={true}
                  onClick={() =>
                    window.open("https://help.tripgrid.com", "_blank")
                  }
                  size="medium"
                />
              </span>
            </Tooltip>

            {user.isSuperAdmin() && (
              <Tooltip text="Admin Dashboard">
                <span>
                  <Button
                    color="dark-gray"
                    icon="admin"
                    isRippleDisabled={true}
                    isRounded={true}
                    isTransparent={true}
                    onClick={() => history.push("/admin")}
                    size="medium"
                  />
                </span>
              </Tooltip>
            )}

            {user.primary && (
              <Tooltip text="Settings">
                <span>
                  <Button
                    color="dark-gray"
                    icon="settings"
                    isRippleDisabled={true}
                    isRounded={true}
                    isTransparent={true}
                    onClick={() => history.push("/settings")}
                    size="medium"
                  />
                </span>
              </Tooltip>
            )}

            <Tooltip text="Log Out">
              <span>
                <Button
                  color="dark-gray"
                  icon="logout"
                  isRippleDisabled={true}
                  isRounded={true}
                  isTransparent={true}
                  onClick={() =>
                    openModal("confirm", {
                      buttonText: "Continue",
                      dialogTitle: "Log Out of Tripgrid",
                      dialogBody:
                        "Are you sure you want to log out of Tripgrid?",
                      onConfirm: () => history.push("/logout")
                    })
                  }
                  size="medium"
                />
              </span>
            </Tooltip>
          </div>
          <div className="create-grid-button">
            <Button
              color="dark-gray"
              isRippleDisabled={true}
              icon="plus"
              isFullWidth={true}
              isRounded={false}
              isTransparent={true}
              label="Create New Grid"
              onClick={() => {
                openModal("edit-grid", {
                  onChangeGrid: (grid: GridModel) => {
                    history.push(`/grids/${grid.id}`);
                  }
                });
              }}
              size="large"
            />
          </div>
        </div>
      </div>

      <div className="expander-button">
        <Button
          color="background-gray"
          isRippleDisabled={true}
          icon={expanded ? "nav-arrow-left" : "nav-arrow-right"}
          size="medium"
          onClick={() => setExpanded(!expanded)}
        />
      </div>
    </div>
  );
};

export default AppSidebar;
