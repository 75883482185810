import { Container } from "inversify";

import { AppContextService } from "./services/app-context.service";
import { AdminService } from "./services/admin.service";
import { ApiService } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import { AuthStorageService } from "./services/auth-storage.service";
import { CollectionService } from "./services/collection.service";
import { CompanyService } from "./services/company.service";
import { DocumentService } from "./services/document.service";
import { FlightService } from "./services/flight.service";
import { GeoService } from "./services/geo.service";
import { GridService } from "./services/grid.service";
import { ImpersonateService } from "./services/impersonate.service";
import { IndexDbService } from "./services/index-db.service";
import { IntegrationService } from "./services/integration.service";
import { LocalStorageService } from "./services/local-storage.service";
import { SearchDbService } from "./services/search-db.service";
import { SearchService } from "./services/search.service";
import { SegmentService } from "./services/segment.service";
import { TagService } from "./services/tag.service";
import { TravelQueryService } from "./services/travel-query.service";
import { TripService } from "./services/trip.service";
import { UserService } from "./services/user.service";
import { WebsocketService } from "./services/websocket.service";
import { WorkspaceService } from "./services/workspace.service";

const AppContainer = new Container();

AppContainer.bind(AppContextService).toSelf().inSingletonScope();
AppContainer.bind(AdminService).toSelf().inSingletonScope();
AppContainer.bind(ApiService).toSelf().inSingletonScope();
AppContainer.bind(AuthService).toSelf().inSingletonScope();
AppContainer.bind(AuthStorageService).toSelf().inSingletonScope();
AppContainer.bind(CollectionService).toSelf().inSingletonScope();
AppContainer.bind(CompanyService).toSelf().inSingletonScope();
AppContainer.bind(DocumentService).toSelf().inSingletonScope();
AppContainer.bind(FlightService).toSelf().inSingletonScope();
AppContainer.bind(GeoService).toSelf().inSingletonScope();
AppContainer.bind(GridService).toSelf().inSingletonScope();
AppContainer.bind(ImpersonateService).toSelf().inSingletonScope();
AppContainer.bind(IndexDbService).toSelf().inSingletonScope();
AppContainer.bind(IntegrationService).toSelf().inSingletonScope();
AppContainer.bind(LocalStorageService).toSelf().inSingletonScope();
AppContainer.bind(SearchDbService).toSelf().inSingletonScope();
AppContainer.bind(SearchService).toSelf().inSingletonScope();
AppContainer.bind(SegmentService).toSelf().inSingletonScope();
AppContainer.bind(TagService).toSelf().inSingletonScope();
AppContainer.bind(TravelQueryService).toSelf().inSingletonScope();
AppContainer.bind(TripService).toSelf().inSingletonScope();
AppContainer.bind(UserService).toSelf().inSingletonScope();
AppContainer.bind(WebsocketService).toSelf().inSingletonScope();
AppContainer.bind(WorkspaceService).toSelf().inSingletonScope();

export default AppContainer;
