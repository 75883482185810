import { DateTime } from "luxon";
import { inject } from "inversify";

import { ApiService, IApiService } from "./api.service";
import BaseService from "./base.service";
import { SearchService, ISearchService } from "./search.service";

import AirportModel from "../models/airport.model";

import { airportToIata, ObjectHash } from "../utils/helpers";
import TripModel from "../models/trip.model";
import SegmentModel from "../models/segment.model";

export type FlightStatsResponse = {
  appendix: ObjectHash;
  request: ObjectHash;
  scheduledFlights: ObjectHash[];
};

export interface IFlightService {
  airlineSearch(keyword: string): Promise<any[]>;

  airportSearch(keyword: string): Promise<AirportModel[]>;

  flightSearch(
    airlineAbbr: string,
    formattedFlightNum: string,
    date: string
  ): Promise<FlightStatsResponse>;

  getAirportsByIata(iatas: string[]): Promise<AirportModel[]>;

  getAirportsByIds(ids: string[]): Promise<AirportModel[]>;

  getAirportsWithCustom(names: string[]): Promise<AirportModel[]>;

  getAirportsforTrips(trips: TripModel[]): Promise<Map<string, AirportModel>>;
}

export class FlightService extends BaseService implements IFlightService {
  @inject(ApiService)
  apiService!: IApiService;

  @inject(SearchService)
  searchService!: ISearchService;

  async airlineSearch(keyword: string): Promise<any[]> {
    const response = await this.searchService.search("airlines", {
      filters: [["name", keyword]],
      fields: ["fs", "iata", "name"],
      order: "name"
    });

    if (!response) {
      return [];
    }

    return response;
  }

  async airportSearch(keyword: string): Promise<AirportModel[]> {
    if (!keyword || !keyword.length) {
      return Promise.resolve([]);
    }

    return this.searchService
      .search("airports", {
        fields: ["city", "iata", "name", "timezoneRegionName"],
        filters: [
          ["city", keyword],
          ["iata", keyword],
          ["name", keyword]
        ],
        any: true,
        order: "iata",
        limit: 400
      })
      .then((airports: any[]) => {
        const allFields = ["iata", "city", "name"];
        const startFields = ["iata", "city"];

        const keywordComp = keyword.toLowerCase();

        if (!airports || !airports.length) {
          return [];
        }

        airports.sort((a: any, b: any) => {
          /*
           * Any field exact match
           */
          const fieldExactMatch = (field: string, airport: any) =>
            airport[field] &&
            String(airport[field]).toLowerCase() === keywordComp;

          const aAnyFieldExactMatch = allFields.find((field: string) =>
            fieldExactMatch(field, a)
          );
          const bAnyFieldExactMatch = allFields.find((field: string) =>
            fieldExactMatch(field, b)
          );

          if (aAnyFieldExactMatch) {
            return -1;
          }

          if (bAnyFieldExactMatch) {
            return 1;
          }

          /*
           * IATA/city starts with
           */
          const fieldStartMatch = (field: string, airport: any) =>
            airport[field] &&
            String(airport[field]).toLowerCase().startsWith(keywordComp);

          const aStartFieldExactMatch = startFields.find((field: string) =>
            fieldStartMatch(field, a)
          );
          const bStartFieldExactMatch = startFields.find((field: string) =>
            fieldStartMatch(field, b)
          );

          if (aStartFieldExactMatch) {
            return -1;
          }

          if (bStartFieldExactMatch) {
            return 1;
          }

          /*
           * Name starts with
           */
          if (a.name && String(a.name).toLowerCase().startsWith(keywordComp)) {
            return -1;
          }

          if (b.name && String(b.name).toLowerCase().startsWith(keywordComp)) {
            return -1;
          }

          return 0;
        });

        return airports
          .slice(0, 10)
          .map((airport) => new AirportModel(airport));
      });
  }

  async flightSearch(
    airlineAbbr: string,
    formattedFlightNum: string,
    date: string
  ): Promise<FlightStatsResponse> {
    const formattedDate = DateTime.fromISO(date).toFormat("yyyy/MM/dd");

    const encoded = window.encodeURIComponent(
      `https://api.flightstats.com/flex/schedules/rest/v1/json/flight/${airlineAbbr}/${formattedFlightNum}/departing/${formattedDate}`
    );

    // handled by backend because FlightStats did not implement CORS, browser will reject request before sending
    const response: FlightStatsResponse = await this.apiService.get(
      `/forward?uri=${encoded}&api=flightStats`
    );

    return response;
  }

  async getAirportsWithCustom(names: string[]): Promise<AirportModel[]> {
    const iatas: string[] = [];
    const customs: string[] = [];
    let airports: AirportModel[] = [];

    names.forEach((name: string) => {
      const iata = airportToIata(name);

      if (iata) {
        iatas.push(iata);
        return;
      }

      if (name) {
        customs.push(name);
      }
    });

    if (iatas.length) {
      airports = await this.getAirportsByIata(iatas);
    }

    customs.forEach((name: string) => {
      airports.push(new AirportModel({ id: name, name }));
    });

    return airports;
  }

  async getAirportsByIata(iatas: string[]): Promise<AirportModel[]> {
    if (!iatas?.length) {
      return [];
    }

    const response = await this.searchService.search("airports", {
      fields: ["id", "city", "iata", "name", "timezoneRegionName"],
      filters: [["iata", iatas]],
      any: true,
      order: "iata",
      limit: 400
    });

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new AirportModel(data));
  }

  async getAirportsByIds(ids: string[]): Promise<AirportModel[]> {
    if (!ids?.length) {
      return [];
    }

    const response = await this.searchService.search("airports", {
      fields: ["id", "city", "iata", "name", "timezoneRegionName"],
      filters: [["id", ids]],
      any: true,
      order: "iata",
      limit: 400
    });

    if (!response) {
      return [];
    }

    return response.map((data: ObjectHash) => new AirportModel(data));
  }

  async getAirportsforTrips(
    trips: TripModel[]
  ): Promise<Map<string, AirportModel>> {
    const iataLocationMap: Map<string, string> = new Map();
    const iataAiportMap: Map<string, AirportModel> = new Map();

    trips.forEach((trip: TripModel) => {
      const { segments, type } = trip;
      if (type !== "AIR") {
        return;
      }

      segments.forEach((segment: SegmentModel) => {
        const { fromLocation, toLocation } = segment;
        const fromIata = airportToIata(fromLocation);
        const toIata = airportToIata(toLocation);

        if (fromIata) {
          iataLocationMap.set(fromIata, fromLocation);
        }

        if (toIata) {
          iataLocationMap.set(toIata, toLocation);
        }
      });
    });

    if (!iataLocationMap.size) {
      return iataAiportMap;
    }

    const response = await this.searchService.search("airports", {
      fields: ["city", "iata", "name", "timezoneRegionName"],
      filters: [["iata", Array.from(iataLocationMap.keys())]],
      any: true,
      order: "iata",
      limit: 400
    });

    if (!response) {
      return iataAiportMap;
    }

    response.forEach((data: ObjectHash) => {
      const airport = new AirportModel(data);
      const source = iataLocationMap.get(data.iata);
      if (source) {
        iataAiportMap.set(source, airport);
      }
    });

    return iataAiportMap;
  }
}
