import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
  useMemo
} from "react";

import AccordionGroup from "../../../accordion-group";
import FormController from "../../../form/form-controller";

import AppContainer from "../../../../container";
import { IUserService, UserService } from "../../../../services/user.service";
import {
  IWorkspaceService,
  TemplateInput,
  WorkspaceService
} from "../../../../services/workspace.service";
import TagModel from "../../../../models/tag.model";
import UserModel from "../../../../models/user.model";

import { LoadState, ObjectHash } from "../../../../utils/helpers";
import { UserModelValidator } from "../../../../utils/form";

interface Props {
  editing: boolean;
  loadState: LoadState;
  menuPortalTarget: any;
  onFormChange?: CallableFunction;
  onSave?: CallableFunction;
  onValidate?: CallableFunction;
  user: UserModel;
}

const ProfileForm = forwardRef((props: Props, ref: any) => {
  const userService: IUserService = AppContainer.get(UserService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  // When creating a new user email is required, when editing, it's optional
  const ModelValidator: ObjectHash = useMemo(
    () => ({ ...UserModelValidator, email: { emailOptional: {} } }),
    []
  );

  const {
    editing,
    loadState,
    menuPortalTarget,
    onFormChange,
    onSave,
    onValidate,
    user
  } = props;

  const formRef = useRef();

  const isNew = !Boolean(user.id);

  const getFoldKey = useCallback(
    (inputKey: string): "unfolded" | "folded" => {
      if (editing) {
        return "unfolded";
      }
      return ["firstName", "lastName", "email"].includes(inputKey)
        ? "unfolded"
        : "folded";
    },
    [editing]
  );

  const templateInputs = workspaceService.getTemplateInputs("profile");

  const formInputs = templateInputs.filter((templateInput: TemplateInput) => {
    const { inputType, key } = templateInput;

    // these fields are moved to the company settings form
    if (inputType === "tag" || ["role", "linkedUserIds"].includes(key)) {
      return false;
    }
    return true;
  });

  const handleZeroState = (zeroState: ObjectHash): ObjectHash => {
    formInputs.forEach((templateInput: TemplateInput) => {
      const { isMultiple, key } = templateInput;
      let value = user[key as keyof UserModel];
      const defaultValue = isMultiple ? null : "";
      zeroState[key] = value || defaultValue;
    });

    return zeroState;
  };

  const [accordionTitle, setAccordionTitle] = useState("View All");

  const handleSave = async (formState: ObjectHash) => {
    const payload: ObjectHash = { ...user.toJSON(), ...formState, tags: [] };

    // tags are handled by the company settings form, so just echo back whatever's already set
    if (user?.tags?.length) {
      payload.tags = user.tags.map((tag: TagModel) => tag.id);
    }

    let updatedUser;

    if (isNew) {
      updatedUser = await userService.create(payload);
    } else {
      updatedUser = await userService.update(payload.id, payload, true);
    }

    updatedUser = updatedUser
      ? await userService.getById(updatedUser.id, true, true)
      : null;

    onSave && onSave(updatedUser);
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      formRef.current && (formRef.current as any).save();
    }
  }));

  const formKeysState: { unfolded: string[]; folded: string[] } = {
    unfolded: [],
    folded: []
  };

  formInputs.forEach((templateInput: TemplateInput) => {
    const { key } = templateInput;
    formKeysState[getFoldKey(key)].push(key);
  });

  const handleCustomProps = (
    templateInput: TemplateInput,
    customProps: ObjectHash
  ): ObjectHash => {
    customProps.menuPortalTarget = menuPortalTarget; // for react-select menus, renders on top of modal to prevent clipping
    return customProps;
  };

  const hasFolded = Boolean(formKeysState.folded.length);

  return (
    <div className="profile-form">
      <div className="unfolded">
        <FormController
          fields={formInputs.filter((formInput) =>
            formKeysState.unfolded.includes(formInput.key)
          )}
          loadState={loadState}
          model={user}
          onCustomProps={handleCustomProps}
          onFormChange={(changed: boolean) =>
            onFormChange && onFormChange(changed)
          }
          onSave={handleSave}
          onValidate={(formValid: boolean) =>
            onValidate && onValidate(formValid)
          }
          onZeroState={handleZeroState}
          readOnly={!editing}
          ref={formRef}
          validator={ModelValidator}
        />
      </div>
      {hasFolded && (
        <div className="folded">
          <AccordionGroup
            defaultOpen={-1}
            titles={[accordionTitle]}
            onGroupChange={(title: string) =>
              setAccordionTitle(title === "View All" ? "Hide" : "View All")
            }
          >
            <FormController
              fields={formInputs.filter((formInput) =>
                formKeysState.folded.includes(formInput.key)
              )}
              loadState={loadState}
              model={user}
              onCustomProps={handleCustomProps}
              onFormChange={(changed: boolean) =>
                onFormChange && onFormChange(changed)
              }
              onSave={handleSave}
              onValidate={(formValid: boolean) =>
                onValidate && onValidate(formValid)
              }
              onZeroState={handleZeroState}
              readOnly={!editing}
              ref={formRef}
              validator={ModelValidator}
            />
          </AccordionGroup>
        </div>
      )}
    </div>
  );
});

export default ProfileForm;
