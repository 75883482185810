import React from "react";
import AirportModel from "../../models/airport.model";
import { airportToIata } from "../../utils/helpers";
import "./airport-chip.scss";

interface Props {
  airport: string | AirportModel;
}

export default function AirportChip(props: Props) {
  const { airport } = props;

  const iataCode =
    typeof airport === "string"
      ? airportToIata(airport)
      : airport.iata || airport.name;

  return (
    <div className="airport-chip">
      <div className="label">{iataCode}</div>
    </div>
  );
}
