import React from "react";
import Loader from "../loader";
import "./page-loader.scss";

export interface Props {
  title: string;
}

export default function PageLoader(props: Props) {
  const { title } = props;
  return (
    <div className="page-loader">
      <Loader type="dots" />
      <div className="title">{title}</div>
    </div>
  );
}
