import React, { MouseEvent } from "react";
import classnames from "classnames";
import AppIcon from "../app-icon";
import TagModel from "../../models/tag.model";
import "./color-palette.scss";

export interface Props {
  onClick: CallableFunction;
  colors?: string[];
  selected?: string;
  fullText?: boolean;
}

export default function ColorPalette(props: Props) {
  let { colors } = props;
  const { onClick, fullText } = props;
  const [selected, setSelected] = React.useState(props.selected);

  if (!colors) {
    const tagColors = TagModel.getColors();
    const lightColors = tagColors.filter((tc) => tc.startsWith("light "));
    const darkColors = tagColors.filter((tc) => !tc.startsWith("light "));
    lightColors.sort();
    darkColors.sort();
    colors = darkColors.concat(lightColors);
  }
  const text = fullText ? "Tag" : "T";

  return (
    <div className="color-palette">
      {colors.map((colorOption: any) => (
        <div
          className={classnames("palette", colorOption, {
            "full-text": fullText
          })}
          key={colorOption}
          onClick={(event: MouseEvent) => {
            setSelected(colorOption);
            onClick(colorOption);
          }}
        >
          {selected === colorOption ? (
            <AppIcon type="checkmark" size="x-small" />
          ) : (
            <span>{text}</span>
          )}
        </div>
      ))}
    </div>
  );
}
