import React, { useCallback, useEffect, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from "@material-ui/core/FormControl";
import Popper from "@material-ui/core/Popper";
import { uniqueId } from "lodash";

import AutocompleteInput from "../../../form/inputs/AutocompleteInput";
import Button from "../../../button";
import RemoveButton from "../../../button/remove";
import Tooltip from "../../../tooltip";

import { getValueInputComponent } from "./helper-funcs";
import { ObjectHash } from "../../../../utils/helpers";

import "./filter-menu.scss";
import useModal from "../../../../hooks/use-modal.hook";

interface Props {
  allTrips: any[];
  customDataOrder: any[];
  data: any[];
  filterableCols: any[];
  onApplyFilter: CallableFunction;
  onResetCustomDataOrder: CallableFunction;
  tableFiltersState: any[];
  tagId: string;
}

export default function FilterMenu(props: Props) {
  const { openModal } = useModal();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const popperClass = open
    ? "filter-popper filter-popper--open"
    : "filter-popper";

  const filtersHash: ObjectHash = {
    After: "from",
    Before: "to",
    "Date Range": "range"
  };

  const getFilterId = (): string => uniqueId("table-filter-");

  props.filterableCols.forEach((col) => {
    if (!["from", "to"].includes(col[0]) && !col[0].includes("tag-group-")) {
      // don't include from/to keys, dates handled uniquely, @todo include tag-group keys after fixing filtering
      [filtersHash[col[1]]] = col;
    }
  });

  let zeroState = {
    filters: [
      {
        id: getFilterId(),
        label: "",
        key: "",
        value: {}
      }
    ]
  };

  if (props.tableFiltersState.length) {
    zeroState = {
      filters: props.tableFiltersState
        .map(([filterKey, filterValues]: [string, any[]]) => {
          if (filterKey === "dates" && filterValues[0]?.length) {
            // generate filters based on epoch dates
            return filterValues[0]
              .map((v: any, index: number) => {
                if ([0, 9999999999999].includes(v)) {
                  return null;
                }
                const dateFilterKey = index === 0 ? "from" : "to";
                return {
                  id: getFilterId(),
                  label: (Object.entries(filtersHash).find(
                    (kv) => kv[1] === dateFilterKey
                  ) || [""])[0],
                  key: dateFilterKey,
                  value: { [dateFilterKey]: v }
                };
              })
              .filter((f: any) => f !== null);
          }

          if (filterKey === "users") {
            // loads all the traveler filter values into one multi input on initial load
            return {
              id: getFilterId(),
              label: (Object.entries(filtersHash).find(
                (kv) => kv[1] === filterKey
              ) || [""])[0],
              key: filterKey,
              value: { [filterKey]: filterValues }
            };
          }

          if (filterValues.length > 1) {
            // generate a single filter for each filter value to show in menu
            return filterValues.map((value: string) => ({
              id: getFilterId(),
              label: (Object.entries(filtersHash).find(
                (kv) => kv[1] === filterKey
              ) || [""])[0],
              key: filterKey,
              value: { [filterKey]: value }
            }));
          }

          return {
            id: getFilterId(),
            label: (Object.entries(filtersHash).find(
              (kv) => kv[1] === filterKey
            ) || [""])[0],
            key: filterKey,
            value: { [filterKey]: filterValues[0] }
          };
        })
        .flat()
    };
  }

  const [state, setState] = React.useState({
    ...zeroState
  });

  const handleClick = (e: any) => {
    const target = e.currentTarget;
    if (props.customDataOrder.length) {
      openModal("confirm", {
        buttonText: "Clear It",
        dialogTitle: "Clear Custom Order",
        dialogBody:
          "Custom ordering of data will be lost, do you want to continue?",
        onConfirm: () => {
          props.onResetCustomDataOrder();
          setAnchorEl(target);
        }
      });
    } else {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleClickAddFilter = () => {
    const newFilters = state.filters.slice();
    newFilters.push({
      id: getFilterId(),
      label: "",
      key: "",
      value: {}
    });
    setState((oldValues) => ({
      ...oldValues,
      filters: newFilters
    }));
  };

  const handleFilterTypeChange = (v: string, id: string) => {
    const newFilters = state.filters.slice();
    const changedFilter: any = newFilters.find((filter) => filter.id === id);
    let newKey = "";
    if (Object.hasOwnProperty.call(filtersHash, v)) {
      newKey = filtersHash[v];
    }
    changedFilter.label = v;
    changedFilter.key = newKey;
    changedFilter.value = {};

    setState((oldValues) => ({
      ...oldValues,
      filters: newFilters
    }));
  };

  const handleFilterValueChange = (
    value: string | any[],
    filterKey: string,
    filterId: string
  ) => {
    let filterValue = value;

    if (filterKey === "users" && Array.isArray(value)) {
      filterValue = value.map((user) => {
        if (user?.value) {
          return user.value;
        }
        return user;
      });
    }

    const newFilters = state.filters.slice();
    const changedFilter: any = newFilters.find(
      (filter) => filter.id === filterId
    );
    changedFilter.value[filterKey] = filterValue;

    setState((oldValues) => ({
      ...oldValues,
      filters: newFilters
    }));
  };

  const handleApplyFilter = useCallback(() => {
    const filterHashToApply = state.filters
      .map((filter) => Object.entries(filter.value))
      .flat()
      .reduce((acc: ObjectHash, [filterKey, filterValue]: [string, any]) => {
        if (Object.hasOwnProperty.call(acc, filterKey)) {
          acc[filterKey].push(filterValue);
          acc[filterKey] = acc[filterKey].flat(); // flatterning for users filter array value
        } else {
          Object.assign(acc, {
            [filterKey]: [filterValue].flat() // flatterning for users filter array value
          });
        }
        return acc;
      }, {});

    const hasFromDate = Boolean(filterHashToApply["from"]);
    const hasToDate = Boolean(filterHashToApply["to"]);

    if (hasFromDate || hasToDate) {
      let fromTimestamp = 0,
        toTimestamp = 9999999999999; // add bookends for epoch date

      if (hasFromDate) {
        fromTimestamp = filterHashToApply["from"][0];
      }

      if (hasToDate) {
        toTimestamp = filterHashToApply["to"][0];
      }

      // passing invalid values here causes an infinite render loop for some reason, so double-check
      // that the values kinda look like timestamps
      if (Number.isInteger(fromTimestamp) && Number.isInteger(toTimestamp)) {
        filterHashToApply.dates = [[fromTimestamp, toTimestamp]];
      }
    }

    props.onApplyFilter(filterHashToApply);
  }, [props, state.filters]);

  const handleClickDeleteFilter = (id: string) => {
    let newFilters = state.filters.slice();
    newFilters = newFilters.filter((filter) => filter.id !== id);

    if (newFilters.length === 0) {
      newFilters.push({
        id: getFilterId(),
        label: "",
        key: "",
        value: {}
      });
    }

    setState((oldValues) => ({
      ...oldValues,
      filters: newFilters
    }));
  };

  useEffect(() => {
    handleApplyFilter();
  }, [handleApplyFilter, state]);

  return (
    <div className={"filter-menu"}>
      <Tooltip text={"Filter"}>
        <div onClick={handleClick}>
          <Button
            color="dark-gray"
            icon="filter"
            isBordered={state.filters.some(
              (f) => Object.keys(f.value).length > 0
            )}
            isRounded={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
      <Popper
        id={`filter-popper-${props.tagId}`}
        className={popperClass}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="filter-popper__content">
            <h1>Filter</h1>
            <form>
              {state.filters.map((filter) => (
                <div
                  key={`filter-row-${filter.id}`}
                  className="filter-popper__input-flex"
                >
                  <FormControl>
                    <AutocompleteInput
                      defaultValue={filter.label ?? ""}
                      onChange={(value: string) =>
                        handleFilterTypeChange(value, filter.id)
                      }
                      options={Object.keys(filtersHash).map((key) => ({
                        label: key,
                        value: key
                      }))}
                      placeholder="Select Filter"
                    />
                  </FormControl>
                  {filter.label === "" && (
                    <div className="filter-popper__input--disabled" />
                  )}
                  {filter.label !== "" &&
                    getValueInputComponent(
                      filter,
                      props.allTrips || props.data, // use all trips to generate filter options
                      handleFilterValueChange
                    )}
                  <Tooltip text={"Delete Filter"}>
                    <RemoveButton
                      key={`delete-filter-${filter.id}`}
                      onClick={() => {
                        handleClickDeleteFilter(filter.id);
                      }}
                      size="small"
                    />
                  </Tooltip>
                </div>
              ))}
            </form>
            <div className={"filter-popper__buttons"}>
              <Button
                color="product-blue"
                icon="plus-circle"
                isRippleDisabled={true}
                isTransparent={true}
                label="Add Filter"
                onClick={handleClickAddFilter}
                size="medium"
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
