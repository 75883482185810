import { inject } from "inversify";
import BaseService from "./base.service";
import {
  LocalStorageService,
  ILocalStorageService
} from "./local-storage.service";
import { ObjectHash } from "../utils/helpers";

export class AuthPair {
  token: string;
  expires: number;

  constructor(data: ObjectHash) {
    this.token = data.token;
    this.expires = data.expires;
  }

  isValid(): boolean {
    if (!this.token) {
      return false;
    }

    return !this.isExpired();
  }

  isExpired(): boolean {
    return new Date().getTime() >= this.expires;
  }
}

export interface IAuthStorageService {
  get(): AuthPair;

  set(pair: AuthPair): void;

  clear(): void;
}

/*
 * This could have very simply gone in the AuthService class, but the ApiService class
 * also needs to pull these credentials, and the AuthService class depends on the ApiService class,
 * which was causing a cyclical dependency.
 */
export class AuthStorageService extends BaseService
  implements IAuthStorageService {
  @inject(LocalStorageService)
  private localStorageService!: ILocalStorageService;

  get(): AuthPair {
    const expires: number = Number(
      this.localStorageService.get("auth_expires")
    );
    let token: any = this.localStorageService.get("auth_token");

    if (!token) {
      token = "";
    }

    return new AuthPair({ token, expires });
  }

  set(pair: AuthPair): void {
    this.localStorageService.set(
      "auth_expires",
      String(new Date(pair.expires).getTime())
    );
    this.localStorageService.set("auth_token", pair.token);
  }

  clear(): void {
    this.localStorageService.set("auth_expires", String(new Date().getTime()));
    this.localStorageService.remove("auth_token");
  }
}
