import React, { ReactNode } from "react";
import {
  getDisplayDate,
  isTagModel,
  isUserModel,
  LoadState
} from "../../../utils/helpers";
import SkeletonLoader from "../../skeleton-loader";
import ReadOnlySkeleton from "./ReadOnlySkeleton";

import TagModel from "../../../models/tag.model";
import TagChip from "../../tag-chip";
import TagGroupModel from "../../../models/tag-group.model";
import TravelerChip from "../../traveler-chip";
import { TemplateInput } from "../../../services/workspace.service";
import useApp from "../../../hooks/use-app.hook";
import UserModel from "../../../models/user.model";

import "./read-only.scss";

export type ReadOnlyValue = string | string[] | TagModel[] | UserModel[];

export interface Props {
  loadState?: LoadState;
  onClick?: CallableFunction;
  templateInput: TemplateInput;
  value: ReadOnlyValue;
}

const getTagChips = (
  tagGroup: TagGroupModel,
  tags: TagModel[] | string[]
): ReactNode[] => {
  const validTagIds = tagGroup.tags.map((tag: TagModel) => tag.id);

  const tagChipElements: ReactNode[] = [];
  tags.forEach((userTag: string | TagModel) => {
    let displayTag: TagModel = isTagModel(userTag)
      ? new TagModel({ ...userTag })
      : new TagModel({ id: userTag });
    if (!validTagIds.includes(displayTag.id)) {
      return;
    }

    if (!isTagModel(userTag)) {
      displayTag =
        tagGroup.tags.find((tag: TagModel) => tag.id === userTag) ||
        new TagModel();
    }

    tagChipElements.push(
      <TagChip tag={displayTag} key={`tag-chip-${displayTag.id}`} />
    );
  });

  return tagChipElements;
};

export default function ReadOnly(props: Props) {
  const { settings } = useApp();
  const { loadState = "loaded", onClick, templateInput, value } = props;
  const { inputType, label, withTime } = templateInput;
  let displayValue: string | ReactNode[] = value;

  // special handling/output for known tg inputs
  if (Array.isArray(value)) {
    if (!value.length) {
      displayValue = "";
    } else {
      if (inputType === "tag") {
        const { tagGroup = new TagGroupModel() } = templateInput;
        displayValue = getTagChips(tagGroup, value as TagModel[] | string[]);
      }

      if (isUserModel(value[0])) {
        displayValue = (value as UserModel[]).map((user: UserModel) => (
          <TravelerChip traveler={user} key={user.id} />
        ));
      }
    }
  }

  if (inputType === "date") {
    const format = withTime ? settings.dateTimeFormat : settings.dateFormat;
    displayValue = getDisplayDate(value as string, format);
  }

  return (
    <SkeletonLoader
      loading={loadState !== "loaded"}
      skeletonComponent={<ReadOnlySkeleton />}
    >
      <div className="read-only" onClick={() => onClick && onClick()}>
        <div className="read-only-label">{label}</div>
        <div className="read-only-value">{displayValue}</div>
      </div>
    </SkeletonLoader>
  );
}
