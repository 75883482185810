import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import MUIDataTable from "mui-datatables";

import EmptyTable from "./empty-table";
import Loader from "../loader";

import CompanyModel from "../../models/company.model";
import GridModel from "../../models/grid.model";
import TemplateModel from "../../models/template.model";

import { getIconComponent } from "../app-icon";
import { StaticDocumentTableWrapper } from "./static-document-table";
import { StaticResultsTableWrapper } from "./static-results-table";
import { ServerSideTableWrapper } from "./server-side-table";
import { withSnackbarContext } from "../../context/snackbar";
import { withModalContext } from "../../context/modal";
import getInitialState from "./table-funcs/get-initial-state";

import { getTableClasses } from "./table-funcs/render-funcs";
import "./object-table.scss";

interface RouterProps {
  id: string; // for react router match.params.id
}

interface Props extends RouteComponentProps<RouterProps> {
  columns?: any[];
  company: CompanyModel | null;
  grid?: GridModel;
  locationsData?: any[]; // for static tables, gives preset location data to speed up manual entry
  onQuickAddTravel?: CallableFunction;
  openModal: CallableFunction;
  printMode?: boolean;
  renderOpts?: { [index: string]: any };
  searchOpts?: { [index: string]: any };
  setRowsPerPage?: CallableFunction; // persists rows per page on non-static tables
  settings: any;
  setSnackbar: CallableFunction;
  staticResults?: any[];
  templateName?: string;
  templates: TemplateModel[];
  title?: string;

  // currently only triggered when a new document is created from the custom toolbar for documents list table
  onCreate?: CallableFunction;

  // for the document builder
  blockState?: any;
  columnsDefault?: any[];
  viewMode?: boolean;
}

interface State {
  [index: string]: any;
}

class ObjectTable extends React.Component<Props, State> {
  _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = getInitialState.bind(this)(
      props,
      this.setState.bind(this),
      this
    );
  }

  async componentDidMount() {
    this._isMounted = true;

    if (this.props.renderOpts && this.props.renderOpts.isDocument) {
      // Sets title input width based on the input value
      const titleInputEl = document.getElementById(
        `input-expander-${this.state.id}`
      );
      if (titleInputEl) {
        const newTitleInputWidth = titleInputEl.offsetWidth;
        if (
          this._isMounted &&
          this.state.titleInputWidth !== newTitleInputWidth
        ) {
          this.setState({ titleInputWidth: newTitleInputWidth });
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.props.renderOpts && this.props.renderOpts.isDocument) {
      // Sets title input width based on the input value
      const titleInputEl = document.getElementById(
        `input-expander-${this.state.id}`
      );
      if (titleInputEl) {
        let newTitleInputWidth = titleInputEl.offsetWidth;
        if (document.querySelector(".document-builder__sharelink")) {
          newTitleInputWidth += 40; // prevent clipping bug of document table titles in share links
        }
        if (
          this._isMounted &&
          this.state.titleInputWidth !== newTitleInputWidth
        ) {
          this.setState({ titleInputWidth: newTitleInputWidth });
        }
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.searchSub) {
      this.state.searchSub.remove();
    }
  }

  render() {
    const { grid, locationsData, onCreate, printMode } = this.props;

    let TableWrapper = ServerSideTableWrapper;
    let wrapperClass = "sourced";

    if (this.props.renderOpts && this.props.renderOpts.isDocument) {
      TableWrapper = StaticDocumentTableWrapper;
      wrapperClass = "static-doc";
    } else if (this.props.staticResults) {
      TableWrapper = StaticResultsTableWrapper;
      wrapperClass = "static-results";
    }

    const params: { [index: string]: any } = TableWrapper(
      this.state,
      this.setState.bind(this),
      this.props
    );
    const { blockState, muiDataTableProps, renderOpts, title } = params;
    const { isLoading, searchOpts } = this.state;

    const tableClasses = getTableClasses(renderOpts, searchOpts, blockState);

    const TableComponent =
      muiDataTableProps.data.length === 0 &&
      !(searchOpts.filters || []).length &&
      renderOpts.showEmptyTable ? (
        <EmptyTable
          locationsData={locationsData}
          onCreate={onCreate}
          gridId={grid?.id}
          tableTitle={title || ""}
        />
      ) : (
        <MUIDataTable
          key={`${muiDataTableProps.title}-${muiDataTableProps.options.rowsPerPage}`}
          components={{
            icons: {
              DownloadIcon: getIconComponent("download"),
              ViewColumnIcon: getIconComponent("view-column")
            }
          }}
          {...muiDataTableProps}
        />
      );

    return (
      <div
        className={[
          ...tableClasses,
          wrapperClass,
          isLoading && "object-table-loading",
          printMode &&
            Array.isArray(muiDataTableProps.data) &&
            muiDataTableProps.data.length > 20 &&
            "enable-table-break"
        ]
          .filter((v) => v)
          .join(" ")}
      >
        <div className="mui-data-table-wrapper">
          {isLoading ? (
            <div className="object-table--loading-container">
              <div>
                <Loader type="dots" />
                <h5
                  style={{
                    textAlign: "center",
                    fontWeight: "normal",
                    fontSize: "12px"
                  }}
                >
                  Loading {searchOpts.resource}...
                </h5>
              </div>
            </div>
          ) : (
            TableComponent
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(withSnackbarContext(withModalContext(ObjectTable)));
