import React from "react";

import {
  airportToIata,
  getDisplayDate,
  getFlightNumber,
  ObjectHash
} from "../../../../../utils/helpers";
import { TmlEntryModel, TmlEntrySegmentId } from "../../lib/models";
import useApp from "../../../../../hooks/use-app.hook";

interface Props {
  entry: TmlEntryModel;
  segmentId: TmlEntrySegmentId;
}

export default function Label(props: Props) {
  const { entry, segmentId } = props;
  const { settings } = useApp();
  const { dateTimeFormat } = settings;

  const { type } = entry;
  const isInbound = type === "inbound";

  const { date, tripType, value } = entry[segmentId] || {
    date: 0,
    tripType: "",
    value: ""
  };

  const { confirmation, flightNum, name, toLocation, trainNum } =
    (value as ObjectHash) || {};

  let title = "--",
    subtitle = "";
  const displayDate = date ? getDisplayDate(Number(date), dateTimeFormat) : "";

  switch (segmentId) {
    case "travelSegment":
      subtitle = displayDate;
      break;
    case "transportSegment":
      subtitle = isInbound
        ? "Arrival transportation"
        : "Departure transportation";
      break;
    case "locationSegment":
      subtitle = isInbound ? "Drop off location" : "Pick up location";
      break;
  }

  const tripLocation = toLocation; //isInbound ? toLocation : fromLocation;

  switch (tripType) {
    case "user":
      if (value) {
        title = value as string;
      }
      break;
    case "air":
      title = [name, getFlightNumber(flightNum)].filter((v) => v).join(" ");
      const location = tripLocation ? airportToIata(tripLocation) : "";
      title = [title, location].filter((v) => v).join(" - ");
      break;
    case "car":
      title = [name, tripLocation].filter((v) => v).join(", ");
      break;
    case "hotel":
      title = [name, confirmation].filter((v) => v).join(" - ");
      break;
    case "rail":
      title = [name, trainNum].filter((v) => v).join(" ");
      title = [title, tripLocation].filter((v) => v).join(" - ");
      break;
  }

  return (
    <div className="label">
      <div className="title truncate">{title}</div>
      <div className="subtitle truncate">{subtitle}</div>
    </div>
  );
}
