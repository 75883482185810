import React from "react";

import Button from "../../components/button";
import Loader from "../../components/loader";
import ObjectTable from "../../components/object-table";

import { AppContext } from "../../context";
import useModal from "../../hooks/use-modal.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";

import AppContainer from "../../container";
import { GridService, IGridService } from "../../services/grid.service";
import GridModel from "../../models/grid.model";
import TravelerModel from "../../models/traveler.model";

import TravelersImage from "../../images/empty-table-travelers.png";

interface Props {
  grid: GridModel;
  onGridChange: CallableFunction;
}

export default function GridTravelers(props: Props) {
  const { grid } = props;

  const { openModal } = useModal();
  const { setSnackbar } = useSnackbar();

  const [travelers, setTravelers] = React.useState<TravelerModel[]>([]);
  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  const gridService: IGridService = AppContainer.get(GridService);

  const addGridTraveler = () => {
    openModal("add-profiles", {
      onChange: async (profileIdsToAdd: string[]) => {
        const newTravelerIds = [
          ...new Set([...grid.travelers, ...profileIdsToAdd])
        ];

        grid.travelers = newTravelerIds;

        const response: GridModel | null = await gridService.update(grid);
        if (response) {
          setSnackbar({
            message: "Travelers added!",
            variant: "success"
          });

          loadTravelers();
        } else {
          setSnackbar({
            message: "There was a problem and the travelers were not added",
            variant: "error"
          });
        }
      }
    });
  };

  const editTraveler = async (traveler: TravelerModel) => {
    openModal("user", {
      userId: traveler.id,
      onChange: () => {
        loadTravelers();
      }
    });
  };

  const loadTravelers = React.useCallback(async () => {
    const responses = await gridService.getTravelers(grid.id);
    // filter out isGhost users, this is set to true for aliases that are merged to other profiles
    setTravelers(responses.filter((traveler) => !traveler.isGhost) ?? []);
    setIsMounted(true);
  }, [setTravelers, gridService, grid, setIsMounted]);

  React.useEffect(() => {
    loadTravelers();
  }, [loadTravelers]);

  if (isMounted && !travelers.length) {
    return (
      <div className="travelers-empty-list-view">
        <img src={TravelersImage} alt="traveler" />
        <div className="subtitle">
          <span>This grid has no travelers.</span>
          <span className="subtitle-helper-text">
            Adding travel to your grid will automatically display associated
            travelers.
          </span>
          <Button
            color="product-blue"
            label="Add Traveler"
            onClick={() => addGridTraveler()}
            size="medium"
          />
        </div>
      </div>
    );
  }

  if (!isMounted) {
    return (
      <React.Fragment>
        <Loader type="dots" />
        <h5
          style={{
            textAlign: "center",
            fontWeight: "normal",
            fontSize: "12px"
          }}
        >
          Loading travelers...
        </h5>
      </React.Fragment>
    );
  }

  return (
    <AppContext.Consumer>
      {(appState) => (
        <React.Fragment>
          <ObjectTable
            columns={[
              ["name", "Name"],
              ["hasInbound", "Arrival"],
              ["hasOutbound", "Departure"],
              ["hasAccommodation", "Hotel"],
              ["hasTransportation", "Transportation"],
              ["id", "ID"],
              ["userId", "User ID"]
            ]}
            renderOpts={{
              disableDownload: true,
              showEmptyTable: true,
              showQuickAdd: true,
              useCardStyle: true,
              updateStaticResults: loadTravelers,
              customRowClick: (traveler: TravelerModel) => {
                editTraveler(traveler);
              }
            }}
            searchOpts={{
              order: "name",
              resource: "travelers",
              viewStateKey: "users-traveler"
            }}
            grid={grid}
            staticResults={travelers}
            title="Traveler"
            company={appState.company}
            templates={appState.templates}
            settings={appState.settings}
            user={appState.user}
          />
        </React.Fragment>
      )}
    </AppContext.Consumer>
  );
}
