import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../../button";
import useModal from "../../../hooks/use-modal.hook";
import "./confirmation-warning-dialog.scss";

interface Props {
  buttonText: string;
  dialogBody: string;
  dialogTitle: string;
  onConfirm: CallableFunction;
}

export default function ConfirmationWarningDialog(props: Props) {
  const { closeModal } = useModal();
  const { onConfirm } = props;

  return (
    <Dialog
      classes={{ root: "confirmation-warning-dialog", paper: "paper" }}
      disableBackdropClick={true}
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <div className="confirmation-warning-dialog__title">
          <h1>{`${
            props.dialogTitle ? props.dialogTitle : "Confirm Delete"
          }`}</h1>
        </div>
      </DialogTitle>
      <DialogContent>
        <p className="confirmation-warning-dialog__body">
          {`${
            props.dialogBody
              ? props.dialogBody
              : "Are you sure you want to delete? This cannot be undone."
          }`}
        </p>
      </DialogContent>
      <DialogActions className="confirmation-warning-dialog__actions">
        <Button
          color="gray"
          isTransparent={true}
          label="Cancel"
          onClick={() => closeModal(true)}
          size="medium"
        />
        <Button
          color="red"
          label={props.buttonText ? `${props.buttonText}` : "Delete It"}
          onClick={() => {
            closeModal();
            onConfirm();
          }}
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
