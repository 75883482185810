import React from "react";
import Button from "../button";
import ColorMenu from "../object-table/table-actions/color-menu";
import Tooltip from "../tooltip";
import useModal from "../../hooks/use-modal.hook";

interface Props {
  onChangeTableBlockColor: CallableFunction;
  onImportCsvData: CallableFunction;
  tagId: string;
}

const CustomToolbar = (props: Props) => {
  const { openModal } = useModal();

  const handlePaste = () => {
    openModal("convert-csv", {
      onImport: props.onImportCsvData
    });
  };

  return (
    <React.Fragment>
      <div className={"excel-paste-button"} key={"excel-paste-button"}>
        <Tooltip text="Paste Values">
          <Button
            color="dark-gray"
            icon="file-copy"
            isRippleDisabled={true}
            isTransparent={true}
            onClick={handlePaste}
            size="medium"
          />
        </Tooltip>
      </div>
      <ColorMenu
        tagId={props.tagId}
        onChangeTableBlockColor={props.onChangeTableBlockColor}
      />
    </React.Fragment>
  );
};

export default CustomToolbar;
