import React from "react";
import TableCell from "@material-ui/core/TableCell";

import Button from "../../button";
import CellInput from "../cell-input";
import Tooltip from "../../tooltip";

export const getCustomTableRowMenu = (
  data: any[],
  tableMeta: any,
  onChangeCustomTableBlockRows: any,
  openModal: CallableFunction
) => (
  <div className="object-table__document-row-menu">
    <Tooltip text="Add row below">
      <Button
        color="product-blue"
        icon="plus"
        isRippleDisabled={true}
        isTransparent={true}
        onClick={() => {
          onChangeCustomTableBlockRows(tableMeta.rowIndex, "add below");
        }}
        size="medium"
      />
    </Tooltip>
    <Tooltip text="Move up">
      <div className="object-table__document-row-menu-button--move-up">
        <Button
          color="product-blue"
          icon="arrow-up"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => {
            onChangeCustomTableBlockRows(tableMeta.rowIndex, "move up");
          }}
          size="medium"
        />
      </div>
    </Tooltip>
    <Tooltip text="Move down">
      <div className="object-table__document-row-menu-button--move-down">
        <Button
          color="product-blue"
          icon="arrow-down"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => {
            onChangeCustomTableBlockRows(tableMeta.rowIndex, "move down");
          }}
          size="medium"
        />
      </div>
    </Tooltip>
    {data.length > 1 && (
      <Tooltip text="Delete row">
        <Button
          color="light-red"
          icon="trash"
          isRippleDisabled={true}
          isTransparent={true}
          onClick={() => {
            openModal("confirm", {
              dialogTitle: "Delete Row",
              dialogBody:
                "Are you sure you want to delete this row? This cannot be undone.",
              onConfirm: () => {
                onChangeCustomTableBlockRows(tableMeta.rowIndex, "delete");
              }
            });
          }}
          size="medium"
        />
      </Tooltip>
    )}
  </div>
);

export const getCustomTableHeader = (
  columns: any[],
  columnMeta: any,
  onChangeCustomTableBlockColName: any,
  onChangeTableBlockCols: any,
  blockId: string,
  viewMode: boolean,
  openModal: CallableFunction
) => {
  if (columnMeta.name === "documentRowMenu") {
    return (
      <td
        key={`document-header-cell-${columnMeta.index}`}
        style={{
          height: "48px",
          borderBottom: "1px solid rgba(224, 224, 224, 1)"
        }}
      />
    );
  }
  const handleChangeColumnName = (colIndex: number, v: string) => {
    onChangeCustomTableBlockColName(colIndex, v);
  };
  return (
    <TableCell
      className="document-header-cell"
      key={`custom-table-header-${columnMeta.index}`}
      variant="head"
    >
      <div className="object-table__document-column-menu">
        <Tooltip text="Add column right">
          <Button
            color="product-blue"
            icon="plus"
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => {
              onChangeTableBlockCols(columnMeta.index, "add right");
            }}
            size="medium"
          />
        </Tooltip>
        <div className="object-table__column-move-button object-table__column-move-button--left">
          <Tooltip
            text="Move left"
            key={`move-left-button-${columnMeta.index}`}
          >
            <Button
              color="product-blue"
              icon="nav-arrow-left"
              isRippleDisabled={true}
              isTransparent={true}
              onClick={() => {
                onChangeTableBlockCols(columnMeta.index, "move left");
              }}
              size="medium"
            />
          </Tooltip>
        </div>
        <div className="object-table__column-move-button object-table__column-move-button--right">
          <Tooltip
            text="Move right"
            key={`move-right-button-${columnMeta.index}`}
          >
            <Button
              color="product-blue"
              icon="nav-arrow-right"
              isRippleDisabled={true}
              isTransparent={true}
              onClick={() => {
                onChangeTableBlockCols(columnMeta.index, "move right");
              }}
              size="medium"
            />
          </Tooltip>
        </div>
        {columns.length > 2 && (
          <div className="object-table__document-column-menu-button--warning">
            <Tooltip text="Delete column">
              <Button
                color="light-red"
                icon="trash"
                isRippleDisabled={true}
                isTransparent={true}
                onClick={() => {
                  openModal("confirm", {
                    dialogTitle: "Delete Column",
                    dialogBody:
                      "Are you sure you want to delete this column? This cannot be undone.",
                    onConfirm: () => {
                      onChangeTableBlockCols(columnMeta.index, "delete");
                    }
                  });
                }}
                size="medium"
              />
            </Tooltip>
          </div>
        )}
      </div>
      <CellInput
        key={`custom-table-header-${columnMeta.index}`}
        defaultValue={columnMeta.name}
        disabled={viewMode}
        inputWidthText={columnMeta.name}
        isMultiline={false}
        onChange={(v: string) => handleChangeColumnName(columnMeta.index, v)}
        tagId={`custom-table-header-${blockId}-${columnMeta.index}`}
      />
    </TableCell>
  );
};

export const getCustomTableBody = (
  value: string,
  tableMeta: any,
  onChangeCustomTableBlockBodyCell: any,
  blockId: string,
  viewMode: boolean
) => {
  const handleChange = (colIndex: number, rowIndex: number, v: string) => {
    onChangeCustomTableBlockBodyCell(colIndex, rowIndex, v);
  };
  return (
    <CellInput
      key={`custom-table-body-${tableMeta.columnIndex}-${tableMeta.rowIndex}`}
      defaultValue={value}
      disabled={viewMode}
      inputWidthText={String(value)
        .split("\n")
        .reduce((a, b) => {
          if (b.length > a.length) {
            return b;
          }
          return a;
        })}
      isMultiline={true}
      onChange={(v: string) =>
        handleChange(tableMeta.columnIndex, tableMeta.rowIndex, v)
      }
      tagId={`custom-table-body-${blockId}-${tableMeta.columnIndex}-${tableMeta.rowIndex}`}
    />
  );
};
