import React, { useState } from "react";
import { DateTime } from "luxon";
import Draggable from "react-draggable";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import { ObjectHash } from "../../../utils/helpers";
import useModal from "../../../hooks/use-modal.hook";

import Button from "../../button";
import CloseButton from "../../button/close";
import DateInput from "../../form/inputs/DateInput";
import TextInput from "../../form/inputs/TextInput";
import Tooltip from "../../tooltip";

import "./schedule-event-dialog.scss";

const PaperComponent = (props: object) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

interface Props {
  data: ObjectHash;
  onAddScheduleEvent: any;
  onChangeScheduleEvent: any;
  onDeleteScheduleEvent: any;
}

export default function ScheduleEventDialog(props: Props) {
  const { closeModal, openModal } = useModal();

  const {
    data,
    onAddScheduleEvent,
    onChangeScheduleEvent,
    onDeleteScheduleEvent
  } = props;

  const [formChanged, setFormChanged] = useState(false);

  let defaultDate = DateTime.local()
    .toISO()
    .split("T")[0]
    .concat("T08:00:00.000Z");

  let zeroState: ObjectHash = {
    description: "",
    from: defaultDate,
    name: "",
    type: "EVENT"
  };

  if (data) {
    zeroState = data;
    defaultDate = zeroState.from;
  }

  const [state, setState] = useState({
    ...zeroState
  });

  const resetState = () => {
    setState(() => ({
      ...zeroState
    }));
  };

  const handleClose = (skipConfirm: boolean) => {
    const resetAndCloseModal = () => {
      closeModal();
      resetState();
    };

    if (formChanged && skipConfirm !== true) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "You have unsaved changes. Are you sure you want to close?",
        onConfirm: resetAndCloseModal
      });
    } else {
      resetAndCloseModal();
    }
  };

  const handleChange = (key: string, v: string) => {
    setState((oldValues) => ({ ...oldValues, [key]: v }));
    setFormChanged(true);
  };

  const variantType: "filled" = "filled"; // Workaround to prevent TS error

  const textFieldProps = {
    className: "input--text",
    fullWidth: true,
    InputProps: {
      disableUnderline: true
    },
    InputLabelProps: {
      shrink: true
    },
    variant: variantType
  };

  const handleAddScheduleEventClick = () => {
    onAddScheduleEvent(state);
    handleClose(true);
  };

  const handleChangeScheduleEventClick = () => {
    onChangeScheduleEvent(state);
    handleClose(true);
  };

  const handleDeleteScheduleEventClick = () => {
    openModal("confirm", {
      dialogTitle: "Delete Event",
      dialogBody:
        "Are you sure you want to delete this event? This cannot be undone.",
      onConfirm: () => {
        onDeleteScheduleEvent(state);
        handleClose(true);
      }
    });
  };

  return (
    <Dialog
      classes={{ paper: "paper" }}
      className="schedule-event-dialog__overlay"
      open={true}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }}>
        <div className="schedule-event-dialog__title">
          <h1>{!data ? "New Event" : "Edit Event"}</h1>
          <div className="schedule-event-dialog-button-group">
            {data && (
              <Tooltip text="Delete Event">
                <Button
                  color="gray"
                  icon="trash"
                  isRippleDisabled={true}
                  isTransparent={true}
                  onClick={() => handleDeleteScheduleEventClick()}
                  size="medium"
                />
              </Tooltip>
            )}
            <Tooltip text="Close">
              <CloseButton onClick={() => handleClose(false)} size="medium" />
            </Tooltip>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <DateInput
          defaultValue={defaultDate}
          label="Date"
          isRequired={true}
          onChange={(value: string) => handleChange("from", value)}
          variant="filled"
          withTime={true}
        />
        <TextInput
          defaultValue={zeroState.name}
          isRequired={true}
          label="Event Name"
          onChange={(value: string) => handleChange("name", value)}
          {...textFieldProps}
        />
        <TextInput
          defaultValue={zeroState.description}
          isMultiline={true}
          label="Description"
          onChange={(value: string) => handleChange("description", value)}
          {...textFieldProps}
        />
      </DialogContent>
      <DialogActions classes={{ root: "dialog-actions" }}>
        <Button
          color="product-blue"
          isDisabled={!(state.name.length > 0 && formChanged)}
          isFullWidth={true}
          label={!data ? "Create Event" : "Save Changes"}
          onClick={() =>
            !data
              ? handleAddScheduleEventClick()
              : handleChangeScheduleEventClick()
          }
          size="medium"
        />
      </DialogActions>
    </Dialog>
  );
}
