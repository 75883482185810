import React, { useCallback, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import { uniqueId } from "lodash";

import CompanySettingsTab from "./tabs/company-settings-tab";
import ProfileAddTab from "./tabs/profile-add-tab";
import ProfileDocumentTab from "./tabs/profile-document-tab";
import ProfileEditTab from "./tabs/profile-edit-tab";
import ProfileProgramTab from "./tabs/profile-program-tab";
import ProfileViewTab from "./tabs/profile-view-tab";

import AppContainer from "../../../container";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import TagModel from "../../../models/tag.model";
import { UserService, IUserService } from "../../../services/user.service";
import UserModel from "../../../models/user.model";

import { LoadState, ObjectHash } from "../../../utils/helpers";

import "./user-dialog.scss";

const PaperComponent = (props: ObjectHash) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

interface Props {
  userId?: string;
  onChange?: CallableFunction; // callback to update other components when changes are committed inside modal
  tagPreset?: TagModel[];
}

type UserDialogTab = {
  key: string;
  props?: ObjectHash;
};

export interface UserDialogTabProps {
  key: string;
  menuPortalTarget: any;
  onChange?: CallableFunction;
  onClose: CallableFunction;
  onFormChange: CallableFunction;
  onViewChange: CallableFunction;
  onSave: CallableFunction;
  user: UserModel;
}

export default function UserDialog(props: Props) {
  const userService: IUserService = AppContainer.get(UserService);

  const { setSnackbar } = useSnackbar();
  const { closeModal, linkModal, openModal } = useModal();

  const { onChange, tagPreset, userId } = props;
  const isNew = !Boolean(userId);
  const domRef = useRef();

  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [menuPortalTarget, setMenuPortalTarget] = useState<any>(null);
  const [user, setUser] = useState<UserModel>(new UserModel());
  const [viewKey, setViewKey] = useState<string>("user-dialog-view");
  const [loadState, setLoadState] = useState<LoadState>(
    isNew ? "loaded" : "unloaded"
  );
  const [tab, setTab] = useState<UserDialogTab>(
    isNew ? { key: "profile-add" } : { key: "profile-view" }
  );

  const loadUser = useCallback(async () => {
    if (!userId || loadState !== "unloaded") {
      return;
    }

    setLoadState("loading");

    const formUser = await userService.getById(userId, true, true);
    if (!formUser) {
      return;
    }

    setUser(formUser);
    setLoadState("loaded");
  }, [loadState, setLoadState, setUser, userId, userService]);

  useEffect(() => {
    if (userId) {
      linkModal("user", { userId });
    }
  }, [linkModal, userId]);

  useEffect(() => {
    if (loadState === "unloaded") {
      loadUser();
    }
  }, [loadUser, loadState]);

  const handleClose = (skipConfirm: boolean) => {
    if (formChanged && skipConfirm !== true) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "You have unsaved changes. Are you sure you want to close?",
        onConfirm: closeModal
      });
    } else {
      closeModal();
    }
  };

  const handleSave = (user: UserModel) => {
    if (user) {
      onChange && onChange();
      setUser(user);
      const message = isNew ? "Profile created!" : "Profile updated!";
      setSnackbar({
        message,
        variant: "success"
      });
      handleViewChange("profile-view");
    } else {
      setSnackbar({
        message: "There was an error and your changes were not saved",
        variant: "error"
      });
    }
  };

  const handleViewChange = (key: string, props?: ObjectHash) => {
    setTab({ key, props });
    setFormChanged(false);
    setViewKey(uniqueId("user-dialog-view-"));
  };

  const activeTabProps: UserDialogTabProps = {
    key: viewKey,
    menuPortalTarget,
    onChange: onChange,
    onClose: handleClose,
    onFormChange: setFormChanged,
    onSave: handleSave,
    onViewChange: handleViewChange,
    user,
    ...(tab.props || {})
  };

  let activeTab: JSX.Element;

  switch (tab.key) {
    default:
    case "profile-add":
      activeTab = <ProfileAddTab {...activeTabProps} />;
      break;
    case "profile-edit":
      activeTab = <ProfileEditTab {...activeTabProps} />;
      break;
    case "profile-view":
      activeTab = <ProfileViewTab {...activeTabProps} />;
      break;
    case "company-settings-edit":
      activeTab = (
        <CompanySettingsTab {...activeTabProps} tagPreset={tagPreset} />
      );
      break;
    case "profile-document":
      activeTab = <ProfileDocumentTab {...activeTabProps} />;
      break;
    case "profile-program":
      activeTab = <ProfileProgramTab {...activeTabProps} />;
      break;
  }

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      classes={{
        root: "user-dialog user-dialog-standard",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper",
        paperWidthSm: "paper-width-sm"
      }}
      onClose={handleClose}
      onEntered={() => setMenuPortalTarget(domRef.current)}
      open={true}
      PaperComponent={PaperComponent}
      ref={domRef}
    >
      {activeTab}
    </Dialog>
  );
}
