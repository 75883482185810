import { DateTime } from "luxon";
import BaseModel from "./base.model";
import BookingSegmentModel from "./booking-segment.model";
import { ObjectHash } from "../utils/helpers";
import UserModel from "./user.model";

export type BookingType = "one-way" | "round-trip" | "multi-city";
export type BookingStatus = "initiated" | "ready" | "requested" | "booked";

export default class BookingRequestModel extends BaseModel {
  id: string;
  bookingCollection: string;
  bookingSegments: BookingSegmentModel[];
  bookingType: BookingType;
  notes: string;
  status: BookingStatus;
  users: UserModel[];

  deleted: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.bookingCollection = data.bookingCollection;
    this.bookingSegments = data.bookingSegments
      ? data.bookingSegments.map(
          (segmentData: ObjectHash) => new BookingSegmentModel(segmentData)
        )
      : [];
    this.bookingType = data.bookingType;
    this.notes = data.notes;
    this.status = data.status;

    this.users = data.users
      ? data.users.map((user: ObjectHash) => new UserModel(user))
      : [];

    this.deleted = Boolean(data.deleted);
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  public getSegments() {
    const segments = [...this.bookingSegments];
    if (this.bookingType === "one-way") {
      return segments;
    }

    /*
     * Sort by date only works if every segment has a start date, otherwise
     * we must (1) assume bookingType === "round-trip" and (2) match segments by
     * start and end location
     */
    const canSortByDate = segments.every(
      (segment: BookingSegmentModel) => segment.startDate
    );
    const canSortByLocation = segments.every(
      (segment: BookingSegmentModel) =>
        segment.startLocation && segment.endLocation
    );

    if (canSortByDate) {
      return segments.sort((a: BookingSegmentModel, b: BookingSegmentModel) => {
        const aDate = DateTime.fromISO(a.startDate);
        const bDate = DateTime.fromISO(b.startDate);

        const aTime = aDate.isValid ? aDate.valueOf() : 99999999999999;
        const bTime = bDate.isValid ? bDate.valueOf() : 99999999999999;

        return aTime < bTime ? -1 : 1;
      });
    }

    if (canSortByLocation && segments.length === 2) {
      if (segments[0].endLocation !== segments[1].startLocation) {
        segments.reverse();
      }
    }

    return segments;
  }

  // one-way === 1 segment, round-trip === 2, multi-city === N > 2
  public getDates(): string[] {
    const validSegments: BookingSegmentModel[] = this.getSegments().filter(
      (s) => s.startDate && s.endDate
    );

    if (!validSegments.length) {
      return [];
    }

    const firstSegment = validSegments[0];
    const lastSegment = validSegments[validSegments.length - 1];

    return [firstSegment.startDate, lastSegment.endDate];
  }
}
