import React from "react";
import { useHistory } from "react-router";
import CollectionModel from "../../models/collection.model";
import UserProfileIconList from "../../components/user-profile-icon-list";
import pluralize from "pluralize";

interface Props {
  collection: CollectionModel;
}

export default function GridCollectionRow(props: Props) {
  const { collection } = props;
  const { bookingRequestCount, collaborators, id, name } = collection;
  const history = useHistory();

  return (
    <div
      className="grid-collection-row"
      onClick={() => history.push(`/collections/${id}`)}
    >
      <div>{name}</div>
      <div>{`${bookingRequestCount} ${pluralize(
        "Traveler",
        bookingRequestCount
      )}`}</div>
      <div>
        <UserProfileIconList users={collaborators} />
      </div>
    </div>
  );
}
