import React from "react";
import { DateTime } from "luxon";
import { debounce } from "lodash";

import AppIcon from "../../../../../components/app-icon";
import Button from "../../../../../components/button";
import TextInput from "../../../../../components/form/inputs/TextInput";
import Tooltip from "../../../../../components/tooltip";

import { getDisplayDate } from "../../../../../utils/helpers";
import useModal from "../../../../../hooks/use-modal.hook";

const scheduleItemHash: any = {
  activity: {
    color: "red",
    iconType: "event",
    fromLabel: ""
  },
  air: {
    color: "blue",
    iconType: "flight-takeoff",
    fromLabel: "Departure",
    toLabel: "Arrival"
  },
  car: {
    color: "purple",
    iconType: "car",
    fromLabel: "Pick-Up",
    toLabel: "Drop-Off"
  },
  event: {
    color: "red",
    iconType: "event",
    fromLabel: "Event"
  },
  hotel: {
    color: "green",
    iconType: "hotel",
    fromLabel: "Check-In",
    toLabel: "Check-Out"
  },
  rail: {
    color: "orange",
    iconType: "train",
    fromLabel: "Departure",
    toLabel: "Arrival"
  }
};

interface Props {
  customEventOrder: any;
  printViewHides?: string[];
  events: any[];
  futureOnly: boolean;
  locationsData: any[];
  onAddScheduleNote: CallableFunction;
  onChangeScheduleEvent: CallableFunction;
  onChangeScheduleEventOrder: CallableFunction;
  onChangeScheduleEventHide: CallableFunction;
  onDeleteScheduleEventOrder: CallableFunction;
  onDeleteScheduleEvent: CallableFunction;
  printMode: boolean;
  notes: any;
  trips: any[];
  updateStaticResults: CallableFunction;
  viewMode: boolean;
}

export default function ScheduleContent(props: Props) {
  const { openModal } = useModal();

  const scheduleTrips = (props.trips || [])
    .map((trip) => {
      if (trip.segments.length) {
        trip.segments.forEach((segment: any) => {
          segment.type = trip.type;
        });
        return trip.segments;
      }
      return trip;
    })
    .flat();

  let scheduleItems = scheduleTrips.concat(props.events);

  if (props.futureOnly) {
    scheduleItems = scheduleItems.filter((i) => {
      const itemDate = i.to || i.from; // use from for schedule events
      return DateTime.local() <= DateTime.fromISO(itemDate);
    });
  }

  const scheduleDates = (scheduleItems || [])
    .map((item) => {
      const itemType = item.type.toLowerCase();
      if (["activity", "air", "event", "rail"].includes(itemType)) {
        return item.from;
      }
      return [item.from, item.to];
    })
    .flat();

  const uniqueDates = [
    ...new Set(scheduleDates.map((date) => getDisplayDate(date, "yyyy-MM-dd")))
  ].sort((a, b) => {
    if (a === b) {
      return 0;
    }
    return a < b ? -1 : 1;
  });

  let scheduleCards = (scheduleItems || [])
    .map((item) => {
      const itemType = item.type.toLowerCase();
      const { color, iconType } = scheduleItemHash[itemType] || {
        color: "red",
        iconType: "event"
      };
      const cards: any[] = [];
      let cardHeader = null;
      const fromCardBody: any[] = [];
      const toCardBody: any[] = [];

      if (itemType === "event") {
        cards.push({
          data: item,
          date: item.from,
          dateGroup: getDisplayDate(item.from, "yyyy-MM-dd"),
          displayProps: {
            actionText: "",
            body: [item.description],
            color,
            header: item.name,
            iconType
          },
          id: `${item.id}-event`,
          type: itemType
        });
        return cards;
      }

      if (["air", "rail"].includes(itemType)) {
        cardHeader = `${item.fromLocation} to ${item.toLocation}`;
        fromCardBody.push(
          `${item.name}${item.flightNum ? ` ${item.flightNum}` : ""}  (${
            item.duration
          })`,
          `Arrives ${getDisplayDate(item.to, "EEEE, MMMM d @ h:mm a")}`
        );
        if (item.confirmation) {
          fromCardBody.push(`Confirmation ${item.confirmation}`);
        }
      } else {
        cardHeader = item.name;
        if (item.fromLocation) {
          fromCardBody.push(`${item.fromLocation}`);
        }
        if (item.supportPhoneNumber) {
          fromCardBody.push(`${item.supportPhoneNumber}`);
        }
      }

      cards.push({
        data: ["air", "rail"].includes(itemType)
          ? props.trips.find((trip) => trip.id === item.tripId)
          : item,
        date: item.from,
        dateGroup: getDisplayDate(item.from, "yyyy-MM-dd"),
        displayProps: {
          actionText: scheduleItemHash[itemType].fromLabel || "",
          body: fromCardBody,
          color,
          header: cardHeader,
          iconType
        },
        id: `${item.id}-from`,
        type: itemType
      });

      if (!["activity", "air", "event", "rail"].includes(itemType)) {
        cards.push({
          data: item,
          date: item.to,
          dateGroup: getDisplayDate(item.to, "yyyy-MM-dd"),
          displayProps: {
            actionText: scheduleItemHash[itemType].toLabel || "",
            body: toCardBody,
            color,
            header: cardHeader,
            iconType
          },
          id: `${item.id}-to`,
          type: itemType
        });
      }
      return cards;
    })
    .flat()
    .sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }

      if (a.date > b.date) {
        return 1;
      }

      return 0;
    });

  const printViewHides = props.printViewHides || [];

  if (props.viewMode && printViewHides.length) {
    scheduleCards = scheduleCards.filter(
      (card) => !printViewHides.includes(card.id)
    );
  }

  return scheduleCards.length ? (
    <div className="schedule-block-content">
      {uniqueDates.map((date: any, index: number) => {
        const cardsByDate = scheduleCards.filter(
          (card) => card.dateGroup === getDisplayDate(date, "yyyy-MM-dd")
        );

        if (props.viewMode && !cardsByDate.length) {
          return null;
        }

        return (
          <div
            key={`schedule-block-date-group-${date}-${index}`}
            className="schedule-block-date-group"
          >
            <div className="schedule-block-date-group__header">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="schedule-block-content__bullet" />
                <h3
                  className="schedule-block-content__date"
                  key={`schedule-block-date-${index}`}
                >
                  {getDisplayDate(date, "EEEE, MMMM d")}
                </h3>
              </div>
              {Object.hasOwnProperty.call(
                props.customEventOrder,
                getDisplayDate(date, "yyyy-MM-dd")
              ) && (
                <div className="schedule-block-date-group__button">
                  <Tooltip text="Remove custom order">
                    <Button
                      color="light-red"
                      icon="sync-strike"
                      isRippleDisabled={true}
                      isTransparent={true}
                      onClick={() => {
                        openModal("confirm", {
                          buttonText: "Clear It",
                          dialogTitle: "Clear Custom Order",
                          dialogBody:
                            "Are you sure you want to clear the custom order for this day?",
                          onConfirm: () =>
                            props.onDeleteScheduleEventOrder(
                              getDisplayDate(date, "yyyy-MM-dd")
                            )
                        });
                      }}
                      size="medium"
                    />
                  </Tooltip>
                </div>
              )}
            </div>
            {scheduleCards
              .filter(
                (card) => card.dateGroup === getDisplayDate(date, "yyyy-MM-dd")
              )
              .sort((a: any, b: any) => {
                const { dateGroup } = a;
                if (
                  Object.hasOwnProperty.call(props.customEventOrder, dateGroup)
                ) {
                  const v1 = a.id;
                  const v2 = b.id;

                  let o1 = props.customEventOrder[dateGroup].indexOf(v1);
                  let o2 = props.customEventOrder[dateGroup].indexOf(v2);

                  if (o1 === -1) {
                    o1 = 9999;
                  }
                  if (o2 === -1) {
                    o2 = 9999;
                  }

                  if (o1 > o2) {
                    return 1;
                  }
                  if (o2 > o1) {
                    return -1;
                  }
                }

                return 0;
              })
              .map((card: any, cardIndex: number, cardArray: any[]) => {
                const printViewHide = printViewHides.includes(card.id);

                return (
                  <div
                    className="schedule-block-content__card-wrapper"
                    key={`${card.dateGroup}-${card.id}-${cardIndex}`}
                  >
                    <div className="schedule-block-content__connector-line" />
                    <div
                      className="schedule-block-content__card"
                      onClick={() => {
                        if (props.printMode || props.viewMode) {
                          return;
                        }

                        if (card.type === "event") {
                          openModal("edit-schedule-event", {
                            data: card.data,
                            onChangeScheduleEvent: props.onChangeScheduleEvent,
                            onDeleteScheduleEvent: props.onDeleteScheduleEvent
                          });
                        }

                        if (
                          ["activity", "air", "car", "hotel", "rail"].includes(
                            card.type
                          )
                        ) {
                          const dataType = String(card.type).toUpperCase();
                          const quickAddType = ["AIR", "RAIL"].includes(
                            dataType
                          )
                            ? "quick-add-large"
                            : "quick-add";

                          openModal(quickAddType, {
                            data: card.data,
                            dataType,
                            locationsData: props.locationsData,
                            onClose: props.updateStaticResults
                          });
                        }
                      }}
                    >
                      <div
                        className="schedule-block-content__card-menu"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="card-menu__option--hide">
                          <Tooltip
                            text={
                              printViewHide
                                ? "Show event in print and shared view"
                                : "Hide event in print and shared view"
                            }
                          >
                            <Button
                              color={printViewHide ? "red" : "product-blue"}
                              icon={
                                printViewHide ? "visible-strike" : "visible"
                              }
                              isRounded={true}
                              isTransparent={true}
                              onClick={() => {
                                props.onChangeScheduleEventHide(
                                  card.id,
                                  !printViewHide
                                );
                              }}
                              size="small"
                            />
                          </Tooltip>
                        </div>

                        <div className="card-menu__option--move-up">
                          <Tooltip text="Move event up">
                            <Button
                              color="product-blue"
                              icon="arrow-up"
                              isDisabled={cardIndex === 0}
                              isRounded={true}
                              isTransparent={true}
                              onClick={() => {
                                props.onChangeScheduleEventOrder(
                                  cardArray.map((c) => c.id),
                                  card,
                                  "up"
                                );
                              }}
                              size="small"
                            />
                          </Tooltip>
                        </div>

                        <div className="card-menu__option--move-down">
                          <Tooltip text="Move event down">
                            <Button
                              color="product-blue"
                              icon="arrow-down"
                              isDisabled={cardIndex + 1 === cardArray.length}
                              isRounded={true}
                              isTransparent={true}
                              onClick={() => {
                                props.onChangeScheduleEventOrder(
                                  cardArray.map((c) => c.id),
                                  card,
                                  "down"
                                );
                              }}
                              size="small"
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <div className="schedule-block-content__card-details">
                        <div
                          className={`icon-wrapper icon-wrapper--${card.displayProps.color}`}
                        >
                          <AppIcon
                            type={card.displayProps.iconType}
                            color={card.displayProps.color}
                            size="small"
                          />
                        </div>
                        <div>
                          <h4 className="schedule-block-content__time">
                            {getDisplayDate(card.date, "h:mm a")}
                            {` ${card.displayProps.actionText}`}
                          </h4>
                          {card.displayProps.header && (
                            <h5 className="schedule-block-content__card-header">
                              {card.displayProps.header}
                            </h5>
                          )}
                          {card.displayProps.body.map(
                            (propName: string, propIndex: number) => {
                              if (!propName) {
                                return null;
                              }
                              return (
                                <p
                                  className="schedule-block-content__card-text"
                                  key={`schedule-block-content__card-text-${propIndex}`}
                                >
                                  {propName}
                                </p>
                              );
                            }
                          )}
                        </div>
                      </div>
                      {(!props.viewMode && !props.printMode) ||
                      ((props.notes || {})[card.id] || "").length ? (
                        <div className="schedule-block-content__card-notes">
                          <div className="schedule-block-content__card-notes-header">
                            <AppIcon type="notes" />
                            <h5 className="schedule-block-content__card-header">
                              Notes
                            </h5>
                          </div>
                          <TextInput
                            key={`${card.id}-${cardIndex}`}
                            disabled={props.viewMode}
                            isMultiline={true}
                            onClick={(e: any) => e.stopPropagation()}
                            onChange={debounce(
                              (value: string) =>
                                props.onAddScheduleNote(card.id, value),
                              300
                            )}
                            defaultValue={(props.notes || {})[card.id] || ""}
                            variant="outlined"
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  ) : null;
}
