import React, { MouseEvent } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";

import AppContainer from "../../container";
import { IGridService, GridService } from "../../services/grid.service";
import GridModel from "../../models/grid.model";

import AppIcon from "../../components/app-icon";
import Button, { ButtonColor } from "../../components/button";

import useApp from "../../hooks/use-app.hook";
import useModal from "../../hooks/use-modal.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";

import "./grid-card-menu.scss";

interface Props {
  color: ButtonColor;
  grid: GridModel;
  onClose?: CallableFunction;
  onOpen?: CallableFunction;
}

export default function GridCardMenu(props: Props) {
  const { color, grid, onClose, onOpen } = props;

  const gridService: IGridService = AppContainer.get(GridService);

  const { openModal } = useModal();
  const { setSnackbar } = useSnackbar();
  const authedUser = useApp().user;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [popperOpen, setPopperOpen] = React.useState<boolean>(false);

  const togglePopper = (event: any) => {
    event.preventDefault(); // stops click from bubbling when rendered inside clickable element
    const popperState = !popperOpen;
    setPopperOpen(popperState);
    popperState && onOpen && onOpen();
    !popperState && onClose && onClose();
  };

  const deleteGrid = () => {
    openModal("confirm", {
      buttonText: "Delete",
      dialogTitle: "Delete Grid",
      dialogBody:
        "Are you sure you want to delete this grid? This cannot be undone.",
      onConfirm: async () => {
        const deletedGrid = await gridService.delete(grid.id);
        if (deletedGrid) {
          setSnackbar({
            message: "Grid deleted!",
            variant: "success"
          });
        }
      }
    });
  };

  const openGridModal = () => {
    openModal("edit-grid", {
      grid
    });
  };

  const toggleArchiveGrid = () => {
    grid.archived = !grid.archived;
    gridService.update(grid);
  };

  const gridStarred = grid.starredBy.includes(authedUser.id);

  const toggleStarGrid = () =>
    gridStarred ? gridService.unstar(grid.id) : gridService.star(grid.id);

  return (
    <React.Fragment>
      <div
        className="grid-card-menu-trigger"
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
          togglePopper(event);
        }}
      >
        <Button color={color} icon="horiz-dots" size="small" />
      </div>
      <Popper
        id={`grid-card-popper-${grid.id}`}
        className="grid-card-popper"
        open={popperOpen}
        anchorEl={anchorEl}
        placement="top-start"
        transition
      >
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={(event) => {
            togglePopper(event);
          }}
        >
          <div>
            <div
              className="popper-row"
              onClick={(event) => {
                togglePopper(event);
                openGridModal();
              }}
            >
              <AppIcon color="black" size="x-small" type="edit" />
              Edit
            </div>
            <div
              className="popper-row"
              onClick={(event) => {
                togglePopper(event);
                toggleArchiveGrid();
              }}
            >
              <AppIcon
                color="black"
                size="x-small"
                type={grid.archived ? "unarchive" : "archive"}
              />
              {grid.archived ? "Unarchive" : "Archive"}
            </div>
            <div
              className="popper-row"
              onClick={(event) => {
                togglePopper(event);
                toggleStarGrid();
              }}
            >
              <AppIcon
                color="black"
                size="x-small"
                type={gridStarred ? "star-border" : "star"}
              />
              {gridStarred ? "Unstar" : "Star"}
            </div>
            {grid.archived && (
              <div
                className="popper-row"
                onClick={(event) => {
                  togglePopper(event); // dismiss popper to prevent popper placement when confirm opens
                  deleteGrid();
                }}
              >
                <AppIcon color="black" size="x-small" type="trash" />
                Delete
              </div>
            )}
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
}
