import React from "react";
import TextField from "@material-ui/core/TextField";
import {
  getTextValueProps,
  MuiVariant,
  ObjectHash
} from "../../../utils/helpers";
import "./inputs.scss";

// format number into integer
const formatNumber = (e: any, min: number, max: number) => {
  const inputVal = e.target.value;

  if (inputVal === "") {
    return inputVal;
  }

  // remove non-numeric, special characters, leading zeros
  let formattedVal = inputVal.replace(/\D/g, "").replace(/^0+/, "");

  // set to min or max if outside bounds
  if (parseInt(formattedVal, 10) < min) {
    formattedVal = min;
  }

  if (parseInt(formattedVal, 10) > max) {
    formattedVal = max;
  }

  return formattedVal;
};

interface Props {
  autoFocus?: boolean;
  defaultValue?: string;
  InputProps?: ObjectHash;
  inputProps?: ObjectHash;
  label?: string;
  isRequired?: boolean;
  onChange: CallableFunction;
  value?: string;
  variant?: MuiVariant;
}

export default function CurrencyInput(props: Props) {
  const {
    autoFocus,
    InputProps,
    inputProps,
    label,
    isRequired,
    onChange,
    variant
  } = props;

  let disableUnderline = {};
  if (variant && ["filled", "standard"].includes(variant)) {
    disableUnderline = { disableUnderline: true };
  }

  return (
    <TextField
      {...getTextValueProps(props)}
      fullWidth
      autoFocus={autoFocus || false}
      autoComplete="off"
      className="text-input integer-input"
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        ...disableUnderline,
        ...InputProps
      }}
      inputProps={{
        ...inputProps
      }}
      label={label}
      onChange={(e) => {
        const formattedValue = formatNumber(
          e,
          (inputProps || {}).min,
          (inputProps || {}).max
        );
        e.target.value = formattedValue;
        onChange(formattedValue);
      }}
      required={isRequired || false}
      type="number"
      variant={variant || "outlined"}
    />
  );
}
