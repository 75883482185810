import React from "react";
import classnames from "classnames";
import "./app-icon-frame.scss";

export interface Props {
  children: JSX.Element | JSX.Element[];
  color?:
    | "tag-light-blue"
    | "product-background-blue"
    | "product-blue"
    | "light-green"
    | "gray";
  shape?: "circle" | "square";
  size?: "large";
}

export default function AppIconFrame(props: Props) {
  const { color = "product-blue", shape = "circle", size = "" } = props;
  return (
    <div className={classnames("app-icon-frame", color, shape, size)}>
      {props.children}
    </div>
  );
}
