import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import TextInput from "../../form/inputs/TextInput";

const ConfirmationNumberFilter = new TravelFilterModel({
  field: TravelFilterFields.ConfirmationNumber,
  name: "Confirmation Number",
  group: TravelFilterGroups.Identifier,
  resource: TravelFilterResources.Trip,
  component: TextInput
});

export default ConfirmationNumberFilter;
