import React from "react";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";

import {
  GeoLocation,
  GeoService,
  IGeoService
} from "../../../services/geo.service";

import AppContainer from "../../../container";
import Loader from "../../loader";

import { ObjectHash, getSelectValueProps } from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  defaultValue?: any[];
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  onChange: CallableFunction;
  value?: any[];
}

export default function LocationAsyncInput(props: Props) {
  const geoService: IGeoService = AppContainer.get(GeoService);

  const { isDisabled, isRequired, label, onChange } = props;

  const { menu, menuPortal, option, singleValue } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu,
    menuPortal,
    option,
    singleValue
  };

  const filterLocations = debounce(
    (inputValue: string, callback: CallableFunction) => {
      if (inputValue.length < 2) {
        return callback([]);
      }
      geoService.getLocations(inputValue).then((locations: GeoLocation[]) => {
        const options = locations.map((location: GeoLocation) => ({
          label: location.description,
          value: location.description,
          meta: location
        }));
        return callback(options);
      });
    },
    300
  );

  const getOptions = (inputValue: string, callback: CallableFunction) => {
    if (!inputValue) {
      return Promise.resolve({ options: [] });
    }
    return filterLocations(inputValue, callback);
  };

  return (
    <div className="autocomplete-input--filled location-async-input-filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncSelect
        {...getSelectValueProps(props)}
        cacheOptions
        className="autocomplete-input autocomplete-input--async"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        isDisabled={isDisabled}
        loadOptions={getOptions}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No locations found" : null
        }
        onChange={(option: any) => onChange(option?.value, option?.meta)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
