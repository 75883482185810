import { flatten, isEqual, uniq } from "lodash";
import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState
} from "react";
import AppContainer from "../../../../container";
import AirportModel from "../../../../models/airport.model";
import {
  FlightService,
  IFlightService
} from "../../../../services/flight.service";
import GridModel from "../../../../models/grid.model";
import { getLogTrips } from "../lib/service";
import TripModel from "../../../../models/trip.model";
import UserModel from "../../../../models/user.model";
import { IUserService, UserService } from "../../../../services/user.service";
import {
  TmlAction,
  TmlActionTypes,
  TmlReducer,
  TmlTripReducer,
  TmlUpdateReducer,
  TmlUserReducer
} from "./reducer";
import { TmlEntryModel, TmlLogModel, TmlLogUpdate } from "../lib/models";
import usePrevious from "../../../../hooks/use-previous.hook";

export interface ITmlState {
  airports: Map<string, AirportModel>;
  dispatch: (action: TmlAction) => void;
  dispatchUsers: (action: TmlAction) => void;
  dispatchTrips: (action: TmlAction) => void;
  dispatchLogUpdates: (action: TmlAction) => void;
  log: TmlLogModel;
  logUpdates: TmlLogUpdate[];
  trips: Map<string, TripModel[]>;
  users: Map<string, UserModel>;
}

export const TmlContext = createContext<ITmlState>({
  airports: new Map(),
  dispatch: (action: TmlAction) => {},
  dispatchUsers: (action: TmlAction) => new Map(),
  dispatchTrips: (action: TmlAction) => new Map(),
  dispatchLogUpdates: (action: TmlAction) => [],
  log: new TmlLogModel(),
  logUpdates: [],
  trips: new Map(),
  users: new Map()
});

export const useTmlLog = () => useContext(TmlContext);

interface Props {
  grid: GridModel;
  children: ReactNode | ReactNode[];
}

type TripsUsersType = {
  trips: Map<string, TripModel[]>;
  users: Map<string, UserModel>;
};

export function TmlProvider(props: Props) {
  const userService: IUserService = AppContainer.get(UserService);
  const flightService: IFlightService = AppContainer.get(FlightService);

  const { grid, children } = props;

  const [airports, setAirports] = useState<Map<string, AirportModel>>(
    new Map()
  );
  const [log, dispatch] = useReducer(
    TmlReducer,
    new TmlLogModel({ grid: grid.id })
  );
  const [trips, dispatchTrips] = useReducer(TmlTripReducer, new Map());
  const [users, dispatchUsers] = useReducer(TmlUserReducer, new Map());
  const [logUpdates, dispatchLogUpdates] = useReducer(TmlUpdateReducer, []);

  const oldLog = usePrevious(log);
  const oldTrips = usePrevious(trips);

  const loadMeta = useCallback(async (): Promise<TripsUsersType> => {
    const { settings } = log;
    if (!log.entries.length) {
      return {
        trips: new Map(),
        users: new Map()
      };
    }

    const userIds = uniq(
      log.entries.map((entry: TmlEntryModel) => entry.userId)
    );
    const users: Map<string, UserModel> = new Map();

    if (userIds.length) {
      const response = await userService.getByIds(userIds as string[]);
      response.forEach((user: UserModel) => users.set(user.id, user));
    }

    const tripsByUsers = await getLogTrips(grid, settings);

    return {
      trips: tripsByUsers,
      users
    };
  }, [grid, log, userService]);

  // @todo ensure that manually adding new users to the log triggers a refresh of users, trips
  // @todo ws listen for incoming new/updated trips, check that a user in this log, has the trip, trigger trips refresh

  // did trips change? load their airports
  useEffect(() => {
    const tripsChanged = Boolean(oldTrips) && !isEqual(trips, oldTrips);
    const locationsChanged =
      Boolean(oldLog?.settings.locations) &&
      !isEqual(oldLog?.settings.locations, log.settings.locations);

    if (!tripsChanged && !locationsChanged) {
      return;
    }

    let subscribed = true;
    const unsubscribe = () => {
      subscribed = false;
    };

    const allTrips = flatten(Array.from(trips).map(([, trips]) => trips));

    Promise.all([
      flightService.getAirportsforTrips(allTrips),
      flightService.getAirportsByIds(log.settings.locations)
    ]).then((response: [Map<string, AirportModel>, AirportModel[]]) => {
      if (!subscribed) {
        return;
      }

      const airports = response[0];
      response[1].forEach((airport: AirportModel) =>
        airports.set(airport.id, airport)
      );
      setAirports(airports);
    });

    return unsubscribe;
  }, [
    flightService,
    log.settings.locations,
    oldTrips,
    oldLog?.settings.locations,
    trips
  ]);

  // refresh log metadata when the log changes
  // @todo when to reload users and trips? new entry, remove entry, settings change
  useEffect(() => {
    let subscribed = true;
    const unsubscribe = () => {
      subscribed = false;
    };

    loadMeta().then((logMeta: TripsUsersType) => {
      if (!subscribed) {
        return;
      }
      const { trips, users } = logMeta;
      dispatchTrips({ type: TmlActionTypes.LoadTrips, payload: trips });
      dispatchUsers({ type: TmlActionTypes.LoadUsers, payload: users });
    });

    return unsubscribe;
  }, [loadMeta, log]); // oldLog

  return (
    <TmlContext.Provider
      value={{
        airports,
        dispatch,
        dispatchTrips,
        dispatchUsers,
        dispatchLogUpdates,
        log,
        logUpdates,
        trips,
        users
      }}
    >
      {children}
    </TmlContext.Provider>
  );
}
