import "reflect-metadata";
import { injectable } from "inversify";

export interface IBaseService {
  subscribeWs(dispatch: CallableFunction): Promise<void>;
}

@injectable()
class BaseService implements IBaseService {
  // extending services should override to connect to the websocket
  async subscribeWs(dispatch: CallableFunction): Promise<void> {}
}

export default BaseService;
