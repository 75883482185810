import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import Button from "../../button";
import AppContainer from "../../../container";
import useModal from "../../../hooks/use-modal.hook";
import DocumentModel from "../../../models/document.model";
import {
  DocumentService,
  IDocumentService
} from "../../../services/document.service";

import { ObjectHash } from "../../../utils/helpers";

import {
  customStyle,
  customReactSelectTheme
} from "../../form/inputs/custom-theme";
import "./create-document-button.scss";

export interface Props {
  customButton?: any;
  gridId?: string;
  onCreate?: CallableFunction;
}

const CreateDocumentButton = (props: Props) => {
  const documentService: IDocumentService = AppContainer.get(DocumentService);
  const { customButton, gridId, onCreate } = props;

  const [templates, setTemplates] = useState<DocumentModel[]>([]);

  const history = useHistory();
  const { openModal } = useModal();
  const [inputKey, setInputKey] = useState(0);

  const { menu, menuPortal, option, singleValue } = customStyle;

  const optionStyles = {
    control: (styles: object) => ({
      ...styles,
      backgroundColor: "transparent",
      boxShadow: "none",
      padding: "0",
      border: "none",
      "> div": {
        padding: "0"
      }
    }),
    menu: (styles: any) => ({
      ...menu(styles),
      overflowY: "auto",
      minWidth: "160px",
      width: "100%",
      left: 0
    }),
    option,
    singleValue,
    menuPortal
  };

  const templateOptions: { label: string; value: string }[] = [];
  templates.forEach((template: { blocks: any[]; id: string; name: string }) => {
    templateOptions.push({
      label: template.name,
      value: template.id
    });
  });

  const options = [
    { label: "Blank Document", value: null },
    ...templateOptions
  ];

  const handleTemplateSelect = async (docTemplateId: string) => {
    const onChangeDocument: CallableFunction = async (newDoc: ObjectHash) => {
      const createdDoc: DocumentModel | null = await documentService.create({
        ...newDoc
      });
      if (createdDoc) {
        onCreate && onCreate();
        history.push(`/documents/${createdDoc.id}/build`);
      }
    };

    const modalOpts: ObjectHash = {
      template: {
        blocks: [],
        filter: {
          gridId
        },
        name: "Blank Document",
        grid: gridId
      },
      target: "document",
      fromBlank: true,
      onChangeDocument
    };

    if (docTemplateId) {
      const docTemplate: DocumentModel | null = await documentService.getById(
        docTemplateId
      );
      if (docTemplate) {
        modalOpts.fromBlank = false;
        modalOpts.template.blocks = docTemplate.blocks ?? [];
        modalOpts.template.name = docTemplate.name
          ? `Untitled from template - ${docTemplate.name}`
          : "Untitled from template - Untitled Template";
      }
    }

    openModal("edit-document", modalOpts);
  };

  const formatOptionLabel = (option: { label: string }) => (
    <div className="create-document-option-wrapper">
      <div className="create-document-option__label">{option.label}</div>
    </div>
  );

  useEffect(() => {
    documentService.getTemplates().then(setTemplates);
  }, [documentService, setTemplates]);

  return (
    <Select
      key={inputKey}
      components={{
        Placeholder: () =>
          customButton ? (
            customButton
          ) : (
            <Button
              color="product-blue"
              icon="plus-circle"
              isTransparent={true}
              isRippleDisabled={true}
              label="Build Document"
              size="medium"
            />
          ),
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      formatOptionLabel={formatOptionLabel}
      isSearchable={false}
      maxMenuHeight={240}
      menuPlacement="auto"
      onChange={(option: any) => {
        const newInputKey = inputKey + 1;
        setInputKey(newInputKey); // Re-renders the component so it can be opened again if the user cancels out of create document dialog
        handleTemplateSelect(option.value);
      }}
      options={options}
      styles={optionStyles}
      value={null}
      tabSelectsValue={false}
      theme={customReactSelectTheme}
    />
  );
};

export default CreateDocumentButton;
