import { cloneDeep, difference, flatten, intersection, isEqual } from "lodash";

import AirportModel from "../../../../models/airport.model";
import AppContainer from "../../../../container";
import { ApiService, IApiService } from "../../../../services/api.service";
import GridAppModel from "../../../../models/gridapp.model";
import GridModel from "../../../../models/grid.model";
import { GridService, IGridService } from "../../../../services/grid.service";
import { Column, Filter, Group, Sort } from "../../../sink/helpers";
import {
  ITravelQueryService,
  TravelQueryService
} from "../../../../services/travel-query.service";
import {
  airportToIata,
  getDisplayDate,
  getStringCompareSortValue,
  getTimestamp,
  ObjectHash
} from "../../../../utils/helpers";
import {
  TmlLogUpdate,
  TmlEntryModel,
  TmlEntrySegment,
  TmlEntrySegmentAir,
  TmlEntrySegmentTravel,
  TmlEntryType,
  TmlLogModel,
  TmlLogSettings,
  TmlEntrySegmentTrip,
  TmlEntrySegmentRail
} from "./models";
import TripModel from "../../../../models/trip.model";
import {
  ISearchService,
  SearchService
} from "../../../../services/search.service";
import UserModel from "../../../../models/user.model";
import { IUserService, UserService } from "../../../../services/user.service";
import {
  FlightService,
  IFlightService
} from "../../../../services/flight.service";
import SegmentModel from "../../../../models/segment.model";
import TagGroupModel from "../../../../models/tag-group.model";
import TravelerModel from "../../../../models/traveler.model";

export async function addLogUsers(
  grid: GridModel,
  log: TmlLogModel,
  userIds: string[]
): Promise<{
  log: TmlLogModel;
  users: Map<string, UserModel>;
  trips: Map<string, TripModel[]>;
} | null> {
  const userService: IUserService = AppContainer.get(UserService);

  const updatedLog = new TmlLogModel({ ...log });
  const { settings } = updatedLog;
  const existingUserIds = updatedLog.entries.map(
    (entry: TmlEntryModel) => entry.userId
  );

  const tripsByUser = await getLogTrips(grid, settings);
  const tripsByUpdatedUser: Map<string, TripModel[]> = new Map();
  const airports = await getTmlSettingsAirports(settings);

  for (const userId of userIds) {
    // ensure user does not already exist in log
    if (existingUserIds.includes(userId)) {
      continue;
    }

    const trips = tripsByUser.get(userId) || [];

    const entries = createUserEntries(userId, trips, airports).map(
      (entry: TmlEntryModel) => {
        entry.addedByUser = true;
        return entry;
      }
    );

    updatedLog.entries = updatedLog.entries.concat(entries);

    tripsByUpdatedUser.set(userId, trips);
  }

  const response = await updateLog(updatedLog);

  if (!response) {
    return null;
  }

  // pull full user objects related to the log for context updates
  const users: Map<string, UserModel> = new Map();
  if (userIds.length) {
    const response = await userService.getByIds(userIds);
    response.forEach((user: UserModel) => users.set(user.id, user));
  }

  return { log: updatedLog, users, trips: tripsByUpdatedUser };
}

/*
 * Create TML Log Entries for a given user and set of trips
 *
 * Note: TML Settings dates are NOT used here because the function assumes that any trips passed in already fall within the correct date range
 */
const createUserEntries = (
  userId: string,
  trips: TripModel[],
  airports: AirportModel[]
): [TmlEntryModel, TmlEntryModel] => {
  const targetAirports = airports.map((airport: AirportModel) =>
    airport.getFullName()
  );

  const timestamp = getTimestamp();

  const inEntry = new TmlEntryModel({
    createdAt: timestamp,
    jobTitle: false,
    type: "inbound",
    updatedAt: timestamp,
    userId
  });

  const outEntry = new TmlEntryModel({
    createdAt: timestamp,
    jobTitle: false,
    type: "outbound",
    updatedAt: timestamp,
    userId
  });

  const inboundTrips: { trip: TripModel; segment?: SegmentModel | null }[] = [];
  const outboundTrips: {
    trip: TripModel;
    segment?: SegmentModel | null;
  }[] = [];

  const airRailTrips = trips.filter((trip: TripModel) =>
    ["air", "rail"].includes(String(trip.type).toLowerCase())
  );
  const otherTrips = difference(trips, airRailTrips);

  /*
   * Determine inbound/outbound travel by matching trip locations to tml settings locations
   */
  airRailTrips.forEach((trip: TripModel) => {
    const destinationSegment = trip.getDestinationSegment();
    const departureSegments = trip.getDepartureSegments();

    const { toLocation = "" } = destinationSegment || {};

    if (targetAirports.includes(toLocation)) {
      inboundTrips.push({ trip, segment: destinationSegment });
    }

    departureSegments.forEach((segment: SegmentModel) => {
      const { fromLocation } = segment;

      if (targetAirports.includes(fromLocation)) {
        outboundTrips.push({ trip, segment });
      }
    });
  });

  // @todo get smarter about dealing with more than one matching trip
  if (inboundTrips.length) {
    const { trip, segment } = inboundTrips[0];
    inEntry.travelSegment = getEntrySegmentFromTrip(
      trip,
      segment
    ) as TmlEntrySegmentTravel;
  }

  if (outboundTrips.length) {
    const { trip, segment } = outboundTrips[0];
    outEntry.travelSegment = getEntrySegmentFromTrip(
      trip,
      segment
    ) as TmlEntrySegmentTravel;
  }

  /*
   * Assign transportation and location
   */

  const transports: TripModel[] = [];
  const locations: TripModel[] = [];

  otherTrips.forEach((trip: TripModel) => {
    const { type } = trip;
    const tripType = String(type).toLowerCase();

    if (tripType === "car") {
      transports.push(trip);
      return;
    }

    if (tripType === "hotel") {
      locations.push(trip);
      return;
    }
  });

  if (transports.length === 1) {
    const transportSegment = getEntrySegmentFromTrip(transports[0]);
    inEntry.transportSegment = transportSegment;
    outEntry.transportSegment = cloneDeep(transportSegment);
  }

  if (locations.length === 1) {
    const locationSegment = getEntrySegmentFromTrip(locations[0]);
    inEntry.locationSegment = locationSegment;
    outEntry.locationSegment = cloneDeep(locationSegment);
  }

  return [inEntry, outEntry];
};

/*
 * Load all trips for a given grid, filter them by TML settings, then
 * generate a new log for the grid using its currently available trips.
 */
export async function createLog(
  grid: GridModel,
  settings: TmlLogSettings,
  disableSave?: boolean
): Promise<{ log: TmlLogModel | null; tripsByUser: Map<string, TripModel[]> }> {
  const apiService: IApiService = AppContainer.get(ApiService);
  const gridService: IGridService = AppContainer.get(GridService);

  const timestamp = getTimestamp();

  const log = new TmlLogModel({
    createdAt: timestamp,
    updatedAt: timestamp,
    grid: grid.id,
    settings
  });

  const tripsByUser = await getLogTrips(grid, settings);
  const airports = await getTmlSettingsAirports(settings);

  const gridUsers = await gridService.getTravelers(grid.id);

  // For each user, generate entries
  log.entries = flatten(
    gridUsers.map((gridUser: TravelerModel) => {
      const { id } = gridUser;
      const trips = tripsByUser.get(id) || [];
      return createUserEntries(id, trips, airports);
    })
  );

  if (disableSave) {
    return { log, tripsByUser };
  }

  const response: GridAppModel = await apiService.put(
    `/grids/${grid.id}/apps/travel-movement-log`,
    log
  );

  if (!response) {
    return { log: null, tripsByUser };
  }

  return { log: makeLogFromAppData(response), tripsByUser };
}

export async function updateLog(log: TmlLogModel): Promise<TmlLogModel | null> {
  const apiService: IApiService = AppContainer.get(ApiService);
  const { grid } = log;

  log.updatedAt = getTimestamp();

  const response = await apiService.put(
    `/grids/${grid}/apps/travel-movement-log`,
    log
  );

  if (!response) {
    return null;
  }

  return makeLogFromAppData(response);
}

export const getTmlColumns = (
  defaultColumns: Column[],
  tmlColumns: Column[],
  tagGroups?: TagGroupModel[]
): Column[] => {
  const systemColumns = [...defaultColumns];

  // add tag groups to available columns
  if (tagGroups) {
    tagGroups.forEach((tagGroup: TagGroupModel) => {
      const { hidden, name } = tagGroup;
      if (hidden) {
        return;
      }

      systemColumns.push({
        id: tagGroup.getFieldId(),
        label: name,
        hidden: true
      });
    });
  }

  const allColumns: Column[] = [];
  let pinnedIndex = 1,
    unpinnedIndex = 1;

  // system columns can only be hidden and positioned by the user, all other config values are "hard-coded"
  systemColumns.forEach((column: Column) => {
    const { id, pinned } = column;

    const tmlColumn = tmlColumns.find((column: Column) => column.id === id);
    const {
      hidden = false,
      position = pinned ? pinnedIndex++ : unpinnedIndex++
    } = tmlColumn || {};

    allColumns.push({ ...column, hidden, position });
  });

  tmlColumns.forEach((column: Column) => {
    const { id } = column;
    const systemColumn = systemColumns.find(
      (column: Column) => column.id === id
    );

    // system columns are added separately, above
    if (systemColumn) {
      return;
    }

    allColumns.push({ ...column });
  });

  return allColumns;
};

const getTmlSettingsAirports = async (
  settings: TmlLogSettings
): Promise<AirportModel[]> => {
  const flightService: IFlightService = AppContainer.get(FlightService);
  return flightService.getAirportsByIds(settings.locations);
};

const makeLogFromAppData = (appData: GridAppModel): TmlLogModel => {
  const { data, id } = appData;
  const { updatedAt, createdAt } = data;
  return new TmlLogModel({ ...data, id, updatedAt, createdAt });
};

export function filterTmlLogEntries(
  entries: TmlEntryModel[],
  filters: Filter[],
  users: Map<string, UserModel>
): TmlEntryModel[] {
  return entries.filter((entry: TmlEntryModel) => {
    const {
      customData,
      locationSegment,
      notes,
      travelSegment,
      transportSegment,
      userId
    } = entry;
    const travelSegmentTime = travelSegment?.date;
    const travelSegmentType = travelSegment?.tripType;

    const user = users.get(userId) || new UserModel();

    return filters.every((filter: Filter) => {
      const { fieldId, value } = filter;

      switch (fieldId) {
        case "keyword":
          const userFields = [user.getFullName(), user.email, notes];
          const travelFields = getSegmentKeywordSearchFields(travelSegment);
          const transportFields = getSegmentKeywordSearchFields(
            transportSegment
          );
          const locationFields = getSegmentKeywordSearchFields(locationSegment);
          const customDataFields = Object.keys(customData).map(
            (key: string) => customData[key]
          );

          return flatten([
            customDataFields,
            locationFields,
            transportFields,
            travelFields,
            userFields
          ])
            .filter((v) => v)
            .some((keywordValue: string) =>
              keywordValue.toLowerCase().includes(String(value).toLowerCase())
            );

        case "locationSegment":
          if (!locationSegment?.value) {
            return false;
          }
          const { tripType } = locationSegment;
          let segmentNames: string[] = [];

          if (tripType === "user") {
            segmentNames = [locationSegment.value as string];
          } else {
            const {
              fromLocation,
              name,
              toLocation
            } = (locationSegment as TmlEntrySegmentAir).value;
            segmentNames = [fromLocation, name, toLocation];
          }

          return segmentNames
            .filter((v) => v)
            .some((segmentName: string) =>
              segmentName.toLowerCase().includes(String(value).toLowerCase())
            );

        case "traveler":
          const userIds = value.map((user: UserModel) => user.id);
          return userIds.includes(userId);
        case "travelDates":
          if (!travelSegmentTime) {
            return false;
          }

          const [startDate, endDate] = value;
          const startTime = getTimestamp(`${startDate}T00:00:00Z`);
          const endTime = getTimestamp(`${endDate}T23:59:59Z`);
          return travelSegmentTime >= startTime && travelSegmentTime <= endTime;

        case "travelSegment":
          if (!travelSegment?.value) {
            return false;
          }

          if (!value?.length) {
            return false;
          }

          const filterAirports = value.map(
            (airport: AirportModel) => airport.iata || airport.name
          );
          let segmentAirports: string[] = [];

          if (travelSegmentType === "user") {
            segmentAirports = [travelSegment.value as string];
          } else if (travelSegmentType === "air") {
            const {
              fromLocation,
              toLocation
            } = (travelSegment as TmlEntrySegmentAir).value;
            segmentAirports = [fromLocation, toLocation];
          }

          return Boolean(
            intersection(
              filterAirports,
              segmentAirports.map(
                (airport: string) => airportToIata(airport) || airport
              )
            ).length
          );
      }

      return true;
    });
  });
}

const getSegmentKeywordSearchFields = (
  segment: TmlEntrySegment | undefined
): string[] => {
  const { tripType, value } = segment || {};

  if (!tripType) {
    return [];
  }

  if (tripType === "user") {
    return [value as string];
  }

  const {
    flightNum,
    fromLocation,
    name,
    toLocation,
    trainNum
  } = value as ObjectHash;

  switch (tripType) {
    case "air":
      return [name, flightNum, fromLocation, toLocation];
    case "car":
    case "hotel":
      return [name, fromLocation, toLocation];
    case "rail":
      return [name, trainNum, fromLocation, toLocation];
  }
};

export function sortTmlLogEntries(
  entries: TmlEntryModel[],
  sort: Sort,
  users: Map<string, UserModel>
): TmlEntryModel[] {
  if (!sort) {
    return entries;
  }

  const { column, direction } = sort;
  const { id } = column;
  const isDesc = direction === "desc";

  switch (id) {
    case "traveler":
      entries.sort((aEntry: TmlEntryModel, bEntry: TmlEntryModel) => {
        const aVal = users.get(aEntry.userId)?.lastName || "";
        const bVal = users.get(bEntry.userId)?.lastName || "";
        return getStringCompareSortValue(aVal, bVal, direction);
      });
      break;

    case "dates":
      entries.sort((aEntry: TmlEntryModel, bEntry: TmlEntryModel) => {
        const aTime = aEntry.travelSegment?.date || 0;
        const bTime = bEntry.travelSegment?.date || 0;

        if (aTime === bTime) {
          return 0;
        }

        if (isDesc) {
          return aTime > bTime ? -1 : 1;
        }

        return aTime > bTime ? 1 : -1;
      });
      break;
  }

  return entries;
}

/*
 * Given a trip, create the sourced entry segment
 */

export function getEntrySegmentFromTrip(
  trip: TripModel,
  segment?: SegmentModel | null
): TmlEntrySegmentTrip {
  const {
    confirmation = "",
    id,
    from = "",
    fromLocation = "",
    name = "",
    type,
    to = "",
    toLocation = ""
  } = trip;
  const tripType = String(type).toLowerCase();

  const entrySegment: ObjectHash = {
    addedByUser: false,
    date: getTimestamp(from),
    tripId: id,
    tripType,
    value: {
      confirmation,
      fromDate: getTimestamp(from),
      fromLocation,
      name: name,
      toDate: getTimestamp(to),
      toLocation
    }
  };

  // Air and Rail trips rely on their segments for names and
  // locations, and may also contain a train or flight number
  if (["air", "rail"].includes(tripType)) {
    const { id, data, flightNum, from, fromLocation, name, toLocation } =
      segment || {};

    entrySegment.date = getTimestamp(from);
    entrySegment.tripSegmentId = id;

    entrySegment.value.fromLocation = fromLocation;
    entrySegment.value.name = name;
    entrySegment.value.toLocation = toLocation;

    if (tripType === "air") {
      entrySegment.value.flightNum = flightNum || "";
    }

    if (tripType === "rail") {
      entrySegment.value.trainNum = data?.trainNum || "";
    }
  }

  return entrySegment as TmlEntrySegmentTrip;
}

/*
 * Load a TML log, and all related data, by grid id
 */
export async function getLogByGridId(
  gridId: string
): Promise<TmlLogModel | null> {
  const apiService: IApiService = AppContainer.get(ApiService);

  const response: GridAppModel = await apiService.get(
    `/grids/${gridId}/apps/travel-movement-log`
  );

  if (!response) {
    return null;
  }

  return makeLogFromAppData(response);
}

/*
 * Pull all trips for a grid, filtered by the TML settings, then group them by user
 */
export async function getLogTrips(
  grid: GridModel,
  settings: TmlLogSettings
): Promise<Map<string, TripModel[]>> {
  const searchService: ISearchService = await AppContainer.get(SearchService);
  const travelQueryService: ITravelQueryService = AppContainer.get(
    TravelQueryService
  );

  // Start by pulling all trips for the grid
  // Note: There is NOT currently a way to optimize or reduce this to a subset, given constraints of the search API
  const { query } = grid;
  const searchParams = travelQueryService.getSearchServiceParams(query);
  searchParams.limit = 1000;

  let logTrips = await searchService.search("trips", searchParams);

  // Filter out trips that do not match the TML Settings date range
  const { dates } = settings;
  const [startTs, endTs] = dates;

  logTrips = logTrips.filter((trip: TripModel) => {
    const { from, to } = trip;
    const fromTs = from ? getTimestamp(from) : 0;
    const toTs = to ? getTimestamp(to) : 0;

    // @todo consider trips without dates?
    if (!fromTs || !toTs) {
      return false;
    }

    return fromTs >= startTs && toTs <= endTs;
  });

  // Group trips by user
  const tripsByUser: Map<string, TripModel[]> = new Map();

  logTrips.forEach((tripData: ObjectHash) => {
    const trip = new TripModel(tripData);
    trip.users.forEach((user: ObjectHash) => {
      const { id } = user;
      const trips = tripsByUser.get(id) || [];
      tripsByUser.set(id, [...trips, cloneDeep(trip)]);
    });
  });

  return tripsByUser;
}

/*
 * Sink grouping
 */
export function getTmlLogEntryGroupId(
  entry: TmlEntryModel,
  group: Group | null,
  user?: UserModel
): string | number {
  const { fieldId } = group || {};

  const { travelSegment, type, userId } = entry;

  switch (fieldId) {
    case "date":
      if (!travelSegment?.date) {
        return 0;
      }
      const compDate = getDisplayDate(travelSegment.date, "yyyy-MM-dd");
      return compDate ? getTimestamp(`${compDate}T00:00:00Z`) : 0;

    case "entryType":
      return type;

    case "location":
      if (!travelSegment) {
        return "";
      }

      if (travelSegment.tripType === "user") {
        return travelSegment.value;
      }

      return travelSegment.value?.toLocation || "";

    case "user":
      return user?.getFullName() || userId;
  }

  return "";
}

/*
 * Determine the index for the given entry in the TmlLogModel.entries array so it can be updated
 */
export function getEntryIndexByUser(
  log: TmlLogModel,
  userId: string,
  type: TmlEntryType
): number {
  return log.entries.findIndex(
    (logEntry: TmlEntryModel) =>
      logEntry.userId === userId && logEntry.type === type
  );
}

export async function updateEntries(
  log: TmlLogModel,
  entries: TmlEntryModel[]
): Promise<TmlLogModel | null> {
  const updatedLog = new TmlLogModel({ ...log });

  entries.forEach((entry: TmlEntryModel) => {
    const { userId, type } = entry;

    const entryIndex = getEntryIndexByUser(updatedLog, userId, type);

    if (entryIndex === -1) {
      return;
    }

    entry.updatedAt = getTimestamp();
    updatedLog.entries[entryIndex] = entry;
  });

  return updateLog(updatedLog);
}

export async function updateEntry(
  log: TmlLogModel,
  entry: TmlEntryModel
): Promise<TmlLogModel | null> {
  return updateEntries(log, [entry]);
}

export async function updateEntryJobTitle(
  log: TmlLogModel,
  entry: TmlEntryModel,
  jobTitle: string
): Promise<TmlLogModel | null> {
  const { entries } = log;
  const updatedEntries: TmlEntryModel[] = [
    new TmlEntryModel({ ...entry, jobTitle })
  ];

  // job title override must always sync between the outbound/inbound entry pair for the given user
  const { type, userId } = entry;
  const pairEntry = entries.find(
    (entry: TmlEntryModel) => entry.userId === userId && entry.type !== type
  );

  if (pairEntry) {
    updatedEntries.push(new TmlEntryModel({ ...pairEntry, jobTitle }));
  }

  return updateEntries(log, updatedEntries);
}

export async function refreshLog(
  grid: GridModel,
  log: TmlLogModel
): Promise<{ log: TmlLogModel; updates: TmlLogUpdate[] }> {
  const refreshLog = new TmlLogModel({ ...log });

  const response: { log: TmlLogModel; updates: TmlLogUpdate[] } = {
    log: refreshLog,
    updates: []
  };

  if (!log.settings.isValid()) {
    return response;
  }

  const latest = await createLog(grid, log.settings, true);
  const latestLog = latest.log;
  const latestTripsByUser = latest.tripsByUser;

  if (!latestLog) {
    return response;
  }

  const removeUserIds: Set<string> = new Set();

  /*
   * Update entry segments with new trip data
   */
  const refreshEntries = refreshLog.entries.map((entry: TmlEntryModel) => {
    const { addedByUser, userId, type } = entry;
    const latestEntryIndex = getEntryIndexByUser(latestLog, userId, type);

    // User is not present in the updated log, they may need to be removed
    if (latestEntryIndex === -1) {
      if (!addedByUser) {
        removeUserIds.add(userId);
      }
      return entry;
    }

    const latestEntry = latestLog.entries[latestEntryIndex];
    const latestTrips = latestTripsByUser.get(userId) || [];

    const updatePayload: TmlLogUpdate = {
      userId,
      entryType: type,
      updateType: "segment/update"
    };

    // Travel segment
    const refreshTravel = refreshEntrySegment(
      entry.travelSegment,
      latestEntry.travelSegment as TmlEntrySegmentTrip,
      latestTrips
    );
    entry.travelSegment = refreshTravel.segment as TmlEntrySegmentTravel;
    if (refreshTravel.updated) {
      response.updates.push({
        ...updatePayload,
        segmentId: "travelSegment"
      });
    }

    // Transport segment
    const refreshTransport = refreshEntrySegment(
      entry.transportSegment,
      latestEntry.transportSegment as TmlEntrySegmentTrip,
      latestTrips
    );
    entry.transportSegment = refreshTransport.segment;
    if (refreshTransport.updated) {
      response.updates.push({
        ...updatePayload,
        segmentId: "transportSegment"
      });
    }

    // Location segment
    const refreshLocation = refreshEntrySegment(
      entry.locationSegment,
      latestEntry.locationSegment as TmlEntrySegmentTrip,
      latestTrips
    );
    entry.locationSegment = refreshLocation.segment;
    if (refreshLocation.updated) {
      response.updates.push({
        ...updatePayload,
        segmentId: "locationSegment"
      });
    }

    return entry;
  });

  /*
   * Remove users not present in the latest log
   */
  Array.from(removeUserIds).forEach((userId: string) => {
    const inboundIndex = getEntryIndexByUser(refreshLog, userId, "inbound");
    const outboundIndex = getEntryIndexByUser(refreshLog, userId, "outbound");

    if (inboundIndex > -1) {
      delete refreshEntries[inboundIndex];
    }

    if (outboundIndex > -1) {
      delete refreshEntries[outboundIndex];
    }

    // This is technically 2 separate actions/changes to the log, but users
    // will consider removing someone from the log as a single action
    if (inboundIndex > -1 || outboundIndex > -1) {
      response.updates.push({
        userId,
        updateType: "user/remove"
      });
    }
  });

  /*
   * Add users not present in the "old" log
   */
  const addUserIds: Set<string> = new Set();
  latestLog.entries.forEach((entry: TmlEntryModel) => {
    const { userId, type } = entry;
    const entryIndex = getEntryIndexByUser(refreshLog, userId, type);

    if (entryIndex === -1) {
      refreshEntries.push(entry);
      addUserIds.add(userId);
    }
  });

  Array.from(addUserIds).forEach((userId: string) => {
    response.updates.push({
      userId,
      updateType: "user/add"
    });
  });

  refreshLog.entries = refreshEntries.filter((v) => v);

  return response;
}

function refreshEntrySegment(
  segment: TmlEntrySegment,
  latestSegment: TmlEntrySegmentTrip,
  trips: TripModel[]
): { segment: TmlEntrySegment; updated: boolean } {
  const response = { segment, updated: false };

  const { addedByUser, tripType } = segment;
  let latestData: ObjectHash = { ...latestSegment };

  // don't change overrides
  if (tripType === "user") {
    return response;
  }

  /*
   * If the TML segment was manually chosen by the user, it may now be based on a different
   * trip than what the refresh data shows. In this case, rather than wiping out the user's
   * selection, look-up the trip the user chose and generate a new latest segment based on that
   */
  if (addedByUser) {
    const { tripId } = segment as TmlEntrySegmentTrip; // we know this is not a user-type segment per the condition above, so consider as a trip-type segment

    let segmentSync = true;
    let trip: TripModel | undefined, tripSegment: SegmentModel | undefined;

    // Have the TML and latest trips diverged? Latest segment is no longer relevant
    if (tripId !== latestData.tripId) {
      segmentSync = false;
      trip = trips.find((trip: TripModel) => trip.id === tripId);
    }

    if (["air", "rail"].includes(tripType)) {
      const airRailTml = segment as TmlEntrySegmentAir | TmlEntrySegmentRail;
      const airRailLatest = latestData as
        | TmlEntrySegmentAir
        | TmlEntrySegmentRail;

      if (airRailTml.tripSegmentId !== airRailLatest.tripSegmentId) {
        segmentSync = false;

        if (trip) {
          tripSegment = trip.segments.find(
            (tripSegment: SegmentModel) =>
              tripSegment.id === airRailTml.tripSegmentId
          );
        }
      }
    }

    // segments are out of sync, try to generate new latest segment
    if (!segmentSync) {
      latestData = trip
        ? getEntrySegmentFromTrip(trip, tripSegment || null)
        : {};
    }
  }

  // TML segment trip data is equal to latest trip data, no updates
  if (
    isEqual(
      { ...segment, addedByUser: false },
      { ...latestData, addedByUser: false }
    )
  ) {
    return response;
  }

  response.segment = { ...(latestData as TmlEntrySegmentTrip), addedByUser };

  response.updated = true;

  return response;
}
