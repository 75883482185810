import { inject } from "inversify";
import BaseService from "./base.service";
import CompanyModel from "../models/company.model";
import { ApiService, IApiService } from "./api.service";

export interface ICompanyService {
  getById(companyId: string): Promise<CompanyModel | null>;
  update(company: CompanyModel): Promise<CompanyModel | null>;
}

export class CompanyService extends BaseService implements ICompanyService {
  @inject(ApiService)
  private apiService!: IApiService;

  async getById(companyId: string): Promise<CompanyModel | null> {
    const response = await this.apiService.get(`/companies/${companyId}`);

    if (!response) {
      return null;
    }

    return new CompanyModel(response);
  }

  async update(company: CompanyModel): Promise<CompanyModel | null> {
    const response = await this.apiService.put(
      `/companies/${company.id}`,
      company
    );

    if (!response) {
      return null;
    }

    return new CompanyModel(response);
  }
}
