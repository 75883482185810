import React from "react";
import AutocompleteInputFilled from "./AutocompleteInputFilled";
import {
  getSelectValueFromOptions,
  SelectOption
} from "../../../utils/helpers";
import "./inputs.scss";

interface Props {
  defaultValue?: any[];
  isRequired?: boolean;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  value?: any;
}

const opts: SelectOption[] = [
  { label: "Female (F)", value: "Female" },
  { label: "Male (M)", value: "male" },
  { label: "Undisclosed (U)", value: "undisclosed" },
  { label: "Unspecified (X)", value: "unspecified" }
];

export default function GenderInput(props: Props) {
  const valueProps = getSelectValueFromOptions(props, opts);
  const acProps = { ...props, ...valueProps };
  return <AutocompleteInputFilled {...acProps} options={opts} />;
}
