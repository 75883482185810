import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Skeleton from "@material-ui/lab/Skeleton";

import AppContainer from "../../../../container";
import CompanySettingsForm from "../forms/company-settings-form";
import ProfileForm from "../forms/profile-form";
import { IUserService, UserService } from "../../../../services/user.service";
import SectionHeader from "../section-header";
import { UserDialogTabProps } from "../";
import UserDialogTitle from "../user-dialog-title";
import UserProfileIcon from "../../../user-profile-icon";
import UserProgramSection from "../sections/UserProgramSection";
import UserDocumentSection from "../sections/UserDocumentSection";
import useModal from "../../../../hooks/use-modal.hook";
import useSnackbar from "../../../../hooks/use-snackbar.hook";

export default function ProfileViewTab(props: UserDialogTabProps) {
  const { menuPortalTarget, onChange, onViewChange, user } = props;
  const loadState = user?.id ? "loaded" : "unloaded";
  const loaded = loadState === "loaded";
  const { closeModal, openModal } = useModal();
  const { setSnackbar } = useSnackbar();
  const userService: IUserService = AppContainer.get(UserService);

  const titleElement = (
    <div className="dialog-title--flex">
      <UserProfileIcon loading={!loaded} user={user} key={user.id} />
      {loaded ? (
        user.getFullName()
      ) : (
        <Skeleton animation="wave" width={240} height={40} />
      )}
    </div>
  );

  const handleDelete = () => {
    if (!user.id) {
      return;
    }

    openModal("confirm", {
      dialogTitle: `Delete Profile`,
      dialogBody: `Are you sure you want to delete this profile? This cannot be undone.`,
      onConfirm: async () => {
        const deletedUser = await userService.delete(user.id);

        if (deletedUser) {
          onChange && onChange();
          closeModal();
          setSnackbar({
            message: `Profile deleted!`,
            variant: "success"
          });
        }
      }
    });
  };

  return (
    <React.Fragment>
      <UserDialogTitle
        {...props}
        deleteTooltip="Delete Profile"
        onDelete={handleDelete}
        title={titleElement}
      />
      <DialogContent
        classes={{ root: "dialog-content" }}
        className="not-draggable"
      >
        <div className="user-dialog__content">
          <SectionHeader
            isDisabled={!loaded}
            title="Personal"
            link="Edit"
            onClick={() => onViewChange("profile-edit")}
          />
          <ProfileForm
            key={`profile-form-readonly-${user.id}`}
            loadState={loadState}
            editing={false}
            menuPortalTarget={menuPortalTarget}
            user={user}
          />
          <SectionHeader
            isDisabled={
              !loaded || Object.keys(user.getValidDocuments()).length === 3
            }
            title="Travel Documents"
            link="Add Document"
            onClick={() => onViewChange("profile-document")}
          />
          <UserDocumentSection
            loading={!loaded}
            user={user}
            onViewChange={onViewChange}
          />
          <SectionHeader
            isDisabled={!loaded}
            title="Loyalty Programs"
            link="Add Program"
            onClick={() => onViewChange("profile-program")}
          />
          <UserProgramSection
            loading={!loaded}
            user={user}
            onViewChange={onViewChange}
          />
          <SectionHeader
            isDisabled={!loaded}
            title="Company Settings"
            link="Edit"
            onClick={() => onViewChange("company-settings-edit")}
          />
          <CompanySettingsForm
            key={`company-settings-form-readonly-${user.id}`}
            loadState={loadState}
            editing={false}
            menuPortalTarget={menuPortalTarget}
            user={user}
          />
        </div>
      </DialogContent>
    </React.Fragment>
  );
}
