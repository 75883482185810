import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import UserModel from "../../../../models/user.model";
import UserDocumentRow from "./UserDocumentRow";

interface Props {
  loading: boolean;
  onViewChange: CallableFunction;
  user: UserModel;
}

export default function UserDocumentSection(props: Props) {
  const { loading, onViewChange, user } = props;

  return (
    <div className="user-dialog-section">
      {Object.keys(user.getValidDocuments()).map(
        (docType: string, index: number) => (
          <UserDocumentRow
            docType={docType}
            loading={loading}
            key={index}
            onViewChange={onViewChange}
          />
        )
      )}
      {Object.keys(user.getValidDocuments()).length === 0 &&
        (loading ? (
          <Skeleton animation="wave" height={80} variant="rect" />
        ) : (
          <div className="user-dialog-section--empty-state">
            You have not added any travel documents.
          </div>
        ))}
    </div>
  );
}
