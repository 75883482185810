export default class Timer {
  private startTimeMs: number = 0;

  start(): void {
    this.startTimeMs = new Date().getTime();
  }

  stop(): number {
    if (!this.startTimeMs) {
      return 0;
    }
    return new Date().getTime() - this.startTimeMs;
  }

  // https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
  static sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
