import React from "react";
import Select from "react-select";
import AppIcon from "../../app-icon";
import {
  getSelectValueProps,
  ObjectHash,
  reactSelectCloseOnScroll
} from "../../../utils/helpers";

import { customStyle, customReactSelectTheme } from "./custom-theme";
import "./inputs.scss";

interface Props {
  defaultValue?: any[];
  isDisabled?: boolean;
  isRequired?: boolean;
  isSearchable?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  onValidate?: CallableFunction;
  options?: any[];
  value?: any[];
}

export default function AutocompleteInputFilled(props: Props) {
  const {
    isDisabled = false,
    isRequired = false,
    isSearchable = false,
    label,
    menuPortalTarget,
    onChange,
    onValidate,
    options = []
  } = props;

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    option,
    menu,
    menuPortal
  };

  const handleChange = (value: any) => {
    onChange(value);
    onValidate && onValidate(value);
  };

  return (
    <div className="autocomplete-input--filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <Select
        {...getSelectValueProps({ ...props, isSearchable: false })}
        components={{
          DropdownIndicator: () => <AppIcon type="menu-arrow" />,
          IndicatorSeparator: () => null
        }}
        className="autocomplete-input"
        classNamePrefix="autocomplete-input"
        closeMenuOnScroll={reactSelectCloseOnScroll}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        menuPortalTarget={menuPortalTarget}
        onChange={(option: any) => handleChange(option?.value)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            handleChange(value);
          }
        }}
        options={options}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
