import React from "react";
import GridModel from "../../models/grid.model";
import { useHistory } from "react-router-dom";

import Button from "../../components/button";
import AutocompleteInput from "../../components/form/inputs/AutocompleteInput";
import GridCard from "./grid-card";
import ObjectTable from "../../components/object-table";
import PageHeader from "../../components/page-header";
import SearchInput from "../../components/search-input";
import Tooltip from "../../components/tooltip";

import { ObjectHash, sortByCreatedAt } from "../../utils/helpers";
import { AppPageProps } from "../../router";
import useModal from "../../hooks/use-modal.hook";

import "./grids-page.scss";

export default function GridsPage(props: AppPageProps) {
  const { user, company, templates, settings } = props;
  const { grids } = settings;
  const history = useHistory();
  const { openModal } = useModal();

  const filterOpts = [
    { label: "My Active Grids", value: "active" },
    { label: "My Starred Grids", value: "starred" },
    { label: "Archived Grids", value: "archived" }
  ];

  const [view, setView] = React.useState("card");
  const [gridStatus, setGridStatus] = React.useState("active");

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = React.useState("");

  let filteredGrids = searchTerm
    ? grids.filter((grid: GridModel) =>
        grid.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : grids;

  const archivedFlag = gridStatus === "archived";
  const starredFlag = gridStatus === "starred";

  filteredGrids = filteredGrids
    .filter(
      (grid: GridModel) =>
        !grid.deleted &&
        grid.archived === archivedFlag &&
        (!starredFlag || (starredFlag && grid.starredBy.includes(user.id)))
    )
    .sort(sortByCreatedAt);

  const statusOpt = filterOpts.find(
    (opt: ObjectHash) => opt.value === gridStatus
  );

  const tableTitle = statusOpt ? statusOpt.label : "All Grids";

  return (
    <div id="grids-page" className="page-container">
      <PageHeader title="All Grids">
        <div className="title-tools">
          <Tooltip text="Card View">
            <Button
              color={view === "card" ? "product-blue" : "gray"}
              icon="grid"
              isRounded={true}
              isTransparent={true}
              onClick={() => {
                setView("card");
              }}
              size="medium"
            />
          </Tooltip>
          <Tooltip text="Table View">
            <Button
              color={view === "table" ? "product-blue" : "gray"}
              icon="table"
              isRounded={true}
              isTransparent={true}
              onClick={() => setView("table")}
              size="medium"
            />
          </Tooltip>
        </div>
      </PageHeader>

      <div className="grids-page-utils">
        <AutocompleteInput
          closeMenuOnScroll={(event: any) => {
            if (event.target.id === "app-page") {
              return event;
            }
            return false;
          }}
          defaultValue={filterOpts[0]}
          isSearchable={false}
          onChange={(value: string) => {
            setGridStatus(value);
          }}
          options={filterOpts}
          menuPortalTarget={document.body}
        />
        <div className="search">
          <SearchInput
            id="grids-page-search-input"
            onInput={(value: string) => {
              setSearchTerm(value);
            }}
            resource="grids"
            keepOpen={false}
          />
          <Button
            color="product-background-blue"
            icon="tg-grid"
            label="New Grid"
            onClick={() =>
              openModal("edit-grid", {
                onChangeGrid: (grid: GridModel) => {
                  if (grid.id) {
                    history.push(`/grids/${grid.id}`);
                  }
                }
              })
            }
            size="medium"
          />
        </div>
      </div>

      {view === "card" && (
        <React.Fragment>
          {!Boolean(filteredGrids.length) && (
            <div className="empty-list-view">No Grids Found</div>
          )}
          <div className="card-list-view">
            {filteredGrids.map((grid: GridModel) => (
              <GridCard grid={grid} key={grid.id} />
            ))}
          </div>
        </React.Fragment>
      )}
      {view === "table" && (
        <ObjectTable
          key={`grids-page-grids-table-${gridStatus}`} // resets selected rows on change of status
          title={tableTitle}
          columns={[
            ["name", "Name"],
            ["id", "ID"]
          ]}
          renderOpts={{
            disableDownload: true,
            disableViewChange: true,
            showQuickAdd: true,
            useCardStyle: true
          }}
          staticResults={filteredGrids}
          searchOpts={{
            filters: [],
            limit: rowsPerPage,
            order: "createdAt",
            resource: "grids",
            reverse: true
          }}
          setRowsPerPage={setRowsPerPage}
          company={company}
          templates={templates}
          settings={settings}
          user={user}
        />
      )}
    </div>
  );
}
