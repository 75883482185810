import React from "react";
import { debounce } from "lodash";
import AsyncCreatableSelect from "react-select/async-creatable";

import Loader from "../../../components/loader";

import AppContainer from "../../../container";
import { IUserService, UserService } from "../../../services/user.service";
import {
  getSelectValueProps,
  ObjectHash,
  reactSelectCloseOnScroll,
  SelectOption
} from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";
import UserModel from "../../../models/user.model";

interface Props {
  isRequired?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  value?: string;
}

export default function TravelerCreateInput(props: Props) {
  const userService: IUserService = AppContainer.get(UserService);

  const { isRequired, label, menuPortalTarget, onChange } = props;

  const getOptions = debounce(
    (inputValue: string, callback: CallableFunction) => {
      if (!inputValue) {
        return Promise.resolve([] as SelectOption[]);
      }

      userService.search(inputValue).then((users: UserModel[]) => {
        const options = users.map((user: UserModel) => ({
          label: user.name,
          value: user.name,
          meta: user
        }));
        return callback(options);
      });
    },
    300
  );

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "0px",
      minHeight: "none",
      "> div": {
        padding: "0",
        height: "24px"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu,
    menuPortal,
    option
  };

  return (
    <div className="autocomplete-input--filled traveler-create-input">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncCreatableSelect
        {...getSelectValueProps(props)}
        cacheOptions
        closeMenuOnScroll={reactSelectCloseOnScroll}
        className="autocomplete-input autocomplete-input--async"
        classNamePrefix="tg-input"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        formatCreateLabel={(label) => `Use custom "${label}"`}
        loadOptions={getOptions}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No travelers found" : null
        }
        onChange={(option: any) => onChange(option?.value, option?.meta)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
