import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";

import {
  filterByKeyword,
  getStaticData,
  StackSort
} from "../../components/stack/helpers";
import CollectionsStackMenu from "./collections-stack-menu";
import KeywordSearchInput from "../../components/stack/table-actions/keyword-search-input";
import Stack from "../../components/stack";
import TagChip from "../../components/tag-chip";
import UserProfileIconList from "../../components/user-profile-icon-list";

import useApp from "../../hooks/use-app.hook";

import CollectionModel from "../../models/collection.model";
import GridModel from "../../models/grid.model";

import { ObjectHash } from "../../utils/helpers";

interface Props {}

export default function CollectionsStack(props: Props) {
  const history = useHistory();
  const { getTagById, settings } = useApp();
  const { collections } = settings;
  const columns = ["name", "gridTags", "users", "tools"];

  const [searchTerm, setSearchTerm] = useState("");
  const hasSearch = Boolean(searchTerm);

  const getStackRows = useCallback(
    (collections: CollectionModel[]) => {
      return collections.map((collection: CollectionModel) => {
        const { id, grids, name, collaborators } = collection;

        const gridTagChips: JSX.Element[] = grids.map((grid: GridModel) => {
          const tag = getTagById(grid.tag.id);
          return <TagChip tag={tag} key={grid.id} />;
        });

        return {
          id,
          name,
          gridTags: grids.length ? <div>{gridTagChips}</div> : null,
          users: <UserProfileIconList users={collaborators} />,
          tools: (
            <div className="tools">
              <CollectionsStackMenu
                key={`collections-stack-${collection.id}`}
                color="gray"
                collection={collection}
              />
            </div>
          )
        };
      });
    },
    [getTagById]
  );

  const handleData = useCallback(
    async (page: number, limit: number, sort: StackSort) => {
      const filterData = collections.filter((collection: CollectionModel) =>
        filterByKeyword(collection, ["name"], searchTerm)
      );
      const pagedResponse = await getStaticData(filterData, page, limit, sort);
      pagedResponse.data = getStackRows(
        pagedResponse.data as CollectionModel[]
      );
      return pagedResponse;
    },
    [collections, getStackRows, searchTerm]
  );

  const title = (
    <KeywordSearchInput
      onChange={(value: string) => {
        setSearchTerm(value);
      }}
      placeholder="Search collections"
      shrink={true}
    />
  );

  return (
    <div id="collection-stack">
      <Stack
        onData={handleData}
        onRowClick={(rowData: ObjectHash) => {
          history.push(`/collections/${rowData.id}`);
        }}
        emptyMessage={
          hasSearch
            ? "There were no collections found that match your search"
            : "You haven't created any collections yet"
        }
        columns={columns}
        hideTableHead={true}
        title={title}
      />
    </div>
  );
}
