import React from "react";
import Button from "../../../button";
import LoyaltyProgramModel from "../../../../models/loyalty-program.model";

interface Props {
  loading: boolean;
  program: LoyaltyProgramModel;
  onViewChange: CallableFunction;
}

export default function UserProgramRow(props: Props) {
  const { program, onViewChange } = props;

  return (
    <div className="user-dialog-section--row">
      <div className="title">{program.company}</div>
      <div className="button">
        <Button
          color="product-background-blue"
          label="View"
          onClick={() => {
            onViewChange("profile-program", { program });
          }}
          size="small"
        />
      </div>
    </div>
  );
}
