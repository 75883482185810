import React, { useState } from "react";
import classnames from "classnames";

interface Props {
  disabled?: boolean;
  on: boolean;
  onChange: CallableFunction;
}

export default function RadioToggleButton(props: Props) {
  const { disabled, on, onChange } = props;
  const [onState, setOnState] = useState<boolean>(disabled ? false : on);

  return (
    <div
      className={classnames("radio-toggle-button", { disabled })}
      onClick={() => {
        if (disabled) {
          return;
        }
        const newState = !onState;
        setOnState(newState);
        onChange(newState);
      }}
    >
      {onState && <div className="inner-circle"></div>}
    </div>
  );
}
