import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Popper from "@material-ui/core/Popper";

import AutocompleteInput from "../../../form/inputs/AutocompleteInput";
import Button from "../../../button";
import CloseButton from "../../../button/close";
import RemoveButton from "../../../button/remove";
import TagSelect from "../../../tag-select";
import Tooltip from "../../../tooltip";

import TagGroupModel from "../../../../models/tag-group.model";
import TagModel from "../../../../models/tag.model";

import "./batch-tags-menu.scss";
import {
  IWorkspaceService,
  WorkspaceService
} from "../../../../services/workspace.service";
import AppContainer from "../../../../container";

interface Props {
  onClickApplyTags: CallableFunction;
  tagGroups: TagGroupModel[];
}

export default function BatchTagsMenu(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTags, setSelectedTags] = React.useState([[new TagModel()]]);
  const [selectedActions, setSelectedActions] = React.useState<string[]>([]);

  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );
  const travelTablePresets = workspaceService.getUserTravelTablePreset();

  const handleClick = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setSelectedTags([[new TagModel()]]); // resets values if popper is closed without applying
    setSelectedActions([]);
    setAnchorEl(null);
  };

  const handleClickApply = () => {
    const tagActions: any = {
      add: [],
      remove: []
    };

    selectedTags.forEach((tags: TagModel[], index) => {
      const tagAction: string = selectedActions[index] || "add";
      tagActions[tagAction] = [...tagActions[tagAction], ...tags];
    });

    props.onClickApplyTags(tagActions);
  };

  const open = Boolean(anchorEl);
  const { tagGroups } = props;

  const selectedTagGroupIds = selectedTags.map(
    (tags: TagModel[]) => tags[0].tagGroup
  );

  let activeTagGroups = tagGroups.filter(
    (tagGroup: TagGroupModel) => selectedTagGroupIds.indexOf(tagGroup.id) === -1
  );

  if (travelTablePresets?.fields?.exclude) {
    activeTagGroups = tagGroups.filter(
      (tagGroup: TagGroupModel) =>
        !travelTablePresets?.fields?.exclude.includes(tagGroup.getFieldId())
    );
  }

  const tagGroupOptions = activeTagGroups.map((tagGroup: TagGroupModel) => {
    return {
      label: tagGroup.name,
      value: tagGroup.id
    };
  });

  const tagActionOptions = [
    { label: "Add", value: "add" },
    { label: "Remove", value: "remove" }
  ];

  return (
    <div className="batch-tags-menu">
      <Tooltip text="Manage tags">
        <div onClick={handleClick}>
          <Button
            color={open ? "product-background-blue" : "black"}
            icon="tag"
            isRippleDisabled={true}
            isTransparent={true}
            size="medium"
          />
        </div>
      </Tooltip>
      <Popper
        id="batch-tags-popper"
        className="batch-tags-popper"
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <div className="batch-tags-popper__content">
          <div className="batch-tags-popper__title">
            <h1>Manage Tags</h1>
            <Tooltip text="Close">
              <CloseButton onClick={() => handleClose()} size="medium" />
            </Tooltip>
          </div>
          <form>
            {selectedTags.map((tags: TagModel[], index: number) => {
              const selectedTagGroupId = selectedTagGroupIds[index];
              const selectedTagGroup = tagGroups.find(
                (tagGroup: TagGroupModel) => tagGroup.id === selectedTagGroupId
              );

              if (!selectedTagGroup && !tagGroupOptions.length) {
                return <React.Fragment key={index}></React.Fragment>;
              }

              return (
                <div key={index} className="batch-tags-popper__input-flex">
                  <FormControl>
                    <AutocompleteInput
                      key={`tag-group-id-input-${index}`}
                      onChange={(value: string) => {
                        selectedTags[index] = [
                          new TagModel({
                            tagGroup: value
                          })
                        ];

                        if (!selectedActions[index]) {
                          selectedActions[index] = "add";
                        }

                        // need to spread here, otherwise react won't pick up on the state change and re-render
                        setSelectedTags([...selectedTags]);
                        setSelectedActions([...selectedActions]);
                      }}
                      options={tagGroupOptions}
                      placeholder="Select Tag Group"
                    />
                  </FormControl>

                  <FormControl className="tag-action-input">
                    <AutocompleteInput
                      defaultValue={tagActionOptions[0]}
                      isSearchable={false}
                      key={`tag-action-input-${index}`}
                      onChange={(value: any) => {
                        selectedActions[index] = value;
                        setSelectedActions(selectedActions);
                      }}
                      options={tagActionOptions}
                    />
                  </FormControl>

                  <FormControl>
                    <TagSelect
                      key={`tag-value-input-${selectedTagGroup?.id}-${index}`}
                      multiSelect={selectedTagGroup?.multiSelect}
                      onChange={(options: any[], tagGroup: TagGroupModel) => {
                        selectedTags[index] = options.length
                          ? options
                          : [new TagModel({ tagGroup: tagGroup.id })];
                        setSelectedTags([...selectedTags]);
                      }}
                      tagGroup={
                        selectedTagGroup
                          ? selectedTagGroup
                          : new TagGroupModel({ mutliSelect: true })
                      }
                      selectedTagIds={tags.map((tag: TagModel) => tag.id)}
                      width={240}
                    />
                  </FormControl>
                  <Tooltip text="Delete row">
                    <RemoveButton
                      key={`delete-tag-${index}`}
                      isDisabled={selectedTags.length <= 1}
                      onClick={() => {
                        const newSelectedTags = [...selectedTags];
                        newSelectedTags.splice(index, 1);

                        const newSelectedActions = [...selectedActions];
                        newSelectedActions.splice(index, 1);

                        setSelectedTags(newSelectedTags);
                        setSelectedActions(newSelectedActions);
                      }}
                      size="small"
                    />
                  </Tooltip>
                </div>
              );
            })}
          </form>
          <div className="batch-tags-popper__buttons">
            <Button
              color="product-blue"
              icon="plus-circle"
              isDisabled={!tagGroupOptions.length}
              isRippleDisabled={true}
              isTransparent={true}
              label="Add Another Tag"
              onClick={() => {
                selectedTags.push([new TagModel()]);
                setSelectedTags([...selectedTags]);
              }}
              size="medium"
            />
            <Button
              color="product-blue"
              isDisabled={
                !selectedTags.filter((tags: TagModel[]) =>
                  tags.filter((tag: TagModel) => tag.id)
                ).length
              }
              label={selectedTags.length > 1 ? "Apply Tags" : "Apply Tag"}
              onClick={() => {
                handleClickApply();
                handleClose();
              }}
              size="medium"
            />
          </div>
        </div>
      </Popper>
    </div>
  );
}
