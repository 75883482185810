import React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
  skeletonComponent: JSX.Element;
}

export default function SkeletonLoader(props: Props) {
  const { children, loading, skeletonComponent } = props;

  return (
    <React.Fragment>{loading ? skeletonComponent : children}</React.Fragment>
  );
}
