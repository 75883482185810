import React, { useState } from "react";
import { camelCase, startCase } from "lodash";

import AppIcon from "../../components/app-icon";
import TextInput from "../../components/form/inputs/TextInput";
import ToggleSwitch from "../../components/toggle-switch";

import AppContainer from "../../container";
import useSnackbar from "../../hooks/use-snackbar.hook";
import {
  CompanyService,
  ICompanyService
} from "../../services/company.service";
import CompanyModel from "../../models/company.model";

interface Props {
  company: CompanyModel;
}

const CompanyFeatures = (props: Props) => {
  const { company } = props;
  const companyService: ICompanyService = AppContainer.get(CompanyService);
  const { setSnackbar } = useSnackbar();

  const [newFeature, setNewFeature] = useState<{ key: string; value: boolean }>(
    {
      key: "",
      value: false
    }
  );

  const addNewFeature = () => {
    const newFeatureKey = camelCase(newFeature.key);
    if (!newFeatureKey || company.features.hasOwnProperty(newFeatureKey)) {
      setSnackbar({
        message: "Empty/existing feature key",
        variant: "error"
      });
      return;
    }

    updateCompanyFeature(newFeatureKey, newFeature.value);
    setNewFeature({ key: "", value: false });
  };

  const updateCompanyFeature = async (
    featureKey: string,
    selected: boolean
  ) => {
    company.features[featureKey] = selected;
    const response = await companyService.update(company);
    if (!response) {
      setSnackbar({
        message: "There was an error and the Company was not updated.",
        variant: "error"
      });
      return;
    }
  };

  return (
    <div className="company-features">
      <h1
        className="circular-bold"
        style={{ fontSize: 20, display: "block", marginBottom: 24 }}
      >
        Company Features
      </h1>
      {Object.keys(company.features).map((featureKey: string) => {
        const feature = company.features[featureKey];

        return (
          <div key={featureKey} className="feature-row">
            <div className="feature-name">{startCase(featureKey)}</div>
            <ToggleSwitch
              on={feature}
              onChange={(selected: boolean) =>
                updateCompanyFeature(featureKey, selected)
              }
            />
            <div></div>
          </div>
        );
      })}

      <div className="feature-row">
        <div className="feature-name">
          <TextInput
            placeholder={"New feature key"}
            value={newFeature.key}
            onChange={(value: string) =>
              setNewFeature({ ...newFeature, key: value })
            }
          />
        </div>
        <ToggleSwitch
          on={false}
          onChange={(selected: boolean) =>
            setNewFeature({ ...newFeature, value: selected })
          }
        />
        <div>
          <span className="add-button" onClick={addNewFeature}>
            <AppIcon type="plus" color="product-blue" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyFeatures;
