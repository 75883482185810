import React from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import AppIcon, { AppIconType } from "../app-icon";

interface Props {
  alias?: string;
  href: string;
  icon: AppIconType;
  label: string;
  onClick?: CallableFunction;
}

export default function AppSidebarLink(props: Props) {
  const { alias, href, icon, label, onClick } = props;
  const location = useLocation();

  const active = [href, alias].includes(location.pathname);

  return (
    <Link
      to={href}
      className={classnames("app-sidebar-link", icon, { active })}
      onClick={() => onClick && onClick()}
    >
      <span className="icon-wrapper">
        <AppIcon type={icon}></AppIcon>
      </span>
      <span className="label">{label}</span>
    </Link>
  );
}
