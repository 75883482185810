import { airportToIata, ObjectHash } from "../utils/helpers";
import BaseModel from "./base.model";
import TagModel from "./tag.model";
import UserModel from "./user.model";
import TravelQueryModel, { TravelQueryParam } from "./travel-query.model";

export default class GridModel extends BaseModel {
  id: string;
  company: string;
  name: string;
  creator: string; // user of id of grid creator
  users: UserModel[]; // user ids of collaborators
  starredBy: string[]; // array of user ids
  tag: TagModel;
  travelers: string[];
  query: TravelQueryModel;
  mailbox: string;

  archived: boolean;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.company = data.company;
    this.name = data.name;
    this.creator = data.creator;
    this.users = data.users
      ? data.users.map((user: ObjectHash) => new UserModel(user))
      : [];
    this.starredBy = data.starredBy ?? [];
    this.tag = new TagModel(data.tag);
    this.travelers = data.travelers ?? [];
    this.query = new TravelQueryModel(data.query);
    this.mailbox = data.mailbox;

    this.archived = Boolean(data.archived);
    this.deleted = Boolean(data.deleted);
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  getEmailForAppDomain(domain: string): string {
    if (!this.mailbox) {
      return "";
    }

    return `${this.mailbox}@${domain}`;
  }

  getFilterDestinationAirports(): string[] {
    const arrivalAirportParam = this.query.params.find(
      (param: TravelQueryParam) => param.field === "airport-arrive"
    );

    if (!arrivalAirportParam?.value.length) {
      return [];
    }

    return arrivalAirportParam.value.map(
      (airport: string) => airportToIata(airport) || airport
    );
  }

  getFilterStartEndDates(): { start: string; end: string } {
    const dates = { start: "", end: "" };

    const startDateParam = this.query.params.find(
      (param: TravelQueryParam) => param.field === "travel-date-after"
    );

    if (startDateParam) {
      dates.start = startDateParam.value;
    }

    const endDateParam = this.query.params.find(
      (param: TravelQueryParam) => param.field === "travel-date-before"
    );

    if (endDateParam) {
      dates.end = endDateParam.value;
    }

    return dates;
  }
}

export class GridDetailModel extends BaseModel {
  startDateTime: string;
  endDateTime: string;
  destinationAirports: string[];
  totalTrips: number;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.startDateTime = data.startDateTime;
    this.endDateTime = data.endDateTime;
    this.destinationAirports = data.destinationAirports ?? [];
    this.totalTrips = data.totalTrips;
  }
}
