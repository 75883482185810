// this component loads dynamic titles into the html head when routing around app for the browser tab display
import React from "react";
import Helmet from "react-helmet";

export interface Props {
  title?: string;
}

const HtmlTitle = (props: Props) => {
  const { title } = props;
  const titleText = `Tripgrid${title ? ` - ${title}` : ""}`;

  return (
    <Helmet>
      <title>{titleText}</title>
    </Helmet>
  );
};

export default HtmlTitle;
