import React, { createContext, useContext, useState, ReactNode } from "react";
import Snackbar, {
  Props as SnackbarProps,
  SnackbarVariant
} from "../../components/snackbar";
import { ObjectHash } from "../../utils/helpers";

export interface ISnackbarState {
  setSnackbar: CallableFunction;
  snackbar: ObjectHash;
}

export const SnackbarContext = createContext<ISnackbarState>({
  setSnackbar: (opts: { message: string; variant: SnackbarVariant }) => {},
  snackbar: {}
});

export function SnackbarProvider({ children }: { children: ReactNode }) {
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    duration: 0,
    message: "",
    variant: "success"
  });

  return (
    <SnackbarContext.Provider value={{ snackbar, setSnackbar }}>
      <Snackbar
        duration={snackbar.duration}
        message={snackbar.message}
        variant={snackbar.variant}
      />
      {children}
    </SnackbarContext.Provider>
  );
}

// wrap class components with this to access the useContext hook functionality
export const withSnackbarContext = (Component: any) => {
  return (props: any) => {
    const { setSnackbar } = useContext(SnackbarContext);

    return <Component setSnackbar={setSnackbar} {...props} />;
  };
};
