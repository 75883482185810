import BaseModel from "./base.model";
import GridModel from "./grid.model";
import { ObjectHash } from "../utils/helpers";
import UserModel from "./user.model";

export default class CollectionModel extends BaseModel {
  id: string;
  bookingRequestCount: number;
  budget: string;
  collaborators: UserModel[];
  company: string;
  creator: UserModel;
  defaultBookingValues: CollectionDefaultsModel;
  expectedTravelers: number;
  grids: GridModel[];
  name: string;
  notes: string; // in lieu of attachments
  primaryContact: string;

  deleted: boolean;
  createdAt: string;
  updatedAt: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.bookingRequestCount = data.bookingRequestCount || 0;
    this.budget = data.budget;
    this.collaborators = data.collaborators
      ? data.collaborators.map((user: ObjectHash) => new UserModel(user))
      : [];
    this.company = data.company;
    this.creator = new UserModel(data.creator);
    this.defaultBookingValues = new CollectionDefaultsModel(
      data.defaultBookingValues
    );
    this.expectedTravelers = data.expectedTravelers;
    this.grids = data.grids
      ? data.grids.map(
          (gridData: ObjectHash) =>
            new GridModel({ ...gridData, tag: { id: gridData.tag } })
        )
      : [];
    this.name = data.name;
    this.notes = data.notes;
    this.primaryContact = data.primaryContact;

    this.deleted = Boolean(data.deleted);
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

export class CollectionDefaultsModel extends BaseModel {
  bookingDates: Date[];
  endDesiredTime: string;
  endLocation: string;
  notes: string;
  startDesiredTime: string;
  startLocation: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.bookingDates = data.bookingDates;
    this.endDesiredTime = data.endDesiredTime;
    this.endLocation = data.endLocation;
    this.notes = data.notes;
    this.startDesiredTime = data.startDesiredTime;
    this.startLocation = data.startLocation;
  }
}
