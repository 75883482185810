import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";

import AppIcon from "../../../../components/app-icon";
import Button from "../../../../components/button";
import Tooltip from "../../../../components/tooltip";
import { blockTypesHash } from "../../blocks/block-types";
import { ObjectHash } from "../../../../utils/helpers";

import "./add-block-menu.scss";

interface Props {
  blockId: string;
  innerBlockId?: string;
  isDisabled?: boolean;
  onAddBlock: CallableFunction;
  onAddMultiBlock: CallableFunction;
}

export default function AddBlockMenu(props: Props) {
  let blockTypes = blockTypesHash;

  const { innerBlockId, isDisabled, onAddBlock, onAddMultiBlock } = props;

  // filter add block menu options for multiBlock inner block
  if (innerBlockId !== undefined) {
    const multiBlockSubTypes = ["h1", "p", "attachment", "image", "map"]; // subset of basic blocks allowed in multi block row
    blockTypes = {
      "Basic Blocks": blockTypesHash[
        "Basic Blocks"
      ].filter((block: ObjectHash) =>
        multiBlockSubTypes.includes(block.subType)
      )
    };
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleBlockSelect = (type: string, subType: string) => {
    if (innerBlockId !== undefined) {
      onAddMultiBlock(type, subType);
    } else {
      onAddBlock(type, subType);
    }
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popperClass = open
    ? "add-block-popper add-block-popper--open"
    : "add-block-popper";

  return (
    <div className="add-block-menu">
      <div className="add-block-menu-button">
        <Tooltip
          text={`Add block ${innerBlockId === undefined ? "above" : "left"}`}
        >
          <div onClick={handleClick}>
            <Button
              color="product-blue"
              icon="plus"
              isTransparent={true}
              isDisabled={isDisabled || false}
              isRippleDisabled={true}
              isRounded={true}
              label={innerBlockId ? "" : "Add New Block"}
              size="medium"
            />
          </div>
        </Tooltip>
        <Popper
          anchorEl={anchorEl}
          className={popperClass}
          id="add-block-popper"
          modifiers={{
            flip: {
              enabled: true,
              behavior: ["bottom", "top"]
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport"
            }
          }}
          open={open}
          placement="right-start"
          transition
        >
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="add-block-popper__content">
              {Object.entries(blockTypes).map(([blockGroup, blocks]: any) => (
                <div className="add-block-popper__block-group" key={blockGroup}>
                  <h1>{blockGroup}</h1>
                  {blocks.map((block: any) => (
                    <div
                      className="add-block-popper__option"
                      key={block.label}
                      onClick={() =>
                        handleBlockSelect(block.type, block.subType)
                      }
                    >
                      <div className="add-block-popper__icon">
                        <AppIcon type={block.iconType} />
                      </div>
                      <div className="add-block-popper__text">
                        <h2>{block.label}</h2>
                        <p>{block.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </ClickAwayListener>
        </Popper>
      </div>
    </div>
  );
}
