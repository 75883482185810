import { ITravelState } from "./index";

export type TravelAction = {
  type: TravelActionTypes;
  payload?: any;
};

export enum TravelActionTypes {
  SubscribedTravel = "travel/subscribed",
  UpdateBookingRequest = "bookingrequest/update",
  IntegrationInstances = "travel/integration-instances"
}

export const TravelReducer = (
  state: ITravelState,
  action: TravelAction
): ITravelState => {
  const { payload, type } = action;
  switch (type) {
    case TravelActionTypes.SubscribedTravel:
      return { ...state, subscribed: true };

    case TravelActionTypes.IntegrationInstances:
      const { parsedEmails, integrationInstances } = payload;
      return { ...state, parsedEmails, integrationInstances };

    case TravelActionTypes.UpdateBookingRequest:
      return { ...state, lastBookingRequest: payload };

    default:
      return { ...state };
  }
};
