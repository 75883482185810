import React from "react";
import { debounce } from "lodash";

import CustomToolbar from "../custom-toolbar";
import CustomToolbarSelect from "../custom-toolbar-select";
import Loader from "../../loader";
import TextInput from "../../form/inputs/TextInput";

import AppContainer from "../../../container";
import { ITagService, TagService } from "../../../services/tag.service";
import {
  IWorkspaceService,
  WorkspaceService
} from "../../../services/workspace.service";

import TagGroupModel from "../../../models/tag-group.model";

import { getDisplayDate, ObjectHash } from "../../../utils/helpers";
import { handleRowClick } from "../table-funcs/helper-funcs";

import {
  customRenderCols,
  disableDownloadCols,
  getConfirmationForDocument,
  getCheckmark,
  getDocumentRowMenu,
  getDocumentTableHeader,
  getIcon,
  getTagChip,
  getPriceForDocument,
  getTravelIcon,
  getUsersForDocument,
  getUserFullName,
  parseResults
} from "../table-funcs/render-funcs";

export const StaticDocumentTableWrapper = (
  vState: { [index: string]: any },
  setState: CallableFunction,
  params: { [index: string]: any }
) => {
  const tagService: ITagService = AppContainer.get(TagService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const {
    doSearch,
    documentVisibleCols,
    id,
    onChangeTableBlockColor,
    onChangeTableBlockCols,
    onChangeTableBlockColOrder,
    onChangeTableBlockDataOrder,
    onChangeTableBlockFilter,
    onChangeTableBlockFutureOnly,
    onChangeTableBlockHiddenTrips,
    onChangeTableBlockSort,
    onChangeTableBlockTitle,
    onChangeTableBlockUsers,
    onResetCustomDataOrder,
    inTableFilters,
    inTableSorters,
    searchOpts,
    template,
    titleInputWidth
  } = vState;

  const { resource, viewStateKey } = searchOpts;
  const {
    blockState,
    history,
    isLoading,
    location, // React Router withRouter prop
    locationsData, // Provides select dropdown options for quick add when using static data
    openModal,
    renderOpts = {},
    staticResults,
    title,
    printMode = false,
    viewMode = false,
    settings
  } = params || {};

  let { results } = vState;

  const columns = vState.columns || params.columns;

  if (blockState) {
    if (blockState.block.options.columnOrder.length) {
      columns.sort((a: any[], b: any[]) => {
        const aIndex = blockState.block.options.columnOrder.indexOf(a[0]);
        const bIndex = blockState.block.options.columnOrder.indexOf(b[0]);

        if (aIndex < bIndex) {
          return -1;
        }
        if (aIndex > bIndex) {
          return 1;
        }
        return 0;
      });
    }
  }

  if (staticResults) {
    results = staticResults;
  }

  let tableTitle = title;

  const colKeys = columns.map((c: any[]) => c[0]);

  // set user defined display cols
  let colsToDisplay = documentVisibleCols || colKeys;

  // convert legacy identifier column settings over to tag column ids
  colsToDisplay = tagService.mapIdentifiersColumnIds(
    colsToDisplay,
    settings.tagGroups
  );

  const tableCols = columns.map((column: any[]) => {
    const [fieldName, label] = column;
    const tableCol: { [index: string]: any } = {
      label: label.length ? label : " ",
      name: fieldName,
      options: { sort: true }
    };

    // disable sorting of cols on doc tables
    tableCol.options.sort = false;

    // enable display of cols
    if (
      renderOpts.disableViewChange ||
      colsToDisplay.includes(fieldName) ||
      customRenderCols.includes(fieldName)
    ) {
      tableCol.options.display = true;
    } else {
      tableCol.options.display = false;
    }
    // disable csv export of cols
    if (
      disableDownloadCols.includes(fieldName) ||
      !colsToDisplay.includes(fieldName)
    ) {
      tableCol.options.download = false;
    }
    // hide cols from viewColumns menu
    if (customRenderCols.includes(fieldName)) {
      tableCol.options.viewColumns = false;
    }
    // disallow hiding of column that is being used to sort in document table
    if (
      blockState.block.options.sorters
        .map((sorter: any[]) => sorter[0])
        .includes(fieldName)
    ) {
      tableCol.options.viewColumns = false;
    }
    // hide id col from display and viewColumns menu on non-admin pages
    if (
      (fieldName === "id" && location.pathname !== "/admin") ||
      fieldName === "userId"
    ) {
      tableCol.options.display = "excluded";
    }
    // use customHeadRender to display React component for document builder table
    tableCol.options.customHeadRender = (columnMeta: {
      [index: string]: any;
    }) =>
      getDocumentTableHeader(
        columns,
        documentVisibleCols,
        columnMeta,
        onChangeTableBlockColOrder,
        results,
        template
      );
    // use customBodyRender to display React component or special display value in td cell
    if (fieldName && fieldName.endsWith("Date")) {
      tableCol.options.customBodyRender = (value: string) =>
        getDisplayDate(value, settings.dateFormat);
    }
    if (
      ["arrivalTime", "departureTime", "from", "to"].includes(fieldName) ||
      (fieldName && fieldName.endsWith("DateTime"))
    ) {
      tableCol.options.customBodyRender = (value: string) =>
        getDisplayDate(value, settings.dateTimeFormat);
    }
    if (
      [
        "hasAccommodation",
        "hasInbound",
        "hasOutbound",
        "hasTransportation"
      ].includes(fieldName)
    ) {
      tableCol.options.customBodyRender = (value: string) =>
        getTravelIcon(value, fieldName);
    }
    // use customBodyRender to merge confirmation cells in document builder table
    if (fieldName === "confirmation") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: { [index: string]: any }
      ) =>
        getConfirmationForDocument(
          tableMeta,
          results,
          colKeys,
          blockState.block.options.hiddenTrips || []
        );
    }
    if (fieldName === "documentRowMenu") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: { [index: string]: any }
      ) => {
        if (tableMeta.rowData) {
          return getDocumentRowMenu(
            tableMeta,
            colKeys,
            onChangeTableBlockDataOrder,
            blockState.block.options.hiddenTrips,
            onChangeTableBlockHiddenTrips,
            blockState.block.options.users,
            onChangeTableBlockUsers,
            staticResults
          );
        }
        return "";
      };
    }
    if (fieldName === "icon") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: { [index: string]: any }
      ) => getIcon(tableMeta, colKeys);
    }
    if (["blocked", "primary"].includes(fieldName)) {
      tableCol.options.customBodyRender = (
        value: boolean,
        tableMeta: { [index: string]: any }
      ) => getCheckmark(value);
    }
    if (
      (
        ((template || {}).properties || []).find(
          (p: { [index: string]: any }) => p.name === fieldName
        ) || {}
      ).propertyType === "currency"
    ) {
      // use customBodyRender to merge price cells in document builder table
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: { [index: string]: any }
      ) =>
        getPriceForDocument(
          tableMeta,
          results,
          colKeys,
          blockState.block.options.hiddenTrips || []
        );
    }
    if (fieldName === "users") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: { [index: string]: any }
      ) =>
        getUsersForDocument(
          tableMeta,
          results,
          colKeys,
          blockState.block.options.hiddenTrips || []
        );
    }

    if (["travelers", "users"].includes(resource) && fieldName === "name") {
      tableCol.options.customBodyRender = (
        value: string,
        tableMeta: ObjectHash
      ) => getUserFullName(value, tableMeta, colKeys, results);
    }

    // Tags as columns
    if (TagGroupModel.isFieldId(fieldName)) {
      tableCol.options.customBodyRender = (
        values: ObjectHash[],
        tableMeta: ObjectHash
      ) => {
        if (!values || !Array.isArray(values)) {
          return "";
        }
        return getTagChip(values, true);
      };
    }

    return tableCol;
  });

  let tableData: any[] = [];

  if (results) {
    tableData = parseResults(results, colKeys, template);
  }

  let selectableRows = renderOpts.disableRowSelect ? "none" : "multiple";

  let isRowSelectable: CallableFunction = () => true;
  let setRowProps = null;
  if (selectableRows !== "none") {
    setRowProps = (row: string[]) => {
      const rowProps: { [index: string]: any } = {};
      const dataId = row[colKeys.indexOf("id")];
      const data = results.find((row: ObjectHash) => row.id === dataId);

      if (data && workspaceService.dataIsFrozen(data)) {
        rowProps.className = "mui-data-table__row--disabled";
      }

      if (data?.cancelled) {
        rowProps.className = "mui-data-table__row--cancelled";
      }

      return rowProps;
    };
    isRowSelectable = (dataIndex: number) => {
      const dataId = tableData[dataIndex][colKeys.indexOf("id")];
      const data = results.find((row: ObjectHash) => row.id === dataId);
      if (data && workspaceService.dataIsFrozen(data)) {
        return false;
      }
      return true;
    };
  }

  // applies class to hide row in document builder table
  setRowProps = (row: string[]) => {
    const rowProps: { [index: string]: any } = {};
    const dataId = row[colKeys.indexOf("id")];
    let hiddenClass = "";
    if (Object.hasOwnProperty.call(blockState.block.options, "hiddenTrips")) {
      hiddenClass = blockState.block.options.hiddenTrips.includes(dataId)
        ? "mui-data-table__row--hidden"
        : "";
    }
    rowProps.className = `mui-data-table__row--with-document-row-menu ${hiddenClass}`;
    return rowProps;
  };

  const tableOpts: { [index: string]: any } = {
    customSort: (data: any, colIndex: number, order: string) => data,
    customToolbarSelect: () => (
      <CustomToolbarSelect
        displayData={tableData}
        doSearch={renderOpts.updateStaticResults || doSearch}
        idColIndex={colKeys.indexOf("id")}
        resource={resource}
        results={results}
        selectedRows={vState.selectedRows}
        setState={setState}
        tagId={id}
        tableTitle={title}
        settings={settings}
      />
    ),
    download: !renderOpts.disableDownload,
    downloadOptions: {
      filename: `${title}.csv`
    },
    filter: false,
    filterType: "textField",
    isRowSelectable,
    onDownload: (
      buildHead: CallableFunction,
      buildBody: CallableFunction,
      cols: any[],
      dataRows: any[]
    ) => {
      try {
        const head = buildHead(
          cols.map((c) => ({ ...c, name: c.label || c.name }))
        );

        const tagColumnIndexes: number[] = [];
        cols.forEach((col: ObjectHash, index: number) => {
          if (TagGroupModel.isFieldId(col.name)) {
            tagColumnIndexes.push(index);
          }
        });

        const body = buildBody(
          dataRows.map((row) => ({
            ...row,
            data: row.data.slice().map((d: ObjectHash, index: number) => {
              if (tagColumnIndexes.indexOf(index) !== -1) {
                return d.map((tag: any) => tag.name);
              }
              if (d && d.props && d.props.children) {
                return d.props.children;
              }
              return d;
            })
          }))
        );
        return `${head}${body}`;
      } catch (e) {
        return false;
      }
    },
    onRowSelectionChange: (
      currentRowsSelected: any[],
      allRowsSelected: any[]
    ) => {
      setState({
        selectedRows: allRowsSelected.map((row) => row.dataIndex).sort()
      });
    },
    pagination: !renderOpts.disablePagination,
    print: false,
    responsive: "standard",
    rowsPerPageOptions: [10, 20, 50, 100],
    rowsSelected: vState.selectedRows,
    search: false,
    selectableRows,
    serverSide: true,
    setRowProps,
    sort: !renderOpts.disableSort,
    sortOrder: {},
    textLabels: {
      body: {
        noMatch: ""
      },
      selectedRows: {
        text: "selected"
      },
      toolbar: {
        viewColumns: "Properties"
      }
    },
    viewColumns: !renderOpts.disableViewChange
  };

  tableOpts.customToolbarSelect.displayName = "CustomToolbarSelect";

  if (
    renderOpts.showColorMenu ||
    renderOpts.showQuickAdd ||
    renderOpts.showFilterMenu ||
    renderOpts.showSortMenu ||
    renderOpts.showUserMenu
  ) {
    const displayedCols = tableCols
      .filter((col: { [index: string]: any }) => col.options.display === true)
      .map((col: { [index: string]: any }) => col.name);
    const visibleCols = columns.filter(
      (col: { [index: string]: any }) =>
        col[0] !== "documentRowMenu" && displayedCols.includes(col[0])
    );

    let handleApplyFilter: CallableFunction = function () {};
    let handleApplySort: CallableFunction = function () {};

    if (staticResults) {
      handleApplyFilter = (filtersHash: { [index: string]: any }) => {
        const filters = Object.entries(filtersHash).filter(
          (kv) => !["from", "to"].includes(kv[0])
        );

        onChangeTableBlockFilter(filters);
      };

      handleApplySort = (sortersToApply: { [index: string]: any }) => {
        onChangeTableBlockSort(sortersToApply);
      };
    }

    tableOpts.customToolbar = () => (
      <CustomToolbar
        allTrips={blockState ? blockState.allTrips : null}
        customDataOrder={
          blockState ? blockState.block.options.customDataOrder : []
        }
        data={staticResults || results}
        dataType={viewStateKey ? viewStateKey.split("-")[1].toUpperCase() : ""}
        doSearch={renderOpts.updateStaticResults || doSearch}
        futureOnly={blockState ? blockState.block.options.futureOnly : false}
        locationsData={locationsData}
        tableFiltersState={inTableFilters}
        tableSortersState={inTableSorters}
        visibleCols={visibleCols}
        onApplyFilter={handleApplyFilter}
        onApplySort={handleApplySort}
        onChangeTableBlockColor={onChangeTableBlockColor}
        onChangeTableBlockFutureOnly={onChangeTableBlockFutureOnly}
        onChangeTableBlockUsers={onChangeTableBlockUsers}
        onResetCustomDataOrder={onResetCustomDataOrder}
        renderOpts={renderOpts}
        resource={resource}
        tagId={id}
      />
    );
    tableOpts.customToolbar.displayName = "CustomToolbar";
  }

  const changeColumnView = (cols: any[]) => {
    const showColsIndexes: any[] = [];

    cols
      .map((o) => o.display)
      .forEach((displayOpt, index) => {
        if (displayOpt === "true") {
          showColsIndexes.push(index);
        }
      });

    const newFields: any[] = showColsIndexes.map((i) => columns[i][0]);

    const newColumns = cols
      .filter((c) => c.display === "true")
      .map((c) => [c.name, c.label]);
    onChangeTableBlockCols(newColumns);
    setState({ documentVisibleCols: ["id", ...newFields] });

    vState.searchOpts.fields = newFields;
  };

  if (staticResults) {
    const changeDownloadOpt = (cols: any[]) => {
      cols.forEach((col) => {
        if (col.display === "false") {
          col.download = false;
        }
        if (col.display === "true" && !disableDownloadCols.includes(col.name)) {
          col.download = true;
        }
      });
    };

    tableOpts.count = results.length;
    tableOpts.onTableChange = (
      action: string,
      tableState: { [index: string]: any }
    ) => {
      switch (action) {
        case "viewColumnsChange":
          changeColumnView(tableState.columns);
          changeDownloadOpt(tableState.columns);
          break;

        case "changeRowsPerPage":
          vState.rowsPerPage = tableState.rowsPerPage;
          break;

        default:
        // do nothing
      }
    };
    if (vState.rowsPerPage) {
      tableOpts.rowsPerPage = vState.rowsPerPage;
    } else {
      tableOpts.rowsPerPage = resource === "trips" ? 10 : 100;
    }
    tableOpts.serverSide = false;

    // Document table title includes input to allow custom title
    tableTitle = (
      <div className="table-title">
        <div className="table-title__name">
          <span
            id={`input-expander-${id}`}
            style={{
              padding: "0 8px 0 0",
              position: "absolute",
              letterSpacing: "0",
              fontSize: "20px",
              height: "0",
              overflow: "hidden",
              whiteSpace: "pre"
            }}
          >
            {title}
          </span>
          <TextInput
            disabled={viewMode}
            margin="dense"
            onChange={debounce((v: string) => {
              onChangeTableBlockTitle(v);
            }, 300)}
            style={{
              width: `${titleInputWidth}px`
            }}
            defaultValue={title}
          />
        </div>
        <span className="table-title__results">( {results.length} )</span>
        {(staticResults && isLoading) || vState.isSoftLoading ? (
          <Loader type="spinner" />
        ) : null}
      </div>
    );
  }

  if (!renderOpts.disableRowClick) {
    tableOpts.onRowClick = (
      rowData: any[],
      rowMeta: { [index: string]: any }
    ) => {
      try {
        const dataId = tableData[rowMeta.dataIndex][colKeys.indexOf("id")];
        if (dataId) {
          const data = results.find(
            (obj: { [index: string]: any }) => obj.id === dataId
          );
          if (data && !printMode && !viewMode) {
            if (renderOpts.customRowClick) {
              renderOpts.customRowClick(data);
            } else {
              handleRowClick(
                data,
                resource,
                history,
                renderOpts.updateStaticResults || doSearch,
                locationsData || [],
                openModal
              );
            }
          }
        }
      } catch (e) {
        // ignore error
      }
    };
  }

  const muiDataTableProps = {
    data: tableData,
    title: tableTitle,
    columns: tableCols,
    options: tableOpts
  };

  return {
    blockState,
    muiDataTableProps,
    renderOpts,
    title
  };
};
