import React from "react";
import Select from "react-select";
import Button from "..";
import TagModel from "../../../models/tag.model";

import {
  customReactSelectTheme,
  customStyle
} from "../../form/inputs/custom-theme";
import useModal from "../../../hooks/use-modal.hook";
import { ObjectHash } from "../../../utils/helpers";
import { AppContext } from "../../../context";

export interface Props {
  gridId?: string;
  locationsData?: any[]; // static data tables feed this in so the quick add modal so dropdowns can create default options from the existing dataset for faster manual entry (address fields)
  tagPreset?: TagModel[];
}

const BuildTravelButton = (props: Props) => {
  const { gridId, locationsData = [], tagPreset } = props;
  const [inputKey, setInputKey] = React.useState(0);
  const { openModal } = useModal();

  const { menu, menuPortal, option, singleValue } = customStyle;

  const optionStyles: ObjectHash = {
    menu: (styles: ObjectHash) => ({
      ...menu(styles),
      width: 152
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      boxShadow: "none",
      padding: "0",
      border: "none",
      "> div": {
        padding: "0"
      }
    }),
    option,
    singleValue,
    menuPortal
  };

  const options = [
    { value: "AIR", label: "Flight" },
    { value: "CAR", label: "Transportation" },
    { value: "HOTEL", label: "Accommodation" },
    { value: "RAIL", label: "Rail" }
  ];

  if (gridId) {
    options.push(
      { value: "ACTIVITY", label: "Event" },
      {
        value: "ROOM_BLOCK",
        label: "Room Block"
      }
    );
  }

  const onChange = (type: string) => {
    if (type === "ROOM_BLOCK") {
      const payload = {
        from: Date.now(),
        gridId,
        to: Date.now() + 60 * 1000,
        type
      };

      return openModal("room-block", {
        payload
      });
    }

    openModal("quick-add", {
      dataType: type,
      locationsData,
      tagPreset
    });
    return false;
  };

  return (
    <AppContext.Consumer>
      {(appState) =>
        appState.user.email?.endsWith("@nfl.com") ? null : (
          <Select
            key={inputKey}
            components={{
              Placeholder: () => (
                <Button
                  color="product-background-blue"
                  icon="plus-box"
                  label="Build Travel"
                  size="medium"
                />
              ),
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null
            }}
            className="autocomplete-input"
            isSearchable={false}
            onChange={(option: any) => {
              const newInputKey = inputKey + 1;
              setInputKey(newInputKey);
              onChange(option.value);
            }}
            options={options}
            styles={optionStyles}
            value={null}
            tabSelectsValue={false}
            theme={customReactSelectTheme}
          />
        )
      }
    </AppContext.Consumer>
  );
};

export default BuildTravelButton;
