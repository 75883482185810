import React, { memo, useRef, useEffect, useState } from "react";
import lottie, { AnimationConfig } from "lottie-web";
import { isEqual } from "lodash";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  animationData: any;
  play?: boolean;
  goTo?: number;
  speed?: number;
  direction?: 1 | -1;
  loop?: number | boolean;
  segments?: Array<number> | boolean;
  props?: any;
  rendererSettings?: AnimationConfig["rendererSettings"];
  renderer?: AnimationConfig["renderer"];
  onComplete?: () => void;
  onLoopComplete?: () => void;
  onEnterFrame?: () => void;
  onSegmentStart?: () => void;
}

const Lottie: React.FC<Props> = memo(
  ({
    animationData,
    play = null,
    speed = 1,
    direction = 1,
    segments: segmentsIn = null,
    goTo = null,
    renderer = "svg",
    loop = true,
    rendererSettings: rendererSettingsIn = {},
    onComplete = () => {},
    onLoopComplete = () => {},
    onEnterFrame = () => {},
    onSegmentStart = () => {},
    ...props
  }) => {
    const animElementRef = useRef<any>();
    const animRef = useRef<any>();

    const [ready, setReady] = useState(false);

    const [segments, setSegments] = useState(segmentsIn);

    // Prevent re-init
    useEffect(() => {
      if (!isEqual(segments, segmentsIn)) setSegments(segmentsIn);
    }, [segmentsIn, segments]);

    const [rendererSettings, setRendererSettings] = useState(
      rendererSettingsIn
    );

    // Prevent re-init
    useEffect(() => {
      if (!isEqual(rendererSettings, rendererSettingsIn))
        setRendererSettings(rendererSettingsIn);
    }, [rendererSettingsIn, rendererSettings]);

    // In order to remove listeners before animRef gets destroyed:
    useEffect(
      () => () => animRef.current.removeEventListener("complete", onComplete),
      [onComplete]
    );
    useEffect(
      () => () =>
        animRef.current.removeEventListener("loopComplete", onLoopComplete),
      [onLoopComplete]
    );
    useEffect(
      () => () =>
        animRef.current.removeEventListener("enterFrame", onEnterFrame),
      [onEnterFrame]
    );
    useEffect(
      () => () =>
        animRef.current.removeEventListener("segmentStart", onSegmentStart),
      [onSegmentStart]
    );

    useEffect(() => {
      animRef.current = lottie.loadAnimation({
        animationData,
        container: animElementRef.current,
        renderer,
        loop: false,
        autoplay: false, // We want to explicitly control playback
        rendererSettings
      });

      const onReady = () => setReady(true);
      animRef.current.addEventListener("DOMLoaded", onReady);

      return () => {
        animRef.current.removeEventListener("DOMLoaded", onReady);
        setReady(false);
        animRef.current.destroy();
        animRef.current = undefined;
      };
    }, [loop, renderer, rendererSettings, animationData]);

    useEffect(() => {
      animRef.current.addEventListener("complete", onComplete);
    }, [onComplete]);

    useEffect(() => {
      animRef.current.addEventListener("loopComplete", onLoopComplete);
    }, [onLoopComplete]);

    useEffect(() => {
      animRef.current.addEventListener("enterFrame", onEnterFrame);
    }, [onEnterFrame]);

    useEffect(() => {
      animRef.current.addEventListener("segmentStart", onSegmentStart);
    }, [onSegmentStart]);

    useEffect(() => {
      if (!ready) return;
      animRef.current.loop = loop;
    }, [ready, loop]);

    useEffect(() => {
      if (!ready) return;

      if (play === true) {
        const force = true;
        if (segments) {
          animRef.current.playSegments(segments, force);
        } else {
          animRef.current.resetSegments(force);
          animRef.current.play();
        }
        animRef.current.setDirection(direction);
      } else if (play === false) {
        animRef.current.pause();
      }
    }, [direction, play, segments, ready]);

    useEffect(() => {
      if (!ready) return;
      if (Number.isNaN(speed)) return;
      animRef.current.setSpeed(speed);
    }, [speed, ready]);

    useEffect(() => {
      if (!ready) return;
      animRef.current.setDirection(direction);
    }, [direction, ready]);

    useEffect(() => {
      if (!ready) return;
      if (goTo == null) return;
      const isFrame = true;
      if (play) animRef.current.goToAndPlay(goTo, isFrame);
      else animRef.current.goToAndStop(goTo, isFrame);
    }, [goTo, play, ready]);

    return <div {...props} ref={animElementRef} />;
  }
);

export default Lottie;
