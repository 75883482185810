import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import FormController from "../../../form/form-controller";

import AppContainer from "../../../../container";
import { IUserService, UserService } from "../../../../services/user.service";
import {
  IWorkspaceService,
  TemplateInput,
  WorkspaceService
} from "../../../../services/workspace.service";

import { ObjectHash } from "../../../../utils/helpers";
import { UserModelValidator } from "../../../../utils/form";

interface Props {
  editing: boolean;
  menuPortalTarget: any;
  onFormChange?: CallableFunction;
  onSave?: CallableFunction;
  onValidate?: CallableFunction;
}

const ProfileForm = forwardRef((props: Props, ref) => {
  const userService: IUserService = AppContainer.get(UserService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const addInputKeys: string[] = ["firstName", "lastName", "email"];

  const { editing, onFormChange, onSave, onValidate } = props;

  const formRef = useRef();

  const templateInputs = workspaceService.getTemplateInputs("profile");

  const [formInputs] = useState(
    templateInputs
      .filter((temlateInput: TemplateInput) =>
        addInputKeys.includes(temlateInput.key)
      )
      .sort((aTemplateInput: TemplateInput, bTemplateInput: TemplateInput) => {
        const aIndex = addInputKeys.indexOf(aTemplateInput.key);
        const bIndex = addInputKeys.indexOf(bTemplateInput.key);

        if (aIndex === bIndex) {
          return 0;
        }

        return aIndex > bIndex ? 1 : -1;
      })
  );

  const [formState] = useState<ObjectHash>({
    ...Object.fromEntries(addInputKeys.map((key: string) => [key, ""])),
    role: "contact"
  });

  const handleSave = async (formState: ObjectHash) => {
    const newUser = await userService.create(formState);

    onSave && onSave(newUser);
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      formRef.current && (formRef.current as any).save();
    }
  }));

  return (
    <div className="profile-add-form">
      <FormController
        fields={formInputs}
        model={formState}
        onFormChange={(changed: boolean) =>
          onFormChange && onFormChange(changed)
        }
        onSave={handleSave}
        onValidate={(formValid: boolean) => onValidate && onValidate(formValid)}
        readOnly={!editing}
        ref={formRef}
        validator={UserModelValidator}
      />
    </div>
  );
});

export default ProfileForm;
