import classNames from "classnames";
import React from "react";

import { GridCell, GridRow, RowHeight, StyledColumnConfig } from "../helpers";
import GridHead from "./grid-head";

import "./grid.scss";

interface Props {
  rows: GridRow[];
  columns: StyledColumnConfig;
  hideGridHead?: boolean;
  onResize: (columnId: string, width: number) => void;
  onResizeEnd?: (columnId: string, width: number) => void;
  pinned?: boolean;
}

export default function Grid(props: Props) {
  const { columns, hideGridHead, onResize, onResizeEnd, rows } = props;
  const gridPinned = Boolean(props.pinned);

  const width = Array.from(columns.entries())
    .map(([, column]) => column.width)
    .reduce((a: number, b: number) => a + b, 0);

  return (
    <div className="grid" style={{ width }}>
      {!hideGridHead && (
        <GridHead
          columns={columns}
          onResize={onResize}
          onResizeEnd={onResizeEnd}
        ></GridHead>
      )}

      <div
        className="grid-content data"
        style={{ height: rows.length * RowHeight }}
      >
        {rows.map((gridRow: GridRow, index: number) => {
          const { cells, pinned, groupId, groupName } = gridRow;
          const rowTop = RowHeight * index;

          let rowElement: JSX.Element[] = [];

          if (pinned) {
            rowElement = [
              <div className="grid-cell group-title" key={groupId}>
                <div className="inner">{gridPinned ? groupName : ""}</div>
              </div>
            ];
          } else {
            rowElement = cells
              .filter((cell: GridCell) => columns.has(cell.columnId))
              .map((cell: GridCell) => {
                const { element, style } = cell;
                return (
                  <div className="grid-cell" style={style} key={cell.columnId}>
                    <div className="inner">{element}</div>
                  </div>
                );
              });
          }

          return (
            <div
              className={classNames("grid-row", { pinned })}
              style={{ top: rowTop, height: RowHeight }}
              key={index}
            >
              {rowElement}
            </div>
          );
        })}
      </div>
    </div>
  );
}
