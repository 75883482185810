import React from "react";

import Button from "../../button";
import useModal from "../../../hooks/use-modal.hook";

import CreateDocumentButton from "../../button/create-document";
import EventsImage from "../../../images/empty-table-events.png";
import AccommodationsImage from "../../../images/empty-table-accommodations.png";
import DocumentsImage from "../../../images/empty-table-documents.png";
import FlightsImage from "../../../images/empty-table-flights.png";
import RailImage from "../../../images/empty-table-rail.png";
import TransportationImage from "../../../images/empty-table-transportation.png";
import TravelersImage from "../../../images/empty-table-travelers.png";
import "./empty-table.scss";

const typeHash: { [index: string]: any } = {
  Accommodations: {
    dataType: "HOTEL",
    description: "",
    image: AccommodationsImage,
    label: "Accommodations"
  },
  Documents: {
    dataType: "DOCUMENT",
    description: "",
    image: DocumentsImage,
    label: "Documents"
  },
  Events: {
    dataType: "ACTIVITY",
    description: "",
    image: EventsImage,
    label: "Events"
  },
  Flights: {
    dataType: "AIR",
    description: "",
    image: FlightsImage,
    label: "Flights"
  },
  Rail: { dataType: "RAIL", description: "", image: RailImage, label: "Rail" },
  Transportation: {
    dataType: "CAR",
    description: "",
    image: TransportationImage,
    label: "Transportation"
  },
  "Traveler Dashboard": {
    dataType: "TRAVELER",
    description:
      "Adding travel will automatically add associated travelers to your grid.",
    image: TravelersImage,
    label: "Travelers"
  }
};

interface Props {
  gridId?: string;
  locationsData?: any[];
  onCreate?: CallableFunction;
  tableTitle: string;
}

export default function EmptyTable(props: Props) {
  const { gridId, locationsData, onCreate, tableTitle } = props;
  const typeConfig = typeHash[tableTitle];

  const { openModal } = useModal();

  if (!typeConfig) {
    return null;
  }

  const label = typeConfig.label;
  const displayText = `There are no ${label.toLowerCase()}.`;
  const displayDescription = typeConfig.description;
  const buttonText = `Add ${
    label.endsWith("s") ? label.slice(0, label.length - 1) : label
  }`;

  const handleClick = (dataType: string) => {
    if (["ACTIVITY", "AIR", "CAR", "HOTEL", "RAIL"].includes(dataType)) {
      openModal("quick-add", {
        dataType,
        locationsData
      });
    }
    if (["PROFILE"].includes(dataType)) {
      openModal("user");
    }
  };

  return (
    <div className="empty-table-wrapper">
      <span className="empty-table__title">{tableTitle}</span>
      <div className="empty-table__content">
        <img
          alt={`empty-table-${typeConfig.dataType.toLowerCase()}`}
          className="empty-table__image"
          src={typeConfig.image}
        />
        <span className="empty-table__display-text">{displayText}</span>
        {typeConfig.dataType === "DOCUMENT" ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "inline-block" }}>
              <CreateDocumentButton
                customButton={
                  <Button
                    color="product-blue"
                    label={buttonText}
                    onClick={() => handleClick(typeConfig.dataType)}
                    size="medium"
                  />
                }
                gridId={gridId}
                onCreate={onCreate}
              />
            </div>
          </div>
        ) : (
          <Button
            color="product-blue"
            label={buttonText}
            onClick={() => handleClick(typeConfig.dataType)}
            size="medium"
          />
        )}
        {displayDescription.length ? (
          <span className="empty-table__description">{displayDescription}</span>
        ) : null}
      </div>
    </div>
  );
}
