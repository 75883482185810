import BaseModel from "./base.model";

export default class ParsedEmailModel extends BaseModel {
  id: string;
  gridId: string;
  canceled: boolean;
  error: boolean;
  inReview: boolean;
  finished: boolean;

  mailgunSubject: string;
  message: string;

  createdAt: string;
  updatedAt: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.gridId = data.gridId;
    this.canceled = data.canceled;
    this.error = data.error;
    this.inReview = data.inReview;
    this.finished = data.finished;

    this.mailgunSubject = data.mailgunSubject;
    this.message = data.message;

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
