import BaseService from "./base.service";

export interface ILocalStorageService {
  get(key: string): any;

  set(key: string, val: any): void;

  remove(key: string): void;

  clear(): void;

  isAvailable(): boolean;

  getViewState(key: string): any;

  setViewState(key: string, value?: any): boolean;
}

export class LocalStorageService extends BaseService
  implements ILocalStorageService {
  private engine: any;

  constructor() {
    super();
    this.engine = this.isAvailable()
      ? localStorage
      : {
          getItem: () => null,
          setItem: () => null,
          removeItem: () => null,
          clear: () => null
        };
  }

  get(key: string): any {
    return this.engine.getItem(key);
  }

  set(key: string, val: any): void {
    try {
      this.engine.setItem(key, val);
    } catch (err) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
      console.error(err);
    }
  }

  remove(key: string): void {
    this.engine.removeItem(key);
  }

  clear(): void {
    this.engine.clear();
  }

  /* @todo
   *
   * These 2 methods (getViewState, setViewState) resided in the auth util module, they
   * were moved here temporarily, but really belong in a separate module more closely
   * related to app-specific state management
   */

  getViewState(key: string): any {
    let viewStateStr: string | null = this.get("viewState");

    if (!viewStateStr) {
      return key ? null : {};
    }

    try {
      const viewState = JSON.parse(viewStateStr) || {};
      const stateVal = key ? viewState[key] : viewState;

      if (stateVal) {
        return JSON.parse(JSON.stringify(stateVal));
      }
    } catch (e) {
      // @todo why does this need to be catched? what's the underlying issue?
    }

    return null;
  }

  setViewState(key: string, value?: any): boolean {
    if (!key || value === undefined) {
      return false;
    }

    const viewStateStr: string | null = this.get("viewState");
    let viewState: any = {};

    // @todo why the try/catch here? if there's invalid json being stored, we should probably know about it
    try {
      if (viewStateStr) {
        viewState = JSON.parse(viewStateStr) || {};
      }
    } catch (e) {
      viewState = {};
    }
    viewState[key] = value;

    this.set("viewState", JSON.stringify(viewState));

    return true;
  }

  // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
  isAvailable() {
    const testVal = "test";
    try {
      localStorage.setItem(testVal, testVal);
      const response = localStorage.getItem(testVal);
      localStorage.removeItem(testVal);
      return response === testVal;
    } catch (e) {
      return false;
    }
  }
}
