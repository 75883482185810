import React, { useCallback, useEffect, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormControl from "@material-ui/core/FormControl";
import Popper from "@material-ui/core/Popper";

import useModal from "../../../../hooks/use-modal.hook";

import AppIcon from "../../../app-icon";
import AutocompleteInput from "../../../form/inputs/AutocompleteInput";
import Button from "../../../button";
import RemoveButton from "../../../button/remove";
import Tooltip from "../../../tooltip";

import "./sort-menu.scss";

interface Props {
  customDataOrder: any[];
  data: any[];
  onApplySort: CallableFunction;
  onResetCustomDataOrder: CallableFunction;
  sortableCols: any[];
  tableSortersState: any[];
  tagId: string;
}

export default function SortMenu(props: Props) {
  const { openModal } = useModal();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const popperClass = open ? "sort-popper sort-popper--open" : "sort-popper";

  const sortHash: { [index: string]: string } = {};
  props.sortableCols.forEach((col) => {
    [sortHash[col[1]]] = col;
  });

  let zeroState = {
    sorters: [
      {
        id: Math.random().toString().slice(2),
        label: "",
        key: "",
        direction: null
      }
    ]
  };

  if (props.tableSortersState.length) {
    zeroState = {
      sorters: props.tableSortersState.map((sorter) => ({
        id: Math.random().toString().slice(2),
        label: (Object.entries(sortHash).find((kv) => kv[1] === sorter[0]) || [
          ""
        ])[0],
        key: sorter[0],
        direction: sorter[1]
      }))
    };
  }

  const [state, setState] = useState({
    ...zeroState
  });

  const handleClick = (e: any) => {
    const target = e.currentTarget;
    if (props.customDataOrder.length) {
      openModal("confirm", {
        buttonText: "Clear It",
        dialogTitle: "Clear Custom Order",
        dialogBody:
          "Custom ordering of data will be lost, do you want to continue?",
        onConfirm: () => {
          props.onResetCustomDataOrder();
          setAnchorEl(target);
        }
      });
    } else {
      setAnchorEl(anchorEl ? null : e.currentTarget);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleClickAddSort = () => {
    const newSorters = state.sorters.slice();
    newSorters.push({
      id: Math.random().toString().slice(2),
      label: "",
      key: "",
      direction: null
    });
    setState((oldValues) => ({
      ...oldValues,
      sorters: newSorters
    }));
  };

  const handleSortTypeChange = (v: string, id: string) => {
    const newSorters = state.sorters.slice();
    const changedSort: any = newSorters.find((sort) => sort.id === id);
    let newKey = "";
    if (Object.hasOwnProperty.call(sortHash, v)) {
      newKey = sortHash[v];
    }
    changedSort.label = v;
    changedSort.key = newKey;
    changedSort.direction = null;

    setState((oldValues) => ({
      ...oldValues,
      sorters: newSorters
    }));
  };

  const handleSortDirectionChange = (v: string, sortId: string) => {
    const newSorters = state.sorters.slice();
    const changedSort: any = newSorters.find((sort) => sort.id === sortId);
    changedSort.direction = v;

    setState((oldValues) => ({
      ...oldValues,
      sorters: newSorters
    }));
  };

  const handleApplySort = useCallback(() => {
    const sortsToApply = state.sorters
      .filter((sorter) => sorter.direction)
      .map((sorter) => [sorter.key, sorter.direction]);

    props.onApplySort(sortsToApply);
  }, [props, state.sorters]);

  const handleClickDeleteSort = (id: string) => {
    let newSorters = state.sorters.slice();
    newSorters = newSorters.filter((sort) => sort.id !== id);

    if (newSorters.length === 0) {
      newSorters.push({
        id: Math.random().toString().slice(2),
        label: "",
        key: "",
        direction: null
      });
    }

    setState((oldValues) => ({
      ...oldValues,
      sorters: newSorters
    }));
  };

  useEffect(() => {
    handleApplySort();
  }, [handleApplySort, state]);

  return (
    <div className="sort-menu">
      <Tooltip text="Sort">
        <div onClick={handleClick}>
          <Button
            color="dark-gray"
            isBordered={
              state.sorters.some((f) => f.direction !== null) &&
              !props.customDataOrder.length
            }
            isRippleDisabled={true}
            isRounded={true}
            isTransparent={true}
            icon="sort"
            size="medium"
          />
        </div>
      </Tooltip>
      <Popper
        id={`sort-popper-${props.tagId}`}
        className={popperClass}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="sort-popper__content">
            <h1>Sort</h1>
            <form>
              {state.sorters.map((sort, index) => (
                <div key={sort.id} className="sort-popper__input-flex">
                  {index > 0 ? (
                    <div style={{ marginRight: "8px" }}>
                      <AppIcon color="gray" type="arrow-down-right" />
                    </div>
                  ) : null}
                  <FormControl>
                    <AutocompleteInput
                      defaultValue={sort.label ?? ""}
                      onChange={(v: string) => handleSortTypeChange(v, sort.id)}
                      options={Object.keys(sortHash).map((key) => ({
                        label: key,
                        value: key
                      }))}
                      placeholder="Select sort column"
                    />
                  </FormControl>
                  {sort.label === "" && (
                    <div className="sort-popper__input--disabled" />
                  )}
                  {sort.label !== "" && (
                    <AutocompleteInput
                      defaultValue={
                        sort.direction
                          ? {
                              label:
                                sort.direction === "asc"
                                  ? "Ascending"
                                  : "Descending",
                              value: sort.direction ?? ""
                            }
                          : ""
                      }
                      isSearchable={false}
                      key={`${sort.id}-${sort.key}`}
                      onChange={(v: string) =>
                        handleSortDirectionChange(v, sort.id)
                      }
                      options={[
                        { label: "Ascending", value: "asc" },
                        { label: "Descending", value: "desc" }
                      ]}
                      placeholder="Sort direction"
                    />
                  )}
                  <Tooltip text="Delete sort">
                    <RemoveButton
                      key={`delete-sort-${sort.id}`}
                      onClick={() => {
                        handleClickDeleteSort(sort.id);
                      }}
                      size="small"
                    />
                  </Tooltip>
                </div>
              ))}
            </form>
            <div className="sort-popper__buttons">
              <Button
                color="product-blue"
                icon="plus-circle"
                isDisabled={state.sorters.length >= 2}
                isRippleDisabled={true}
                isTransparent={true}
                label="Add Sort"
                onClick={handleClickAddSort}
                size="medium"
              />
            </div>
          </div>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
