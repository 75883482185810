import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react";

import FormController from "../../../form/form-controller";

import AppContainer from "../../../../container";
import { IUserService, UserService } from "../../../../services/user.service";
import {
  IWorkspaceService,
  TemplateInput,
  WorkspaceService
} from "../../../../services/workspace.service";
import TagGroupModel from "../../../../models/tag-group.model";
import TagModel from "../../../../models/tag.model";
import UserModel, { RoleModel, UserRoles } from "../../../../models/user.model";

import useApp from "../../../../hooks/use-app.hook";
import { LoadState, ObjectHash, SelectOption } from "../../../../utils/helpers";
import { ReadOnlyValue } from "../../../form/read-only";
import { UserModelValidator } from "../../../../utils/form";

interface Props {
  editing: boolean;
  loadState: LoadState;
  menuPortalTarget: any;
  onFormChange?: CallableFunction;
  onSave?: CallableFunction;
  onValidate?: CallableFunction;
  tagPreset?: TagModel[];
  user: UserModel;
}

const CompanySettingsForm = forwardRef((props: Props, ref) => {
  const userService: IUserService = AppContainer.get(UserService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const authedUser = useApp().user;
  const isAdmin = authedUser.isAdmin();

  const {
    editing,
    loadState,
    menuPortalTarget,
    onFormChange,
    onSave,
    onValidate,
    tagPreset,
    user
  } = props;

  const formRef = useRef();

  const templateInputs = workspaceService.getTemplateInputs("profile");

  const [formInputs] = useState(
    templateInputs.filter((templateInput: TemplateInput) => {
      const { inputType, key } = templateInput;
      if (["searchPreset", "travelTablePreset"].includes(key)) {
        return true;
      }
      if (inputType === "tag") {
        return true;
      }
      if (isAdmin && ["role", "linkedUserIds"].includes(key)) {
        if (key === "role" && editing) {
          return false; // hide role input in edit mode
        }
        return true;
      }
      return false;
    })
  );

  const formTagGroups: TagGroupModel[] = [];

  const handleZeroState = (zeroState: ObjectHash): ObjectHash => {
    zeroState.linkedUserIds = (user.linkedUsers || []).map(
      (user: UserModel) => ({
        label: user.name,
        value: user.id,
        meta: user
      })
    );

    // Distribute the single array of user tag ids out into separate tag group inputs
    Object.keys(zeroState)
      .filter((key: string) => TagGroupModel.isFieldId(key))
      .forEach((key: string) => {
        const templateInput = formInputs.find(
          (templateInput: TemplateInput) => templateInput.key === key
        );
        const { tagGroup } = templateInput || {};

        if (tagGroup) {
          formTagGroups.push(tagGroup);

          if (user.tags.length) {
            zeroState[key] = user.tags
              .filter((tag: TagModel) => tag.tagGroup === tagGroup.id)
              .map((tag: TagModel) => tag.id);
          }
        }
      });

    // load tag presets
    if (tagPreset) {
      tagPreset.forEach((tag: TagModel) => {
        const tagGroup = formTagGroups.find(
          (tagGroup: TagGroupModel) => tagGroup.id === tag.tagGroup
        );
        if (!tagGroup) {
          return;
        }
        const fieldId = tagGroup.getFieldId();
        zeroState[fieldId].push(tag.id);
      });
    }

    return zeroState;
  };

  const handleSave = async (formState: ObjectHash) => {
    const payload: ObjectHash = { ...user.toJSON(), ...formState };

    // merge tags separated into groups back into a single array of tag ids
    let tags: string[] = [];
    Object.keys(formState)
      .filter((key: string) => TagGroupModel.isFieldId(key))
      .forEach((key: string) => {
        const value = formState[key];
        delete payload[key];
        if (value?.length) {
          tags = tags.concat(value);
        }
      });

    payload.tags = tags;

    const updatedUser = await userService.update(payload.id, payload);

    // remove unlinked users
    if (updatedUser) {
      updatedUser.linkedUsers = [];
      const initialLinkedUsers = user.linkedUsers || [];
      const linkedUserIds = payload.linkedUserIds.map(
        (opt: SelectOption) => opt.value
      );

      initialLinkedUsers.forEach((user: UserModel) => {
        if (!linkedUserIds.includes(user.id)) {
          userService.update(user.id, {
            linkedUser: null
          });
        } else {
          updatedUser?.linkedUsers?.push(user); // ts needs the qmarks for some reason even though it's assured to be there
        }
      });
    }

    onSave && onSave(updatedUser);
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      formRef.current && (formRef.current as any).save();
    }
  }));

  const handleCustomProps = (
    templateInput: TemplateInput,
    customProps: ObjectHash
  ): ObjectHash => {
    customProps.menuPortalTarget = menuPortalTarget; // for react-select menus, renders on top of modal to prevent clipping
    return customProps;
  };

  const handleReadOnly = (
    templateInput: TemplateInput,
    readOnlyValue: ReadOnlyValue
  ): ReadOnlyValue => {
    const { key } = templateInput;

    if (key === "role") {
      const role = UserRoles.find(
        (role: RoleModel) => role.id === readOnlyValue
      );
      readOnlyValue = role?.name || "Traveler";
    }

    if (key === "linkedUserIds" && user.linkedUsers) {
      readOnlyValue = user.linkedUsers;
    }

    return readOnlyValue;
  };

  return (
    <div className="company-settings-form">
      <FormController
        fields={formInputs}
        loadState={loadState}
        model={user}
        onCustomProps={handleCustomProps}
        onFormChange={(changed: boolean) =>
          onFormChange && onFormChange(changed)
        }
        onReadOnly={handleReadOnly}
        onSave={handleSave}
        onZeroState={handleZeroState}
        onValidate={(formValid: boolean) => onValidate && onValidate(formValid)}
        readOnly={!editing}
        ref={formRef}
        validator={UserModelValidator}
      />
    </div>
  );
});

export default CompanySettingsForm;
