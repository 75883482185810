import React, { useCallback, useRef, useState } from "react";
import classnames from "classnames";

import {
  ColumnMaxWidth,
  ColumnMinWidth,
  HeadRowHeight,
  StyledColumnConfig
} from "../helpers";

interface Props {
  columns: StyledColumnConfig;
  onResize: (columnId: string, width: number) => void;
  onResizeEnd?: (columnId: string, width: number) => void;
}

const DragSize = {
  columnWidth: 0,
  set: false,
  startWidth: 0,
  startX: 0
};

let mouseMoveListener: (event: MouseEvent) => void;
let mouseUpListener: (event: MouseEvent) => void;

export default function GridHead(props: Props) {
  const { columns, onResize, onResizeEnd } = props;

  const handleRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());

  const [activeColumnId, setActiveColumnId] = useState("");

  const handleMouseMove = useCallback(
    (event: any, columnId) => {
      const handleRef = handleRefs.current.get(columnId);
      if (!handleRef) {
        return;
      }

      const { clientX } = event;

      const { width } = handleRef.getBoundingClientRect() || {
        width: 0
      };

      if (!DragSize.set) {
        DragSize.startX = clientX;
        DragSize.startWidth = width;
        DragSize.set = true;
      }

      const { startWidth, startX } = DragSize;

      const offset = clientX - startX;
      let columnWidth = startWidth + offset;

      if (columnWidth < ColumnMinWidth || columnWidth > ColumnMaxWidth) {
        return;
      }

      DragSize.columnWidth = columnWidth;

      onResize(columnId, columnWidth);
    },
    [onResize]
  );

  const handleMouseUp = useCallback(
    (event: MouseEvent, columnId: string) => {
      const { columnWidth } = DragSize;

      onResizeEnd && onResizeEnd(columnId, columnWidth);

      window.removeEventListener("mousemove", mouseMoveListener);
      window.removeEventListener("mouseup", mouseUpListener);

      setActiveColumnId("");
      DragSize.set = false;
    },
    [onResizeEnd]
  );

  const handleMouseDown = (columnId: string) => {
    setActiveColumnId(columnId);

    mouseMoveListener = (event: any) => handleMouseMove(event, columnId);
    mouseUpListener = (event: any) => handleMouseUp(event, columnId);

    window.addEventListener("mousemove", mouseMoveListener);
    window.addEventListener("mouseup", mouseUpListener);
  };

  return (
    <div className="grid-content head" style={{ height: HeadRowHeight }}>
      <div className="grid-row" style={{ height: HeadRowHeight }}>
        {Array.from(columns.entries()).map(([columnId, column]) => {
          const {
            hideLabel = false,
            id,
            label,
            left,
            resize = true,
            width
          } = column;

          return (
            <div
              className={classnames("grid-cell", columnId)}
              key={id}
              ref={(el) => handleRefs.current.set(id, el)}
              style={{ left, width }}
            >
              <div className="inner">
                <div className="label circular-bold">{!hideLabel && label}</div>
              </div>
              <div
                className={classnames("column-handle", {
                  active: columnId === activeColumnId,
                  disabled: !resize
                })}
                onMouseDown={() => handleMouseDown(columnId)}
                style={{ height: HeadRowHeight }}
              >
                <div className="handle-bar"></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
