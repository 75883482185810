import BaseModel from "./base.model";
import { ObjectHash } from "../utils/helpers";

export default class DocumentModel extends BaseModel {
  id: string;
  deleted: boolean;
  isTemplate: boolean;

  company: string;
  companyId: string;
  mailbox: string;

  grid?: string;

  blocks: any[];
  filter: ObjectHash;
  name: string;
  html: string; // Legacy value from documents built on Froala-based V2 Editor

  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    super();

    this.id = data.id;
    this.deleted = data.deleted;
    this.isTemplate = data.isTemplate;

    this.company = data.company;
    this.companyId = data.companyId;
    this.mailbox = data.mailbox;

    if (data.grid) {
      this.grid = data.grid;
    }

    this.blocks = data.blocks;
    this.filter = data.filter;
    this.name = data.name;
    this.html = data.html;

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
