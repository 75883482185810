import { DateTime } from "luxon";

import { BookingStatusOpts } from "../../../components/form/inputs/BookingRequestStatusInput";
import { BookingTypeOpts } from "../../../components/form/inputs/BookingTypeInput";
import {
  getDisplayDate,
  getStringCompareSortValue,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";
import {
  StackColumn,
  StackFilter,
  StackSort
} from "../../../components/stack/helpers";
import { GroupMenuSection } from "../../../components/stack/table-actions/group-menu";

export const Columns: StackColumn[] = [
  {
    id: "traveler",
    index: 0,
    label: "Traveler",
    sort: false
  },
  {
    id: "bookingDates",
    index: 1,
    label: "Travel Dates",
    sort: false
  },
  {
    id: "segmentList",
    index: 2,
    label: "Requested Journey",
    sort: false
  },
  {
    id: "notes",
    index: 3,
    label: "Notes",
    sort: false
  },
  {
    id: "status",
    index: 4,
    label: "Status",
    sort: false
  }
];

export const Filters: StackFilter[] = [
  {
    fieldId: "traveler",
    label: "Travelers",
    inputType: "user-select",
    article: "are"
  },
  {
    article: "are",
    fieldId: "bookingDates",
    label: "Travel Dates",
    inputType: "date-range"
  },
  {
    fieldId: "inboundDepartureAirport",
    label: "Inbound Departure Airport",
    inputType: "airport"
  },
  {
    fieldId: "inboundArrivalAirport",
    label: "Inbound Arrival Airport",
    inputType: "airport"
  },
  {
    fieldId: "outboundDepartureAirport",
    label: "Outbound Departure Airport",
    inputType: "airport"
  },
  {
    fieldId: "outboundArrivalAirport",
    label: "Outbound Arrival Airport",
    inputType: "airport"
  },
  {
    fieldId: "status",
    label: "Status",
    inputType: "booking-status"
  }
];

export const GroupSections: GroupMenuSection[] = [
  {
    label: "Pre-Trip Details",
    groups: [
      {
        label: "Status",
        fieldId: "status",
        onStackTitle: (value: any): string => {
          const opt = BookingStatusOpts.find(
            (opt: SelectOption) => opt.value === value
          );
          return opt?.label || "";
        }
      },
      {
        label: "Trip Type",
        fieldId: "bookingType",
        onStackTitle: (value: any): string => {
          const opt = BookingTypeOpts.find(
            (opt: SelectOption) => opt.value === value
          );
          return opt?.label || "";
        }
      },
      {
        label: "Departing Date",
        fieldId: "departDate",
        onStackTitle: (value: any, meta?: ObjectHash): string =>
          getDisplayDate(value, meta?.dateFormat)
      },
      {
        label: "Return Date",
        fieldId: "returnDate",
        onStackTitle: (value: any, meta?: ObjectHash): string =>
          getDisplayDate(value, meta?.dateFormat)
      },
      {
        label: "Inbound Departure Airport",
        fieldId: "inboundDepartureAirport"
      },
      {
        label: "Inbound Arrival Airport",
        fieldId: "inboundArrivalAirport"
      },
      {
        label: "Outbound Departure Airport",
        fieldId: "outboundDepartureAirport"
      },
      {
        label: "Outbound Arrival Airport",
        fieldId: "outboundArrivalAirport"
      }
    ]
  }
];

// when sorting by status, we need to sort by the option label, as opposed to the actual status value
const getBookingStatusOptLabel = (status: string): string => {
  return (
    BookingStatusOpts.find((opt: SelectOption) => opt.value === status)
      ?.label || ""
  );
};

export const Sorts: StackSort[] = [
  {
    column: { label: "Traveler", id: "traveler", index: 0 },
    direction: "asc",
    onSort: (a: ObjectHash, b: ObjectHash, sort: StackSort): number => {
      const { direction } = sort;

      const aVal = a.users[0].lastName;
      const bVal = b.users[0].lastName;

      return getStringCompareSortValue(aVal, bVal, direction);
    }
  },
  {
    column: { label: "Travel Dates", id: "bookingDates", index: 1 },
    direction: "asc",
    directionType: "date",
    onSort: (a: ObjectHash, b: ObjectHash, sort: StackSort): number => {
      const { direction } = sort;
      const isDesc = direction === "desc";

      const aDate = DateTime.fromISO(a.getDates()[0]);
      const bDate = DateTime.fromISO(b.getDates()[0]);

      // requests without valid segment dates should come last when sorting by desc
      const aTime = aDate.isValid ? aDate.valueOf() : 0;
      const bTime = bDate.isValid ? bDate.valueOf() : 0;

      if (aTime === bTime) {
        return 0;
      }

      if (isDesc) {
        return aTime > bTime ? -1 : 1;
      }

      return aTime > bTime ? 1 : -1;
    }
  },
  {
    column: { label: "Status", id: "status", index: 2 },
    direction: "asc",
    onSort: (a: ObjectHash, b: ObjectHash, sort: StackSort): number => {
      const { direction } = sort;

      const aVal = getBookingStatusOptLabel(a.status);
      const bVal = getBookingStatusOptLabel(b.status);

      return getStringCompareSortValue(aVal, bVal, direction);
    }
  }
];
