import React from "react";
import TextField from "@material-ui/core/TextField";
import { getTextValueProps, MuiVariant } from "../../../utils/helpers";
import "./inputs.scss";

// format number 1000000 to 1,234,567
const formatNumber = (n: string, isDecimal: boolean) => {
  if (isDecimal) {
    // don't apply commas to decimal
    return n.replace(/\D/g, "");
  }
  // remove non-numeric, leading zeros, then apply commas
  return n
    .replace(/\D/g, "")
    .replace(/^0+/, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const formatCurrency = (e: any) => {
  const inputVal = e.target.value;

  if (inputVal === "") {
    return inputVal;
  }

  let formattedVal = inputVal;

  // check for decimal
  if (formattedVal.indexOf(".") >= 0) {
    // get position of first decimal
    const decimalPosition = formattedVal.indexOf(".");

    // split number by decimal point
    let leftSide = formattedVal.substring(0, decimalPosition);
    let rightSide = formattedVal.substring(decimalPosition) || "";

    leftSide = formatNumber(leftSide, false);

    rightSide = formatNumber(rightSide, true);
    // truncate decimal
    if (rightSide.length > 2) {
      rightSide = rightSide.substring(0, 2);
    }

    formattedVal = `$${leftSide}.${rightSide}`;
  } else {
    // no decimal entered
    formattedVal = formatNumber(formattedVal, false);
    formattedVal = `$${formattedVal}`;
  }

  // manage cursor index
  const element = e.target;
  const caretIndex = element.selectionStart;

  const startLength = inputVal.length;
  const endLength = formattedVal.length;

  const newCaretIndex = endLength - startLength + caretIndex;

  window.requestAnimationFrame(() => {
    element.selectionStart = newCaretIndex;
    element.selectionEnd = newCaretIndex;
  });

  return formattedVal;
};

interface Props {
  autoFocus?: boolean;
  defaultValue?: string;
  label?: string;
  isRequired?: boolean;
  onChange: CallableFunction;
  value?: string;
  variant?: MuiVariant;
}

export default function CurrencyInput(props: Props) {
  const { autoFocus, label, isRequired, onChange, variant } = props;

  let disableUnderline = {};
  if (variant && ["filled", "standard"].includes(variant)) {
    disableUnderline = { disableUnderline: true };
  }

  return (
    <TextField
      {...getTextValueProps(props)}
      fullWidth
      autoFocus={autoFocus || false}
      autoComplete="off"
      className="text-input"
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        className: "roboto-mono"
      }}
      InputProps={{
        ...disableUnderline
      }}
      label={label}
      onChange={(e) => {
        const formattedValue = formatCurrency(e);
        e.target.value = formattedValue;
        onChange(formattedValue);
      }}
      required={isRequired || false}
      type="text"
      variant={variant || "outlined"}
    />
  );
}
