import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import AppContainer from "../../../../container";
import { AppContext } from "../../../../context";
import useModal from "../../../../hooks/use-modal.hook";
import { GridService, IGridService } from "../../../../services/grid.service";
import DocumentModel from "../../../../models/document.model";
import GridModel from "../../../../models/grid.model";

import BigTile from "../../big-tile";
import ObjectTable from "../../../object-table";
import SuggestedDocuments from "../../../suggested-documents";
import "./big-tile.scss";

interface Props {
  grid: GridModel;
  onClose?: CallableFunction;
}

export default function GridDocumentsBigTile(props: Props) {
  const gridService: IGridService = AppContainer.get(GridService);

  const history = useHistory();
  const { closeModal, linkModal } = useModal();
  const { grid, onClose } = props;

  const [documents, setDocuments] = React.useState<DocumentModel[]>([]);

  const getDocuments = React.useCallback(async () => {
    const response = await gridService.getDocuments(grid.id);
    setDocuments(response);
  }, [grid.id, gridService]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  useEffect(() => {
    linkModal("grid-documents-big-tile");
  }, [linkModal]);

  return (
    <BigTile title="Grid Documents" onClose={onClose}>
      <div className="grid-documents-big-tile">
        <AppContext.Consumer>
          {(appState) => (
            <React.Fragment>
              <ObjectTable
                columns={[
                  ["name", "Name"],
                  ["id", "ID"]
                ]}
                grid={grid}
                onCreate={() => {
                  closeModal();
                }}
                renderOpts={{
                  customRowClick: (document: DocumentModel) => {
                    history.push(`/documents/${document.id}/build`);
                    closeModal(); // dimisses big tile on row click
                  },
                  disableDownload: true,
                  disableViewChange: true,
                  showEmptyTable: true,
                  showQuickAdd: true,
                  updateStaticResults: () => getDocuments(),
                  useCardStyle: true
                }}
                searchOpts={{
                  order: "name",
                  resource: "documents"
                }}
                staticResults={documents}
                title="Documents"
                company={appState.company}
                templates={appState.templates}
                settings={appState.settings}
                user={appState.user}
              />
              <SuggestedDocuments gridId={grid.id} onCreate={closeModal} />
            </React.Fragment>
          )}
        </AppContext.Consumer>
      </div>
    </BigTile>
  );
}
