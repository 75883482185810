import React from "react";
import Button from "../../../button";
import Tooltip from "../../../tooltip";
import useModal from "../../../../hooks/use-modal.hook";
import "./future-only-menu.scss";

interface Props {
  customDataOrder: any[];
  futureOnly: boolean;
  onApplyFilter: CallableFunction;
  onChangeTableBlockFutureOnly: CallableFunction;
  onResetCustomDataOrder: CallableFunction;
}

export default function FutureOnlyMenu(props: Props) {
  const { openModal } = useModal();

  const handleClick = () => {
    if (props.customDataOrder.length) {
      openModal("confirm", {
        buttonText: "Clear It",
        dialogTitle: "Clear Custom Order",
        dialogBody:
          "Custom ordering of data will be lost, do you want to continue?",
        onConfirm: () => {
          props.onResetCustomDataOrder();
          props.onChangeTableBlockFutureOnly();
        }
      });
    } else {
      props.onChangeTableBlockFutureOnly();
    }
  };

  return (
    <div className={"future-only-menu"}>
      <Tooltip text={`${props.futureOnly ? "Show" : "Hide"} past travel`}>
        <Button
          color="dark-gray"
          icon="update"
          isBordered={props.futureOnly}
          isRounded={true}
          isTransparent={true}
          onClick={handleClick}
          size="medium"
        />
      </Tooltip>
    </div>
  );
}
