import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AppContainer from "../../../container";
import Button from "../../button";
import { GridService, IGridService } from "../../../services/grid.service";

import useSnackbar from "../../../hooks/use-snackbar.hook";
import useModal from "../../../hooks/use-modal.hook";
import { downloadCSV } from "../../../utils/csv";
import { ObjectHash } from "../../../utils/helpers";
import { openPicker } from "../../file-uploader";

import BoltImage from "../../../images/room-block-auto.svg";
import ManualImage from "../../../images/room-block-manual.svg";
import "./room-block-dialog.scss";

const downloadTemplate = () => {
  const friendlyColNames = [
    "Hotel Name",
    "Hotel Address",
    "Guest First Name",
    "Guest Last Name",
    "Check In",
    "Check Out",
    "Total Cost",
    "Confirmation",
    "Hotel Phone Number"
  ];

  const filename = "Tripgrid-Room-Block-Template.csv";

  downloadCSV(friendlyColNames, filename);
};

const uploadOptions = [
  {
    image: BoltImage,
    timeLabel: "Instantly",
    name: "Tripgrid Template",
    description:
      "Instantly add your room block by uploading a completed Tripgrid room block template.",
    showTemplateDownload: true
  },
  {
    image: ManualImage,
    timeLabel: "Up to 24 hrs",
    name: "Custom Room Block",
    description:
      "Upload any room block and Tripgrid will add the details within 24 hours.",
    showTemplateDownload: false,
    subDescription: "Note - Ensure room block is as close to final as possible."
  }
];

interface Props {
  payload: ObjectHash;
}

export default function RoomBlockDialog(props: Props) {
  const { closeModal } = useModal();
  const gridService: IGridService = AppContainer.get(GridService);
  const { setSnackbar } = useSnackbar();

  const handleUploadRoomBlock = async (gridId: string, payload: ObjectHash) => {
    const response: ObjectHash | null = await gridService.uploadRoomBlock(
      gridId,
      payload
    );
    if (response) {
      setSnackbar({
        message: response.message || "Room block uploaded!",
        variant: "success"
      });
    }
  };

  return (
    <Dialog
      classes={{
        root: "room-block-dialog",
        paper: "paper",
        paperWidthSm: "paper-width-sm"
      }}
      onClose={() => closeModal()}
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <div className="room-block-dialog__title">
          <h1>Upload a Room Block</h1>
        </div>
      </DialogTitle>
      <DialogContent className="not-draggable">
        <p className="room-block-dialog__body">Select your upload option.</p>
        <div className="room-block-dialog__upload-options">
          {uploadOptions.map((option, index) => (
            <div key={`upload-option-${index}`} className="upload-option">
              <div className="upload-option__image-wrapper">
                <img alt="upload-option-type" src={option.image} />
              </div>
              <p className="upload-option__time-label">{option.timeLabel}</p>
              <p className="upload-option__name">{option.name}</p>
              <p className="upload-option__description">{option.description}</p>
              {option.subDescription && (
                <p className="upload-option__sub-description">
                  {option.subDescription}
                </p>
              )}
              {option.showTemplateDownload && (
                <div
                  className="upload-option__download-link"
                  onClick={() => downloadTemplate()}
                >
                  Download Template
                </div>
              )}
              <Button
                color="product-blue"
                label="Upload"
                onClick={() =>
                  openPicker(
                    {
                      fromSources: ["local_file_system"],
                      maxFiles: 1
                    },
                    (
                      urls: any[],
                      [{ originalFile, url }]: [
                        { originalFile: any; url: string }
                      ]
                    ) => {
                      closeModal();
                      handleUploadRoomBlock(props.payload.projectId, {
                        ...originalFile,
                        url
                      });
                    }
                  )
                }
                size="medium"
              />
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
