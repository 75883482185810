import React from "react";

import AppContainer from "../../container";
import { AppActionTypes } from "../../context/reducer";
import useApp from "../../hooks/use-app.hook";
import {
  ICollectionService,
  CollectionService
} from "../../services/collection.service";
import CollectionModel from "../../models/collection.model";

import AppIcon from "../../components/app-icon";
import Button, { ButtonColor } from "../../components/button";
import PopperMenu from "../../components/popper-menu";

import useModal from "../../hooks/use-modal.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";

import "./collections-page.scss";

interface Props {
  color: ButtonColor;
  collection: CollectionModel;
}

export default function CollectionsStackMenu(props: Props) {
  const { color, collection } = props;

  const { dispatch } = useApp();

  const collectionService: ICollectionService = AppContainer.get(
    CollectionService
  );

  const { openModal } = useModal();
  const { setSnackbar } = useSnackbar();

  const deleteCollection = () => {
    openModal("confirm", {
      buttonText: "Delete",
      dialogTitle: "Delete Collection",
      dialogBody:
        "Are you sure you want to delete this collection? This cannot be undone.",
      onConfirm: async () => {
        const deletedCollection = await collectionService.delete(collection.id);
        if (deletedCollection) {
          setSnackbar({
            message: "Collection deleted!",
            variant: "success"
          });

          dispatch({
            type: AppActionTypes.DeleteCollection,
            payload: deletedCollection
          });
        } else {
          setSnackbar({
            message: "There was a problem and the collection was not removed.",
            variant: "error"
          });
        }
      }
    });
  };

  return (
    <PopperMenu
      anchor={
        <Button
          color={color}
          icon="horiz-dots"
          isTransparent={true}
          size="small"
        />
      }
      paddingSize="small"
      placement="top"
    >
      <div
        className="popper-row"
        onClick={(event) => {
          event.stopPropagation();
          deleteCollection();
        }}
      >
        <AppIcon color="black" size="x-small" type="trash" />
        Delete
      </div>
    </PopperMenu>
  );
}
