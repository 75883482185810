import React from "react";
import classnames from "classnames";
import AppIcon from "../app-icon";
import TravelerModel from "../../models/traveler.model";
import UserModel from "../../models/user.model";
import "./traveler-chip.scss";

interface Props {
  onRemove?: CallableFunction;
  traveler: TravelerModel | UserModel;
}

export default function TravelerChip(props: Props) {
  const { onRemove, traveler } = props;

  return (
    <div
      className={classnames("traveler-chip", { removable: Boolean(onRemove) })}
    >
      <div className="label">{traveler.getFullName()}</div>
      {onRemove && (
        <div className="remove-button" onClick={() => onRemove(traveler)}>
          <AppIcon type="close" size="x-small" />
        </div>
      )}
    </div>
  );
}
