const getInitialState = (props: any) => {
  const { blockState } = props || {};

  const state = {
    id: blockState.block.id,
    onChangeTableBlockColor: blockState.onChangeTableBlockColor,
    onChangeCustomTableBlockBodyCell:
      blockState.onChangeCustomTableBlockBodyCell,
    onChangeCustomTableBlockColName: blockState.onChangeCustomTableBlockColName,
    onChangeCustomTableBlockCols: blockState.onChangeCustomTableBlockCols,
    onChangeCustomTableBlockRows: blockState.onChangeCustomTableBlockRows,
    onImportCsvData: blockState.onImportCsvData,
    onChangeTableBlockTitle: blockState.onChangeTableBlockTitle,
    titleInputWidth: 0
  };

  return state;
};

export default getInitialState;
