import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { IUserService, UserService } from "../../../../services/user.service";

import TabHeader, { Tab } from "../../../tab-header";

import AppContainer from "../../../../container";
import { ObjectHash } from "../../../../utils/helpers";

import {
  FormConfig,
  UserDocumentIdCardValidator,
  UserDocumentPassportValidator,
  UserDocumentTsaValidator
} from "../../../../utils/form";
import UserModel from "../../../../models/user.model";
import { UserDocumentType } from "../../../../models/user-document.model";
import FormController from "../../../form/form-controller";
import { TemplateInput } from "../../../../services/workspace.service";

interface Props {
  docType?: UserDocumentType;
  menuPortalTarget: any;
  onFormChange?: CallableFunction;
  onSave?: CallableFunction;
  onTabChange?: CallableFunction;
  onReadOnly?: CallableFunction;
  onValidate?: CallableFunction;
  readOnly?: boolean;
  user: UserModel;
}

const tabs: { id: UserDocumentType; title: string }[] = [
  { id: "passport", title: "Passport" },
  { id: "idCard", title: "ID Card" },
  { id: "tsa", title: "TSA" }
];

const formConfigs: { [index: string]: FormConfig } = {
  passport: {
    validator: UserDocumentPassportValidator,
    fields: [
      {
        key: "passportNumber",
        label: "Passport Number",
        inputType: "text"
      },
      {
        key: "issuingCountry",
        label: "Issuing Country",
        inputType: "country"
      },
      {
        key: "expirationDate",
        label: "Expiration Date",
        inputType: "date"
      },
      {
        key: "issueDate",
        label: "Issue Date",
        inputType: "date"
      },
      {
        key: "sex",
        label: "Sex",
        inputType: "gender"
      },
      {
        key: "firstName",
        label: "First Name",
        inputType: "text",
        helperText: "Exactly as it appears on document"
      },
      {
        key: "lastName",
        label: "Last Name",
        inputType: "text",
        helperText: "Exactly as it appears on document"
      },
      {
        key: "dateOfBirth",
        label: "Date Of Birth",
        inputType: "date"
      },
      {
        key: "nationality",
        label: "Nationality",
        inputType: "country"
      },
      {
        key: "countryOfResidence",
        label: "Country Of Residence",
        inputType: "country"
      }
    ]
  },
  idCard: {
    validator: UserDocumentIdCardValidator,
    fields: [
      {
        key: "idCardNumber",
        label: "Card Number",
        inputType: "text"
      },
      {
        key: "issuingCountry",
        label: "Issuing Country",
        inputType: "country"
      },
      {
        key: "expirationDate",
        label: "Expiration Date",
        inputType: "date"
      },
      {
        key: "issueDate",
        label: "Issue Date",
        inputType: "date"
      },
      {
        key: "sex",
        label: "Sex",
        inputType: "gender"
      },
      {
        key: "firstName",
        label: "First Name",
        inputType: "text"
      },
      {
        key: "lastName",
        label: "Last Name",
        inputType: "text"
      },
      {
        key: "dateOfBirth",
        label: "Date Of Birth",
        inputType: "date"
      },
      {
        key: "nationality",
        label: "Nationality",
        inputType: "country"
      },
      {
        key: "countryOfResidence",
        label: "Country Of Residence",
        inputType: "country"
      }
    ]
  },
  tsa: {
    validator: UserDocumentTsaValidator,
    fields: [
      {
        key: "tsaNumber",
        label: "TSA Number",
        inputType: "text"
      },
      {
        key: "expirationDate",
        label: "Expiration Date",
        inputType: "date"
      }
    ]
  }
};

const ProfileDocumentForm = forwardRef((props: Props, ref: any) => {
  const {
    docType,
    menuPortalTarget,
    onFormChange,
    onSave,
    onTabChange,
    onReadOnly,
    onValidate,
    readOnly,
    user
  } = props;

  const userService: IUserService = AppContainer.get(UserService);

  const filteredTabs = tabs.filter(
    (tab) => !Object.keys(user.getValidDocuments()).includes(tab.id)
  ); // only provide tabs for docs that don't yet exist

  const [documentType, setDocumentType] = useState<UserDocumentType>(
    docType || filteredTabs[0]?.id
  );

  const [formTabs] = useState<Tab[]>(
    filteredTabs.map((tab: Tab) => {
      tab.isActive = tab.id === documentType;
      return tab;
    })
  );

  const formRef = useRef();
  const document = user.getValidDocuments()[documentType as any] || null;
  const isNew = !Boolean(document);

  const { validator, fields } = formConfigs[documentType];

  const handleCustomProps = (
    templateInput: TemplateInput,
    customProps: ObjectHash
  ): ObjectHash => {
    const { key } = templateInput;

    customProps.menuPortalTarget = menuPortalTarget;

    /*
     * Since these sensitive fields are usually starred out, updating them means you're typing a whole new
     * value rather than modifying the existing value, so make that a little easier by selecting the contents
     * of the field when clicking in
     */
    if (["tsaNumber", "idCardNumber", "passportNumber"].includes(key)) {
      customProps.inputProps = {
        onFocus: (e: any) => {
          e.target.select();
        }
      };
    }

    return customProps;
  };

  // auto-fill passport fields based on related user
  const handleZeroState = (zeroState: ObjectHash) => {
    if (isNew && ["idCard", "passport"].includes(documentType)) {
      zeroState.firstName = user.firstName;
      zeroState.lastName = user.lastName;
      zeroState.dateOfBirth = (user as ObjectHash).dateOfBirth; // @todo better way to access custom fields?
    }

    ["countryOfResidence", "issuingCountry", "nationality"].forEach(
      (key: string) => {
        if (zeroState.hasOwnProperty(key) && !zeroState[key]) {
          zeroState[key] = "US";
        }
      }
    );

    return zeroState;
  };

  const handleSave = async (formState: ObjectHash) => {
    const userDocs = { ...user.documents, [documentType]: formState };

    let updatedUser = await userService.update(user.id, {
      documents: userDocs
    });

    updatedUser = updatedUser
      ? await userService.getById(updatedUser.id, true, true)
      : null;

    onSave && onSave(updatedUser);
  };

  useImperativeHandle(ref, () => ({
    save: () => {
      formRef.current && (formRef.current as any).save();
    }
  }));

  const handleTabChange = (tab: Tab) => {
    const docType = tab.id as UserDocumentType;
    setDocumentType(docType);
    onTabChange && onTabChange(tab);
  };

  const activeTabTitle =
    tabs.find((tab: Tab) => tab.id === docType)?.title || "";

  return (
    <div className="profile-document-form">
      {isNew && <TabHeader tabs={formTabs} onChange={handleTabChange} />}
      {readOnly && (
        <div className="readonly-header">
          <div className="readonly-header--title">{activeTabTitle}</div>
          <div className="readonly-header--edit">
            <span
              className="edit-button"
              onClick={() => onReadOnly && onReadOnly()}
            >
              Edit
            </span>
          </div>
        </div>
      )}
      <div className="form-container">
        <FormController
          key={`profile-document-form-${documentType}`}
          fields={fields}
          model={document}
          onCustomProps={handleCustomProps}
          onFormChange={(changed: boolean) =>
            onFormChange && onFormChange(changed)
          }
          onSave={handleSave}
          onZeroState={handleZeroState}
          onValidate={(formValid: boolean) =>
            onValidate && onValidate(formValid)
          }
          readOnly={readOnly}
          ref={formRef}
          validator={validator}
        />
      </div>
    </div>
  );
});

export default ProfileDocumentForm;
