import React, { ReactFragment } from "react";
import { useHistory } from "react-router-dom";

import useModal from "../../hooks/use-modal.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";

import AppContainer from "../../container";
import { GridService, IGridService } from "../../services/grid.service";

import Button from "../button";
import CreateDocumentButton from "../button/create-document";
import ColorMenu from "./table-actions/color-menu";
import FilterMenu from "./table-actions/filter-menu";
import FutureOnlyMenu from "./table-actions/future-only-menu";
import SortMenu from "./table-actions/sort-menu";
import UserMenu from "./table-actions/user-menu";
import GridModel from "../../models/grid.model";
import { AppContext } from "../../context";

const labelHash: { [index: string]: string } = {
  ACTIVITY: "Event",
  AIR: "Flight",
  CAR: "Transportation",
  HOTEL: "Accommodation",
  RAIL: "Rail",
  PROFILE: "Profile",
  TRAVELER: "Travelers"
};

interface Props {
  allTrips?: any[];
  customDataOrder?: any[];
  data?: any[];
  dataType: string;
  doSearch?: CallableFunction;
  futureOnly?: boolean;
  grid?: GridModel;
  locationsData?: any[];
  isDocument?: boolean;
  onApplyFilter?: CallableFunction;
  onApplySort?: CallableFunction;
  onChangeTableBlockColor?: CallableFunction;
  onChangeTableBlockFutureOnly?: CallableFunction;
  onChangeTableBlockUsers?: CallableFunction;
  onCreate?: CallableFunction;
  onQuickAddTravel?: CallableFunction;
  onResetCustomDataOrder?: CallableFunction;
  renderOpts: { [index: string]: any };
  resource?: string;
  tableFiltersState?: any[];
  tableSortersState?: any[];
  tagId: string;
  visibleCols?: any[];
}

const CustomToolbar = (props: Props) => {
  const {
    allTrips, // provide preset filter options for static data sets
    customDataOrder,
    data,
    dataType,
    grid,
    futureOnly,
    onApplyFilter,
    onApplySort,
    onChangeTableBlockColor,
    onChangeTableBlockFutureOnly,
    onChangeTableBlockUsers,
    onCreate,
    onQuickAddTravel,
    onResetCustomDataOrder,
    renderOpts,
    resource,
    tableFiltersState,
    tableSortersState,
    tagId,
    visibleCols
  } = props;

  const history = useHistory();

  const { openModal } = useModal();
  const { setSnackbar } = useSnackbar();

  const gridService: IGridService = AppContainer.get(GridService);

  const handleQuickAddClick = () => {
    if (["ACTIVITY", "AIR", "CAR", "HOTEL", "RAIL"].includes(dataType)) {
      openModal("quick-add", {
        dataType,
        locationsData: props.locationsData,
        tagPreset: grid?.tag ? [grid.tag] : [],
        onChange: () => {
          onQuickAddTravel && onQuickAddTravel();
        }
      });
    }
    if (["PROFILE"].includes(dataType)) {
      openModal("user");
    }
    if (["TRAVELER"].includes(dataType)) {
      openModal("add-profiles", {
        onChange: async (profileIdsToAdd: string[]) => {
          if (grid) {
            const newTravelerIds = [
              ...new Set([...grid.travelers, ...profileIdsToAdd])
            ];

            grid.travelers = newTravelerIds;

            const response: GridModel | null = await gridService.update(grid);
            if (response) {
              setSnackbar({
                message: "Travelers added!",
                variant: "success"
              });

              props.doSearch && props.doSearch();
            } else {
              setSnackbar({
                message: "There was a problem and the travelers were not added",
                variant: "error"
              });
            }
          }
        }
      });
    }
  };

  let addButton: ReactFragment;

  if (renderOpts.showQuickAdd && resource === "documents") {
    addButton = (
      <div className={"quick-add-button"} key={`quick-add-button-${tagId}`}>
        <CreateDocumentButton gridId={grid?.id} onCreate={onCreate} />
      </div>
    );
  } else if (resource === "grids") {
    addButton = (
      <div className="quick-add-button" key={`quick-add-button-${tagId}`}>
        <Button
          color="product-blue"
          icon="plus-circle"
          isRippleDisabled={true}
          isTransparent={true}
          label="Add Grid"
          onClick={() => {
            openModal("edit-grid", {
              onChangeGrid: (grid: GridModel) => {
                history.push(`/grids/${grid.id}`);
              }
            });
          }}
          size="medium"
        />
      </div>
    );
  } else if (renderOpts.showQuickAdd) {
    addButton = (
      <div className={"quick-add-button"} key={`quick-add-button-${tagId}`}>
        <Button
          color="product-blue"
          icon="plus-circle"
          isRippleDisabled={true}
          isTransparent={true}
          label={`Add ${labelHash[dataType]}`}
          onClick={handleQuickAddClick}
          size="medium"
        />
      </div>
    );
  }

  return (
    <AppContext.Consumer>
      {(appState) => (
        <React.Fragment>
          {renderOpts.showFutureOnlyMenu && (
            <FutureOnlyMenu
              customDataOrder={customDataOrder || []}
              futureOnly={futureOnly || false}
              onApplyFilter={onApplyFilter || function () {}}
              onChangeTableBlockFutureOnly={
                onChangeTableBlockFutureOnly || function () {}
              }
              onResetCustomDataOrder={onResetCustomDataOrder || function () {}}
            />
          )}
          {renderOpts.showFilterMenu && (
            <FilterMenu
              allTrips={allTrips || []}
              customDataOrder={customDataOrder || []}
              data={data || []}
              filterableCols={visibleCols || []}
              key={`filter-menu-react-${tagId}`}
              onApplyFilter={onApplyFilter || function () {}}
              onResetCustomDataOrder={onResetCustomDataOrder || function () {}}
              tableFiltersState={tableFiltersState || []}
              tagId={tagId}
            />
          )}
          {renderOpts.showSortMenu && (
            <SortMenu
              customDataOrder={customDataOrder || []}
              data={data || []}
              key={`sort-menu-react-${tagId}`}
              onApplySort={onApplySort || function () {}}
              onResetCustomDataOrder={onResetCustomDataOrder || function () {}}
              sortableCols={visibleCols || []}
              tableSortersState={tableSortersState || []}
              tagId={tagId}
            />
          )}
          {renderOpts.showUserMenu && (
            <UserMenu
              data={data || []}
              key={`user-menu-react-${tagId}`}
              onChangeTableBlockUsers={
                onChangeTableBlockUsers || function () {}
              }
              onResetCustomDataOrder={onResetCustomDataOrder || function () {}}
              tagId={tagId}
            />
          )}
          {renderOpts.showColorMenu && (
            <ColorMenu
              tagId={tagId}
              onChangeTableBlockColor={
                onChangeTableBlockColor || function () {}
              }
            />
          )}
          {appState.user.email?.endsWith("@nfl.com") && dataType !== "PROFILE"
            ? null
            : addButton}
        </React.Fragment>
      )}
    </AppContext.Consumer>
  );
};

export default CustomToolbar;
