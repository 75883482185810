import React from "react";
import { debounce } from "lodash";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";

import TextInput from "../form/inputs/TextInput";
import CustomToolbar from "./custom-toolbar";

import {
  getCustomTableHeader,
  getCustomTableBody,
  getCustomTableRowMenu
} from "./table-funcs/render-funcs";
import getInitialState from "./table-funcs/get-initial-state";
import { ObjectHash } from "../../utils/helpers";

import "./custom-table.scss";
import { withModalContext } from "../../context/modal";

interface WrapperState {
  id: string;
  onChangeCustomTableBlockBodyCell: CallableFunction;
  onChangeCustomTableBlockColName: CallableFunction;
  onChangeCustomTableBlockCols: CallableFunction;
  onChangeCustomTableBlockRows: CallableFunction;
  onChangeTableBlockColor: CallableFunction;
  onChangeTableBlockTitle: CallableFunction;
  onImportCsvData: CallableFunction;
  titleInputWidth: number;
}

interface WrapperParams {
  blockState: ObjectHash;
  columns: [];
  data: [];
  title: string;
  viewMode: boolean;
}

export const Wrapper = (
  state: WrapperState,
  setState: CallableFunction,
  params: WrapperParams,
  openModal: CallableFunction
) => {
  const {
    id,
    onChangeCustomTableBlockBodyCell,
    onChangeCustomTableBlockColName,
    onChangeCustomTableBlockCols,
    onChangeCustomTableBlockRows,
    onChangeTableBlockColor,
    onChangeTableBlockTitle,
    onImportCsvData
  } = state || {};
  const { blockState, columns, data, title, viewMode } = params || {};
  const tableData = data;

  const tableTitle = (
    <div className="table-title">
      <div className="table-title__name">
        <span
          id={`input-expander-${id}`}
          style={{
            padding: "0 8px 0 0",
            position: "absolute",
            letterSpacing: "0",
            fontSize: "20px",
            height: "0",
            overflow: "hidden",
            whiteSpace: "pre"
          }}
        >
          {title}
        </span>
        <TextInput
          disabled={viewMode}
          margin="dense"
          onChange={debounce((v: string) => {
            onChangeTableBlockTitle(v);
          }, 300)}
          style={{
            width: `${state.titleInputWidth}px`
          }}
          defaultValue={title}
        />
      </div>
    </div>
  );

  const tableCols = columns.map((c: string) => {
    const options: any = {};
    const tableCol = {
      label: c,
      name: c,
      options
    };

    // use customHeadRender to display React component for document builder table header cell
    tableCol.options.customHeadRender = (columnMeta: object) =>
      getCustomTableHeader(
        columns,
        columnMeta,
        onChangeCustomTableBlockColName,
        onChangeCustomTableBlockCols,
        id,
        viewMode,
        openModal
      );

    // use customHeadRender to display React component for document builder table body cell
    if (c === "documentRowMenu") {
      tableCol.label = " ";
      tableCol.options.customBodyRender = (value: string, tableMeta: object) =>
        getCustomTableRowMenu(
          data,
          tableMeta,
          onChangeCustomTableBlockRows,
          openModal
        );
    } else {
      tableCol.options.customBodyRender = (value: string, tableMeta: object) =>
        getCustomTableBody(
          value,
          tableMeta,
          onChangeCustomTableBlockBodyCell,
          id,
          viewMode
        );
    }
    return tableCol;
  });

  const tableOpts: MUIDataTableOptions = {
    customToolbar: () => (
      <CustomToolbar
        onImportCsvData={onImportCsvData}
        onChangeTableBlockColor={onChangeTableBlockColor}
        tagId={id}
      />
    ),
    download: false,
    filter: false,
    pagination: false,
    print: false,
    responsive: "standard",
    search: false,
    selectableRows: "none",
    setRowProps: () => ({
      className: "mui-data-table__row--with-document-row-menu"
    }),
    sort: false,
    textLabels: {
      body: {
        noMatch: ""
      }
    },
    viewColumns: false
  };

  const muiDataTableProps = {
    data: tableData,
    title: tableTitle,
    columns: tableCols,
    options: tableOpts
  };

  return {
    blockState,
    muiDataTableProps
  };
};

interface CustomTableProps extends WrapperParams {
  openModal: CallableFunction;
  printMode: boolean;
}

interface CustomTableState extends WrapperState {
  id: string;
}

class CustomTable extends React.Component<CustomTableProps, CustomTableState> {
  constructor(props: CustomTableProps) {
    super(props);
    this.state = getInitialState.bind(this)(props);
  }

  componentDidMount() {
    // Sets title input width based on the input value
    const inputEl = document.getElementById(`input-expander-${this.state.id}`);
    if (inputEl && inputEl.offsetWidth) {
      const newTitleInputWidth = inputEl.offsetWidth;
      if (this.state.titleInputWidth !== newTitleInputWidth) {
        this.setState({
          titleInputWidth: newTitleInputWidth
        });
      }
    }
  }

  componentDidUpdate() {
    // Sets title input width based on the input value
    const inputEl = document.getElementById(`input-expander-${this.state.id}`);
    if (inputEl && inputEl.offsetWidth) {
      const newTitleInputWidth = inputEl.offsetWidth;
      if (this.state.titleInputWidth !== newTitleInputWidth) {
        this.setState({
          titleInputWidth: newTitleInputWidth
        });
      }
    }
  }

  render() {
    const { openModal } = this.props;
    const params = Wrapper(
      this.state,
      this.setState.bind(this),
      this.props,
      openModal
    );
    const { blockState, muiDataTableProps } = params;
    const tableBlockColor =
      (((blockState || {}).block || {}).options || {}).color || null;

    const tableColorClassName = tableBlockColor
      ? `table-block-color--${tableBlockColor}`
      : null;

    return (
      <div
        className={[
          "custom-table custom-table--card",
          "object-table--document",
          tableColorClassName,
          this.props.printMode &&
            Array.isArray(muiDataTableProps.data) &&
            muiDataTableProps.data.length > 20 &&
            "enable-table-break"
        ]
          .filter((v) => v)
          .join(" ")}
      >
        <div className="mui-data-table-wrapper">
          <MUIDataTable {...muiDataTableProps} />
        </div>
      </div>
    );
  }
}

export default withModalContext(CustomTable);
