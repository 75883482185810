import React from "react";
import TextField from "@material-ui/core/TextField";

interface Props {
  blockSubType: string;
  defaultValue: any;
  disabled: boolean;
  isViewMode: boolean;
  onChange: CallableFunction;
  options: any;
}

export default function TextBlock(props: Props) {
  const {
    blockSubType,
    defaultValue,
    disabled,
    isViewMode,
    onChange,
    options
  } = props;

  const componentProps: any = {
    defaultValue,
    disabled,
    InputProps: {
      disableUnderline: true,
      style: {
        fontStyle: options.isItalic ? "italic" : "normal",
        fontWeight: options.isBold ? "bold" : "normal",
        textDecoration: options.isUnderlined ? "underline" : "none",
        paddingRight: options.isItalic ? "16px" : "0"
      }
    },
    // attributes passed down to the input el
    inputProps: {
      style: {
        textAlign: options.textAlign,
        paddingRight: options.isItalic ? "16px" : "0"
      }
    },
    onChange
  };

  if (blockSubType === "h1") {
    componentProps.multiline = true;
    componentProps.placeholder = isViewMode ? "" : "Header";
    componentProps.InputProps.style.fontSize = options.isLargerFontSize
      ? "32px"
      : "24px";
    componentProps.InputProps.style.margin = "8px 0";
  }

  if (blockSubType === "p") {
    componentProps.multiline = true;
    componentProps.placeholder = isViewMode ? "" : "Text";
    componentProps.InputProps.style.fontSize = options.isLargerFontSize
      ? "18px"
      : "16px";
  }

  return <TextField fullWidth {...componentProps} />;
}
