import React from "react";
import CollectionModel from "../../models/collection.model";
import CollectionGeneralSettingsForm from "./forms/collection-general-settings-form";
import CollectionDefaultsEditor from "./collection-defaults-editor";
import ProTip from "../../components/pro-tip";

interface Props {
  collection: CollectionModel;
  onChange: CallableFunction;
}

export default function CollectionSettings(props: Props) {
  const { collection, onChange } = props;
  return (
    <div id="collection-settings">
      <div className="inner">
        <div className="form-header">General Settings</div>
        <CollectionGeneralSettingsForm
          collection={collection}
          onChange={onChange}
        />
      </div>

      <div className="inner separator">
        <div className="form-header">
          Pre-trip Details
          <span className="description">
            These are the fields that we need to collect from each traveler
          </span>
        </div>
        <ProTip text="Save time and set default values for each field" />

        <CollectionDefaultsEditor collection={collection} onChange={onChange} />
      </div>
    </div>
  );
}
