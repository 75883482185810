import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ClipboardJS from "clipboard";
import Select from "react-select";

import AppIcon from "../../../components/app-icon";
import Button from "../../../components/button";
import Tooltip from "../../../components/tooltip";

import AppContainer from "../../../container";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import {
  DocumentService,
  IDocumentService
} from "../../../services/document.service";
import DocumentModel from "../../../models/document.model";

import { ObjectHash } from "../../../utils/helpers";

import {
  customStyle,
  customReactSelectTheme
} from "../../../components/form/inputs/custom-theme";
import useModal from "../../../hooks/use-modal.hook";

interface Props {
  backLink: string;
  docData: ObjectHash;
  isImpersonating: boolean;
  isPrimaryAdmin: boolean;
  onChangeDocument: CallableFunction;
  onChangeDocumentBuilderEditMode: CallableFunction;
  onDeleteDocument: CallableFunction;
  onDownloadDocument: CallableFunction;
  onSaveDocument: CallableFunction;
  onRestoreLastDeletedBlock: CallableFunction;
  writeLock: boolean;
  downloadLock: boolean;
  restoreLock: boolean;
  printMode: boolean;
  shareLink: string;
  viewMode: boolean;
}

const { menu, menuPortal, option, singleValue } = customStyle;

const optionStyles = {
  control: (styles: any) => ({
    ...styles,
    boxShadow: "none",
    height: "100%",
    padding: "0",
    marginLeft: "8px",
    border: "none",
    "> div": {
      padding: "0"
    }
  }),
  menu: (styles: any) => ({
    ...menu(styles),
    width: "auto",
    whiteSpace: "nowrap",
    right: "0"
  }),
  option,
  singleValue,
  menuPortal
};

export default function DocumentMenuBar(props: Props) {
  new ClipboardJS(".share-link-copy-button");

  const documentService: IDocumentService = AppContainer.get(DocumentService);
  const { setSnackbar } = useSnackbar();
  const history = useHistory();
  const { openModal } = useModal();

  const {
    backLink,
    docData,
    downloadLock,
    isPrimaryAdmin,
    onChangeDocument,
    onChangeDocumentBuilderEditMode,
    onDeleteDocument,
    onDownloadDocument,
    onSaveDocument,
    onRestoreLastDeletedBlock,
    printMode,
    restoreLock,
    shareLink,
    viewMode,
    writeLock
  } = props;

  const label = docData.isTemplate ? "Template" : "Document";
  const [inputKey, setInputKey] = useState(0);

  const moreActionsOpts = [
    { label: `Rename ${label}`, value: "rename" },
    { label: `Delete ${label}`, value: "delete" }
  ];
  if (isPrimaryAdmin) {
    moreActionsOpts.splice(1, 0, {
      label: "Save as Template",
      value: "template"
    });
  }

  return (
    <div className="document-builder__menu-bar">
      {viewMode || printMode ? (
        <Button
          color="light-red"
          label={printMode ? "Exit Print Mode" : "Exit Share Mode"}
          onClick={() => onChangeDocumentBuilderEditMode()}
          size="medium"
        />
      ) : (
        <Tooltip
          text={`Save and Go Back to ${
            docData && docData.isTemplate ? "Settings" : "Grid"
          }`}
        >
          <div
            className="document-builder__menu__backlink"
            onClick={() => {
              if (!backLink) {
                return;
              }

              onSaveDocument().then(() => {
                history.push(backLink);
              });
            }}
          >
            <AppIcon type="nav-arrow-left" />
            {docData && <span>{docData.name ? docData.name : "Go back"}</span>}
          </div>
        </Tooltip>
      )}

      <div className="document-builder__menu__button-group">
        <Tooltip text="Restore last deleted block">
          <Button
            color="dark-gray"
            icon="restore"
            isDisabled={restoreLock}
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => onRestoreLastDeletedBlock()}
            size="medium"
          />
        </Tooltip>
        <Tooltip text="Preview Share Mode">
          <Button
            color={viewMode && !printMode ? "product-blue" : "dark-gray"}
            icon="visible"
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => onChangeDocumentBuilderEditMode("view")}
            size="medium"
          />
        </Tooltip>
        <Tooltip text="Print Preview">
          <Button
            color={printMode ? "product-blue" : "dark-gray"}
            icon="print"
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => onChangeDocumentBuilderEditMode("print")}
            size="medium"
          />
        </Tooltip>
        <Tooltip text={downloadLock ? "" : "Download PDF"}>
          <Button
            color="dark-gray"
            icon="pdf"
            isDisabled={downloadLock}
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => onDownloadDocument()}
            size="medium"
          />
        </Tooltip>
        <Tooltip text={writeLock ? "" : `Save ${label}`}>
          <Button
            color="dark-gray"
            icon="save"
            isDisabled={writeLock}
            isRippleDisabled={true}
            isTransparent={true}
            onClick={() => onSaveDocument(true)}
            size="medium"
          />
        </Tooltip>
        <Select
          key={inputKey}
          components={{
            Placeholder: () => (
              <Tooltip text="More Actions" placement="left">
                <Button
                  color="dark-gray"
                  icon="vert-dots"
                  isRippleDisabled={true}
                  isTransparent={true}
                  size="medium"
                />
              </Tooltip>
            ),
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
          className="autocomplete-input"
          isSearchable={false}
          onChange={(option: any) => {
            const newInputKey = inputKey + 1;
            setInputKey(newInputKey); // Re-renders the component so it can be opened again if the user cancels out of create document dialog

            if (option.value === "rename") {
              openModal("edit-document", {
                document: docData,
                target: docData.isTemplate ? "template" : "document",
                onChangeDocument,
                onDeleteDocument
              });
            }

            if (option.value === "template") {
              openModal("edit-document", {
                template: {
                  blocks: docData.blocks || [],
                  html: null,
                  name: docData.name ? `Template from - ${docData.name}` : ""
                },
                target: "template",
                onChangeDocument: async (data: ObjectHash) => {
                  const newTemplate: DocumentModel | null = await documentService.create(
                    {
                      ...data,
                      isTemplate: true
                    }
                  );
                  if (newTemplate) {
                    setSnackbar({
                      message: "New template created!",
                      variant: "success"
                    });
                  }
                }
              });
            }

            if (option.value === "delete") {
              onDeleteDocument();
            }
          }}
          options={moreActionsOpts}
          styles={optionStyles}
          tabSelectsValue={false}
          theme={customReactSelectTheme}
        />
        {!docData.isTemplate && (
          <Tooltip text="Copy Share Link">
            <span
              className="share-link-copy-button"
              data-clipboard-text={shareLink}
            >
              <Button
                color="product-background-blue"
                onClick={() => {
                  onSaveDocument();
                  if (shareLink) {
                    setSnackbar({
                      message: "Share link copied!",
                      variant: "success"
                    });
                  }
                }}
                label="Share"
                size="medium"
              />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
