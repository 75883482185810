import React from "react";
import { debounce } from "lodash";
import AsyncCreatableSelect from "react-select/async-creatable";

import Loader from "../../loader";

import AppContainer from "../../../container";
import {
  FlightService,
  IFlightService
} from "../../../services/flight.service";

import {
  getDisplayDate,
  getSelectValueProps,
  ObjectHash
} from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  airline?: string;
  closeMenuOnScroll?: any; // can be bool or func
  date?: string;
  isRequired?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  value?: any[];
}

export default function FlightNumberInput(props: Props) {
  const flightService: IFlightService = AppContainer.get(FlightService);

  const {
    airline,
    closeMenuOnScroll,
    date,
    isRequired,
    label,
    menuPortalTarget,
    onChange
  } = props;

  const getFlights = debounce(
    async (flightNum: string, callback: CallableFunction) => {
      if (!airline || !date) {
        return callback([]);
      }
      // finds airline from a sibling form component if present
      const airlineData = await flightService.airlineSearch(airline);

      if (airlineData[0]) {
        const airlineAbbr = airlineData[0].fs || "";
        const formattedFlightNum =
          flightNum.charAt(0) === "0" ? flightNum.slice(1) : flightNum;

        // search FlightStats for flights that match airline code, flight number and departure date (airlines sometimes use the same flight number for different flights on the same day)
        const flightStatsData = await flightService.flightSearch(
          airlineAbbr,
          formattedFlightNum,
          date
        );

        // generate options for menu, adds additional key values into an option that are used to autofill sibling form inputs in quick add modal
        if (flightStatsData) {
          const {
            appendix,
            scheduledFlights
          }: {
            appendix: ObjectHash;
            scheduledFlights: ObjectHash[];
          } = flightStatsData;

          const { airlines = [], airports = [] } = appendix || {};

          if (!airlines.length || !airports.length) {
            return callback([]);
          }

          const airline = airlines.find(
            (airline: ObjectHash) => airline.fs === airlineAbbr
          );
          if (!airline) {
            return callback([]);
          }

          // only return options that have all necessary values for complete autofill
          const options = scheduledFlights.length
            ? scheduledFlights.map((flight: ObjectHash) => {
                if (
                  !flight.arrivalTime ||
                  !flight.arrivalAirportFsCode ||
                  !flight.departureTime ||
                  !flight.departureAirportFsCode ||
                  !flight.flightNumber
                ) {
                  return null;
                }

                const depart = airports.find(
                  (airport: ObjectHash) =>
                    airport.iata === flight.departureAirportFsCode
                );
                if (
                  !depart ||
                  !depart.city ||
                  !depart.iata ||
                  !depart.timeZoneRegionName
                ) {
                  return null;
                }

                const arrive = airports.find(
                  (airport: ObjectHash) =>
                    airport.iata === flight.arrivalAirportFsCode
                );
                if (
                  !arrive ||
                  !arrive.city ||
                  !arrive.iata ||
                  !arrive.timeZoneRegionName
                ) {
                  return null;
                }

                const { arrivalTime, departureTime } = flight;

                const label = `${flightNum} - (${depart.iata}) ${getDisplayDate(
                  departureTime,
                  "h:mm a"
                )} to (${arrive.iata}) ${getDisplayDate(
                  arrivalTime,
                  "h:mm a"
                )}`;

                return {
                  label,
                  value: label,
                  meta: {
                    label,
                    from: flight.departureTime,
                    to: flight.arrivalTime,
                    fromDate: getDisplayDate(departureTime, "MMM dd, yyyy"),
                    toDate: getDisplayDate(arrivalTime, "MMM dd, yyyy"),
                    fromTime: getDisplayDate(departureTime, "hh:mm a"),
                    toTime: getDisplayDate(arrivalTime, "hh:mm a"),
                    flightNum: flight.flightNumber,
                    fromLocation: `(${depart.iata}) ${depart.city}`,
                    toLocation: `(${arrive.iata}) ${arrive.city}`,
                    fromTimezone: depart.timeZoneRegionName,
                    toTimezone: arrive.timeZoneRegionName
                  }
                };
              })
            : [];
          return callback(options);
        } else {
          return callback([]);
        }
      }
      return callback([]);
    },
    300
  );

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu: (styles: ObjectHash) => ({
      ...menu(styles),
      display: "relative",
      fontSize: "14px"
    }),
    option,
    menuPortal
  };

  const getOptions = (inputValue: string, callback: any) => {
    if (!inputValue) {
      return Promise.resolve({ options: [] });
    }
    return getFlights(inputValue, callback);
  };

  return (
    <div className="autocomplete-input--filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncCreatableSelect
        {...getSelectValueProps({ ...props, isSearchable: true })}
        className="autocomplete-input autocomplete-input--async flight-number-input"
        closeMenuOnScroll={closeMenuOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        formatCreateLabel={(label) => `Use custom "${label}"`}
        loadOptions={getOptions}
        isSearchable={true}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No flights found" : null
        }
        onChange={(option: any) => onChange(option.value, option.meta)}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
