import React from "react";

import AppContainer from "../../container";
import AirportChip from "../airport-chip";
import AirportModel from "../../models/airport.model";
import { FlightService, IFlightService } from "../../services/flight.service";
import { LoadState } from "../../utils/helpers";
import SelectToggleMenu, { SelectOption } from "../select-toggle-menu";
import SelectToggleMenuSkeleton from "../select-toggle-menu/SelectToggleMenuSkeleton";

export interface Props {
  airports?: string[];
  isDisabled?: boolean;
  loadState?: LoadState;
  menuPortalTarget?: any;
  menuPosition?: "left" | "right";
  multiSelect?: boolean;
  onChange: (airports: AirportModel[]) => void;
  searchPlaceholder?: string;
  selectedAirports?: AirportModel[];
  width?: number | string;
}

// @todo remove airports prop, unused, collides with selectedAirports
// @todo selected airports should be cached here for chip display
// @todo ability to pass just the airport id, or airport iata, or airport full name as the selected value

export default function AirportSelect(props: Props) {
  const {
    airports,
    isDisabled,
    loadState = "loaded",
    onChange,
    menuPortalTarget,
    menuPosition,
    multiSelect,
    searchPlaceholder,
    selectedAirports,
    width
  } = props;

  const flightService: IFlightService = AppContainer.get(FlightService);

  const selectedOptionIds = selectedAirports?.length
    ? selectedAirports.map((airport: AirportModel) => airport.id)
    : [];

  const selectOptions = airports || selectedAirports;

  const handleSearch = async (keyword: string): Promise<SelectOption[]> => {
    return flightService.airportSearch(keyword);
  };

  if (loadState !== "loaded") {
    return <SelectToggleMenuSkeleton />;
  }

  return (
    <SelectToggleMenu
      createOption={true}
      isDisabled={isDisabled}
      menuPortalTarget={menuPortalTarget}
      menuPosition={menuPosition}
      multiSelect={multiSelect}
      onChange={(options: SelectOption[]) =>
        onChange(
          options.map((option: SelectOption) => new AirportModel(option))
        )
      }
      onOption={(option: SelectOption) => {
        const airport = new AirportModel(option);
        return airport.getFullName();
      }}
      onSearch={handleSearch}
      onSelectedOption={(option: SelectOption) => (
        <AirportChip airport={option as AirportModel} />
      )}
      options={selectOptions ? (selectOptions as SelectOption[]) : []}
      placeholder="Select Airports"
      searchPlaceholder={searchPlaceholder}
      selectedOptionIds={selectedOptionIds}
      width={width}
    />
  );
}
