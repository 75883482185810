import React from "react";
import Button from "../button";

import { AuthService, IAuthService } from "../../services/auth.service";
import AppContainer from "../../container";
import useModal from "../../hooks/use-modal.hook";

interface Props {}

export default function ImpersonateBar(props: Props) {
  const authService: IAuthService = AppContainer.get(AuthService);
  const { openModal } = useModal();

  const onClick = () => {
    const actionOpts = {
      buttonText: "Continue",
      dialogTitle: "Exit Impersonation Mode",
      dialogBody: "Are you sure you want to exit impersonation mode?",
      onConfirm: () => {
        authService.logout();
      }
    };

    openModal("confirm", actionOpts);
  };

  return (
    <div className="impersonation-indicator-wrapper">
      <Button
        alignment="align-center"
        color="red"
        isFullWidth={true}
        label="Impersonation Mode Currently Active - Click to Exit"
        onClick={onClick}
        size="large"
      />
    </div>
  );
}
