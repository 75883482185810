import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { cloneDeep } from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import AppIcon from "../../app-icon";
import Button from "../../button";
import CloseButton from "../../button/close";
import Loader from "../../loader";
import RemoveButton from "../../button/remove";
import Tooltip from "../../tooltip";

import AppContainer from "../../../container";
import useApp from "../../../hooks/use-app.hook";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";
import {
  SegmentService,
  ISegmentService
} from "../../../services/segment.service";
import { TripService, ITripService } from "../../../services/trip.service";
import { TagService, ITagService } from "../../../services/tag.service";
import {
  IWorkspaceService,
  TemplateInput,
  WorkspaceService
} from "../../../services/workspace.service";

import SegmentModel from "../../../models/segment.model";
import TagModel from "../../../models/tag.model";
import TagGroupModel from "../../../models/tag-group.model";
import TripModel from "../../../models/trip.model";
import UserModel from "../../../models/user.model";

import {
  getDisplayDate,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";
import {
  getDefaultDate,
  getInputComponent,
  handleDateChange
} from "./helper-funcs";

import "./quick-add-dialog.scss";
import { AppContext } from "../../../context";

const dataTypeLabelHash: { [index: string]: string } = {
  AIR: "Flight",
  RAIL: "Rail"
};

const segmentInputKeys: { [index: string]: string[] } = {
  AIR: [
    "fromForSearch",
    "name",
    "flightNum",
    "from",
    "fromLocation",
    "to",
    "toLocation"
  ],
  RAIL: ["name", "trainNum", "from", "fromLocation", "to", "toLocation"]
};

const PaperComponent = (props: ObjectHash) => (
  <Draggable cancel={"[class*='not-draggable']"}>
    <Paper {...props} />
  </Draggable>
);

interface Props {
  data: ObjectHash;
  dataType: string;
  locationsData: any[];
  onChange?: CallableFunction; // callback to update other components when changes are committed inside modal
  resource: string;
}

export default function QuickAddDialogLarge(props: Props) {
  const segmentService: ISegmentService = AppContainer.get(SegmentService);
  const tripService: ITripService = AppContainer.get(TripService);
  const tagService: ITagService = AppContainer.get(TagService);
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const { setSnackbar } = useSnackbar();
  const { settings } = useApp();
  const { closeModal, linkModal, openModal } = useModal();

  const { data, dataType, locationsData, onChange } = props;

  const isNew = !Boolean(data?.id);

  useEffect(() => {
    if (!isNew) {
      linkModal("quick-add-large", { trip: data.id });
    }
  }, [data, isNew, linkModal]);

  const templateInputs = workspaceService.getTemplateInputs(dataType);
  const segmentKeys = segmentInputKeys[dataType];

  const tripInputs: TemplateInput[] = [];
  const segmentInputs: TemplateInput[] = [];

  templateInputs.forEach((templateInput: TemplateInput) => {
    if (segmentKeys.includes(templateInput.key)) {
      segmentInputs.push(templateInput);
    } else {
      tripInputs.push(templateInput);
    }
  });

  const domRef = useRef();

  const [menuPortalTarget, setMenuPortalTarget] = useState<any>(null);
  const [segmentState, setSegmentState] = useState<{
    [index: string]: any;
  }>({
    hasLoaded: false,
    segments: [],
    uniqueDates: []
  });
  // track if form changed for trip or segment to render button style
  const [tripFormChanged, setTripFormChanged] = useState(false);
  const [segmentFormChanged, setSegmentFormChanged] = useState(false);

  const tripZeroState: ObjectHash = {
    id: null,
    type: dataType
  };

  tripInputs.forEach((templateInput: TemplateInput) => {
    const { key, inputType, isMultiple } = templateInput;
    let value: null | string | SelectOption[] | string[] = "";

    if (isMultiple) {
      value = null;
    }

    if (inputType === "tag") {
      value = [];
    }

    if (["fromForSearch", "from", "to"].includes(key)) {
      value = getDefaultDate(dataType, key);
    }

    if (!isNew) {
      value = data[key];

      // separate trip tags out into their tag groups for input pre-fills
      if (inputType === "tag") {
        const { tagGroup } = templateInput;
        if (tagGroup) {
          if (data.tags?.length) {
            value = data.tags
              .filter((tag: TagModel) => tag.tagGroup === tagGroup.id)
              .map((tag: TagModel) => tag.id);
          }
        }
      }

      // convert user objects into array of options using name and id
      if (key === "users") {
        value = (data?.users || []).map((user: ObjectHash) => ({
          label: user.name,
          value: user.id,
          meta: new UserModel(user) // @todo remove this casting per #347
        }));
      }
    }

    tripZeroState[key] = value;
  });

  const dataIsFrozen = workspaceService.dataIsFrozen(data);

  const updateTags = (options: any, tagGroup: TagGroupModel) => {
    const updatedFormState = cloneDeep(tripFormState);
    updatedFormState[tagGroup.getFieldId()] = options.map(
      (option: ObjectHash) => option.tag.id
    );

    // apply tag presets
    const tagNames = options.map((option: ObjectHash) => option.tag.name);
    const tagUpdates = tagService.getPresetChanges(
      tagGroup,
      tagNames.shift(),
      tagGroups
    );

    tagUpdates.forEach(
      (tagUpdate: { tagGroup: TagGroupModel; tagIds: string[] }) => {
        const { tagGroup } = tagUpdate;
        updatedFormState[tagGroup.getFieldId()] = tagUpdate.tagIds;
      }
    );

    setTripFormState(updatedFormState);
    setTripFormChanged(true);
  };

  const { tagGroups } = settings;

  const [tripFormState, setTripFormState] = useState({
    ...tripZeroState
  });

  const segmentZeroState: ObjectHash = {
    id: null,
    type: dataType
  };

  segmentInputs.forEach((templateInput: TemplateInput) => {
    const { key } = templateInput;
    let value: null | string = "";

    if (["fromForSearch", "from", "to"].includes(key)) {
      value = getDefaultDate(dataType, key);
    }

    segmentZeroState[key] = value;
  });

  const getUniqueDates = (segments: any[]) =>
    [...new Set(segments.map((segment) => segment.getLegDate()))].sort(
      (a, b) => {
        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      }
    );

  const [segmentFormState, setSegmentFormState] = useState({
    ...segmentZeroState
  });

  const requiredInputs = ["name"];
  const formValid = (): boolean => {
    return requiredInputs.every((key: string) =>
      Boolean(segmentFormState[key])
    );
  };

  // fetch trip's segments
  let segments: any[] = [];
  if (!segmentState.hasLoaded && !segmentState.segments.length) {
    tripService.getById(data.id).then((trip: TripModel | null) => {
      if (trip) {
        segments = trip.segments
          .filter(
            (segment: ObjectHash) =>
              !segment.deleted || (data.cancelled && segment.cancelled)
          )
          .sort((objA: ObjectHash, objB: ObjectHash) => {
            const a = objA.from;
            const b = objB.from;
            if (a === b) {
              return 0;
            }

            return a < b ? -1 : 1;
          });
      }

      const uniqueDates = getUniqueDates(segments);
      const newSegmentFormState = cloneDeep(segmentZeroState);
      setSegmentState((oldValues: ObjectHash) => ({
        ...oldValues,
        hasLoaded: true,
        segments,
        uniqueDates
      }));

      // Set first leg as active segment form
      Object.entries(segments[0] || {}).forEach(([key, value]) => {
        if (Object.hasOwnProperty.call(segmentZeroState, key)) {
          // use empty string instead of null to prevent prop console warning
          newSegmentFormState[key] = value || "";
          if (key === "from") {
            newSegmentFormState.fromForSearch = value || "";
          }
        }
      });

      setSegmentFormState((oldValues) => ({
        ...oldValues,
        ...newSegmentFormState
      }));
    });
  }

  const resetTripFormState = () => {
    setTripFormState(() => ({
      ...tripZeroState
    }));
  };

  const handleClose = (skipConfirm: boolean) => {
    if (
      (tripFormChanged || segmentFormChanged) &&
      !skipConfirm &&
      !dataIsFrozen
    ) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "You have unsaved changes. Are you sure you want to close?",
        onConfirm: () => {
          closeModal();
          resetTripFormState();
        }
      });
    } else {
      closeModal();
      resetTripFormState();
    }
  };

  const handleInputChange = (key: string, value: any, meta?: ObjectHash) => {
    const segmentInputKeys = segmentInputs.map(
      (templateInput: TemplateInput) => templateInput.key
    );
    let stateSetter = setTripFormState;
    if (segmentInputKeys.includes(key)) {
      stateSetter = setSegmentFormState;
    }

    // related dates validation
    if (["from", "fromForSearch", "to"].includes(key)) {
      handleDateChange(key, value, stateSetter, segmentFormState);
    }

    // set timezones when updating airport departure/destination
    else if (
      ["fromLocation", "toLocation"].includes(key) &&
      dataType === "AIR"
    ) {
      if (key === "fromLocation") {
        stateSetter((oldValues) => ({
          ...oldValues,
          fromLocation: value,
          fromTimezone: meta?.timezoneRegionName || ""
        }));
      } else if (key === "toLocation") {
        stateSetter((oldValues) => ({
          ...oldValues,
          toLocation: value,
          toTimezone: meta?.timezoneRegionName || ""
        }));
      }
    } else if (key === "flightNum") {
      /*
       * When selecting a flight number, populate all other relevant
       * arrival and departure fields based on the flight info
       */
      stateSetter((oldValues: ObjectHash) => {
        const updates: ObjectHash = { flightNum: value };

        if (meta) {
          updates.from = meta.from || "";
          updates.fromLocation = meta.fromLocation || "";
          updates.fromTimezone = meta.fromTimezone || "";
          updates.to = meta.to || "";
          updates.toLocation = meta.toLocation || "";
          updates.toTimezone = meta.fromTimezone || "";
        }

        return {
          ...oldValues,
          ...updates
        };
      });
    } else {
      stateSetter((oldValues) => ({ ...oldValues, [key]: value }));
    }

    if (stateSetter === setSegmentFormState) {
      setSegmentFormChanged(true);
    } else {
      setTripFormChanged(true);
    }
  };

  const getPayload = (formState: ObjectHash): ObjectHash => {
    const payload: ObjectHash = { ...formState, tags: [] };

    Object.keys(payload).forEach((key: string) => {
      const value = payload[key];

      // turn group-separated tag ids back into a single array
      if (TagGroupModel.isFieldId(key)) {
        if (value?.length) {
          payload.tags = payload.tags.concat(value);
        }
        delete payload[key];
      }

      if (key === "users") {
        payload[key] = (value || []).map(
          (option: SelectOption) => option.value
        );
      }
    });

    return payload;
  };

  const handleChangeTripClick = () => {
    const payload = getPayload(tripFormState);
    tripService.update(data.id, payload).then(() => {
      onChange && onChange();
      setTripFormChanged(false);
      setSnackbar({
        message: "Trip updated!",
        variant: "success"
      });
    });
  };

  const handleDeleteTripClick = () => {
    openModal("confirm", {
      dialogTitle: "Delete Trip",
      dialogBody:
        "Are you sure you want to delete this trip? This cannot be undone.",
      onConfirm: () => {
        tripService.delete(data.id).then(() => {
          onChange && onChange();
          handleClose(true);
          setSnackbar({
            message: "Trip deleted!",
            variant: "success"
          });
        });
      }
    });
  };

  const handleAddSegmentClick = async () => {
    const payload: ObjectHash = getPayload(segmentFormState);
    payload.tripId = data.id;
    const newSegment: SegmentModel | null = await segmentService.create(
      payload
    );
    if (newSegment) {
      const newSegments = segmentState.segments.slice();
      newSegments.push(newSegment);
      const newUniqueDates = getUniqueDates(newSegments);
      setSegmentFormState((oldValues) => ({
        ...oldValues,
        id: newSegment.id
      }));
      setSegmentState((oldValues) => ({
        ...oldValues,
        segments: newSegments,
        uniqueDates: newUniqueDates
      }));
      setSegmentFormChanged(false);
      onChange && onChange();
      setSnackbar({
        message: "Segment created!",
        variant: "success"
      });
    }
  };

  const handleChangeSegmentClick = async () => {
    const payload: ObjectHash = getPayload(segmentFormState);
    payload.tripId = data.id;
    const updatedSegment: SegmentModel | null = await segmentService.update(
      segmentFormState.id,
      payload
    );
    if (updatedSegment) {
      const newSegments = segmentState.segments.slice();
      const changedSegment = newSegments.find(
        (segment: ObjectHash) => segment.id === updatedSegment.id
      );
      Object.entries(updatedSegment).forEach(([k, v]) => {
        changedSegment[k] = v;
      });
      const newUniqueDates = getUniqueDates(newSegments);
      setSegmentState((oldValues) => ({
        ...oldValues,
        segments: newSegments,
        uniqueDates: newUniqueDates
      }));
      setSegmentFormChanged(false);
      onChange && onChange();
      setSnackbar({
        message: "Leg updated!",
        variant: "success"
      });
    }
  };

  const handleDeleteSegmentClick = (segmentId: string) => {
    openModal("confirm", {
      dialogTitle: "Delete Leg",
      dialogBody:
        "Are you sure you want to delete this leg? This cannot be undone.",
      onConfirm: async () => {
        const deletedSegment: SegmentModel | null = await segmentService.delete(
          segmentId
        );
        if (deletedSegment) {
          const newSegments = segmentState.segments.filter(
            (segment: ObjectHash) => segment.id !== segmentId
          );
          const newUniqueDates = getUniqueDates(newSegments);
          setSegmentFormState(() => ({ ...segmentZeroState }));
          setSegmentState((oldValues) => ({
            ...oldValues,
            segments: newSegments,
            uniqueDates: newUniqueDates
          }));
          setSegmentFormChanged(false);
          onChange && onChange();
          setSnackbar({
            message: "Leg deleted!",
            variant: "success"
          });
        }
      }
    });
  };

  const handleAddLegButtonClick = () => {
    setSegmentFormState(() => ({
      ...segmentZeroState
    }));

    // Focus first input in segment form
    const segmentForm = document.getElementById("segment-form");
    if (segmentForm) {
      const inputToFocus = segmentForm.querySelector("input");
      if (inputToFocus) {
        inputToFocus.focus();
      }
    }
  };

  const handleSegmentButtonClick = (segmentId: string) => {
    setSegmentFormChanged(false);

    const selectedSegment = segmentState.segments.find(
      (segment: ObjectHash) => segment.id === segmentId
    );
    const newSegmentFormState = JSON.parse(JSON.stringify(segmentZeroState));
    Object.entries(selectedSegment).forEach(([k, v]) => {
      if (Object.hasOwnProperty.call(segmentZeroState, k)) {
        // use empty string instead of null to prevent prop console warning
        const value = v || "";
        newSegmentFormState[k] = value;
        if (k === "from") {
          newSegmentFormState.fromForSearch = value;
        }
      }
    });

    setSegmentFormState((oldValues) => ({
      ...oldValues,
      ...newSegmentFormState
    }));
  };

  const tripInputElements = tripInputs.map((templateInput: TemplateInput) => {
    const { inputType, key, presetExclude } = templateInput;

    if (presetExclude) {
      return <></>;
    }

    const inputOpts = templateInput as ObjectHash;

    if (inputType === "tag") {
      const { tagGroup = new TagGroupModel() } = templateInput;
      inputOpts.tagGroup = tagGroup;

      if (tagGroup.id) {
        inputOpts.selectedTagIds = tripFormState[key]?.length
          ? tripFormState[key]
          : [];
      }
      inputOpts.onChange = updateTags;
    }

    return getInputComponent(
      key,
      inputOpts,
      dataType,
      handleInputChange,
      tripFormState,
      [],
      menuPortalTarget,
      false
    );
  });

  const segmentInputElements = segmentInputs.map(
    (templateInput: TemplateInput) => {
      const { inputType, key, presetExclude } = templateInput;

      if (presetExclude) {
        return <></>;
      }

      const inputOpts = templateInput as ObjectHash;

      if (inputType === "tag") {
        const { tagGroup = new TagGroupModel() } = templateInput;
        inputOpts.tagGroup = tagGroup;

        if (tagGroup.id) {
          inputOpts.selectedTagIds = segmentFormState[key]?.length
            ? segmentFormState[key]
            : [];
        }
        inputOpts.onChange = updateTags;
      }

      return getInputComponent(
        key,
        inputOpts,
        dataType,
        handleInputChange,
        segmentFormState,
        locationsData || [],
        menuPortalTarget,
        requiredInputs.includes(key)
      );
    }
  );

  return (
    <AppContext.Consumer>
      {(appState) => (
        <Dialog
          aria-labelledby="form-dialog-title"
          classes={{
            root: "quick-add-dialog quick-add-dialog-large",
            paper: "paper",
            paperScrollPaper: "paper-scroll-paper",
            paperWidthSm: "paper-width-sm"
          }}
          onClose={() => handleClose(false)}
          onEntered={() => setMenuPortalTarget(domRef.current)}
          open={true}
          PaperComponent={PaperComponent}
          ref={domRef}
        >
          <DialogTitle style={{ cursor: "move" }}>
            <div className="quick-add-dialog__title">
              <div className="quick-add-dialog__title">
                <h1 style={{ fontSize: "24px" }}>{`${
                  dataIsFrozen ? "View" : "Edit"
                } ${dataTypeLabelHash[dataType]}`}</h1>
                {dataIsFrozen && (
                  <div className="quick-add-dialog__lock-indicator">
                    <AppIcon type="lock" size="x-small" />
                    <span>LOCKED</span>
                  </div>
                )}
                <div>
                  <p
                    style={{
                      fontSize: 12,
                      marginLeft: 12,
                      verticalAlign: "bottom",
                      color: "#2e71f1"
                    }}
                  >
                    Last Updated:{" "}
                    {getDisplayDate(data.updatedAt, settings.dateTimeFormat)}
                  </p>
                </div>
              </div>
              <div className="quick-add-dialog__button-group">
                {data &&
                  !dataIsFrozen &&
                  (dataType === "PROFILE" ||
                    !appState.user.email?.endsWith("@nfl.com")) && (
                    <Tooltip text="Delete Trip">
                      <Button
                        color="gray"
                        icon="trash"
                        isRippleDisabled={true}
                        isTransparent={true}
                        onClick={() => handleDeleteTripClick()}
                        size="medium"
                      />
                    </Tooltip>
                  )}
                <Tooltip text="Close">
                  <CloseButton
                    onClick={() => handleClose(false)}
                    size="medium"
                  />
                </Tooltip>
              </div>
            </div>
          </DialogTitle>
          <DialogContent
            classes={{ root: "dialog-content" }}
            className="not-draggable"
          >
            <div className="quick-add-dialog__form">
              <div className="quick-add-dialog__form-header-wrapper">
                <h2 className="quick-add-dialog__form-header">Trip Details</h2>
                {data && data.gdsLocator && data.gdsLocator.length && (
                  <div className="quick-add-dialog__gds-info">
                    <AppIcon type="globe" color="green" />
                    <span>{`GDS Locator: ${data.gdsLocator}`}</span>
                  </div>
                )}
              </div>
              {tripInputElements}
              {!dataIsFrozen && (
                <Button
                  alignment="align-center"
                  color="product-blue"
                  isDisabled={!tripFormChanged}
                  isFullWidth={true}
                  label="Save Changes"
                  onClick={() => handleChangeTripClick()}
                  size="medium"
                />
              )}
            </div>
            <div className="quick-add-dialog__segment-selector">
              <div className="quick-add-dialog__form-header-wrapper">
                <h2 className="quick-add-dialog__form-header">Legs</h2>
                {!dataIsFrozen && (
                  <Button
                    color="product-blue"
                    icon="plus-circle"
                    isDisabled={!segmentFormState.id}
                    isRippleDisabled={true}
                    isTransparent={true}
                    label="Add Leg"
                    onClick={() => handleAddLegButtonClick()}
                    size="medium"
                  />
                )}
              </div>
              {segmentState.uniqueDates.length
                ? segmentState.uniqueDates.map((date: string) => (
                    <div
                      key={`segment-selector-date-group-${date}`}
                      className="segment-selector-date-group"
                    >
                      <h3
                        key={`segment-selector-date-${date}`}
                        className="segment-selector-date-header"
                      >
                        {getDisplayDate(date, "EEEE - MMMM d, yyyy")}
                      </h3>
                      {segmentState.segments
                        .filter(
                          (segment: SegmentModel) =>
                            segment.getLegDate() === date
                        )
                        .sort((objA: ObjectHash, objB: ObjectHash) => {
                          const a = objA.from;
                          const b = objB.from;

                          if (a < b) {
                            return -1;
                          }
                          if (a > b) {
                            return 1;
                          }
                          return 0;
                        }, [])
                        .map((segment: ObjectHash) => (
                          <div
                            className="segment-selector-button-group"
                            key={`segment-selector-button-group${segment.id}`}
                          >
                            <Button
                              key={`segment-selector-button-${segment.id}`}
                              alignment="align-center"
                              color={
                                segmentFormState.id === segment.id
                                  ? "product-background-blue"
                                  : "gray"
                              }
                              isBordered={true}
                              isFullWidth={true}
                              isTransparent={segmentFormState.id !== segment.id}
                              label={`${
                                segment.fromLocation || "undefined"
                              } to ${segment.toLocation || "undefined"}`}
                              onClick={() => {
                                if (segmentFormState.id !== segment.id) {
                                  handleSegmentButtonClick(segment.id);
                                }
                              }}
                              size="medium"
                            />
                            {!dataIsFrozen && (
                              <Tooltip text="Delete Leg" placement="right">
                                <RemoveButton
                                  key={`delete-segment-button-${segment.id}`}
                                  isDisabled={segmentState.segments.length <= 1}
                                  onClick={() =>
                                    handleDeleteSegmentClick(segment.id)
                                  }
                                  size="medium"
                                />
                              </Tooltip>
                            )}
                          </div>
                        ))}
                    </div>
                  ))
                : !segmentState.hasLoaded && <Loader type="dots" />}
            </div>
            <div id="segment-form" className="quick-add-dialog__form">
              <div className="quick-add-dialog__form-header-wrapper">
                <h2 className="quick-add-dialog__form-header">
                  {segmentFormState.id ? "Leg Details" : "Add Leg"}
                </h2>
              </div>
              {segmentInputElements}
              {!dataIsFrozen && (
                <Button
                  alignment="align-center"
                  color="product-blue"
                  isDisabled={!segmentFormChanged || !formValid()}
                  isFullWidth={true}
                  label={segmentFormState.id ? "Save Leg Changes" : "Add Leg"}
                  onClick={() =>
                    segmentFormState.id
                      ? handleChangeSegmentClick()
                      : handleAddSegmentClick()
                  }
                  size="medium"
                />
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </AppContext.Consumer>
  );
}
