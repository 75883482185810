import React, { useEffect, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import AppIcon from "../../app-icon";
import Tooltip from "../../tooltip";
import {
  getTextValueProps,
  MuiVariant,
  ObjectHash
} from "../../../utils/helpers";
import "./inputs.scss";

const emailIsValid = (email: string) =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

interface Props {
  autoFocus?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  label?: string;
  margin?: "none" | "dense" | "normal" | undefined;
  InputProps?: ObjectHash;
  inputProps?: ObjectHash;
  isRequired?: boolean;
  onClick?: any;
  onChange: CallableFunction;
  placeholder?: string;
  style?: ObjectHash;
  type?: string;
  value?: string;
  variant?: MuiVariant;
}

export default function EmailInput(props: Props) {
  const {
    autoFocus,
    defaultValue,
    disabled,
    label,
    margin,
    InputProps,
    inputProps,
    isRequired,
    onClick,
    onChange,
    placeholder,
    style,
    type,
    value,
    variant
  } = props;

  const [inputValid, setInputValid] = useState(true);
  const [showValid, setShowValid] = useState(
    Boolean(value) || Boolean(defaultValue)
  );

  let disableUnderline = {};
  if (variant && ["filled", "standard"].includes(variant)) {
    disableUnderline = { disableUnderline: true };
  }

  useEffect(() => {
    setShowValid(Boolean(value));
  }, [value]);

  return (
    <TextField
      {...getTextValueProps(props)}
      fullWidth
      autoFocus={autoFocus}
      autoComplete="off"
      className="email-input"
      disabled={disabled || false}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {showValid && (
              <React.Fragment>
                {inputValid ? (
                  <AppIcon type="checkmark-circle" color="green" />
                ) : (
                  <Tooltip text="Invalid email">
                    <span>
                      <AppIcon type="error" color="yellow" />
                    </span>
                  </Tooltip>
                )}
              </React.Fragment>
            )}
          </InputAdornment>
        ),
        ...disableUnderline,
        ...InputProps
      }}
      inputProps={{
        ...inputProps,
        autoComplete: "new-password" // disables Chrome autocomplete
      }}
      label={label}
      margin={margin}
      onClick={onClick}
      onChange={(e) => {
        const value = e.target.value;
        onChange(value);
        setInputValid(emailIsValid(value));
        setShowValid(Boolean(value));
      }}
      placeholder={placeholder}
      required={isRequired || false}
      style={{ ...style, paddingRight: "0" }}
      type={type || "text"}
      variant={variant || "outlined"}
    />
  );
}
