import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import { ObjectHash } from "../../../utils/helpers";

import TravelerSelect from "../../traveler-select";
import { SelectOption } from "../../select-toggle-menu";

const TravelerFilter = new TravelFilterModel({
  field: TravelFilterFields.Travelers,
  name: "Traveler",
  group: TravelFilterGroups.People,
  resource: TravelFilterResources.Trip,
  component: TravelerSelect,
  onComponentProps: function (filterProps: ObjectHash) {
    const { value, meta } = this;

    filterProps.selectedUsers = [];

    if (value?.length && meta?.users) {
      value.forEach((userId: string) => {
        const user = meta.users.get(userId);
        if (!user) {
          return;
        }
        filterProps.selectedUsers.push(user);
      });
    }

    filterProps.multiSelect = true;
    filterProps.width = 300;

    return filterProps;
  },
  onChange: function (options: SelectOption[]) {
    if (!this.meta.users) {
      this.meta.users = new Map();
    }
    return options.map((option: SelectOption) => {
      this.meta.users.set(option.id, option);
      return option.id;
    });
  }
});

export default TravelerFilter;
