import React from "react";

interface Props {
  isDisabled?: boolean;
  link: string;
  onClick: CallableFunction;
  title: string;
}

export default function SectionHeader(props: Props) {
  const { isDisabled = false, link, onClick, title } = props;
  return (
    <div className="section-header">
      <div className="section-header-title">{title}</div>
      <div
        className={`section-header-link${
          isDisabled ? " section-header-link--disabled" : ""
        }`}
        onClick={() => !isDisabled && onClick()}
      >
        {link}
      </div>
    </div>
  );
}
