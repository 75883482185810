import React from "react";
import Button, { ButtonSizes } from "..";
import "./remove-button.scss";

export interface Props {
  isDisabled?: boolean;
  onClick?: CallableFunction;
  size: ButtonSizes;
}

const RemoveButton = (props: Props) => {
  const { isDisabled, onClick, size } = props;

  return (
    <div className="remove-button">
      <Button
        color="gray"
        icon="remove-circle"
        isDisabled={isDisabled || false}
        isRippleDisabled={true}
        isTransparent={true}
        onClick={onClick}
        size={size || "small"}
      />
    </div>
  );
};

export default RemoveButton;
