import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "../../button";
import CloseButton from "../../button/close";
import Tooltip from "../../tooltip";
import UserModel from "../../../models/user.model";

interface Props {
  deleteTooltip?: string;
  hideClose?: boolean;
  onClose: CallableFunction;
  onDelete?: CallableFunction;
  title: JSX.Element;
  user?: UserModel;
}

export default function UserDialogTitle(props: Props) {
  const { deleteTooltip, hideClose = false, onClose, onDelete, title } = props;

  return (
    <DialogTitle classes={{ root: "dialog-title" }} style={{ cursor: "move" }}>
      <div className="title">{title}</div>

      <div className="tools">
        <div className="profile-icon"></div>

        <div className="buttons">
          {Boolean(onDelete) && (
            <Tooltip text={deleteTooltip || ""}>
              <Button
                color="gray"
                icon="trash"
                isRippleDisabled={true}
                isTransparent={true}
                onClick={() => onDelete && onDelete()}
                size="medium"
              />
            </Tooltip>
          )}
          {!hideClose && (
            <div className="close-button">
              <Tooltip text="Close">
                <CloseButton onClick={() => onClose(false)} size="medium" />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </DialogTitle>
  );
}
