import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

import env from "../../utils/env";
import useApp from "../../hooks/use-app.hook";
import AppContainer from "../../container";
import {
  IImpersonateService,
  ImpersonateService
} from "../../services/impersonate.service";

interface Props {}

export default function Analytics(props: Props) {
  const { user } = useApp();
  const impersonateService: IImpersonateService = AppContainer.get(
    ImpersonateService
  );

  /*
   * Datadog
   */
  useEffect(() => {
    if (!env.datadog?.applicationId) {
      return;
    }

    datadogRum.init({
      ...env.datadog,
      sampleRate: 100,
      trackInteractions: false,
      silentMultipleInit: true
    });
  }, []);

  /*
   * Segment.com
   */
  useEffect(() => {
    if (!env.segment?.apiKey || (window as any).segmentLoaded) {
      return;
    }

    // must be signed-in as self
    if (!user?.id || impersonateService.isActive()) {
      return;
    }

    (window as any).segmentLoaded = true;

    let analytics = ((window as any).analytics =
      (window as any).analytics || []);
    if (!analytics.initialize) {
      if (analytics.invoked) {
        console.error("Segment snippet included twice.");
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on"
        ];
        analytics.factory = function (t: any) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t: any, e: any) {
          var n = document.createElement("script");
          n.type = "text/javascript";
          n.async = !0;
          n.src =
            ("https:" === document.location.protocol ? "https://" : "http://") +
            "cdn.segment.com/analytics.js/v1/" +
            t +
            "/analytics.min.js";
          var o = document.getElementsByTagName("script")[0];
          if (o.parentNode) {
            o.parentNode.insertBefore(n, o);
          }
          analytics._loadOptions = e;
        };

        analytics.SNIPPET_VERSION = "4.1.0";
        analytics.load(env.segment.apiKey);
        analytics.page();
      }
    }

    (window as any).analytics.identify(
      user.migratedId || user.id,
      {
        company: {
          id: user.companyMigratedId || user.companyId,
          name: user.companyName
        },
        email: user.email,
        name: user.name,
        role: user.role
      },
      {
        integrations: {
          Intercom: {
            user_hash: user.intercomToken
          }
        }
      }
    );
  }, [impersonateService, user]);

  return null;
}
