import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { cloneDeep, isEqual, uniqueId } from "lodash";

import AppContainer from "../../../container";
import { formIsValid, FormValidator } from "../../../utils/form";
import InputController from "../../form/input-controller";
import { ITagService, TagService } from "../../../services/tag.service";
import { LoadState, ObjectHash } from "../../../utils/helpers";
import TagGroupModel from "../../../models/tag-group.model";
import { TemplateInput } from "../../../services/workspace.service";
import useApp from "../../../hooks/use-app.hook";
import usePrevious from "../../../hooks/use-previous.hook";

interface Props {
  fields: TemplateInput[];
  loadState?: LoadState;
  model?: ObjectHash;
  readOnly?: boolean;
  onCustomProps?: (
    templateInput: TemplateInput,
    customProps: ObjectHash
  ) => ObjectHash;
  onFormChange?: CallableFunction;
  onReadOnly?: CallableFunction;
  onSave?: CallableFunction;
  onValidate?: CallableFunction;
  onZeroState?: CallableFunction;
  validator?: FormValidator;
}

const FormController = forwardRef((props: Props, ref: any) => {
  const tagService: ITagService = AppContainer.get(TagService);

  const { settings } = useApp();
  const { tagGroups } = settings;

  const {
    fields,
    loadState = "loaded",
    model = {},
    onCustomProps,
    onFormChange,
    onReadOnly,
    onSave,
    onValidate,
    onZeroState,
    readOnly,
    validator
  } = props;

  const getZeroState = useCallback((): ObjectHash => {
    let zeroState: ObjectHash = {};
    fields.forEach((templateInput: TemplateInput) => {
      const { isMultiple, key } = templateInput;
      const emptyValue = isMultiple ? null : "";
      let value = model && model[key as any] ? model[key as any] : emptyValue;
      zeroState[key] = value;
    });

    if (onZeroState) {
      zeroState = onZeroState(zeroState);
    }

    return zeroState;
  }, [fields, model, onZeroState]);

  const initState = getZeroState();
  const [formId] = useState<string>(uniqueId("tg-form-"));

  const [zeroState, setZeroState] = useState<ObjectHash>(initState);
  const [formState, setFormState] = useState<ObjectHash>(initState);
  const hasValidator = Boolean(validator);

  // add model to meta for all validators as a convenience
  const validatorMeta = useMemo(() => {
    if (!validator) {
      return {};
    }

    const validatorMeta: FormValidator = {};
    Object.keys(validator).forEach((key: string) => {
      validatorMeta[key] = { ...validator[key], meta: { model } };
    });

    return validatorMeta;
  }, [model, validator]);

  const oldModel = usePrevious(model);

  useEffect(() => {
    if (isEqual(oldModel, model)) {
      return;
    }
    const zeroState = getZeroState();
    setZeroState(zeroState);
    setFormState(zeroState);
  }, [getZeroState, model, oldModel]);

  useEffect(() => {
    const formValid = !hasValidator || formIsValid(validatorMeta, formState);
    onValidate && onValidate(formValid);
  }, [formState, hasValidator, validatorMeta, onValidate]);

  const handleInputChange = (key: string, value: any, meta?: ObjectHash) => {
    onFormChange && onFormChange(true);
    setFormState((oldValues) => ({ ...oldValues, [key]: value }));
  };

  const handleSave = () => {
    if (hasValidator && !formIsValid(validatorMeta, formState)) {
      return;
    }
    onSave && onSave(formState);
  };

  const handleReset = () => {
    onFormChange && onFormChange(false);
    setFormState(zeroState);
  };

  useImperativeHandle(ref, () => ({
    save: handleSave,
    reset: handleReset
  }));

  const updateTags = (options: ObjectHash[], tagGroup: TagGroupModel) => {
    const updatedFormState = cloneDeep(formState);
    updatedFormState[tagGroup.getFieldId()] = options.map(
      (option: ObjectHash) => option.tag.id
    );

    // apply tag presets
    const tagNames = options.map((option: ObjectHash) => option.tag.name);
    const tagUpdates = tagService.getPresetChanges(
      tagGroup,
      tagNames.shift(),
      tagGroups
    );

    tagUpdates.forEach(
      (tagUpdate: { tagGroup: TagGroupModel; tagIds: string[] }) => {
        const { tagGroup } = tagUpdate;
        updatedFormState[tagGroup.getFieldId()] = tagUpdate.tagIds;
      }
    );

    onFormChange && onFormChange(true);
    setFormState(updatedFormState);
  };

  const inputElements = fields.map(
    (templateInput: TemplateInput, index: number) => {
      const { inputType, key } = templateInput;
      const compKey = `${formId}-${key}-${index}`;

      let customProps: ObjectHash = {
        onChange: (value: any, meta?: ObjectHash) =>
          handleInputChange(key, value, meta),
        variant: "filled" // for non react-select input filled style
      };

      if (inputType === "tag") {
        customProps.onChange = updateTags;
      }

      if (onCustomProps) {
        customProps = onCustomProps(templateInput, customProps);
      }

      return (
        <InputController
          key={compKey}
          customProps={customProps}
          loadState={loadState}
          model={formState}
          onReadOnly={onReadOnly}
          templateInput={templateInput}
          readOnly={readOnly}
          validator={hasValidator ? validatorMeta[key] : null}
        />
      );
    }
  );

  return <div className="tg-form">{inputElements}</div>;
});

export default FormController;
