import { inject } from "inversify";
import BaseService from "./base.service";
import { ApiService, IApiService } from "./api.service";
import CompanyModel from "../models/company.model";
import CustomerModel from "../models/customer.model";
import AdminSearchUserModel from "../models/admin-search-user.model";
import { ObjectHash } from "../utils/helpers";

export interface IAdminService {
  getCompanies(): Promise<CompanyModel[]>;
  getCustomers(): Promise<CustomerModel[]>;
  getOnlineUsers(): Promise<AdminSearchUserModel[]>;
  searchUsers(queryString: string): Promise<AdminSearchUserModel[]>;
}

export class AdminService extends BaseService implements IAdminService {
  @inject(ApiService)
  private apiService!: IApiService;

  async getCompanies(): Promise<CompanyModel[]> {
    const response = await this.apiService.get("/admin/companies");

    if (!response) {
      return [];
    }

    return response.map((company: ObjectHash) => new CompanyModel(company));
  }

  async getCustomers(): Promise<CustomerModel[]> {
    const response = await this.apiService.get("/admin/customers");

    if (!response) {
      return [];
    }

    return response.map((customer: ObjectHash) => new CustomerModel(customer));
  }

  async getOnlineUsers(): Promise<AdminSearchUserModel[]> {
    const response = await this.apiService.get("/admin/online");

    if (!response) {
      return [];
    }

    return response.map((user: ObjectHash) => new AdminSearchUserModel(user));
  }

  async searchUsers(queryString: string): Promise<AdminSearchUserModel[]> {
    const response = await this.apiService.get(
      `/admin/search/users/${queryString}`
    );

    if (!response) {
      return [];
    }

    return response.map((user: ObjectHash) => new AdminSearchUserModel(user));
  }
}
