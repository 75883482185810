import React, { useState } from "react";
import classnames from "classnames";
import { debounce, merge } from "lodash";

import AppContainer from "../../../container";
import {
  fieldIsRequired,
  fieldIsValid,
  FieldValidator
} from "../../../utils/form";
import {
  IWorkspaceService,
  TemplateInput,
  WorkspaceService
} from "../../../services/workspace.service";
import { LoadState, ObjectHash } from "../../../utils/helpers";

import AirportAsyncCreatableInputFilled from "../../form/inputs/AirportAsyncCreatableInputFilled";
import AirportSelect from "../../airport-select";
import AutocompleteInputFilled from "../../form/inputs/AutocompleteInputFilled";
import BookingRequestStatusInput from "../inputs/BookingRequestStatusInput";
import BookingTypeInput from "../inputs/BookingTypeInput";
import CountryInput from "../../form/inputs/CountryInput";
import CurrencyInput from "../../form/inputs/CurrencyInput";
import DateInput from "../../form/inputs/DateInput";
import DateRangeSelect from "../../date-range-select";
import EmailInput from "../../form/inputs/EmailInput";
import GenderInput from "../inputs/GenderInput";
import IntegerInput from "../inputs/IntegerInput";
import LocationAsyncCreatableInputFilled from "../../form/inputs/LocationAsyncCreatableInputFilled";
import LoyaltyProgramInput from "../../form/inputs/LoyaltyProgramInput";
import ReadOnly from "../read-only";
import RoleInput from "../../form/inputs/RoleInput";
import TagGroupModel from "../../../models/tag-group.model";
import TagInput from "../../form/inputs/TagInput";
import TagModel from "../../../models/tag.model";
import TextInput from "../../form/inputs/TextInput";
import TravelerInput from "../../form/inputs/TravelerInput";
import TravelerSelect from "../../traveler-select";
import TimeInput from "../inputs/TimeInput";
import TimezoneAsyncInputFilled from "../../form/inputs/TimezoneAsyncInputFilled";

import "./input-controller.scss";

export interface Props {
  customProps?: ObjectHash;
  loadState?: LoadState;
  model: ObjectHash;
  onValidate?: CallableFunction;
  onReadOnly?: CallableFunction;
  readOnly?: boolean;
  templateInput: TemplateInput;
  validator?: FieldValidator | null;
}

/*
 * Ideally this map would be stored in WorkspaceService alongside
 * getTemplateInputProps, but refrencing component functions/classes from
 * within a service module suggest a conversion to TSX from TS, and more
 * importantly, creates a cyclical dependency issue for any referenced
 * component which also uses WorkspaceService. If other components need
 * access to this map, consider exporting getComponentByType
 */
const inputMap: Map<string, any> = new Map([
  ["airport", AirportAsyncCreatableInputFilled],
  ["airport-select", AirportSelect],
  ["booking-request-status", BookingRequestStatusInput],
  ["booking-type", BookingTypeInput],
  ["country", CountryInput],
  ["currency", CurrencyInput],
  ["date", DateInput],
  ["date-range", DateRangeSelect],
  ["email", EmailInput],
  ["gender", GenderInput],
  ["integer", IntegerInput],
  ["location", LocationAsyncCreatableInputFilled as any],
  ["loyalty-program", LoyaltyProgramInput],
  ["role", RoleInput],
  ["select", AutocompleteInputFilled],
  ["time", TimeInput],
  ["timezone", TimezoneAsyncInputFilled],
  ["tag", TagInput],
  ["user_list", TravelerInput],
  ["user-select", TravelerSelect]
]);

const getComponentByType = (inputType: string): any => {
  return inputMap.get(inputType) || TextInput;
};

export default function InputController(props: Props) {
  const workspaceService: IWorkspaceService = AppContainer.get(
    WorkspaceService
  );

  const {
    customProps,
    loadState = "loaded",
    model,
    onValidate,
    onReadOnly,
    templateInput,
    readOnly,
    validator
  } = props;

  const {
    helperText,
    inputType,
    key,
    label,
    labelHelperText,
    options,
    withTime
  } = templateInput;
  const isTag = ["tag", "tag-select"].includes(inputType);

  const isRequired: Boolean =
    Boolean(validator) && fieldIsRequired(validator as FieldValidator);
  const hasLabel = Boolean(label);
  const hasLabelHelperText = Boolean(labelHelperText);

  const [errorText, setErrorText] = useState<string>("");
  const hasError = Boolean(errorText.length);

  const InputComponent = getComponentByType(inputType);

  const value = model[key];

  const templateProps = workspaceService.getTemplateInputProps(
    templateInput,
    model
  );

  const inputProps = merge({}, templateProps, customProps || {}, {
    loadState,
    value
  });

  if (validator) {
    const { debounceMs } = validator;
    inputProps.onValidate = (value: any) => {
      const messages = fieldIsValid(value, validator);
      setErrorText(messages?.length ? messages.join(",") : "");
      onValidate && onValidate(!Boolean(messages?.length));
    };

    if (debounceMs) {
      inputProps.onValidate = debounce(inputProps.onValidate, debounceMs);
    }
  }

  if (!inputProps.onChange) {
    inputProps.onChange = () => {};
  }

  if (inputType === "textarea") {
    inputProps.isMultiline = true;
  }

  // append time input to date input
  if (withTime) {
    inputProps.withTime = true;
  }

  // set options for select inputs
  if (options) {
    inputProps.options = options;
  }

  // force traveler input to be uncontrolled
  if (inputType === "user_list") {
    inputProps.isCreatable = true;
    inputProps.defaultValue = value;
    delete inputProps.value;
  }

  // move value prop for select toggle menu components
  if (inputType === "user-select") {
    inputProps.selectedUsers = value;
    delete inputProps.value;
  }

  if (inputType === "airport-select") {
    inputProps.selectedAirports = value;
    delete inputProps.value;
  }

  if (isTag) {
    const { tagGroup = new TagGroupModel() } = templateInput;

    inputProps.tagGroup = tagGroup;
    inputProps.placeholder = "";
    inputProps.menuPlacement = "top"; // @todo move this out?
    inputProps.selectedTagIds = [];
    delete inputProps.value;

    if (tagGroup.id && Array.isArray(value)) {
      inputProps.selectedTagIds = tagGroup.tags
        .map((tag: TagModel) => tag.id)
        .filter((tagId: string) => value.includes(tagId));
    }
  }

  if (readOnly) {
    const readOnlyValue = onReadOnly ? onReadOnly(templateInput, value) : value;
    return (
      <ReadOnly
        loadState={loadState}
        templateInput={templateInput}
        value={readOnlyValue}
      />
    );
  }

  return (
    <div className={classnames("input-controller", { error: hasError })}>
      {hasLabel && (
        <div className="input-controller-label">
          {label}
          {isRequired ? " *" : ""}
        </div>
      )}
      {hasLabelHelperText && (
        <div className="input-controller-label-helper-text">
          {labelHelperText}
        </div>
      )}
      <div className="input-controller-input">
        {<InputComponent {...inputProps} />}
      </div>
      <div
        className={classnames("input-controller-helper", {
          "input-controller-helper--error": hasError
        })}
      >
        {hasError ? errorText : helperText}
      </div>
    </div>
  );
}
