import React from "react";
import { debounce } from "lodash";
import TextInput from "../../form/inputs/TextInput";
import "./cell-input.scss";

interface CellInputProps {
  defaultValue: string;
  disabled: boolean;
  isMultiline: boolean;
  inputWidthText: string;
  onChange: any;
  tagId: string;
}

export default function CellInput(props: CellInputProps) {
  const [inputWidth, setInputWidth] = React.useState<null | number>(null);

  const handleChange = (inputValue: string) => {
    props.onChange(inputValue);

    if (props.inputWidthText) {
      const inputEl = document.getElementById(props.tagId);
      if (inputEl) {
        // hidden span for dynamic width of input
        const spanEl = inputEl.querySelector("span");
        if (spanEl && spanEl.offsetWidth) {
          const newInputWidth = spanEl.offsetWidth + 28;
          if (inputWidth !== newInputWidth) {
            setInputWidth(newInputWidth);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    if (props.inputWidthText) {
      const inputEl = document.getElementById(props.tagId);
      if (inputEl) {
        // hidden span for dynamic width of input
        const spanEl = inputEl.querySelector("span");
        if (spanEl && spanEl.offsetWidth) {
          const newInputWidth = spanEl.offsetWidth + 28;
          if (inputWidth !== newInputWidth) {
            setInputWidth(newInputWidth);
          }
        }
      }
    }
  }, [props.inputWidthText, props.tagId, inputWidth]);

  return (
    <div
      className="cell-input"
      id={props.tagId}
      style={{
        minWidth: `${inputWidth ? `${inputWidth}px` : "auto"}`
      }}
    >
      {props.inputWidthText && (
        <span className="col-input-expander">{props.inputWidthText}</span>
      )}
      <TextInput
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        isMultiline={props.isMultiline || false}
        key={props.tagId}
        margin="dense"
        onChange={debounce((v: string) => handleChange(v), 300)}
      />
    </div>
  );
}
