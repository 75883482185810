import React from "react";
import { debounce } from "lodash";
import AsyncCreatableSelect from "react-select/async-creatable";

import Loader from "../../loader";

import AppContainer from "../../../container";
import {
  IFlightService,
  FlightService
} from "../../../services/flight.service";
import {
  getSelectValueProps,
  ObjectHash,
  SelectOption
} from "../../../utils/helpers";

import Colors from "../../../styles/colors.module.scss";
import { customReactSelectTheme, customStyle } from "./custom-theme";
import "./inputs.scss";

interface Props {
  closeMenuOnScroll?: any; // can be bool or func
  isRequired?: boolean;
  label?: string;
  menuPortalTarget?: any;
  onChange: CallableFunction;
  value?: string;
}

export default function AirlineAsyncCreatableInputFilled(props: Props) {
  const flightService: IFlightService = AppContainer.get(FlightService);

  const {
    closeMenuOnScroll,
    isRequired,
    label,
    menuPortalTarget,
    onChange
  } = props;

  const filterAirlines = debounce(
    (inputValue: string, callback: CallableFunction) => {
      flightService.airlineSearch(inputValue).then((airlines: any[]) => {
        const options = airlines.map((airline: any) => ({
          label: airline.name,
          value: airline.name,
          meta: airline
        }));
        return callback(options);
      });
    },
    300
  );

  const getOptions = (inputValue: string, callback: CallableFunction) => {
    if (!inputValue) {
      return Promise.resolve([] as SelectOption[]);
    }
    return filterAirlines(inputValue, callback);
  };

  const { menu, menuPortal, option } = customStyle;

  const optionStyles = {
    clearIndicator: (styles: ObjectHash) => ({
      ...styles,
      cursor: "pointer",
      ":hover": {
        color: Colors.red,
        height: "100%"
      }
    }),
    control: (styles: ObjectHash) => ({
      ...styles,
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none",
      cursor: "text",
      marginTop: "4px",
      minHeight: "none",
      "> div": {
        padding: "0"
      },
      "& input": {
        opacity: "1 !important" // addresses bug where input opacity turns to 0
      }
    }),
    menu,
    menuPortal,
    option
  };

  return (
    <div className="autocomplete-input--filled">
      {label && (
        <label className="autocomplete-input__label">
          {label}
          {isRequired ? " *" : ""}
        </label>
      )}
      <AsyncCreatableSelect
        {...getSelectValueProps({ ...props, isSearchable: true })}
        cacheOptions
        className="autocomplete-input autocomplete-input--async"
        closeMenuOnScroll={closeMenuOnScroll}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => <Loader type="spinner" />
        }}
        formatCreateLabel={(label) => `Use custom "${label}"`}
        isSearchable={true}
        loadOptions={getOptions}
        menuPortalTarget={menuPortalTarget}
        noOptionsMessage={({ inputValue }) =>
          inputValue.length ? "No airlines found" : null
        }
        onChange={(option: any) => {
          onChange(option?.value, option?.meta);
        }}
        onInputChange={(value, { action }) => {
          if (action === "input-change") {
            onChange(value);
          }
        }}
        placeholder=""
        styles={optionStyles}
        tabSelectsValue={false}
        theme={customReactSelectTheme}
      />
    </div>
  );
}
