import TravelFilterModel, {
  TravelFilterFields,
  TravelFilterGroups,
  TravelFilterResources
} from "../../../models/travel-filter.model";

import DateInput from "../../form/inputs/DateInput";

const TravelDateBeforeFilter = new TravelFilterModel({
  field: TravelFilterFields.TravelDateBefore,
  name: "Before",
  group: TravelFilterGroups.TravelDate,
  resource: TravelFilterResources.Trip,
  component: DateInput
});

export default TravelDateBeforeFilter;
