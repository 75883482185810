import BaseModel from "./base.model";

export default class BookingSegmentModel extends BaseModel {
  endDate: string;
  endDesiredTime: string;
  endLocation: string;
  startDate: string;
  startDesiredTime: string;
  startLocation: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.endDate = data.endDate;
    this.endDesiredTime = data.endDesiredTime;
    this.endLocation = data.endLocation;
    this.startDate = data.startDate;
    this.startDesiredTime = data.startDesiredTime;
    this.startLocation = data.startLocation;
  }
}
