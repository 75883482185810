import React, { createRef } from "react";
import Draggable from "react-draggable";
import pluralize from "pluralize";
import { resolve } from "inversify-react";
import { uniqueId } from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";

import Button from "../../button";
import CloseButton from "../../button/close";
import RemoveButton from "../../button/remove";
import Tooltip from "../../tooltip";

import AutocompleteInput from "../../form/inputs/AutocompleteInput";
import EmailInput from "../../form/inputs/EmailInput";
import TextInput from "../../form/inputs/TextInput";
import TagSelect from "../../tag-select";
import TravelerCreateInput from "../../form/inputs/TravelerCreateInput";

import { UserService, IUserService } from "../../../services/user.service";

import TagGroupModel from "../../../models/tag-group.model";
import UserModel from "../../../models/user.model";

import { AppSettings, withAppContext } from "../../../context";
import { withSnackbarContext } from "../../../context/snackbar";
import { ObjectHash } from "../../../utils/helpers";

import "./add-profiles-dialog.scss";

const PaperComponent = (props: object) => (
  <Draggable
    cancel={"[class*='not-draggable'], [class*='select-toggle-menu-popper']"} // prevents draggable behavior when clicking portaled tag select popper
  >
    <Paper {...props} />
  </Draggable>
);

interface Props {
  closeModal: CallableFunction;
  onChange?: CallableFunction;
  openModal: CallableFunction;
  setSnackbar: CallableFunction;
  settings: AppSettings;
}

interface State {
  formChanged: boolean;
  menuPortalTarget: HTMLElement | null;
  profiles: ObjectHash[];
  showTagInputs: boolean;
  tagGroupId: string;
  tagIds: string[];
}

class AddProfilesDialog extends React.Component<Props, State> {
  @resolve(UserService)
  private userService!: IUserService;

  private modalRef: React.RefObject<HTMLElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      menuPortalTarget: null,
      formChanged: false,
      profiles: [this.getNewProfile()],
      showTagInputs: false,
      tagIds: [],
      tagGroupId: ""
    };

    this.modalRef = createRef();
  }

  handleClose = (skipConfirm: boolean) => {
    const { closeModal, openModal } = this.props;
    const { formChanged } = this.state;

    const onConfirm = () => {
      this.setState({ showTagInputs: false }, () => closeModal());
    };

    if (formChanged && skipConfirm !== true) {
      openModal("confirm", {
        buttonText: "Close",
        dialogTitle: "Unsaved Changes",
        dialogBody: "Are you sure you’d like to cancel adding profiles?",
        onConfirm
      });
    } else {
      onConfirm();
    }
  };

  toggleTagInputs = () => {
    this.setState({
      showTagInputs: !this.state.showTagInputs,
      tagIds: [],
      tagGroupId: ""
    });
  };

  isLastRowEmpty = (profiles: ObjectHash[]) => {
    const lastTraveler = profiles[profiles.length - 1];
    let isEmpty = true;
    ["email", "firstName", "lastName"].forEach((key) => {
      if (lastTraveler[key] !== "") {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  focusNextRow = (formId: string) => {
    const { profiles } = this.state;

    const rowIndex = profiles.findIndex((profile) => profile.formId === formId);

    if (rowIndex === -1 || rowIndex === profiles.length - 1) {
      return;
    }

    const nextRow = profiles[rowIndex + 1];
    const nextRowEl = document.getElementById(
      `profile-input-row-${nextRow.formId}`
    );

    if (nextRowEl) {
      const inputToFocus = nextRowEl.querySelector("input");
      if (inputToFocus) {
        inputToFocus.focus();
      }
    }
  };

  // First name input field uses autocomplete to find existing profiles
  handleAutoCompleteChange = (
    formId: string,
    value: string,
    meta?: ObjectHash
  ) => {
    const { profiles } = this.state;

    const profileToChange = profiles.find(
      (profile) => profile.formId === formId
    );
    if (profileToChange) {
      if (meta) {
        profileToChange.backendId = meta.id || "null";
        profileToChange.email = meta.email || "";
        profileToChange.firstName = meta.firstName || "";
        profileToChange.lastName = meta.lastName || "";
        this.focusNextRow(formId);
      } else {
        profileToChange["firstName"] = value;
      }
    }
    if (!this.isLastRowEmpty(profiles)) {
      profiles.push(this.getNewProfile());
    }

    this.setState({ formChanged: true, profiles });
  };

  getNewProfile = () => {
    return {
      backendId: null,
      formId: uniqueId("form-id"),
      firstName: "",
      lastName: "",
      email: ""
    };
  };

  profilesAreValid = () => {
    const { profiles } = this.state;

    return (
      profiles.filter(
        (profile) => profile.firstName.length && profile.lastName.length
      ).length > 0
    );
  };

  async loadUsers() {
    const { setSnackbar } = this.props;
    const { tagIds } = this.state;

    const users = await this.userService.getByTags(tagIds);

    if (!users.length) {
      this.setState({
        formChanged: true,
        profiles: []
      });

      setSnackbar({
        message: "No profiles associated with this tag",
        variant: "error"
      });

      return;
    }

    const newTravelers: ObjectHash[] = users.map((user: UserModel) => ({
      backendId: user.id,
      formId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName
    }));

    newTravelers.push(this.getNewProfile());

    this.setState({
      formChanged: true,
      profiles: newTravelers
    });

    const loadedCount = newTravelers.length - 1;

    setSnackbar({
      message: `${loadedCount} ${pluralize("profile", loadedCount)} loaded`,
      variant: "success"
    });

    this.setState({ showTagInputs: false });
  }

  handleTextFieldChange = (
    key: "email" | "lastName",
    value: string,
    formId: string
  ) => {
    const { profiles } = this.state;
    const profileToChange = profiles.find(
      (profile) => profile.formId === formId
    );

    if (profileToChange) {
      profileToChange[key] = value;
    }

    if (!this.isLastRowEmpty(profiles)) {
      profiles.push(this.getNewProfile());
    }

    this.setState({ formChanged: true, profiles });
  };

  handleRemoveProfile = (formId: string) => {
    const { profiles } = this.state;
    const newTravelers = profiles.filter(
      (profile: ObjectHash) => profile.formId !== formId
    );
    this.setState({ profiles: newTravelers });
  };

  handleAddProfiles = async () => {
    const { onChange } = this.props;
    const { profiles } = this.state;

    const profilesToCreate = profiles.filter(
      (user) => !user.backendId && user.firstName && user.lastName
    );

    const requests = profilesToCreate.map(
      (user) =>
        new Promise(async (resolve: (value: boolean) => void) => {
          const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email
          };

          const response = await this.userService.create(payload);

          if (response) {
            const profile = profiles.find(
              (profile) => profile.formId === user.formId
            );

            if (profile) {
              profile.backendId = response.id;
            }

            resolve(true);
          }
        })
    );

    await Promise.all(requests).then(async () => {
      // run the update after all users are created
      const profileIdsToAdd = profiles
        .filter((profile) => profile.backendId)
        .map((profile) => profile.backendId);

      onChange && onChange(profileIdsToAdd);

      this.handleClose(true);
    });
  };

  render() {
    const { settings } = this.props;
    const {
      menuPortalTarget,
      profiles,
      showTagInputs,
      tagIds,
      tagGroupId
    } = this.state;

    const tagGroupOpts = settings.tagGroups
      .filter((tagGroup: TagGroupModel) => !tagGroup.hidden)
      .map((tagGroup: TagGroupModel) => ({
        label: tagGroup.name,
        value: tagGroup.id
      }));

    const tagGroup =
      settings.tagGroups.find(
        (tagGroup: TagGroupModel) => tagGroup.id === tagGroupId
      ) || new TagGroupModel();

    const textFieldProps = {
      className: "input--text",
      fullWidth: true,
      InputProps: {
        disableUnderline: true
      },
      InputLabelProps: {
        shrink: true
      },
      variant: "filled" as "filled"
    };

    return (
      <Dialog
        classes={{
          root: "add-profiles-dialog",
          paper: "paper",
          paperScrollPaper: "paper-scroll-paper",
          paperWidthSm: "paper-width-sm"
        }}
        aria-labelledby="form-dialog-title"
        disableEnforceFocus={true} // allows nested portaled tag select to take focus
        onClose={this.handleClose}
        open={true}
        PaperComponent={PaperComponent}
        onEntered={() => {
          this.setState({ menuPortalTarget: this.modalRef.current });
        }}
        ref={this.modalRef}
      >
        <DialogTitle
          classes={{ root: "dialog-title" }}
          style={{ cursor: "move" }}
        >
          <div className="add-profiles-dialog__title">
            <h1>Add Travelers</h1>
            <div className="add-profiles-dialog-button-group">
              <Tooltip text="Add users by tag">
                <Button
                  color={showTagInputs ? "product-background-blue" : "gray"}
                  icon="add-users"
                  isRippleDisabled={true}
                  isBordered={showTagInputs}
                  isRounded={true}
                  isTransparent={true}
                  onClick={() => {
                    this.toggleTagInputs();
                  }}
                  size="medium"
                />
              </Tooltip>
              <Tooltip text="Close">
                <CloseButton
                  onClick={() => this.handleClose(false)}
                  size="medium"
                />
              </Tooltip>
            </div>
          </div>
        </DialogTitle>
        <DialogContent
          classes={{ root: "dialog-content" }}
          className="not-draggable"
        >
          <div className="profile-input-rows">
            {showTagInputs ? (
              <div className="profile-input-row">
                <AutocompleteInput
                  key={"tag-type-input"}
                  onChange={(value: string) => {
                    this.setState({ tagIds: [], tagGroupId: value });
                  }}
                  options={tagGroupOpts}
                  menuPortalTarget={menuPortalTarget}
                  placeholder="Select tag type"
                />
                <TagSelect
                  key={`tag-value-input-${tagGroupId}`}
                  onChange={(
                    options: ObjectHash[],
                    tagGroup: TagGroupModel
                  ) => {
                    this.setState({
                      tagIds: options.map((option: ObjectHash) => option.id)
                    });
                  }}
                  menuPortalTarget={menuPortalTarget}
                  multiSelect={true}
                  selectedTagIds={tagIds}
                  tagGroup={tagGroup}
                  width={240}
                />
              </div>
            ) : (
              profiles.map((profile, index) => (
                <div
                  id={`profile-input-row-${profile.formId}`}
                  key={`profile-input-row-${index}-${
                    profile.backendId ?? profile.formId
                  }`}
                  className="profile-input-row"
                >
                  <TravelerCreateInput
                    value={profile.firstName}
                    label="First Name"
                    menuPortalTarget={menuPortalTarget}
                    onChange={(value: string, meta?: ObjectHash) =>
                      this.handleAutoCompleteChange(profile.formId, value, meta)
                    }
                    {...textFieldProps}
                  />
                  <TextInput
                    value={profile.lastName}
                    label="Last Name"
                    onChange={(value: string) =>
                      this.handleTextFieldChange(
                        "lastName",
                        value,
                        profile.formId
                      )
                    }
                    {...textFieldProps}
                  />
                  <EmailInput
                    value={profile.email}
                    label="Email"
                    onChange={(value: string) =>
                      this.handleTextFieldChange("email", value, profile.formId)
                    }
                    {...textFieldProps}
                  />
                  {index !== profiles.length - 1 && (
                    <Tooltip text="Remove row" placement="right">
                      <RemoveButton
                        onClick={() => this.handleRemoveProfile(profile.formId)}
                        size="small"
                      />
                    </Tooltip>
                  )}
                </div>
              ))
            )}
          </div>
        </DialogContent>
        <DialogActions
          classes={{ root: "dialog-actions add-profiles-dialog__actions" }}
        >
          {showTagInputs ? (
            <Button
              color="product-blue"
              isDisabled={!tagIds.length}
              isFullWidth={true}
              isTransparent={!tagIds.length}
              label="Load Users"
              onClick={() => {
                this.loadUsers();
              }}
              size="medium"
            />
          ) : (
            <Button
              color="product-blue"
              isDisabled={!this.profilesAreValid()}
              isFullWidth={true}
              isTransparent={!this.profilesAreValid()}
              label="Add Travelers"
              onClick={() => this.handleAddProfiles()}
              size="medium"
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAppContext(withSnackbarContext(AddProfilesDialog));
