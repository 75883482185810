import React, { useRef, useState } from "react";
import classnames from "classnames";

import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import PopperMenu from "../../../popper-menu";
import { Group } from "../../helpers";

import "./group-menu.scss";

export type GroupMenuSection = { label: string; groups: Group[] };

interface Props {
  defaultValue?: Group;
  disablePortal?: boolean;
  onChange: CallableFunction;
  sections: GroupMenuSection[];
}

export default function GroupMenu(props: Props) {
  const { defaultValue, disablePortal, sections, onChange } = props;

  const menuRef = useRef();

  const [activeGroup, setActiveGroup] = useState<Group | null>(
    defaultValue || null
  );

  const handleChange = (group: Group) => {
    const updatedGroup = activeGroup?.fieldId === group.fieldId ? null : group;
    setActiveGroup(updatedGroup);
    onChange(updatedGroup);
  };

  const hasActiveGroup = Boolean(activeGroup);

  return (
    <PopperMenu
      anchor={
        <AppIconFrame
          color={hasActiveGroup ? "product-blue" : "product-background-blue"}
          shape="square"
        >
          <AppIcon type="group" color={hasActiveGroup ? "white" : "black"} />
        </AppIconFrame>
      }
      disablePortal={disablePortal}
      ref={menuRef}
    >
      <div className="sink-group-menu">
        {sections.map((section: GroupMenuSection) => {
          const { label, groups } = section;
          return (
            <div className="section" key={label}>
              <div className="title">{label}</div>
              <div className="groups">
                {groups.map((group: Group) => {
                  const { label, fieldId } = group;
                  return (
                    <div
                      className={classnames("group", {
                        active: activeGroup?.fieldId === fieldId
                      })}
                      key={fieldId}
                      onClick={() => handleChange(group)}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </PopperMenu>
  );
}
