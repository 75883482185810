import React, { useRef, useState } from "react";
import { uniqueId } from "lodash";

import AppIcon from "../../../app-icon";
import AppIconFrame from "../../../app-icon-frame";
import Button from "../../../button";
import FilterRow from "./filter-row";
import { ObjectHash } from "../../../../utils/helpers";
import PopperMenu from "../../../popper-menu";
import { SelectOption, sortByPosition } from "../../../../utils/helpers";
import { Filter } from "./../../helpers";

import "./filter-menu.scss";

const makeFormId = () => uniqueId("filter-value-");

const emptyFilter: Filter = { fieldId: "", inputType: "", value: "" };

interface Props {
  defaultValue?: Filter[];
  disablePortal?: boolean;
  filters: Filter[];
  portalTarget?: HTMLElement;
  onChange: CallableFunction;
}

export default function FilterMenu(props: Props) {
  const {
    defaultValue = [{ ...emptyFilter }],
    disablePortal,
    filters,
    portalTarget,
    onChange
  } = props;

  const filterOptions: SelectOption[] = filters
    .sort(sortByPosition)
    .map((filter: Filter) => ({
      label: filter.label || "",
      value: filter.fieldId,
      meta: filter
    }));

  const zeroState: ObjectHash = {};
  defaultValue.forEach((filter: Filter, index: number) => {
    const formId = filter.formId || makeFormId();
    zeroState[formId] = { ...filter, formId, position: index };
  });

  const getFilters = (formState: ObjectHash): Filter[] => {
    const filters: Filter[] = [];
    Object.keys(formState).forEach((key: string, index: number) => {
      const filter = formState[key];
      filters.push({ ...filter, position: index });
    });
    return filters;
  };

  const [formState, setFormState] = useState<ObjectHash>(zeroState);
  const [hasFilters, setHasFilters] = useState<Boolean>(
    defaultValue
      ? Boolean(defaultValue.filter((filter: Filter) => filter.value).length)
      : false
  );

  const menuRef = useRef();

  const handleFieldChange = (formId: string, fieldId: string) => {
    const filter = filters.find((filter: Filter) => filter.fieldId === fieldId);
    if (!filter) {
      return;
    }

    const { article, inputType } = filter;

    const updatedFilter = {
      ...formState[formId],
      article,
      inputType,
      fieldId,
      value: ""
    };

    setFormState({ ...formState, [formId]: updatedFilter });
  };

  const handleAdd = () => {
    const formId = makeFormId();
    setFormState({
      ...formState,
      [formId]: { formId, inputType: "", value: "" }
    });
  };

  const handleValueChange = (formId: string, value: any) => {
    const updatedFilter = { ...formState[formId], value };
    setFormState({ ...formState, [formId]: updatedFilter });
  };

  const handleRemove = (formId: string) => {
    const updatedFormState = { ...formState };

    // only one filter left? clear it out rather than removing it
    if (Object.keys(formState).length === 1) {
      updatedFormState[formId] = { ...emptyFilter, formId };
    } else {
      delete updatedFormState[formId];
    }

    setFormState(updatedFormState);
  };

  const handleClose = () => {
    (menuRef.current as any)?.close();
  };

  const filterRows = getFilters(formState).sort(sortByPosition);

  return (
    <PopperMenu
      anchor={
        <AppIconFrame
          color={hasFilters ? "product-blue" : "product-background-blue"}
          shape="square"
        >
          <AppIcon type="filter" color={hasFilters ? "white" : "black"} />
        </AppIconFrame>
      }
      disablePortal={disablePortal}
      ref={menuRef}
    >
      <div className="sink-filter-menu">
        <div className="title">Filter By</div>
        <div className="filter-rows">
          {filterRows.map((filter: Filter, index: number) => {
            const { fieldId, formId = makeFormId() } = filter;
            const hasNextRow = filterRows.length > index + 1;

            return (
              <FilterRow
                key={`filter-row-${formId}-${fieldId}`}
                filter={filter}
                filterOptions={filterOptions}
                hasNextRow={hasNextRow}
                portalTarget={portalTarget}
                onFieldChange={(value: string) =>
                  handleFieldChange(formId, value)
                }
                onValueChange={(value: any) => handleValueChange(formId, value)}
                onRemove={() => handleRemove(formId)}
              />
            );
          })}
        </div>
        <div className="add-filter-row">
          <span onClick={() => handleAdd()}>Add Filter</span>
        </div>
        <div className="buttons">
          <Button
            color="gray"
            isTransparent={true}
            label="Cancel"
            onClick={() => handleClose()}
            size="medium"
          />
          <Button
            color="product-blue"
            label="Apply"
            onClick={() => {
              const activeFilters = getFilters(formState);

              setHasFilters(
                Boolean(
                  activeFilters.filter((filter: Filter) => filter.value).length
                )
              );

              onChange && onChange(activeFilters);
              handleClose();
            }}
            size="medium"
          />
        </div>
      </div>
    </PopperMenu>
  );
}
