import React, { useRef } from "react";

import BigTile from "../../big-tile";
import BigTileTitle from "./big-tile-title";
import GridModel from "../../../../models/grid.model";
import TmlApp from "./tml-app";
import { TmlProvider } from "../context";

import "./big-tile.scss";

interface Props {
  grid: GridModel;
  onClose?: CallableFunction;
}

export default function TravelMovementLogBigTile(props: Props) {
  const { grid, onClose } = props;

  const refs: any = {
    dialog: useRef<HTMLDivElement>(null),
    tile: useRef<HTMLDivElement>(null)
  };

  return (
    <TmlProvider grid={grid}>
      <BigTile
        title={<BigTileTitle grid={grid} />}
        onClose={onClose}
        ref={refs}
      >
        <TmlApp grid={grid} refs={refs} />
      </BigTile>
    </TmlProvider>
  );
}
