import React, { MouseEvent } from "react";

import AppIcon from "../app-icon";
import Tooltip from "../tooltip";

import AppContainer from "../../container";
import useApp from "../../hooks/use-app.hook";
import useModal from "../../hooks/use-modal.hook";
import useSnackbar from "../../hooks/use-snackbar.hook";

import { AuthService, IAuthService } from "../../services/auth.service";
import { UserService, IUserService } from "../../services/user.service";

import UserModel from "../../models/user.model";

import "./travel-planner-actions.scss";

interface Props {
  search: any;
  user: UserModel;
}

export default function TravelPlannerActions(props: Props) {
  const authService: IAuthService = AppContainer.get(AuthService);
  const userService: IUserService = AppContainer.get(UserService);
  const { setSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const { search, user } = props;
  const authedUser = useApp().user;

  const resendInvite = async (e: MouseEvent) => {
    e.stopPropagation(); // prevents row click bubbling in object table
    const response: boolean = await authService.sendInvite(user.id);
    if (response) {
      setSnackbar({
        message: "Invite sent!",
        variant: "success"
      });
    }
  };

  const setBlocked = (e: MouseEvent, bool: boolean) => {
    e.stopPropagation(); // prevents row click bubbling in object table
    openModal("confirm", {
      buttonText: bool ? "Disable" : "Enable",
      dialogTitle: `${bool ? "Disable" : "Enable"} Travel Planner`,
      dialogBody: `Are you sure you want to ${
        bool ? "disable" : "enable"
      } this travel planner?`,
      onConfirm: () => {
        userService
          .update(user.id, {
            blocked: bool
          })
          .then(search);
      }
    });
  };

  const setPrimary = (e: MouseEvent, bool: boolean) => {
    e.stopPropagation(); // prevents row click bubbling in object table
    openModal("confirm", {
      buttonText: bool ? "Grant Permission" : "Revoke Permission",
      dialogTitle: `${bool ? "Grant" : "Revoke"} Primary Admin`,
      dialogBody: `Are you sure you want to ${
        bool ? "grant" : "revoke"
      } primary admin permissions for this travel planner?`,
      onConfirm: () => {
        userService
          .update(user.id, {
            primary: bool
          })
          .then(search);
      }
    });
  };

  const removeTravelPlanner = (e: MouseEvent) => {
    e.stopPropagation(); // prevents row click bubbling in object table
    openModal("confirm", {
      buttonText: "Remove",
      dialogTitle: "Remove Travel Planner",
      dialogBody: "Are you sure you want to remove this travel planner?",
      onConfirm: () => {
        userService
          .update(user.id, {
            role: "contact"
          })
          .then(search);
      }
    });
  };

  return (
    <div className="travel-planner-actions">
      {!user.activated && !user.blocked && (
        <Tooltip text="Resend invite" placement="top">
          <div onClick={(e: MouseEvent) => resendInvite(e)}>
            <AppIcon type="email" />
          </div>
        </Tooltip>
      )}
      {!user.primary && (
        <Tooltip text="Grant administrator access" placement="top">
          <div onClick={(e: MouseEvent) => setPrimary(e, true)}>
            <AppIcon type="admin" />
          </div>
        </Tooltip>
      )}
      {user.primary &&
      Boolean(authedUser.id !== user.id) && ( // prevent user from removing their own admin rights
          <Tooltip text="Revoke administrator access" placement="top">
            <div onClick={(e: MouseEvent) => setPrimary(e, false)}>
              <AppIcon color="red" type="admin" />
            </div>
          </Tooltip>
        )}
      {!user.primary && !user.blocked && (
        <Tooltip text="Disable login" placement="top">
          <div onClick={(e: MouseEvent) => setBlocked(e, true)}>
            <AppIcon color="red" type="laptop-strike" />
          </div>
        </Tooltip>
      )}
      {!user.primary && user.blocked && (
        <Tooltip text="Enable login" placement="top">
          <div onClick={(e: MouseEvent) => setBlocked(e, false)}>
            <AppIcon type="laptop" />
          </div>
        </Tooltip>
      )}
      {!user.primary && (
        <Tooltip text="Remove travel planner" placement="top">
          <div onClick={(e: MouseEvent) => removeTravelPlanner(e)}>
            <AppIcon color="red" type="trash" />
          </div>
        </Tooltip>
      )}
    </div>
  );
}
