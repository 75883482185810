export const downloadCSV = (csv: any, filename: string) => {
  const csvFile = new Blob([csv], { type: "text/csv" });
  const downloadLink = document.createElement("a");

  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);

  downloadLink.click();
};

export const exportTablesToCSV = (filename: string) => {
  const csv: any[] = [];
  const tables: any[] = [];

  document.querySelectorAll("table").forEach((table) => {
    if (table.childElementCount > 0) {
      tables.push(table);
    }
  });

  if (!tables.length) {
    return;
  }

  const prepTableCellText = (cellElement: any): string => {
    if (!cellElement) {
      return "";
    }

    let cellText = String(cellElement.innerText);

    cellText = cellText.replace(/"/g, '\\"');

    return `"${cellText}"`;
  };

  tables.forEach((table) => {
    const tableTitle = table.parentNode.getElementsByClassName(
      "table-title__name"
    )[0].innerText;
    csv.push(tableTitle);

    const rows = table.querySelectorAll("tr");

    rows.forEach((rowElement: any) => {
      const cols = rowElement.querySelectorAll("td, th");
      const rowData = Array.from(cols).map((col: any) =>
        prepTableCellText(col)
      );
      csv.push(rowData.join(","));
    });

    csv.push("");
  });

  downloadCSV(csv.join("\n"), filename);
};
