import React from "react";
import { resolve } from "inversify-react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";

import Loader from "../../../components/loader";

import { AppPageProps } from "../../../router";
import { getQueryStringParams, ObjectHash } from "../../../utils/helpers";

import {
  AuthStorageService,
  IAuthStorageService,
  AuthPair
} from "../../../services/auth-storage.service";
import {
  IntegrationService,
  IIntegrationService
} from "../../../services/integration.service";
import CompanyModel from "../../../models/company.model";
import UserModel from "../../../models/user.model";

import TripgridLetterMark from "../../../images/tripgrid-letter-mark.svg";
import "../registration-page.scss";

interface Props extends AppPageProps, RouteComponentProps {}

interface State {
  company: CompanyModel;
  finished: boolean;
  link: string;
  loading: boolean;
  policyConsent: boolean;
  user: UserModel;
}

class SandboxPage extends React.Component<Props, State> {
  @resolve(IntegrationService)
  private integrationService!: IIntegrationService;

  @resolve(AuthStorageService)
  authStorageService!: IAuthStorageService;

  constructor(props: Props) {
    super(props);
    this.state = {
      company: props.company,
      finished: false,
      link: "",
      loading: false,
      policyConsent: false,
      user: props.user
    };
  }

  componentDidMount() {
    try {
      const { cid, uid } = getQueryStringParams();

      if (cid && uid) {
        const sandboxAuthPair = new AuthPair({
          token: `sandbox-${cid}-${uid}`,
          expires: Date.now() + 7 * 24 * 60 * 60 * 10_000
        });
        this.authStorageService.set(sandboxAuthPair);

        window.location.href = "/";
      }
    } catch (e) {
      // ignore this
    }
  }

  register = async (
    companyName: string,
    userName: string,
    userEmail: string
  ) => {
    this.setState({ loading: true });

    const payload = {
      companyName,
      email: userEmail,
      name: userName
    };

    const createdSandbox: ObjectHash | null = await this.integrationService.createSandbox(
      payload
    );

    if (createdSandbox) {
      this.setState({
        finished: true,
        loading: false,
        link: createdSandbox.link
      });
    }
  };

  render() {
    const {
      company,
      finished,
      link,
      loading,
      policyConsent,
      user
    } = this.state;

    return (
      <div className="registration-page">
        <div className="registration-page-header">
          <div className="registration-page-header__branding">
            <img
              alt="tripgrid-logo"
              src="https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg"
            />
            <img alt="tripgrid-letter-mark.svg" src={TripgridLetterMark} />
          </div>
          <div />
        </div>
        {finished ? (
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2
              style={{
                fontWeight: "normal",
                marginBottom: "48px"
              }}
            >
              Access your sandbox account
            </h2>
            <a href={link}>{link}</a>
            <br />
            <br />
            <p style={{ fontSize: "16px", margin: "auto" }}>
              A copy of this link was sent to<b> {user.email} </b>for
              bookmarking and sharing with co-workers.
            </p>
            <p>
              The link and corresponding sandbox will be removed after 7 days.
            </p>
          </div>
        ) : (
          <div>
            <h2
              style={{
                fontWeight: "normal",
                marginBottom: "48px"
              }}
            >
              Create your sandbox
            </h2>
            <form
              className="registration-form"
              onSubmit={(e) => {
                e.preventDefault();
                this.register(company.name, user.name, user.email);
              }}
            >
              <div className="form-input">
                <input
                  defaultValue={user.name}
                  onChange={(e) => {
                    user.name = e.target.value;
                    this.setState({ user });
                  }}
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="form-input">
                <input
                  defaultValue={(company || {}).name || ""}
                  onChange={(e) => {
                    company.name = e.target.value;
                    this.setState({ company });
                  }}
                  placeholder="Company Name"
                  required
                />
              </div>
              <div className="form-input">
                <input
                  defaultValue={user.email}
                  onChange={(e) => {
                    user.email = e.target.value;
                    this.setState({ user });
                  }}
                  placeholder="Work Email"
                  type="email"
                  required
                />
              </div>
              <div className="checkbox-wrapper">
                <Checkbox
                  checked={policyConsent}
                  color="primary"
                  disabled={loading}
                  onChange={() =>
                    this.setState({
                      policyConsent: !policyConsent
                    })
                  }
                />
                <p>
                  I agree to the Tripgrid{" "}
                  <a
                    href="https://www.tripgrid.com/legal/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  {" and "}
                  <a
                    href="https://www.tripgrid.com/legal/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
              {loading ? (
                <div>
                  <p>
                    We're setting up your personal sandbox and generating test
                    data.
                  </p>
                  <p>This may take several minutes.</p>
                  <Loader type="dots" />
                </div>
              ) : (
                <button
                  className={policyConsent ? "" : "disabled"}
                  disabled={!policyConsent}
                  type="submit"
                >
                  Create
                </button>
              )}
            </form>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SandboxPage);
