import React, { createContext, useContext } from "react";

import CollectionModel from "../models/collection.model";
import CompanyModel from "../models/company.model";
import GridModel from "../models/grid.model";
import TemplateModel from "../models/template.model";
import TagGroupModel from "../models/tag-group.model";
import UserModel from "../models/user.model";
import { ObjectHash } from "../utils/helpers";

export type AppSettings = {
  collections: CollectionModel[];
  dateFormat: string;
  dateTimeFormat: string;
  dataFreezeDate: string;
  filterGlobalViews: boolean;
  grids: GridModel[];
  tagGroups: TagGroupModel[];
  searchPresets: ObjectHash;
  travelTablePresets: ObjectHash;
};

export interface IAppState {
  dispatch: CallableFunction;

  getGridTagGroup: CallableFunction;

  getTagById: CallableFunction;

  company: CompanyModel;

  isAuthed: boolean;

  isLoading: boolean;

  isOnline: boolean;

  settings: AppSettings;

  templates: TemplateModel[];

  user: UserModel;
}

export const AppState: IAppState = {
  user: new UserModel(),
  company: new CompanyModel(),
  templates: [],
  settings: {
    collections: [],
    dateFormat: "MMM dd, yyyy",
    dateTimeFormat: "MMM dd, yyyy @ hh:mm a",
    filterGlobalViews: false,
    dataFreezeDate: "",
    tagGroups: [],
    grids: [],
    searchPresets: {},
    travelTablePresets: {}
  },
  isAuthed: false,
  isLoading: true,
  isOnline: true,
  dispatch: () => {},
  getTagById: () => {},
  getGridTagGroup: () => {}
};

export const AppContext = createContext<IAppState>(AppState);

export const withAppContext = (Component: any) => {
  return (props: any) => {
    const { dispatch, settings } = useContext(AppContext);
    return <Component dispatch={dispatch} settings={settings} {...props} />;
  };
};
