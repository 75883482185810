import React from "react";
import AppIcon from "../app-icon";
import Tooltip from "../tooltip";
import TagModel from "../../models/tag.model";
import classnames from "classnames";
import "./tag-chip.scss";

interface Props {
  tag: TagModel;
  hideUnconfirmedTooltip?: boolean;
}

export default function TagChip(props: Props) {
  const { tag, hideUnconfirmedTooltip } = props;

  return (
    <div
      className={classnames({
        "tag-chip": true,
        unconfirmed: tag.unconfirmed
      })}
      style={tag.getChipColors()}
    >
      {tag.unconfirmed && !hideUnconfirmedTooltip && (
        <Tooltip
          text="This tag does not match any pre-defined tags in your tag group"
          placement="top"
        >
          <span className="icon-flex-wrapper">
            <AppIcon type="error" size="x-small" color="invert-black" />
          </span>
        </Tooltip>
      )}

      <span>{tag.name}</span>
    </div>
  );
}
