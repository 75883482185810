import React from "react";
import { DateTime } from "luxon";
import { uniqBy } from "lodash";
import UserModel from "../../../../models/user.model";
import AutocompleteInput from "../../../form/inputs/AutocompleteInput";
import DateInput from "../../../form/inputs/DateInput";
import TravelerSelect from "../../../traveler-select";

import { ObjectHash } from "../../../../utils/helpers";

export const inputsHash: { [index: string]: any } = {
  from: DateInput,
  to: DateInput
};

export const getValueInputComponent = (
  filterProps: { [index: string]: any },
  staticData: any[],
  handleFilterValueChange: CallableFunction
) => {
  let Component = AutocompleteInput; // use uppercase for dynamic component generation
  const componentProps: { [index: string]: any } = {};

  if (filterProps.key === "users") {
    const tripUsers = staticData
      .map((trip) => trip.users)
      .flat()
      .filter((user) => user.name)
      .map((user: ObjectHash) => new UserModel(user));

    const uniqueUsers = uniqBy(tripUsers, (user: UserModel) => user.id);

    const selectedUsers: UserModel[] = [];
    if (filterProps.value[filterProps.key]) {
      // handling is for rendering docs when old traveler input was returning the userId
      const value = [filterProps.value[filterProps.key]];
      value.flat().forEach((userValue: string | ObjectHash) => {
        if (typeof userValue === "string") {
          const user = uniqueUsers.find(
            (user: UserModel) => user.id === userValue
          );
          if (user) {
            selectedUsers.push(user);
          }
        } else {
          selectedUsers.push(new UserModel(userValue));
        }
      });
    }

    return (
      <TravelerSelect
        users={uniqueUsers}
        onChange={(v: any[]) => {
          const userIds = v.map((user) => user.id);
          handleFilterValueChange(userIds, "users", filterProps.id);
        }}
        selectedUsers={selectedUsers}
        width="240"
      />
    );
  } else if (["from", "range", "to"].includes(filterProps.key)) {
    let fromDate = (filterProps.value || {}).from || "";
    if (fromDate) {
      fromDate = DateTime.fromMillis(Number(fromDate)).toISO();
    }

    let toDate = (filterProps.value || {}).to || "";
    if (toDate) {
      toDate = DateTime.fromMillis(Number(toDate)).toISO();
    }

    // date range
    if (filterProps.key === "range") {
      return (
        <div className="date-input--range">
          <DateInput
            defaultValue={fromDate}
            label="From"
            key={`${filterProps.key}-from-${filterProps.id}`}
            onChange={(value: string) => {
              const timestamp = DateTime.fromISO(value).valueOf();
              handleFilterValueChange(timestamp, "from", filterProps.id);
            }}
          />
          <DateInput
            defaultValue={toDate}
            label="To"
            key={`${filterProps.key}-to-${filterProps.id}`}
            onChange={(value: string) => {
              const timestamp = DateTime.fromISO(value).valueOf();
              handleFilterValueChange(timestamp, "to", filterProps.id);
            }}
          />
        </div>
      );
    }

    // before date
    if (filterProps.key === "to") {
      toDate = (filterProps.value || {}).to || "";

      if (toDate) {
        toDate = DateTime.fromMillis(toDate)
          .plus({ days: 1 })
          .toFormat("yyyy-MM-dd");
      }

      return (
        <DateInput
          defaultValue={toDate}
          label="Before"
          key={`${filterProps.key}-to-${filterProps.id}`}
          onChange={(value: string) => {
            const prevDay = DateTime.fromISO(value)
              .minus({ days: 1 })
              .valueOf();
            handleFilterValueChange(prevDay, "to", filterProps.id);
          }}
        />
      );
    }

    // after date
    if (filterProps.key === "from") {
      return (
        <DateInput
          defaultValue={fromDate}
          label="After"
          key={`${filterProps.key}-from-${filterProps.id}`}
          onChange={(value: string) => {
            const timestamp = DateTime.fromISO(value).valueOf();
            handleFilterValueChange(timestamp, "from", filterProps.id);
          }}
        />
      );
    }
  } else {
    const vals = staticData
      .map((trip) => trip[filterProps.key])
      .flat()
      .filter((v) => v && v.length);
    const uniqueVals = [...new Set(vals.map((v) => v))];
    if (filterProps.value[filterProps.key]) {
      componentProps.defaultValue = [
        {
          label: filterProps.value[filterProps.key],
          value: filterProps.value[filterProps.key]
        }
      ];
    }
    componentProps.options = uniqueVals
      .map((v) => ({
        value: v,
        label: v
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    componentProps.placeholder = `Select ${filterProps.label.toLowerCase()}`;
  }

  return (
    <Component
      key={`${filterProps.key}-${filterProps.id}`}
      onChange={(v: any) =>
        handleFilterValueChange(v, filterProps.key, filterProps.id)
      }
      {...componentProps}
    />
  );
};
