import { DateTime } from "luxon";
import BaseModel from "../../../../models/base.model";
import { Column } from "../../../sink/helpers";
import { ObjectHash } from "../../../../utils/helpers";

export type TmlLogUpdate = {
  userId: string;
  entryType?: TmlEntryType;
  segmentId?: TmlEntrySegmentId;
  updateType: "segment/update" | "user/add" | "user/remove";
};

export type TmlEntrySegmentAir = {
  addedByUser: boolean;
  date: number;
  tripId: string;
  tripSegmentId: string;
  tripType: "air";
  value: {
    confirmation: string;
    name: string; // airline
    flightNum: string;
    fromLocation: string;
    fromDate: number;
    toDate: number;
    toLocation: string;
  };
};

export type TmlEntrySegmentHotel = {
  addedByUser: boolean;
  date: number;
  tripId: string;
  tripType: "hotel";
  value: {
    confirmation: string;
    name: string; // hotel name
    fromLocation: string; // hotel address
    fromDate: number;
    toDate: number;
  };
};

export type TmlEntrySegmentTransportation = {
  addedByUser: boolean;
  date: number;
  tripId: string;
  tripType: "car";
  value: {
    confirmation: string;
    name: string; // car rental agency
    fromLocation: string;
    fromDate: number;
    toDate: number;
    toLocation: string;
  };
};

export type TmlEntrySegmentRail = {
  addedByUser: boolean;
  date: number;
  tripId: string;
  tripSegmentId: string;
  tripType: "rail";
  value: {
    confirmation: string;
    name: string; // train line
    trainNum: string;
    fromLocation: string;
    fromDate: number;
    toDate: number;
    toLocation: string;
  };
};

export type TmlEntrySegmentUser = {
  addedByUser: true;
  date: number;
  tripType: "user";
  value: string;
};

export type TmlEntrySegment =
  | TmlEntrySegmentAir
  | TmlEntrySegmentHotel
  | TmlEntrySegmentTransportation
  | TmlEntrySegmentRail
  | TmlEntrySegmentUser;

export type TmlEntrySegmentTrip =
  | TmlEntrySegmentAir
  | TmlEntrySegmentHotel
  | TmlEntrySegmentTransportation
  | TmlEntrySegmentRail;

export type TmlEntrySegmentTravel =
  | TmlEntrySegmentAir
  | TmlEntrySegmentRail
  | TmlEntrySegmentUser;

export type TmlEntrySegmentId =
  | "travelSegment"
  | "transportSegment"
  | "locationSegment";

export type TmlEntryType = "inbound" | "outbound";

export class TmlLogSettings extends BaseModel {
  columns: Column[];
  dates: [number, number];
  locations: string[];
  targetLocation: string;
  updatedAt: number;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.columns = data.columns || [];
    this.dates = data.dates || [0, 0];
    this.locations = data.locations || [];
    this.targetLocation = data.targetLocation || "";
    this.updatedAt = data.updatedAt || 0;
  }

  datesValid(): boolean {
    // must specify both a start and end date
    const validDates = this.dates.filter(
      (date: number) => date && DateTime.fromSeconds(date).isValid
    );
    if (validDates.length !== 2) {
      return false;
    }

    // must be valid date range
    if (validDates[0] > validDates[1]) {
      return false;
    }

    return true;
  }

  locationsValid(): boolean {
    // must have at least one location
    return this.locations.filter((l) => l).length > 0;
  }

  isValid(): boolean {
    if (!this.datesValid()) {
      return false;
    }

    if (!this.locationsValid()) {
      return false;
    }

    return true;
  }
}

export class TmlLogModel extends BaseModel {
  id: string;
  grid: string;
  settings: TmlLogSettings;

  entries: TmlEntryModel[];

  createdAt: number;
  updatedAt: number;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.grid = data.grid;
    this.settings = new TmlLogSettings(data.settings);

    this.entries = data.entries
      ? data.entries.map((entry: ObjectHash) => new TmlEntryModel(entry))
      : [];

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

export class TmlEntryModel extends BaseModel {
  userId: string;
  type: TmlEntryType;
  jobTitle: string | false;
  notes: string;
  addedByUser: boolean;

  travelSegment: TmlEntrySegmentTravel;
  transportSegment: TmlEntrySegment;
  locationSegment: TmlEntrySegment;

  customData: ObjectHash;

  createdAt: number;
  updatedAt: number;

  constructor(data?: ObjectHash) {
    super();

    if (!data) {
      data = {};
    }

    this.userId = data.userId;
    this.jobTitle = data.jobTitle;

    this.type = data.type;
    this.travelSegment = data.travelSegment || {};
    this.transportSegment = data.transportSegment || {};
    this.locationSegment = data.locationSegment || {};

    this.notes = data.notes || "";
    this.addedByUser = Boolean(data.addedByUser);

    this.customData = data.customData || {};

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
