import React from "react";
import { AppPageProps } from "../../router";
import PageHeader from "../../components/page-header";
import DataFreezePage from "./sub-pages/data-freeze";
import TemplatesPage from "./sub-pages/templates";
import TravelPlannersPage from "./sub-pages/travel-planners";
import WorkspacePage from "./sub-pages/workspace";

export default function SettingsPage(props: AppPageProps) {
  const { user, company } = props;
  const { features } = company;

  const hasDataFreeze = features && features.dataFreeze && user.primary;

  return (
    <div id="settings-page" className="page-container">
      <PageHeader title="Workspace Settings" />

      <WorkspacePage {...props} />

      <TravelPlannersPage {...props} />

      <TemplatesPage {...props} />

      {hasDataFreeze && <DataFreezePage {...props} />}
    </div>
  );
}
