import React, { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import AppContainer from "../../container";
import { IGridService, GridService } from "../../services/grid.service";
import GridModel from "../../models/grid.model";

import Button from "../../components/button";
import GridCardMenu from "./grid-card-menu";
import UserProfileIconList from "../../components/user-profile-icon-list";
import useApp from "../../hooks/use-app.hook";

import "./grid-card.scss";

interface Props {
  grid: GridModel;
}

export default function GridCard(props: Props) {
  const { grid } = props;

  const gridService: IGridService = AppContainer.get(GridService);
  const [persistHover, setPersistHover] = useState<Boolean>(false);

  const authedUser = useApp().user;
  const gridTagColors = grid.tag.getChipColors();
  const isStarred = grid.starredBy.includes(authedUser.id);

  const toggleStarGrid = () =>
    isStarred ? gridService.unstar(grid.id) : gridService.star(grid.id);

  return (
    <div className={classnames("grid-card", { hover: persistHover })}>
      <Link to={`/grids/${grid.id}`}>
        <div className="grid-card-utils">
          <Button
            color={isStarred ? "yellow" : "gray"}
            icon={isStarred ? "star" : "star-border"}
            isRippleDisabled={true}
            isTransparent={true}
            onClick={(event: MouseEvent) => {
              event.preventDefault(); // prevent click from triggering Link component action (i.e. location change)
              toggleStarGrid();
            }}
            size="small"
          />

          <GridCardMenu
            key={`grid-card-${grid.id}`}
            color="background-gray"
            grid={grid}
            onOpen={() => setPersistHover(true)}
            onClose={() => setPersistHover(false)}
          />
        </div>
        <div className="grid-card-title">{grid.name}</div>

        <div className="footer">
          <UserProfileIconList users={grid.users} />

          <div
            className="grid-tag-color-square"
            style={{ backgroundColor: gridTagColors.backgroundColor }}
          ></div>
        </div>
      </Link>
    </div>
  );
}
