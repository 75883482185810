import React from "react";

import "./stack.scss";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export default function StackToolbar(props: Props) {
  const { children } = props;

  return <div className="stack-toolbar">{children}</div>;
}
