import React, { forwardRef, Ref } from "react";
import classnames from "classnames";
import "./toggle-text.scss";

export interface Props {
  disabled?: boolean;
  onChange: (updatedIndex: number) => void;
  options: string[];
  selectedIndex?: number;
}

const ToggleText = forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { disabled, options, onChange, selectedIndex = 0 } = props;

  const handleChange = () => {
    if (disabled) {
      return;
    }
    const newIndex = selectedIndex === 1 ? 0 : 1;
    onChange(newIndex);
  };

  return (
    <div
      className={classnames("toggle-text", { disabled })}
      onClick={() => handleChange()}
      ref={ref}
    >
      <div
        className={classnames("option left", {
          selected: selectedIndex === 0
        })}
      >
        {options[0]}
      </div>
      <div
        className={classnames("option right", {
          selected: selectedIndex === 1
        })}
      >
        {options[1]}
      </div>
    </div>
  );
});

export default ToggleText;
