import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { isEqual } from "lodash";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  Responsive,
  SelectableRows
} from "mui-datatables";

import EmptyMoon from "../../images/empty-table-flights.png";
import { LoadState, ObjectHash } from "../../utils/helpers";
import Loader from "../loader";
import {
  StackColumn,
  StackColumnProp,
  StackFilter,
  StackPage,
  StackSort
} from "./helpers";
import usePrevious from "../../hooks/use-previous.hook";

import "./stack.scss";

const resetMuiTableOpts: MUIDataTableOptions = {
  download: false,
  filter: false,
  page: 0,
  pagination: true,
  print: false,
  responsive: "standard" as Responsive,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 20, 50, 100],
  search: false,
  selectableRows: "none" as SelectableRows,
  serverSide: true,
  sort: true,
  viewColumns: false
};

type StackTableOpts = {
  page: number;
  rowsPerPage: number;
  sort: StackSort;
};

export interface Props {
  columns: StackColumnProp[];
  emptyMessage?: string;
  filters?: StackFilter[]; // note that the only usage for this prop is to watch and reset pagination to zero when filters change
  hideTableHead?: boolean;
  onData: CallableFunction;
  onRowClick?: CallableFunction;
  title?: string | JSX.Element;
  toolbar?: () => JSX.Element;
  sort?: StackSort; // note that the only usage for this prop is to watch and reset pagination to zero when sort changes
}

export default function Stack(props: Props) {
  const {
    columns,
    emptyMessage,
    filters,
    hideTableHead,
    onData,
    onRowClick,
    title,
    toolbar,
    sort
  } = props;

  const hasTitle = Boolean(title);
  const hasToolbar = hasTitle || Boolean(toolbar);

  /*
   * Allow passing strings for columns, as a convenience feature, and
   * filter out hidden columns.
   */
  const fullColumns: StackColumn[] = columns
    .map((col: StackColumnProp, index: number) => {
      if (typeof col === "string") {
        return { id: col, index, label: col, sort: true };
      }
      return col;
    })
    .filter((col: StackColumn) => !col.hidden);

  const [data, setData] = useState<ObjectHash[]>([]);
  const [loadState, setLoadState] = useState<LoadState>("unloaded");
  const [count, setCount] = useState<number>(0);

  const [tableOpts, setTableOpts] = useState<StackTableOpts>({
    page: 0,
    rowsPerPage: 10,
    sort: {
      column: fullColumns[0],
      direction: "asc"
    }
  });

  const onColumnSortChange = (
    changedColumn: string,
    direction: "asc" | "desc"
  ) => {
    // @todo this approach obviously does not accomodate non-unique column labels
    const column = fullColumns.find(
      (col: StackColumn) => col.label === changedColumn
    );

    if (!column) {
      return;
    }

    const sort = { column, direction };
    setTableOpts({ ...tableOpts, page: 0, rowsPerPage, sort });
  };

  const onTableChange = (action: string, tableState: ObjectHash) => {
    switch (action) {
      case "changePage":
        const { page, rowsPerPage } = tableState;
        setTableOpts({ ...tableOpts, page, rowsPerPage });
        break;
    }
  };

  useEffect(() => {
    const { page, rowsPerPage } = tableOpts;

    setLoadState("loading");

    let subscribed = true;

    onData(page, rowsPerPage, sort).then((result: StackPage) => {
      if (!subscribed) {
        return;
      }
      const { count, data } = result;
      setLoadState("loaded");
      setData(data);
      setCount(count);
    });

    return () => {
      subscribed = false;
    };
  }, [onData, setCount, setData, setLoadState, sort, tableOpts]);

  // Did filters or sort change? Reset pagination
  const previousFilters = usePrevious(filters);
  const previousSort = usePrevious(sort);

  useEffect(() => {
    const filterChanged = previousFilters && !isEqual(previousFilters, filters);
    const sortChanged = previousSort && !isEqual(previousSort, sort);

    if (filterChanged || sortChanged) {
      setTableOpts({ ...tableOpts, page: 0 });
    }
  }, [filters, previousFilters, previousSort, sort, tableOpts]);

  const { page, rowsPerPage } = tableOpts;

  const muiTableOpts: MUIDataTableOptions = {
    ...resetMuiTableOpts,
    count,
    onColumnSortChange,
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      const { dataIndex } = rowMeta;
      onRowClick && onRowClick(data[dataIndex]);
    },
    onTableChange,
    page,
    rowsPerPage,
    setRowProps: (row: any[], rowIndex: number) => {
      if (!row.filter((v) => v).length) {
        return { className: "empty" };
      }
      return {};
    }
  };

  if (hasToolbar) {
    muiTableOpts.customToolbar = toolbar;
  }

  const muiColumns: MUIDataTableColumn[] = fullColumns.map(
    (col: StackColumn) => {
      const { id, label, sort } = col;
      let colConfig: MUIDataTableColumn = {
        label,
        name: id,
        options: {
          sort: sort || false,
          // add column names to the th class list for better css targeting
          setCellHeaderProps: (columnMeta: ObjectHash): ObjectHash => {
            return { className: columnMeta.name };
          }
        }
      };

      if (hideTableHead) {
        colConfig.options = { customHeadRender: () => null };
      }

      return colConfig;
    }
  );

  let titleElement: string | JSX.Element | undefined = "";

  if (hasTitle) {
    titleElement =
      typeof title === "string" ? (
        <div className="stack-title">
          <div className="stack-title--title">{title}</div>
          <div className="stack-title--loader">
            {loadState === "loading" && <Loader type="spinner" />}
          </div>
        </div>
      ) : (
        title
      );
  }

  const showEmptyMessage =
    emptyMessage?.length && loadState === "loaded" && !data.length;
  const emptyTable = showEmptyMessage;
  if (emptyTable) {
    muiTableOpts.pagination = false;
  }

  return (
    <div
      className={classnames("stack", {
        "hide-table-head": hideTableHead,
        "hide-toolbar": !hasToolbar
      })}
    >
      <div className={classnames("table", { empty: emptyTable })}>
        <MUIDataTable
          title={titleElement}
          data={data}
          columns={muiColumns}
          options={muiTableOpts}
        />
      </div>
      {showEmptyMessage && (
        <div className="empty-message">
          <div>
            <img src={EmptyMoon} alt="No records found" />
          </div>
          <div>{emptyMessage}</div>
        </div>
      )}
    </div>
  );
}
