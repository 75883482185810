import BaseModel from "./base.model";

export default class CustomerModel extends BaseModel {
  id: string;
  active: boolean;
  billable: boolean;
  deleted: boolean;
  hasCompletedSetup: boolean;
  sandbox: boolean;
  subdomain: any;

  billingDate: string;
  billingPlan: string;
  nextInvoice: any;

  features: { [index: string]: boolean };
  settings: { [index: string]: any };
  status: string;

  name: string;
  logo: any;
  logoUploadUrl: string;
  mailbox: string;
  migratedId: number;

  createdAt: string;
  updatedAt: string;

  constructor(data?: any) {
    super();

    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.active = data.active;
    this.billable = data.billable;
    this.deleted = data.deleted;
    this.hasCompletedSetup = data.hasCompletedSetup;
    this.sandbox = data.sandbox;
    this.subdomain = data.subdomain;

    this.features = data.features;
    this.settings = data.settings;
    this.status = data.status;

    this.billingDate = data.billingDate;
    this.billingPlan = data.billingPlan;
    this.nextInvoice = data.nextInvoice;

    this.name = data.name;
    this.logo = data.logo;
    this.logoUploadUrl = data.logoUploadUrl;
    this.mailbox = data.mailbox;
    this.migratedId = data.migratedId;

    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
