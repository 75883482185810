import React from "react";
import { cloneDeep, debounce, isEqual } from "lodash";
import { DateTime } from "luxon";

import CustomTable from "../../../components/custom-table";
import ObjectTable from "../../../components/object-table";

import { blockNameHash } from "../";
import { getBlockTypes } from "../blocks/block-types";
import AttachmentBlock from "../blocks/attachment";
import ImageBlock from "../blocks/image";
import MapBlock from "../blocks/map";
import ScheduleBlock from "../blocks/schedule";
import TextBlock from "../blocks/text";

import AddBlockMenu from "../builder-actions/add-block-menu";
import FormatBlockMenu from "../builder-actions/format-block-menu";
import MoveBlockMenu from "../builder-actions/move-block-menu";

import { AppContext } from "../../../context";
import { ObjectHash } from "../../../utils/helpers";
import TemplateModel from "../../../models/template.model";

interface Props {
  _dataUpdated: any;
  block: ObjectHash;
  // The ctx property contains a "this" reference to the DocumentBuilderPage component, and is used to access its state
  // @todo This is a *major* anti-pattern that is causing some strange bugs.
  ctx: ObjectHash;
  index: number;
  locationsData: ObjectHash[];
  printMode: boolean;
  template?: ObjectHash;
  updateStaticResults: CallableFunction;
  viewMode: boolean;
  templates: TemplateModel[];
}

interface State {}

export default class BuilderBlock extends React.Component<Props, State> {
  private block: any;

  shouldComponentUpdate(nextProps: Props) {
    if (
      this.props.index !== nextProps.index &&
      (!this.props.index || !nextProps.index)
    ) {
      return true;
    }

    if (
      this.props.viewMode !== nextProps.viewMode ||
      this.props.printMode !== nextProps.printMode
    ) {
      return true;
    }

    if (
      this.block &&
      this.block.blockType === "table" &&
      this.block.blockSubType !== "custom" &&
      this.props._dataUpdated !== nextProps._dataUpdated
    ) {
      // NOTE: may need to revisit this later if performance on object tables becomes an issue
      return true;
    }

    if (
      this.block &&
      this.block.blockType === "table" &&
      this.block.options.color !== nextProps.block.options.color
    ) {
      return true;
    }

    if (
      this.block &&
      this.block.blockType === "traveler" &&
      this.block.blockSubType === "schedule" &&
      this.props._dataUpdated !== nextProps._dataUpdated
    ) {
      return true;
    }

    return !isEqual(this.block, nextProps.block);
  }

  render() {
    const { block, ctx, printMode, template, templates, viewMode } = this.props;
    const { shareSettings } = ctx.state;

    try {
      // necessary at the moment due to mutability of block object in handlers below
      this.block = JSON.parse(JSON.stringify(block));
    } catch (e) {
      this.block = null;
    }

    if (!block) {
      return null;
    }

    let inner = null;

    const { blockType, blockSubType, id, options = {}, templateName } = block;

    if (blockType === "table") {
      if (blockSubType === "custom") {
        inner = (
          <CustomTable
            key={`react-table-${blockSubType}-${id}-${options.tableKey}`}
            blockState={{
              block,
              onChangeTableBlockColor: (color: any) =>
                ctx.handleChangeTableBlockColor(color, id),
              onChangeCustomTableBlockBodyCell: (
                colIndex: number,
                rowIndex: number,
                value: any
              ) =>
                ctx.handleChangeCustomTableBlockBodyCell(
                  colIndex,
                  rowIndex,
                  value,
                  id
                ),
              onChangeCustomTableBlockColName: (colIndex: number, value: any) =>
                ctx.handleChangeCustomTableBlockColName(colIndex, value, id),
              onChangeCustomTableBlockCols: (colIndex: number, action: any) =>
                ctx.handleChangeCustomTableBlockCols(colIndex, action, id),
              onChangeCustomTableBlockRows: (rowIndex: number, action: any) =>
                ctx.handleChangeCustomTableBlockRows(rowIndex, action, id),
              onChangeTableBlockTitle: (value: any) =>
                ctx.handleChangeTableBlockTitle(value, id),
              onImportCsvData: (newColumns: any[], newData: any) =>
                ctx.handleImportCustomTableData(newColumns, newData, id)
            }}
            columns={options.columns}
            data={options.data}
            title={options.title}
            viewMode={viewMode}
            printMode={printMode}
          />
        );
      } else if (
        ["arrival", "departure", "ground-transportation"].includes(blockSubType)
      ) {
        const travelers = ctx.getBlockObjects(block);
        const blockType: any = getBlockTypes().find(
          (block: any) => block.blockSubType === blockSubType
        );
        const defaultColumns = blockType ? blockType.options.columns : [];

        inner = (
          <AppContext.Consumer>
            {(appState) => (
              <ObjectTable
                key={`react-table-${blockSubType}-${id}`}
                blockState={{
                  block,
                  onChangeTableBlockColor: (color: any) =>
                    ctx.handleChangeTableBlockColor(color, id),
                  onChangeTableBlockCols: (cols: any[]) =>
                    ctx.handleChangeTableBlockCols(cols, id),
                  onChangeTableBlockColOrder: (
                    cols: any[],
                    visibleCols: any[],
                    colIndex: number,
                    direction: string
                  ) =>
                    ctx.handleChangeTableBlockColOrder(
                      cols,
                      visibleCols,
                      colIndex,
                      direction,
                      id
                    ),
                  onChangeTableBlockDataOrder: (
                    userId: string,
                    direction: string
                  ) =>
                    ctx.handleChangeTableBlockDataOrder(userId, direction, id),
                  onChangeTableBlockHiddenTrips: (
                    tripId: string,
                    action: any
                  ) =>
                    ctx.handleChangeTableBlockHiddenTrips(tripId, action, id),
                  onChangeTableBlockSort: (sorters: any) =>
                    ctx.handleChangeTableBlockSort(sorters, id),
                  onChangeTableBlockTitle: (value: any) =>
                    ctx.handleChangeTableBlockTitle(value, id),
                  onResetCustomDataOrder: () =>
                    ctx.handleResetTableBlockDataOrder(id)
                }}
                columns={options.columns}
                columnsDefault={defaultColumns}
                renderOpts={{
                  disableRowClick: true,
                  disableDownload: true,
                  disablePagination: true,
                  disableRowSelect: true,
                  isDocument: true,
                  showColorMenu: true,
                  showQuickAdd: false,
                  showSortMenu: true,
                  useCardStyle: true,
                  showTagCol: true
                }}
                searchOpts={{
                  filters: [],
                  resource: "users",
                  viewStateKey: options.viewStateKey
                }}
                staticResults={travelers}
                title={options.title}
                viewMode={viewMode}
                printMode={printMode}
                templates={templates}
                company={appState.company}
                settings={shareSettings || appState.settings}
                user={appState.user}
              />
            )}
          </AppContext.Consumer>
        );
      } else if (blockSubType === "profile") {
        const profiles = ctx.getBlockObjects(block);
        inner = (
          <AppContext.Consumer>
            {(appState) => (
              <ObjectTable
                key={`react-table-${blockSubType}-${id}`}
                blockState={{
                  block,
                  onChangeTableBlockColor: (color: any) =>
                    ctx.handleChangeTableBlockColor(color, id),
                  onChangeTableBlockCols: (cols: any[]) =>
                    ctx.handleChangeTableBlockCols(cols, id),
                  onChangeTableBlockColOrder: (
                    cols: any[],
                    visibleCols: any[],
                    colIndex: number,
                    direction: string
                  ) =>
                    ctx.handleChangeTableBlockColOrder(
                      cols,
                      visibleCols,
                      colIndex,
                      direction,
                      id
                    ),
                  onChangeTableBlockDataOrder: (
                    userId: string,
                    direction: string
                  ) =>
                    ctx.handleChangeTableBlockDataOrder(userId, direction, id),
                  onChangeTableBlockSort: (sorters: any) =>
                    ctx.handleChangeTableBlockSort(sorters, id),
                  onChangeTableBlockTitle: (value: any) =>
                    ctx.handleChangeTableBlockTitle(value, id),
                  onChangeTableBlockUsers: (users: any[]) =>
                    ctx.handleChangeTableBlockUsers(users, id),
                  onResetCustomDataOrder: () =>
                    ctx.handleResetTableBlockDataOrder(id)
                }}
                columns={options.columns}
                renderOpts={{
                  disableDownload: true,
                  disablePagination: true,
                  disableRowSelect: true,
                  isDocument: true,
                  showColorMenu: true,
                  showQuickAdd: false,
                  showSortMenu: true,
                  showUserMenu: true,
                  useCardStyle: true,
                  showTagCol: true
                }}
                searchOpts={{
                  filters: [],
                  resource: "users",
                  viewStateKey: options.viewStateKey
                }}
                staticResults={profiles}
                templateName={templateName}
                title={options.title}
                viewMode={viewMode}
                printMode={printMode}
                templates={templates}
                company={appState.company}
                settings={shareSettings || appState.settings}
                user={appState.user}
              />
            )}
          </AppContext.Consumer>
        );
      } else {
        const allTrips = ctx.getBlockObjects(block, true); // used to generate in-table filter menu options
        let trips = ctx.getBlockObjects(block);

        if (options.futureOnly) {
          const NowDateTime = DateTime.local();
          trips = trips.filter(
            (trip: any) => trip.to && NowDateTime <= DateTime.fromISO(trip.to)
          );
        }

        if (["air", "rail"].includes(blockSubType)) {
          // removes currency values from all but first segment for rowspan merging logic to work (can only span down)
          const uniqueParentTripIds: Set<string> = new Set();
          trips.forEach((trip: any) => uniqueParentTripIds.add(trip.tripId));
          const nonFirstSegmentIds: any[] = [];
          uniqueParentTripIds.forEach((parentTripId: string) => {
            trips
              .filter((trip: any) => trip.tripId === parentTripId)
              .forEach((trip: any, index: number) => {
                if (index !== 0) {
                  nonFirstSegmentIds.push(trip.id);
                }
              });
          });
          nonFirstSegmentIds.forEach((segmentId) => {
            if (template) {
              const currencyProperties = template.properties
                .filter((p: any) => p.propertyType === "currency")
                .map((p: any) => p.name);
              const segment = trips.find((trip: any) => trip.id === segmentId);
              currencyProperties.forEach((currencyProp: any) => {
                segment[currencyProp] = null;
              });
            }
          });
        }

        inner = (
          <AppContext.Consumer>
            {(appState) => (
              <ObjectTable
                key={`react-table-${blockSubType}-${id}`}
                blockState={{
                  allTrips,
                  block,
                  onChangeTableBlockColor: (color: any) =>
                    ctx.handleChangeTableBlockColor(color, id),
                  onChangeTableBlockCols: (cols: any[]) =>
                    ctx.handleChangeTableBlockCols(cols, id),
                  onChangeTableBlockColOrder: (
                    cols: any[],
                    visibleCols: any[],
                    colIndex: number,
                    direction: string
                  ) =>
                    ctx.handleChangeTableBlockColOrder(
                      cols,
                      visibleCols,
                      colIndex,
                      direction,
                      id
                    ),
                  onChangeTableBlockDataOrder: (
                    tripId: string,
                    direction: string
                  ) =>
                    ctx.handleChangeTableBlockDataOrder(tripId, direction, id),
                  onChangeTableBlockFilter: (filters: any) =>
                    ctx.handleChangeTableBlockFilter(filters, id),
                  onChangeTableBlockFutureOnly: () =>
                    ctx.handleChangeTableBlockFutureOnly(id),
                  onChangeTableBlockHiddenTrips: (
                    tripId: string,
                    action: any
                  ) =>
                    ctx.handleChangeTableBlockHiddenTrips(tripId, action, id),
                  onChangeTableBlockSort: (sorters: any) =>
                    ctx.handleChangeTableBlockSort(sorters, id),
                  onChangeTableBlockTitle: (value: string) =>
                    ctx.handleChangeTableBlockTitle(value, id),
                  onResetCustomDataOrder: () =>
                    ctx.handleResetTableBlockDataOrder(id)
                }}
                columns={options.columns}
                locationsData={this.props.locationsData}
                renderOpts={{
                  disableDownload: true,
                  disablePagination: true,
                  disableRowSelect: true,
                  isDocument: true,
                  showColorMenu: true,
                  showFilterMenu: true,
                  showFutureOnlyMenu: true,
                  showQuickAdd: false,
                  showSortMenu: true,
                  updateStaticResults: this.props.updateStaticResults,
                  useCardStyle: true,
                  showTagCol: true
                }}
                searchOpts={{
                  filters: [],
                  resource: "trips",
                  viewStateKey: options.viewStateKey
                }}
                staticResults={trips}
                title={options.title}
                templateName={templateName}
                viewMode={viewMode}
                printMode={printMode}
                templates={templates}
                company={appState.company}
                settings={shareSettings || appState.settings}
                user={appState.user}
              />
            )}
          </AppContext.Consumer>
        );
      }
    }
    if (blockType === "multi") {
      inner = (
        <div
          className="multi-block-wrapper"
          id={`multi-block-wrapper-${id}`}
          key={`multi-block-wrapper-${blockSubType}-${id}`}
        >
          {block.blocks.map((innerBlock: any) =>
            ctx.getMultiBlock(
              innerBlock,
              block.id,
              block.blocks,
              ctx.state.viewMode
            )
          )}
        </div>
      );
    }
    if (blockType === "text") {
      const debounceEventHandler = (onChange: any, wait: number) => {
        const debounced = debounce(onChange, wait);
        return (e: any) => {
          e.persist();
          return debounced(e.target.value, id);
        };
      };
      inner = (
        <div
          className="text-block-wrapper"
          key={`text-block-wrapper-${blockSubType}-${id}`}
        >
          <TextBlock
            blockSubType={blockSubType}
            defaultValue={block.value}
            disabled={viewMode}
            isViewMode={viewMode}
            options={options}
            onChange={debounceEventHandler(
              ctx.handleChangeBasicBlockValue,
              300
            )}
          />
          {!viewMode ? (
            <FormatBlockMenu
              key={`format-block-menu-${id}`}
              blockId={id}
              blockType={blockType}
              className="format-block-menu"
              options={options}
              onChangeBlockFormat={(opt: any, value: any) =>
                ctx.handleChangeBasicBlockFormat(opt, value, id)
              }
            />
          ) : null}
        </div>
      );
    }
    if (blockType === "miscellaneous" && blockSubType === "attachment") {
      inner = (
        <div
          className="attachment-block-wrapper"
          key={`attachment-block-wrapper-${blockSubType}-${id}`}
        >
          <AttachmentBlock
            filename={(block.options || {}).filename || ""}
            isViewMode={viewMode}
            onUpload={(url: string, uploadedFile: any) => {
              ctx.handleChangeBasicBlockValue(url, id);
              ctx.handleChangeBasicBlockFormat(
                "filename",
                uploadedFile.filename,
                id
              );
            }}
            src={block.value || ""}
          />
        </div>
      );
    }
    if (blockType === "image" && blockSubType === "image") {
      inner = (
        <div
          className="image-block-wrapper"
          key={`image-block-wrapper-${blockSubType}-${id}`}
        >
          <ImageBlock
            src={block.value || ""}
            onUpload={(url: string) => ctx.handleChangeBasicBlockValue(url, id)}
            textAlign={options.textAlign}
            width={options.width}
          />
          {!viewMode ? (
            <FormatBlockMenu
              key={`format-block-menu-${id}`}
              blockId={id}
              blockType={blockType}
              className="format-block-menu"
              options={options}
              onChangeBlockFormat={(opt: any, value: any) =>
                ctx.handleChangeBasicBlockFormat(opt, value, id)
              }
            />
          ) : null}
        </div>
      );
    }
    if (blockType === "image" && blockSubType === "map") {
      inner = (
        <div
          className="image-block-wrapper"
          key={`image-block-wrapper-${blockSubType}-${id}`}
        >
          <MapBlock
            isViewMode={viewMode}
            location={block.value || {}}
            onChange={(location: any) =>
              ctx.handleChangeBasicBlockValue(location, id)
            }
            textAlign={options.textAlign}
            width={options.width}
          />
          {!viewMode ? (
            <FormatBlockMenu
              key={`format-block-menu-${id}`}
              blockId={id}
              blockType={blockType}
              className="format-block-menu"
              options={options}
              onChangeBlockFormat={(opt: any, value: any) =>
                ctx.handleChangeBasicBlockFormat(opt, value, id)
              }
            />
          ) : null}
        </div>
      );
    }
    if (blockType === "traveler" && blockSubType === "schedule") {
      const uniqueUsers: Map<string, ObjectHash> = new Map();
      const { trips = [] } = ctx.state.data ? cloneDeep(ctx.state.data) : {};

      trips
        .map((trip: any) => trip.users)
        .flat()
        .forEach((user: ObjectHash) => {
          // @todo trips with null/undefined values in the users prop?
          if (user?.id && user?.name) {
            uniqueUsers.set(user.id, user);
          }
        });

      const scheduleTrips = trips.filter((trip: any) =>
        (trip.users || []).some((user: any) => user.id === options.userId)
      );

      inner = (
        <div
          className="schedule-block-wrapper"
          key={`schedule-block-wrapper-${blockSubType}-${id}`}
        >
          <ScheduleBlock
            id={id}
            block={block}
            locationsData={this.props.locationsData}
            onApplyFilter={() => {}}
            onAddScheduleEvent={(scheduleEvent: any) =>
              ctx.handleAddScheduleEvent(scheduleEvent, id)
            }
            onAddScheduleNote={(eventId: string, value: any) =>
              ctx.handleAddScheduleNote(eventId, value, id)
            }
            onChangeScheduleBlockUser={(userId: string) =>
              ctx.handleChangeScheduleBlockUser(userId, id)
            }
            onChangeScheduleEventOrder={(
              eventIds: string[],
              eventCard: any,
              direction: string
            ) =>
              ctx.handleChangeScheduleEventOrder(
                eventIds,
                eventCard,
                direction,
                id
              )
            }
            onChangeScheduleEventHide={(cardIndex: number, hide: boolean) =>
              ctx.handleChangeScheduleEventHide(cardIndex, hide, id)
            }
            onChangeScheduleEvent={(scheduleEvent: any) =>
              ctx.handleChangeScheduleEvent(scheduleEvent, id)
            }
            onChangeTableBlockFutureOnly={() =>
              ctx.handleChangeTableBlockFutureOnly(id)
            }
            onDeleteScheduleEvent={(scheduleEvent: any) =>
              ctx.handleDeleteScheduleEvent(scheduleEvent, id)
            }
            onDeleteScheduleEventOrder={(dateGroup: any) =>
              ctx.handleDeleteScheduleEventOrder(dateGroup, id)
            }
            printMode={printMode}
            updateStaticResults={this.props.updateStaticResults}
            scheduleTrips={scheduleTrips}
            uniqueUsers={Array.from(uniqueUsers.values())}
            viewMode={viewMode}
          />
        </div>
      );
    }
    if (blockType === "print" && blockSubType === "break") {
      inner = (
        <div
          className="page-break-wrapper enable-table-break"
          key={`page-break-wrapper-${id}`}
        >
          <div className="page-break-line" />
          <span>Page Break</span>
          <div className="page-break-line" />
        </div>
      );
    }

    return (
      <div className="document-builder__block">
        {!viewMode ? (
          <AddBlockMenu
            key={`add-block-menu-${id}`}
            blockId={id}
            onAddBlock={(bType: string, bSubType: string) =>
              ctx.handleAddBlock(bType, bSubType, id)
            }
            onAddMultiBlock={() => {}}
          />
        ) : null}
        {inner}
        {!viewMode ? (
          <MoveBlockMenu
            key={`move-block-menu-${id}`}
            blockCount={ctx.state.blocks.length}
            blockId={id}
            blockDeleteLabel={blockNameHash[blockSubType]}
            onDeleteBlock={ctx.handleDeleteBlock}
            onMoveBlock={ctx.handleMoveBlock}
          />
        ) : null}
      </div>
    );
  }
}
