import BaseService from "./base.service";
import { Subject } from "rxjs";
import Pusher from "pusher-js";
import { inject } from "inversify";

import env from "../utils/env";

import { ApiService, IApiService } from "./api.service";

export interface IWebsocketService {
  connect(companyId?: string): boolean;

  isConnected(): boolean;

  reconnect(): boolean;

  getConnectPub(): Subject<boolean>;

  subscribe(
    topic: string,
    onMessageCallback: CallableFunction
  ): Promise<boolean>;

  unsubscribe(topic: string): void;
}

export class WebsocketService extends BaseService implements IWebsocketService {
  @inject(ApiService)
  private apiService!: IApiService;

  private chunkedPayloads: any = {};
  private companyId: string = "";
  private connected: boolean = false;
  private socket!: Pusher;
  private topics: any = {};

  connectPub: Subject<boolean> = new Subject();

  constructor() {
    super();

    this.connectPub.subscribe((connected) => {
      this.connected = connected;
    });
  }

  getConnectPub(): Subject<boolean> {
    return this.connectPub;
  }

  connect(companyId?: string) {
    // already initalized or cannot initialize
    if (this.connected) {
      return false;
    }

    if (companyId) {
      this.companyId = companyId;
    }

    const headers = this.apiService.getAuthHeaders();

    this.socket = new Pusher(env.pusher.apiKey, {
      auth: { headers },
      authEndpoint: `${env.api}/socket/auth`,
      cluster: "us3",
      forceTLS: true
    });

    // https://github.com/pusher/pusher-js#connection-states
    // @todo unavailable, failed
    this.socket.connection.bind("error", (err: any) => {
      console.error("WebsocketService::connect | socket error", err);
      // @todo this means the app can't load, show massive error of some kind
      this.connectPub.next(false);
    });

    // ensure that new connection attempts have updated auth credentials
    this.socket.connection.bind("state_change", (states: any) => {
      console.log("WebsocketService::connect | state change", states);
      if (states.current === "connecting") {
        const headers = this.apiService.getAuthHeaders();
        this.socket.config.auth = { headers };
      }
    });

    this.connectPub.next(true);

    return true;
  }

  isConnected(): boolean {
    return this.connected;
  }

  reconnect(): boolean {
    if (this.connected) {
      this.socket.disconnect();
      this.connectPub.next(false);
    }

    this.connect();

    return true;
  }

  async subscribe(
    topic: string,
    onMessageCallback: CallableFunction
  ): Promise<boolean> {
    if (!this.connected) {
      console.error(
        "WebsocketService::subscribe | called before being connected",
        topic
      );
      return false;
    }

    // pull/set auth credentials from local storage to ensure we have the most recent set
    const headers = this.apiService.getAuthHeaders();
    this.socket.config.auth = { headers };

    const channelName = ["presence", `company_${this.companyId}`, topic].join(
      "-"
    );

    // https://pusher.com/docs/channels/using_channels/channels#channel-naming-conventions
    if (channelName.length > 164) {
      console.error(
        "WebsocketService::subscribe | channel name length error",
        channelName
      );
    }

    this.unsubscribe(topic);

    const channelSub = this.socket.subscribe(channelName);

    channelSub.bind("pusher:error", (error: any) => {
      console.error("WebsocketService::subscribe | pusher error", error);
    });

    channelSub.bind("message", (payload: any) => {
      onMessageCallback(payload);
    });

    channelSub.bind(
      "chunked-message",
      ({ chunkId, chunkLength, data = [] }: any) => {
        if (!chunkId || !chunkLength || !Array.isArray(data)) {
          return;
        }

        if (!this.chunkedPayloads[chunkId]) {
          this.chunkedPayloads[chunkId] = [];
        }

        this.chunkedPayloads[chunkId].push(...data);

        if (this.chunkedPayloads[chunkId].length === chunkLength) {
          onMessageCallback(this.chunkedPayloads[chunkId].slice());
          delete this.chunkedPayloads[chunkId];
        }
      }
    );

    this.topics[topic] = channelSub;

    const data = await this.apiService.get(`/socket/initial/${channelName}`);

    return onMessageCallback(data, true);
  }

  unsubscribe(topic: string): void {
    if (this.topics[topic]) {
      this.topics[topic].unbind();
      delete this.topics[topic];
    }
  }
}
