import { DateTime } from "luxon";

import BaseModel from "./base.model";
import { ObjectHash } from "../utils/helpers";
import SegmentModel from "./segment.model";
import TagModel from "./tag.model";

export default class TripModel extends BaseModel {
  id: string;
  type: string; // @todo create type
  company: string;

  data: ObjectHash; // @todo what's in here?
  users: ObjectHash[]; // trip users
  tags: TagModel[];

  confirmation: string;
  bookedDateTime: string;
  flightNum: string;
  name: string;

  // departure
  from: string;
  fromLocation: string;

  // arrival
  to: string;
  toLocation: string;

  price: string;

  routing: string;
  segments: SegmentModel[];

  cancelled: boolean;
  deleted: boolean;

  createdAt: string;
  updatedAt: string;

  constructor(data?: ObjectHash) {
    super();
    if (!data) {
      data = {};
    }

    this.id = data.id;
    this.type = data.type;
    this.company = data.company;
    this.data = data.data;
    this.users = data.users || [];
    this.tags = data.tags
      ? data.tags.map((tag: ObjectHash) => new TagModel(tag))
      : [];
    this.confirmation = data.confirmation;
    this.bookedDateTime = data.bookedDateTime;
    this.flightNum = data.flightNum || "";
    this.name = data.name;
    this.from = data.from;
    this.fromLocation = data.fromLocation;
    this.to = data.to;
    this.toLocation = data.toLocation;
    this.price = data.price;
    this.routing = data.routing;
    this.segments = data.segments
      ? data.segments.map((segment: ObjectHash) => new SegmentModel(segment))
      : [];
    this.cancelled = data.cancelled;
    this.deleted = data.deleted;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  /*
   * This method returns segments representing locations that travelers are leaving from, excluding locations
   * that are part of connecting segment. For example, consider a round-trip trip with the following hops:
   *
   * ATL > MDW > JFK > BDL > LGA > ATL
   *
   * Travelers of this trip are departing from ATL and JFK, and NOT MDW, BDL or LGA. Determining these
   * locations for one-way trips is much simpler. For example:
   *
   * ATL > MDW > JFK
   *
   * The only departing location for this trip is ATL.
   */
  getDepartureSegments(): SegmentModel[] {
    if (!["AIR", "RAIL"].includes(this.type)) {
      return [];
    }

    const segments = this.getSegments();
    const departSegments = segments[0] ? [segments[0]] : [];

    // round trips depart from their original location, plus their destination during their return
    if (this.isRoundTrip()) {
      const destSegment = this.segments.find(
        (segment: SegmentModel) => segment.fromLocation === this.toLocation
      );

      if (destSegment) {
        departSegments.push(destSegment);
      }
    }

    return departSegments;
  }

  getDestinationSegment(): SegmentModel | null {
    if (!["AIR", "RAIL"].includes(this.type)) {
      return null;
    }

    if (!this.isRoundTrip()) {
      const segments = this.getSegments();
      return segments[segments.length - 1] || null;
    }

    return (
      this.segments.find(
        (segment: SegmentModel) => segment.toLocation === this.toLocation
      ) || null
    );
  }

  getSegments(): SegmentModel[] {
    return this.segments.sort(
      (aSegment: SegmentModel, bSegment: SegmentModel) => {
        const aDate = DateTime.fromISO(aSegment.from);
        const bDate = DateTime.fromISO(bSegment.from);

        const aTime = aDate.isValid ? aDate.valueOf() : 0;
        const bTime = bDate.isValid ? bDate.valueOf() : 0;

        if (aTime === bTime) {
          return 0;
        }

        return aTime < bTime ? -1 : 1;
      }
    );
  }

  getTemplateName(): string {
    const nameMap: ObjectHash = {
      activity: "activity",
      air: "flight",
      car: "transportation",
      hotel: "accommodation",
      rail: "rail"
    };

    return nameMap[String(this.type).toLowerCase()] ?? "";
  }

  isRoundTrip(): boolean {
    if (!["AIR", "RAIL", "CAR"].includes(this.type)) {
      return false;
    }

    if (this.segments.length < 2) {
      return false;
    }

    const segments = this.getSegments();
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    return firstSegment.fromLocation === lastSegment?.toLocation;
  }
}
