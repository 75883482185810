import React from "react";

import AppIcon, { AppIconType } from "../../../../app-icon";
import {
  airportToIata,
  getDisplayDate,
  getFlightNumber
} from "../../../../../utils/helpers";
import SegmentModel from "../../../../../models/segment.model";
import { TmlEntryModel } from "../../lib/models";
import TripModel from "../../../../../models/trip.model";

interface Props {
  entry: TmlEntryModel;
  onSelect: (trip: TripModel, segment?: SegmentModel) => void;
  segment?: SegmentModel;
  trip: TripModel;
}

export default function TmlSegmentTripRow(props: Props) {
  const { entry, onSelect, segment = new SegmentModel(), trip } = props;

  const { type } = trip;
  const isInbound = entry.type === "inbound";

  let iconType: AppIconType = "ticket";
  let title = "";
  let tripLocation = "";
  let fromDate = "";
  let toDate = "";

  if (type === "AIR") {
    const { flightNum, from, fromLocation, name, to, toLocation } = segment;
    title = name;

    if (flightNum) {
      title = `${title} ${getFlightNumber(flightNum)}`;
    }

    title = `${title} - ${airportToIata(fromLocation)} > ${airportToIata(
      toLocation
    )}`;
    tripLocation = airportToIata(isInbound ? toLocation : fromLocation) || "";
    fromDate = from;
    toDate = to;
  } else if (type === "RAIL") {
    const { data = {}, from, fromLocation, name, to, toLocation } = segment;
    title = name;

    if (data.trainNum) {
      title = `${title} ${data.trainNum}`;
    }

    title = `${title} - ${fromLocation} > ${toLocation}`;
    fromDate = from;
    toDate = to;
  } else {
    const { from, fromLocation, name, to } = trip;
    title = name;
    if (fromLocation) {
      title = `${title} - ${fromLocation}`;
    }

    fromDate = from;
    toDate = to;
  }

  let subtitle = isInbound ? "Arriving" : "Departing";
  if (tripLocation) {
    subtitle = `${subtitle} ${tripLocation}`;
  }

  const tripDate = isInbound ? fromDate : toDate;
  const travelDate = tripDate ? getDisplayDate(tripDate, "EEE, MMM d") : "";
  if (travelDate) {
    subtitle = `${subtitle} - ${travelDate}`;
  }

  return (
    <div
      className="tml-segment-trip-row"
      onClick={() => onSelect(trip, segment)}
    >
      <AppIcon color="product-blue" type={iconType} />
      <div>
        <div className="title">{title}</div>
        <div className="subtitle">{subtitle}</div>
      </div>
    </div>
  );
}
