import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import classnames from "classnames";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { ApiService, IApiService } from "../../../services/api.service";
import AppContainer from "../../../container";
import AppIcon from "../../app-icon";
import AutocompleteInputFilled from "../../form/inputs/AutocompleteInputFilled";
import Button from "../../button";
import DateInput from "../../form/inputs/DateInput";
import { downloadCSV } from "../../../utils/csv";
import CloseButton from "../../button/close";
import { getReportFormInputs, ReportFormInput } from "./helper-funcs";
import { ObjectHash } from "../../../utils/helpers";
import TagGroupModel from "../../../models/tag-group.model";
import Tooltip from "../../tooltip";
import TripgridLetterMark from "../../../images/tripgrid-letter-mark.svg";
import useApp from "../../../hooks/use-app.hook";
import useModal from "../../../hooks/use-modal.hook";
import useSnackbar from "../../../hooks/use-snackbar.hook";

import "./customize-report-dialog.scss";

const inputHash: ObjectHash = {
  date: DateInput,
  select: AutocompleteInputFilled
};

interface Props {
  iconType: string;
  reportType: string;
  reportDesc: string;
}

export default function CustomizeReportDialog(props: Props) {
  const apiService: IApiService = AppContainer.get(ApiService);

  const { settings, company } = useApp();
  const { reportDesc, reportType } = props;
  const { dataFreezeDate, tagGroups } = settings;
  const { features = {}, logoUploadUrl = "" } = company;
  const { closeModal } = useModal();
  const { setSnackbar } = useSnackbar();

  // @todo remove this to a small component
  const logoUrl = logoUploadUrl
    ? logoUploadUrl
    : "https://assets-global.website-files.com/5ed7f06278144a348f52c998/632b677b322f00b570a1006f_new-tg-icon.svg";

  const [formState, setFormState] = useState(
    getReportFormInputs(features, tagGroups, dataFreezeDate)
  );

  const handleInputChange = (key: string, value: string | boolean) => {
    const updatedFormState = [...formState];
    const inputIndex = updatedFormState.findIndex(
      (input: ReportFormInput) => input.key === key
    );

    if (inputIndex > -1) {
      updatedFormState[inputIndex].value = value;
      setFormState(updatedFormState);
    }
  };

  const downloadReport = async () => {
    const payload: ObjectHash = { fields: [], filters: {} };
    payload.deserialize = (v: any) => v;
    formState.forEach((formInput: ReportFormInput) => {
      const { key, value, label } = formInput;
      if (key.startsWith("filter.")) {
        if (value && value !== "All") {
          payload.filters[key.replace("filter.", "")] = value;
        }
      } else if (key === "dateFrom") {
        payload.dateFrom = `${value}T00:00:00Z`;
      } else if (key === "dateTo") {
        // NOTE: this logic may need to be changed to 23:59:59Z
        payload.dateTo = `${value}T00:00:00Z`;
      } else if (TagGroupModel.isFieldId(key) && value) {
        payload.fields.push(["tagGroup", TagGroupModel.getIdFromField(key)]);
      } else if (value) {
        payload.fields.push([key, label]);
      }
    });

    const data = await apiService.post(
      `/reports/${reportType.toLowerCase()}`,
      payload
    );

    if (data) {
      downloadCSV(
        data,
        `Custom ${reportType} Report - ${payload.dateFrom} to ${payload.dateTo}.csv`
      );
    } else {
      setSnackbar({
        message: "There was an error and the report was not generated",
        variant: "error"
      });
    }
  };

  const checkboxInputs = formState.filter(
    (formInput: ObjectHash) => formInput.inputType === "checkbox"
  );
  checkboxInputs.sort((fieldA: ReportFormInput, fieldB: ReportFormInput) => {
    const tagA = Boolean(fieldA.tag);
    const tagB = Boolean(fieldB.tag);

    if (tagA === tagB) {
      return 0;
    }

    return tagA ? 1 : -1;
  });

  const specialCheckboxInputs = formState.filter(
    (formInput) => formInput.inputType === "checkbox--special"
  );

  return (
    <Dialog
      classes={{
        root: "customize-report-dialog",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper",
        paperWidthSm: "paper-width-sm"
      }}
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle classes={{ root: "dialog-title" }}>
        <div className="customize-report-dialog__title">
          <img
            alt="company-logo"
            className="customize-report-dialog__company-logo"
            src={logoUrl}
          />
          <img
            alt="tripgrid"
            src={TripgridLetterMark}
            style={{ height: "32px" }}
          />
          <Tooltip text="Close">
            <CloseButton onClick={() => closeModal()} size="medium" />
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: "dialog-content" }}>
        <div className="customize-report-breadcrumb">
          <p
            className="customize-report-breadcrumb__link"
            onClick={() => closeModal()}
          >
            Reports
          </p>
          <AppIcon type="nav-arrow-right" />
          <p>{`Custom ${reportType} Report`}</p>
        </div>
        <div className="customize-report-form">
          <h1>{`Generate Custom ${reportType} Report`}</h1>
          <h2>{reportDesc}</h2>
          <h3>What properties would you like to include in this report?</h3>
          <div className={"customize-report__checkbox-section"}>
            {checkboxInputs.map((formInput: ReportFormInput) => {
              const { value, key, label, tag = false } = formInput;

              return (
                <div className="customize-report__checkbox-input" key={key}>
                  <Checkbox
                    checked={value as boolean}
                    color="primary"
                    onChange={() => handleInputChange(key, !value)}
                  />
                  <span
                    className={classnames("checkbox-label", {
                      tag: Boolean(tag)
                    })}
                  >
                    <span className="label">{label}</span>
                  </span>
                </div>
              );
            })}
          </div>
          <h3>Define your data set for this report.</h3>
          <div className="customize-report__input-section">
            {formState
              .filter(
                (formInput: ReportFormInput) =>
                  !["checkbox", "checkbox--special"].includes(
                    formInput.inputType
                  )
              )
              .map((formInput: ReportFormInput) => {
                const {
                  inputType,
                  options = [],
                  label,
                  key,
                  value
                } = formInput;
                const InputComponent = inputHash[inputType];
                const componentProps: ObjectHash = {
                  label: label || "",
                  onChange: (v: any) => handleInputChange(key, v)
                };
                if (inputType === "date") {
                  componentProps.defaultValue = value;
                  componentProps.variant = "filled";
                }
                if (inputType === "select") {
                  componentProps.defaultValue = [
                    {
                      label: value,
                      value
                    }
                  ];
                  componentProps.options = options.map((o: string) => ({
                    label: o,
                    value: o
                  }));
                }
                return <InputComponent key={key} {...componentProps} />;
              })}
            {specialCheckboxInputs.map((formInput: ReportFormInput) => {
              const { key, value, label } = formInput;

              return (
                <div className="customize-report__checkbox-input" key={key}>
                  <Checkbox
                    checked={value as boolean}
                    color="primary"
                    onChange={() => handleInputChange(key, !value)}
                  />
                  {label}
                </div>
              );
            })}
          </div>
          <div className="customize-report__download-button">
            <Button
              alignment="align-center"
              color="product-blue"
              isFullWidth={true}
              label="Download CSV"
              onClick={() => downloadReport()}
              size="medium"
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
