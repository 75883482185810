import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "../button";
import { AuthService, IAuthService } from "../../services/auth.service";
import AppContainer from "../../container";
import { getQueryStringParams } from "../../utils/helpers";

import "./app-status.scss";

export interface Props {
  forceSessionExpired?: Boolean; // used for Storybook rendering
}

export default function AppStatus(props: Props) {
  const authService: IAuthService = AppContainer.get(AuthService);
  const { forceSessionExpired } = props;

  const qsParams = getQueryStringParams();
  const sessionExpired =
    forceSessionExpired || qsParams.hasOwnProperty("expired");

  if (!sessionExpired) {
    return null;
  }

  const statusConfig = {
    title: "Session Expired",
    subTitle: "You've been logged out due to inactivity.",
    description: "We do this to protect your privacy.",
    buttonComponent: (
      <Button
        color="product-blue"
        label="Log Back In"
        onClick={() => {
          authService.smartLoginRedirect();
        }}
        size="medium"
      />
    )
  };

  return (
    <Dialog
      classes={{
        root: "app-status__dialog",
        paper: "paper",
        paperScrollPaper: "paper-scroll-paper"
      }}
      disableBackdropClick={true}
      open={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <div className="app-status__title">
          <h1 className="circular-bold">{statusConfig.title}</h1>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: "app-status__content" }}>
        <h2 className="app-status__sub-title">{statusConfig.subTitle}</h2>
        <p className="app-status__description">{statusConfig.description}</p>
      </DialogContent>
      <DialogActions classes={{ root: "app-status__actions" }}>
        {statusConfig.buttonComponent}
      </DialogActions>
    </Dialog>
  );
}
